import API from "@common/API"
import { Auth } from "aws-amplify"

import { MappingProperty } from "src/services/organization"

type ServiceIdentifier = 
  | "s10k.c2c.i9n.locus" 
  | "s10k.c2c.i9n.solrenview"
  | "s10k.c2c.i9n.solaredge.v1"
  | "s10k.c2c.i9n.egauge";

interface IntegrationApiResponse {
  userId: number;
  configId: number;
  created: string;
  modified: string;
  enabled: boolean;
  name: string;
  serviceIdentifier: ServiceIdentifier;
  serviceProperties?: {
    password?: string;
    username?: string;
    partnerId?: string;
    oauthClientId?: string;
    oauthClientSecret?: string;
    baseUrl?: string;
    apiKey?: string;
  };
}

interface IntegrationData {
  name: string;
  serviceIdentifier: ServiceIdentifier;
  serviceProperties?: {
    password?: string;
    username?: string;
    partnerId?: string;
    oauthClientId?: string;
    oauthClientSecret?: string;
    baseUrl?: string;
    apiKey?: string;
  };
}

export interface IntegrationMapping {
  name: string;
  sourceId?: string;
  properties?: Array<{
    name: string;
    value: string;
    enabled?: boolean;
  }>;
  integrationId?: number;
}

interface MappingPropertyFilters {
  index?: number;
  indexes?: number[];
  offset?: number;
  max?: number;
}

export interface IntegrationDataStream {
  enabled: boolean;
  name: string;
  serviceIdentifier: ServiceIdentifier;
  datumStreamMappingId: number;
  schedule: string;
  kind: string;
  objectId: string;
  sourceId: string;
  serviceProperties: {
    granularity: 'latest' | '1min' | '5min' | '15min' | 'hourly' | 'daily' | 'monthly' | 'yearly';
    [key: string]: string;
  };
}

export interface IntegrationMappingProperty {
  propertyName: string;
  propertyType: 'i' | 'a' | 's';  // Instantaneous, Accumulating, Status
  valueType: 'r' | 's';  // Reference, Spel Expression
  valueReference: string;
  multiplier?: number;
  scale?: number;
  enabled: boolean;
  mappingId?: number;
}

const IntegrationsService = {
  getIntegrationSchema() {
    return API.get("/schemas/integration")
  },

  async availableIntegrations(credentialAlias: string): Promise<any> {
    return API.getFromCache<any>(`/energy/integrations/${credentialAlias}/available`)
  },

  async listIntegrations(credentialAlias: string): Promise<IntegrationApiResponse[]> {
    return API.getFromCache<any>(`/energy/integrations/${credentialAlias}/enabled`)
  },

  async getIntegration(credentialAlias: string, integrationId: string): Promise<IntegrationApiResponse> {
    return API.get(`/energy/integrations/${credentialAlias}/${integrationId}`)
  },

  async createIntegration(credentialAlias: string, integrationData: IntegrationData): Promise<IntegrationApiResponse> {
    return API.post(`/energy/integrations/${credentialAlias}`, integrationData)
  },

  async updateIntegration(credentialAlias: string, integrationId: string, integrationData: Partial<IntegrationData>): Promise<IntegrationApiResponse> {
    return API.put(`/energy/integrations/${credentialAlias}/${integrationId}`, integrationData)
  },

  async deleteIntegration(credentialAlias: string, integrationId: string): Promise<void> {
    return API.delete(`/energy/integrations/${credentialAlias}/${integrationId}`)
  },

  async listMappings(credentialAlias: string, integrationId: string): Promise<any[]> {
    return API.get(`/energy/integration/${credentialAlias}/${integrationId}/datum-stream-mappings`)
  },

  async createMapping(credentialAlias: string, integrationId: string, mappingData: IntegrationMapping): Promise<any> {
    return API.post(`/energy/integration/${credentialAlias}/${integrationId}/datum-stream-mappings`, mappingData)
  },

  async updateMapping(credentialAlias: string, integrationId: string, mappingId: string, mappingData: Partial<IntegrationMapping>): Promise<any> {
    return API.put(`/energy/integration/${credentialAlias}/${integrationId}/datum-stream-mappings/${mappingId}`, mappingData)
  },

  async deleteMapping(credentialAlias: string, integrationId: string, mappingId: string): Promise<void> {
    return API.delete(`/energy/integration/${credentialAlias}/${integrationId}/datum-stream-mappings/${mappingId}`)
  },

  async updateMappingProperty(
    credentialAlias: string, 
    integrationId: string, 
    mappingId: string, 
    propertyIndex: number, 
    propertyData: {name: string, value: string}
  ): Promise<any> {
    return API.put(
      `/energy/integration/${credentialAlias}/${integrationId}/datum-stream-mappings/${mappingId}/properties/${propertyIndex}`, 
      propertyData
    )
  },

  async deleteMappingProperty(
    credentialAlias: string, 
    integrationId: string, 
    mappingId: string, 
    propertyIndex: number
  ): Promise<void> {
    return API.delete(
      `/energy/integration/${credentialAlias}/${integrationId}/datum-stream-mappings/${mappingId}/properties/${propertyIndex}`
    )
  },

  async updateMappingPropertyEnabled(
    credentialAlias: string,
    integrationId: string,
    mappingId: string,
    propertyIndex: number,
    enabled: boolean
  ): Promise<any> {
    return API.put(
      `/energy/integration/${credentialAlias}/${integrationId}/datum-stream-mappings/${mappingId}/properties/${propertyIndex}/enabled/${enabled}`
    )
  },

  async getIntegrationDataValues(
    credentialAlias: string,
    integrationId: string,
    filters?: {
      siteId?: string;
      componentId?: string;
    }
  ): Promise<any> {
    const queryParams = new URLSearchParams()
    if (filters?.siteId) queryParams.append('siteId', filters.siteId)
    if (filters?.componentId) queryParams.append('componentId', filters.componentId)
    
    const queryString = queryParams.toString()
    const url = `/energy/integration/${credentialAlias}/${integrationId}/data${queryString ? `?${queryString}` : ''}`
    
    return API.get(url)
  },

  async listMappingProperties(
    credentialAlias: string,
    mappingId: string,
    filters?: MappingPropertyFilters
  ): Promise<MappingProperty[]> {
    const queryParams = new URLSearchParams()
    
    if (filters?.index !== undefined) {
      queryParams.append('index', filters.index.toString())
    }
    if (filters?.indexes) {
      queryParams.append('indexes', filters.indexes.join(','))
    }
    if (filters?.offset !== undefined) {
      queryParams.append('offset', filters.offset.toString())
    }
    if (filters?.max !== undefined) {
      queryParams.append('max', filters.max.toString())
    }

    const queryString = queryParams.toString()
    const url = `/energy/integration/${credentialAlias}/datum-stream-mappings/${mappingId}/properties${
      queryString ? `?${queryString}` : ''
    }`

    return API.get(url)
  },

  async listIntegrationDataStreams(credentialAlias: string): Promise<IntegrationDataStream[]> {
    return API.get(`/energy/integration/${credentialAlias}/integration-data-streams`)
  },

  async createIntegrationDataStream(
    credentialAlias: string,
    mappingId: string,
    streamData: IntegrationDataStream
  ): Promise<IntegrationDataStream> {
    // energy/integration/{credentialAlias}/integration-data-streams
    return API.post(`/energy/integration/${credentialAlias}/integration-data-streams`, streamData)
  },

  // energy/integration/{credentialAlias}/integration-data-streams/{streamId}
  async deleteIntegrationDataStream(
    credentialAlias: string,
    streamId: string
  ): Promise<void> {
    return API.delete(`/energy/integration/${credentialAlias}/integration-data-streams/${streamId}`)
  },

  async createMappingProperty(
    credentialAlias: string,
    mappingId: string,
    propertyData: IntegrationMappingProperty
  ): Promise<MappingProperty> {
    return API.post(
      `/energy/integration/${credentialAlias}/0/datum-stream-mappings/${mappingId}/properties`,
      [propertyData]
    );
  },
}

export default IntegrationsService

