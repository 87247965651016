import { Menu, MenuItem, Icon, Flex, Text, Loader } from "@aws-amplify/ui-react"
import * as React from "react"
import { useUserAssociatedOrganizations } from "./services/organization"
import { useSwitchOrganization, useUserOrganization } from "./services/users"

interface Props {
  userId: string
}

export default function AccountsCentre(props: Props) {
  const { userId } = props
  const { data } = useUserAssociatedOrganizations()
  const { data: userOrgData } = useUserOrganization(userId)
  const switchOrganizationMutation = useSwitchOrganization()
  const [error, setError] = React.useState<string | null>(null)

  const orgId = userOrgData?.organization.id

  if (!data || data.length <= 1) return null

  const handleSwitch = (orgId: string) => {
    switchOrganizationMutation.mutate(
      { organizationId: orgId },
      {
        onSuccess: () => {
          window.location.reload()
        },
        onError: (error) => {
          console.error("Failed to switch organization:", error)
          setError((error as Error).message)
        },
      },
    )
  }

  return (
    <div style={{ marginRight: "16px" }}>
      <Menu
        trigger={
          <Icon
            ariaLabel="Switch Organization"
            pathData="M7 10l5 5 5-5z"
            height="24px"
            width="24px"
            style={{ cursor: "pointer" }}
          />
        }
      >
        {data.map(({ name, id }) => (
          <MenuItem key={id} onClick={() => handleSwitch(id)} isDisabled={orgId === id}>
            {name}
          </MenuItem>
        ))}
      </Menu>

      {switchOrganizationMutation.isLoading && (
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          style={{ zIndex: 10000 }}
        >
          <Flex direction="column" alignItems="center" backgroundColor="white" padding="2rem" borderRadius="8px">
            <Loader size="large" />
            <Text variation="primary" fontSize="1.2rem" fontWeight="bold" marginTop="1rem">
              Switching Organization...
            </Text>
          </Flex>
        </Flex>
      )}
    </div>
  )
}
