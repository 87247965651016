import React from "react"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, LineChart, Line } from "recharts"
import moment from "moment-timezone"
import { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"

export default class ApparantPowerLineGraph extends DateRangeGraph {
  getData() {
    let data = Object.keys(this.props.graphDatums).map((dateKey) => {
      let datum = this.props.graphDatums[dateKey]
      let date = moment(dateKey)
      let entry = {
        date: date.toDate(),
        time: date.toDate().getTime(),
        name: date.format("lll"),
      }
      Object.keys(datum.assetDatums).forEach((assetCode) => {
        entry[assetCode] = this.round(datum.assetDatums[assetCode].apparentPower)
        entry[`${assetCode}-max`] = this.round(datum.assetDatums[assetCode].apparentPowerMax)
        entry[`${assetCode}-min`] = this.round(datum.assetDatums[assetCode].apparentPowerMin)
      })
      return entry
    })
    return data
  }

  renderContent() {
    if (this.props.range && this.props.graphDatums && this.props.datums) {
      let data = this.getData()
      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"

      return (
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
            <XAxis
              dataKey="time"
              type="number"
              scale="time"
              domain={this.getDomain(this.props.range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis />

            <Tooltip
              wrapperStyle={{ color: "#000", pointerEvents: "auto" }}
              formatter={(value) => {
                if (this.props.normalise) {
                  return value
                }
                return EnergyUtils.formatKva(value)
              }}
              labelFormatter={(label) => {
                return GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)
              }}
            />

            {this.props.assets.map((asset, idx) => {
              return (
                <Line
                  key={`${idx}-max`}
                  type={graphType}
                  label={`${asset.code} (Max)`}
                  dataKey={`${asset.code}-max`}
                  strokeDasharray="4 4"
                  stroke={GRAPH_COLORS[idx % GRAPH_COLORS.length]}
                  dot={aggregation === "day"}
                />
              )
            })}
            {this.props.assets.map((asset, idx) => {
              return <Line key={idx} type={graphType} dataKey={asset.code} stroke={GRAPH_COLORS[idx % GRAPH_COLORS.length]} strokeWidth={2} dot={aggregation === "day"} />
            })}
            {this.props.assets.map((asset, idx) => {
              return (
                <Line
                  key={`${idx}-min`}
                  type={graphType}
                  label={`${asset.code} (Min)`}
                  dataKey={`${asset.code}-min`}
                  strokeDasharray="1 1"
                  stroke={GRAPH_COLORS[idx % GRAPH_COLORS.length]}
                  dot={aggregation === "day"}
                />
              )
            })}
            {this.props.referenceLine ? this.props.referenceLine : null}
            {this.renderUserAlerts()}
            {this.renderCurrentTime()}
            {this.renderAssetEvents()}
          </LineChart>
        </ResponsiveContainer>
      )
    }
    return <Loading />
  }
}
