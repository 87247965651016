import * as React from "react"
import { DropdownItem } from "reactstrap"
import Icon from "@common/display/Icon"
import { useUserOrganization } from "./services/users"

interface Props {
  onClick: () => void
  userId: string
}

export default function SalesForceMenuItem({ onClick, userId }: Props) {
  const { data } = useUserOrganization(userId)

  if (!data?.organization || !data?.organization?.settings?.salesforceUrl) return null

  return (
    <DropdownItem
      onClick={() => {
        window.open(data.organization.settings?.salesforceUrl)
        onClick()
      }}
    >
      Salesforce <Icon icon="open_in_new" />
    </DropdownItem>
  )
}
