var ConsumptionDatumUtils = {
  groupDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, asset) => {
        let assetCode = asset.code
        let assetEntry = assetMap[assetCode]
        if (assetEntry && assetEntry.aggregatedTotals) {
          Object.keys(assetEntry.aggregatedTotals).forEach((dateKey) => {
            let datum = assetEntry.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[assetCode] = {
                consumption: datum.consumption,
                consumptionReading: datum.consumptionReading,
                consumptionMax: datum.consumptionMax,
                consumptionMin: datum.consumptionMin,
                powerFactor: datum.powerFactor,
                powerFactorMax: datum.powerFactorMax,
                powerFactorMin: datum.powerFactorMin,
                apparentPower: datum.apparentPower,
                apparentPowerMax: datum.apparentPowerMax,
                apparentPowerMin: datum.apparentPowerMin,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                consumption: datum.consumption,
                consumptionReading: datum.consumptionReading,
                consumptionMax: datum.consumptionMax,
                consumptionMin: datum.consumptionMin,
                powerFactor: datum.powerFactor,
                powerFactorMax: datum.powerFactorMax,
                powerFactorMin: datum.powerFactorMin,
                apparentPower: datum.apparentPower,
                apparentPowerMax: datum.apparentPowerMax,
                apparentPowerMin: datum.apparentPowerMin,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].consumption += datum.consumption
              datums[dateKey].consumptionReading += datum.consumptionReading
              datums[dateKey].consumptionMax += datum.consumptionMax
              datums[dateKey].consumptionMin += datum.consumptionMin

              if (!datums[dateKey].powerFactor) {
                datums[dateKey].powerFactor = datum.powerFactor
                datums[dateKey].powerFactorMax = datum.powerFactorMax
                datums[dateKey].powerFactorMin = datum.powerFactorMin
              }

              if (!datums[dateKey].apparentPower) {
                datums[dateKey].apparentPower = datum.apparentPower
                datums[dateKey].apparentPowerMax = datum.apparentPowerMax
                datums[dateKey].apparentPowerMin = datum.apparentPowerMin
              }

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[assetCode] = {
                consumption: datum.consumption,
                consumptionReading: datum.consumptionReading,
                consumptionMax: datum.consumptionMax,
                consumptionMin: datum.consumptionMin,
                powerFactor: datum.powerFactor,
                powerFactorMax: datum.powerFactorMax,
                powerFactorMin: datum.powerFactorMin,
                apparentPower: datum.apparentPower,
                apparentPowerMax: datum.apparentPowerMax,
                apparentPowerMin: datum.apparentPowerMin,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },
}

export default ConsumptionDatumUtils
