import i18n from "src/i18n"

const { t } = i18n

export const tableHeaders = [
  {
    placement: "bottom",
    target: "node",
    content: `${t("table_headings.node")}`,
    tooltipText: `${t("settings.tooltips.unique_identifier")}`,
  },
  {
    placement: "bottom",
    target: "name",
    tooltipText: `${t("table_headings.name")}`,
    content: `${t("settings.tooltips.given_name")}`,
  },

  {
    placement: "bottom",
    target: "description",
    tooltipText: `${t("settings.tooltips.general_des")}`,
    content: `${t("table_headings.description")}`,
  },

  {
    placement: "bottom",
    target: "sn-location-id",
    content: `${t("settings.labels.location_id")}`,
    tooltipText: `${t("settings.tooltips.location_id")}`,
  },

  {
    placement: "bottom",
    target: "location",
    tooltipText: `${t("settings.tooltips.physical_location_des")}`,
    content: `${t("table_headings.location")}`,
  },

  {
    placement: "bottom",
    target: "time-zone",
    tooltipText: `${t("labels.timezone")}`,
    className: "center-table-th",
    content: `${t("labels.timezone")}`,
  },

  {
    placement: "bottom",
    target: "enabled",
    tooltipText: `${t("settings.tooltips.toggle_tousein_ecosuite")}`,
    className: "center-table-th",
    content: `${t("labels.enabled")}`,
  },

  {
    placement: "bottom",
    target: "commissioned",
    tooltipText: `${t("settings.tooltips.confirm_installation")}`,
    content: `${t("labels.commissioned")}`,
  },
]
