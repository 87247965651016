import React from "react"

import { Button } from "reactstrap"
import DateTime from "react-datetime"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { EcosuiteFieldTemplate, EcosuiteArrayFieldTemplate, FormError } from "@common/form/EcosuiteForm"
import NotificationService from "./NotificationService"
import { DualListBox } from "@common/input/select/DualListBox"
import i18n from "src/i18n"

const { t } = i18n
export default class NotificationForm extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.saveNotifiction = this.saveNotifiction.bind(this)
    this.deleteNotifiction = this.deleteNotifiction.bind(this)
    this.dualListBoxField = this.dualListBoxField.bind(this)
  }

  saveNotifiction(form) {
    this.setStateIfMounted({
      loading: true,
    })

    NotificationService.saveUserNotification(this.props.userId, form.formData)
      .then((savedNotification) => {
        this.props.updateUserNotification(this.props.notification, savedNotification)
        this.setStateIfMounted({
          loading: false,
        })
      })
      .catch((err) => {
        this.setStateIfMounted({
          loading: false,
          error: err,
        })
      })
  }

  deleteNotifiction(notification) {
    this.setStateIfMounted({
      loading: true,
    })

    NotificationService.deleteUserNotification(this.props.userId, notification.id)
      .then(() => {
        this.props.removeNotification(notification.id)
        this.setStateIfMounted({
          loading: false,
        })
      })
      .catch((err) => {
        this.setStateIfMounted({
          loading: false,
          error: err,
        })
      })
  }

  getError() {
    if (this.state && this.state.error) {
      return this.state.error.message ? this.state.error.message : this.state.error
    }
    return null
  }

  dualListBoxField(props) {
    const projects = this.props.participants
    const selected = props.formData ? projects.map((item, index) => index).filter((index) => props.formData.find((code) => code === this.props.participants[index].getID())) : []

    return (
      <div className={props.className}>
        <fieldset>
          <legend id={props.idSchema.$id}>
            {props.title}
            {props.required ? <span className="required">*</span> : null}
          </legend>
          <DualListBox fields={["Code", "Name"]} options={projects} onChange={(items) => props.onChange(items)} selected={selected} />
        </fieldset>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }

    let notification = this.props.notification
    return (
      <React.Fragment>
        <EcosuiteForm
          formData={notification}
          schema={this.props.schema}
          uiSchema={this.getUiSchema()}
          FieldTemplate={EcosuiteFieldTemplate}
          ArrayFieldTemplate={EcosuiteArrayFieldTemplate}
          onSubmit={this.saveNotifiction}
          transformErrors={transformErrors}
        >
          <FormError error={this.state.error} />
          {notification.id ? (
            <React.Fragment>
              <Button color="primary" type="submit">
                {t("buttons.update")}
              </Button>
              <Button
                color="danger"
                type="submit"
                onClick={(e) => {
                  e.preventDefault()
                  this.deleteNotifiction(notification)
                }}
              >
                {t("buttons.delete")}
              </Button>
            </React.Fragment>
          ) : (
            <Button color="primary" type="submit">
              {t("buttons.submit")}
            </Button>
          )}
        </EcosuiteForm>
      </React.Fragment>
    )
  }

  getUiSchema() {
    return {
      subscription: {
        "ui:options": {
          label: false,
        },
      },
      dateRestrictions: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
        enabled: {
          "ui:options": {
            label: false,
          },
        },
        start: {
          "ui:options": {
            inline: true,
          },
        },
        end: {
          "ui:options": {
            inline: true,
          },
        },
        recurring: {
          "ui:options": {
            label: false,
          },
        },
      },
      timeRestrictions: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
        enabled: {
          "ui:options": {
            label: false,
          },
        },
        start: {
          "ui:options": {
            inline: true,
          },
          "ui:widget": (props) => {
            return (
              <DateTime
                value={props.value}
                dateFormat={false}
                timeFormat="HH:mm"
                onChange={(time) => {
                  if (typeof time === "object") {
                    props.onChange(time.format("HH:mm"))
                  } else {
                    props.onChange(time)
                  }
                }}
              />
            )
          },
        },
        end: {
          "ui:options": {
            inline: true,
          },
          "ui:widget": (props) => {
            return (
              <DateTime
                value={props.value}
                dateFormat={false}
                timeFormat="HH:mm"
                onChange={(time) => {
                  if (typeof time === "object") {
                    props.onChange(time.format("HH:mm"))
                  } else {
                    props.onChange(time)
                  }
                }}
              />
            )
          },
        },
      },
      recordEvents: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
      },
      alerts: {
        "ui:item-label": "Alert",
        "ui:layout": {
          type: "list",
          inline: true,
          // buttonsClassName: "field-array-object-buttons",
          buttonsClassName: "field-array-field-buttons",
        },
        items: {
          "ui:options": {
            label: false,
          },
          sourceId: {
            "ui:options": {
              inline: true,
            },
          },
          datum: {
            "ui:options": {
              inline: true,
            },
          },
          aggregate: {
            "ui:options": {
              inline: true,
            },
          },
          operation: {
            "ui:options": {
              inline: true,
            },
          },
          limit: {
            "ui:options": {
              inline: true,
            },
          },
        },
      },
      participants: {
        "ui:field": this.dualListBoxField,
        "ui:projects": this.props.participants,
      },
    }
  }
}

function transformErrors(errors) {
  return errors.map((error) => {
    // Currently based on the assumption that the only property using the "pattern" configuration are the time properties
    if (error.name === "pattern") {
      error.message = "Invalid time, should be in format: HH:mm"
    }
    return error
  })
}
