import React from "react"

import { EcosuiteComponentError } from "@common/EcosuiteComponent"

import ProjectsTable from "./project/ProjectsTable"
import EnergyView from "./EnergyView"

/**
 * Expects the following props:
 *
 * projects - list of the projects that should be displayed
 * projectsStatus - list of the status of projects

 * readings - map of project readings
 * lastMonthsEnergyReadings - map of last months project readings

 * datums - map of project datums
 * datumsFilter - the filter for the project datums

 * predictedConsumption - the forecast consumption datums
 * expectedGeneration - the expected generation datums
 * predictedGeneration - the predicted generation datums

 * instantaneous - map of instantaneous project readings

 * selectProject={this.selectProject}
 */
export default class EnergyOverView extends EnergyView {
  getProjectStatus(projectId) {
    if (this.isContentValid(this.props.projectsStatus)) {
      return this.props.projectsStatus.projects.find((projectStatus) => {
        return projectStatus.code === projectId
      })
    }
    return null
  }

  getProjectReading(projectId) {
    if (this.isContentValid(this.props.readings)) {
      return this.props.readings.projects[projectId]
    }
    return null
  }

  renderMainView() {
    return (
      <ProjectsTable
        range={this.props.range}
        groups={this.props.groups}
        projects={this.props.projects}
        projectsStatus={this.props.projectsStatus}
        readings={this.props.readings}
        datums={this.props.datums instanceof EcosuiteComponentError ? null : this.props.datums}
        consumptionCost={this.props.consumptionCost}
        predictedConsumption={this.props.predictedConsumption}
        expectedGeneration={this.props.expectedGeneration}
        predictedGeneration={this.props.predictedGeneration}
        lastMonthsEnergyReadings={this.props.lastMonthsEnergyReadings}
        selectProject={this.props.selectProject}
        showGeneration={this.props.showGeneration}
        showConsumption={this.props.showConsumption}
      />
    )
  }
}
