const CATEGORY_NAMES = ["Income", "Expense", "Fixed Asset", "Liability", "Equity"]

const Categories = {
  mergeReportCategory(categories, aggregatedReport) {
    Object.values(aggregatedReport.categories).forEach((category) => {
      if (categories[category.name]) {
        // Populate any sub categories that were missing from the actual data
        Object.values(category.categories).forEach((subCategory) => {
          var mergedSubCategory = categories[category.name].categories.find((mergedSubCategory) => subCategory.name === mergedSubCategory.name)
          if (!mergedSubCategory) {
            // The sub cat doesb't exist so we just copy in the whole entry
            mergedSubCategory = { name: subCategory.name }
            if (subCategory.categories) {
              mergedSubCategory.categories = Object.values(subCategory.categories).map((account) => {
                return { name: account.name }
              })
            }
            categories[category.name].categories.push(mergedSubCategory)
          } else {
            // The sub categfory already exists so make sure to add any additional accounts
            Object.values(subCategory.categories).forEach((account) => {
              if (!mergedSubCategory.categories.find((mergedAccount) => account.name === mergedAccount.name)) {
                mergedSubCategory.categories.push({ name: account.name })
              }
            })
          }
        })
      } else {
        // Populate the categories using the forecast data if it was missing from the actual
        categories[category.name] = {
          name: category.name,
          categories: Object.values(category.categories).map((subCategory) => {
            const mergedSubCategory = { name: subCategory.name }
            if (subCategory.categories) {
              mergedSubCategory.categories = Object.values(subCategory.categories).map((account) => {
                return { name: account.name }
              })
            }
            return mergedSubCategory
          }),
        }
      }
    })
  },

  mergeReportCategories(categories, report) {
    if (report) {
      // We populate the categories based on the data from the accounting platform
      this.mergeReportCategory(categories, report.actual)

      // We also make sure that any categories not currently used in the accounting platform get populated if needed
      this.mergeReportCategory(categories, report.forecast) // This also cover "expected" and "latestBestEstimate"

      Object.keys(categories).forEach((categoryName) => {
        if (!CATEGORY_NAMES.includes(categoryName)) {
          delete categories[categoryName]
        }
      })

      Object.values(categories).forEach((category) => {
        category.categories = Object.values(category.categories).sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        Object.values(category.categories).forEach((subCategory) => {
          if (subCategory.categories) {
            subCategory.categories = Object.values(subCategory.categories).sort((a, b) => {
              return a.name.localeCompare(b.name)
            })
          }
        })
      })
    }

    return categories
  },
}

export default Categories
