import React from "react"
import { Button, Col, Container, Input, InputGroup, Row } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import EcosuiteComponent from "@common/EcosuiteComponent"
import Logger from "@common/Logger"
import OrganizationAdminService from "./OrganizationAdminService"

import CreateOrganization from "./CreateOrganization"
import EditOrganization from "./EditOrganization"
import { fuzzySearchStringList } from "@common/input/search/SearchGroup"
import OrganizationList from "./widgets/OrganizationList"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
// The Organization table entry padding.
//
// This is used to accurately align other items on the screen around the fact
// that the Organization table entries have extra right padding.
const OrganizationTablePadding = "20px"

class Organizations extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.refreshOrganization = this.refreshOrganization.bind(this)
    this.selectOrganization = this.selectOrganization.bind(this)
    this.loadOrganizations = this.loadOrganizations.bind(this)
    this.toggle = this.toggle.bind(this)

    this.state = {
      Organizations: [],
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadOrganizations()
  }

  loadOrganizations() {
    this.setState({ Organizations: [] })

    OrganizationAdminService.getOrganizations()
      .then((response) => {
        Logger.info("OrganizationAdminService.getOrganizations() response=" + JSON.stringify(response))
        let selectedOrganizationId = this.props.selectedOrganizationId
        let Organizations = response.Items.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })

        let Organization = Organizations.find((Organization) => {
          return Organization.id === selectedOrganizationId
        })

        Logger.info("selectedOrganizationId=" + selectedOrganizationId + ", Organization = " + Organization)
        this.setStateIfMounted({
          Organizations: Organizations,
          selectedOrganization: Organization,
        })
      })
      .catch((error) => {
        Logger.error(error)
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  refreshOrganization(Organization) {
    Logger.info("updating organization " + Organization.id)
    for (var i = 0; i < this.state.Organizations.length; i++) {
      if (this.state.Organizations[i].id === Organization.id) {
        this.state.Organizations[i] = Organization
        Logger.info("updated organization " + Organization.id)
        break
      }
    }

    this.setState({ Organizations: this.state.Organizations, selectedOrganization: null })
  }

  componentDidUpdate(prevProps) {
    if (this.state.Organizations.length > 0 && this.props.selectedOrganizationId !== prevProps.selectedOrganizationId) {
      let Organization = this.state.Organizations.find((Organization) => {
        return Organization.id === this.props.selectedOrganizationId
      })
      this.setStateIfMounted({
        selectedOrganization: Organization,
      })
    }
  }

  selectOrganization(Organization) {
    Logger.info("selectOrganization " + JSON.stringify(Organization))
    if (this.state.selectedOrganization && Organization && this.state.selectedOrganization.id === Organization.id) {
      Organization = null
    }
    this.setStateIfMounted({
      selectedOrganization: Organization,
    })
  }

  /**
   * Get all possible search terms. This currently returns a list of Organization
   * emails.
   */
  getPossibleSearchTerms() {
    return this.state.Organizations ?? []
  }

  /**
   * Get a Organization by name.
   * @param name - The expected name.
   */
  getOrganizationByName(name) {
    return this.state.Organizations.find((element) => element.name.toLowerCase().includes(name.toLowerCase()))
  }

  /**
   * Search the Organizations given an onChange event.
   * @param event - The onChange event.
   */
  searchOrganizations(event) {
    const value = event.target.value
    let terms = []

    // If the Organization has input some value, then run the fuzzy search.
    if (value) {
      const results = fuzzySearchStringList(this.getPossibleSearchTerms(), value, {
        includeScore: true,
        threshold: 0.25,
        keys: ["name"],
      })
      results.forEach((result) => {
        terms.push(result.item)
      })
    }

    this.setStateIfMounted({
      searchTerm: value,
      searchTermResults: value ? terms : this.getPossibleSearchTerms(),
    })
  }

  /**
   * Clear the current search parameters.
   */
  clearSearch() {
    this.setStateIfMounted({
      searchTerm: "",
      searchTermResults: this.getPossibleSearchTerms(),
    })
  }

  renderContent() {
    if (this.state.selectedOrganization && !this.state.selectedOrganization.id) {
      return (
        <CreateOrganization
          Organization={this.state.selectedOrganization}
          selectOrganization={this.selectOrganization}
          reloadOrganizations={this.loadOrganizations}
          projects={this.props.projects}
          portfolios={this.props.portfolios}
        />
      )
    }

    return this.state.selectedOrganization ? (
      <Row>
        <Container fluid={true} className="Admin-footer">
          <div className="admin-footer">
            {this.renderSearchBar()}
            <Button
              className="header-button float-end"
              color="danger"
              onClick={() => {
                this.selectOrganization()
              }}
            >
              {t("buttons.cancel")}
            </Button>
          </div>
        </Container>
        <Container fluid={true} className="Admin-content">
          <Row>
            <Col md="3">
              <OrganizationList
                OrganizationId={this.state.selectedOrganization ? this.state.selectedOrganization.id : null}
                Organizations={this.state.Organizations}
                selectOrganization={(id) => this.selectOrganization(this.state.Organizations.find((u) => u.id === id))}
                searchTermResults={this.state.searchTermResults}
                extended={false}
              />
            </Col>
            <Col md="9">{this.renderOrganizationDetails()}</Col>
          </Row>
        </Container>
      </Row>
    ) : (
      <Row>
        <Container fluid={true} className="Admin-content">
          <OrganizationList
            OrganizationId={this.state.selectedOrganization ? this.state.selectedOrganization.id : null}
            selectOrganization={(id) => this.selectOrganization(this.state.Organizations.find((u) => u.id === id))}
            searchTermResults={this.state.searchTermResults}
            extended={true}
          />
        </Container>
        <Container fluid={true} className="Admin-footer">
          <div className="admin-footer">
            {this.renderSearchBar()}
            <Button
              className="header-button float-end"
              color="ecogy"
              onClick={() => {
                this.selectOrganization({})
              }}
            >
              {t("settings.labels.new_organization")}
            </Button>
          </div>
        </Container>
      </Row>
    )
  }

  /**
   * Render the Organization search bar.
   * @returns {JSX.Element} - The Organization search bar.
   */
  renderSearchBar() {
    // TODO: move to SearchGroup component.
    return (
      <InputGroup
        style={{
          width: `calc(25% - ${OrganizationTablePadding})`,
          display: "inline-flex",
          paddingLeft: "2.5px",
        }}
        className={"float-start"}
      >
        <Input
          placeholder={`${t("settings.labels.search_organizations")}`}
          value={this.state.searchTerm ? this.state.searchTerm : ""}
          onChange={(event) => this.searchOrganizations(event)}
          on="true"
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              if (this.state.searchTermResults) {
                this.selectOrganization(this.getOrganizationByName(this.state.searchTermResults[0]))
              }
            }
          }}
        />
        <Button
          id={"clearOrganizationInput"}
          onClick={(e) => {
            e.preventDefault()
            this.clearSearch()
          }}
        >
          <Icon icon="clear" />
        </Button>
        <UncontrolledTooltip placement="bottom" target={"clearOrganizationInput"}>
          {`${t("settings.labels.clear_input")}`}
        </UncontrolledTooltip>
      </InputGroup>
    )
  }

  renderOrganizationDetails() {
    return (
      <div className="admin-content admin-tabs">
        <EditOrganization
          organization={this.state.selectedOrganization}
          organizationChanged={this.refreshOrganization}
          organizationDeleted={this.loadOrganizations}
          projects={this.props.projects}
          portfolios={this.props.portfolios}
          selectOrganization={(id) => this.selectOrganization(this.state.Organizations.find((u) => u.id === id))}
        />
      </div>
    )
  }
}

export default Organizations
