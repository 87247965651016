import { Button } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import React from "react"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export interface ClearButtonProps {
  onClear: () => void
  size?: string
}

/**
 * A clear button, used to clear input.
 * @param props
 * @constructor
 */
export const ClearButton = (props: ClearButtonProps): JSX.Element => {
  return (
    <>
      <Button
        size={props.size}
        id={"clearUserInput"}
        onClick={(e) => {
          e.preventDefault()
          props.onClear.call(this)
        }}
      >
        <Icon icon="clear" />
      </Button>
      <UncontrolledTooltip placement={"top"} target={"clearUserInput"}>
        {`${t("labels.clear_input")}`}
      </UncontrolledTooltip>
    </>
  )
}

export default ClearButton
