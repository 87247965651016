import { get, getUnuthenticated, post, put } from "../setup"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { MetaData } from "src/PublicView/types"

export type ShareRecordDocumentsBody = MetaData & {
  emailAddresses: Array<string>
  organization: { name: string; logo?: string }
  createdAt?: string
}

export const shareRecordDocumentsApi = (body: ShareRecordDocumentsBody) =>
  post<ShareRecordDocumentsBody, any>("/records/public-documents", body)

export const getPublicRecordDocumentsApi = (uuid: string) =>
  getUnuthenticated<ShareRecordDocumentsBody>(`/records/public-documents/${uuid}`)

export function useGetPublicDocuments(uuid: string | undefined) {
  return useQuery({
    queryKey: [`record/public-documents/${uuid}`],
    queryFn: async () => {
      const response = await getPublicRecordDocumentsApi(uuid!)
      return response
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: Boolean(uuid),
  })
}

export function useShareRecordDocumentsToPublic() {
  const client = useQueryClient()
  return useMutation(shareRecordDocumentsApi, {
    onError: (e: Error) => {
      console.error(e)
    },
  })
}
