import React from "react"
import moment from "moment-timezone"

import { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"

import { ResponsiveContainer, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart } from "recharts"

import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"
import Utils from "@common/utils/Utils"
import Aggregations from "@common/Aggregations"
import { CostGraphTooltip } from "./CostGraphTooltip"
import i18n from "src/i18n"

const { t } = i18n
export default class ConsumptionCostGraph extends DateRangeGraph {
  getMinimumAggregation() {
    return Aggregations.Hour
  }

  renderContent() {
    if (this.props.consumptionCostDatums) {
      let data = Object.keys(this.props.consumptionCostDatums).map((dateKey) => {
        let date = moment(dateKey)

        return {
          date: date.toDate(),
          time: date.toDate().getTime(),
          name: date.format("lll"),
          meteredCost: this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey] ? this.props.consumptionCostDatums[dateKey].meteredCost : null,
          fixedCost: this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey] ? this.props.consumptionCostDatums[dateKey].fixedCost : null,
          demandCost: this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey] ? this.props.consumptionCostDatums[dateKey].demandCost : null,
        }
      })

      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"

      return (
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
            <XAxis
              dataKey="time"
              domain={this.getDomain(this.props.range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis
              yAxisId="cost"
              tickFormatter={(amount) => {
                return Utils.formatCurrency(amount)
              }}
            />
            <Tooltip content={<CostGraphTooltip assets={this.props.assets} getDataTitle={getDataTitle} range={this.props.range} aggregation={this.props.aggregation} />} showTotal={true} />

            <Bar stackId="cost" name={getDataTitle("fixedCost")} type={graphType} dataKey="fixedCost" stroke={GRAPH_COLORS[2]} fill={GRAPH_COLORS[2]} dot={false} yAxisId="cost" />
            <Bar stackId="cost" name={getDataTitle("demandCost")} type={graphType} dataKey="demandCost" stroke={GRAPH_COLORS[1]} fill={GRAPH_COLORS[1]} dot={false} yAxisId="cost" />
            <Bar stackId="cost" name={getDataTitle("meteredCost")} type={graphType} dataKey="meteredCost" stroke={GRAPH_COLORS[0]} fill={GRAPH_COLORS[0]} dot={false} yAxisId="cost" />

            {this.props.referenceLine ? this.props.referenceLine : null}

            <Legend iconType="plainline" />
          </BarChart>
        </ResponsiveContainer>
      )
    }

    return <Loading />
  }
}

export var getDataTitle = function (name) {
  switch (name) {
    case "predictedConsumption":
      return `${t("econode.dataTitles.Forecast Consumption")}`
    case "consumption":
      return `${t("labels.consumption")}`
    case "consumptionCost":
      return `${t("econode.dataTitles.Total Charges")}`
    case "meteredCost":
      return `${t("econode.dataTitles.Metered Charges")}`
    case "demandCharge":
      return `${t("econode.dataTitles.Demand Charges")}`
    case "fixedCost":
      return `${t("econode.dataTitles.Fixed Charges")}`
    case "peakDemandCharge":
      return `${t("econode.dataTitles.Demand Charges")}`
    case "demandCost":
      return `${t("econode.dataTitles.Demand Charges")}`
    default:
      return name
  }
}
