import React from "react"
// eslint-disable-next-line no-unused-vars
import { LineType, ResponsiveContainer, XAxis, YAxis, Legend, LineChart, Line, Tooltip } from "recharts"
import moment from "moment-timezone"

import { Error, Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"

import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"
import SourceUtils from "./SourceUtils"
import i18n from "src/i18n"

const { t } = i18n
class PowerUserGraph extends DateRangeGraph {
  constructor(props) {
    super(props)
    GraphUtils.dateFormat = GraphUtils.dateFormat.bind(this)
    this.getGraphData = this.getGraphData.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.setStateIfMounted({ assets: this.getAssets() })
  }

  componentDidUpdate(prevProps) {
    if (this.props.checkedKeys !== prevProps.checkedKeys) {
      this.setStateIfMounted({ assets: this.getAssets() })
    }
  }

  dateFormat(time) {
    if (this.props.aggregation === "month") {
      return moment(time).format("MMM YY")
    } else if (this.props.aggregation === "day") {
      return moment(time).format("MMM DD")
    } else {
      return moment(time).format("HH:mm")
    }
  }

  /**
   * Gets the assets that should be used for the supplied properties
   * @return {Array.<{sourceId: string, propertyName: string}>} maps source IDs to individual property names
   */
  getAssets() {
    /** @type {Array.<String>} */
    let checkedKeys = this.props.checkedKeys

    if (checkedKeys) {
      return checkedKeys
        .filter((key) => SourceUtils.isProperty(key))
        .map((key) => {
          return { sourceId: SourceUtils.getNodeAndSource(key), propertyName: SourceUtils.getProperty(key) }
        })
    } else {
      return []
    }
  }

  /** @return {Array.<{date: Date, time: number}>} */
  getGraphData(datums) {
    /** @type {{date: Date, time: number}} */
    var previous
    /** @type {Array.<{date: Date, time: number}>} */
    const graphData = datums.map((datum) => {
      /** @type moment.Moment */
      const date = moment(datum.localDate + datum.localTime, "YYYY-MM-DDHH:mm")
      /** @type boolean */
      const sameDateAndTime = previous && previous.date && date.isSame(previous.date)
      /** @type {{date: Date, time: number}} */
      const entry = sameDateAndTime
        ? previous
        : {
            date: date.toDate(),
            time: date.toDate().getTime(),
          }
      /** @type Array.<string> */
      const keys = Object.keys(datum)
      /** @type string */
      const sourceId = datum.nodeId + datum.sourceId
      /** @type boolean */
      var matched = false
      this.state.assets.forEach((asset) => {
        if (asset.sourceId === sourceId && keys.includes(asset.propertyName)) {
          entry[asset.sourceId + "/" + asset.propertyName] = datum[asset.propertyName]
          matched = !sameDateAndTime
          previous = entry
        }
      })
      return matched ? entry : null
    })
    return graphData.filter((entry) => entry != null)
  }

  renderContent() {
    if (this.props.datums && this.state.assets) {
      if (this.isContentError(this.props.datums)) {
        return (
          <div>
            <Error error={this.props.datums.getError()} />
            <p className="graph-warning">{t("warnings.graph_warnings")}</p>
          </div>
        )
      }

      var range = this.props.range
      /** @type {Array.<{date: Date, time: number}>} */
      const data = this.getGraphData(this.props.datums)
      /** @type LineType */
      const graphType = this.props.aggregation === "day" ? "linear" : "monotone"
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <XAxis
              dataKey="time"
              type="number"
              scale="time"
              domain={this.getDomain(range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis />
            <Legend layout="vertical" verticalAlign="top" align="right" />
            <Tooltip
              wrapperStyle={{ color: "#000" }}
              labelFormatter={(label) => {
                return moment(label).format("lll")
              }}
            />

            {this.state.assets.map((asset, idx) => {
              return <Line key={idx} type={graphType} dataKey={asset.sourceId + "/" + asset.propertyName} stroke={GRAPH_COLORS[idx % GRAPH_COLORS.length]} dot={this.props.aggregation === "day"} />
            })}
          </LineChart>
        </ResponsiveContainer>
      )
    } else if (!this.props.loading && (!this.state.assets || !this.state.assets.length)) {
      return (
        <div className="select-projects-mesg">
          <p>{t("notes.select_remote_data")}</p>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

export default PowerUserGraph
