import React from "react"
import { ButtonToolbar, ButtonGroup, Button } from "reactstrap"
import Toggle from "react-toggle"

import Logger from "@common/Logger"
import { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import API from "@common/API"
import Schemas from "@common/Schemas"

import TypeFilter from "../TypeFilter"

import RecordService from "./RecordService"
import RecordList from "./list/RecordList"
import RecordDetails from "./details/RecordDetails"
import ProjectUtils from "@common/utils/ProjectUtils"
import { getErrorMessage } from "@common/SharedComponentUtils"
import RecordNotifications from "./RecordNotifications"
import RecordUtils from "./RecordUtils"
import i18n from "src/i18n"
import { deepEquals } from "@rjsf/core/lib/utils"

const { t } = i18n

export default class RecordView extends EcosuiteView {
  constructor(props) {
    super(props, "record-list")

    this.loadRecords = this.loadRecords.bind(this)
    this.loadRecordDocuments = this.loadRecordDocuments.bind(this)
    this.newRecord = this.newRecord.bind(this)

    this.selectRecord = this.selectRecord.bind(this)
    this.selectNextRecord = this.selectNextRecord.bind(this)
    this.recordAdded = this.recordAdded.bind(this)
    this.recordUpdated = this.recordUpdated.bind(this)
    this.recordDeleted = this.recordDeleted.bind(this)

    this.toggleRecordType = this.toggleRecordType.bind(this)
    this.toggleRecordSubType = this.toggleRecordSubType.bind(this)
    this.saveRecordSettings = this.saveRecordSettings.bind(this)

    this.addFoldersToTypeHeirarchy = this.addFoldersToTypeHeirarchy.bind(this)

    // { ...undefined } => { }
    // this.state.viewableRecordTypes = { ...this.props.moduleViews?.recordTypes }
    // This is temporary until we update the current userTypes using the new userType + recordType definiton, then line 34 becomes ideal
    this.state.viewableRecordTypes = {
      financial: [],
      compliance: [],
      energy: [],
      ...this.props.moduleViews?.recordTypes,
    }
    this.state.showUnmadeRecords = true
    this.state.showNonApplicableRecords = true
  }

  componentDidMount() {
    super.componentDidMount()

    // When viewing a portfolio you would get an error because this.props.project is null
    // This is a temporary fix until implementing this feature to this.props.projects becomes necessary
    if (this.props.project) {
      this.loadRecordSettings()
    }

    this.loadRecords()

    Promise.all([this.loadSchema(), this.loadRecordDocuments()]).then(() => {
      if (this.props.record) {
        //this.newRecord(JSON.parse(atob(this.props.record)))
        const a = atob(this.props.record)
        const b = JSON.parse(a)
        this.newRecord(b)
      }
    })
  }

  componentDidUpdate(prevProps) {
    // Deselect any viewed records if the selected project changes
    if (this.props.project !== prevProps.project) {
      this.setStateIfMounted({
        record: null,
        readonly: null,
      })
    }

    if (this.props.loadTime !== prevProps.loadTime || this.props.project !== prevProps.project) {
      this.loadRecords()
    } else if (
      this.props.project &&
      (!deepEquals(prevProps.project, this.props.project) || (prevProps.projects && !this.props.projects))
    ) {
      this.loadRecords()
    } else if (
      this.props.portfolio &&
      (!deepEquals(prevProps.portfolio, this.props.portfolio) || (prevProps.projects && !this.props.projects))
    ) {
      this.loadRecords()
    }

    // When viewing a portfolio you would get an error because this.props.project is null
    // This is a temporary fix until implementing this feature to this.props.projects becomes necessary
    if (this.props.project && this.props.project !== prevProps.project) {
      this.loadRecordSettings()
    }
  }

  loadSchema() {
    Schemas.getRecordSchema().then((schema) => {
      let typeHierarchy = []
      let disabledSubTypes = []
      let selectedTypes = {}
      let recordSubTypeNames = {}

      schema.properties.recordType.enum.forEach((recordType, idx) => {
        selectedTypes[recordType] = {}
        let recordTypeDependency = schema.dependencies.recordType.oneOf.find(
          (type) => type.properties.recordType.enum[0] === recordType,
        )

        // typeHierarchy is still needed because it contains more information than this.state.viewableRecordTypes
        // Filter typeHierarchy based on this.state.viewableRecordTypes here instead of inside <TypeFilters />
        if (this.state.viewableRecordTypes[recordType]?.length) {
          typeHierarchy.push({
            id: recordType,
            name: schema.properties.recordType.enumNames[idx],
            subTypes: recordTypeDependency.properties.recordSubType.enum
              .map((subTypeId, subIdx) => {
                return {
                  id: subTypeId,
                  name: recordTypeDependency.properties.recordSubType.enumNames[subIdx],
                }
              })
              .filter((subType) => this.state.viewableRecordTypes[recordType]?.includes(subType.id)),
          })
        }

        recordTypeDependency.properties.recordSubType.enum.forEach((subType) => {
          // Skipping 'other' here is a hack, currently we have an 'other' subType for each recordType and
          // react-jsonschema-form can only disable enum options by key, so disabling one 'other' subType would disable all of them
          if (subType !== "other" && !this.state.viewableRecordTypes[recordType]?.includes(subType)) {
            disabledSubTypes.push(subType)
          }
          selectedTypes[recordType][subType] = true // enable all sub types by default
        })
      })

      typeHierarchy.map((recordType) => {
        recordType.subTypes.map((recordSubType) => (recordSubTypeNames[recordSubType.id] = recordSubType.name))
      })

      this.setStateIfMounted({
        recordSchema: schema,
        typeHierarchy: typeHierarchy,
        selectedTypes: selectedTypes,
        recordSubTypeNames: recordSubTypeNames,
        disabledSubTypes: disabledSubTypes,
      })
    })
  }

  async loadRecords() {
    this.setStateIfMounted({
      records: null,
    })
    try {
      let records = []
      if (this.props.project) {
        const response = await RecordService.getProjectRecords(this.props.project.code)
        const data = response.records
        records = records.concat(data)
      } else {
        // If a portfolio is selected, get all by portfolio.
        if (this.props.portfolio) {
          const response = await RecordService.getPortfolioRecords(this.props.portfolio.id)
          const data = response.records
          records = records.concat(data)
        }
        // If multiple projects are selected, get all projects.
        else if (this.props.projects) {
          const responses = await Promise.all(
            this.props.projects.map(async (project) => await RecordService.getProjectRecords(project.code)),
          )
          for (const response of responses) {
            const data = response.records
            records = records.concat(data)
          }
        }
      }

      // Sort record documents by `record.path`.
      records.sort((a, b) => {
        return a.path.localeCompare(b.path)
      })

      this.setStateIfMounted({
        records: records,
      })
    } catch (error) {
      this.setStateIfMounted({
        records: new EcosuiteComponentError(error),
      })
    }
  }

  /**
   * Load all record documents and combine with the local type hierarchy.
   *
   * This is to include user created directories.
   * @returns {Promise<void>}
   */
  async loadRecordDocuments() {
    this.setStateIfMounted({ recordDocuments: null })

    try {
      let records = []
      if (this.props.project) {
        const response = await RecordService.getProjectDocuments(this.props.project.code)
        const data = response.data
        records = records.concat(data)
      } else {
        // If a portfolio is selected, get all by portfolio.
        if (this.props.portfolio) {
          const response = await RecordService.getPortfolioDocuments(this.props.portfolio.id)
          const data = response.data
          records = records.concat(data)
        }
        // If multiple projects are selected, get all projects.
        else if (this.props.projects) {
          const responses = await Promise.all(
            this.props.projects.map(async (project) => await RecordService.getProjectDocuments(project.code)),
          )
          for (const response of responses) {
            const data = response.data
            records = records.concat(data)
          }
        }
      }

      const { typeHierarchy, selectedTypes } = this.addFoldersToTypeHeirarchy(records, this.state.record)

      // Sort record documents by `record.recordPath`.
      records.sort((a, b) => {
        return a.recordPath.localeCompare(b.recordPath)
      })

      this.setStateIfMounted({
        recordDocuments: records,
        typeHierarchy,
        selectedTypes,
      })
    } catch (error) {
      Logger.error(error)
      this.setStateIfMounted({ recordDocuments: new EcosuiteComponentError(error) })
    }
  }

  addFoldersToTypeHeirarchy(recordDocuments, record) {
    /**
     * Check if the record document matches the project code.
     * @param code - The code.
     * @param recordDocument - The record document.
     * @returns {boolean}
     */
    const doesMatchProjectCode = (code, recordDocument) => {
      return ProjectUtils.getProjectCode(recordDocument.recordPath) === code
    }

    // We expect typeHierarchy to have already been populated by loadSchema
    const typeHierarchy = this.state.typeHierarchy
    if (typeHierarchy) {
      if (recordDocuments) {
        recordDocuments
          ?.filter((recordDocument) => {
            if (record && record.path) {
              return doesMatchProjectCode(ProjectUtils.getProjectCode(record.path), recordDocument)
            } else if (this.props.project) {
              return doesMatchProjectCode(this.props.project.code, recordDocument)
            } else if (this.props.projects) {
              return this.props.projects.some((project) => doesMatchProjectCode(project.code, recordDocument))
            }
          })
          ?.forEach((recordDocument) => {
            const type = typeHierarchy.find((type) => type.id === recordDocument.recordType)
            if (type) {
              const subType = type.subTypes.find((subType) => subType.id === recordDocument.recordSubType)

              if (subType && recordDocument.documents) {
                subType.folders = recordDocument.documents.map((document) => {
                  return document.folderName
                })
              }
            }
          })
      }

      return {
        typeHierarchy,
        selectedTypes: RecordUtils.convertTypeHierarchyToTypes(typeHierarchy),
      }
    } else {
      Logger.warning("No type hierarchy loaded")
    }
  }

  selectRecord(record, readonly, _selectedTypes) {
    // Get folder names when a record is selected as that is when this data will be used
    const { typeHierarchy, selectedTypes } = this.addFoldersToTypeHeirarchy(this.state.recordDocuments, record)
    this.setStateIfMounted({
      record: record,
      readonly: readonly,
      typeHierarchy,
      selectedTypes: _selectedTypes || selectedTypes,
    })
  }

  selectNextRecord() {
    Logger.debug("loading next document")
    const records = this.state.recordDocuments
    const idx = records.findIndex((record) => record === this.state.record) + 1
    if (idx <= records.legnth - 1) {
      this.selectRecord(records[idx])
    }
  }

  recordAdded(record) {
    Logger.debug("Record added")
    this.notifyRecordChange(record)
  }

  recordUpdated(record) {
    Logger.debug("Record updated")
    this.notifyRecordChange(record)
  }

  recordDeleted(record) {
    Logger.debug("Record deleted")
    this.notifyRecordChange(record)
  }

  notifyRecordChange(record) {
    this.selectRecord(record)
    this.loadRecords() // We just crudely reload all the records

    // We crudely just clear the entire cache so that the IRR reports and other financial data will be updated
    API.clearCache()
  }

  // A 'new' record is an empty object
  newRecord(record) {
    if (this.props.project) {
      const autoFill = [this.props.project.code]
      const defaultSites = Object.values(this.props.project.sites)
      if (defaultSites.length === 1) {
        autoFill.push(defaultSites[0].code)
        const defaultSystems = Object.values(defaultSites[0].systems)
        if (defaultSystems.length === 1) {
          autoFill.push(defaultSystems[0].code)
        }
      }
      this.selectRecord({ path: `/${autoFill.join("/")}`, ...record })
    } else {
      this.selectRecord(record)
    }
  }

  loadRecordSettings() {
    this.setStateIfMounted({
      loading: " ",
      error: null,
    })

    const projectId = this.props.project.code
    RecordService.getProjectRecordSettings(projectId)
      .then((settings) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            settings: settings,
            loading: "",
          })
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            error: "Error while retrieving record settings for project " + projectId,
          })
        }
      })
  }

  saveRecordSettings(settings) {
    // Instead of having the function loadRecordSettings after awaiting the promise
    // Visually update N/A immediately and persist data change in the background
    this.setStateIfMounted({
      settings: settings,
      error: null,
    })

    RecordService.updateProjectRecordSettings(this.props.project.code, settings).catch((err) => {
      Logger.error(err)
      this.setStateIfMounted({
        error: getErrorMessage(err),
        settings: settings,
      })
    })
  }

  renderViewControls() {
    if (this.state.record || this.props.document) {
      return null // Don't show the controls and "New Record" button when a record is selected
    }

    return (
      <div className="content-view-controls">
        {this.props.groups.includes("data-write") ? (
          <ButtonToolbar className="float-end">
            <ButtonGroup className="header-button-group">
              <Button
                onClick={() => {
                  this.newRecord({})
                }}
                size="sm"
                color="ecogy"
              >
                {t("buttons.new_record")}
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ) : null}

        <ButtonToolbar className="float-start">
          {/* Render Record Type Filters */}
          <TypeFilter
            typeHierarchy={this.state.typeHierarchy}
            selectedTypes={this.state.selectedTypes}
            toggleType={this.toggleRecordType}
            toggleSubType={this.toggleRecordSubType}
          />

          {/* Render Ghost Record Toggles */}
          {this.props.project && (
            <ButtonGroup className="type-filter__ghost-records">
              <Toggle
                defaultChecked={this.state.showUnmadeRecords}
                onChange={() => {
                  this.setStateIfMounted((prev) => ({ showUnmadeRecords: !prev.showUnmadeRecords }))
                }}
              />
              <span>{t("buttons.show_unmade")}</span>
              <Toggle
                defaultChecked={this.state.showNonApplicableRecords}
                onChange={() => {
                  this.setStateIfMounted((prev) => ({ showNonApplicableRecords: !prev.showNonApplicableRecords }))
                }}
              />
              <span>{t("buttons.show_N/A")}</span>
            </ButtonGroup>
          )}

          {/* Render Notifications Button */}
          <RecordNotifications project={this.props.project} userId={this.props.userId} />
        </ButtonToolbar>
      </div>
    )
  }

  toggleRecordType(e, type) {
    e.preventDefault()
    e.stopPropagation()
    let selectedTypes = this.state.selectedTypes

    let selected = Object.keys(selectedTypes[type]).find((subType) => !selectedTypes[type][subType]) === undefined

    Object.keys(selectedTypes[type]).forEach((subType) => {
      selectedTypes[type][subType] = !selected
    })

    this.setStateIfMounted({ selectedTypes: selectedTypes })
    sessionStorage.setItem("selectedTypes", JSON.stringify(selectedTypes))
  }

  toggleRecordSubType(e, type, subType) {
    e.preventDefault()
    e.stopPropagation()
    let selectedTypes = this.state.selectedTypes
    selectedTypes[type][subType] = !selectedTypes[type][subType]

    this.setStateIfMounted({ selectedTypes: selectedTypes })
    sessionStorage.setItem("selectedTypes", JSON.stringify(selectedTypes))
  }

  renderMainView() {
    if (this.state.loading) {
      return <Loading message={this.state.loading} />
    }
    if (this.state.record) {
      return (
        <RecordDetails
          restrictions={this.props.restrictions}
          userName={this.props.userName}
          groups={this.props.groups}
          autoCompletes={this.props.autoCompletes}
          record={this.state.record}
          projects={this.props.projects}
          project={this.props.project}
          selectRecord={this.selectRecord}
          selectNextRecord={this.selectNextRecord}
          recordAdded={this.recordAdded}
          recordUpdated={this.recordUpdated}
          recordDeleted={this.recordDeleted}
          viewableRecordTypes={this.state.viewableRecordTypes}
          disabledSubTypes={this.state.disabledSubTypes}
          readonly={this.isReadOnly() || this.state.readonly}
          typeHierarchy={this.state.typeHierarchy}
        />
      )
    } else if (this.isContentError(this.state.records)) {
      return <Error error={this.state.records.getError()} />
    } else if (this.isContentValid(this.state.records)) {
      return (
        <RecordList
          records={this.getRecords()}
          project={this.props.project}
          projects={this.props.projects}
          groups={this.props.groups}
          recordFilters={this.state.selectedTypes}
          recordSubTypeNames={this.state.recordSubTypeNames}
          viewableRecordTypes={this.state.viewableRecordTypes}
          naRecords={this.state.settings ? this.state.settings.naRecords : {}}
          typeHierarchy={this.state.typeHierarchy}
          showUnmadeRecords={this.state.showUnmadeRecords}
          showNonApplicableRecords={this.state.showNonApplicableRecords}
          actions={{
            newRecord: this.newRecord,
            selectRecord: this.selectRecord,
            saveSettings: this.saveRecordSettings,
          }}
        />
      )
    } else {
      return <Loading />
    }
  }

  renderContent() {
    if (this.state.record && this.state.readonly) {
      return super.renderLayout() // Make sure there are no controls or space for controls rendered
    } else {
      return super.renderContent()
    }
  }

  getRecords() {
    if (this.state.records && this.props.project) {
      return this.getFilteredRecords().filter((record) => {
        let projectId = ProjectUtils.getProjectCode(record.path)
        return this.props.project.code === projectId
      })
    } else if (this.state.records && this.props.projects) {
      return this.getFilteredRecords().filter((record) => {
        let projectId = ProjectUtils.getProjectCode(record.path)
        return this.props.projects.find((project) => project.code === projectId)
      })
    } else {
      return []
    }
  }

  getFilteredRecords() {
    if (this.state.selectedTypes) {
      return this.state.records.filter((record) => {
        if (this.state.selectedTypes[record.recordType]) {
          return this.state.selectedTypes[record.recordType][record.recordSubType]
        }
      })
    }
    return this.state.records
  }
}
