import * as React from "react"
import SelectComponent from "react-select"
import CreatableSelect from "react-select/creatable"
import { components } from "react-select"
import { CaretSortIcon, CheckIcon, Cross2Icon as CloseIcon } from "@radix-ui/react-icons"
import { FixedSizeList as List } from "react-window"
import type {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MenuListProps,
  MenuProps,
  MultiValueRemoveProps,
  OptionProps,
  Props,
  ClassNamesConfig,
  GroupBase,
  StylesConfig,
} from "react-select"
import { type CreatableAdditionalProps } from "react-select/dist/declarations/src/useCreatable"
import { uniqueId } from "lodash"

const cn = (...args: any[]) => args.filter(Boolean).join(" ")

const controlStyles = {
  base: "tw-flex tw-!min-h-9 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-transparent tw-pl-3 tw-py-1 tw-pr-1 tw-gap-1 tw-text-sm tw-shadow-sm tw-transition-colors hover:tw-cursor-pointer",
  focus: "tw-outline-none tw-ring-1 tw-ring-ring",
  disabled: "tw-cursor-not-allowed tw-opacity-50",
}
const placeholderStyles = "tw-text-sm tw-text-muted-foreground"
const valueContainerStyles = "tw-gap-1"
const multiValueStyles =
  "tw-inline-flex tw-items-center tw-gap-2 tw-rounded-md tw-border tw-border-transparent tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/80 tw-px-1.5 tw-py-0.5 tw-text-xs tw-font-semibold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2"
const indicatorsContainerStyles = "tw-gap-1"
const clearIndicatorStyles = "tw-p-1 tw-rounded-md"
const indicatorSeparatorStyles = "tw-bg-border"
const dropdownIndicatorStyles = "tw-p-1 tw-rounded-md"
const menuStyles =
  "tw-p-1 tw-mt-1 tw-border tw-bg-popover tw-shadow-md tw-rounded-md tw-text-popover-foreground tw-z-[9999]"
const groupHeadingStyles = "tw-py-2 tw-px-1 tw-text-secondary-foreground tw-text-sm tw-font-semibold"
const optionStyles = {
  base: "hover:tw-cursor-pointer hover:tw-bg-accent hover:tw-text-accent-foreground  tw-z-[9999] tw-px-2 tw-py-1.5 tw-rounded-sm tw-!text-sm tw-!cursor-default tw-!select-none tw-!outline-none tw-font-sans",
  focus: "active:tw-bg-accent/90 tw-bg-accent tw-text-accent-foreground",
  disabled: "tw-pointer-events-none tw-opacity-50",
  selected: "",
}
const noOptionsMessageStyles =
  "tw-text-accent-foreground tw-p-2 tw-bg-accent tw-border tw-border-dashed tw-border-border tw-rounded-sm"
const loadingIndicatorStyles = "tw-flex tw-items-center tw-justify-center tw-h-4 tw-w-4 tw-opacity-50"
const loadingMessageStyles = "tw-text-accent-foreground tw-p-2 tw-bg-accent"

const createClassNames = (classNames: ClassNamesConfig): ClassNamesConfig => {
  return {
    clearIndicator: (state) => cn(clearIndicatorStyles, classNames?.clearIndicator?.(state)),
    container: (state) => cn(classNames?.container?.(state)),
    control: (state) =>
      cn(
        controlStyles.base,
        state.isDisabled && controlStyles.disabled,
        state.isFocused && controlStyles.focus,
        classNames?.control?.(state),
      ),
    dropdownIndicator: (state) => cn(dropdownIndicatorStyles, classNames?.dropdownIndicator?.(state)),
    group: (state) => cn(classNames?.group?.(state)),
    groupHeading: (state) => cn(groupHeadingStyles, classNames?.groupHeading?.(state)),
    indicatorsContainer: (state) => cn(indicatorsContainerStyles, classNames?.indicatorsContainer?.(state)),
    indicatorSeparator: (state) => cn(indicatorSeparatorStyles, classNames?.indicatorSeparator?.(state)),
    input: (state) => cn(classNames?.input?.(state)),
    loadingIndicator: (state) => cn(loadingIndicatorStyles, classNames?.loadingIndicator?.(state)),
    loadingMessage: (state) => cn(loadingMessageStyles, classNames?.loadingMessage?.(state)),
    menu: (state) => cn(menuStyles, classNames?.menu?.(state)),
    menuList: (state) => cn(classNames?.menuList?.(state)),
    menuPortal: (state) => cn(classNames?.menuPortal?.(state)),
    multiValue: (state) => cn(multiValueStyles, classNames?.multiValue?.(state)),
    multiValueLabel: (state) => cn(classNames?.multiValueLabel?.(state)),
    multiValueRemove: (state) => cn(classNames?.multiValueRemove?.(state)),
    noOptionsMessage: (state) => cn(noOptionsMessageStyles, classNames?.noOptionsMessage?.(state)),
    option: (state) =>
      cn(
        optionStyles.base,
        state.isFocused && optionStyles.focus,
        state.isDisabled && optionStyles.disabled,
        state.isSelected && optionStyles.selected,
        classNames?.option?.(state),
      ),
    placeholder: (state) => cn(placeholderStyles, classNames?.placeholder?.(state)),
    singleValue: (state) => cn(classNames?.singleValue?.(state)),
    valueContainer: (state) => cn(valueContainerStyles, classNames?.valueContainer?.(state)),
  }
}

const defaultClassNames = createClassNames({})

const defaultStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
  input: (base) => ({
    ...base,
    "input:focus": {
      boxShadow: "none",
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    whiteSpace: "normal",
    overflow: "visible",
  }),
  control: (base) => ({
    ...base,
    transition: "none",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      background: "transparent",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "hsl(var(--tw-border))",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "transparent",
    },
  }),
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretSortIcon className={"tw-h-4 tw-w-4 tw-opacity-50"} />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon className={"tw-h-3.5 tw-w-3.5 tw-opacity-50"} />
    </components.ClearIndicator>
  )
}

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon className={"tw-h-3 tw-w-3 tw-opacity-50"} />
    </components.MultiValueRemove>
  )
}

const Option = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <div className="tw-flex tw-items-center tw-justify-between">
        <div>{(props.data as { label: string }).label}</div>
        {props.isSelected && <CheckIcon />}
      </div>
    </components.Option>
  )
}

const Menu = (props: MenuProps) => {
  return <components.Menu {...props}>{props.children}</components.Menu>
}

const MenuList = (props: MenuListProps) => {
  const { children, maxHeight } = props

  const childrenArray = React.Children.toArray(children)

  const calculateHeight = () => {
    const totalHeight = childrenArray.length * 35
    return totalHeight < maxHeight ? totalHeight : maxHeight
  }

  const height = calculateHeight()

  if (!childrenArray || childrenArray.length - 1 === 0) {
    return <components.MenuList {...props} />
  }
  return (
    <List height={height} itemCount={childrenArray.length} itemSize={35} width="100%">
      {({ index, style }) => <div style={style}>{childrenArray[index]}</div>}
    </List>
  )
}

const Select = React.forwardRef<
  React.ElementRef<typeof SelectComponent>,
  React.ComponentPropsWithoutRef<typeof SelectComponent>
>((props: Props, ref) => {
  const {
    value,
    onChange,
    options = [],
    styles = defaultStyles,
    classNames = defaultClassNames,
    components = {},
    ...rest
  } = props

  const id = uniqueId()

  return (
    <SelectComponent
      instanceId={id}
      ref={ref}
      value={value}
      onChange={onChange}
      options={options}
      unstyled
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        Option,
        Menu,
        MenuList,
        ...components,
      }}
      styles={styles}
      classNames={classNames}
      {...rest}
    />
  )
})

Select.displayName = "Select"

const Creatable = React.forwardRef<
  React.ElementRef<typeof CreatableSelect>,
  React.ComponentPropsWithoutRef<typeof CreatableSelect>
>((props: Props, ref) => {
  const {
    value,
    onChange,
    options = [],
    styles = defaultStyles,
    classNames = defaultClassNames,
    components = {},
    ...rest
  } = props

  const id = uniqueId()

  return (
    <CreatableSelect
      instanceId={id}
      ref={ref}
      value={value}
      onChange={onChange}
      options={options}
      unstyled
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        Option,
        Menu,
        MenuList,
        ...components,
      }}
      styles={styles}
      classNames={classNames}
      {...rest}
    />
  )
})

Creatable.displayName = "Creatable"

const useFormatters = () => {
  const formatCreateLabel: CreatableAdditionalProps<unknown, GroupBase<unknown>>["formatCreateLabel"] = (label) => (
    <span className={"tw-text-sm"}>
      Add
      <span className={"tw-font-semibold"}>{` "${label}"`}</span>
    </span>
  )

  const formatGroupLabel: (group: GroupBase<unknown>) => React.ReactNode = (data) => (
    <div className={"tw-flex tw-items-center tw-justify-between"}>
      <span>{data.label}</span>
      <span
        className={
          "tw-rounded-md tw-bg-secondary tw-px-1 tw-text-xs tw-font-normal tw-text-secondary-foreground tw-shadow-sm"
        }
      >
        {data.options.length}
      </span>
    </div>
  )

  return {
    formatCreateLabel,
    formatGroupLabel,
  }
}

export { Select, Creatable, useFormatters }
