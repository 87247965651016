import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"

/**
 * Import to pro forma.
 * @param projectId - The project ID.
 * @param mergeMethod - The merge method.
 */
export const importToProForma = (projectId: string, mergeMethod: string): Promise<unknown> => {
  return API.put(`/import/to-pro-forma/${projectId}`, { mergeMethod: mergeMethod })
    .then()
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to import from financial model: ${getErrorMessage(err)}` })
    })
}
