import { Input, InputGroup } from "reactstrap"
import React, { ChangeEvent } from "react"
import Fuse from "fuse.js"
import ClearButton from "./ClearButton"
import i18n from "src/i18n"

const { t } = i18n
export interface SearchGroupProps {
  searchTerm: string
  searchTermResults: Array<unknown>
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void
  onEnter: (result: unknown) => void
  onClear: () => void
  searchPlaceholder?: string
}

/**
 * A simple search box.
 * @param props
 * @constructor
 */
export const SearchGroup = (props: SearchGroupProps): JSX.Element => {
  return (
    <>
      <InputGroup size="sm">
        <Input
          placeholder={props.searchPlaceholder ?? `${t("labels.search")}...`}
          value={props.searchTerm ? props.searchTerm : ""}
          onChange={(event) => props.onSearch.call(this, event)}
          on
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              if (props.searchTermResults) {
                props.onEnter.call(this, props.searchTermResults[0])
              }
            }
          }}
        />
        <ClearButton onClear={props.onClear} />
      </InputGroup>
    </>
  )
}

/**
 * A simple fuzzy search using a list of strings and a string input.
 *
 * Note that the returned result is always ordered by value, so it can be
 * iterated without needing to be sorted (assuming that the most likely
 * result is preferred first).
 * @param array - The array to search.
 * @param input - The string input to compare ot the list.
 * @param options - The fuse options.
 * @returns {Fuse.FuseResult<unknown>[]} - The search result.
 */
export const fuzzySearchStringList = (
  array: unknown[],
  input: string,
  options: Fuse.IFuseOptions<unknown> = {
    includeScore: true,
    threshold: 0.2,
  },
): Fuse.FuseResult<unknown>[] => {
  const fuse = new Fuse(array, options)
  return fuse.search(input)
}

/**
 * Fuzzy search all possible results.
 * @param event - The incoming event.
 * @param allPossibleResults - All possible results.
 */
export const fuzzySearchResults = (event: ChangeEvent<HTMLInputElement>, allPossibleResults: string[]): { searchTerm: string; searchTermResults: string[] | Fuse.FuseResult<unknown>[] } => {
  const value = event.target.value
  const terms: Fuse.FuseResult<unknown>[] = []

  // If the user has input some value, then run the fuzzy search.
  if (value) {
    const results = fuzzySearchStringList(allPossibleResults, value)

    results.forEach((result: Fuse.FuseResult<unknown>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      terms.push(result.item)
    })
  }

  return {
    searchTerm: value,
    searchTermResults: value ? terms : allPossibleResults,
  }
}

export default SearchGroup
