import React from "react"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import PortoflioCashFlowDates from "./PortoflioCashFlowDates"

import PortfolioCashFlowDifferences from "./PortfolioCashFlowDifferences"

import "../IRRReportContent.css"
import i18n from "src/i18n"

const END_PADDING = 10 //px
const { t } = i18n

export default class PortfolioIRRReportContent extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state.paths = new Set()

    this.toggleProjects = this.toggleProjects.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.report !== prevProps.report) {
      // We clear out any error state that may heve been recorded for the previous report
      this.setStateIfMounted({ hasError: false, error: undefined })
    }
  }

  toggleProjects(path) {
    const paths = this.state.paths
    if (this.state.paths.has(path)) {
      paths.delete(path)
    } else {
      paths.add(path)
    }
    this.setStateIfMounted({ paths: paths })
  }

  isPathEnabled(path) {
    return this.state.paths.has(path)
  }

  renderContent() {
    if (this.props.report) {
      const numColumns = this.props.columns.length ? this.props.columns.length : 1
      const lineWidth = this.props.report.dates.length * numColumns * 100 + 500 + END_PADDING
      return (
        <div className="irr-report multi-report">
          <div className={this.props.aggregate} id="irr-report">
            <div className={`multi-report-content${this.getColumnClasses()} columns-${numColumns}`} style={{ width: `${lineWidth}px` }}>
              <PortoflioCashFlowDates dates={this.props.report.dates} aggregate={this.props.aggregate} />
              <PortfolioCashFlowDifferences projects={this.props.projects} report={this.props.report} toggleProjects={this.toggleProjects} paths={this.state.paths} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <Loading message={t("loadingMsg.loading_reports")} />
        </React.Fragment>
      )
    }
  }

  getColumnClasses() {
    return (
      this.getColumnClass("forecast") +
      this.getColumnClass("expected") +
      this.getColumnClass("actual") +
      this.getColumnClass("latestBestEstimate") +
      this.getColumnClass("difference") +
      (this.props.showAccounts ? "" : " hide-accounts") +
      (this.props.showAccounts && this.props.showSubAccounts ? "" : " hide-sub-accounts")
    )
  }

  getColumnClass(column) {
    return this.props.columns.indexOf(column) < 0 ? " hide-" + column : ""
  }
}
