import { Auth } from "aws-amplify"
import aws from "aws-sdk"

import API from "@common/API"
import { getUserOrganizationId } from "@common/OrganizationUtils"

var ReportService = {
  async listOperatingReports(portfolio) {
    const organizationId = await getUserOrganizationId()

    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Prefix: `${organizationId}/report/operating/${portfolio.id}/`,
      }

      return s3.listObjects(params).promise()
    })
  },

  /**
   * @param portfolio
   * @param {{
   *  sendEmail: Boolean,
   *  targetMonth: dateString,
   * }} args
   */
  async generateReport(portfolio, args) {
    return API.post(`/report/operating/portfolio/${portfolio.id}?sendEmail=${args.sendEmail}&targetMonth=${args.targetMonth}`)
  },

  async viewFile(key) {
    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Key: key,
      }

      return s3.getSignedUrlPromise("getObject", params)
    })
  },

  downloadFile(key) {
    Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Key: key,
      }

      s3.getSignedUrl("getObject", params, (err, url) => {
        window.open(url)
      })
    })
  },
}

export default ReportService
