import React from "react"
import { Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import CashFlowTableData from "./CashFlowTableData"
import { Button, ButtonGroup } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"
import { Trans } from "react-i18next"

const { t } = i18n
class PortfolioCashFlowPaymentLogsView extends EcosuiteView {
  constructor(props) {
    super(props)
    this.state = {
      filter: "expected",
    }
  }

  renderMainView() {
    return (
      <CashFlowTableData
        projects={this.props.projects.map((p) => ({ id: p.code, name: p.name }))}
        filter={this.state.filter}
      />
    )
  }

  renderViewControls() {
    return (
      <div>
        <ButtonGroup className="header-button-group" style={{ paddingTop: "8px" }}>
          <Button
            className={this.state.filter === "expected" ? "selected" : null}
            onClick={() => {
              this.setState({ filter: "expected" })
            }}
            id="expected_button__cashflow"
            size="sm"
          >
            {t("labels.expected")}
          </Button>
          <Button
            className={this.state.filter === "advised" ? "selected" : null}
            onClick={() => {
              this.setState({ filter: "advised" })
            }}
            id="advised_button__cashflow"
            size="sm"
          >
            {t("labels.advised")}
          </Button>
        </ButtonGroup>
        <UncontrolledTooltip placement={"top"} target="expected_button__cashflow">
          <Trans i18nKey="economics.tooltips.amount_expected">
            Amount <b>expected</b> to be owed (as calculated by us)
          </Trans>
        </UncontrolledTooltip>
        <UncontrolledTooltip placement={"top"} target="advised_button__cashflow">
          <Trans i18nKey="economics.tooltips.amount_owed">
            Amount owed as <b>advised</b> by the debtor
          </Trans>
        </UncontrolledTooltip>
      </div>
    )
  }
}

export default PortfolioCashFlowPaymentLogsView
