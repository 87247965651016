import * as React from "react"

interface ProgressBarProps {
  value: number
  max: number
  percentify: boolean
}

export default function ProgressBar(props: ProgressBarProps) {
  const { value, max, percentify } = props

  const displayValue = percentify ? `${Math.floor((value / max) * 100)}%` : `${value}/${max}`

  return (
    <div className="progress-bar" role="progressbar" style={{ width: `${(value / max) * 100}%` }} aria-valuenow={value} aria-valuemin={0} aria-valuemax={max}>
      {displayValue}
    </div>
  )
}
