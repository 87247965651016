import React from "react"
import { Button, Row, Col, Alert } from "reactstrap"

import EcosuiteView from "@common/module/EcosuiteView"

import "react-datetime/css/react-datetime.css"
import { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm from "@common/form/EcosuiteForm"
import moment from "moment"
import Datetime from "react-datetime"
import { DualListBox } from "@common/input/select/DualListBox"
import DRService from "../DRService"
import { deepEquals } from "@rjsf/core/lib/utils"
import i18n from "src/i18n"

const { t } = i18n
class DREventDetailsView extends EcosuiteView {
  constructor(props) {
    super(props)
    this.ArrayComponent = this.ArrayComponent.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
    this.validate = this.validate.bind(this)

    this.state = {
      loading: true,
      alert: {
        text: null,
        color: null,
      },
      formLoading: false,
    }

    Promise.all([DRService.getEventSchema(), DRService.listParticipants()]).then(([schema, participants]) => {
      // Sort by code
      participants.sort((a, b) => a.code.localeCompare(b.code))

      const options = participants.map((participant) => {
        const name = this.props.allProjects.find((p) => p.code === participant.code).name
        return {
          getID() {
            return participant.code
          },
          getValues() {
            return [participant.code, name]
          },
        }
      })

      // If we are editing an existing event, we need to select from options the currently selected participants for
      // this event
      const selected = this.props.event.participants
        ? options
            .map((v, i) => i)
            .filter((index) => {
              // If this index is part of the currently selected participants, include it
              for (const participant of this.props.event.participants) {
                if (participant === options[index].getID()) {
                  return true
                }
              }
              return false
            })
        : []

      this.setState({
        loading: false,
        schema: schema,
        participants: participants,
        options: options,
        selected: selected,
      })
    })
  }

  setInfoMessage(text) {
    this.setState({
      alert: {
        text: text,
        color: "info",
      },
    })
  }

  setSuccessMessage(text) {
    this.setState({
      alert: {
        text: text,
        color: "success",
      },
    })
  }

  setErrorMessage(text) {
    this.setState({
      alert: {
        text: text,
        color: "danger",
      },
    })
  }

  ArrayComponent(props) {
    return (
      <div className={props.className}>
        <fieldset>
          <legend id={props.idSchema.$id}>
            {props.title}
            {props.required ? <span className="required">*</span> : null}
          </legend>
          <DualListBox fields={["Code", "Name"]} options={props.uiSchema["ui:options"]} selected={props.uiSchema["ui:selected"]} onChange={(items) => props.onChange(items)} />
        </fieldset>
      </div>
    )
  }

  isReadOnly() {
    return false
  }

  componentDidMount() {
    super.componentDidMount()

    let formData = this.props.event
    this.setState({ formData: formData })
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  getDateWidget(props) {
    let date = props.value ? moment(props.value) : null
    return (
      <div>
        <Datetime
          value={date}
          dateFormat="ll"
          timeFormat="HH:mm:ss"
          inputProps={{
            disabled: false, // EP-657 prevent certain SolarNetwork properties from being edited
          }}
          onChange={(date) => {
            if (typeof date === "object") {
              props.onChange(date ? date.toISOString() : "")
            }
          }}
        />
        {null}
      </div>
    )
  }

  getUiSchema() {
    return {
      startDate: {
        "ui:widget": (props) => {
          return this.getDateWidget(props)
        },
      },
      endDate: {
        "ui:widget": (props) => {
          return this.getDateWidget(props)
        },
      },
      notificationDate: {
        "ui:widget": (props) => {
          return this.getDateWidget(props)
        },
      },
      participants: {
        "ui:options": this.state.options,
        "ui:selected": this.state.selected,
      },
      notificationSent: {
        "ui:readonly": true,
      },
    }
  }

  validate(formData, errors) {
    const start = moment(formData.startDate)
    const end = moment(formData.endDate)
    const notification = moment(formData.notificationDate)

    if (start > end) {
      errors.addError(t("demand.messages.wrong_event_end_date"))
    }

    if (notification > start) {
      errors.addError(t("demand.messages.wrong_event_notification"))
    }

    return errors
  }

  doSubmit(form) {
    const creating = this.props.event.id === undefined

    this.setInfoMessage(creating ? `${t("demand.messages.creating_DR_event")}` : `${t("demand.messages.updating_DR_event")}`)
    this.setState({ formLoading: true })

    const promise = creating ? DRService.createEvent(form.formData) : DRService.putEvent(this.props.event.id, form.formData)

    if (!deepEquals(this.state.formData, form.formData)) {
      form.formData.notificationSent = false
    }

    this.setState({
      formData: form.formData,
    })

    promise
      .then((id) => {
        this.props.fetchEvents()
        return id
      })
      .then((id) => {
        this.setSuccessMessage((creating ? `${t("demand.messages.created_DR_event")}: ` : `${t("demand.messages.updating_DR_event")}: `) + id)

        this.setState({ formLoading: false })

        if (creating) {
          this.props.onCancel()
        }
      })
      .catch((e) => {
        this.setErrorMessage((creating ? `${t("demand.messages.failed_to_create_DR_event")}: ` : `${t("demand.messages.failed_to_update_DR_event")}: `) + e)
        this.setState({ formLoading: false })
      })
  }

  deleteEvent(e) {
    e.preventDefault()
    this.setInfoMessage(t("demand.messages.deleting_DR_event"))
    this.setState({ formLoading: true })

    DRService.deleteEvent(this.props.event.id)
      .then(() => this.props.onClose())
      .catch((e) => {
        this.setErrorMessage(`${t("demand.messages.failed_to_delete_DR_event")}: ` + e)
        this.setState({ formLoading: false })
      })
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }

    return (
      <div className="item-details">
        <div className={"item-details-title " + (this.state.formData.name ? "item-edit-title" : "item-create-title")}>
          <h2>{this.state.formData.name ? this.state.formData.name : `${t("demand.messages.create_DR_event")}`}</h2>
        </div>

        <div className="dr-event-details-content">
          {this.state.alert.text && <Alert color={this.state.alert.color}>{this.state.alert.text}</Alert>}

          <Row>
            <Col>
              {this.state.formLoading ? (
                <Loading />
              ) : (
                <EcosuiteForm
                  className="ecogy-form"
                  schema={this.state.schema}
                  formData={this.state.formData}
                  uiSchema={this.getUiSchema()}
                  onSubmit={(form) => this.doSubmit(form)}
                  onChange={this.onFormChange}
                  fields={{ ArrayField: this.ArrayComponent }}
                  disabled={this.isReadOnly()}
                  validate={this.validate}
                >
                  <Row className="ecogy-form-buttons">
                    {this.state.formData.id ? (
                      <React.Fragment>
                        <Col className="button-section" sm="12">
                          <Button color="primary" type="submit" disabled={this.isReadOnly()}>
                            {t("buttons.update")}
                          </Button>
                          <Button color="danger" type="submit" onClick={this.deleteEvent} disabled={this.isReadOnly()}>
                            {t("buttons.delete")}
                          </Button>
                          <Button color="secondary" type="button" onClick={this.props.onCancel}>
                            {t("buttons.cancel")}
                          </Button>
                        </Col>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Col className="button-section" sm="12">
                          <Button color="primary" type="submit" disabled={this.isReadOnly()}>
                            {t("buttons.create")}
                          </Button>
                          <Button color="secondary" type="button" onClick={this.props.onCancel}>
                            {t("buttons.cancel")}
                          </Button>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                </EcosuiteForm>
              )}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default DREventDetailsView
