import React from "react"
import moment from "moment"
import { Button } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import OrganizationService from "../OrganizationAdminService"

import "./OrganizationList.css"
import i18n from "src/i18n"

const { t } = i18n
const organizationTablePadding = "20px"

export default class OrganizationList extends EcosuiteComponent {
  componentDidMount() {
    super.componentDidMount()

    // The parent component can either provide a list of organizations, or we can fetch them ourselves
    if (this.props.organizations) {
      this.applyOrganizations(this.props.organizations)
    } else {
      this.loadOrganizations()
    }
  }

  loadOrganizations() {
    this.setStateIfMounted({
      organizations: null,
    })

    OrganizationService.getOrganizations()
      .then((response) => {
        const organizations = response.Items.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })

        return this.applyOrganizations(organizations)
      })
      .then((id) => {
        if (id) {
          this.props.selectOrganization(id)
        }
      })
  }

  applyOrganizations(organizations) {
    const defaultOrganization = this.props.organizationId ? organizations.find((organization) => organization.id === this.props.organizationId) : null

    this.setState({
      organizations: organizations,
      selectedOrganization: defaultOrganization,
    })

    return defaultOrganization ? defaultOrganization.id : null
  }

  isRegistrationExpired(organization) {
    return moment().isAfter(moment(organization.updated).add(7, "days")) && organization.status === "FORCE_CHANGE_PASSWORD"
  }

  render() {
    if (this.state.organizations && this.state.organizations.length == 0) {
      return <p>{t("settings.messages.no_organizations_exist")}</p>
    } else if (this.state.organizations) {
      return (
        <div className="organizationlist-content">
          <table className="organization-table">
            <thead>
              <tr>
                <th>{t("table_headings.organization_name")}</th>
                {this.props.extended && !this.state.selectedOrganization ? (
                  <React.Fragment>
                    <th>{t("table_headings.created")}</th>
                    <th>{t("table_headings.updated")}</th>
                  </React.Fragment>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {(this.props.searchTermResults ?? this.state.organizations).map((organization) => {
                return (
                  <tr key={organization.id}>
                    <td
                      title={`Organization: ${organization.name}`}
                      className={"active"}
                      style={{
                        paddingRight: this.state.selectedOrganization ? "0px" : organizationTablePadding,
                      }}
                      onClick={() => {
                        this.setState({
                          selectedOrganization: organization,
                        })
                        this.props.selectOrganization(organization.id)
                      }}
                    >
                      <Button className="organization-button">{organization.name}</Button>
                    </td>

                    {this.props.extended && !this.state.selectedOrganization ? (
                      <React.Fragment>
                        <td>{moment(organization.created).format("lll")}</td>
                        <td>{moment(organization.updated).format("lll")}</td>
                      </React.Fragment>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
