import Icon from "@common/display/Icon"
import React, { useState } from "react"
import { Button, ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, InputGroup } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"
import "./MediaSearch.scss"

/**
 * The media search bar.
 * @param allMediaTags - All media tags.
 * @param parentSearchMedia - Search media.
 * @param enabledTagsIndex - The enabled tags index.
 * @param setEnabledTagsIndex - Set the enabled tags index.
 * @param areTagsFiltered - Check if tags are currently filtered.
 * @constructor
 */
const { t } = i18n
export const MediaSearch = ({
  allMediaTags,
  parentSearchMedia,
  enabledTagsIndex,
  setEnabledTagsIndex,
  areTagsFiltered,
}: {
  allMediaTags: MediaTagResponse[]
  parentSearchMedia: (searchTerm: string) => MediaResponse[]
  enabledTagsIndex: number[]
  setEnabledTagsIndex: (index: number[]) => void
  areTagsFiltered: boolean
}): JSX.Element => {
  const emptySearchValue = ""

  // The current search term.
  const [searchTerm, setSearchTerm] = useState<string>(emptySearchValue)
  // Whether to show the tag dropdown..
  const [dropdown, setDropdown] = useState<boolean>(false)

  /**
   * Search all media.
   * @param event - The input event.
   */
  const searchMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setSearchTerm(value)
    parentSearchMedia.call(this, value)
  }

  /**
   * Clear the media search.
   */
  const clearSearch = () => {
    setSearchTerm(emptySearchValue)
    parentSearchMedia.call(this, emptySearchValue)
  }

  /**
   * Build the tag filter dropdown.
   */
  const buildTagDropdown = () => {
    const items = []
    items.push(
      <DropdownItem header disabled={true}>
        All tags
      </DropdownItem>,
    )
    allMediaTags.forEach((tag, index) =>
      items.push(
        <DropdownItem
          key={index}
          toggle={false}
          onClick={() => {
            if (enabledTagsIndex.includes(index)) {
              // Remove the tag from the index.
              setEnabledTagsIndex.call(
                this,
                enabledTagsIndex.filter((tagIndex) => tagIndex !== index),
              )
            } else {
              // Add the tag to the index.
              setEnabledTagsIndex.call(this, enabledTagsIndex.concat([index]))
            }
          }}
        >
          <FormGroup check inline>
            <Input type={"checkbox"} checked={enabledTagsIndex.includes(index)} />
            {tag.label}
          </FormGroup>
        </DropdownItem>,
      ),
    )

    return items
  }

  return (
    <>
      <div className={"media-search content-view-controls force"}>
        <InputGroup size="sm">
          <Input placeholder={`${t("process.labels.search_media")}`} value={searchTerm} onChange={(event) => searchMedia(event)} />
          <Button
            size="sm"
            id={"media-search-clear"}
            onClick={(e) => {
              e.preventDefault()
              clearSearch()
            }}
          >
            <Icon icon="clear" />
          </Button>
          <UncontrolledTooltip target={"media-search-clear"}>{`${t("process.labels.clear_input")}`}</UncontrolledTooltip>
          <ButtonGroup size="sm" id={"media-search-filter"}>
            <ButtonDropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction={"start"}>
              <DropdownToggle caret color={areTagsFiltered ? "primary" : undefined}>
                <Icon icon={"filter_list"} />
                <DropdownMenu>{buildTagDropdown()}</DropdownMenu>
              </DropdownToggle>
            </ButtonDropdown>
          </ButtonGroup>
          <UncontrolledTooltip placeholder={"top"} target={"media-search-filter"}>{`${t("process.labels.filter_by_tags")}`}</UncontrolledTooltip>
        </InputGroup>
      </div>
    </>
  )
}

export default MediaSearch
