import React from "react"
import { Form, Input, Button, Alert, Container, TabContent, TabPane } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import { Row, Table } from "reactstrap"
import { NavLink } from "reactstrap"
import { Trans, withTranslation } from "react-i18next"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import i18n from "../../i18n"

import PortfolioList from "./portfolio/PortfolioList"
import { ParameterStore } from "./parameter-store/ParameterStore"

import SettingsService from "./SettingsService"

import "@common/shared.scss"
import { tableHeaders } from "./portfolio/constants"
import TariffView from "@dashboard/data/tariffs/TariffView"

const { t } = i18n

class SettingsAdmin extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.selectTab = this.selectTab.bind(this)
    this.updateNodeIds = this.updateNodeIds.bind(this)

    this.state = {
      activeTab: "portfolios",
    }
  }

  selectTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadNodes()
    this.loadNodeIds()
  }

  loadNodes() {
    this.setState({ nodes: null })

    SettingsService.getAllNodes()
      .then((response) => {
        this.setStateIfMounted({
          nodes: response.nodes,
        })
      })
      .catch((error) => {
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  loadNodeIds() {
    this.setState({ enabledNodes: null, commissionedNodes: null })

    SettingsService.getNodeIds()
      .then((response) => {
        this.setStateIfMounted({
          enabledNodes: response.enabledNodes,
          commissionedNodes: response.commissionedNodes,
        })
      })
      .catch((error) => {
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  updateNodeIds(enabledNodes, commissionedNodes) {
    this.setState({ enabledNodes: null, commissionedNodes: null })

    SettingsService.setNodeIds(enabledNodes, commissionedNodes)
      .then((response) => {
        this.setStateIfMounted({
          enabledNodes: response.enabledNodes,
          commissionedNodes: response.commissionedNodes,
        })
      })
      .catch((error) => {
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  renderContent() {
    return (
      <div className="Admin">
        <ul className="nav nav-tabs app-mode">
          {this.props.groups.includes("data-write") ? (
            <li className={`nav-item${this.state.activeTab === "portfolios" ? " selected" : ""}`}>
              <NavLink
                className="active nav-link"
                onClick={() => {
                  this.selectTab("portfolios")
                }}
              >
                {t("labels.portfolios")}
              </NavLink>
            </li>
          ) : null}
          {this.props.groups.includes("solarnetwork") ? (
            <li className={`nav-item${this.state.activeTab === "nodes" ? " selected" : ""}`}>
              <NavLink
                className="active nav-link"
                onClick={() => {
                  this.selectTab("nodes")
                }}
              >
                {t("labels.solarNetwork")}
              </NavLink>
            </li>
          ) : null}
          {this.props.groups.includes("power-user") ? (
            <li className={`nav-item${this.state.activeTab === "constants" ? " selected" : ""}`}>
              <NavLink
                className="active nav-link"
                onClick={() => {
                  this.selectTab("constants")
                }}
              >
                {t("labels.constants")}
              </NavLink>
            </li>
          ) : null}
          <li className={`nav-item${this.state.activeTab === "tariff" ? " selected" : ""}`}>
            <NavLink
              className="active nav-link"
              onClick={() => {
                this.selectTab("tariff")
              }}
            >
              Tariff
            </NavLink>
          </li>
        </ul>

        <TabContent activeTab={this.state.activeTab}>
          {this.props.groups.includes("data-write") ? (
            <TabPane tabId="portfolios">
              <Container fluid={true} className="Admin-content">
                <div className="admin-content admin-content-area">
                  <PortfolioList {...this.props} />
                </div>
              </Container>
            </TabPane>
          ) : null}
          {this.props.groups.includes("solarnetwork") ? (
            <TabPane tabId="nodes">
              <Container fluid={true} className="Admin-content">
                <div className="admin-content admin-content-area">{this.renderNodes()}</div>
              </Container>
            </TabPane>
          ) : null}

          {this.props.groups.includes("power-user") ? (
            <TabPane tabId="constants">
              <ParameterStore />
            </TabPane>
          ) : null}

          <TabPane tabId="tariff">
            <TariffView />
          </TabPane>
        </TabContent>
      </div>
    )
  }

  renderNodes() {
    if (this.state.nodes && this.state.enabledNodes && this.state.commissionedNodes) {
      return (
        <NodeTable
          nodes={this.state.nodes}
          enabledNodes={this.state.enabledNodes}
          commissionedNodes={this.state.commissionedNodes}
          updateNodeIds={this.updateNodeIds}
        />
      )
    } else {
      return <Loading />
    }
  }
}

class NodeTable extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.toggleNodeId = this.toggleNodeId.bind(this)
    this.updateNodeIds = this.updateNodeIds.bind(this)

    this.state = {
      enabledNodes: this.props.enabledNodes,
      commissionedNodes: this.props.commissionedNodes,
      error: this.props.error,
      tooltipOpen: false,
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.state.enabledNodes = this.props.enabledNodes
    this.state.commissionedNodes = this.props.commissionedNodes
    this.state.error = this.props.error
  }

  componentDidUpdate() {
    this.state.enabledNodes = this.props.enabledNodes
    this.state.commissionedNodes = this.props.commissionedNodes
    this.state.error = this.props.error
  }

  toggleNodeId(nodeId) {
    let nodeIds = this.state.enabledNodes
    let idx = nodeIds.indexOf(nodeId)
    if (idx >= 0) {
      nodeIds.splice(idx, 1) // remove the nodeId
    } else {
      nodeIds.push(nodeId)
    }
    this.setStateIfMounted({ enabledNodes: nodeIds })
  }

  toggleCommissionedNodes(nodeId) {
    let nodeIds = this.state.commissionedNodes
    let idx = nodeIds.indexOf(nodeId)
    if (idx >= 0) {
      nodeIds.splice(idx, 1) // remove the nodeId
    } else {
      nodeIds.push(nodeId)
    }
    this.setStateIfMounted({ commissionedNodes: nodeIds })
  }

  updateNodeIds(e) {
    e.preventDefault()
    this.props.updateNodeIds(this.state.enabledNodes, this.state.commissionedNodes)
  }

  renderContent() {
    return (
      <div className="ecogy-form admin-tab-content-area">
        <Alert color="info">
          <Trans i18nKey={"settings.messages.alert_info"}>
            The Nodes available on your SolarNetwork account are listed below; you can control which ones are used in
            your org by setting the <b>Enabled</b> flag.
          </Trans>
        </Alert>
        <Form onSubmit={this.updateNodeIds}>
          <Table striped>
            <thead className="sticky">
              <tr>
                {tableHeaders.map((header) => (
                  <React.Fragment key={header.target}>
                    <th id={header.target} className={header.className && header.className}>
                      {header.content}
                    </th>
                    <UncontrolledTooltip placement={header.placement} target={header.target}>
                      {header.tooltipText}
                    </UncontrolledTooltip>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.nodes.map((node) => {
                let nodeId = node.id.toString()
                return (
                  <tr key={nodeId}>
                    <td>{nodeId}</td>
                    <td>{node.name}</td>
                    <td>{node.description}</td>
                    <td>{node.nodeLocation.id}</td>
                    <td>
                      {node.nodeLocation.locality} {node.nodeLocation.stateOrProvince} {node.nodeLocation.country}
                    </td>
                    <td>{node.node.timeZone}</td>
                    <td className={"center-table-td"}>
                      <Input
                        type="checkbox"
                        checked={this.state.enabledNodes.indexOf(nodeId) >= 0}
                        onChange={() => {
                          this.toggleNodeId(nodeId)
                        }}
                      />
                    </td>
                    <td className={"center-table-td"}>
                      <Input
                        type="checkbox"
                        checked={this.state.commissionedNodes.indexOf(nodeId) >= 0}
                        onChange={() => {
                          this.toggleCommissionedNodes(nodeId)
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Row>
            <div className="admin-footer">
              <Button color="primary" className="float-end">
                {t("buttons.save_settings")}
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    )
  }
}

export default withTranslation()(SettingsAdmin)
