import React from "react"

import { Error, Loading } from "@common/EcosuiteComponent"

import ProjectsTable from "./project/ProjectTableView"
import EcosuiteView from "@common/module/EcosuiteView"
import Settings from "@common/Settings"
import FinanceService from "../FinanceService"
import { Button, ButtonGroup, ButtonToolbar, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, UncontrolledDropdown } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"
import Logger from "@common/Logger"

const { t } = i18n

const FLAGS = {
  backend: "Back-End Cash Flows",
  debt: "Forecasted Perm Debt",
}

class FinanceContent extends EcosuiteView {
  constructor(props) {
    super(props, "finance-dashboard")

    this.state.mode = Settings.getSetting("financeDashboardMode", "forecast")
    this.state.flags = Settings.getSetting("financeDashboardFlags", ["debt"])

    this.selectMode = this.selectMode.bind(this)
    this.toggleFlag = this.toggleFlag.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadCashFlowTotals()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (this.props.loadTime !== prevProps.loadTime) {
      // A change in loadTime means that the project list has been refreshed so we should make sure we have the latest cash flow totals
      this.loadCashFlowTotals()
    }
  }

  loadCashFlowTotals() {
    this.setState(
      {
        cashFlowTotals: undefined,
      },
      () => {
        const mode = this.state.mode
        const flags = this.state.flags
        FinanceService.getCashFlowTotals(mode, flags)
          .then((response) => {
            if (mode === this.state.mode && flags === this.state.flags) {
              this.setStateIfMounted({
                cashFlowTotals: response.projectTotals,
              })
            } else {
              Logger.debug(`Ignoring out of date response for: ${mode} ${flags}`)
            }
          })
          .catch((error) => {
            this.setState({ hasError: true, error: error })
          })
      },
    )
  }

  selectMode(mode) {
    this.setStateIfMounted({ mode: mode }, () => {
      Settings.setSetting("financeDashboardMode", mode)
      this.loadCashFlowTotals()
    })
  }

  toggleFlag(event) {
    const flags = this.state.flags.filter((flag) => flag !== event.target.value) // Remove the flag from the selections
    if (event.target.checked) {
      flags.push(event.target.value) // Add the flag to the selections
    }

    this.setState({ flags: flags }, () => {
      Settings.setSetting("financeDashboardFlags", flags)
      this.loadCashFlowTotals()
    })
  }

  renderMainView() {
    if (this.isContentError(this.state.cashFlowTotals)) {
      return <Error error={this.state.cashFlowTotals.getError()} />
    } else if (this.isContentValid(this.state.cashFlowTotals)) {
      return (
        <ProjectsTable
          projects={this.props.projects}
          cashFlowTotals={this.state.cashFlowTotals}
          projectDiscountRate={this.props.projectDiscountRate}
          hasDataWriteAccess={this.props.groups.some((el) => el === "data-write")}
        />
      )
    } else {
      return <Loading />
    }
  }

  renderViewControls() {
    return (
      <div className="content-view-controls">
        <ButtonToolbar className="float-start">
          <ButtonGroup className="header-button-group">
            <Button
              className={this.state.mode === "actual" ? "selected" : null}
              onClick={() => {
                this.selectMode("actual")
              }}
              title={`${t("labels.actual")}`}
              size="sm"
            >
              {t("labels.actual")}
            </Button>
            <Button
              className={this.state.mode === "lbe" ? "selected" : null}
              onClick={() => {
                this.selectMode("lbe")
              }}
              title={`${t("labels.latest_best_estimate")}`}
              size="sm"
            >
              {t("labels.LBE")}
            </Button>
            <Button
              className={this.state.mode === "forecast" ? "selected" : null}
              onClick={() => {
                this.selectMode("forecast")
              }}
              title={`${t("labels.forecast")}`}
              size="sm"
            >
              {t("labels.forecast")}
            </Button>
          </ButtonGroup>
        </ButtonToolbar>

        <span className="report-flag-controls">
          <UncontrolledDropdown direction="up" className="float-start" size="sm">
            &nbsp;
            <DropdownToggle caret id="cash-flow-flags">
              {t("labels.show")}
            </DropdownToggle>
            <DropdownMenu>
              <Form>
                {Object.keys(FLAGS).map((flag) => {
                  return (
                    <FormGroup key={flag} check>
                      <Label check>
                        {FLAGS[flag]}
                        <Input type="checkbox" value={flag} onChange={this.toggleFlag} checked={this.state.flags?.includes(flag)} />
                      </Label>
                    </FormGroup>
                  )
                })}
              </Form>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledTooltip placement="left" target="cash-flow-flags">
            {t("economics.tooltips.select_the_flagged")}
          </UncontrolledTooltip>
        </span>
      </div>
    )
  }
}

export default FinanceContent
