import React from "react"
import { Button, Input, Tooltip } from "reactstrap"
import moment from "moment"

import { Loading, Message, Warning } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import Utils from "@common/utils/Utils"

import EventUtils from "@dashboard/event/EventUtils"
import EventService from "@dashboard/event/EventService"

import EventViewControls from "./EventViewControls"
import SelectAll, { evaluateSelectAllStatus } from "@common/input/button/SelectAll"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class EventListView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.state = { showTooltip: false, eventTableSelected: {} }

    this.resolveEvent = this.resolveEvent.bind(this)
    this.clearSelectedEvents = this.clearSelectedEvents.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.setState({
      showTooltip: true,
    })
  }

  isReadOnly() {
    return !this.props.groups.includes("event-write")
  }

  toggleEvent(event) {
    if (this.isEventSelected(event)) {
      this.props.actions.selectEvent() // deselect the event
    } else {
      this.props.actions.selectEvent(event)
    }
  }

  setTooltipEvent(event) {
    this.setStateIfMounted({
      tooltipEvent: event,
    })
  }

  isEventSelected(event) {
    return this.props.event && this.props.event.id === event.id
  }

  formatEventDate(event, date) {
    return (
      <React.Fragment>
        {moment(date).format("lll")}
        {/* <span className="event-time-local">{" (" + EventUtils.formatEventDate(this.props.projects, event, date, "Do LT") + ")"}</span> */}
      </React.Fragment>
    )
  }

  static getPriority(event) {
    switch (event.priority) {
      case 10:
        return "High"
      case 5:
        return "Medium"
      default:
        return "Low"
    }
  }

  getHashTags() {
    const events = this.props.events
    const hashtags = new Set()
    if (events && events.length > 0) {
      events.forEach((event) => {
        if (event.cause) {
          const matches = event.cause.match(/\B#\w\w+\b/g)
          if (matches) {
            matches.forEach((hashtag) => {
              hashtags.add(hashtag)
            })
          }
        }
        if (event.description) {
          const matches = event.description.match(/\B#\w\w+\b/g)
          if (matches) {
            matches.forEach((hashtag) => {
              hashtags.add(hashtag)
            })
          }
        }
        if (event.notes) {
          event.notes.forEach((note) => {
            const matches = note.note.match(/\B#\w\w+\b/g)
            if (matches) {
              matches.forEach((hashtag) => {
                hashtags.add(hashtag)
              })
            }
          })
        }
      })
    }
    return Array.from(hashtags)
      .sort()
      .filter((hashtag) => {
        return isNaN(hashtag.substring(1))
      })
  }

  convertHashttagsToLinks(text) {
    return (
      <span>
        {text.split(/\s*(#\S+)\s*/).map((part, idx) => {
          if (part.startsWith("#")) {
            return (
              <a
                key={idx}
                href="/#"
                onClick={(e) => {
                  this.props.actions.search(part)
                  e.preventDefault()
                }}
              >
                {part + " "}
              </a>
            )
          } else {
            return part + " "
          }
        })}
      </span>
    )
  }

  resolveEvent(event) {
    // We hide the tooltip to avoid a racer condition
    this.setStateIfMounted({ loading: true, tooltipEvent: null }, () => {
      EventService.resolveEvent(event.id)
        .then(() => {
          this.props.actions.eventUpdated()
          this.setStateIfMounted({ loading: false })
        })
        .catch((err) => {
          this.setStateIfMounted({ loading: false })
          alert(typeof err === "string" ? err : err.message ? err.message : JSON.stringify(err))
        })
    })
  }

  /**
   * Clear the selected events.
   */
  clearSelectedEvents() {
    this.setStateIfMounted({
      eventTableSelected: {},
    })
  }

  renderViewControls() {
    return (
      <EventViewControls
        {...this.props}
        hashtags={this.getHashTags()}
        readonly={this.isReadOnly()}
        eventTableSelected={this.state.eventTableSelected}
        clearSelectedEvents={() => {
          this.setStateIfMounted({ tooltipEvent: null })
          this.clearSelectedEvents()
        }}
      />
    )
  }

  renderResolveButton(event) {
    if (event.endDate) {
      return null
    } else {
      return (
        <Button
          color="ecogy"
          size="sm"
          title={`${t("event.messages.record_issue_as_resolved")}`}
          onClick={() => {
            this.resolveEvent(event)
          }}
        >
          {t("buttons.resolve")}
        </Button>
      )
    }
  }

  renderMainView() {
    if (this.props.groups.indexOf("event") < 0) {
      return <Warning message={t("event.messages.unable_toshow_events")} />
    }
    if (this.state.loading) {
      return <Loading />
    }
    const events = this.props.events
    if (events) {
      if (events.length > 0) {
        const tooltipEvent = this.state.tooltipEvent
        return (
          <div className="events">
            <table className="events-table">
              <thead>
                <tr>
                  <th>
                    <SelectAll
                      status={evaluateSelectAllStatus(Object.keys(events).length, Object.keys(this.state.eventTableSelected).length)}
                      allToggle={() => {
                        const currentEventTableSelected = structuredClone(this.state.eventTableSelected)

                        events.forEach((event) => {
                          currentEventTableSelected[`${event.id}`] = event
                        })

                        this.setStateIfMounted({
                          eventTableSelected: currentEventTableSelected,
                        })
                      }}
                      noneToggle={() => this.clearSelectedEvents()}
                    />
                  </th>
                  <th className="edit"></th>
                  <th>{t("table_headings.priority")}</th>
                  {this.props.types ? <th>{t("table_headings.type")}</th> : null}
                  <th>{t("table_headings.sub_type")}</th>
                  <th>{t("table_headings.cause")}</th>
                  <th>{t("table_headings.asset")}</th>
                  <th>
                    {t("table_headings.start_date")}
                    {/*<span className="event-time-local">(Local Time)</span>*/}
                  </th>
                  <th>
                    {t("table_headings.due_date")}
                    {/*<span className="event-time-local">(Local Time)</span>*/}
                  </th>
                  <th>
                    {t("table_headings.end_date")}
                    {/*<span className="event-time-local">(Local Time)</span>*/}
                  </th>
                  <th>{t("labels.created_by")}</th>
                  {this.isReadOnly() ? null : <th></th>}
                </tr>
              </thead>
              <tbody>
                {events.map((event) => {
                  let selectedClass = tooltipEvent && tooltipEvent.id === event.id ? "selected" : null
                  let unresolvedClass = event.endDate ? null : "unresolved"
                  return (
                    <tr key={event.id} className={selectedClass ? selectedClass : null} onMouseOver={() => this.setTooltipEvent(event)} onMouseOut={() => this.setTooltipEvent()}>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                        <Input
                          type={"checkbox"}
                          checked={event.id in this.state.eventTableSelected}
                          onChange={() => {
                            const currentEventTableSelected = structuredClone(this.state.eventTableSelected)

                            if (event.id in this.state.eventTableSelected) {
                              delete currentEventTableSelected[`${event.id}`]
                            } else {
                              currentEventTableSelected[`${event.id}`] = event
                            }

                            this.setStateIfMounted({
                              eventTableSelected: currentEventTableSelected,
                            })
                          }}
                        />
                      </td>
                      <td>
                        {this.isReadOnly() ? (
                          <Button
                            color="link"
                            size="sm"
                            title={`${t("event.labels.view_event")}`}
                            onClick={() => {
                              this.toggleEvent(event)
                            }}
                          >
                            <Icon icon="link" />
                          </Button>
                        ) : (
                          <Button
                            color="link"
                            size="sm"
                            title={`${t("event.labels.edit_event")}`}
                            onClick={() => {
                              this.toggleEvent(event)
                            }}
                          >
                            <Icon icon="edit" />
                          </Button>
                        )}
                      </td>
                      <td className={`event-priority event-priority-${event.priority}`}>{EventListView.getPriority(event)}</td>
                      {this.props.types ? <td className={"event-type "}>{event.type}</td> : null}
                      <td className="event-type">{Utils.splitCamelCase(event.subType ?? "None")}</td>
                      <td className={"event-cause "} id={event.id}>
                        {this.convertHashttagsToLinks(event.cause)}
                      </td>
                      <td>{event.path}</td>
                      <td>{this.formatEventDate(event, event.startDate)}</td>
                      <td className={EventUtils.isEventDue(event) ? "due" : null}>{event.dueDate ? this.formatEventDate(event, event.dueDate) : null}</td>
                      <td className={unresolvedClass}>{event.endDate ? this.formatEventDate(event, event.endDate) : t("labels.UNRESOLVED")}</td>
                      <td>{event.userName}</td>
                      {this.isReadOnly() ? null : <td className="resolve">{this.renderResolveButton(event)}</td>}
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {this.getTooltip(tooltipEvent)}
          </div>
        )
      } else {
        return <Message message={t("event.messages.no_events")} />
      }
    } else {
      return <Loading />
    }
  }

  getTooltip(tooltipEvent) {
    if (tooltipEvent) {
      // EP-861 Event tooltips are locking the browser up, this seems to be a bug in reactstrap 8, and the "modifiers" property helps mitigate the issue
      return (
        <Tooltip
          placement="bottom"
          modifiers={[
            {
              name: "preventOverflow",
              options: { boundariesElement: "viewport" },
            },
          ]}
          isOpen={this.state.showTooltip}
          target={tooltipEvent.id}
          className="event-tooltip"
        >
          <p className="event-description">{tooltipEvent.description}</p>
          {tooltipEvent.notes
            ? tooltipEvent.notes.map((note, idx) => {
                return (
                  <p className="note" key={idx}>
                    <em>{note.noteDate ? moment(note.noteDate).format("lll") + ": " : null}</em>
                    {note.note}
                  </p>
                )
              })
            : null}
        </Tooltip>
      )
    } else {
      return null
    }
  }
}
