import React from "react"
import moment from "moment-timezone"

import WeatherService from "@dashboard/energy/WeatherService"

import "./Weather.css"
import EcosuiteComponent from "@common/EcosuiteComponent"
import Logger from "@common/Logger"

class SiteWeatherLine extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      weather: [],
    }

    this.loadWeather = this.loadWeather.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadWeather()
  }

  componentDidUpdate(prevProps) {
    if (this.props.range && (this.props.range.startDate !== prevProps.range.startDate || this.props.range.endDate !== prevProps.range.endDate)) {
      this.loadWeather()
    }
  }

  loadWeather() {
    const range = this.props.range
    const projectId = this.props.project.code
    const siteId = this.props.site.code
    WeatherService.getSiteWeather(projectId, siteId, range).then((data) => {
      if (this.isRangeCurrent(range) && this.isProjectCurrent(projectId) && this.isSiteCurrent(siteId)) {
        const weatherRange = {
          startDate: moment(data.range.startDate),
          endDate: moment(data.range.endDate),
        }

        this.setState({
          weather: data.weather,
          range: weatherRange,
        })
      } else {
        Logger.debug(
          `Ignoring out of date response for project: ${projectId} ${this.isProjectCurrent(projectId)} site: ${siteId} ${this.isSiteCurrent(siteId)} range: ${range} ${this.isRangeCurrent(range)}`,
        )
      }
    })
  }

  renderContent() {
    let weatherDots = this.state.weather

    let style = {}
    let centeredPadding = 0
    if (weatherDots && this.props.centered) {
      centeredPadding = (1 / weatherDots.length / 2) * 100 + "%"
      style.marginLeft = centeredPadding
      style.marginRight = centeredPadding
    }

    return (
      <div className="weather-line" style={this.props.style}>
        <div style={style}>
          {weatherDots.map((dot, idx) => {
            let left = (idx / (weatherDots.length - 1)) * 100 + "%"

            return (
              <div key={idx} className="weather-dot" style={{ left: left }}>
                {dot.conditions ? <img src={dot.conditions.icon} alt={dot.conditions.sky} title={dot.conditions.sky} /> : null}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default SiteWeatherLine
