import React, { Component } from "react"

class ReportSection extends Component {
  getCellClass(idx) {
    if (idx === this.props.futureIdx - 1) {
      return "edge before"
    }
    if (idx < this.props.futureIdx) {
      return "before"
    }
    return "after"
  }

  formatTitle(title) {
    return title.replace(/([A-Z])/g, " $1").trim()
  }

  getColumnClass(column) {
    return this.props.columns.indexOf(column) < 0 ? "hidden" : ""
  }

  renderSpacer() {
    return this.props.columns.length < 1 ? <td></td> : null
  }

  renderFutureSpacer() {
    return this.props.columns.indexOf("latestBestEstimate") < 0 && this.props.columns.indexOf("forecast") < 0 ? <td></td> : null
  }
}

export default ReportSection
