import React from "react"
import Moment from "moment"
import { extendMoment } from "moment-range"

import { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import { LAYOUT_CONTENT_RIGHT, SideBarSection } from "@common/module/EcosuiteView"

import ConsumptionDatumUtils from "@dashboard/energy/graphs/datums/ConsumptionDatumUtils"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import EconodeUtils from "src/econode/EconodeUtils"

import EnergyView from "./EconodeEnergyView"

import SavingsGoalBarGraph from "../graphs/consumption/SavingsGoalBarGraph"
import EventLineGraph from "@dashboard/event/line-graph/EventLineGraph"
import DatumUtils from "@dashboard/energy/graphs/datums/DatumUtils"
import EnergyService from "@dashboard/energy/EnergyService"
import ConsumptionSavings from "./widgets/ConsumptionSavings"
import ConsumptionSavingsGraph from "./widgets/ConsumptionSavingsGraph"
import ForecastConsumption from "./widgets/ForecastConsumption"
import Consumption from "./widgets/Consumption"
import Utils from "@common/utils/Utils"
import { DateTime } from "luxon"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import Icon from "@common/display/Icon"
import ProgressBar from "@common/display/ProgressBar"
import i18n from "src/i18n"
import { Button, Input, Label, Modal, ModalBody } from "reactstrap"
import ProjectAdminService from "@dashboard/data/project/ProjectAdminService"
import Logger from "@common/Logger"

const { t } = i18n
const moment = extendMoment(Moment)

export default class EconodeEnergyDashboardProjectView extends EnergyView {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: props.project?.reportStartDate || "",
      isModalOpen: false,
    }
    this.openModal = this.openModal.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadRange(this.props.range)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate()

    if (this.props.project !== prevProps.project || !this.props.range.isSame(prevProps.range)) {
      this.loadRange(this.props.range)
    }
  }

  openModal() {
    this.setState({ isModalOpen: true })
  }

  handleDateChange(event) {
    event.preventDefault()
    this.setState({ selectedDate: event.target.value })
  }

  handleSubmit() {
    const { selectedDate } = this.state
    const { project } = this.props

    const updatedProject = {
      ...project,
      reportStartDate: selectedDate,
      updated: "",
    }
    ProjectAdminService.editProject(updatedProject.code, { formData: updatedProject }, "Update Report Year Start Date")
      .then((data) => {
        this.props.updateProject(data.project)
        Logger.info(data)
      })
      .catch((err) => {
        Logger.error(err)
      })
    this.setState({ isModalOpen: false })
  }

  closeModal() {
    this.setState({ isModalOpen: false })
  }

  loadRange(range) {
    const thisMonthRange = moment.range(moment().startOf("month"), moment().endOf("day"))
    const thisYearRange = moment.range(moment().startOf("year"), moment().endOf("day"))
    const yearRange = moment.range(moment().startOf("year"), moment().endOf("year"))

    // The previousRange props may not be up to date so we calculate one for the current range here
    const now = moment()
    const actualRange = moment.range(range.start, range.end.isAfter(now) ? now.add(1, this.props.aggregate).startOf(this.props.aggregate) : range.end)
    const rangeLength = actualRange.end - actualRange.start
    const previousRange = moment.range(moment(actualRange.start - rangeLength).startOf("day"), actualRange.end - rangeLength)

    const previousYearStartDate = DateTime.fromISO(DateTime.fromISO(this.props.project.reportStartDate).minus({ year: "1" })).startOf("day")
    const previousYearTodayEquivalentDate = DateTime.now().endOf("day").minus({ year: 1 })
    const previousYearEndDate = this.props.project.reportStartDate

    const reportingYearStart = DateTime.fromISO(this.props.project.reportStartDate)
    const reportingYearEnd = DateTime.fromISO(this.props.project.reportStartDate).plus({ year: 1 })

    if (this.props.project.reportStartDate) {
      this.loadEnergyReadings({ start: reportingYearStart, end: DateTime.now() }, "thisReportingYear")
      this.loadForecastConsumptionDatums({ start: reportingYearStart, end: reportingYearEnd }, "thisReportingYear")

      this.loadEnergyReadings({ start: previousYearStartDate, end: previousYearTodayEquivalentDate }, "previousYearUntilPreviousYearTodaysDate")
      this.loadForecastConsumptionDatums({ start: previousYearStartDate, end: previousYearTodayEquivalentDate }, "previousYearUntilPreviousYearTodaysDate")

      this.loadForecastConsumptionDatums({ start: previousYearStartDate, end: previousYearEndDate }, "previousReportingYear")
    }

    this.loadForecastConsumptionDatums(range, "thisPeriod")
    this.loadEnergyReadings(range, "thisPeriod")

    this.loadForecastConsumptionDatums(previousRange, "previousPeriodStrict", true)

    this.loadForecastConsumptionDatums(previousRange, "previousPeriod")
    this.loadEnergyReadings(previousRange, "previousPeriod")

    this.loadForecastConsumptionDatums(thisMonthRange, "thisMonth")
    this.loadEnergyReadings(thisMonthRange, "thisMonth")

    this.loadForecastConsumptionDatums(thisYearRange, "thisYear")
    this.loadEnergyReadings(thisYearRange, "thisYear")

    this.loadForecastConsumptionDatums(yearRange, "year")
    this.loadEnergyReadings(yearRange, "year")
  }

  loadForecastConsumptionDatums(range, name, useForecast) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        [name + "ConsumptionForecast"]: undefined,
      },
      () => {
        if (this.props.project && range) {
          EnergyService.getProjectPredictedConsumption(range, null, this.props.project.code, useForecast)
            .then((response) => {
              this.setStateIfMounted({
                [name + "ConsumptionForecast"]: response,
              })
            })
            .catch((err) => {
              this.setStateIfMounted({
                [name + "ConsumptionForecast"]: new EcosuiteComponentError(err),
              })
            })
        }
      },
    )
  }

  loadEnergyReadings(range, name) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      [name + "Readings"]: undefined,
    })

    if (range) {
      EnergyService.getEnergyReadings(range)
        .then((response) => {
          this.setStateIfMounted({
            [name + "Readings"]: response.projects[this.props.project.code],
          })
        })
        .catch((err) => {
          this.setStateIfMounted({
            [name + "Readings"]: new EcosuiteComponentError(err),
          })
        })
    }
  }

  getLayout() {
    return LAYOUT_CONTENT_RIGHT
  }

  renderMainView() {
    return (
      <div className="econode-graphs">
        {this.renderInfo()}
        {this.renderEconodeGraphs()}
      </div>
    )
  }

  renderInfo() {
    if (this.isContentError(this.state.thisYearConsumptionForecast)) {
      return <Error error={this.state.thisYearConsumptionForecast.getError()} />
    }
    if (this.isContentError(this.state.yearConsumptionForecast)) {
      return <Error error={this.state.yearConsumptionForecast.getError()} />
    }
    if (this.isContentError(this.state.thisYearReadings)) {
      return <Error error={this.state.thisYearReadings.getError()} />
    }

    if (!this.state.thisYearConsumptionForecast || !this.state.yearConsumptionForecast || !this.state.thisYearReadings) {
      return <Loading />
    }
    const historicForecast = this.state.yearConsumptionForecast ? this.state.yearConsumptionForecast.projectDatums.historicForecast : 0
    const targetForecast = this.state.yearConsumptionForecast ? this.state.yearConsumptionForecast.projectDatums.targetForecast : 0

    // const targetConsumption = this.state.thisYearConsumptionForecast ? this.state.thisYearConsumptionForecast.projectDatums.targetForecast : 0

    const diffHistoricWithActualConsumptionUntilTodate =
      (this.state.previousYearUntilPreviousYearTodaysDateConsumptionForecast?.projectDatums.historicForecast || 0) - (this.state.thisReportingYearReadings?.consumption || 0)
    const previousYearHistoricForecast = this.state.previousReportingYearConsumptionForecast?.projectDatums.historicForecast || 0

    const reportingYearHasExpired = !this.props.project.reportStartDate || DateTime.now().diff(DateTime.fromISO(this.props.project.reportStartDate), "year").years > 1

    const hasDataWrite = this.props.groups.includes("data-write")
    const hasDataRead = this.props.groups.includes("data-read")

    return (
      <div className="econode-info">
        <>
          <UncontrolledTooltip placement="top" target={"historicAnnualConsumption-tooltip"}>
            {t("econode.labels.historic_annual_consumption")}
          </UncontrolledTooltip>
          <div className="econode-info-spot" id="historicAnnualConsumption-tooltip">
            <div className="econode-info-spot-label">{`${t("econode.dataTitles.Historic Annual Consumption")} (kWh):`} </div>
            <div className="econode-info-spot-content">{EnergyUtils.formatNumber(EnergyUtils.convertToKilo(historicForecast))}</div>
          </div>
        </>
        <>
          <UncontrolledTooltip placement="top" target={"targetAnnualConsumption-tooltip"}>
            {t("econode.labels.target_annual_consumption")}
          </UncontrolledTooltip>
          <div className="econode-info-spot" id="targetAnnualConsumption-tooltip">
            <div className="econode-info-spot-label">{`${t("econode.labels.target_annual_consumption_thisYear")}:`} </div>
            <div className="econode-info-spot-content">{EnergyUtils.formatNumber(EnergyUtils.convertToKilo(targetForecast))}</div>
          </div>
        </>
        <>
          <UncontrolledTooltip placement="top" target={"historicTargetAnnualConsumption-tooltip"}>
            {t("econode.labels.historic_traget_annual_consumption")}
          </UncontrolledTooltip>
          <div className="econode-info-spot" id="historicTargetAnnualConsumption-tooltip">
            <div className="econode-info-spot-label">
              {t("labels.annual")}{" "}
              <span>
                <Icon icon="change_history" />
              </span>
              {`${t("labels.target")} (kWh):`}{" "}
            </div>
            <div className="econode-info-spot-content">
              -{EnergyUtils.formatNumber(EnergyUtils.convertToKilo(historicForecast - targetForecast))} {this.formatPercent(-Math.round(100 - (targetForecast / historicForecast) * 100))}
            </div>
          </div>
        </>
        <>
          <UncontrolledTooltip placement="top" target={"resultsThisYear-tooltip"}>
            {t("econode.labels.result_thisYear")}
            {this.props.project.reportStartDate ? <span>(which started {this.props.project.reportStartDate})</span> : null}.
          </UncontrolledTooltip>
          <div className="econode-info-spot" id="resultsThisYear-tooltip">
            <div className="econode-info-spot-label">
              <Icon icon="change_history" />
              <span className="px-2">
                {`${t("econode.dataTitles.Result This Year")} (kWh):`} <br />
              </span>
              {reportingYearHasExpired && <p className="report-date-warning">{t("econode.messages.reporting_year_expired")}</p>}
            </div>
            {!reportingYearHasExpired && (
              <div className="econode-info-spot-content">
                <div className="px-4">
                  -{EnergyUtils.formatNumber(EnergyUtils.convertToKilo(diffHistoricWithActualConsumptionUntilTodate))} (
                  {Utils.formatNumber(((diffHistoricWithActualConsumptionUntilTodate / previousYearHistoricForecast) * 100).toFixed(2))}%)
                </div>
                <div className="progress mt-2" id="econode_savings__progressbar">
                  <ProgressBar value={diffHistoricWithActualConsumptionUntilTodate} max={historicForecast - targetForecast} percentify />{" "}
                </div>
                <UncontrolledTooltip placement="top" target={"econode_savings__progressbar"}>
                  {t("econode.labels.econode_savings_progressbar")}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
          {hasDataWrite ||
            (hasDataRead && (
              <div className="btn-report-start-date">
                <Button color="primary" onClick={this.openModal}>
                  {t("econode.labels.edit_report_date")}
                </Button>
              </div>
            ))}
          <Modal isOpen={this.state.isModalOpen}>
            <ModalBody>
              <div className="row form-group field">
                <Label className="control-label col-sm-2 col-form-label w-100 ">{t("econode.labels.report_year_start_date")}</Label>
                <div className="col-sm-10">
                  <Input type="date" className="mb-3" value={this.state.selectedDate} onChange={(e) => this.handleDateChange(e)} />
                </div>
                <div className="d-flex align-items-baseline">
                  <Button color="primary" className="m-3" onClick={() => this.handleSubmit()}>
                    {t("buttons.save")}
                  </Button>
                  <Button color="danger" onClick={this.closeModal}>
                    {t("buttons.cancel")}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      </div>
    )
  }

  formatPercent(number) {
    if (isNaN(number) || number === Infinity) {
      return ""
    }

    number = Utils.formatNumber(number)
    if (number > 0) {
      number = "+" + number
    }

    return `(${number}%)`
  }

  getEconodes() {
    return EconodeUtils.getEconodesForProject(this.props.project)
  }

  renderEconodeGraphs() {
    if (this.isContentValid(this.props.datums)) {
      const econodes = this.getEconodes()
      const graphDatums = ConsumptionDatumUtils.groupDatums(econodes, EconodeUtils.mapEconodeDatums(this.props.project, this.props.datums))

      const predictedConsumptionDatums = this.isContentValid(this.props.predictedConsumption)
        ? DatumUtils.groupPredictedConsumptionDatums(econodes, EconodeUtils.mapEconodeDatums(this.props.project, this.props.predictedConsumption))
        : null

      const range = this.props.datumsRange
      const aggregation = this.props.datumsAggregation

      return (
        <React.Fragment>
          <EventLineGraph
            readonly={this.props.readonly}
            groups={this.props.groups}
            projects={[this.props.project]}
            range={this.props.range}
            dotAggregate={aggregation + "s"}
            type="energy"
            centered={true}
            project={this.props.project.code}
            style={{
              marginLeft: "60px",
              marginRight: "60px",
              width: "calc(100% - 150px)",
            }}
          />

          <SavingsGoalBarGraph
            project={this.props.project}
            site={this.props.site}
            assets={econodes}
            graphDatums={graphDatums}
            datums={this.props.datums}
            predictedConsumptionDatums={predictedConsumptionDatums}
            range={range}
            aggregation={aggregation}
            showGeneration={this.props.showGeneration}
            showConsumption={this.props.showConsumption}
            selectRange={this.props.selectRange}
            referenceLine={this.props.consumptionReferenceLine}
          />
        </React.Fragment>
      )
    } else if (this.isContentError(this.props.datums)) {
      return <Error error={this.props.datums.getError()} />
    } else {
      return <Loading />
    }
  }

  renderSiderBar() {
    const thisMonthRange = moment.range(moment().startOf("month"), moment().endOf("day"))

    return (
      <React.Fragment>
        <SideBarSection
          title={`${t("econode.dataTitles.Consumption This Month")}`}
          thisMonthRange
          range={thisMonthRange}
          classNames="disregard-range"
          content={<ConsumptionSavingsGraph readings={this.state.thisMonthReadings} predictedConsumption={this.state.thisMonthConsumptionForecast} />}
        />
        <SideBarSection
          title={`${t("econode.dataTitles.Actual Consumption This Period")}`}
          range={this.props.actualRange}
          content={<Consumption readings={this.props.readings} />}
          toolTip={`${t("econode.labels.actual_consumption_this_period", {
            actualConsumption: EnergyUtils.displayWattHours(this.props.readings?.consumption, {
              maximumFractionDigits: 3,
              returnPreciseValue: true,
            }),
          })}`}
        />
        <SideBarSection
          title={`${t("econode.dataTitles.Previous Historical Period")}`}
          range={this.props.previousRange}
          content={<ForecastConsumption forecastKey="historicForecast" predictedConsumption={this.state.previousPeriodConsumptionForecast} />}
          toolTip={`${t("econode.labels.previous_consumption", {
            previousConsumption: EnergyUtils.displayWattHours(this.state.previousPeriodConsumptionForecast?.projectDatums["historicForecast"], {
              maximumFractionDigits: 3,
              returnPreciseValue: true,
            }),
          })}`}
        />
        <SideBarSection
          title={`${t("econode.dataTitles.Trend Comparison Previous Historical Period")}`}
          range={this.props.previousRange}
          content={<ConsumptionSavings readings={this.state.thisPeriodReadings} predictedConsumption={this.state.thisPeriodConsumptionForecast} />}
          toolTip={`${t("econode.labels.trend_comparison")}`}
        />
        <SideBarSection
          title={`${t("econode.dataTitles.Trend Previous Period With Forecast")}`}
          range={this.props.previousRange}
          content={<ConsumptionSavings readings={this.state.previousPeriodReadings} predictedConsumption={this.state.previousPeriodStrictConsumptionForecast} />}
          toolTip={`${t("econode.labels.current_consumption_comparison")}`}
        />
      </React.Fragment>
    )
  }
}
