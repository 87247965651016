import React from "react"

import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import EconodeEconomicsUtils from "../../EconodeEconomicsUtils"

export default class PreviousPeriodConsumptionComparison extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.consumptionCost)) {
      return <Error error={this.props.consumptionCost.getError()} />
    }
    if (this.isContentError(this.props.previousConsumptionCost)) {
      return <Error error={this.props.previousConsumptionCost.getError()} />
    }
    if (this.isContentValid(this.props.consumptionCost) && this.isContentValid(this.props.previousConsumptionCost)) {
      const diff = -(100 - (EconodeEconomicsUtils.getTotalCost(this.props.consumptionCost) / EconodeEconomicsUtils.getTotalCost(this.props.previousConsumptionCost)) * 100)
      const positive = diff >= 0
      return <div className={`sidebar-heading ${positive ? "negative" : "positive"}`}>{`${positive ? "+" : ""}${diff ? Math.round(diff === Infinity ? 0 : diff) : 0} %`}</div>
    } else if (this.props.previousConsumptionCost !== null) {
      return <Loading />
    } else {
      return null
    }
  }
}
