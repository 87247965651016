import React from "react"
import { Button } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import moment from "moment"

import { Loading, Message } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"

import ServiceRequestService from "@dashboard/event/ServiceRequestService"

import ServiceRequestViewControls from "./ServiceRequestViewControls"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class ServiceRequestListView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.resolveServiceRequest = this.resolveServiceRequest.bind(this)
  }

  isReadOnly() {
    return !this.props.groups.includes("event-write")
  }

  toggleServiceRequest(serviceRequest) {
    if (this.isServiceRequestSelected(serviceRequest)) {
      this.props.actions.selectServiceRequest() // deselect the serviceRequest
    } else {
      this.props.actions.selectServiceRequest(serviceRequest)
    }
  }

  setTooltipServiceRequest(serviceRequest) {
    this.setStateIfMounted({
      tooltipServiceRequest: serviceRequest,
    })
  }

  isServiceRequestSelected(serviceRequest) {
    return this.props.serviceRequest && this.props.serviceRequest.id === serviceRequest.id
  }

  formatServiceRequestDate(serviceRequest, date) {
    return (
      <React.Fragment>
        {moment(date).format("lll")}
        {/* <span className="event-time-local">{" (" + ServiceRequestUtils.formatServiceRequestDate(this.props.projects, serviceRequest, date, "Do LT") + ")"}</span> */}
      </React.Fragment>
    )
  }

  resolveServiceRequest(serviceRequest) {
    // We hide the tooltip to avoid a racer condition
    this.setStateIfMounted({ loading: true, tooltipServiceRequest: null }, () => {
      ServiceRequestService.resolveServiceRequest(serviceRequest.id)
        .then(() => {
          this.props.actions.serviceRequestUpdated()
          this.setStateIfMounted({ loading: false })
        })
        .catch((err) => {
          this.setStateIfMounted({ loading: false })
          alert(typeof err === "string" ? err : err.message ? err.message : JSON.stringify(err))
        })
    })
  }

  renderViewControls() {
    return <ServiceRequestViewControls {...this.props} selectServiceRequest={this.props.actions.selectServiceRequest} searchServiceRequest={this.props.actions.search} readonly={this.isReadOnly()} />
  }

  renderResolveButton(serviceRequest) {
    if (serviceRequest.closed) {
      return null
    } else {
      return (
        <Button
          color="ecogy"
          size="sm"
          title={`${t("event.messages.record_issue_as_resolved")}`}
          onClick={() => {
            this.resolveServiceRequest(serviceRequest)
          }}
        >
          {t("buttons.resolve")}
        </Button>
      )
    }
  }

  renderMainView() {
    if (this.state.loading) {
      return <Loading />
    }
    const serviceRequests = this.props.serviceRequests
    if (serviceRequests) {
      if (serviceRequests.length > 0) {
        const tooltipServiceRequest = this.state.tooltipServiceRequest
        return (
          <div className="events">
            <table className="events-table">
              <thead>
                <tr>
                  <th className="edit"></th>
                  <th>Request Number</th>
                  <th className="event-cause">{t("event.labels.task_summary")}</th>
                  <th>{t("table_headings.asset")}</th>
                  <th>{t("event.labels.request_date")}</th>
                  <th>{t("event.labels.close_date")}</th>
                  <th>{t("labels.added_by")}</th>
                  {this.isReadOnly() ? null : <th></th>}
                </tr>
              </thead>
              <tbody>
                {serviceRequests.map((serviceRequest, index) => {
                  let selectedClass = tooltipServiceRequest && tooltipServiceRequest.id === serviceRequest.id ? "selected" : null
                  let unresolvedClass = serviceRequest.closed ? null : "unresolved"
                  return (
                    <tr
                      key={serviceRequest.id}
                      className={selectedClass ? selectedClass : null}
                      onMouseOver={() => this.setTooltipServiceRequest(serviceRequest)}
                      onMouseOut={() => this.setTooltipServiceRequest()}
                    >
                      <td>
                        {this.isReadOnly() ? (
                          <Button
                            color="link"
                            size="sm"
                            title={t("event.labels.view_service_request")}
                            onClick={() => {
                              this.toggleServiceRequest(serviceRequest)
                            }}
                          >
                            <Icon icon="link" />
                          </Button>
                        ) : (
                          <>
                            <UncontrolledTooltip target={`service-request-edit-${index}`}>{t("event.labels.edit_service_request")}</UncontrolledTooltip>
                            <Button
                              id={`service-request-edit-${index}`}
                              color="link"
                              size="sm"
                              title={`${t("event.labels.edit_service_request")}`}
                              onClick={() => {
                                this.toggleServiceRequest(serviceRequest)
                              }}
                            >
                              <Icon icon="edit" />
                            </Button>
                          </>
                        )}
                      </td>
                      <td id={serviceRequest.id}>{serviceRequest.referenceNumber}</td>
                      <td id={serviceRequest.id}>{serviceRequest.taskSummary}</td>
                      <td>{serviceRequest.path}</td>
                      <td>{this.formatServiceRequestDate(serviceRequest, serviceRequest.created)}</td>
                      <td className={unresolvedClass}>{serviceRequest.closed ? this.formatServiceRequestDate(serviceRequest, serviceRequest.closed) : t("labels.UNRESOLVED")}</td>
                      <td>{serviceRequest.userName}</td>
                      {this.isReadOnly() ? null : <td className="resolve">{this.renderResolveButton(serviceRequest)}</td>}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      } else {
        return <Message message={t("event.messages.no_service_request")} />
      }
    } else {
      return <Loading />
    }
  }
}
