import React, { useState } from "react"
import { Alert, Button, ListGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import S3DocumentServiceInstance from "@common/input/files/S3DocumentService"
import { Loading } from "@common/EcosuiteComponent"
import { S3 } from "aws-sdk"
import DocumentTile from "@common/input/files/DocumentTile"
import FileDropzone from "@common/input/files/FileDropzone"
import { ObjectList } from "aws-sdk/clients/s3"
import i18n from "src/i18n"

const { t } = i18n
/**
 * A simple document upload dialog.
 * @param title - The modal title.
 * @param bucketPath - The bucket path to upload the document to.
 * @param allowTagDirectory - Whether to allow a tag directory.
 * @param showModal - Whether to show the modal.
 * @param onClose - The function to call to close the modal.
 * @constructor
 */
export const DocumentUpload = ({
  title,
  bucketPath,
  showModal,
  onClose,
  uploadFunc,
  uploadArgs,
}: {
  title: string | undefined
  bucketPath: string
  showModal: boolean
  onClose: () => void
  uploadFunc: (file: File, onProgress: (name: string, onProgress: number) => void, onFinish: (name: string) => void, ...args: unknown[]) => void
  uploadArgs?: unknown[]
}) => {
  const [initializingData, setInitializingData] = useState<boolean>(true)
  const [actioningData, setActioningData] = useState<boolean>(false)
  const [actioningUpload, setActioningUpload] = useState<boolean>(false)

  const [displayWarning, setDisplayWarning] = useState<boolean>(true)
  const [foundDocumentKeys, setFoundDocumentKeys] = useState<string[]>([])
  const [documentData, setDocumentData] = useState<Record<string, S3.Types.GetObjectOutput>>({})
  const [toUpload, setToUpload] = useState<File[]>([])
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({})

  useCurrentEffect(
    (isCurrent) => {
      if (showModal) initializeData(isCurrent)
    },
    [
      bucketPath,
      // Refresh whenever the modal is opened and closed.
      showModal,
    ],
  )

  /**
   * Initialize the root data before actioningData the entire view.
   * @param isCurrent Whether the render is current.
   */
  const initializeData = async (isCurrent: () => boolean) => {
    setInitializingData(true)

    const listResponse = await S3DocumentServiceInstance.listFiles(bucketPath)

    if (isCurrent()) {
      await updateData(listResponse)

      // Reset the documents to upload.
      setToUpload([])

      setInitializingData(false)
    }
  }

  /**
   * Update the document data.
   * @param data - The ObjectList data.
   */
  const updateData = async (data: ObjectList | undefined) => {
    setActioningData(true)

    const newFoundDocumentKeys: string[] = []
    const newDocumentData: Record<string, S3.Types.GetObjectOutput> = {}

    // Extract the documents.
    const documentResponseKeyArray = data?.map((item) => item?.Key) ?? []
    await Promise.all(
      documentResponseKeyArray.map(async (key) => {
        if (key && key !== bucketPath) {
          const documentResponse = await S3DocumentServiceInstance.getFile(key)
          documentResponse.Body

          newFoundDocumentKeys.push(key)
          if (documentResponse.Body) {
            newDocumentData[key] = documentResponse
          }
        }
      }),
    )

    setFoundDocumentKeys(newFoundDocumentKeys)
    setDocumentData(newDocumentData)
    setActioningData(false)
  }

  /**
   * Upload documents.
   */
  const uploadDocuments = async () => {
    setActioningUpload(true)

    let liveProgress: Record<string, number> = {}
    await Promise.all(
      toUpload.map(async (file) => {
        liveProgress = { ...liveProgress, [file.name]: 0 }

        await uploadFunc.call(
          this,
          file,
          (name: string, progress: number) => {
            liveProgress = { ...liveProgress, [name]: progress }
            setUploadProgress(liveProgress)
          },
          async (name: string) => {
            delete liveProgress[name]
            setUploadProgress(liveProgress)

            // Finish upload.
            if (Object.keys(liveProgress).length === 0) {
              setToUpload([])

              const data = await S3DocumentServiceInstance.listFiles(bucketPath)
              await updateData(data)

              setActioningUpload(false)
            }
          },
          ...(uploadArgs ?? []),
        )
      }),
    )
  }

  return (
    <>
      <Modal isOpen={showModal} toggle={() => onClose.call(this)}>
        <ModalHeader toggle={() => onClose.call(this)}>{title ?? `${t("labels.documents")}`}</ModalHeader>
        <ModalBody>
          <Alert color="warning" toggle={() => setDisplayWarning(false)} isOpen={displayWarning}>
            {t("alertsInfo.doc_uploads_not_kept")}
          </Alert>
          {initializingData || actioningData ? (
            <Loading />
          ) : foundDocumentKeys.length == 0 ? (
            <Alert color="info">{t("alertsInfo.doc_not_found")}</Alert>
          ) : (
            <>
              <ListGroup>
                {foundDocumentKeys.map((key, index) => (
                  <DocumentTile
                    key={index}
                    path={foundDocumentKeys[index]}
                    data={documentData[key]}
                    deleteFile={(key) => {
                      setActioningData(true)

                      // Remove the key.
                      const currentKeys = foundDocumentKeys
                      currentKeys.splice(index, 1)
                      // Remove the data.
                      const currentData: Record<string, S3.Types.GetObjectOutput> = documentData
                      delete currentData[key]

                      setFoundDocumentKeys(currentKeys)
                      setDocumentData(currentData)

                      setActioningData(false)
                    }}
                  />
                ))}
              </ListGroup>
            </>
          )}
          <FileDropzone
            onChange={(documents) => setToUpload(documents)}
            uploadProgress={uploadProgress}
            disabled={!(initializingData || actioningData || actioningUpload)}
            waitText={`${t("loadingMsg.please_wait")}`}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={initializingData || actioningData || actioningUpload || toUpload?.length === 0} onClick={uploadDocuments}>
            {t("buttons.upload")}
          </Button>{" "}
          <Button color="secondary" onClick={() => onClose.call(this)}>
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DocumentUpload
