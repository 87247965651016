import { useEffect, useState } from "react"
import SettingsService from "../SettingsService"

import type { JSONSchema7 } from "json-schema"
import type { ParameterStorePathResponse } from "@ecogy-types/openapi-types"
import type { FormData } from "./ParameterStore"

const ParameterStoreHooks = () => {
  const [formSchema, setFormSchema] = useState<JSONSchema7 | undefined>(undefined)
  const [formData, setFormData] = useState<FormData[] | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    // Get Form Schema
    SettingsService.getSettingsFormSchema()
      .then((formSchema) => setFormSchema(formSchema as JSONSchema7))
      .catch(setError)

    // Get Form Data
    // @ts-ignore TODO: Fix this
    SettingsService.getParamsFromPaths("Settings")
      // @ts-ignore
      .then((formData: { [path: string]: ParameterStorePathResponse }) => {
        const formattedFormData = Object.values(formData).reduce(
          (total, params) => [
            ...total,
            ...params.map((param) => ({
              // Entries have this format `/Settings/PARAM_NAME`
              path: param.Name,
              paramName: param.Name?.split("/")[2] ?? null,
              paramValue: param.Value ?? null,
              lastModified: param.LastModifiedDate,
            })),
          ],
          [] as FormData[],
        )

        setFormData(formattedFormData)
      })
      .catch(setError)
  }, [])

  return {
    formSchema,
    setFormSchema,
    formData,
    setFormData,
    error,
  }
}

export default ParameterStoreHooks
