import React from "react"
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row } from "reactstrap"
import EcosuiteForm, { EcosuiteArrayFieldTemplate } from "@common/form/EcosuiteForm"
import { sharedTariffUISchema } from "@dashboard/data/tariffs/tariffUtils"

interface TariffViewerProps {
  selectedTariff: Tariff
  schema: Schema
  value?: Schema
  onChange?: (value: Schema) => void
  onSubmit: (value: Schema) => void
}

/**
 * The tariff viewer.
 * @param props - The props.
 * @constructor
 */
export const TariffViewer = (props: TariffViewerProps) => {
  const { selectedTariff, schema, value, onChange, onSubmit } = props

  const tariffUISchema = {
    ...sharedTariffUISchema,
    id: {
      // The ID is always shown at the top.
      "ui:widget": "hidden",
    },
    assetType: {
      // The asset type isn't very useful here.
      "ui:widget": "hidden",
    },
  }

  /**
   * Get the header text.
   */
  function getHeader(): string {
    if (selectedTariff.name) {
      return selectedTariff.name
    } else if (selectedTariff.rate) {
      return selectedTariff.rate
    } else {
      return selectedTariff.id
    }
  }

  return (
    <Card>
      <CardHeader tag={"h5"}>
        <Row>
          <Col>
            <CardTitle>{getHeader()}</CardTitle>
            {(selectedTariff.name || selectedTariff.rate) && (
              <CardSubtitle className={"mb-2 text-muted"} tag={"h6"}>
                {selectedTariff.id}
              </CardSubtitle>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Col className={"ecogy-form"}>
          <EcosuiteForm schema={schema} uiSchema={tariffUISchema} formData={value} ArrayFieldTemplate={EcosuiteArrayFieldTemplate} onChange={onChange} onSubmit={onSubmit} />
        </Col>
      </CardBody>
    </Card>
  )
}
