import React from "react"
import { VariantProps, cva } from "class-variance-authority"
import { Loader2 } from "lucide-react"
import { cn } from "src/lib"

const spinnerVariants = cva("twx-font tw-flex-col tw-items-center tw-justify-center", {
  variants: {
    show: {
      true: "tw-flex",
      false: "tw-hidden",
    },
  },
  defaultVariants: {
    show: true,
  },
})

const loaderVariants = cva("tw-animate-spin tw-text-foreground", {
  variants: {
    size: {
      small: "tw-size-6",
      medium: "tw-size-8",
      large: "tw-size-12",
    },
  },
  defaultVariants: {
    size: "medium",
  },
})

interface SpinnerContentProps extends VariantProps<typeof spinnerVariants>, VariantProps<typeof loaderVariants> {
  className?: string
  children?: React.ReactNode
}

export function Spinner({ size, show, children, className }: SpinnerContentProps) {
  return (
    <span className={spinnerVariants({ show })}>
      <Loader2 className={cn(loaderVariants({ size }), className)} />
      {children && <span className="tw-mt-2 tw-text-foreground">{children}</span>}
    </span>
  )
}
