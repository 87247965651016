import React from "react"

import EnergyView from "@common/module/EcosuiteView"
import { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"

import IRRCategories from "./finance/IRRCategories"
import FinanceSettings from "./finance/FinanceSettings"
import FinanceService from "./finance/FinanceService"
import { Alert } from "reactstrap"
import { getErrorMessage } from "@common/SharedComponentUtils"

export default class ProjectFinanceView extends EnergyView {
  constructor(props) {
    super(props)

    this.saveSettings = this.saveSettings.bind(this)
    this.updateLocation = this.updateLocation.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadProjectSettings()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadProjectSettings()
    }
  }

  loadProjectSettings() {
    this.setStateIfMounted({
      settings: null,
      error: null,
      locationId: null,
    })

    const projectId = this.props.project.code
    FinanceService.getProjectSettings(projectId)
      .then((settings) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            settings: settings,
            locationId: settings.locationId,
          })
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            error: getErrorMessage(err),
          })
        }
      })
  }

  saveSettings(settings) {
    this.setStateIfMounted({
      settings: null,
      error: null,
    })

    FinanceService.updateProjectSettings(this.props.project.code, settings)
      .then(() => {
        this.loadProjectSettings()
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({
          error: "Error while updating finance settings for project " + this.props.project.code,
          settings: settings,
        })
      })
  }

  updateLocation(settings) {
    const locationId = settings.formData.locationId
    this.setState({ ...this.state, locationId, settings: settings.formData })
  }

  renderMainView() {
    const readonly = this.isReadOnly()
    if (this.state.settings) {
      return (
        <div className="project-content project-finance">
          {this.state.error ? <Alert color="danger">{this.state.error}</Alert> : null}
          <FinanceSettings
            settings={this.state.settings}
            project={this.props.project}
            actions={{ saveSettings: this.saveSettings, changeSettings: this.updateLocation }}
            readonly={readonly}
          />
          {this.state.settings.provider ? (
            <IRRCategories
              project={this.props.project}
              settings={this.state.settings}
              actions={{ saveSettings: this.saveSettings }}
              readonly={readonly}
              location={this.state.locationId}
            />
          ) : null}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
