import React, { useState } from "react"
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Alert } from "reactstrap"

import type { ObjectFieldTemplateProps, UiSchema } from "@rjsf/core"
import type { Record } from "@ecogy-types/openapi-types"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
type HiddenObjectFieldTemplateProps = ObjectFieldTemplateProps<Record & { [key: string]: unknown }>

export default function HiddenFieldsObjectFieldTemplate({ title, description, formData, schema, properties, ...props }: HiddenObjectFieldTemplateProps) {
  const formId = schema.$id ?? title
  const uiSchema = props.uiSchema as UiSchema & { "ui:hideable-fields"?: string[] }
  const hiddenFields = formData.hiddenFields

  // Control Accordion State
  const [openAccordion, setOpenAccordion] = useState("")

  return (
    <div>
      {title ? props.TitleField({ id: formId, title: title, required: false }) : null}
      {description && (!uiSchema || !uiSchema["ui:link"]) ? props.DescriptionField({ id: `${formId}_description`, description: description }) : null}
      {uiSchema && uiSchema["ui:link"] ? (
        <p>
          {description}
          <a href={uiSchema["ui:link"]} target="_blank" rel="noreferrer noopener">
            {uiSchema["ui:link"]}
          </a>
        </p>
      ) : null}

      {/*   Render fields, skip if they are hidden   */}
      {properties?.map((element, idx) => {
        if (hiddenFields?.includes(element.name)) {
          return
        }

        return (
          <div key={idx} className="property-wrapper hidden-fields-form-template">
            <div style={{ width: "100%" }}>{element.content}</div>

            {uiSchema && uiSchema["ui:hideable-fields"]?.includes(element.name) ? (
              <button className="hide-toggle" onClick={() => props.formContext?.toggleHiddenField(element.name)}>
                <Icon icon="visibility_off" />
              </button>
            ) : null}
          </div>
        )
      })}

      {/*   If there are no hidden fields skip rendering them   */}
      {hiddenFields?.length ? (
        // Accordian is supposed to be given a function `toggle` but there is currently a bug in which
        // that prop isn't exposed to typescript. https://github.com/reactstrap/reactstrap/issues/2165
        // @ts-ignore
        <Accordion open={openAccordion} toggle={() => setOpenAccordion((prev) => (prev ? "" : "hidden-fields"))} flush>
          <AccordionItem>
            <AccordionHeader targetId="hidden-fields">
              <div>
                <Icon icon={openAccordion === "hidden-fields" ? "expand_less" : "expand_more"} />
                {t("labels.hidden_fields")}
              </div>

              {/*   Display warning when a required field is hidden and doesn't contain data  */}
              {hiddenFields.some((field) => schema.required?.includes(field) && formData[field] === undefined) ? (
                <Alert color="warning" className="record-form__required-hidden">
                  <Icon icon="warning" />
                  {hiddenFields.filter((field) => schema.required?.includes(field) && formData[field] === undefined).length === 1
                    ? `${t("alertsInfo.empty_hidden_field")}`
                    : `${t("alertsInfo.some_empty_hidden_fields")}`}
                </Alert>
              ) : null}
            </AccordionHeader>
            <AccordionBody accordionId="hidden-fields">
              {properties?.map((element, idx) => {
                if (!hiddenFields?.includes(element.name)) {
                  return
                }

                return (
                  <div key={idx} className="property-wrapper hidden-fields-form-template">
                    <div style={{ width: "100%" }}>{element.content}</div>

                    {uiSchema && uiSchema["ui:hideable-fields"]?.includes(element.name) ? (
                      <button className="hide-toggle" onClick={() => props.formContext?.toggleHiddenField(element.name)}>
                        <Icon icon="visibility" />
                      </button>
                    ) : null}
                  </div>
                )
              })}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      ) : null}
    </div>
  )
}
