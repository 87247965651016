import React from "react"
import EcosuiteView, { LAYOUT_FULL } from "@common/module/EcosuiteView"
import { EcosuiteComponentError, Loading, Error } from "@common/EcosuiteComponent"
import StoreageLineGraph from "@dashboard/energy/graphs/storage/StorageLineGraphAdvanced"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import EnergyService from "@dashboard/energy/EnergyService"
import "./billing/Billing.css"
import Logger from "@common/Logger"
import DatumUtils from "@dashboard/energy/graphs/datums/DatumUtils"
import EnergyPricingService from "../EnergyPricingService"

export default class BatteryView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    this.loadBatteryDatums()
  }

  componentDidUpdate(prevProps) {
    if (!this.state.loading) {
      if (this.props.project !== prevProps.project) {
        this.loadBatteryDatums()
      } else if (!this.state.batteryDatums) {
        this.loadBatteryDatums()
      } else if (
        this.props.range.start.unix() !== prevProps.range.start.unix() ||
        this.props.range.end.unix() !== prevProps.range.end.unix()
      ) {
        this.loadBatteryDatums() //comparing like this because comparing range objects directly doesent work and causes constant re-renders
      }
    }
  }

  getLayout() {
    return LAYOUT_FULL
  }

  async loadBatteryDatums() {
    this.setState({ loading: true })
    const range = this.props.range
    const aggregate = this.props.aggregation
    const projectId = this.props.project.code
    const loadingSourceIds = ["**/BESS/*", "**/STO/*"]
    let projectPromises = []
    projectPromises.push(
      EnergyService.getNodesDatums(range, aggregate, projectId, null, loadingSourceIds)
        .then((response) => {
          if (response.data.error) {
            Logger.error("Error response for nodes datums for " + projectId + ": " + response.data.error)
            this.setStateIfMounted({
              batteryDatums: new EcosuiteComponentError(response.data.error),
            })
          } else {
            return response
          }
        })
        .catch((err) => {
          Logger.error("failed to get nodes datums for " + projectId + ": " + err)
          this.setStateIfMounted({
            batteryDatums: new EcosuiteComponentError(err),
          })
        }),
    )

    Object.keys(this.props.project.sites).map((siteCode) => {
      projectPromises.push(
        EnergyPricingService.getSitePricing(projectId, siteCode, range, aggregate, ["electricityinfo.co.nz"])
          .then((data) => {
            data.siteCode = siteCode
            return data
          })
          .catch((err) => {
            Logger.error("failed to get nodes datums for " + projectId + ": " + err)
            this.setStateIfMounted({
              priceDatums: new EcosuiteComponentError(err),
            })
          }),
      )
    })

    let responses = await Promise.all(projectPromises)

    const groupedBatteryDatums = DatumUtils.groupDatumsBySiteSource(responses[0].data)
    const groupedPriceDatums = {}
    responses.forEach((response, index) => {
      if (index === 0) {
        return
      }
      groupedPriceDatums[response.siteCode] = DatumUtils.groupPriceDatums(response.pricing)
    })
    //battery datums come from device/source datums so we need to group them by site and source
    //price datums come from location/weather datums so they come grouped by site (energy pricing is the only source)

    this.setState({
      priceDatums: groupedPriceDatums,
      batteryDatums: groupedBatteryDatums,
      loading: false,
    })
  }

  renderMainView() {
    if (this.isContentError(this.state.batteryDatums)) {
      return <Error error={this.state.batteryDatums} />
    } else if (
      this.isContentValid(this.state.batteryDatums) &&
      !this.state.loading &&
      this.isContentValid(this.state.priceDatums)
    ) {
      let aggregation = this.props.aggregation
      return (
        <React.Fragment>
          <div className="battery-view-header">
            <h3>Storage Revenue</h3>
          </div>
          {Object.values(this.props.project.sites).map((site) => {
            const graphDatums = DatumUtils.groupStoragePricing(
              this.state.batteryDatums[site.code],
              this.state.priceDatums[site.code],
            )
            return (
              <div key={site.code}>
                <div className="battery-view-header">
                  <h4>{site.code}</h4>
                </div>
                <div className="site-content" style={{ padding: "0px", position: "relative" }}>
                  <StoreageLineGraph
                    key={site.code}
                    project={this.props.project}
                    site={site}
                    assets={EnergyUtils.getSystems(site)}
                    graphDatums={graphDatums}
                    range={this.props.range}
                    aggregation={aggregation}
                    selectRange={this.props.selectRange}
                  />
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )
    } else {
      return <Loading />
    }
  }
}
