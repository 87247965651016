import React from "react"
import { addAutoComplete } from "@common/form/UISchema"
import SiteDetailsContacts from "@dashboard/data/project/sites/SiteDetailsContacts"

export default function getRecordUiSchema(record, readonly, autoCompletes, viewableRecordTypes, disabledSubTypes, formData, handleContactIdsUpdate) {
  // The json schema lib doesn't like the order containing properties that aren't there so we need to dynamically update them when needed
  // https://github.com/mozilla-services/react-jsonschema-form/issues/823

  let order = ["name", "isContract", "recordType"]
  if (record && record.recordType) {
    order.push("recordSubType")
  }
  if (record && record.isContract === "yes") {
    order.splice(2, 0, "counterparty")
  }
  order.push("*")
  order.push("contacts")
  order.push("notes")
  order.push("verified")
  order.push("hiddenFields")

  const uiSchema = {
    "ui:order": order,
    "ui:hideable-fields": HIDDEN_FIELDS,
    hiddenFields: {
      "ui:field": () => <></>,
      "ui:options": {
        label: false,
      },
    },
    recordType: {
      // Disable recordType option if none of its subTypes are viewable
      "ui:enumDisabled": Object.keys(viewableRecordTypes).filter((recordType) => {
        return !viewableRecordTypes[recordType]?.length
      }),
    },
    recordSubType: {
      "ui:enumDisabled": disabledSubTypes,
    },
    contacts: {
      "ui:custom": () => (
        <div>
          <SiteDetailsContacts
            form={formData}
            handleContactsUpdate={(contactIds) => {
              handleContactIdsUpdate(contactIds)
            }}
          />
        </div>
      ),
    },
    notes: {
      "ui:item-label": "Note",
      "ui:collapse": {
        collapsed: true,
        renderTitle: (props, idx) => {
          const entry = props.items[idx].children.props.formData
          if (entry.note) {
            return <>{entry.note}</>
          } else {
            return null
          }
        },
        isNewItem(props, idx) {
          const entry = props.items[idx].children.props.formData
          return !entry.created
        },
      },
      items: {
        "ui:options": {
          label: false,
        },
        note: {
          "ui:widget": "textarea",
          "ui:options": {
            rows: 10,
          },
        },
        userName: {
          "ui:disabled": true,
        },
        created: {
          "ui:disabled": true,
        },
        comments: {
          items: {
            "ui:options": {
              label: false,
            },
            comment: {
              "ui:widget": "textarea",
            },
            userName: {
              "ui:disabled": true,
            },
            created: {
              "ui:disabled": true,
            },
          },
        },
      },
    },
    solarArrays: {
      items: {
        "ui:options": {
          label: false,
        },
      },
    },
    verified: {
      "ui:options": {
        label: false,
        labelPlaceholder: true,
      },
    },
    equipment: {
      items: {
        "ui:options": {
          label: false,
        },
      },
    },
  }

  addAutoComplete(uiSchema, autoCompletes, readonly, "utilityName")
  addAutoComplete(uiSchema, autoCompletes, readonly, "incentiveName")
  addAutoComplete(uiSchema, autoCompletes, readonly, "grantName")
  addAutoComplete(uiSchema, autoCompletes, readonly, "epcContractorName")
  addAutoComplete(uiSchema, autoCompletes, readonly, "leaseholdImprovementsContractorName")
  addAutoComplete(uiSchema, autoCompletes, readonly, "taxExemptionForm")
  addAutoComplete(uiSchema, autoCompletes, readonly, "geotechnicalFirmName")
  addAutoComplete(uiSchema, autoCompletes, readonly, "roofWarrantyProviderName")
  addAutoComplete(uiSchema, autoCompletes, readonly, "stateOfIncorporation")

  addAutoComplete(uiSchema.equipment.items, autoCompletes, readonly, "manufacturer")

  return uiSchema
}

export const HIDDEN_FIELDS = [
  // Hidden fields from the spreadsheet linked in this ticket: https://ecogyenergy.atlassian.net/browse/EP-2397
  // Site Lease Fields
  "descriptionOfProperty",
  "slaSignedByLessorDate",
  "slaSignedByLesseeDate",
  "isLegalDescriptionInSiteLease",
  "optionPeriod",
  "optionExtensionLength",
  "verifySignature",
  "isLeaseholdImprovementsRequired",
  // Interconnection Services Fields
  "isRadialDistribution",
  "circuitFeederNumber",
  "aggregateDgOnFeeder",
  "substationVoltage",
  "primaryVoltage",
  "phaseAtSite",
  "distanceToNearest3Ph",
  "appAcknowledgementDueDate",
  "isStraightToImpactStudy",
  "isUpgradesAndCostsAccepted",
  "isModificationAndCostsAgreed",
  "wirelessSignalApplicationApproved",
  "utilityConstructionSchedule",
  // O&M Fields
  "totalPrice",
  // Construction Fields
  "epcNoticeToProceedDueDate",
  "materialsProcuredByDevCo",
  "percentConstructionComplete",
  // Taxes Fields
  "requirements",
  // Community Solar Fields
  "percentSystemSubscribed",
  // System Design Fields
  "pVACDisconnectLocation",
  "pVACDisconnectRatingA",
  "standards",
  "groundingTransformerRequired",
  "recloserRequired",
  "recloseBlockingRequired",
  "relayRequired",
  "primaryorSecondaryMetering",
  "padorPoleMountedTransformer",
  "proposedInterconnectionPoint",
  // Independent Engineer Report Fields
  "dateofIEInspection",
  // Title Report Fields
  "siteOwner",
  "propertyDescription",
  "openMortgagesDescription",
  "titleReportRequestedDate",
  // SNDA Fields
  "siteOwner",
  "openMortgagesDescription",
  // Memo of Lease Fields
  "siteOwner",
  "isDescriptionCorrect",
  "lessorSignedDate",
  "lesseeSignedDate",
  // Site Engineering Fields
  "geotechnicalFirmName",
  "geotechnicalFirmEmail",
  "geotechnicalFirmPhoneNumber",
  "isRoofWarrantied",
  "willRoofWorkOccurPriorToSolarInstallation",
  "rackingEstimateDate",
  // AMS Report Fields
  "reportName",
  "reportDescription",
  "reportDate",
]
