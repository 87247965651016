import API, { IRange } from "@common/API"

const UserAlertService = {
  getUserAlerts(userId: string, range: IRange) {
    const url = API.addRangeToUrl(`/users/${userId}/alerts`, range)
    return API.get<{ events: unknown }>(url as string).then((response) => response.events)
  },
}

export default UserAlertService
