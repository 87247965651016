/**
 * Common logging class.
 */

type LogFunction = (...args: unknown[]) => void

const Logger = {
  debug: process.env.REACT_APP_DEBUG
    ? // eslint-disable-next-line no-console
      (Function.prototype.bind.call(console.log, console, "DEBUG") as LogFunction)
    : () => ({}), // console.debug doesn't seem to work so using console.log

  // eslint-disable-next-line no-console
  info: Function.prototype.bind.call(console.info, console, "INFO") as LogFunction,

  // eslint-disable-next-line no-console
  warning: Function.prototype.bind.call(console.warn, console, "WARNING") as LogFunction,

  // eslint-disable-next-line no-console
  error: Function.prototype.bind.call(console.error, console, "ERROR") as LogFunction,
}

export default Logger
