import React from "react"

import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts"
import i18n from "src/i18n"

import type { StatusData } from "./FunnelView.d"

const { t } = i18n
type FunnelViewChartsProps = {
  statusMap: StatusData[]
}

const FunnelViewCharts = ({ statusMap }: FunnelViewChartsProps): JSX.Element => {
  return (
    <div className="funnel-view__charts">
      {/* Render project count of each status code as a bar chart */}
      <BarChart width={500} height={400} data={statusMap}>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip labelStyle={{ color: "black" }} />
        <Legend verticalAlign="top" wrapperStyle={{ top: 0 }} />
        <XAxis
          dataKey="status"
          interval={0}
          height={75}
          padding={{ left: 30, right: 30 }}
          angle={-45}
          tick={{
            fill: "white",
            textAnchor: "end",
            dy: -5,
          }}
        />
        <YAxis tick={{ fill: "white" }} />
        <Bar dataKey="projectCount" name={`${t("process.labels.project_count")}`} fill="#4bb3d8" />
      </BarChart>

      {/* Render total kWDC of each status code as a bar chart */}
      <BarChart width={500} height={400} data={statusMap}>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip labelStyle={{ color: "black" }} />
        <Legend verticalAlign="top" wrapperStyle={{ top: 0 }} />
        <XAxis
          dataKey="status"
          interval={0}
          height={75}
          padding={{ left: 30, right: 30 }}
          angle={-45}
          tick={{
            fill: "white",
            textAnchor: "end",
            dy: -5,
          }}
        />
        <YAxis tickCount={8} tick={{ fill: "white" }} />
        <Bar dataKey="kWDC" name={`${t("process.labels.total_kw_dc")}`} fill="#988dec" />
      </BarChart>
    </div>
  )
}

export default FunnelViewCharts
