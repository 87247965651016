import { getInjectedPlugins, pipeInsertDataQuery, WithOverride } from "@udecode/plate-core"
import { S3ImagePlugin } from "./types"
import { insertImage, isImageUrl } from "@udecode/plate"
import { s3InsertImage } from "./s3InsertImage"

/**
 * The S3 specific image upload override.
 * @param editor - The PlateEditor.
 * @param plugin - The plugin specified.
 */
// This is needed because the Plate override requires the '{}' type. Don't blame me.
//
// eslint-disable-next-line @typescript-eslint/ban-types
export const withS3ImageUpload: WithOverride<{}, S3ImagePlugin> = (editor, plugin) => {
  const {
    options: { s3UploadImage, s3Bucket },
  } = plugin
  const { insertData } = editor

  editor.insertData = (dataTransfer: DataTransfer) => {
    const text = dataTransfer.getData("text/plain")
    const { files } = dataTransfer
    if (files && files.length > 0) {
      const injectedPlugins = getInjectedPlugins(editor, plugin)
      if (!pipeInsertDataQuery(injectedPlugins, { data: text, dataTransfer })) {
        return insertData(dataTransfer)
      }

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        const [mime] = files[i].type.split("/")

        if (mime === "image") {
          reader.addEventListener("load", async () => {
            if (!reader.result) {
              return
            }

            // If given means to upload the image using an S3 specific function, do so.
            if (s3UploadImage && s3Bucket) {
              s3UploadImage(reader.result, s3Bucket).then((result) => {
                s3InsertImage(editor, result, s3Bucket)
              })
              // Else, upload with the built-in Image functionality.
            } else {
              const url = reader.result
              insertImage(editor, url)
            }
          })
          reader.readAsDataURL(files[i])
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(dataTransfer)
    }
  }

  return editor
}
