import React from "react"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import SourceTable from "../source/SourceTable"
import AssetSummary from "../info/AssetSummary"
import ProjectUtils from "@common/utils/ProjectUtils"
import DeviceUtils from "@common/utils/DeviceUtils"
import i18n from "src/i18n"

const { t } = i18n
class System extends EcosuiteComponent {
  /**
   * @param {Object} system - system object from project hierarchy
   * @param {Array<Object>} nodesStatus - Array of node statuses
   * @returns {Array<Boolean>} - [ dcSizeWarning: Boolean, acSizeWarning: Boolean ]
   *  return array of two Booleans that determine if the warnings need to be displayed
   */
  getSizeWarnings(system, nodesStatus) {
    let systemDCSize = system.dcSize,
      systemACSize = system.acSize
    if (nodesStatus) {
      nodesStatus.forEach((node) => {
        if (ProjectUtils.getDeviceType(node.sourceId) === "GEN") {
          systemDCSize -= DeviceUtils.getDeviceDCSize(system, node.sourceId)
          systemACSize -= DeviceUtils.getDeviceACSize(system, node.sourceId)
        }
      })
      return [Math.round(systemDCSize) != 0, Math.round(systemACSize) != 0]
    } else {
      return [false, false]
    }
  }

  render() {
    let system = this.props.system
    let systemStatus = this.props.systemStatus
    let systemReadings = this.props.readings
    let [dcSizeWarning, acSizeWarning] = this.getSizeWarnings(system, systemStatus?.nodesStatus)

    return this.props.system ? (
      <div className="system-content">
        <h6 className={"content-subtitle " + (systemStatus && systemStatus.status ? systemStatus.status : "no-status")}>
          {system.name} ({system.code})
        </h6>

        <AssetSummary
          className="details-info"
          range={this.props.range}
          readings={this.props.readings}
          asset={this.props.system}
          project={this.props.project}
          site={this.props.site}
          system={this.props.system}
          status={systemStatus}
          dcSizeWarning={dcSizeWarning}
          acSizeWarning={acSizeWarning}
          consumptionCost={this.props.consumptionCost}
          showGeneration={this.props.showGeneration}
          showConsumption={this.props.showConsumption}
          showStorage={this.props.showStorage}
        />

        {systemStatus && systemStatus.nodesStatus.length > 0 ? (
          <SourceTable
            project={this.props.project}
            site={this.props.site}
            system={system}
            metaData={this.props.metaData}
            nodesStatus={systemStatus.nodesStatus}
            readings={systemReadings}
            datums={this.props.datums}
            predictedConsumption={this.props.predictedConsumption}
            expectedGeneration={this.props.expectedGeneration}
            predictedGeneration={this.props.predictedGeneration}
            range={this.props.range}
            showGeneration={this.props.showGeneration}
            showConsumption={this.props.showConsumption}
            showStorage={this.props.showStorage}
          />
        ) : systemStatus ? (
          <div className="no-match">{t("energy.errorMsgs.no_solar_network_matched")}</div>
        ) : (
          <Loading />
        )}
      </div>
    ) : (
      <Loading />
    )
  }
}

export default System
