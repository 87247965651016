import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { useHistory } from "react-router-dom"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "src/components/ui/card"
import { Button } from "src/components/ui/button"
import Logo from "src/img/ecosuite_logo.png"
import Header, { ThemeProvider } from "../Components/Header"
import { toast } from "react-toastify"
import { Label } from "src/components/ui/label"
import { Input } from "src/components/ui/input"
import { Alert, AlertDescription } from "src/components/ui/alert"
import LoadingButton from "src/components/ui/loading-button"
import { PasswordInput } from "src/components/ui/password-input"

const RequestCodeForm: React.FC<{
  username: string
  setUsername: React.Dispatch<React.SetStateAction<string>>
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  error: string
  isLoading: boolean
}> = ({ username, setUsername, onSubmit, error, isLoading }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="tw-flex tw-flex-col tw-space-y-4">
        <div className="tw-flex tw-flex-col tw-space-y-1.5">
          <Label htmlFor="username">Username</Label>
          <Input
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username"
            required
          />
        </div>
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <LoadingButton className="tw-w-full" type="submit" loading={isLoading}>
          Get Password Reset Code
        </LoadingButton>
      </div>
    </form>
  )
}

const ResetPasswordForm: React.FC<{
  code: string
  setCode: React.Dispatch<React.SetStateAction<string>>
  newPassword: string
  setNewPassword: React.Dispatch<React.SetStateAction<string>>
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
  error: string
  isLoading: boolean
}> = ({ code, setCode, newPassword, setNewPassword, onSubmit, error, isLoading }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="tw-flex tw-flex-col tw-space-y-4">
        <div className="tw-flex tw-flex-col tw-space-y-1.5">
          <Label htmlFor="code">Reset Code</Label>
          <Input
            id="code"
            value={code}
            type="number"
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter the reset code"
            required
          />
        </div>
        <div className="tw-flex tw-flex-col tw-space-y-1.5">
          <Label htmlFor="newPassword">New Password</Label>
          <PasswordInput
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter your new password"
            required
          />
        </div>
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <LoadingButton className="tw-w-full" type="submit" loading={isLoading}>
          Reset Password
        </LoadingButton>
      </div>
    </form>
  )
}

const PasswordResetPage: React.FC = () => {
  const [username, setUsername] = useState("")
  const [code, setCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [step, setStep] = useState<"requestCode" | "resetPassword">("requestCode")
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const handleRequestCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError("")
    setIsLoading(true)

    try {
      await Auth.forgotPassword(username)
      setStep("resetPassword")
    } catch (error) {
      setError((error as Error).message || "An error occurred while requesting the code.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError("")
    setIsLoading(true)

    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword)
      toast.success("Password changed successfully! Please enter your new credentials.")
      history.push("/onboarding/login")
    } catch (error) {
      setError((error as Error).message || "An error occurred while resetting the password.")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ThemeProvider>
      <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-background onboarding">
        <Header logo={Logo} />
        <div className="tw-flex tw-flex-grow tw-items-center tw-justify-center">
          <Card className="tw-w-[460px]">
            <CardHeader>
              <CardTitle className="tw-text-xl tw-font-bold">Reset Password</CardTitle>
              <CardDescription>
                {step === "requestCode"
                  ? "Enter your username to receive a password reset code"
                  : "Enter the code you received in your email and your new password"}
              </CardDescription>
            </CardHeader>
            <CardContent>
              {step === "requestCode" ? (
                <RequestCodeForm
                  username={username}
                  setUsername={setUsername}
                  onSubmit={handleRequestCode}
                  error={error}
                  isLoading={isLoading}
                />
              ) : (
                <ResetPasswordForm
                  code={code}
                  setCode={setCode}
                  newPassword={newPassword}
                  setNewPassword={setNewPassword}
                  onSubmit={handleResetPassword}
                  error={error}
                  isLoading={isLoading}
                />
              )}
            </CardContent>
            <CardFooter>
              <div className="tw-flex tw-justify-center tw-w-[100%]">
                <Button variant="link" onClick={() => history.push("/onboarding/login")}>
                  Back to Login
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default PasswordResetPage
