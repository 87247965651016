import { Organization } from "src/services/organization"
import { produce } from "immer"

export const formatData = (data?: Omit<Organization, "id">): Omit<Organization, "id"> | undefined => {
  if (data?.settings) {
    if (data.settings.projectMilestoneRecipients) {
      return {
        ...data,
        settings: {
          ...data.settings,
          projectMilestoneRecipients: data.settings.projectMilestoneRecipients.map((datum) => ({
            label: datum,
            value: datum,
          })) as unknown as string[],
        },
      }
    }
  }
  return data
}

export const formatForm = (
  data: Omit<Organization, "id"> | undefined,
  imageUrl: string | undefined,
): Omit<Organization, "id"> | undefined => {
  if (!data) return data
  const updatedData = produce(data, (draft) => {
    if (draft.settings) {
      if (draft.settings.projectMilestoneRecipients) {
        draft.settings.projectMilestoneRecipients = (
          draft.settings.projectMilestoneRecipients as unknown as { label: string; value: string }[]
        ).map((datum) => datum.value)
      }
      if (imageUrl) {
        draft.settings.logoUrl = imageUrl
      }
    }
    return draft
  })
  return updatedData
}
