import React from "react"
import { Button, ButtonGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap"
import { RFIReplyStored } from "@ecogy-types/openapi-types"
import { DateTime } from "luxon"
import "./RFIReplyTile.scss"
import { RFIEditorValue } from "@dashboard/process/views/rfi/RFIEditor"
import UserDisplayEmail from "@common/display/UserDisplayEmail"
import i18n from "src/i18n"

/**
 * An RFI reply tile.
 * @param rfi - The RFI which the reply belongs to.
 * @param rfiReply - The RFI reply.
 * @param author - The RFI reply author.
 * @param currentUser - The current user.
 * @param showMediaKeys - The callback to display the selected media keys.
 * @param setEditing - The callback for setting the editable object.
 * @param onDeleteRFIReply - The callback for deleting the reply.
 * @constructor
 */
const { t } = i18n
export const RFIReplyTile = ({
  rfi,
  rfiReply,
  author,
  currentUser,
  showMediaKeys,
  setEditing,
  onDeleteRFIReply,
}: {
  rfi: RFIStored
  rfiReply: RFIReplyStored
  author: User | undefined
  currentUser: User
  showMediaKeys: (keys: string[]) => void
  setEditing: (object: RFIStored | RFIReplyStored, value: RFIEditorValue) => void
  onDeleteRFIReply: (id: string) => void
}): JSX.Element => {
  return (
    <ListGroupItem className={"rfi-reply-tile"}>
      <ListGroupItemHeading>
        {author ? `${author.attributes.firstName} ${author.attributes.lastName} ` : `${t("labels.unknown")}`}
        {author && <UserDisplayEmail user={author} />}
      </ListGroupItemHeading>
      <ListGroupItemText>{DateTime.fromISO(rfiReply.createdDateTime).toLocaleString()}</ListGroupItemText>
      <ListGroupItemText>
        <p className={"display-linebreak"}>{rfiReply.content}</p>
      </ListGroupItemText>
      <ButtonGroup>
        {rfiReply.media.length > 0 && <Button onClick={() => showMediaKeys.call(this, rfiReply.media as string[])}>{t("process.labels.view_attatchments")}</Button>}
        {rfiReply.author === currentUser.id && (
          <ButtonGroup>
            <Button color={"danger"} onClick={() => onDeleteRFIReply.call(this, rfiReply.id)}>
              {t("buttons.delete")}
            </Button>
            <Button color={"primary"} disabled={rfi.resolved} onClick={() => setEditing.call(this, rfiReply, RFIEditorValue.RFIReply)}>
              {t("buttons.edit")}
            </Button>
          </ButtonGroup>
        )}
      </ButtonGroup>
    </ListGroupItem>
  )
}

export default RFIReplyTile
