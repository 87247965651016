import API from "./common/API"
import Logger from "./common/Logger"
import { getErrorMessage } from "./common/SharedComponentUtils"

/**
 * Send some feedback.
 * @param version - The version used.
 * @param email - The user email.
 * @param feedback - The feedback.
 */
export const sendFeedback = async (version: string, email: string | undefined, feedback: string): Promise<string | undefined> => {
  try {
    await API.post("/feedback", { version, email, feedback })
  } catch (e) {
    Logger.error(e)
    return `Unable to send feedback: ${getErrorMessage(e)}`
  }
}
