import React, { useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import i18n from "src/i18n"

const { t } = i18n
// Checkbox widget override that adds Select All and Removed Selected buttons
const UserTypeSelector = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  let { id, schema, options } = props

  // Currently only Array Type Schema Elements can be checkboxes
  if (schema.type !== "array") {
    return undefined
  }

  return (
    <>
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={() => {
          setDropdownOpen((dropdownOpen) => !dropdownOpen)
        }}
      >
        <DropdownToggle className="ecogy-form__user-type__dropdown-toggle" caret />
        <DropdownMenu end>
          <DropdownItem
            onClick={(e) => {
              e.preventDefault()
              props.onChange([...schema.items.enum])
            }}
          >
            {t("labels.select_all")}
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              e.preventDefault()
              props.onChange([])
            }}
          >
            {t("labels.removed_selected")}
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {/* Copy of react-jsonform-schema checkbox widget */}
      <div id={id} className="checkboxes">
        {options?.enumOptions?.map((option, idx) => {
          return (
            <label key={`${id}_${idx}`} className="checkbox-inline">
              <span>
                <input
                  id={`${id}_${idx}`}
                  type="checkbox"
                  checked={props.value.includes(option.value)}
                  onChange={() => {
                    if (props.value.includes(option.value)) {
                      // make a copy of the value prop to avoid direct mutations
                      const valuePropCopy = [...props.value]
                      valuePropCopy.splice(props.value.indexOf(option.value), 1)
                      props.onChange(valuePropCopy)
                    } else {
                      props.onChange([...props.value, option.value])
                    }
                  }}
                />
                <span>{option.label}</span>
              </span>
            </label>
          )
        })}
      </div>
    </>
  )
}

export default UserTypeSelector
