import React from "react"
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts"

import EcosuiteComponent, { Error, Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import Utils from "@common/utils/Utils"
import i18n from "src/i18n"

const { t } = i18n
const RADIAN = Math.PI / 180

export default class ConsumptionCostsPieGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.projectCosts)) {
      return <Error error={this.props.projectCosts.getError()} />
    }
    if (this.isContentValid(this.props.projectCosts)) {
      return this.renderGraph()
    } else {
      return <Loading />
    }
  }

  renderGraph() {
    const data = [
      { name: `${t("econode.dataTitles.Metered Charges")}`, value: this.props.projectCosts.consumptionCost.totalMeteredCost },
      { name: `${t("econode.dataTitles.Demand Charges")}`, value: this.props.projectCosts.consumptionCost.totalDemandCharge },
      { name: `${t("econode.dataTitles.Fixed Charges")}`, value: this.props.projectCosts.consumptionCost.totalFixedCost },
    ]
    return <Graph data={data} />
  }
}

class Graph extends EcosuiteComponent {
  renderContent() {
    return (
      <div className="sidebar-graph">
        <PieChart width={300} height={220} onMouseEnter={this.onPieEnter}>
          <Pie data={this.props.data} dataKey="value" nameKey="name" labelLine={false} outerRadius={80} fill="#8884d8" label={this.renderCustomizedLabel} isAnimationActive={false}>
            {this.props.data.map((entry, index) => (
              <Cell fill={GRAPH_COLORS[index % GRAPH_COLORS.length]} key={index} />
            ))}
          </Pie>
          <Tooltip
            // eslint-disable-next-line no-unused-vars
            formatter={(value, name, props) => {
              return Utils.formatCurrency(value)
            }}
          />
          <Legend />
        </PieChart>
      </div>
    )
  }

  renderCustomizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent /*, index*/ }) {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
}
