import * as React from "react"
import { Heading, TextField, withAuthenticator } from "@aws-amplify/ui-react"
import { Auth } from "aws-amplify"
import LoginHeader from "../login/LoginHeader"
import { Trans } from "react-i18next"
import { t } from "i18next"
import { QRCodeSVG } from "qrcode.react"
import { Button } from "reactstrap"
import { Redirect, Route } from "react-router-dom"

export async function SignOut() {
  Auth.signOut()
  window.location.href = "/"
}

export async function VerifySuperAdmin() {
  try {
    const session: any = await Auth.currentSession()
    if (session.idToken.payload["custom:rootuser"] !== "1") {
      SignOut()
    }
  } catch (e) {
    SignOut()
  }
}

function SuperAdmin() {
  const [authVerified, setAuthVerified] = React.useState(false)
  const [user, setUser] = React.useState<any>()
  const [code, setCode] = React.useState("")
  const [mfaType, setMfaType] = React.useState<any>()
  const [challengeAnswer, setChallengeAnswer] = React.useState("")

  async function verifyAuth() {
    const session: any = await Auth.currentSession()
    if (session.idToken.payload["custom:rootuser"] !== "1") {
      Auth.signOut()
    }
  }

  React.useEffect(() => {
    async function init() {
      await verifyAuth()
      setAuthVerified(true)
      const user = await Auth.currentAuthenticatedUser()
      const code = await Auth.setupTOTP(user)
      await loadMfaType()
      setUser(user)
      setCode(code)
    }
    init()
  }, [])

  function updateCredentials(e: any) {
    setChallengeAnswer(e.target.value)
  }

  async function loadMfaType() {
    const user = await Auth.currentAuthenticatedUser()
    const mfaType = await Auth.getPreferredMFA(user, { bypassCache: true })
    setMfaType(mfaType)
  }

  function verifyCredentials(e: any) {
    e.preventDefault()
    Auth.verifyTotpToken(user, challengeAnswer).then(() => {
      // don't forget to set TOTP as the preferred MFA method
      Auth.setPreferredMFA(user, "TOTP").then(() => {
        loadMfaType()
      })
    })
  }

  if (!authVerified) return null

  if (user && code && mfaType === "NOMFA") {
    const str = "otpauth://totp/AWSCognito:" + user.attributes.email + "?secret=" + code + "&issuer=Ecosuite"
    return (
      <div>
        <LoginHeader />
        <div className="login-mfa">
          <div className="login-mfa-wrapper">
            <Heading level={5}>Setup mfa for authentication</Heading>
            <div className="login-mfa-body">
              <p>
                <Trans i18nKey="notes.account_auth_step_one">
                  <b>Step 1:</b> Your account has administrative privileges and requires MFA(multi-factor
                  authentication) to be enabled using an app such as{" "}
                </Trans>
                <a
                  href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS&oco=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("labels.google_auth")}
                </a>{" "}
                or{" "}
                <a href="https://authy.com/download/" target="_blank" rel="noopener noreferrer">
                  Authy
                </a>
                {t("notes.if_have_no_account")}
              </p>

              <div style={{ width: "100%" }}>
                <Trans i18nKey="notes.account_auth_step_two">
                  <b>Step 2:</b>
                  <br />
                  <p>To setup multi-factor authentication, open your authenticator app and scan the QR code below. </p>
                </Trans>
                <QRCodeSVG value={str} style={{ margin: "auto", display: "flex", marginBottom: "8px" }} />
                <p>
                  <Trans i18nKey="notes.enter_code_on_auth">
                    <b>OR</b>, enter the code <b>{t("notes.enter_code_on_auth", { code: code })}</b> on your
                    authenticator app.
                  </Trans>
                </p>
              </div>

              <div>
                <Trans i18nKey="notes.account_auth_step_three">
                  <p>
                    <b>Step 3:</b> Once you have scanned the QR code or entered the code, complete the setup by entering
                    the 6 digit code listed in your authenticator app.
                  </p>
                </Trans>

                {/* @ts-ignore */}
                <TextField placeholder={t("labels.auth_code")} onChange={updateCredentials} />
              </div>
            </div>
            <Button onClick={verifyCredentials} isFullWidth className="login-mfa-confirm-button">
              {t("labels.confirm")}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  if (mfaType === "SOFTWARE_TOKEN_MFA") {
    return <Redirect to="/su-admin/organizations" />
  }

  return null
}

export default withAuthenticator(SuperAdmin, {
  hideSignUp: true,
  passwordSettings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS"],
  },
})
