import React from "react"
// import { NavLink } from "react-router-dom"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"

import EcosuiteComponent from "@common/EcosuiteComponent"
import ProfileForm from "./ProfileForm"
import i18n from "src/i18n"

const { t } = i18n
export default class ProfileTabs extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "profile",
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  renderContent() {
    return (
      <div className="admin-content admin-tabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={this.state.activeTab === "profile" ? "active" : null}
              onClick={() => {
                this.toggle("profile")
              }}
            >
              {t("header.userMenu.profile")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="profile">
            {/* <Row>
              <Col sm="12"> */}
            <ProfileForm />
            {/* </Col>
            </Row> */}
          </TabPane>
        </TabContent>
      </div>
    )
  }
}
