import React from "react"
import { Alert, Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Typeahead } from "react-bootstrap-typeahead"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import RecordService from "../../RecordService"
import sanitize from "sanitize-filename"
import i18n from "src/i18n"

const documentFolderHierarchy = require("../../record-document-folders.json")
const { t } = i18n

export class RecordDocumentEdit extends EcosuiteComponent {
  constructor(props) {
    super(props)

    // this.state.folderName = ""
    // this.state.fileName = ""

    this.toggleModal = this.toggleModal.bind(this)
    this.editDocument = this.editDocument.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.initDocument()
  }

  componentDidUpdate(prevProps) {
    if (this.props.document !== prevProps.document) {
      this.initDocument()
    }
  }

  initDocument() {
    if (this.props.document) {
      this.setStateIfMounted({ folderName: this.props.document.folderName, fileName: this.props.document.fileName })
    } else {
      this.setStateIfMounted({ folderName: "", fileName: "" })
    }
  }

  toggleModal() {
    this.setStateIfMounted({ error: null, message: "", files: [] })
    this.props.selectDocument() // Clear the selected document
  }

  getDefaultFolderNames() {
    var folderNames
    if (this.props.record && this.props.record.recordType && this.props.record.recordSubType) {
      folderNames = documentFolderHierarchy[this.props.record.recordType][this.props.record.recordSubType]
    }
    if (!folderNames) {
      folderNames = []
    }
    return folderNames
  }

  async editDocument() {
    if (!this.state.folderName) {
      this.setStateIfMounted({ error: t("data.errorMsg.select_folder"), loading: false })
    } else if (!this.state.fileName) {
      this.setStateIfMounted({ error: t("data.errorMsg.document_name"), loading: false })
    } else if (this.state.fileName !== sanitize(this.state.fileName)) {
      this.setStateIfMounted({ error: t("data.errorMsg.file_name"), loading: false })
    } else {
      this.setStateIfMounted({ loading: true, error: null, message: null })
      await RecordService.moveFile(
        this.props.document.fileKey,
        `records/${this.props.record.id}/${this.state.folderName}${this.props.document.subFolderName ? `/${this.props.document.subFolderName}` : ""}/${this.state.fileName}`,
        this.props.groups,
      )
        .then(() => {
          this.setStateIfMounted({ message: t("messages.document_saved"), loading: false })
          this.props.actions.loadDocuments(this.props.record) // Reload the documents to pick up the newly uploaded ones
          this.toggleModal()
        })
        .catch((err) => {
          this.setStateIfMounted({ error: `${t("errors.saving_document")}: ${err.message}`, loading: false })
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.document !== undefined} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>{t("labels.edit_document")}</ModalHeader>
          <ModalBody>
            <Label>{t("labels.folder")}:</Label>
            <Typeahead
              id="folderAutoComplete"
              onChange={(selected) => {
                this.setStateIfMounted({ folderName: selected && selected.length ? (typeof selected[0] === "object" ? selected[0].label : selected[0]) : null })
              }}
              options={this.getDefaultFolderNames()}
              selected={this.state.folderName ? [this.state.folderName] : []}
              allowNew={true}
              disabled={this.props.readonly}
            />

            <Label>{t("labels.name")}:</Label>
            <Input
              type="text"
              value={this.state.fileName}
              onChange={(e) => {
                e.preventDefault()
                this.setStateIfMounted({ fileName: e.target.value })
              }}
            />

            {this.state.loading ? <Loading message={t("loadingMsg.editing_documents")} /> : null}
            {this.state.message ? <Alert color="info">{this.state.message}</Alert> : null}
            {this.state.error ? <Alert color="danger">{this.state.error}</Alert> : null}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editDocument}>
              {t("buttons.save")}
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModal}>
              {t("buttons.cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}
