import React from "react"
import DRMapView from "./views/DRMapView"
import EcosuiteComponent, { Error } from "@common/EcosuiteComponent"
import DREventView from "./views/DREventView"
import DRParticipantView from "./views/DRParticipantView"
import DRNotificationView from "./views/DRNotificationView"
import i18n from "src/i18n"

const { t } = i18n
export default class DRModuleViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "overview":
        return <DRMapView {...this.props} />
      case "event":
        return <DREventView {...this.props} />
      case "notification":
        return <DRNotificationView {...this.props} />

      case "participant":
        return <DRParticipantView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ${this.props.view}` }} />
    }
  }
}
