import React from "react"
import { Container, Row, Col } from "reactstrap"

import EcosuiteComponent, { Loading, Warning, Message } from "@common/EcosuiteComponent"

import PortfolioIRRReportContent from "./irr/portfolio/PortfolioIRRReportContent"

import "./IRRReport.css"
import { PROJECT_LIMIT } from "../ReportView"
import i18n from "src/i18n"

const { t } = i18n

export default class IRRReport extends EcosuiteComponent {
  renderContent() {
    if (this.props.projects.length > PROJECT_LIMIT) {
      return <Warning message={t("economics.messages.most_selected", { limit: PROJECT_LIMIT })} />
    } else if (this.props.projects.length < 1) {
      return <Message message={t("economics.messages.select_one")} />
    } else if (this.props.report) {
      return (
        <div className="IRRReport">
          <Container fluid={true} className="IRRReport-content">
            <Row>
              <Col>
                <div className="IRRReport-content" id="finance-report">
                  <div className="scroller">
                    <PortfolioIRRReportContent
                      projects={this.props.projects}
                      report={this.props.report}
                      aggregate={this.props.aggregate}
                      columns={this.props.columns}
                      showAccounts={this.props.showAccounts}
                      showSubAccounts={this.props.showSubAccounts}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )
    } else {
      return <Loading message={t("economics.messages.aggregating_reports", { projectsLength: this.props.projects.length, aggregate: this.props.aggregate })} />
    }
  }
}
