import React from "react"
import moment from "moment"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import EnergyView from "./EnergyView"

import SiteInfo from "./site/SiteInfo"
import AssetSummary from "./info/AssetSummary"
import i18n from "src/i18n"

const { t } = i18n
class ProjectEnergyinfoView extends EnergyView {
  getInstantaneousTime() {
    if (this.props.instantaneousTimestamp) {
      return moment(this.props.instantaneousTimestamp).format("lll")
    }
    return null
  }

  getSiteStatus(siteId) {
    if (this.props.projectStatus) {
      return this.props.projectStatus.sites[siteId]
    }
    return null
  }

  getSiteReading(siteId) {
    if (this.props.readings) {
      return this.props.readings.sites[siteId]
    }
    return null
  }

  renderMainView() {
    if (this.props.project) {
      return (
        <div>
          <Project {...this.props} />

          {Object.values(this.props.project.sites).map((site) => {
            let siteStatus = this.isContentValid(this.props.projectStatus) ? this.props.projectStatus.sites[site.code] : this.props.projectStatus
            let siteConsumptionCost = this.isContentValid(this.props.consumptionCost) ? this.props.consumptionCost.sites[site.code] : this.props.consumptionCost
            let sitePredictedConsumption = this.isContentValid(this.props.predictedConsumption) ? this.props.predictedConsumption.sites[site.code] : this.props.predictedConsumption
            let siteExpectedGeneration = this.isContentValid(this.props.expectedGeneration) ? this.props.expectedGeneration.sites[site.code] : this.props.expectedGeneration
            let sitePredictedGeneration = this.isContentValid(this.props.predictedGeneration) ? this.props.predictedGeneration.sites[site.code] : this.props.predictedGeneration
            let siteReadings = this.isContentValid(this.props.readings) ? this.props.readings.sites[site.code] : this.props.readings

            return (
              <SiteInfo
                key={site.code}
                groups={this.props.groups}
                project={this.props.project}
                site={site}
                consumptionCost={siteConsumptionCost}
                predictedConsumption={sitePredictedConsumption}
                expectedGeneration={siteExpectedGeneration}
                predictedGeneration={sitePredictedGeneration}
                readings={siteReadings}
                siteStatus={siteStatus}
                range={this.props.range}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
              />
            )
          })}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

class Project extends EcosuiteComponent {
  renderContent() {
    return (
      <div className="project-details">
        <h3 className={"content-title " + (this.isContentValid(this.props.projectStatus) && this.props.projectStatus.status ? this.props.projectStatus.status : "no-status")}>
          {t("labels.project")}: {this.props.project.name}
        </h3>
        <AssetSummary
          className="project-details-info"
          range={this.props.range}
          asset={this.props.project}
          project={this.props.project}
          status={this.props.projectStatus}
          readings={this.props.readings}
          consumptionCost={this.props.consumptionCost}
          predictedConsumption={this.props.predictedConsumption}
          expectedGeneration={this.props.expectedGeneration}
          predictedGeneration={this.props.predictedGeneration}
          showGeneration={this.props.showGeneration}
          showConsumption={this.props.showConsumption}
        />
      </div>
    )
  }
}

export default ProjectEnergyinfoView
