import moment from "moment"
import { divide } from "mathjs"

import Categories from "./irr/Categories"
import ReportUtils from "./irr/ReportUtils"
import InputsDownload from "../pro-forma-inputs/ProFormaInputsDownload"

const ExcelJS = require("exceljs")

// const COLUMN_COUNT = 5

const BORDER = {
  top: { style: "thin", color: { argb: "FFD4D4D4" } },
  left: { style: "thin", color: { argb: "FFD4D4D4" } },
  bottom: { style: "thin", color: { argb: "FFD4D4D4" } },
  right: { style: "thin", color: { argb: "FFD4D4D4" } },
}

const COLOR_ACTUAL = "FFFFF2CC"
const COLOR_FORECAST = "FFCEE2F3"
const COLOR_EXPECTED = "FFD9EAD3"

const ReportDownload = {
  calculateColumnIndexes(columns) {
    const indexes = {
      // forecast : 0,
      // expected : 1,
      // actual : 2,
      // latestBestEstimate : 3,
    }

    if (columns.includes("forecast")) {
      indexes["forecast"] = Object.keys(indexes).length
    }

    if (columns.includes("expected")) {
      indexes["expected"] = Object.keys(indexes).length
    }

    if (columns.includes("actual")) {
      indexes["actual"] = Object.keys(indexes).length
    }

    if (columns.includes("latestBestEstimate")) {
      indexes["latestBestEstimate"] = Object.keys(indexes).length
    }

    return indexes
  },

  downloadReport(project, proForma, proFormaIrr, report, reportTotals, categories, flags, columns, activeTab, options) {
    const workbook = new ExcelJS.Workbook()
    const reportCategories = Categories.mergeReportCategories(categories, report)
    options.columnIndexes = this.calculateColumnIndexes(columns)
    options.columnCount = Object.keys(options.columnIndexes).length ? Object.keys(options.columnIndexes).length : 1

    workbook.creator = "Ecosuite"
    workbook.created = new Date()

    if (proForma) {
      InputsDownload.addProFormaInputsWorksheet(workbook, project, proForma, proFormaIrr, report, reportTotals, categories)
    }
    this.addWorksheet(workbook, project, report, reportCategories, "month", options)
    this.addWorksheet(workbook, project, report, reportCategories, "quarter", options)
    this.addWorksheet(workbook, project, report, reportCategories, "year", options)
    this.addWorksheet(workbook, project, report, reportCategories, "total", options)

    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 40000,
        height: 30000,
        firstSheet: 0,
        activeTab: this.getActiveTab(activeTab, proForma),
        visibility: "visible",
      },
    ]

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement("a")
      anchor.href = url
      anchor.download = `${project.code}-${project.companyName}${flags && flags.length ? " (" + flags.join("+") + ") " : ""} ${moment().format("YYYY-MM-DD")}.xlsx`
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  },

  getActiveTab(activeTab, showProFormaInputs) {
    const aggregateTab = this.getActiveAggregateTab(activeTab)
    if (showProFormaInputs) {
      if (activeTab === "inputs") {
        return 0
      } else {
        return 1 + aggregateTab
      }
    } else {
      return aggregateTab
    }
  },

  getActiveAggregateTab(aggregate) {
    switch (aggregate) {
      case "quarter":
        return 1
      case "year":
        return 2
      case "total":
        return 3
      default:
        return 0
    }
  },

  addWorksheet(workbook, project, report, categories, aggregate, options) {
    if (aggregate) {
      report = ReportUtils.aggregateReport(report, aggregate)
    }

    const worksheet = workbook.addWorksheet(this.getTitle(aggregate), { headerFooter: { firstHeader: "Ecosuite IRR Report for: " + project.companyName } })
    // worksheet.properties.defaultColWidth = 12

    this.addColumnHeaders(worksheet, report, aggregate, options)

    this.addCashFlows(worksheet, report, categories, aggregate, options)
  },

  getTitle(aggregate) {
    switch (aggregate) {
      case "quarter":
        return "Quarterly"
      case "year":
        return "Yearly"
      case "total":
        return "Lifetime"
      default:
        return "Monthly"
    }
  },

  formatDate(date, aggregate) {
    switch (aggregate) {
      case "quarter":
        return "Q" + moment(date).format("Q YYYY")
      case "year":
        return moment(date).format("YYYY")
      case "total":
        return "Total"
      default:
        return moment(date).format("MMM YYYY")
    }
  },

  addColumnHeaders(worksheet, report, aggregate, options) {
    // sheet.addRow(["", ...report.dates])
    report.dates.forEach((date, idx) => {
      const colStart = idx * options.columnCount + 2
      const colEnd = colStart + options.columnCount - 1
      worksheet.mergeCells(1, colStart, 1, colEnd)
      worksheet.getCell(1, colStart).value = this.formatDate(date, aggregate)
      worksheet.getColumn(1).width = 30

      const isCurrent = aggregate === "total" || moment(date).endOf(aggregate).isSame(moment().endOf(aggregate))
      const isFuture = aggregate === "total" || moment(date).endOf(aggregate).isAfter(moment())

      this.addColumnHeader(worksheet, colStart, "forecast", "Forecast", COLOR_FORECAST, options)
      this.addColumnHeader(worksheet, colStart, "actual", "Actual", COLOR_ACTUAL, options)
      this.addColumnHeader(worksheet, colStart, "expected", "Anticipated", COLOR_EXPECTED, options)
      this.addColumnHeader(worksheet, colStart, "latestBestEstimate", `LBE (${isFuture ? (isCurrent ? "Actual+Forecast" : "Forecast") : "Actual"})`, isFuture ? COLOR_FORECAST : COLOR_ACTUAL, options)
    })

    worksheet.getRow(1).font = { bold: true }
    worksheet.getRow(1).height = 20
    worksheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" }
    worksheet.getRow(1).border = BORDER

    worksheet.getRow(2).font = { bold: true }
    worksheet.getRow(2).height = 20
    worksheet.getRow(2).alignment = { vertical: "middle", horizontal: "center", wrapText: true }
    worksheet.getRow(2).border = BORDER
  },

  addColumnHeader(worksheet, colStart, key, title, color, options) {
    const columnIdx = options.columnIndexes[key]
    if (!isNaN(columnIdx)) {
      worksheet.getCell(2, colStart + columnIdx).value = title
      worksheet.getCell(2, colStart + columnIdx).alignment = { horizontal: "center" }
      worksheet.getCell(2, colStart + columnIdx).fill = { type: "pattern", pattern: "solid", fgColor: { argb: color } }
      worksheet.getColumn(colStart + columnIdx).numFmt = '_($* #,##0_);_($* (#,##0);_($* "-"??_);_(@_)'
      worksheet.getColumn(colStart + columnIdx).width = 14
    }
  },

  addCashFlows(worksheet, report, categories, aggregate, options) {
    this.addCategory(worksheet, report, categories["Fixed Asset"], aggregate, options)
    this.addCategory(worksheet, report, categories["Income"], aggregate, options)
    this.addCategory(worksheet, report, categories["Expense"], aggregate, options)

    this.addSectionTotals(worksheet, report, "Net Income", "netIncomes", options)
    this.addSectionTotals(worksheet, report, "Unlevered Cash Flow", "unleveredTotals", options)
    this.addSectionPercents(worksheet, report, "Unlevered IRR", "unleveredIrr", options)

    this.addCategory(worksheet, report, categories["Liability"], aggregate, options)
    this.addSectionTotals(worksheet, report, "Levered Cash Flow", "leveredTotals", options)
    this.addSectionPercents(worksheet, report, "Levered IRR", "leveredIrr", options)
    this.addCategory(worksheet, report, categories["Equity"], aggregate, options)
    this.addSectionTotals(worksheet, report, "Retained Earnings", "retainedEarnings", options)
  },

  addCategory(worksheet, report, category, aggregate, options) {
    if (category) {
      const categoryRow = worksheet.addRow([category.name, ...this.getCategoryTotals(report, category.name, options)])
      categoryRow.font = { bold: true, color: { argb: "FFFFFFFF" } }
      categoryRow.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFA5A5A5" } }
      categoryRow.height = 20
      categoryRow.alignment = { vertical: "middle" }
      categoryRow.border = BORDER

      Object.values(category.categories).forEach((account) => {
        this.addAccount(worksheet, report, category, account, aggregate, options)
      })
    }
  },

  addSectionTotals(worksheet, report, title, totalName, options) {
    const totals = this.getSectionTotals(report, totalName, options)
    const sectionTotalRow = worksheet.addRow([title, ...totals])
    sectionTotalRow.height = 20
    sectionTotalRow.alignment = { vertical: "middle" }
    sectionTotalRow.border = BORDER

    sectionTotalRow.font = { bold: true, color: { argb: "FFFFFFFF" } }
    sectionTotalRow.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF000000" } }
  },

  addSectionPercents(worksheet, report, title, totalName, options) {
    const percents = this.getSectionTotals(report, totalName, options).map((value) => (value ? divide(value, 100) : value))
    const sectionTotalRow = worksheet.addRow([title, ...percents])
    sectionTotalRow.height = 20
    sectionTotalRow.alignment = { vertical: "middle" }
    sectionTotalRow.border = BORDER

    sectionTotalRow.font = { bold: true, color: { argb: "FFFFFFFF" } }
    sectionTotalRow.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF000000" } }
    sectionTotalRow.numFmt = "0.00%"
  },

  getSectionTotals(report, totalName, options) {
    const totals = []
    report.dates.forEach((date, idx) => {
      if (!isNaN(options.columnIndexes["forecast"])) {
        this.getSectionTotal(report, "forecast", totalName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["expected"])) {
        this.getSectionTotal(report, "expected", totalName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["actual"])) {
        this.getSectionTotal(report, "actual", totalName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["latestBestEstimate"])) {
        this.getSectionTotal(report, "latestBestEstimate", totalName, totals, idx)
      }
    })
    return totals
  },

  getSectionTotal(report, sectionName, totalName, totals, idx) {
    if (report[sectionName] && report[sectionName][totalName] && report[sectionName][totalName][idx]) {
      totals.push(report[sectionName][totalName][idx])
    } else {
      totals.push(0)
    }
  },

  getCategoryTotals(report, categoryName, options) {
    const totals = []
    report.dates.forEach((date, idx) => {
      if (!isNaN(options.columnIndexes["forecast"])) {
        this.getSectionCategoryTotal(report, "forecast", categoryName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["expected"])) {
        this.getSectionCategoryTotal(report, "expected", categoryName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["actual"])) {
        this.getSectionCategoryTotal(report, "actual", categoryName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["latestBestEstimate"])) {
        this.getSectionCategoryTotal(report, "latestBestEstimate", categoryName, totals, idx)
      }
    })
    return totals
  },

  getSectionCategoryTotal(report, sectionName, categoryName, totals, idx) {
    if (report[sectionName] && report[sectionName].categories[categoryName] && report[sectionName].categories[categoryName].totals[idx]) {
      totals.push(report[sectionName].categories[categoryName].totals[idx])
    } else {
      totals.push(0)
    }
  },

  addAccount(worksheet, report, category, account, aggregate, options) {
    const accountRow = worksheet.addRow([account.name, ...this.getAccountTotals(report, category.name, account.name, options)])
    accountRow.border = BORDER

    report.dates.forEach((date, idx) => {
      const colStart = idx * options.columnCount + 2
      const futureIdx = aggregate === "total" || moment(date).endOf(aggregate).isAfter(moment())
      this.styleAccountCell(worksheet, accountRow.number, colStart, "forecast", COLOR_FORECAST, options)
      this.styleAccountCell(worksheet, accountRow.number, colStart, "expected", COLOR_EXPECTED, options)
      this.styleAccountCell(worksheet, accountRow.number, colStart, "actual", COLOR_ACTUAL, options)
      this.styleAccountCell(worksheet, accountRow.number, colStart, "latestBestEstimate", futureIdx ? COLOR_FORECAST : COLOR_ACTUAL, options)
    })

    if (options.showSubAccounts && account.categories) {
      Object.values(account.categories).forEach((subAccount) => {
        this.addSubAccount(worksheet, report, category, account, subAccount, options)
      })
    }
  },

  styleAccountCell(worksheet, rowNumber, colStart, columnKey, color, options) {
    if (!isNaN(options.columnIndexes[columnKey])) {
      worksheet.getCell(rowNumber, colStart + options.columnIndexes[columnKey]).fill = { type: "pattern", pattern: "solid", fgColor: { argb: color } }
    }
  },

  getAccountTotals(report, categoryName, accountName, options) {
    const totals = []
    report.dates.forEach((date, idx) => {
      if (!isNaN(options.columnIndexes["forecast"])) {
        this.getAccountTotal(report, "forecast", categoryName, accountName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["expected"])) {
        this.getAccountTotal(report, "expected", categoryName, accountName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["actual"])) {
        this.getAccountTotal(report, "actual", categoryName, accountName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["latestBestEstimate"])) {
        this.getAccountTotal(report, "latestBestEstimate", categoryName, accountName, totals, idx)
      }
    })
    return totals
  },

  getAccountTotal(report, sectionName, categoryName, accountName, totals, idx) {
    if (
      report[sectionName] &&
      report[sectionName].categories[categoryName] &&
      report[sectionName].categories[categoryName].categories[accountName] &&
      report[sectionName].categories[categoryName].categories[accountName].totals[idx]
    ) {
      totals.push(report[sectionName].categories[categoryName].categories[accountName].totals[idx])
    } else {
      totals.push(0)
    }
  },

  addSubAccount(worksheet, report, category, account, subAccount, options) {
    const subAccountRow = worksheet.addRow(["- " + subAccount.name, ...this.getSubAccountTotals(report, category.name, account.name, subAccount.name, options)])
    subAccountRow.border = BORDER
    subAccountRow.font = { size: 10, color: { argb: "FF666666" } }
    // subAccountRow.hidden = !options.showSubAccounts

    report.dates.forEach((date, idx) => {
      const colStart = idx * options.columnCount + 2

      this.styleAccountCell(worksheet, subAccountRow.number, colStart, "forecast", COLOR_FORECAST, options)
      this.styleAccountCell(worksheet, subAccountRow.number, colStart, "expected", COLOR_EXPECTED, options)
      this.styleAccountCell(worksheet, subAccountRow.number, colStart, "actual", COLOR_ACTUAL, options)
      this.styleAccountCell(worksheet, subAccountRow.number, colStart, "latestBestEstimate", "FFFFFFFF", options)
    })
  },

  getSubAccountTotals(report, categoryName, accountName, subAccountName, options) {
    const totals = []
    report.dates.forEach((date, idx) => {
      if (!isNaN(options.columnIndexes["forecast"])) {
        this.getSubAccountTotal(report, "forecast", categoryName, accountName, subAccountName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["expected"])) {
        this.getSubAccountTotal(report, "expected", categoryName, accountName, subAccountName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["actual"])) {
        this.getSubAccountTotal(report, "actual", categoryName, accountName, subAccountName, totals, idx)
      }
      if (!isNaN(options.columnIndexes["latestBestEstimate"])) {
        this.getSubAccountTotal(report, "latestBestEstimate", categoryName, accountName, subAccountName, totals, idx)
      }
    })
    return totals
  },

  getSubAccountTotal(report, sectionName, categoryName, accountName, subAccountName, totals, idx) {
    if (
      report[sectionName] &&
      report[sectionName].categories[categoryName] &&
      report[sectionName].categories[categoryName].categories[accountName] &&
      report[sectionName].categories[categoryName].categories[accountName].categories[subAccountName] &&
      report[sectionName].categories[categoryName].categories[accountName].categories[subAccountName].totals[idx]
    ) {
      totals.push(report[sectionName].categories[categoryName].categories[accountName].categories[subAccountName].totals[idx])
    } else {
      totals.push(0)
    }
  },
}

export default ReportDownload
