import API from "@common/API"

/**
 * Service for administering Ecosuite Projects SoftLedger settings.
 */
const SoftLedgerAdminService = {
  getLocations() {
    return API.get<{ locations: unknown }>("/finance/softledger/locations").then((response) => {
      return response.locations
    })
  },
}

export default SoftLedgerAdminService
