import React from "react"

import EcosuiteComponent, { Error } from "@common/EcosuiteComponent"

import EnergyOverView from "./views/EnergyDashboardView"
import EnergyMapView from "./views/EnergyMapView"
import EnergyListView from "./views/EnergyListView"
import EnergySourceView from "./views/EnergySourceView"
import EnergyEventsView from "./views/EnergyEventsView"

import EnergyProjectOverView from "./views/EnergyDashboardProjectView"
import EnergyProjectMapView from "./views/EnergyMapProjectView"
import EnergyProjectInfoView from "./views/EnergyInfoProjectView"
import EnergyProjectLiveView from "./views/EnergyLiveProjectView"
import EnergyProjectGraphsView from "./views/EnergyGraphsProjectView"
import EnergyProjectPowerDashboardView from "./views/EnergyPowerDashboardProjectView"
import EnergyProjectSourceView from "./views/EnergySourceProjectView"
import EnergyProjectSourceGraphsView from "./views/EnergySourceGraphsProjectView"
import EnergyPhotoProjectView from "./views/EnergyPhotoProjectView"
import EconodeEnergyProjectView from "./views/EconodeEnergyProjectView"
import EconodeSavingGoals from "./views/EconodeSavingGoals"
import i18n from "src/i18n"

const { t } = i18n
export class EnergyModuleViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "overview":
        return <EnergyOverView {...this.props} />
      case "map":
        return <EnergyMapView {...this.props} />
      case "list":
        return <EnergyListView {...this.props} />
      case "source":
        return <EnergySourceView {...this.props} />
      case "events":
        return <EnergyEventsView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}` + this.props.view }} />
    }
  }
}

export class EnergyProjectViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "overview":
        return <EnergyProjectOverView {...this.props} />
      case "energy":
        return (
          <EconodeEnergyProjectView
            {...this.props}
            datums={this.props.econodeDatums?.projectDatums}
            actualRange={this.props.econodeAcutalRange}
            previousRange={this.props.econodePreviousRange}
            previousReadings={this.props.econodePreviousReadings}
          />
        )
      case "savings":
        return <EconodeSavingGoals {...this.props} />
      case "map":
        return <EnergyProjectMapView {...this.props} />
      case "info":
        return <EnergyProjectInfoView {...this.props} />
      case "graphs":
        return <EnergyProjectGraphsView {...this.props} />
      case "live":
        return <EnergyProjectLiveView {...this.props} />
      case "power":
        return <EnergyProjectPowerDashboardView {...this.props} />
      case "source":
        return <EnergyProjectSourceView {...this.props} />
      case "sourceGraphs":
        return <EnergyProjectSourceGraphsView {...this.props} />
      case "events":
        return <EnergyEventsView {...this.props} />
      case "photos":
        return <EnergyPhotoProjectView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}` + this.props.view }} />
    }
  }
}
