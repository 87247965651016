import React from "react"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"

export default class Consumption extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentValid(this.props.readings) && this.props.readings.consumption !== undefined) {
      return (
        <div className="sidebar-heading" id={this.props.id}>
          {EnergyUtils.displayWattHours(this.props.readings.consumption)}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
