import API from "@common/API"
import Logger from "@common/Logger"

const ProjectService = {
  getProjectContacts(projectId: string): Promise<unknown> {
    return API.get<{ project: unknown }>(`/projects/${projectId}/contacts`)
      .then((data) => {
        return data.project
      })
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: "Unable to retrieve contacts for project: " + projectId })
      })
  },
}

export default ProjectService
