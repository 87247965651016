import React from "react"
import { Button, Alert } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm from "@common/form/EcosuiteForm"
import Logger from "@common/Logger"

import UserAdminService from "../UserAdminService"

import UserTypeSchema from "./UserTypeSchema"
import { UserGroupSelector, UserTypeSelector } from "../widgets"
import Icon from "@common/display/Icon"
import TimedMessage from "@common/display/TimedMessage"
import i18n from "src/i18n"

const { t } = i18n
var jsprim = require("jsprim")

const LAST_PAGE_OF_FORM = 1

export default class EditUserType extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentFormPage: 0,
      currentPageSchema: null,
      formData: this.props.userType,
    }
    this.cancel = this.cancel.bind(this)
    this.editUserType = this.editUserType.bind(this)
    this.deleteUserType = this.deleteUserType.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    // Get schema for Modules page
    UserTypeSchema.getUserTypeSchema().then(([userTypeSchema, userGroupSchema]) => {
      this.setStateIfMounted({ userTypeSchema: userTypeSchema }, () => {
        // Once the schema is loaded we can update the user type
        this.setStateIfMounted({ formData: this.sanitiseUserType() })
      })

      this.setStateIfMounted({ userGroupSchema: userGroupSchema })
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.userType !== prevProps.userType) {
      this.setState({
        formData: this.sanitiseUserType(),
        error: undefined,
        success: undefined,
      })
    }
  }

  sanitiseUserType() {
    // EP-1906 We create a copy of the userType in question and make sure any invalid views not in the current schema are removed
    const userType = jsprim.deepCopy(this.props.userType)
    Object.keys(userType.modules).forEach((moduleAppId) => {
      const app = userType.modules[moduleAppId]

      Object.keys(app).forEach((moduleId) => {
        const module = app[moduleId]
        const appDependency = this.state.userTypeSchema.dependencies.application.oneOf.find(
          (dependency) => dependency.properties.application.enum[0] === userType.application,
        )
        const appViews = appDependency.properties.modules.properties[moduleAppId].properties
        const supportedPortfolioViews = appViews[moduleId].properties.portfolioViews.items.enum
        const supportedProjectViews = appViews[moduleId].properties.projectViews.items.enum

        // Would throw error if either portfolioViews or projectViews does not exist
        module.portfolioViews = module.portfolioViews?.filter((viewId) => supportedPortfolioViews.includes(viewId))
        module.projectViews = module.projectViews?.filter((viewId) => supportedProjectViews.includes(viewId))

        // sanitise data -> recordTypes
        if (module.recordTypes) {
          Object.keys(module.recordTypes).forEach((recordType) => {
            module.recordTypes[recordType] = module.recordTypes[recordType].filter((recordTypeItem) =>
              appViews[moduleId].properties.recordTypes.properties[recordType].items.enum.includes(recordTypeItem),
            )
          })
        }
      })
    })

    return userType
  }

  editUserType(formData) {
    this.setState({ error: undefined, success: false, loading: true })
    UserAdminService.editUserType(this.props.userType.id, formData)
      .then((response) => {
        this.setStateIfMounted({ success: true, loading: false })
        this.props.userTypeChanged(response.userType)
      })
      .catch((err) => {
        Logger.error(err)
        this.setState({ error: err, success: false, loading: false })
      })
  }

  deleteUserType() {
    const confirm = window.confirm(`${t("settings.messages.confirm_delete_userType")}: ${this.props.userType.name}?`)
    if (confirm) {
      this.setState({ loading: true })
      UserAdminService.deleteUserType(this.props.userType.id).then(() => {
        this.props.userTypeDeleted()
      })
    }
  }

  getCurrentPageSchema() {
    switch (this.state.currentFormPage) {
      case 0:
        return this.state.userTypeSchema
      case 1:
        return this.state.userGroupSchema
      default:
        return null
    }
  }

  cancel() {
    this.props.selectUserType()
  }

  renderFormButtons() {
    switch (this.state.currentFormPage) {
      // Display these buttons when on first page of the form
      case 0:
        return (
          <Button color="primary" type="submit">
            {`${t("labels.next")}\t`}
            <Icon icon="arrow_forward" />
          </Button>
        )
      // Display these buttons when on last page of the form
      case LAST_PAGE_OF_FORM:
        return (
          <>
            <Button
              color="primary"
              type="submit"
              onClick={() => this.setState({ currentFormPage: this.state.currentFormPage - 1 })}
            >
              <Icon icon="arrow_back" />
              {`\t${t("labels.back")}`}
            </Button>
            <Button color="primary" type="submit">
              {t("buttons.submit")}
            </Button>
          </>
        )
      // Display these buttons when in the middle of the form
      default:
        return (
          <>
            <Button
              color="primary"
              type="submit"
              onClick={() => this.setState({ currentFormPage: this.state.currentFormPage - 1 })}
            >
              <Icon icon="arrow_back" />
              {`\t${t("labels.back")}`}
            </Button>
            <Button color="primary" type="submit">
              {`${t("labels.next")}\t`}
              <Icon icon="arrow_forward" />
            </Button>
          </>
        )
    }
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.userTypeSchema && this.state.userGroupSchema) {
      let error = this.getError()
      return (
        <div className="ecogy-form admin-tab-content-area">
          <EcosuiteForm
            schema={this.getCurrentPageSchema()}
            uiSchema={UserTypeSchema.getUserTypeUISchema()}
            formData={this.state.formData}
            onChange={this.formDataChanged}
            onSubmit={({ formData }) => {
              if (this.state.currentFormPage === LAST_PAGE_OF_FORM) {
                this.editUserType({ ...this.state.formData, ...formData })
              } else {
                this.setState({
                  currentFormPage: this.state.currentFormPage + 1,
                  formData: { ...this.state.formData, ...formData },
                })
              }
            }}
            widgets={{
              userTypeSelector: UserTypeSelector,
              userGroupSelector: UserGroupSelector,
              //CheckboxWidget: CheckboxesWidget
            }}
          >
            <div className="admin-footer float-end">
              <div className="admin-footer__form-buttons">
                <Button color="danger" onClick={this.cancel}>
                  {t("buttons.cancel")}
                </Button>
                {this.renderFormButtons()}
                {/* TODO We need to check if there are users still assigned before allowing them to be deleted */}
                {/* <Button color="danger" onClick={this.deleteUserType}>
                Delete
              </Button> */}
              </div>
            </div>
          </EcosuiteForm>
          {this.state.success ? (
            <TimedMessage delay={8000}>
              <Alert color="info">{t("settings.messages.userType_updated")}</Alert>
            </TimedMessage>
          ) : null}
          {error ? <Alert color="danger">{error}</Alert> : null}
        </div>
      )
    } else {
      return <Loading />
    }
  }

  getError() {
    if (this.state && this.state.error) {
      return this.state.error.message ? this.state.error.message : this.state.error
    }
    return null
  }
}
