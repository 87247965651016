import React, { Component } from "react"
import { Input } from "reactstrap"

/**
 * A custom NumberField that renders an input of type number that has the default browser scrolling disabled.
 * We don't seem to be easily able to modify the "react-jsonschema-form/lib/components/fields/NumberField" field so render an entirely custom component.
 */
export default class EcosuiteNumberField extends Component {
  constructor(props) {
    super(props)

    this.state = { value: props.formData ? props.formData : "" }

    this.onChange = this.onChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.formData !== prevProps.formData) {
      this.setState({ value: this.props.formData ? this.props.formData : "" })
    }
  }

  onChange(event) {
    this.setState({ value: event.target.value }, () => {
      this.props.onChange(event.target.value === "" ? undefined : Number(event.target.value))
    })
  }

  render() {
    // If there's a custom widget configured, make sure that we use that to render the field
    if (this.props.uiSchema && this.props.uiSchema["ui:widget"]) {
      const widget = this.props.registry.widgets[this.props.uiSchema["ui:widget"]]

      const options = this.props.uiSchema && this.props.uiSchema["ui:options"]
      const placeholder = this.props.uiSchema && this.props.uiSchema["ui:placeholder"]

      // Convert Field props into Widget props following this specification:
      // https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-widgets-fields/#adding-your-own-custom-widgets
      return widget({
        id: this.props.idSchema?.$id,
        label: this.props.schema?.title ? this.props.schema.title : this.props.name,
        schema: this.props.schema,
        uiSchema: this.props.uiSchema,
        value: this.props.formData,
        placeholder: placeholder,
        required: this.props.required,
        disabled: this.props.disabled,
        readonly: this.props.readonly,
        autofocus: this.props.autofocus,
        onChange: this.props.onChange,
        onBlur: this.props.onBlur,
        onFocus: this.props.onFocus,
        options: options,
        formContext: this.props.formContext,
        rawErrors: this.props.rawErrors,
      })
    }

    return (
      <div>
        <Input type="number" disabled={this.props.disabled} readOnly={this.props.readonly} value={this.state.value} onChange={this.onChange} onWheel={(e) => e.target.blur()} />
      </div>
    )
  }
}
