import API, { IRange } from "@common/API"

const EventService = {
  warmCache() {
    API.cacheGet("/schemas/event")
  },

  getEventSchema() {
    return API.getFromCache("/schemas/event")
  },

  listEvents(range: IRange, type: string, projectId: string, siteId: string) {
    let url = API.addRangeToUrl("/events", range)
    if (type) {
      url += "&type=" + type
    }
    if (projectId) {
      url += "&project=" + projectId
    }
    if (siteId) {
      url += "&site=" + siteId
    }
    return API.get(url as string)
  },

  async getEvent(eventId: string) {
    return API.get<{ event: unknown }>("/events/" + eventId).then((response) => response.event)
  },

  async createEvent(formData: unknown) {
    return API.post<{ event: unknown }, unknown>("/events", formData).then((response) => response.event)
  },

  async updateEvent(eventId: string, formData: unknown) {
    return API.put<{ event: unknown }, unknown>("/events/" + eventId, formData).then((response) => response.event)
  },

  resolveEvent(eventId: string) {
    return API.put("/events/" + eventId + "/resolve")
  },

  deleteEvent(eventId: string) {
    return API.delete("/events/" + eventId)
  },
}

export default EventService
