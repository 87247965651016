import React from "react"
import { Button, ButtonGroup, ButtonToolbar, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import moment from "moment"
import TableToExcel from "@linways/table-to-excel"

import EcosuiteView from "@common/module/EcosuiteView"
import { Loading, Error, Message } from "@common/EcosuiteComponent"

import RecordService from "@dashboard/data/record/RecordService"
import RecordPayments from "./records/RecordPayments"

import "./records/RecordPayments.css"
import ProFormaService from "@dashboard/data/pro-forma/ProFormaService"
import Logger from "@common/Logger"
import FinanceUtils from "../FinanceUtils"
import i18n from "src/i18n"

const { t } = i18n
export default class RecordPaymentsProjectView extends EcosuiteView {
  constructor(props) {
    super(props, "finance-contracts")

    this.toggle = this.toggle.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadContracts()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadContracts()
    }
  }

  async loadContracts() {
    const projectId = this.props.project.code
    ProFormaService.getProjectProForma(projectId)
      .then(async (proForma) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ payments: null, error: null })

          const contractCashFlows = proForma.cashFlows
            ? proForma.cashFlows.filter((cashFlow) => {
                return cashFlow.recordId && cashFlow.payments.find((payment) => FinanceUtils.isOperatingRevenuePayment(cashFlow, payment) || payment.paymentType === "tariff")
              })
            : []

          const contractPayments = await Promise.all(
            contractCashFlows.map((contractCashFlow) => {
              return RecordService.getRecordPayments(contractCashFlow.recordId)
            }),
          )

          this.setStateIfMounted({ payments: contractPayments, activeTab: contractPayments.length ? 0 : null })
        } else {
          Logger.debug(`Ignoring out of date response for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ error: err })
        }
      })
  }

  renderMainView() {
    if (this.state.error) {
      return <Error error={this.state.error} />
    }
    if (this.state.payments) {
      if (!this.state.payments) {
        return <Message message={t("economics.messages.no_record_payments_available")} />
      }
      return (
        <div className="record-payments">
          <Nav tabs className="record-payment-tabs">
            {this.state.payments.map((recordPaymentDetails, idx) => {
              return (
                <NavItem key={idx}>
                  <NavLink
                    className={this.state.activeTab === idx ? "active" : null}
                    onClick={() => {
                      this.toggle(idx)
                    }}
                  >
                    {recordPaymentDetails.record.name}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            {this.state.payments.map((recordPaymentDetails, idx) => {
              return (
                <TabPane tabId={idx} key={idx}>
                  <RecordPayments recordPaymentDetails={recordPaymentDetails} />
                </TabPane>
              )
            })}
          </TabContent>
        </div>
      )
    } else {
      return <Loading message={t("loadingMsg.loading_record_payment")} />
    }
  }

  getActiveRecordPaymentDetails() {
    if (this.state.payments) {
      return this.state.payments[this.state.activeTab]
    }
  }

  renderViewControls() {
    const recordPaymentDetails = this.getActiveRecordPaymentDetails()
    return (
      <div className="content-view-controls search-bar">
        <ButtonToolbar className="float-end">
          <ButtonGroup className="header-button-group" size="sm">
            {recordPaymentDetails ? (
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  TableToExcel.convert(document.getElementById(`table-${recordPaymentDetails.record.id}`), {
                    name: `${this.props.project.code}_${recordPaymentDetails.record.name}_${moment().format("YYYY-MM-DD")}.xlsx`,
                  })
                }}
              >
                {t("buttons.download")}
              </Button>
            ) : null}
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }
}
