import React from "react"
import { Button, UncontrolledCollapse } from "reactstrap"

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu-v2"

import EcosuiteComponent from "@common/EcosuiteComponent"
import DocumentService from "@dashboard/data/DocumentService"
import moment from "moment"
import { RecordDocumentUpload } from "./RecordDocumentUpload"
import { RecordDocumentEdit } from "./RecordDocumentEdit"
import RecordService from "../../RecordService"
import RecordUtils from "../../RecordUtils"
import { RecordFolderEdit } from "./RecordFolderEdit"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n

export class RecordDocuments extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.selectDocument = this.selectDocument.bind(this)
    this.selectFolder = this.selectFolder.bind(this)
    this.canSeeArchivedDocuments = this.canSeeArchivedDocuments.bind(this)
  }

  selectFolder(folder) {
    this.setStateIfMounted({ folder: folder })
  }

  previewFile(document) {
    DocumentService.viewFile(document.fileKey).then((url) => {
      const documentParts = document.fileKey.split("/")
      const previewDocument = { documentUrl: url, documentName: documentParts[documentParts.length - 1] }
      this.props.actions.previewDocument(previewDocument)
    })
  }

  selectDocument(document) {
    this.props.actions.selectDocument(document)
  }

  downloadFiles(fileName) {
    this.setStateIfMounted({ loading: `${t("loadingMsg.generating_download")}: ${fileName}` })
    DocumentService.downloadFiles(
      fileName,
      this.props.recordDocuments.map((document) => document.fileKey),
    ).then(() => {
      this.setStateIfMounted({ loading: null })
    })
  }

  canSeeArchivedDocuments = (username = "") => {
    // TODO: move
    const orgOwners = ["ecogysolar.com", "ecogyenergy.com"]
    const [, domain] = username.split("@")
    return orgOwners.includes(domain)
  }

  render() {
    if (this.props.record && this.props.record.id) {
      // We only show documents for existing records
      return (
        <div>
          <h3 /* style={{fontSize: "1.75rem", marginBottom: "0.5rem"}} */>{t("headings.documents")}</h3>
          <div className="side-panel-section">
            {!this.props.readonly && <RecordDocumentUpload {...this.props} />}
            {this.state.loading ? (
              this.state.loading
            ) : this.props.restrictions.preventDownload === "no" ? (
              <Button
                title={t("buttons.download_all")}
                className="download float-end"
                onClick={() => {
                  this.downloadFiles(`record-${this.props.record.name}-documents.zip`)
                }}
                size="sm"
                disabled={!this.props.recordDocuments || this.props.recordDocuments.length < 1}
              >
                {t("buttons.download_all")}
              </Button>
            ) : null}
          </div>
          {this.renderDocumentGroups()}
          <RecordDocumentEdit
            groups={this.props.groups}
            record={this.props.record}
            document={this.props.document}
            selectDocument={this.selectDocument}
            actions={this.props.actions}
          />
          <RecordFolderEdit
            groups={this.props.groups}
            record={this.props.record}
            typeHierarchy={this.props.typeHierarchy}
            folder={this.state.folder}
            documents={this.props.recordDocuments}
            selectFolder={this.selectFolder}
            actions={this.props.actions}
          />
        </div>
      )
    } else {
      return null
    }
  }

  renderDocumentGroups() {
    if (!this.props.record || !this.props.recordDocuments) {
      return t("labels.loading")
    } else if (this.props.record && this.props.recordDocuments.length) {
      const groupedDocuments = RecordUtils.groupDocuments(this.props.recordDocuments)
      return (
        <div className="side-panel-section">
          {Object.keys(groupedDocuments.folders)
            .sort()
            .map((folderName, folderIdx) => {
              return (
                <div key={folderName}>
                  {this.renderFolder(folderName, folderIdx, groupedDocuments.folders[folderName])}
                </div>
              )
            })}

          {groupedDocuments.unassigned.length
            ? this.renderFolder(null, groupedDocuments.folders.length + 1, groupedDocuments.unassigned)
            : null}
        </div>
      )
    } else {
      return <div>{t("alertsInfo.no_documents")}</div>
    }
  }

  renderFolder(folderName, folderIdx, recordDocuments) {
    const documents = recordDocuments.sort((a, b) => moment(b.updated) - moment(a.updated))
    const archived = documents
      .filter((document) => document.subFolderName === "Archive")
      .sort((a, b) => a.updated.localeCompare(b.updated))
      .reverse()
    const displayName = folderName ? folderName : "Unassigned Folder"
    const hasWritePermission = this.props.groups.includes("data-write")

    return (
      <div className="folder">
        <ContextMenuTrigger renderTag="div" id={"folder-row-" + displayName}>
          <h4 id={`folder-${folderIdx}`}>
            {folderName ? folderName : t("headings.unassigned_folder")}
            <Icon icon="arrow_drop_down" />
          </h4>
        </ContextMenuTrigger>
        {hasWritePermission && (
          <ContextMenu id={"folder-row-" + displayName}>
            <MenuItem
              onClick={() => {
                this.selectFolder(folderName)
              }}
            >
              <Icon icon="edit" /> {t("buttons.edit")}
            </MenuItem>
          </ContextMenu>
        )}

        <UncontrolledCollapse toggler={`#folder-${folderIdx}`}>
          {documents.map((document, idx) => {
            if (document.subFolderName !== "Archive") {
              return <div key={idx}>{this.renderDocument(document)}</div>
            }
          })}
          {archived.length && this.canSeeArchivedDocuments(this.props.userName) ? (
            <React.Fragment>
              <h5 id={`archive-${folderIdx}`}>
                <Icon icon="folder_open" /> Archive
                <Icon icon="arrow_drop_down" className="float-end" />
              </h5>
              <UncontrolledCollapse toggler={`#archive-${folderIdx}`}>
                {archived.map((document, idx) => {
                  return <div key={idx}>{this.renderDocument(document)}</div>
                })}
              </UncontrolledCollapse>
            </React.Fragment>
          ) : null}
        </UncontrolledCollapse>
      </div>
    )
  }

  renderDocument(document) {
    const hasWritePermission = this.props.groups.includes("data-write")

    return (
      <div className="folder-document">
        <ContextMenuTrigger renderTag="div" id={"project-row-" + document.fileKey}>
          <div
            className="folder-document-click-wrapper"
            onClick={() => {
              this.previewFile(document)
            }}
          >
            <span style={{ clear: "right", float: "right" }}>{moment(document.updated).format("lll")} </span>
            <span>{document.fileName} </span>
          </div>
        </ContextMenuTrigger>

        <ContextMenu id={"project-row-" + document.fileKey}>
          <MenuItem
            onClick={() => {
              this.previewFile(document)
            }}
          >
            <Icon icon="preview" /> {t("labels.preview")}
          </MenuItem>

          {hasWritePermission && (
            <MenuItem
              onClick={() => {
                this.selectDocument(document)
              }}
            >
              <Icon icon="edit" /> {t("buttons.edit")}
            </MenuItem>
          )}

          {hasWritePermission && (
            <MenuItem
              onClick={() => {
                if (window.confirm(`${t("data.confirmation_msg.delete_document")}: ` + document.fileName)) {
                  DocumentService.deleteFile(document.fileKey, this.props.groups).then(() => {
                    this.props.actions.loadDocuments(this.props.record)
                  })
                }
              }}
            >
              <Icon icon="delete" /> {t("buttons.delete")}
            </MenuItem>
          )}

          {this.props.restrictions.preventDownload === "no" && (
            <MenuItem
              onClick={() => {
                DocumentService.downloadFile(document.fileKey)
              }}
            >
              <Icon icon="download" /> {t("buttons.download")}
            </MenuItem>
          )}

          {hasWritePermission && document.subFolderName !== "Archive" ? (
            <MenuItem
              onClick={() => {
                if (window.confirm(`${t("data.confirmation_msg.archive_document")}: ` + document.fileName)) {
                  RecordService.archiveFile(document.fileKey, this.props.groups).then(() => {
                    this.props.actions.loadDocuments(this.props.record)
                  })
                }
              }}
            >
              <Icon icon="archive" /> {t("buttons.archive")}
            </MenuItem>
          ) : (
            hasWritePermission && (
              <MenuItem
                onClick={() => {
                  if (window.confirm(`${t("data.confirmation_msg.unarchive_document")}: ` + document.fileName)) {
                    RecordService.unarchiveFile(document.fileKey, this.props.groups).then(() => {
                      this.props.actions.loadDocuments(this.props.record)
                    })
                  }
                }}
              >
                <Icon icon="archive" /> {t("buttons.unarchive")}
              </MenuItem>
            )
          )}
        </ContextMenu>
      </div>
    )
  }
}
