import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import Logger from "@common/Logger"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import ProjectUtils from "@common/utils/ProjectUtils"
import Aggregations from "@common/Aggregations"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import GraphUtils from "@common/utils/GraphUtils"

import EnergyService from "@dashboard/energy/EnergyService"
import WeatherService from "@dashboard/energy/WeatherService"
import DateRangeGraph from "../DateRangeGraph"
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import DeviceUtils from "@common/utils/DeviceUtils"

export default class SourceGenerationLineGraph extends EcosuiteComponent {
  componentDidMount() {
    super.componentDidMount()
    this.getDaylight()
    this.loadDatums()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.project !== prevProps.project || this.props.site !== prevProps.site || this.props.range !== prevProps.range) {
      this.getDaylight()
      this.loadDatums()
    }
  }

  loadDatums() {
    const range = this.props.range
    const aggregation = this.props.aggregation
    const projectId = this.props.project.code
    EnergyService.getNodesDatums(range, aggregation, projectId, undefined, undefined, ProjectUtils.getPath(this.props.project, this.props.site) + "/**/INV/**").then((response) => {
      if (this.isProjectCurrent(projectId) && this.isAggregationCurrent(aggregation) && this.isRangeCurrent(range)) {
        const devices = {}
        Object.values(this.props.site.systems).forEach((system) => {
          system.devices.forEach((device) => {
            if (DeviceUtils.isInverter(device)) {
              devices[ProjectUtils.getPath(this.props.project, this.props.site, system, device)] = device
            }
          })
        })

        const sources = this.getSources(devices, response.data, response.range, response.aggregation)
        this.setStateIfMounted({ sources: sources, range: response.range, aggregation: response.aggregation })
      } else {
        Logger.debug(`Ignoring out of date repsonse`)
      }
    })
  }

  getDaylight() {
    const range = this.props.range
    const projectId = this.props.project.code
    WeatherService.getProjectDaylight(projectId, range).then((response) => {
      if (this.isProjectCurrent(projectId) && this.isRangeCurrent(range)) {
        this.setStateIfMounted({ daylightMinutes: response.daylight.sites[this.props.site.code] })
      } else {
        Logger.debug(`Ignoring out of date response`)
      }
    })
  }

  getSources(devices, datums, range, aggregation) {
    const sourceIds = Object.keys(devices)
    // Logger.debug(`Loading sources for unit: ${unit} and system: ${systemId}`)
    datums = datums.filter((datum) => sourceIds.includes(datum.sourceId))

    let sources = {}
    let date = moment(range.localStartDate)
    let end = moment(range.localEndDate)
    while (date.isBefore(end)) {
      let dateKey = date.format("YYYY-MM-DDTHH:mm")
      sources[dateKey] = {
        time: moment(dateKey).valueOf(),
      }
      date.add(Aggregations.getSize(aggregation), Aggregations.getUnits(aggregation))
    }

    // let sourceIds = new Set()
    datums.forEach((datum) => {
      //   sourceIds.add(datum.sourceId) // keep track of any sourceIds we come across
      const dateKey = `${datum.localDate}T${datum.localTime}`
      const daylightHours = this.state.daylightMinutes[dateKey] / 60
      if (datum.wattHours) {
        if (sources[dateKey]) {
          sources[dateKey][datum.sourceId] = EnergyUtils.getNormalisedGeneration(devices[datum.sourceId].dcSize, datum.wattHours, aggregation, daylightHours)
        } else {
          Logger.warning(`Ignoring unexpected local date in results: ${dateKey} for source: ${datum.sourceId}`)
        }
      }
    })
    // Logger.debug(`Loaded data for sources: ${Array.from(sourceIds)}`)
    return {
      sourceIds: Array.from(sourceIds),
      data: Object.values(sources),
    }
  }

  renderContent() {
    if (this.state.daylightMinutes && this.state.sources) {
      return <SourceGraph {...this.props} range={this.state.range} aggregation={this.state.aggregation} sources={this.state.sources} />
    } else {
      return <Loading />
    }
  }
}

class SourceGraph extends DateRangeGraph {
  renderContent() {
    let sources = this.props.sources
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={sources.data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
          <XAxis
            dataKey="time"
            type="number"
            scale="time"
            domain={this.getDomain(this.props.range, this.props.aggregation)}
            tickFormatter={(time) => {
              return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
            }}
          />
          <YAxis />
          <Tooltip content={<SourceTooltip unit={this.props.unit} range={this.props.range} aggregation={this.props.aggregation} />} />
          <Legend iconType="plainline" />
          {sources.sourceIds.map((sourceId, idx) => {
            const color = GRAPH_COLORS[idx % sources.sourceIds.length]
            const graphType = this.props.aggregation === "day" || this.props.aggregation === "month" || this.props.aggregation === "year" ? "linear" : "monotone"
            return <Line key={sourceId} name={sourceId} type={graphType} dataKey={sourceId} stroke={color} fill={color} dot={true} isAnimationActive={false} />
          })}
          {this.renderCurrentTime()}
        </LineChart>
      </ResponsiveContainer>
    )
  }
}

class SourceTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {payload.map((data) => {
              return (
                <React.Fragment key={data.dataKey}>
                  <p className="label" style={{ color: data.fill }}>
                    {`${data.name} : ${data.value}`}
                  </p>
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }
    return null
  }
}
