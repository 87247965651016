import React from "react"
import EventService from "@dashboard/event/EventService"

import EconodeModule from "../EconodeModule"

import "@dashboard/event/EventModule.css"
import UserAlertService from "@dashboard/event/UserAlertService"
import { EcosuiteComponentError } from "@common/EcosuiteComponent"
import UserAlertsView from "@dashboard/event/views/UserAlertsView"
import EventView from "@dashboard/event/views/EventView"
import Logger from "@common/Logger"

const EVENT_TYPES = ["energy", "data", "solarnetwork", "other"]

class EventModule extends EconodeModule {
  constructor(props) {
    super(props, "econode-event")

    this.loadEvents = this.loadEvents.bind(this)
    this.eventUpdated = this.eventUpdated.bind(this)
    this.eventDeleted = this.eventDeleted.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadEvents(this.getExclusiveRange())
    this.loadUserAlerts(this.getExclusiveRange())
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (this.props.loadTime !== prevProps.loadTime) {
      this.loadEvents(this.getExclusiveRange())
      this.loadUserAlerts(this.getExclusiveRange())
    }
  }

  selectRange(rangeName, customRange) {
    let range = super.selectRange(rangeName, customRange)
    this.loadEvents(range)
  }

  loadEvents(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setState({
      events: undefined,
    })
    EventService.listEvents(range).then((data) => {
      if (this.isRangeCurrent(range)) {
        const events = data.events.sort((a, b) => {
          if (a.startDate === b.startDate) {
            return a.cause.localeCompare(b.cause)
          }
          return a.startDate.localeCompare(b.startDate)
        })

        this.setStateIfMounted({
          events: events,
        })
      } else {
        Logger.debug(`Ignoring out of date response for range: ${range}`)
      }
    })
  }

  loadUserAlerts(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        userAlerts: undefined,
      },
      () => {
        UserAlertService.getUserAlerts(this.props.userId, range)
          .then((alerts) => {
            if (this.isRangeCurrent(range)) {
              this.setStateIfMounted({
                userAlerts: alerts,
              })
            } else {
              Logger.debug(`Ignoring out of date response for range: ${range}`)
            }
          })
          .catch((err) => {
            Logger.error(err)
            if (this.isRangeCurrent(range)) {
              this.setStateIfMounted({
                userAlerts: new EcosuiteComponentError(err),
              })
            }
          })
      },
    )
  }

  eventUpdated(event) {
    if (event) {
      const events = this.state.events
      if (events) {
        let found = false
        events.forEach((listEvent, idx) => {
          if (listEvent.id === event.id) {
            events[idx] = event // replace the matching event
            found = true
          }
        })

        if (!found) {
          events.push(event)
        }
      }

      this.setStateIfMounted({
        events: events,
      })
    } else {
      this.loadEvents(this.getExclusiveRange())
    }
  }

  eventDeleted() {
    this.loadEvents(this.getExclusiveRange())
  }

  renderView() {
    if (this.props.project) {
      if (this.state.projectView === "userAlerts") {
        return this.renderAlerts()
      } else {
        return this.renderEvents()
      }
    } else {
      if (this.state.moduleView === "userAlerts") {
        return this.renderAlerts()
      } else {
        return this.renderEvents()
      }
    }
  }

  renderEvents() {
    return <EventView {...this.props} events={this.state.events} actions={{ eventUpdated: this.eventUpdated, eventDeleted: this.eventDeleted }} types={EVENT_TYPES} />
  }

  renderAlerts() {
    const alerts =
      this.props.project && this.state.userAlerts
        ? this.state.userAlerts.filter((alert) => (alert.sourceId && alert.sourceId.startsWith("/" + this.props.project.code)) || alert.message.startsWith("/" + this.props.project.code))
        : this.state.userAlerts
    return <UserAlertsView userAlerts={alerts} />
  }
}

export default EventModule
