import API from "@common/API"

var WeatherService = {
  getSiteWeather(projectId, siteId, range) {
    let url = API.addRangeToUrl("/weather/projects/" + projectId + "/sites/" + siteId, range)
    return API.getFromCache(url, API.getDefaultCacheTimeout())
  },

  getProjectDaylight(projectId, range) {
    let url = API.addRangeToUrl("/weather/daylight/projects/" + projectId, range)
    return API.getFromCache(url, API.getDefaultCacheTimeout())
  },
}

export default WeatherService
