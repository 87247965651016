import React from "react"
import { create, all } from "mathjs"

import EnergyUtils from "../../EnergyUtils"
import i18n from "src/i18n"

const math = create(all)
const { t } = i18n

const ProjectsTableSumsAvgs: React.FC<{
  // Prop Types
  showGeneration: boolean
  showConsumption: boolean
  showStorage: boolean
  totals: Record<string, number>
  specificYieldValues: Array<number>
}> = ({
  // Props
  showGeneration,
  showConsumption,
  showStorage,
  totals,
  specificYieldValues,
}) => {
  const averageSpecificYeild = specificYieldValues.length ? specificYieldValues.reduce((sum, value) => sum + value, 0) / specificYieldValues.length : 0
  return (
    <tr>
      <th colSpan={2}>{t("energy.labels.totals_and_averages")}</th>
      {showGeneration && !showConsumption ? (
        <>
          <td id="projects-table__total-dc-size" className="projects-table__averages">
            {EnergyUtils.formatNumber(totals.totalDCSize)}
          </td>
          <td id="projects-table__total-ac-size" className="projects-table__averages">
            {EnergyUtils.formatNumber(totals.totalACSize)}
          </td>
        </>
      ) : null}
      {showConsumption && !showGeneration ? <td id="col-peak-power" title={`${t("table_headings.expected_peak")}`}></td> : null}
      {showStorage ? (
        <>
          <td className="projects-table__averages" title={`${t("labels.storage")}`}></td>
        </>
      ) : null}
      {showConsumption ? (
        <>
          <td className="projects-table__averages" title={`${t("labels.consumption")}`}></td>
          <td className="projects-table__averages" title={`${t("labels.forecase")}`}></td>
          <td className="projects-table__averages" title="%"></td>

          {!showGeneration ? (
            <>
              <td className="projects-table__averages" title={`${t("labels.peak")}`}></td>
              <td className="projects-table__averages" title="%"></td>
              <td className="projects-table__averages" title={`${t("labels.cost")}`}></td>
            </>
          ) : null}
        </>
      ) : null}
      {showGeneration ? (
        <>
          <td id="projects-table__total-gen" className="projects-table__averages">
            {EnergyUtils.formatNumberAsKilo(totals.totalGen)}
          </td>
          <td id="projects-table__total-gen-expected" className="projects-table__averages">
            {EnergyUtils.formatNumberAsKilo(totals.totalExpectedGen)}
          </td>
          <td id="projects-table__average-gen-expected-percent" className="projects-table__averages">
            {math.round?.((totals.weightedExpectation / totals.expectationWeights || 0) * 100)}
          </td>
          <td id="projects-table__total-gen-forecast" className="projects-table__averages">
            {EnergyUtils.formatNumberAsKilo(totals.totalForecastGen)}
          </td>
          <td id="projects-table__average-gen-forecast-percent" className="projects-table__averages">
            {math.round?.((totals.weightedForecast / totals.forecastWeights || 0) * 100)}
          </td>
          <td id="projects-table__specific-yeild" className="projects-table__averages">
            {EnergyUtils.formatNumberAsKilo(math.round(averageSpecificYeild, 2) )}
          </td>
          <td id="projects-table__average-gen-normalised" className="projects-table__averages">
            {math.round?.(totals.weightedNormalisedGen / totals.normalisedGenWeights || 0, 2)}
          </td>

          {!showConsumption ? (
            <>
              <td id="projects-table__total-gen-peak" className="projects-table__averages">
                {EnergyUtils.formatNumberAsKilo(totals.totalPeakGen)}
              </td>
              <td id="projects-table__average-gen-peak-percent" className="projects-table__averages">
                {math.round?.((totals.weightedPeakGen / totals.peakGenWeights || 0) * 100)}
              </td>
            </>
          ) : null}
        </>
      ) : null}
    </tr>
  )
}

export default ProjectsTableSumsAvgs
