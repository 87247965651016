import API from "@common/API"

/**
 * Service for administering Ecosuite user settings.
 */
const UserAdminService = {
  async getUsers() {
    return API.get<unknown>("/users")
  },

  async getUserSchema(): Promise<unknown> {
    return API.getFromCache("/schemas/user")
  },

  async createUser(form: { formData: unknown }): Promise<unknown> {
    return API.post("/users", form.formData)
  },

  async editUser(id: string, form: { formData: unknown }): Promise<unknown> {
    return API.put("/users/" + id, form.formData)
  },

  async editUserLimitedAdmin(id: string, formData: unknown): Promise<unknown> {
    return API.put("/updateUserForLimitedAdmin/" + id, formData)
  },

  async getUser(id: string): Promise<unknown> {
    return API.get("/users/" + id)
  },

  async deleteUser(id: string): Promise<unknown> {
    return API.delete("/users/" + id)
  },

  async getGroups(): Promise<unknown> {
    return API.get("/user-groups")
  },

  async getUserGroups(user: { id: string }): Promise<unknown> {
    return API.get("/users/" + user.id + "/groups")
  },

  async resetUserGroup(user: { id: string }): Promise<unknown> {
    return API.put("/users/" + user.id + "/groups/reset")
  },

  async addUserToGroup(user: { id: string }, group: string): Promise<unknown> {
    return API.post("/users/" + user.id + "/groups/" + group)
  },

  async removeUserFromGroup(user: { id: string }, group: string): Promise<unknown> {
    return API.delete("/users/" + user.id + "/groups/" + group)
  },

  async getMfa(user: { id: string }): Promise<unknown> {
    return API.get("/users/" + user.id + "/mfa")
  },

  async resetMfa(user: { id: string }): Promise<unknown> {
    return API.delete("/users/" + user.id + "/mfa")
  },

  async resend(user: { id: string }): Promise<unknown> {
    return API.put("/users/" + user.id + "/resend")
  },

  async getUserTypes(): Promise<unknown> {
    return API.get("/user-types")
  },

  /**
   * API endpoint `/schemas/user-type`
   * @returns {Promise<jsonFormSchema>} `userTypeSchema` combined with `userGroupSchema`
   */
  async getUserTypeSchema(): Promise<unknown> {
    return API.get("/schemas/user-type")
  },

  /**
   * API endpoint `/schemas/user-groups`
   * @returns {Promise<jsonFormSchema>} `userGroupSchema`
   */
  async getUserGroupSchema(): Promise<unknown> {
    return API.get("/schemas/user-groups")
  },

  async createUserType(formData: unknown): Promise<unknown> {
    return API.post("/user-types", formData)
  },

  async editUserType(id: string, formData: unknown): Promise<unknown> {
    return API.put("/user-types/" + id, formData)
  },

  async getUserType(id: string): Promise<unknown> {
    return API.get("/user-types/" + id)
  },

  async deleteUserType(id: string): Promise<unknown> {
    return API.delete("/user-types/" + id)
  },

  async getLastLogin(id: string): Promise<unknown> {
    return API.get("/last-login/" + id )
  },
}

export default UserAdminService
