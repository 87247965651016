import React from "react"
import { Link } from "react-router-dom"
import EcosuiteView from "@common/module/EcosuiteView"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n

export default class RecordDocumentPreview extends EcosuiteView {
  constructor(props) {
    super(props, "record-document-list")
  }

  renderPreviewContent() {
    const parts = this.props.previewDocument.documentUrl.split(".")
    if (parts.length > 1) {
      const suffix = parts.pop()
      if (suffix.startsWith("doc") || suffix.startsWith("xl") || suffix.startsWith("odt") || suffix.startsWith("pp")) {
        return (
          <iframe
            title={t("labels.preview")}
            className="preview-content"
            src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(this.props.previewDocument.documentUrl)}`}
          ></iframe>
        )
      } else {
        return <iframe title={t("labels.preview")} className="preview-content" src={this.props.previewDocument.documentUrl}></iframe>
      }
    }

    return (
      <div className="preview-content">
        <p className="preview-unavailable">{t("messages.preview_unavailable")}</p>
      </div>
    )
  }

  renderContent() {
    return (
      <div className="preview">
        <div className="preview-header">
          {this.props.onCancel ? (
            <div
              className="float-end"
              onClick={() => {
                this.props.onCancel()
              }}
            >
              <Icon icon="close" className="close-button" />
            </div>
          ) : (
            <Link className="float-end" to="/" style={{ color: "white" }}>
              <Icon icon="close" className="close-button" />
            </Link>
          )}
          <div className="preview-title">{`${t("labels.preview")}: ${this.props.previewDocument.documentName}`}</div>
        </div>
        {this.renderPreviewContent()}
      </div>
    )
  }
}
