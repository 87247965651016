import ProjectUtils from "./ProjectUtils"

var DeviceUtils = {
  isGenerating(device) {
    return device.type === "GEN" || device.type === "INV"
  },

  isGenerationMeter(device) {
    return device.type === "GEN"
  },

  isInverter(device) {
    return device.type === "INV"
  },

  isPyranometer(device) {
    return device.type === "PYR"
  },

  isConsuming(device) {
    return device.type === "CON" || device.type === "EVSE"
  },

  getDeviceDCSize(system, sourceId) {
    const devices = system ? system.devices : undefined
    if (devices) {
      let matches = devices.find((device) => {
        return DeviceUtils.isGenerating(device) && ProjectUtils.getDeviceType(sourceId) === device.type && ProjectUtils.getDeviceId(sourceId) === "" + device.id
      })
      if (matches) {
        return matches.dcSize
      }
    }
  },

  getDeviceACSize(system, sourceId) {
    const devices = system ? system.devices : undefined
    if (devices) {
      let matches = devices.find((device) => {
        return DeviceUtils.isGenerating(device) && ProjectUtils.getDeviceType(sourceId) === device.type && ProjectUtils.getDeviceId(sourceId) === "" + device.id
      })
      if (matches) {
        return matches.acSize
      }
    }
  },

  getDevicePeakPower(system, sourceId) {
    const devices = system ? system.devices : undefined
    if (devices) {
      let matches = devices.find((device) => {
        return DeviceUtils.isConsuming(device) && ProjectUtils.getDeviceType(sourceId) === device.type && ProjectUtils.getDeviceId(sourceId) === "" + device.id
      })
      if (matches) {
        return matches.peakPower
      }
    }
  },

  getDevicePeakPercent(system, sourceId, sourcePeak) {
    const devices = system ? system.devices : undefined
    if (devices) {
      let matches = devices.find((device) => {
        return DeviceUtils.isGenerating(device) && ProjectUtils.getDeviceType(sourceId) === device.type && ProjectUtils.getDeviceId(sourceId) === "" + device.id
      })
      if (matches) {
        if (sourcePeak && (matches.dcSize || matches.acSize)) {
          let cleanedDCSize = isNaN(matches.dcSize) ? Infinity : matches.dcSize
          let cleanedACSize = isNaN(matches.acSize) ? Infinity : matches.acSize
          // Moves decimal two spaces to the right, rounds to the nearest integer
          return +Math.round(sourcePeak / Math.min(cleanedDCSize, cleanedACSize) + "E+2")
        }
      }
    }
  },
}

export default DeviceUtils
