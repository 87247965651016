import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"

/**
 * List all files associated with a project code.
 * @param code - The project code.
 */
export const listCodeFiles = (code: string) => {
  return API.get<{ media: MediaResponse[] }>(`/media/storage/code/${code}`)
    .then((response) => response.media)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'getCodeFiles': ${getErrorMessage(err)}` })
    })
}

/**
 * Get all media tags associated with a project code.
 * @param code - The project code.
 */
export const getTags = (code: string) => {
  return API.get<{ tags: MediaTagResponse }>(`/media/tags/storage/code/${code}`)
    .then((response) => response.tags)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'getTags': ${getErrorMessage(err)}` })
    })
}

/**
 * Get a file.
 * @param key - The file key.
 */
export const getFile = (key: string): Promise<MediaResponse> => {
  return API.get<{ media: MediaResponse }>(`/media/storage/file/${encodeURIComponent(key)}`)
    .then((response) => response.media)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'getFile': ${getErrorMessage(err)}` })
    })
}
/**
 * Delete a file.
 * @param key - The key to delete.
 */
export const deleteFile = (key: string) => {
  return API.delete<{ deleted: string }, unknown>(`/media/storage/file/${encodeURIComponent(key)}`)
    .then((response) => response.deleted)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'deleteFile': ${getErrorMessage(err)}` })
    })
}
