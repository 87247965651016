import React from "react"
import { Loading } from "@common/EcosuiteComponent"
import { Modal } from "reactstrap"
import "./LoadingModal.scss"

/**
 * A generic loading view that obfuscates the display using a modal.
 * @param isLoading - Whether to show the loading view.
 * @param exit - A callback to prematurely end the loading.
 * @constructor
 */
export const LoadingModal = ({ show, exit }: { show: boolean; exit: undefined | (() => void) }): JSX.Element => {
  return (
    <Modal centered={true} size={"sm"} isOpen={show} toggle={exit ? () => exit?.call(this) : undefined}>
      <div className={"loading-modal"}>
        <Loading />
      </div>
    </Modal>
  )
}

export default LoadingModal
