import React from "react"
import PropTypes from "prop-types"
import { create, all } from "mathjs"

import EcosuiteComponent from "@common/EcosuiteComponent"
import Utils from "@common/utils/Utils"
import GraphUtils from "@common/utils/GraphUtils"
import i18n from "src/i18n"

const { t } = i18n
const math = create(all)

export class CostGraphTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {payload.map((data) => {
              return (
                <p key={data.dataKey} className="label" style={{ color: data.stroke }}>
                  {`${this.props.getDataTitle(data.name)} : ${Utils.formatCurrency(data.value ? data.value : 0)}`}
                </p>
              )
            })}
            {this.props.showTotal ? (
              <p>
                {t("labels.total")}: {`${Utils.formatCurrency(this.getTotalFromPayload(payload))}`}
              </p>
            ) : null}
          </div>
        )
      }
    }
    return null
  }

  getTotalFromPayload(payload) {
    if (payload) {
      return payload.reduce((total, data) => math.add(total, data.value), 0)
    }
    return 0
  }
}
