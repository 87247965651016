import React from "react"
import { Modal, ModalBody, ModalFooter, Button, Input, Alert } from "reactstrap"
import i18n from "src/i18n"
import "../IconModal.scss"
import TimedMessage from "../TimedMessage"
import UncontrolledTooltip from "../ToolTip/UncontrolledTooltip"

const { t } = i18n

type Icon = {
  icon: React.ReactNode
  iconName: string
  meaning: string
  type: string
  tooltip?: string
}

type IconsArray = Icon[]

interface IconListModalProps {
  icons: IconsArray
  selectedIcons: IconsArray
  showInput: boolean
  iconTooltip: string
  inputError: boolean
  showAlert: boolean
  onHide: () => void
  handleIconClick: (icon: Icon) => void
  closeModal: () => void
  handleSubmitTooltip: () => void
  handleInputChange: (value: string) => void
}

interface IconDeleteProps {
  showDeletePopup: boolean
  iconToDelete: Icon | undefined
  rowId: number | undefined
  closeDeleteModal: () => void
  handleIconRemove: (selectedIcon: Icon, rowId?: number) => void
}

const tooltipIds = [
  "LinkHorizontalThin",
  "LinkHorizontalSolid",
  "LinkHorizontalSlash",
  "FileLigth",
  "FileSolid",
  "FileExcelRegular",
  "ScrewdriverWrenchLight",
  "ScrewdriverWrenchRegular",
  "ScrewdriverWrenchSolid",
  "ArrowUpRightDots",
  "ArrowUpRightLeftBottom",
  "ArrowUpSquareTriangle",
]

export function IconListModal({
  icons,
  showInput,
  iconTooltip,
  selectedIcons,
  inputError,
  showAlert,
  onHide,
  handleIconClick,
  handleInputChange,
  handleSubmitTooltip,
  closeModal,
}: IconListModalProps) {
  return (
    <>
      <Modal isOpen={true} toggle={onHide}>
        <ModalBody>
          <p>{t("economics.labels.choose_icons")}:</p>
          <div className="icons-container">
            {icons.map((iconItem, index) => (
              <React.Fragment key={index}>
                <div id={iconItem.iconName} onClick={() => handleIconClick(iconItem)}>
                  {iconItem.icon}
                </div>

                {tooltipIds.map((id: string) => {
                  if (iconItem.iconName === id) {
                    return (
                      <UncontrolledTooltip key={id} placement="top" target={id}>
                        {t(`economics.tooltips.icons.${iconItem.meaning}`)}
                      </UncontrolledTooltip>
                    )
                  }
                  return null
                })}
              </React.Fragment>
            ))}
          </div>

          <div className="selected-icons">
            {selectedIcons.map((iconItem, index) => (
              <React.Fragment key={index}>
                <div id={iconItem.tooltip} style={{ cursor: "pointer" }}>
                  {iconItem.icon}
                </div>
                {iconItem.tooltip && <UncontrolledTooltip target={iconItem.tooltip}>{iconItem.tooltip}</UncontrolledTooltip>}
              </React.Fragment>
            ))}
          </div>
          {showAlert && (
            <TimedMessage delay={3000}>
              <Alert color="danger">{t("economics.messages.icon_already_exists")}</Alert>
            </TimedMessage>
          )}
          {showInput && (
            <div>
              <Input type="text" className="mb-4" value={iconTooltip} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)} />
              {inputError && <Alert color="danger">{t("economics.messages.words_limit")}</Alert>}
              <Button disabled={!iconTooltip} color="primary" onClick={handleSubmitTooltip}>
                {t("buttons.submit")}
              </Button>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={closeModal}>
            {t("labels.close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function ConfirmDeleteModal({ iconToDelete, rowId, showDeletePopup, closeDeleteModal, handleIconRemove }: IconDeleteProps) {
  return (
    <Modal isOpen={showDeletePopup}>
      <ModalBody>
        <p>{t("economics.messages.confirm_delete_icon")}</p>
        <div id={`${iconToDelete?.tooltip}`}>{iconToDelete?.icon}</div>
      </ModalBody>
      <ModalFooter>
        {iconToDelete && (
          <Button color="danger" onClick={() => handleIconRemove(iconToDelete, rowId)}>
            {t("buttons.delete")}
          </Button>
        )}
        <Button color="danger" onClick={closeDeleteModal}>
          {t("buttons.cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
