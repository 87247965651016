import React from "react"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import { SideBarSection } from "@common/module/EcosuiteView"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import EnvironmentUtils from "@dashboard/energy/EnvironmentUtils"
import gas from "src/img/gas.png"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
import { Trans } from "react-i18next"
import moment from "moment"

const { t } = i18n
export default class Emissions extends EcosuiteComponent {
  /**
   * Renders consumption source-type specific ui
   *
   * @param {number} consumptionValue - Consumption reading value
   * @param {number} emissionValue - Emission reading value OR Avoided-Emission reading value
   *
   * @returns {JSX.Element}
   */
  renderConsumptionSpecificInformation(consumptionValue, emissionValue) {
    return (
      <React.Fragment>
        <div className="sidebar-text">{`${t("energy.labels.caused_by_consumption")}: `}</div>
        <div className="sidebar-heading">{EnergyUtils.displayWattHours(consumptionValue)}</div>
        <div className="sidebar-text">{t("energy.labels.which_produced")}</div>
        <div className="sidebar-text">
          <span className="siderbar-reading">{EnergyUtils.formatNumber(EnvironmentUtils.getUSTonsSaved(emissionValue))}</span> short tons of CO<sub>2</sub>
          <span>
            <Icon icon="info" id="causedEmissions" />
            <UncontrolledTooltip target="causedEmissions">
              <Trans i18nKey="energy.tooltips.caused_emissions">
                Calculated using the kWh for the period and each Systems CO<sub>2</sub>(e) per kWh (kg)
              </Trans>
            </UncontrolledTooltip>
          </span>
        </div>
      </React.Fragment>
    )
  }

  /**
   * Renders generation source-type specific ui
   *
   * @param {number} generationValue - generation reading value
   * @param {number} emissionValue - Emission reading value OR Avoided-Emission reading value
   *
   * @returns {JSX.Element}
   */
  renderGenerationSpecificInformation(generationValue, emissionValue) {
    const localStartDate = this.props.readings?.range?.localStartDate
    const localEndDate = this.props.readings?.range?.localEndDate
    const rangeStartDate = moment(localEndDate).format("MMMM DD, YYYY")
    const rangeEndDate = moment(localStartDate).format("MMMM DD, YYYY")
    return (
      <React.Fragment>
        <UncontrolledTooltip target="emission-value" placement="bottom">
          {t("energy.tooltips.emissions_date_range", { rangeStartDate: rangeStartDate, rangeEndDate: rangeEndDate })}
        </UncontrolledTooltip>
        <div className="sidebar-heading" id="emission-value">
          {EnergyUtils.displayWattHours(generationValue)}
        </div>
        <div className="sidebar-text">of generation saved:</div>
        <div className="sidebar-text">
          <span className="siderbar-reading">{EnergyUtils.formatNumber(EnvironmentUtils.getUSTonsSaved(emissionValue))}</span> short tons of CO<sub>2</sub>
          <span>
            <Icon icon="info" id="causedEmissions" />
            <UncontrolledTooltip target="causedEmissions">
              <Trans i18nKey="energy.tooltips.caused_emissions">
                Calculated using the kWh for the period and each Systems CO<sub>2</sub>(e) per kWh (kg)
              </Trans>
            </UncontrolledTooltip>
          </span>
        </div>
      </React.Fragment>
    )
  }

  /**
   * Renders an emissions widget in a sidebar.
   *
   * @param {string} sourceType - The type of source ("consumption" or "generation").
   * @param {number} emissionValue - The value of emissions; could be of emissions OR avoided-emissions.
   * @param {string} widgetTitle - The title of the widget.
   * @param {number} sourceTypeValue - The specific value of the source type; could be of consumption OR generation
   *
   * @returns {React.Component} - Returns a React component for the emissions widget.
   */
  renderEmissionsWidget(sourceType, emissionValue, widgetTitle, sourceTypeValue) {
    return (
      <SideBarSection
        title={widgetTitle}
        content={
          <React.Fragment>
            {sourceType === "consumption" ? this.renderConsumptionSpecificInformation(sourceTypeValue, emissionValue) : this.renderGenerationSpecificInformation(sourceTypeValue, emissionValue)}
            <div className="sidebar-text">{`${t("energy.labels.equal_to_burning")}: `}</div>
            <div className="sidebar-text">
              <span className="siderbar-reading">
                <img src={gas} width="40px" style={{ marginTop: "-10px" }} alt="Gas" /> {EnergyUtils.formatNumber(EnvironmentUtils.getGasolineSaved(emissionValue))}
              </span>{" "}
              {t("energy.labels.gallons_of_gas")}
              <span>
                <Icon icon="info" id="causedEmissionsGas" />
                <UncontrolledTooltip target="causedEmissionsGas">
                  <Trans i18nKey="energy.tooltips.caused_emissions_gas">
                    Burning 1 gallon gas releases 19.64 pounds of CO<sub>2</sub>
                  </Trans>
                </UncontrolledTooltip>
              </span>
            </div>
          </React.Fragment>
        }
      />
    )
  }

  renderError(widgetTitle) {
    return (
      <SideBarSection
        title={
          <>
            <Icon
              icon="error"
              style={{
                paddingRight: "5px",
                transform: "scale(1.5)",
                color: "orange",
              }}
            />
            {widgetTitle}
          </>
        }
        content={
          <React.Fragment>
            <div className="siderbar-error">
              <p>{t("energy.errorMsgs.emissions_calculation")}</p>
              <Trans i18nKey="energy.errorMsgs.check_project_settings">
                <p>
                  Please check that you have provided a correct option for calculating CO<sub>2</sub> data for each site within the Project settings.
                </p>
              </Trans>
            </div>
          </React.Fragment>
        }
      />
    )
  }

  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentValid(this.props.readings)) {
      if (this.props.sourceType === "consumption") {
        // if the source is consuming and there are no emissions there is a CO2 coefficient error
        if (!this.props.readings.emissions) {
          return this.renderError("Emissions")
        } else {
          return this.renderEmissionsWidget("consumption", this.props.readings.emissions, "Emissions", this.props.readings.consumption)
        }
      } else if (this.props.sourceType === "generation") {
        // if the source is not generating avoided emissions widget doesn't need to be rendered
        if (!this.props.readings.generation) {
          return null

          // if the source is generating and there are no avoidedEmissions there is a CO2 coefficient error
        } else if (this.props.readings.generation && !this.props.readings.avoidedEmissions) {
          return this.renderError("Avoided Emissions")
        } else {
          return this.renderEmissionsWidget("generation", this.props.readings.avoidedEmissions, "Avoided Emissions", this.props.readings.generation)
        }
      } else {
        return null
      }
    } else {
      return <SideBarSection title={t("labels.emissions")} content={<Loading />} />
    }
  }
}
