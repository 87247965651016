import SparkMD5 from "spark-md5"
import { Auth } from "aws-amplify"
import aws from "aws-sdk"
import { S3ImagePlugin } from "./types"

/**
 * An implementation of an upload method using S3.
 */
export const s3ImageUpload: S3ImagePlugin = {
  s3UploadImage: (dataUrl, imageBucket: string) => {
    // Image can be either a string or array buffer. For simplicity, convert any array buffers to string.
    if (typeof dataUrl !== "string") {
      dataUrl = new TextDecoder().decode(dataUrl)
    }
    // Create an appropriate hash to name the image.
    const hash = SparkMD5.hash(dataUrl)
    // Get the actual buffer of the image.
    const buf = Buffer.from(dataUrl.replace(/^data:image\/\w+;base64,/, ""), "base64")
    // Get the type of the image.
    //
    // Note that this is stored at the beginning of dataUrl.
    const type = dataUrl.substring(dataUrl.indexOf(":") + 1, dataUrl.indexOf(";"))

    // Create the file with the extension on the end.
    const ext = `.${type.substring(type.indexOf("/") + 1)}`
    const name = hash + ext

    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      const putParams = {
        Body: buf,
        Bucket: imageBucket,
        Key: name,
        ContentType: type,
        ContentEncoding: "base64",
      }

      return (
        s3
          .putObject(putParams)
          .promise()
          // Make sure to return the link to the image to display within the editor.
          .then(() => {
            return name
          })
      )
    })
  },
}
