import React from "react"
import EventView from "@dashboard/event/views/EventView"
import EcosuiteView from "@common/module/EcosuiteView"
import EventListView from "@dashboard/event/views/event/EventListView"
import { Error, EcosuiteComponentError, Loading } from "@common/EcosuiteComponent"
import { Button, ButtonToolbar, ButtonGroup } from "reactstrap"
import EventViewControls from "@dashboard/event/views/event/EventViewControls"
import EventService from "@dashboard/event/EventService"
import i18n from "src/i18n"

const { t } = i18n
export default class EnergyEventsView extends EcosuiteView {
  constructor(props) {
    super(props, "energy-events")

    this.loadEvents = this.loadEvents.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadEvents(this.props.range)
  }

  loadEvents(range) {
    if (!range) {
      range = this.getExclusiveRange()
    }
    // Clear the existing state to make it clear an update is occurring.
    this.setState({
      events: undefined,
    })

    EventService.listEvents(range, "energy")
      .then((data) => {
        let events = data.events.sort((a, b) => {
          if (a.startDate === b.startDate) {
            return a.cause.localeCompare(b.cause)
          }
          return a.startDate.localeCompare(b.startDate)
        })

        this.setStateIfMounted({
          events: events,
        })
      })
      .catch((err) => {
        this.setStateIfMounted({
          events: new EcosuiteComponentError(err),
        })
      })
  }

  renderMainView() {
    return (
      <EnergyEventView
        {...this.props}
        events={this.state.events}
        actions={{
          eventUpdated: () => {
            this.loadEvents(this.props.range)
          },
          eventDeleted: () => {
            this.loadEvents(this.props.range)
          },
        }}
      />
    )
  }
}

class EnergyEventView extends EventView {
  renderEvents() {
    if (this.isContentError(this.props.events)) {
      return <Error error={this.props.events.getError()} />
    }

    if (!this.props.events) {
      return <Loading message={t("loadingMsg.loading_events")} />
    }

    return (
      <div className="content-view">
        <EnergyEventListView
          groups={this.props.groups}
          projects={this.props.projects}
          events={this.getEvents()}
          eventSchema={this.state.eventSchema}
          event={this.state.event}
          searchTerm={this.state.searchTerm}
          types={this.state.types}
          selectedTypes={this.state.selectedTypes}
          eventSubTypes={Array.from(this.state.subTypes ?? "")}
          selectedEventSubTypes={Array.from(this.state.selectedSubTypes ?? "")}
          priorities={this.state.priorities}
          selectedPriorities={this.state.selectedPriorities}
          filters={{
            showDueOnly: this.state.showDueOnly,
            showUnresolvedOnly: this.state.showUnresolvedOnly,
          }}
          actions={{
            eventUpdated: this.eventUpdated,
            selectView: this.selectView,
            selectRange: this.selectRange,
            selectProject: this.selectProject,
            selectEvent: this.selectEvent,
            search: this.search,
            toggleType: this.toggleType,
            toggleSubTypes: this.toggleSubTypes,
            togglePriority: this.togglePriority,
            toggleUnresolved: this.toggleUnresolved,
            toggleDue: this.toggleDue,
          }}
        />
      </div>
    )
  }
}

class EnergyEventListView extends EventListView {
  renderViewControls() {
    return <EnergyEventViewControls {...this.props} hashtags={this.getHashTags()} readonly={this.isReadOnly()} />
  }
}

class EnergyEventViewControls extends EventViewControls {
  renderEventButtons() {
    if (this.props.readonly) {
      return null
    }

    return (
      <ButtonToolbar className="secondary-header-toolbar float-end">
        <ButtonGroup className="header-button-group">
          <Button
            className={this.props.event && !this.props.event.id ? "selected" : ""}
            onClick={() => {
              this.props.actions.selectEvent({ type: "energy" })
            }}
            size="sm"
            color="ecogy"
          >
            {t("event.labels.new_event")}
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    )
  }
}
