import { Auth } from "aws-amplify"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { get, post, put, del } from "../setup"

function removeLocalStorageItemsExceptPrefix(prefix: string): number {
  if (typeof localStorage === "undefined") {
    console.warn("localStorage is not available in this environment")
    return 0
  }

  let removedCount = 0
  const keysToRemove: string[] = []

  // First, collect all keys that don't match the prefix
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key && !key.startsWith(prefix)) {
      keysToRemove.push(key)
    }
  }

  // Then, remove the collected keys
  keysToRemove.forEach((key) => {
    localStorage.removeItem(key)
    removedCount++
  })

  return removedCount
}

interface User {
  id: string
}

export const getUsersForLimitedAdminApi = () => get<Array<User>>("/usersLimitedAdmin")
export const createUserForLimitedAdminApi = (body: User) => post<User, User>("/createUserForLimitedAdmin", body)

export function useUsersForLimitedAdmin() {
  return useQuery({
    queryKey: ["usersLimitedAdmin"],
    queryFn: async () => {
      const response = await getUsersForLimitedAdminApi()
      return response
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  })
}

export function usecreateUserForLimitedAdmin() {
  const client = useQueryClient()
  return useMutation(createUserForLimitedAdminApi, {
    onSuccess: () => client.invalidateQueries(["usersLimitedAdmin"]),
  })
}
interface UserOrganization {
  id: string
  userId: string
  organizationId: string
  name: string
  solarNetworkCredentials?: {
    host?: string
    sshHost?: string
    wssHost?: string
    secret?: string
    token?: string
  }
  settings?: {
    projectMilestoneRecipients?: string[]
    logoUrl?: string
    salesforceUrl?: string
    qgisUrl?: string
    isPublic?: boolean
    slackXbobToken?: string
  }
}

export const getUserOrganizationApi = (userId: string) =>
  get<{ organization: UserOrganization }>(`/organizations/user/${userId}`)

export function useUserOrganization(userId: string | undefined) {
  return useQuery({
    queryKey: ["userOrganization", userId],
    queryFn: async () => {
      const response = await getUserOrganizationApi(userId!)
      return response
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: Boolean(userId),
  })
}

export const updateOrganizationApi = (organization: UserOrganization) =>
  put<UserOrganization, UserOrganization>("/organizations", organization)

export function useUpdateOrganization() {
  const client = useQueryClient()
  return useMutation(updateOrganizationApi, {
    onSuccess: () => client.invalidateQueries(["userOrganization"]),
  })
}

export interface InviteUserPayload {
  userEmail: string
  accessPolicies: string[]
  userTypeId: string
}

export const inviteUserToOrganizationApi = (body: InviteUserPayload) =>
  post<InviteUserPayload, any>("/invite-user-organization", body)

interface SwitchOrganizationPayload {
  organizationId: string
}

export const switchOrganizationApi = async (payload: SwitchOrganizationPayload) => {
  // First, switch the organization
  await post<SwitchOrganizationPayload, any>("/switch-organization", payload)

  // Refresh the Cognito session to get updated JWT
  const _cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
  removeLocalStorageItemsExceptPrefix("CognitoIdentityServiceProvider")
  return true
}

export function useSwitchOrganization() {
  const queryClient = useQueryClient()

  return useMutation(switchOrganizationApi, {
    onSuccess: () => {
      queryClient.invalidateQueries("userOrganization")
    },
  })
}

export interface ExternalUser {
  userId: string
  email: string
  firstName: string
  lastName: string
  userType: {
    id: string
    name: string
  }
  accessPolicies: Array<string>
  organizationId: string
  originalOrgId: string
  organization: {
    id: string
    name: string
  }
}

export interface ExternalUserUpdate {
  userId: string
  updates: {
    accessPolicies: Array<string>
    userType: {
      id: string
      name: string
    }
  }
}

export const getExternalUsersApi = () => get<Array<ExternalUser>>("/external-users")

export const updateExternalUserApi = (body: ExternalUserUpdate) =>
  put<ExternalUserUpdate, ExternalUser>("/external-user", body)

export const removeExternalUserApi = (userId: string) => del(`/external-user/${userId}`)

export function useExternalUsers() {
  return useQuery({
    queryKey: ["externalUsers"],
    queryFn: async () => {
      const response = await getExternalUsersApi()
      return response
    },
    refetchInterval: 60000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}
