import React from "react"
import { ButtonToolbar, ButtonGroup, Button } from "reactstrap"

import EcosuiteComponent from "@common/EcosuiteComponent"

import DownloadModal from "../DownloadModal"
import EnergyService from "../EnergyService"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class EnergyViewControls extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.downloadConsumption = this.downloadConsumption.bind(this)
  }

  isShowViewSelector() {
    return false
  }

  render() {
    return (
      <div className="content-view-controls search-bar">
        <ButtonToolbar className="float-end">
          <ButtonGroup className="header-button-group">
            {this.isConsumptionDownloadDisabled() ? null : (
              <Button size="sm" label={t("energy.labels.download_consumption")} onClick={this.downloadConsumption}>
                {t("energy.labels.download_consumption")} <Icon icon="cloud_download" />
              </Button>
            )}
            {this.props.restrictions.preventDownload === "yes" ? null : <DownloadModal label={t("loadingMsg.download_datums")} size="sm" className="download-modal" {...this.props} />}
          </ButtonGroup>
        </ButtonToolbar>

        {this.props.viewControls}
      </div>
    )
  }

  isConsumptionDownloadDisabled() {
    return this.props.restrictions.preventDownload === "yes" || !this.props.showConsumption || !this.props.project || !isConsumptionProject(this.props.project)
  }

  downloadConsumption() {
    EnergyService.downloadConsumption(this.props.project)
  }
}

const isConsumptionProject = (project) => {
  var match = false
  Object.values(project.sites).forEach((site) => {
    Object.values(site.systems).forEach((system) => {
      if (system.type === "consumption") {
        match = true
      }
    })
  })
  return match
}
