import React from "react"
import { create, all } from "mathjs"
import { ReferenceLine } from "recharts"
import Moment from "moment"
import { extendMoment } from "moment-range"

import { Error, Loading } from "@common/EcosuiteComponent"
import EcosuiteView, { LAYOUT_CONTENT_RIGHT, SideBarSection } from "@common/module/EcosuiteView"
import Utils from "@common/utils/Utils"

import DatumUtils from "@dashboard/energy/graphs/datums/DatumUtils"

import ConsumptionCostGraph from "../graphs/consumption/ConsumptionCostGraph"
import DemandChargeGraph from "../graphs/consumption/DemandChargeGraph"

import ConsumptionCostComparison from "./widgets/ConsumptionCostComparison"
import ConsumptionCost from "./widgets/ConsumptionCost"
import EconodeConsumptionCosts from "./dashboard/EconodeConsumptionCosts"
import EconodeRangeConsumptionCosts from "./dashboard/EconodeRangeConsumptionCosts"
import ConsumptionCostsPieGraph from "./dashboard/ConsumptionCostsPieGraph"
import i18n from "src/i18n"

const { t } = i18n
const moment = extendMoment(Moment)
const math = create(all)

export default class EconodeEconomicsDashboardProjectView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.state = {
      filteredEconodes: [],
    }

    this.showReferenceLine = this.showReferenceLine.bind(this)
  }

  getLayout() {
    return LAYOUT_CONTENT_RIGHT
  }

  showReferenceLine(referenceId, value, label) {
    const state = {}
    if (value) {
      state[referenceId] = <ReferenceLine y={value} yAxisId="cost" stroke="white" label={{ position: "top", value: label, fill: "white" }} />
    } else {
      state[referenceId] = null
    }
    this.setStateIfMounted(state)
  }

  renderMainView() {
    if (this.isContentValid(this.props.projectCosts)) {
      return <div className="econode-graphs">{this.renderEconodeGraphs()}</div>
    } else if (this.isContentError(this.props.projectCosts)) {
      return <Error error={this.props.projectCosts.getError()} />
    } else {
      return <Loading />
    }
  }

  renderInfo() {
    return (
      <div className="econode-info">
        <div className="econode-info-spot">
          <div className="econode-info-spot-label">{`${t("econode.dataTitles.Estimated Charges")}: `} </div>
          <div className="econode-info-spot-content">{this.props.projectCosts ? Utils.formatCurrency(this.props.projectCosts.consumptionCost.totalCost) : "-"}</div>
        </div>
        <div className="econode-info-spot">
          <div className="econode-info-spot-label">{`${t("econode.dataTitles.Average Price $/kWh")}: `} </div>
          <div className="econode-info-spot-content">
            {this.props.projectCosts && this.props.readings
              ? Utils.formatCurrency(math.divide(this.props.projectCosts.consumptionCost.totalCost, math.divide(this.props.readings.consumption, 1000)))
              : "-"}
          </div>
        </div>
      </div>
    )
  }

  renderBreakDownInfo() {
    return (
      <div className="econode-info">
        <div className="econode-info-spot econode-info-spot-3">
          <div className="econode-info-spot-label">{`${t("econode.dataTitles.Metered Charges")}: `} </div>
          <div className="econode-info-spot-content">{this.props.projectCosts ? Utils.formatCurrency(this.props.projectCosts.consumptionCost.totalMeteredCost) : "-"}</div>
        </div>

        <div className="econode-info-spot econode-info-spot-3">
          <div className="econode-info-spot-label">{`${t("econode.dataTitles.Demand Charges")}: `} </div>
          <div className="econode-info-spot-content">{this.props.projectCosts ? Utils.formatCurrency(this.props.projectCosts.consumptionCost.totalDemandCharge) : "-"}</div>
        </div>

        <div className="econode-info-spot econode-info-spot-3">
          <div className="econode-info-spot-label">{`${t("econode.dataTitles.Fixed Charges")}: `} </div>
          <div className="econode-info-spot-content">{this.props.projectCosts ? Utils.formatCurrency(this.props.projectCosts.consumptionCost.totalFixedCost) : "-"}</div>
        </div>
      </div>
    )
  }

  renderEconodeGraphs() {
    let consumptionCostDatums = this.isContentValid(this.props.projectCosts)
      ? DatumUtils.groupConsumptionCostDatums([this.props.project], { [this.props.project.code]: this.props.projectCosts })
      : null

    return (
      <React.Fragment>
        {this.renderInfo()}
        {this.renderBreakDownInfo()}
        <div className="content-graph">
          <ConsumptionCostGraph
            {...this.props}
            consumptionCostDatums={consumptionCostDatums}
            readonly={this.isReadOnly()}
            range={this.props.datumsRange}
            aggregation={this.props.datumsAggregation}
            referenceLine={this.state.meteredCostReferenceLine}
          />
        </div>
        <EconodeConsumptionCosts consumptionCost={this.props.projectCosts} datumsAggregation={this.props.datumsAggregation} showReferenceLine={this.showReferenceLine} />
        <div className="content-graph">
          <DemandChargeGraph {...this.props} consumptionCostDatums={consumptionCostDatums} readonly={this.isReadOnly()} range={this.props.datumsRange} aggregation={this.props.datumsAggregation} />
        </div>
      </React.Fragment>
    )
  }

  renderSiderBar() {
    const thisMonthRange = moment.range(moment().startOf("month"), moment().endOf("day"))
    return (
      <React.Fragment>
        <SideBarSection
          title={`${t("dates.This Month")}`}
          range={thisMonthRange}
          classNames="disregard-range"
          content={<EconodeRangeConsumptionCosts project={this.props.project} range={thisMonthRange} />}
        />
        {this.props.showConsumption ? (
          <SideBarSection title={`${t("econode.dataTitles.Charges This Period")}`} range={this.props.actualRange} content={<ConsumptionCost consumptionCost={this.props.projectCosts} />} />
        ) : null}
        {this.props.showConsumption ? (
          <SideBarSection
            title={`${t("econode.dataTitles.Charges Last Period")}`}
            range={this.props.previousRange}
            content={<ConsumptionCost consumptionCost={this.props.previousConsumptionCost} />}
          />
        ) : null}
        {this.props.showConsumption ? (
          <SideBarSection
            title={`${t("econode.dataTitles.Compared to Last Period")}`}
            content={<ConsumptionCostComparison consumptionCost={this.props.projectCosts} previousConsumptionCost={this.props.previousConsumptionCost} />}
          />
        ) : null}
        <SideBarSection title={`${t("econode.dataTitles.Charges This Period")}`} range={this.props.actualRange} content={<ConsumptionCostsPieGraph projectCosts={this.props.projectCosts} />} />
      </React.Fragment>
    )
  }
}
