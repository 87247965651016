import React from "react"
import moment from "moment-timezone"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar, Legend } from "recharts"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import { Loading, Error } from "@common/EcosuiteComponent"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import ProjectUtils from "@common/utils/ProjectUtils"

export class BillingGeneration extends DateRangeGraph {
  getExpected() {
    const path = this.props.lineItem.path
    const projectId = ProjectUtils.getProjectCode(path)
    const projectReadings = this.props.expected.projects[projectId]
    const siteId = ProjectUtils.getSiteCode(path)

    if (siteId) {
      const siteReadings = projectReadings.sites[siteId]
      const systemId = ProjectUtils.getSystemCode(path)

      if (systemId) {
        return siteReadings.systems[systemId].expectedGeneration
      } else {
        return siteReadings.expectedGeneration
      }
    } else {
      return projectReadings.expectedGeneration
    }
  }

  getPredicted() {
    const path = this.props.lineItem.path
    const projectId = ProjectUtils.getProjectCode(path)
    const projectReadings = this.props.predicted.projects[projectId]
    const siteId = ProjectUtils.getSiteCode(path)

    if (siteId) {
      const siteReadings = projectReadings.sites[siteId]
      const systemId = ProjectUtils.getSystemCode(path)

      if (systemId) {
        return siteReadings.systems[systemId].predictedGeneration
      } else {
        return siteReadings.predictedGeneration
      }
    } else {
      return projectReadings.predictedGeneration
    }
  }

  renderContent() {
    if (this.props.lineItem && this.isContentValid(this.props.expected) && this.isContentValid(this.props.predicted)) {
      const data = [
        {
          month: this.props.lineItem.current && this.props.lineItem.current.month,
          time: moment(this.props.lineItem.current && this.props.lineItem.current.month)
            .toDate()
            .getTime(),
          generation: EnergyUtils.convertToKilo(this.props.lineItem.current && this.props.lineItem.current.generation),
          expected: EnergyUtils.convertToKilo(this.getExpected()),
          forecast: EnergyUtils.convertToKilo(this.getPredicted()),
        },
      ]

      return (
        <ResponsiveContainer width="100%" height={150}>
          <BarChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }}>
            <XAxis
              dataKey="time"
              tickFormatter={(time) => {
                return moment(time).format("MMM YYYY")
              }}
            />
            <YAxis />

            <Tooltip
              wrapperStyle={{ color: "#000", pointerEvents: "auto" }}
              // eslint-disable-next-line no-unused-vars
              formatter={(value, name, props) => {
                return EnergyUtils.formatKiloWattHours(value)
              }}
              labelFormatter={(label) => {
                return moment(label).format("MMM YYYY")
              }}
            />

            <Bar dataKey={"generation"} fill={GRAPH_COLORS[0]} name="Generated" />
            <Bar dataKey={"expected"} fill={GRAPH_COLORS[1]} name="Expected" />
            <Bar dataKey={"forecast"} fill={GRAPH_COLORS[2]} name="Forecast" />

            <Legend />
          </BarChart>
        </ResponsiveContainer>
      )
    } else if (this.isContentError(this.props.expected)) {
      return <Error error={this.props.expected.getError()} />
    } else if (this.isContentError(this.props.predicted)) {
      return <Error error={this.props.predicted.getError()} />
    } else {
      return <Loading />
    }
  }
}
