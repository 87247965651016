/** @jsxImportSource @emotion/react */

import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { setNodes } from "@udecode/plate-core"
import { getRootProps } from "@udecode/plate-styled-components"
import { Resizable } from "re-resizable"
import { Node, Transforms } from "slate"
import { ReactEditor, useFocused, useSelected } from "slate-react"
import { Auth } from "aws-amplify"
import aws from "aws-sdk"
import { getImageElementStyles, ImageElementProps, ImageHandle } from "@udecode/plate-ui-image"

/**
 * An ImageElement that uses the S3ImagePlugin.
 * @param props - The ImageElementProps.
 * @constructor
 */
export const S3ImageElement = (props: ImageElementProps): JSX.Element => {
  const [imgSrc, setImgSrc] = useState("")

  const {
    attributes,
    children,
    element,
    nodeProps,
    caption = {},
    resizableProps = {
      minWidth: 92,
    },
    align = "center",
    draggable,
    editor,
  } = props

  const rootProps = getRootProps(props)

  const { placeholder = "Write a caption..." } = caption

  const { url, bucket, width: nodeWidth = "100%", caption: nodeCaption = [{ children: [{ text: "" }] }] } = element
  const focused = useFocused()
  const selected = useSelected()
  const [width, setWidth] = useState(nodeWidth)

  /**
   * Get the image associated with the given key.
   * @param key - The key (name) of the file.
   * @param bucket - The bucket to retrieve the file from.
   */
  const getImage = async (key: string, bucket: string) => {
    return Auth.currentCredentials().then(async (credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      // If no bucket is provided, then get the placeholder.
      if (!bucket) {
        return s3.getSignedUrl("getObject", {
          Bucket: S3ImageElementUtils.placeholderBucket,
          Key: S3ImageElementUtils.placeholderURL,
        })
      }

      // Check to see if the image actually exists.
      const exists = await s3
        .headObject({
          Bucket: bucket,
          Key: key,
        })
        .promise()
        .then(
          () => true,
          (err) => {
            if (err.code === "NotFound") {
              return false
            }
            throw err
          },
        )

      // If the image doesn't exist, then get the placeholder instead.
      const getParams = {
        Bucket: exists ? bucket : S3ImageElementUtils.placeholderBucket,
        Key: exists ? key : S3ImageElementUtils.placeholderURL,
      }

      return s3.getSignedUrl("getObject", getParams)
    })
  }

  useEffect(() => {
    // Immediately try and fill the image from the bucket.
    if (imgSrc === "") {
      getImage(url, bucket).then((result) => {
        setImgSrc(result)
      })
    }
    setWidth(nodeWidth)
  }, [nodeWidth])

  const styles = getImageElementStyles({ ...props, align, focused, selected })

  const setNodeWidth = useCallback(
    (w: number) => {
      const path = ReactEditor.findPath(editor, element)

      if (w === nodeWidth) {
        // Focus the node if not resized.
        Transforms.select(editor, path)
      } else {
        setNodes(editor, { width: w }, { at: path })
      }
    },
    [editor, element, nodeWidth],
  )

  const onChangeCaption: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      const path = ReactEditor.findPath(editor, element)
      setNodes(editor, { caption: [{ text: e.target.value }] }, { at: path })
    },
    [editor, element],
  )

  const captionString = useMemo(() => {
    return Node.string(nodeCaption[0]) || ""
  }, [nodeCaption])

  // NOTE:
  // This function contains many Typescript ignore declarations because the
  // CSS prop type is not imported. I have spent enough time trying to get this
  // imported and working correctly, and have failed.
  //
  // If someone wants to spend time trying to get this to work without these
  // declarations, they are welcome to try.

  return (
    <div
      {...attributes}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={styles.root.css}
      className={styles.root.className}
      {...rootProps}
      {...nodeProps}
    >
      <div contentEditable={false}>
        <figure
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={styles.figure?.css}
          className={`group ${styles.figure?.className}`}
        >
          <Resizable
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={styles.resizable?.css}
            className={styles.resizable?.className}
            size={{ width, height: "100%" }}
            maxWidth="100%"
            lockAspectRatio
            resizeRatio={align === "center" ? 2 : 1}
            enable={{
              left: ["center", "left"].includes(align),
              right: ["center", "right"].includes(align),
            }}
            handleComponent={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              left: <ImageHandle css={[styles.handleLeft?.css]} className={styles.handleLeft?.className} />,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              right: <ImageHandle css={styles.handleRight?.css} className={styles.handleRight?.className} />,
            }}
            handleStyles={{
              left: { left: 0 },
              right: { right: 0 },
            }}
            onResize={(e, direction, ref) => {
              setWidth(ref.offsetWidth)
            }}
            onResizeStop={(e, direction, ref) => setNodeWidth(ref.offsetWidth)}
            {...resizableProps}
          >
            <img
              data-testid={"ImageElementImage"}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={styles.img?.css}
              className={styles.img?.className}
              src={imgSrc}
              alt={captionString}
              draggable={draggable}
              {...nodeProps}
            />
          </Resizable>

          {!caption.disabled && (captionString.length || selected) && (
            <figcaption
              style={{ width }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={styles.figcaption?.css}
              className={styles.figcaption?.className}
            >
              <TextareaAutosize
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                css={styles.caption?.css}
                className={styles.caption?.className}
                value={nodeCaption[0].text}
                placeholder={placeholder}
                onChange={onChangeCaption}
              />
            </figcaption>
          )}
        </figure>
      </div>
      {children}
    </div>
  )
}

/**
 * Some helpful ImageElement specific utilities.
 */
export const S3ImageElementUtils = {
  /**
   * The placeholder image URL.
   */
  placeholderURL: "missing.jpg",
  /**
   * The placeholder image bucket.
   */
  placeholderBucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
}
