import API from "@common/API"
import Logger from "@common/Logger"
import i18n from "src/i18n"

const { t } = i18n
const SearchService = {
  async getRefinements(): Promise<unknown> {
    return API.get("/search/refinements").catch((err: unknown) => {
      Logger.error(err)
      return Promise.reject({ message: `${t("errors.unable_retrieve_search_refinements")}` })
    })
  },

  async searchSalesforceProject(searchTerm: string) {
    return API.get<Record<string, unknown>>(`/search/search-sf-project?name=${searchTerm}`).catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `${t("errors.unable_retrieve_search_results")}` })
    })
  },
}

export default SearchService
