import moment from "moment"

import CategoryUtils from "./CategoryUtils"

const ReportUtils = {
  getFutureIndex(report, aggregate) {
    if (!report || aggregate === "total") {
      return null
    }
    const START_OF_MONTH = moment().startOf("month")
    let idx = report.dates.findIndex((date) => {
      return START_OF_MONTH.isSameOrBefore(moment(date))
    })

    // If we didn't find the future index check if the supplied dates are in the past then use the last as the future index
    if (idx < 0 && moment().isAfter(moment(report.dates[report.dates.length - 1]))) {
      idx = report.dates.length
    }
    return idx
  },

  initialiseAggregatedReport(report) {
    const aggregatedReport = {
      dates: [],
      actual: { dates: [], unleveredTotals: [], leveredTotals: [], unleveredIrr: [], leveredIrr: [], retainedEarnings: [], netIncomes: [], categories: {} },
      expected: { dates: [], unleveredTotals: [], leveredTotals: [], unleveredIrr: [], leveredIrr: [], retainedEarnings: [], netIncomes: [], categories: {} },
      forecast: { dates: [], unleveredTotals: [], leveredTotals: [], unleveredIrr: [], leveredIrr: [], retainedEarnings: [], netIncomes: [], categories: {} },
      latestBestEstimate: { dates: [], unleveredTotals: [], leveredTotals: [], unleveredIrr: [], leveredIrr: [], retainedEarnings: [], netIncomes: [], categories: {} },
    }

    Object.keys(report.actual.categories).forEach((categoryId) => {
      const category = report.actual.categories[categoryId]
      const groupCategory = CategoryUtils.groupCategory(category)
      aggregatedReport.actual.categories[categoryId] = groupCategory
    })

    Object.keys(report.expected.categories).forEach((categoryId) => {
      const category = report.expected.categories[categoryId]
      const groupCategory = CategoryUtils.groupCategory(category)
      aggregatedReport.expected.categories[categoryId] = groupCategory
    })

    Object.keys(report.forecast.categories).forEach((categoryId) => {
      const category = report.forecast.categories[categoryId]
      const groupCategory = CategoryUtils.groupCategory(category)
      aggregatedReport.forecast.categories[categoryId] = groupCategory
    })

    if (report.latestBestEstimate && report.latestBestEstimate.categories) {
      Object.keys(report.latestBestEstimate.categories).forEach((categoryId) => {
        const category = report.latestBestEstimate.categories[categoryId]
        const groupCategory = CategoryUtils.groupCategory(category)
        aggregatedReport.latestBestEstimate.categories[categoryId] = groupCategory
      })
    }

    return aggregatedReport
  },

  /**
   * Groups the content of the report using the specified date aggregate and the calendar year.
   * @param {{}} report The IRR report to group by date aggregate
   * @param {string} aggregate one of: month, quarter, year and total
   * @returns the aggregated report
   */
  aggregateReport(report, aggregate) {
    if (aggregate === "month") {
      return report
    }

    const aggregatedReport = this.initialiseAggregatedReport(report)
    const futureIdx = this.getFutureIndex(report, aggregate)

    report.dates.forEach((date, idx) => {
      const lastGroupDate = moment(aggregatedReport.dates[aggregatedReport.dates.length - 1])

      if (idx !== 0 && (aggregate === "total" || moment(date).isSame(lastGroupDate, aggregate))) {
        let groupIdx = aggregatedReport.dates.length - 1

        CategoryUtils.addGroupTotals("actual", report, idx, aggregatedReport, groupIdx, futureIdx)
        CategoryUtils.addGroupTotals("expected", report, idx, aggregatedReport, groupIdx, futureIdx)
        CategoryUtils.addGroupTotals("forecast", report, idx, aggregatedReport, groupIdx, futureIdx)

        if (aggregatedReport.latestBestEstimate) {
          CategoryUtils.addGroupTotals("latestBestEstimate", report, idx, aggregatedReport, groupIdx, futureIdx)
        }
      } else {
        // Create a new entry
        aggregatedReport.dates.push(date)

        CategoryUtils.initGroupTotals(report.actual, idx, aggregatedReport.actual, date)
        CategoryUtils.initGroupTotals(report.expected, idx, aggregatedReport.expected, date)
        CategoryUtils.initGroupTotals(report.forecast, idx, aggregatedReport.forecast, date)

        if (aggregatedReport.latestBestEstimate) {
          CategoryUtils.initGroupTotals(report.latestBestEstimate, idx, aggregatedReport.latestBestEstimate, date)
        }
      }
    })

    return aggregatedReport
  },
}

export default ReportUtils
