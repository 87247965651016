import React from "react"

import EcosuiteComponent, { Error, Loading } from "@common/EcosuiteComponent"
import ProjectUtils from "@common/utils/ProjectUtils"

import { EnergyPieGraph } from "@dashboard/energy/graphs/EnergyGraphs"

export class EnergyConsumedPieGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentValid(this.props.readings)) {
      let data = this.props.econodes.map((econode) => {
        let siteId = ProjectUtils.getSiteCode(econode.code)
        let systemId = ProjectUtils.getSystemCode(econode.code)
        let econodeReadings = this.props.readings.sites[siteId] && this.props.readings.sites[siteId].systems[systemId] ? this.props.readings.sites[siteId].systems[systemId] : null
        return { name: econode.name, value: econodeReadings ? econodeReadings.consumption : null }
      })
      return <EnergyPieGraph data={data} fixedTooltip={true} />
    } else {
      return <Loading />
    }
  }
}
