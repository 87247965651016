import { Auth } from "aws-amplify"
import aws from "aws-sdk"
import i18n from "src/i18n"

var JSZip = require("jszip")
const { t } = i18n

var DocumentService = {
  async viewFile(key) {
    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Key: key,
      }

      return s3.getSignedUrlPromise("getObject", params)
    })
  },

  downloadFile(key) {
    Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Key: key,
      }

      s3.getSignedUrl("getObject", params, (err, url) => {
        window.open(url)
      })
    })
  },

  async downloadFiles(fileName, documentKeys) {
    if (documentKeys.length) {
      const signedUrls = await Promise.all(
        documentKeys.map((documentKey) => {
          return this.viewFile(documentKey)
        }),
      )

      const zip = new JSZip()
      var hasContent = false
      await Promise.all(
        documentKeys.map(async (file, idx) => {
          const fileContent = await fetch(signedUrls[idx]).then((response) => {
            if (response.ok) {
              return response.blob()
            } else {
              return null
            }
          })
          if (fileContent) {
            hasContent = true
            zip.file(file, fileContent, { base64: true })
          }
        }),
      )

      if (hasContent) {
        // Generate the zip file asynchronously
        return zip.generateAsync({ type: "blob" }).then((content) => {
          // Force down of the Zip file
          const blobURL = URL.createObjectURL(new Blob([content], { type: "application/zip" }))
          let a = document.createElement("a")
          a.download = fileName
          a.href = blobURL
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          // window.open()
        })
      } else {
        alert(t("alertsInfo.no_file_available"))
      }
    }
  },

  async deleteFile(key, groups) {
    if (groups.includes("data-write")) {
      return Auth.currentCredentials().then((credentials) => {
        const s3 = new aws.S3({
          apiVersion: "2013-04-01",
          credentials: Auth.essentialCredentials(credentials),
        })

        var params = {
          Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
          Key: key,
        }

        return s3.deleteObject(params).promise()
      })
    }
  },
}

export default DocumentService
