import API from "@common/API"
import Logger from "@common/Logger"
import i18n from "src/i18n"

const { t } = i18n
const ExportService = {
  exportProject(projectId: string): Promise<unknown> {
    return API.get(`/projects/${projectId}/export`)
      .then((data: unknown) => {
        return data
      })
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: `${t("errors.unable_to_export_project")}: ` + projectId })
      })
  },
}

export default ExportService
