import * as React from "react"
import Table from "@common/display/Table"
import FinanceService from "@dashboard/finance/FinanceService"
import FinanceUtils from "@dashboard/finance/FinanceUtils"
import i18n from "src/i18n"
import ExportCashFlowPortfolio from "./ExportCashFlowTableData"

const { t } = i18n

interface PaymentTimeSeriesLog {
  total: number
  "0-30": number
  "31-60": number
  "61-90": number
  "91-120": number
  "121-150": number
  "150+": number
}

interface SitePaymentData {
  name: string
  payments: { expected: PaymentTimeSeriesLog; advised: PaymentTimeSeriesLog }
}
interface ProjectPaymentData {
  projectName: string
  payments: { expected: PaymentTimeSeriesLog; advised: PaymentTimeSeriesLog }
  sites: Array<SitePaymentData>
}

interface CashFlowTableDataProps {
  projects: Array<{ id: string; name: string }>
  filter: "expected" | "advised"
}

function createTableData(data: Array<Array<ProjectPaymentData>>, key: "expected" | "advised") {
  const ret = []
  for (const project of data) {
    const projectDetails = project[0]
    const row = {
      name: projectDetails.projectName,
      ...projectDetails.payments[key],
      subRows: projectDetails.sites.map((s) => ({ name: s.name, ...s.payments[key] })),
    }
    ret.push(row)
  }
  return ret
}

const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> => p.status === "fulfilled"
const isRejected = <T,>(p: PromiseSettledResult<T>): p is PromiseRejectedResult => p.status === "rejected"

export default function CashFlowTableData(props: CashFlowTableDataProps) {
  const { projects, filter } = props

  const [, setRejectCount] = React.useState(0)
  const [fetching, setFetching] = React.useState(false)
  const [data, setData] = React.useState<Array<Array<ProjectPaymentData>>>([])

  React.useEffect(() => {
    if (projects.length > 0) {
      setFetching(true)
      const promises = projects.map(({ id, name }) => FinanceService.getCashFlowTimeSeriesArrears(id).then((r) => ({ data: r, projectName: name })))
      Promise.allSettled(promises).then((r) => {
        const rejectedReasons = r.filter(isRejected).map((p) => p.reason)
        setRejectCount(rejectedReasons.length)
        const fulfilledValues = r
          .filter(isFulfilled)
          .map((p) => p.value)
          .filter((v) => v.data.length > 0)
        setData(fulfilledValues.map((item) => item.data.map((d: Array<ProjectPaymentData>) => ({ ...d, projectName: item.projectName }))))
        setFetching(false)
      })
    }
  }, [projects.length])

  return fetching ? (
    <div>{t("labels.loading")}</div>
  ) : (
    <div>
      <ExportCashFlowPortfolio data={createTableData(data, filter)} />
      <Table
        defaultColumn={{ accessorKey: "id" }}
        data={createTableData(data, filter)}
        initialState={{
          sorting: [{ id: "total", desc: true }],
        }}
        columns={[
          {
            id: "name",
            accessorKey: "name",
            header: () => <span>{t("table_headings.name")}</span>,
            cell: ({ row, getValue }) => <Table.DefaultRowExpander row={row} getValue={getValue} />,
            size: 300,
          },
          {
            accessorFn: (row) => row.total,
            id: "total",
            header: "Total",
            size: 200,
            cell: (info) => FinanceUtils.formatCurrency(info.getValue()),
            meta: {
              tooltip: { text: t("economics.tooltips.total_amount_owed"), placement: "top" },
              align: "right",
            },
          },
          {
            id: "zeroToThirty",
            accessorKey: "0-30",
            header: "0-30",
            size: 100,
            cell: (info) => FinanceUtils.formatCurrency(info.getValue()),
            meta: {
              tooltip: { text: t("economics.tooltips.amount_owed_since_last_30"), placement: "top" },
              align: "right",
            },
          },
          {
            id: "thirtyOneToSixty",
            accessorKey: "31-60",
            header: "31-60",
            size: 100,
            cell: (info) => FinanceUtils.formatCurrency(info.getValue()),
            meta: {
              tooltip: { text: t("economics.tooltips.amount_owed_in_last_31"), placement: "top" },
              align: "right",
            },
          },
          {
            id: "sixtyOneToNinety",
            accessorKey: "61-90",
            header: "61-90",
            size: 100,
            cell: (info) => FinanceUtils.formatCurrency(info.getValue()),
            meta: {
              tooltip: { text: t("economics.tooltips.amount_owed_in_last_61"), placement: "top" },
              align: "right",
            },
          },
          {
            id: "ninetyOneToHundredTwenty",
            accessorKey: "91-120",
            header: "91-120",
            size: 100,
            cell: (info) => FinanceUtils.formatCurrency(info.getValue()),
            meta: {
              tooltip: { text: t("economics.tooltips.amount_owed_in_last_91"), placement: "top" },
              align: "right",
            },
          },
          {
            id: "oneHundredTwentyOneToHundredFifty",
            accessorKey: "121-150",
            header: "121-150",
            size: 100,
            cell: (info) => FinanceUtils.formatCurrency(info.getValue()),
            meta: {
              tooltip: { text: t("economics.tooltips.amount_owed_in_last_121"), placement: "top" },
              align: "right",
            },
          },
          {
            id: "oneHundredFiftyOnePlus",
            accessorKey: "150+",
            header: "150+",
            size: 100,
            cell: (info) => FinanceUtils.formatCurrency(info.getValue()),
            meta: {
              tooltip: { text: t("economics.tooltips.amount_owed_in_last_150"), placement: "top" },
              align: "right",
            },
          },
        ]}
      />
    </div>
  )
}
