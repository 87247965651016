import { JSONSchema7 } from "json-schema"

/**
 * An unknown data object that maps some key value to an unknown value.
 */
type DataObject = {
  [key: string]: unknown
}

/**
 * Extract both the properties and dependencies from a schema.
 * @param schema - The schema.
 * @param object - The object to evaluate.
 */
export const extractPropertiesAndDependencies = (schema: JSONSchema7, object: DataObject): DataObject => {
  const formData: DataObject = {}

  // Extract the properties.
  if (schema.properties) {
    Object.keys(schema.properties).forEach((property) => {
      formData[property] = object[property]
    })
  }

  if (object.contactIds) {
    formData["contactIds"] = object["contactIds"] || []
  }

  // Extract the dependencies
  if (schema.dependencies) {
    Object.keys(schema.dependencies).forEach((dependencyKey) => {
      // Evaluate each dependency.
      //
      // This has to be done because the JSON schema provided by
      // react-json-schema is not 100% compliant, so evaluating it as a string
      // makes things easier.
      JSON.stringify(schema.dependencies, (_, nestedValue) => {
        if (nestedValue) {
          if (nestedValue.properties) {
            Object.keys(nestedValue.properties).forEach((property) => {
              // If the property is the same as one of the passed in attributes, save it.
              //
              // Note that we should skip the actual dependency key, as that is the value that we are evaluating for
              // purely as a relationship.
              if (Object.keys(object).includes(property) && property !== dependencyKey) {
                formData[property] = object[property]
              }
            })
          }
        }
        return nestedValue
      })
    })
  }

  return formData
}
