import React from "react"
import moment from "moment-timezone"

import { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"

import { ResponsiveContainer, ComposedChart, Bar, XAxis, CartesianGrid, Tooltip, Legend, YAxis, Line } from "recharts"

import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import { EnergyGraphTooltip } from "@dashboard/energy/graphs/EnergyGraphTooltip"
import GraphUtils from "@common/utils/GraphUtils"
import i18n from "src/i18n"

const { t } = i18n
export default class ConsumptionGenerationBarGraph extends DateRangeGraph {
  renderContent() {
    if (this.props.graphDatums) {
      let data = Object.keys(this.props.graphDatums).map((dateKey) => {
        let entry = this.props.graphDatums[dateKey]
        let date = moment(dateKey)

        const consumption = this.round(entry.consumption)
        const historicConsumption =
          this.props.predictedConsumptionDatums && this.props.predictedConsumptionDatums[dateKey] ? this.round(this.props.predictedConsumptionDatums[dateKey].historicConsumption) : null
        const targetConsumption =
          this.props.predictedConsumptionDatums && this.props.predictedConsumptionDatums[dateKey] ? this.round(this.props.predictedConsumptionDatums[dateKey].targetConsumption) : null
        const difference = consumption && targetConsumption ? -(1 - consumption / targetConsumption) * 100 : 0

        return {
          date: date.toDate(),
          time: date.toDate().getTime(),
          name: date.format("lll"),
          consumption: consumption,
          historicConsumption: historicConsumption,
          targetConsumption: targetConsumption,
          difference: difference,
        }
      })

      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"

      return (
        <ResponsiveContainer width="100%" height={250} className="econode-tooltip">
          <ComposedChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
            <XAxis
              scale="band"
              dataKey="time"
              domain={this.getDomain(this.props.range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis />
            <YAxis yAxisId="difference" orientation="right" unit="%" />
            <Tooltip content={<EnergyGraphTooltip assets={this.props.assets} getDataTitle={getDataTitle} range={this.props.range} aggregation={this.props.aggregation} />} />
            <Legend iconType="plainline" />
            {this.props.showConsumption ? (
              <Bar name={getDataTitle("consumption")} type={graphType} dataKey="consumption" stroke={GRAPH_COLORS[0]} fill={GRAPH_COLORS[0]} dot={aggregation === "day"} />
            ) : null}
            {this.props.showConsumption ? (
              <Bar name={getDataTitle("targetConsumption")} type={graphType} dataKey="targetConsumption" stroke={GRAPH_COLORS[1]} fill={GRAPH_COLORS[1]} dot={aggregation === "day"} />
            ) : null}
            {this.props.showConsumption ? (
              <Bar name={getDataTitle("historicConsumption")} type={graphType} dataKey="historicConsumption" stroke={GRAPH_COLORS[2]} fill={GRAPH_COLORS[2]} dot={aggregation === "day"} />
            ) : null}
            {this.props.showConsumption ? <Line yAxisId="difference" name={getDataTitle("difference")} type={graphType} dataKey="difference" stroke={GRAPH_COLORS[3]} strokeWidth={2} /> : null}
            {this.props.referenceLine ? this.props.referenceLine : null}

            {this.renderUserAlerts()}
            {this.renderAssetEvents()}
          </ComposedChart>
        </ResponsiveContainer>
      )
    }

    return <Loading />
  }
}

export var getDataTitle = function (name) {
  switch (name) {
    case "difference":
      return `${t("labels.difference")}`
    case "targetConsumption":
      return `${t("econode.dataTitles.Target Consumption")}`
    case "historicConsumption":
      return `${t("econode.dataTitles.Historic Consumption")}`
    case "predictedConsumption":
      return `${t("econode.dataTitles.Forecast Consumption")}`
    case "consumption":
      return `${t("econode.dataTitles.Actual Consumption")}`
    default:
      return name
  }
}
