import React from "react"

import { Button, Container, Row } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Schemas from "@common/Schemas"
import NotificationService from "./NotificationService"
import NotificationForm from "./NotificationForm"
import ProjectService from "@common/project/ProjectService"
import DRService from "@dashboard/demandresponse/DRService"
import DRUtils from "@dashboard/demandresponse/DRUtils"
import i18n from "src/i18n"

const { t } = i18n
export default class Notifications extends EcosuiteComponent {
  componentDidMount() {
    super.componentDidMount()
    Schemas.getNotificationSchema().then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
    this.loadSourceIds()

    const demandresponse = this.props.groups.includes("demandresponse")
    const admin = this.props.admin

    this.setState({
      admin: admin,
    })

    // All users can query a list of projects
    const actions = [
      ProjectService.getProjects().then((projects) =>
        this.setStateIfMounted({
          projects: projects,
        }),
      ),
    ]

    // Only users in the demand response group can query a list of participants
    if (demandresponse) {
      actions.push(
        DRService.listParticipants().then((participants) =>
          this.setStateIfMounted({
            participants: participants,
          }),
        ),
      )
    }

    Promise.all(actions).then(() => {
      return this.loadNotifications(this.props.userId)
    })

    this.newNotification = this.newNotification.bind(this)
    this.removeNotification = this.removeNotification.bind(this)
    this.updateUserNotification = this.updateUserNotification.bind(this)
    this.renderNotifications = this.renderNotifications.bind(this)
    this.loadNotifications = this.loadNotifications.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      this.loadNotifications(this.props.userId)
    }
  }

  loadSourceIds() {
    this.setStateIfMounted({
      sourceIds: null,
    })
    NotificationService.getDevices().then((devices) => {
      let sourceIds = new Set()
      Object.keys(devices).forEach((projectId) => {
        let project = devices[projectId]
        Object.keys(project.sites).forEach((siteId) => {
          let site = project.sites[siteId]
          Object.keys(site.systems).forEach((systemId) => {
            let system = site.systems[systemId]
            Object.keys(system.nodes).forEach((nodeId) => {
              let node = system.nodes[nodeId]
              node.devices.forEach((device) => {
                let sourceId = "/" + [projectId, siteId, systemId, device].join("/")
                sourceIds.add(sourceId)
              })
            })
          })
        })
      })

      this.setStateIfMounted({
        sourceIds: Array.from(sourceIds),
      })
    })
  }

  async loadNotifications(userId) {
    this.setStateIfMounted({
      notifications: null,
      selectedUserId: userId,
    })

    const users = this.props.users
    const notifications = await NotificationService.getUserNotifications(userId)
    const projects = this.state.projects
    const participants = this.state.participants

    // If participants is null, we don't have access to any demand response features, so let items be empty
    const items = participants ? projects.filter((p) => participants.find((participant) => p.code === participant.code)) : []

    let options = items.map((project) => {
      return {
        getID() {
          return project.code
        },
        getValues() {
          return [project.code, project.name]
        },
      }
    })

    // If we're an admin, filter down the items according to the targeted user
    if (this.state.admin) {
      const allowed = await DRUtils.allowedProjects(
        projects,
        users.find((user) => user.id === userId),
      )
      const setOfAllowed = new Set(allowed)
      options = options.filter((option) => setOfAllowed.has(option.getID()))
    }

    this.setStateIfMounted({
      notifications: notifications,
      options: options,
    })
  }

  newNotification(e) {
    e.preventDefault()

    return this.setStateIfMounted({
      notifications: [...this.state.notifications, {}],
    })
  }

  removeNotification(notificationId) {
    this.setStateIfMounted({
      notifications: this.state.notifications.filter((notification) => notification.id !== notificationId),
    })
  }

  updateUserNotification(originalNotification, updatedNotification) {
    let notifications = [...this.state.notifications]
    notifications.forEach((notification, idx) => {
      if (notification.id === originalNotification.id) {
        notifications[idx] = updatedNotification
      }
    })

    this.setStateIfMounted({
      notifications: notifications,
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Container fluid={true} className="notification-content">
            <Row>
              <>
                {this.props.renderTitle && <h1>{t("labels.notifications")}</h1>}
                <div className="user-notifications">{this.renderNotifications()}</div>
              </>
            </Row>
          </Container>
        </Row>
      </div>
    )
  }

  renderNotifications() {
    if (this.state.schema && this.state.notifications && this.state.sourceIds) {
      if (this.state.notifications.length < 1) {
        return (
          <React.Fragment>
            <div className="message">{t("messages.no_notification_configured")}</div>

            <Button onClick={this.newNotification} color="primary">
              {t("labels.new_notification")}
            </Button>
          </React.Fragment>
        )
      } else {
        // We dynamically configure the alert notification source IDs enum with the latest values
        this.state.schema.dependencies.notificationType.oneOf[1].properties.alerts.items.properties.sourceId.enum = this.state.sourceIds

        return (
          <div>
            {this.state.notifications.map((notification, idx) => {
              return (
                <div className="ecogy-form" key={idx}>
                  <NotificationForm
                    userId={this.state.selectedUserId}
                    schema={this.state.schema}
                    notification={notification}
                    projects={this.state.projects}
                    participants={this.state.options}
                    removeNotification={this.removeNotification}
                    updateUserNotification={this.updateUserNotification}
                  />
                </div>
              )
            })}
            {this.state.notifications.find((notification) => !notification.id) ? null : (
              <Button onClick={this.newNotification} color="primary">
                {t("labels.new_notification")}
              </Button>
            )}
          </div>
        )
      }
    } else {
      return <Loading />
    }
  }
}
