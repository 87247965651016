import React from "react"

import EcosuiteComponent, { Error } from "@common/EcosuiteComponent"
import Settings from "@common/Settings"

import GraphSelector from "@dashboard/GraphSelector"
import DatumUtils from "@dashboard/energy/graphs/datums/DatumUtils"
import EventLineGraph from "@dashboard/event/line-graph/EventLineGraph"

import EconodeUtils from "src/econode/EconodeUtils"

import TotalConsumptionBarGraph from "@econode/energy/graphs/consumption/TotalConsumptionBarGraph"
import ConsumptionLineGraph from "@econode/energy/graphs/consumption/ConsumptionLineGraph"
import ConsumptionPowerLineGraph from "@econode/energy/graphs/consumption/ConsumptionPowerLineGraph"
import PowerFactorBarGraph from "@econode/energy/graphs/PowerFactorBarGraph"
import ApparantPowerLineGraph from "@econode/energy/graphs/ApparantPower"
import i18n from "src/i18n"

const { t } = i18n
export default class EconodeConsumptionGraphs extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      econodeGraphView: Settings.getSetting("econodeGraphView", "consumption"),
    }
    this.selectGraph = this.selectGraph.bind(this)
  }

  selectGraph(graph) {
    Settings.setSetting("econodeGraphView", graph)
    this.setState({ econodeGraphView: graph })
  }

  renderContent() {
    const econodes = this.props.econodes
    const graphDatums = this.props.graphDatums

    if (this.isContentError(this.props.datums)) {
      return <Error error={this.props.datums.getError()} />
    } else if (this.isContentValid(this.props.datums) && graphDatums && Object.keys(graphDatums).length > 0) {
      // if toggle is disabled predictedConsumption should be null
      let predictedConsumptionDatums =
        this.props.showForecastConsumption && this.isContentValid(this.props.predictedConsumption)
          ? DatumUtils.groupPredictedConsumptionDatums(econodes, EconodeUtils.mapEconodeDatums(this.props.project, this.props.predictedConsumption))
          : null

      let datumsRange = this.props.datumsRange
      let aggregation = this.props.datumsAggregation

      let graphs = {}
      if (this.props.showConsumption) {
        graphs.consumption = {
          graph: (
            <React.Fragment>
              <EventLineGraph
                readonly={this.props.readonly}
                groups={this.props.groups}
                projects={[this.props.project]}
                range={this.props.range}
                dotAggregate={aggregation + "s"}
                type="energy"
                centered={true}
                project={this.props.project.code}
                style={{
                  marginLeft: "60px",
                  width: "calc(100% - 90px)",
                }}
              />

              <TotalConsumptionBarGraph
                project={this.props.project}
                site={this.props.site}
                assets={econodes}
                graphDatums={graphDatums}
                datums={this.props.datums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                range={datumsRange}
                aggregation={aggregation}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                selectRange={this.props.selectRange}
                referenceLine={this.props.consumptionReferenceLine}
              />
            </React.Fragment>
          ),
          name: `${t("econode.dataTitles.Total Consumption")} (kWh)`,
        }

        graphs.econodeConsumption = {
          graph: (
            <React.Fragment>
              <EventLineGraph
                readonly={this.props.readonly}
                groups={this.props.groups}
                projects={[this.props.project]}
                range={this.props.range}
                dotAggregate={aggregation + "s"}
                type="energy"
                centered={false}
                project={this.props.project.code}
                style={{
                  marginLeft: "60px",
                  width: "calc(100% - 90px)",
                }}
              />

              <ConsumptionLineGraph
                project={this.props.project}
                site={this.props.site}
                assets={econodes}
                graphDatums={graphDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                datums={this.props.datums}
                range={datumsRange}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
                referenceLine={this.props.consumptionReferenceLine}
              />
            </React.Fragment>
          ),
          name: `${t("labels.consumption")} (kWh)`,
        }
      }

      if (this.props.showConsumption) {
        graphs.econodePower = {
          graph: (
            <React.Fragment>
              <EventLineGraph
                readonly={this.props.readonly}
                groups={this.props.groups}
                projects={[this.props.project]}
                range={this.props.range}
                dotAggregate={aggregation + "s"}
                type="energy"
                centered={false}
                project={this.props.project.code}
                style={{
                  marginLeft: "60px",
                  width: "calc(100% - 90px)",
                }}
              />
              <ConsumptionPowerLineGraph
                project={this.props.project}
                site={this.props.site}
                assets={econodes}
                graphDatums={graphDatums}
                datums={this.props.datums}
                range={datumsRange}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
                referenceLine={this.props.demandReferenceLine}
              />
            </React.Fragment>
          ),
          name: `${t("table_headings.demand")} (kW)`,
        }
      }

      graphs.apparantPower = {
        graph: (
          <>
            <EventLineGraph
              readonly={this.props.readonly}
              groups={this.props.groups}
              projects={[this.props.project]}
              range={this.props.range}
              dotAggregate={aggregation + "s"}
              type="energy"
              centered={false}
              project={this.props.project.code}
              style={{
                marginLeft: "60px",
                width: "calc(100% - 90px)",
              }}
            />
            <ApparantPowerLineGraph
              project={this.props.project}
              site={this.props.site}
              assets={econodes}
              graphDatums={graphDatums}
              datums={this.props.datums}
              range={datumsRange}
              aggregation={aggregation}
              selectRange={this.props.selectRange}
              referenceLine={this.props.apparantPowerReferenceLine}
            />
          </>
        ),
        name: `${t("econode.dataTitles.Apparent Power")} (kVA)`,
      }

      graphs.powerFactorGraph = {
        graph: (
          <React.Fragment>
            <EventLineGraph
              readonly={this.props.readonly}
              groups={this.props.groups}
              projects={[this.props.project]}
              range={this.props.range}
              dotAggregate={aggregation + "s"}
              type="energy"
              centered={false}
              project={this.props.project.code}
              style={{
                marginLeft: "60px",
                width: "calc(100% - 90px)",
              }}
            />
            <PowerFactorBarGraph
              project={this.props.project}
              site={this.props.site}
              assets={econodes}
              graphDatums={graphDatums}
              datums={this.props.datums}
              range={datumsRange}
              aggregation={aggregation}
              selectRange={this.props.selectRange}
              referenceLine={this.props.powerFactorReferenceLine}
            />
          </React.Fragment>
        ),
        name: `${t("econode.dataTitles.Power Factor")}`,
      }

      return <GraphSelector selectedGraph={this.state.econodeGraphView} selectGraph={this.selectGraph} graphs={graphs} selectRange={this.props.selectRange} range={this.props.range} />
    } else {
      return <GraphSelector range={this.props.range} selectRange={this.props.selectRange} />
    }
  }
}
