import Logger from "@common/Logger"
import _ from "lodash"

const SCRIPT_GOOGLE_TAG_MANAGER = `https://www.googletagmanager.com/gtag/js`
const SCRIPT_LUCKY_ORANGE = "https://d10lpsik1i8c69.cloudfront.net/w.js"

const installGoogleTagManager = (trackingId) => {
  // Do nothing if the tracking id is not specified
  if (!trackingId) return

  // Get window title so we can inject scripts into <head>
  const title = document.getElementsByTagName("title")[0]

  // Install Google Tag Manager
  const tagManager = document.createElement("script")
  tagManager.name = "google-tag-manager"
  tagManager.type = "text/javascript"
  tagManager.async = true
  tagManager.src = SCRIPT_GOOGLE_TAG_MANAGER + `?id=${trackingId}`
  title.parentNode.insertBefore(tagManager, title)
  Logger.debug("Google Tag Script installed", tagManager)

  // Add gtag function globally to window
  const gtag = document.createElement("script")
  gtag.name = "google-tag-manager"
  gtag.type = "text/javascript"
  gtag.text = `
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "${trackingId}")
  `
  title.parentNode.insertBefore(gtag, title)
  Logger.debug("gtag function added to window", gtag)
}

const installLuckyOrange = (siteId) => {
  // do nothing if site id not specified, e.g. maybe non-prod environments
  if (!siteId) return

  // asynchronous loading script for Lucky Orange
  window.__lo_site_id = siteId
  const luckyOrange = document.createElement("script")
  luckyOrange.type = "text/javascript"
  luckyOrange.async = true
  luckyOrange.src = SCRIPT_LUCKY_ORANGE
  const script = document.getElementsByTagName("script")[0]
  script.parentNode.insertBefore(luckyOrange, script)

  Logger.debug(`Lucky Orange Installed`, luckyOrange)
}

const uninstallGoogleTagManager = () => {
  const scripts = document.getElementsByName("google-tag-manager")
  if (scripts.length) {
    scripts.forEach((script) => script.parentNode.removeChild(script))
  }
  Logger.debug("Google Analytics Uninstalled", scripts)
}

const uninstallLuckyOrange = () => {
  // if Lucky Orange script was injected, clean-up ourselves
  const scripts = document.getElementsByTagName("script")
  const luckyOrange = _.find(scripts, (script) => _.isEqual(script.getAttribute("src"), SCRIPT_LUCKY_ORANGE))

  if (luckyOrange) {
    luckyOrange.parentNode.removeChild(luckyOrange)

    Logger.debug(`Lucky Orange Uninstalled`, luckyOrange)
  }
}

const setUserLuckyOrange = (user) => {
  // signed-in user tracking is implemented in Lucky Orange by setting custom data against current session
  window._loq = window._loq || []
  window._loq.push(["custom", _.pick(user, ["name", "email"])])
}

const emitGoogleTag = (event) => {
  // How to handle tracking events using the Google gtag
  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  // Important to note that the gtag function was added globally to the window so `window.gtag(...)` invokes it.
  if (window.gtag) {
    window.gtag("event", "page_updated", {
      event_label: event.name,
    })
  }
  Logger.debug("gtag event", event)
}

const emitLuckyOrange = (event) => {
  // custom events works in Lucky Orange by adding tags to the current session
  window._loq = window._loq || []
  if (_.get(event, "name")) {
    window._loq.push(["tag", event.name])
  }
}

/**
 * Initialize all tracking modules
 */
export const install = (organization) => {
  installLuckyOrange(organization.settings.luckyOrangeSiteID)
  installGoogleTagManager(process.env.REACT_APP_GOOGLE_TRACKING_ID)
  // any additional tracking packages (e.g. GA) can be installed below...
}

/**
 * Clean-up all tracking modules
 */
export const uninstall = () => {
  uninstallLuckyOrange()
  uninstallGoogleTagManager()
  // remove additional tracking packages as required below...
}

/**
 * Inform tracking module of currently signed-in user detail
 */
export const setUser = (user) => {
  setUserLuckyOrange(user)
  // additional tracking package actions below...
}

/**
 * Emit custom tracking event
 */
export const emit = (event) => {
  emitLuckyOrange(event)
  emitGoogleTag(event)
  // additional tracking package actions below...
}
