import React, { Component } from "react"
import logo from "src/img/ecosuite_logo.png"

export default class LoginHeader extends Component {
  render() {
    return (
      <header className="App-header">
        <img src={logo} className="logo" alt="logo" />
      </header>
    )
  }
}
