/**
 * The order of months in a year.
 */
const monthOrder: { [k: string]: number } = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
}

/**
 * Sorts months in order of January to December.
 * @param a - The first month.
 * @param b - The second month.
 */
export const monthSorter = (a: string, b: string) => monthOrder[a.toLowerCase()] - monthOrder[b.toLowerCase()]

/**
 * Sorts months in order of January to December.
 * @param months - The months to sort.
 */
export const monthSort = (months: string[]) => months.sort((a: string, b: string) => monthSorter(a, b))
