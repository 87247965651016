import React from "react"
import { ButtonToolbar, ButtonGroup, Button, Label } from "reactstrap"
import EcosuiteComponent from "@common/EcosuiteComponent"
import i18n from "src/i18n"

const { t } = i18n

class PaymentLogControls extends EcosuiteComponent {
  availibleYears() {
    let availibleYears = []
    let lastYear = {}
    this.props.cashFlowPaymentLogs.paymentLogs.payments.forEach((payment) => {
      if (availibleYears.length > 3) {
        return
      }
      let curYear = payment.start.slice(0, 4)
      if (curYear !== lastYear) {
        availibleYears.push(curYear)
        lastYear = curYear
      }
    })
    if (this.props.selectedYear === "most recent") {
      this.props.actions.setYear(availibleYears[0])
    }

    return availibleYears
  }

  yearButton(year) {
    if (year === this.props.selectedYear) {
      return <Button className="selected">{year}</Button>
    }
    return <Button onClick={() => this.props.actions.setYear(year)}>{year}</Button>
  }

  allYearsButton() {
    if (!this.props.selectedYear) {
      return <Button className="selected">{t("labels.all")}</Button>
    }
    return <Button onClick={() => this.props.actions.setYear()}>{t("labels.all")}</Button>
  }

  yearButtons() {
    let availibleYears = this.availibleYears()
    let yearButtons = []
    yearButtons.push(this.allYearsButton())
    availibleYears
      .slice()
      .reverse()
      .forEach((year) => yearButtons.push(this.yearButton(year)))
    return <React.Fragment>{yearButtons}</React.Fragment>
  }

  renderContent() {
    return (
      <div className="content-view-controls">
        <ButtonToolbar className="float-start">
          <Label> {t("labels.year filter")} </Label>

          <ButtonGroup className="header-button-group" size="sm">
            {this.yearButtons()}
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }
}

export default PaymentLogControls
