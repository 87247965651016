import React from "react"
import { Form, Label, Input, Row, Col, Alert } from "reactstrap"
import jsprim from "jsprim"

import Logger from "@common/Logger"
import ProjectUtils from "@common/utils/ProjectUtils"
import EcosuiteComponent, { EcosuiteComponentError, Loading } from "@common/EcosuiteComponent"

import RecordService from "../RecordService"
import ProFormaService from "@dashboard/data/pro-forma/ProFormaService"

import RecordForm from "./RecordForm"
import RecordInfo from "./RecordInfo"
import RecordLinkedProForma from "./RecordLinkedCashFlow/LinkedCashFlowPopover"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
import { Trans } from "react-i18next"
import DocumentService from "@dashboard/data/DocumentService"

const { t } = i18n

class RecordDetails extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.selectProject = this.selectProject.bind(this)
    this.recordModified = this.recordModified.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.loadDocuments = this.loadDocuments.bind(this)
    this.previewDocument = this.previewDocument.bind(this)
    this.selectDocument = this.selectDocument.bind(this)
    this.selectNextDocument = this.selectNextDocument.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)

    this.state = {
      linkedCashFlow: "Loading",
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadData(this.props.record)
    document.addEventListener("keydown", this.handleKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.record !== prevProps.record) {
      this.loadData(this.props.record)
    }
  }

  previewDocument(previewDocument) {
    this.setStateIfMounted({ previewDocument: previewDocument })
  }

  selectDocument(selectedDocument) {
    this.setStateIfMounted({ selectedDocument: selectedDocument })
  }

  previewFile(document) {
    DocumentService.viewFile(document.fileKey).then((url) => {
      const documentParts = document.fileKey.split("/")
      const previewDocument = { documentUrl: url, documentName: documentParts[documentParts.length - 1] }
      this.previewDocument(previewDocument)
    })
  }

  selectNextDocument(increment) {
    const records = this.state.recordDocuments
    let idx = records.findIndex((record) => record.fileName === this.state.previewDocument.documentName)
    idx += increment
    let length = records.length
    if (idx < length && idx >= 0) {
      this.previewFile(records[idx])
    }
  }

  handleKeyPress(e) {
    if (e.key === "ArrowDown") {
      e.preventDefault()
      {
        this.selectNextDocument(-1)
      }
    }
    if (e.key === "ArrowRight") {
      e.preventDefault()
      {
        this.selectNextDocument(-1)
      }
    }
    if (e.key === "ArrowUp") {
      e.preventDefault()
      {
        this.selectNextDocument(1)
      }
    }
    if (e.key === "ArrowLeft") {
      e.preventDefault()
      {
        this.selectNextDocument(1)
      }
    }
  }

  renderPreviewContent() {
    const parts = this.state.previewDocument.documentUrl.split(".")
    if (parts.length > 1) {
      const suffix = parts.pop()
      if (suffix.startsWith("doc") || suffix.startsWith("xl") || suffix.startsWith("odt") || suffix.startsWith("pp")) {
        return (
          <iframe
            title={t("labels.preview")}
            className="preview-content"
            src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
              this.state.previewDocument.documentUrl,
            )}`}
          ></iframe>
        )
      } else {
        return (
          <div className={"preveiw-with-controls"}>
            <button className={"arrow"} onClick={() => this.selectNextDocument(1)}>
              <Icon icon="chevron_left" />
            </button>
            <iframe
              title={t("labels.preview")}
              className="preview-content"
              src={this.state.previewDocument.documentUrl}
            ></iframe>
            <button className={"arrow"} onClick={() => this.selectNextDocument(-1)}>
              <Icon icon="chevron_right" />
            </button>
          </div>
        )
      }
    }

    return (
      <div className="preview-content">
        <p className="preview-unavailable">{t("warnings.preview_not_available")}</p>
      </div>
    )
  }

  renderPreview() {
    return (
      <div className="preview">
        <div className="preview-header">
          <div
            className="float-end"
            onClick={() => {
              this.setStateIfMounted({ previewDocument: null })
            }}
          >
            <Icon icon="close" className="close-button" />
          </div>
          <div className="preview-title">{`Preview: ${this.state.previewDocument.documentName}`}</div>
        </div>
        {this.renderPreviewContent()}
      </div>
    )
  }

  /**
   * Load the necessary data.
   * @param record - The record.
   */
  loadData(record) {
    if (record && record.path) {
      let projectId = ProjectUtils.getProjectCode(record.path)
      let siteId = ProjectUtils.getSiteCode(record.path)
      let systemId = ProjectUtils.getSystemCode(record.path)

      let project = this.props.projects.find((project) => project.code === projectId),
        site,
        system
      if (siteId) {
        site = Object.values(project.sites).find((site) => site.code === siteId)
        if (systemId) {
          system = Object.values(site.systems).find((system) => system.code === systemId)
        }
      }

      this.setStateIfMounted({ project: project, site: site, system: system })
      this.loadDocuments(record)
      this.loadRecordPayments(record)
      this.loadLinkedCashFlow(projectId)
    } else {
      this.setStateIfMounted({
        project: this.props.project,
        site: undefined,
        system: undefined,
        // Indicate that the data has tried to load.
        linkedCashFlow: null,
        recordPayments: null,
      })
    }
  }

  selectProject(projectId) {
    if (projectId) {
      let project = this.props.projects.find((project) => project.code === projectId)
      this.setStateIfMounted({ project: project, site: undefined, system: undefined })
    } else {
      this.setStateIfMounted({ project: undefined, site: undefined, system: undefined })
    }
  }

  selectSite(siteId) {
    if (siteId) {
      let site = Object.values(this.state.project.sites).find((site) => site.code === siteId)
      this.setStateIfMounted({ site: site, system: undefined })
    } else {
      this.setStateIfMounted({ site: undefined, system: undefined })
    }
  }

  selectSystem(systemId) {
    if (systemId) {
      let system = Object.values(this.state.site.systems).find((system) => system.code === systemId)
      this.setStateIfMounted({ system: system })
    } else {
      this.setStateIfMounted({ system: undefined })
    }
  }

  async loadDocuments(record) {
    if (record && record.id) {
      this.setStateIfMounted({ recordDocuments: undefined })
      const recordId = record.id
      RecordService.getRecordDocuments(recordId)
        .then((response) => {
          if (this.isRecordCurrent(recordId)) {
            this.setStateIfMounted({ recordDocuments: response.data.documents })
          } else {
            Logger.debug(`Ignoring out of date response for record: ${recordId}`)
          }
        })
        .catch((error) => {
          Logger.error(error)
          if (this.isRecordCurrent(recordId)) {
            this.setStateIfMounted({
              hasError: true,
              error: error,
            })
          } else {
            Logger.debug(`Ignoring out of date response for record: ${recordId}`)
          }
        })
    } else {
      this.setStateIfMounted({ recordDocuments: null })
    }
  }

  async loadRecordPayments(record) {
    this.setStateIfMounted({ recordPayments: undefined })
    if (record && record.id) {
      const recordId = record.id
      RecordService.getRecordPayments(recordId)
        .then((response) => {
          if (this.isRecordCurrent(recordId)) {
            this.setStateIfMounted({ recordPayments: response })
          } else {
            Logger.debug(`Ignoring out of date repsonse for record: ${recordId}`)
          }
        })
        .catch((error) => {
          Logger.error(error)
          if (this.isRecordCurrent(recordId)) {
            this.setStateIfMounted({
              hasError: true,
              error: error,
            })
          } else {
            Logger.debug(`Ignoring out of date repsonse for record: ${recordId}`)
          }
        })
    }
  }

  /**
   * Load the linked cash flow.
   *
   * Tries to find the cash flow with the linked record ID.
   * @param projectId
   * @returns {Promise<T>}
   */
  async loadLinkedCashFlow(projectId) {
    this.setStateIfMounted({ linkedCashFlow: null })

    // Only run to load the linked cash flow if the record has an ID. We aren't interested in pro formas with cash flows
    // of record ID null/undefined/whatever.
    if (this.props.record.id) {
      return ProFormaService.getProjectProForma(projectId)
        .then((proForma) => {
          // Since each pro forma cashflow is linked to a specific record id there should only ever be
          // one or zero results, so accessing position zero will either be a cashflow or undefined
          const linkedCashFlow = proForma.cashFlows?.filter((cashflow) => {
            return cashflow.recordId === this.props.record.id
          })[0]
          this.setStateIfMounted({ linkedCashFlow })
        })
        .catch((err) => {
          Logger.error(err)
          this.setStateIfMounted({ linkedCashFlow: new EcosuiteComponentError(err) })
        })
    }
  }

  isRecordCurrent(recordId) {
    return this.props.record && recordId === this.props.record.id
  }

  setLoading(loading) {
    this.setStateIfMounted({ loading: loading })
  }

  /**
   * Used to notify that the record has been modifed in some form and may need to be saved.
   */
  recordModified(record) {
    if (
      !record.id &&
      (!this.state.record ||
        (record && record.recordSubType && record.recordSubType != this.state.record.recordSubType))
    ) {
      this.populateRecordFromProject(record, this.state.project, this.state.site, this.state.system)
    }
    this.setState({ record: record })
  }

  populateRecordFromProject(record, project, site, system) {
    if (record) {
      switch (record.recordSubType) {
        case "devCoLLC":
          this.populateDevCoLLCFromProject(record, project)
          break
        case "siteLease":
          this.populateSiteLeaseFromSite(record, site)
          break
        case "systemDesign":
          this.populateSystemDesignFromSystem(record, system)
          break
      }
    }
  }

  populateDevCoLLCFromProject(devCoLLC, project) {
    if (project) {
      devCoLLC.companyName = project.companyName
      devCoLLC.ein = project.einNumber
    }
  }

  populateSiteLeaseFromSite(siteLease, site) {
    if (site) {
      siteLease.address = site.address
    }
  }

  populateSystemDesignFromSystem(systemDesign, system) {
    if (system) {
      systemDesign.totalSystemDCRatingkWPeak = system.size || system.dcSize
      systemDesign.totalSystemACRatingkW = system.acSize
      systemDesign.solarArrays = system.solarArrays
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    /**
     * We dont' want to update when recordModified is called. We currently determine this by checking this.state.record which only recordModified updates.
     * In all other cases we perform the default behaviour and update.
     */
    const recordModified = !jsprim.deepEqual(this.state.record, nextState.record)
    return !recordModified
  }

  isModified() {
    return this.state.record && !jsprim.deepEqual(this.state.record, this.props.record)
  }

  renderCloseDialog() {
    return (
      <div className={"item-details-title " + (this.props.record.id ? "item-edit-title" : "item-create-title")}>
        <h2>
          {this.props.readonly
            ? t("buttons.view_record")
            : this.props.record.id
              ? t("buttons.edit_record")
              : t("buttons.create_record")}
          {this.props.record.id ? <span className="header-id">{this.props.record.id}</span> : null}

          <div
            className="float-end"
            onClick={() => {
              if (
                this.props.readonly ||
                !this.isModified() ||
                window.confirm(t("data.confirmation_msg.save_changes"))
              ) {
                const selectedTypes = sessionStorage.getItem("selectedTypes")
                this.props.selectRecord(undefined, undefined, JSON.parse(selectedTypes))
              }
            }}
          >
            <Icon icon="close" className="close-button" />
          </div>

          <RecordLinkedProForma linkedCashFlow={this.state.linkedCashFlow} />
          {this.props.record.userName ? (
            <span className="right-title">
              {t("labels.added_by")}: {this.props.record.userName}
            </span>
          ) : null}
        </h2>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }

    return (
      <React.Fragment>
        <Alert color="info">
          <Trans i18nKey="data.documents_can_be_uploaded">
            Documents can be uploaded in the <b>Upload Document</b> button below.
          </Trans>
        </Alert>
        {this.state.previewDocument ? this.renderPreview() : null}
        <div className="item-details" style={{ display: this.state.previewDocument ? "none" : "block" }}>
          {this.renderCloseDialog()}
          <div className="item-details-content record-content">
            <Row>
              <Col sm={8}>
                {this.renderLocation()}

                <RecordForm
                  groups={this.props.groups}
                  autoCompletes={this.props.autoCompletes}
                  project={this.state.project}
                  site={this.state.site}
                  system={this.state.system}
                  record={this.props.record}
                  linkedCashFlow={this.state.linkedCashFlow}
                  recordAdded={this.props.recordAdded}
                  recordUpdated={this.props.recordUpdated}
                  recordDeleted={this.props.recordDeleted}
                  recordModified={this.recordModified}
                  viewableRecordTypes={this.props.viewableRecordTypes}
                  disabledSubTypes={this.props.disabledSubTypes}
                  setLoading={this.setLoading}
                  readonly={this.props.readonly}
                />
              </Col>
              <Col sm={4} className="side-panel">
                <RecordInfo
                  restrictions={this.props.restrictions}
                  userName={this.props.userName}
                  groups={this.props.groups}
                  record={this.props.record}
                  typeHierarchy={this.props.typeHierarchy}
                  project={this.state.project}
                  site={this.state.site}
                  system={this.state.system}
                  recordDocuments={this.state.recordDocuments}
                  recordPayments={this.state.recordPayments}
                  document={this.state.selectedDocument}
                  actions={{
                    loadDocuments: this.loadDocuments,
                    previewDocument: this.previewDocument,
                    selectDocument: this.selectDocument,
                  }}
                  readonly={this.props.readonly}
                />
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderLocation() {
    return (
      <Form className="ecogy-form">
        <div className="row form-group">
          <div className="col-sm-2">
            <Label>
              {t("labels.project")}
              <span>*</span>
            </Label>
          </div>
          <div className="col-sm-10">
            <Input
              type="select"
              required={true}
              value={this.state.project ? this.state.project.code : undefined} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
              onChange={(e) => {
                this.selectProject(e.target.value)
              }}
              disabled={this.props.readonly}
            >
              <option value="">{t("labels.select_project")}</option>
              {this.props.projects.map((project) => {
                return (
                  <option key={project.code} value={project.code}>
                    {project.name}
                  </option>
                )
              })}
            </Input>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-sm-2">
            <Label>{t("labels.site")}</Label>
          </div>
          <div className="col-sm-10">
            <Input
              type="select"
              required={true}
              value={this.state.site ? this.state.site.code : undefined} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
              onChange={(e) => {
                this.selectSite(e.target.value)
              }}
              disabled={this.props.readonly}
            >
              <option value="">{t("labels.select_site")}</option>
              {this.state.project
                ? Object.values(this.state.project.sites).map((site) => {
                    return (
                      <option key={site.code} value={site.code}>
                        {site.name}
                      </option>
                    )
                  })
                : null}
            </Input>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-sm-2">
            <Label>{t("labels.system")}</Label>
          </div>
          <div className="col-sm-10">
            <Input
              type="select"
              required={true}
              value={this.state.system ? this.state.system.code : undefined} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
              onChange={(e) => {
                this.selectSystem(e.target.value)
              }}
              disabled={this.props.readonly}
            >
              <option value="">{t("labels.select_system")}</option>
              {this.state.site
                ? Object.values(this.state.site.systems).map((system) => {
                    return (
                      <option key={system.code} value={system.code}>
                        {system.name}
                      </option>
                    )
                  })
                : null}
            </Input>
          </div>
        </div>
      </Form>
    )
  }
}

export default RecordDetails
