import React, { useState } from "react"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import { deleteTariff, listTariffs, newTariff, tariffSchema, updateTariff } from "@dashboard/data/tariffs/TariffService"
import { Loading } from "@common/EcosuiteComponent"
import { Col, Row } from "reactstrap"
import { TariffSelector } from "@dashboard/data/tariffs/TariffSelector"
import { TariffViewer } from "@dashboard/data/tariffs/TariffViewer"
import { TariffToolbar } from "@dashboard/data/tariffs/TariffToolbar"
import { TariffCreator } from "@dashboard/data/tariffs/TariffCreator"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TariffViewProps {}

/**
 * The tariff view.
 * @param props - The props.
 * @constructor
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TariffView = (props: TariffViewProps) => {
  const [tariffSchemaState, setTariffSchemaState] = useState<Schema>()
  const [tariffsState, setTariffsState] = useState<Tariff[]>()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [createValue, setCreateValue] = useState<Schema>({} as Schema)
  const [editValue, setEditValue] = useState<Schema>({} as Schema)
  const [selectedTariff, setSelectedTariff] = useState<Tariff>()

  useCurrentEffect((isCurrent) => {
    init(isCurrent)
  }, [])

  async function init(isCurrent: () => boolean) {
    setIsLoading(true)

    await Promise.all([tariffSchema(), listTariffs()]).then(([tariffSchema, tariffs]) => {
      if (isCurrent()) {
        setTariffSchemaState(tariffSchema)
        setTariffsState(tariffs)

        open(tariffs[0])

        setIsLoading(false)
      }
    })
  }

  /**
   * Update a tariff.
   * @param tariffValue - The tariff value.
   */
  async function updateTariffAction(tariffValue: Schema) {
    await updateTariff(tariffValue.formData)
    await init(() => true)
  }

  /**
   * Delete a tariff.
   * @param tariff - The tariff.
   */
  async function deleteTariffAction(tariff: Tariff) {
    await deleteTariff(tariff.id)
    await init(() => true)
  }

  /**
   * Create a new tariff.
   * @param newTariffValue - The new tariff value.
   */
  async function newTariffAction(newTariffValue: Schema) {
    await newTariff(newTariffValue.formData)
    await init(() => true)
  }

  /**
   * Toggle a new tariff creation.
   */
  function toggleNewTariff() {
    setSelectedTariff(undefined)
    setEditValue(undefined)
  }

  /**
   * Open a tariff.
   * @param tariff - The tariff.
   */
  function open(tariff: Tariff) {
    setSelectedTariff(tariff)
    setEditValue(tariff)
  }

  /**
   * Close all open elements.
   */
  function closeAll() {
    setSelectedTariff(undefined)
    setEditValue(undefined)
  }

  if (isLoading) return <Loading />

  return (
    <div className={"content content-full"}>
      <Row>
        {
          // If there are tariffs, render a selection box.
          tariffsState && tariffsState.length > 0 && (
            <Col md={"3"} style={{ overflowY: "auto", maxHeight: "calc(100vh - 68px)" }}>
              <TariffSelector
                tariffs={tariffsState}
                selectedTariff={selectedTariff}
                onChange={(tariff) => {
                  setEditValue(undefined)

                  if (selectedTariff && selectedTariff.id === tariff.id) {
                    closeAll()
                  } else {
                    open(tariff)
                  }
                }}
              />
            </Col>
          )
        }
        <Col>
          <Row style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}>
            {selectedTariff ? (
              <TariffViewer selectedTariff={selectedTariff} schema={tariffSchemaState} value={editValue} onSubmit={updateTariffAction} />
            ) : (
              <TariffCreator schema={tariffSchemaState} value={createValue} onChange={(newTariff) => setCreateValue(newTariff)} onSubmit={(newTariff) => newTariffAction(newTariff)} />
            )}
          </Row>
          <Row>
            <TariffToolbar selectedTariff={selectedTariff} onDelete={deleteTariffAction} onNew={toggleNewTariff} />
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default TariffView
