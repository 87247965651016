import moment from "moment-timezone"
import { create, all } from "mathjs"

const math = create(all)

const DIVIDER = "."

var PowerDashboardGraphTools = {
  getPowerDashabordDatums(datums, types, phase, showMaxMin) {
    // We create an array of objects to graph, one for each date and all the source and datum combination
    let data = {}
    let sourceIds = new Set()
    let datumIds = new Set()

    datums.forEach((datum) => {
      const dateKey = `${datum.localDate}T${datum.localTime}`
      sourceIds.add(datum.sourceId)

      if (!data[dateKey]) {
        data[dateKey] = {
          time: moment(dateKey).valueOf(),
        } // create an entry for the date if there isn't one
      }

      // For each of the requested untis add entries for this datums source
      types.forEach((type) => {
        if (phase) {
          type = `${type}_${phase}`
        }
        this.addDatumValue(data, datumIds, dateKey, datum, type)

        if (showMaxMin) {
          this.addDatumValue(data, datumIds, dateKey, datum, `${type}_min`)
          this.addDatumValue(data, datumIds, dateKey, datum, `${type}_max`)
        }
      })
    })

    return {
      sourceIds: Array.from(sourceIds),
      datumIds: Array.from(datumIds),
      data: Object.values(data),
    }
  },

  addDatumValue(data, datumIds, dateKey, datum, type) {
    let datumId = `${datum.sourceId}${DIVIDER}${type}`
    let value = this.getDatumValue(type, datum)
    if (value || value === 0) {
      datumIds.add(datumId)
      data[dateKey][datumId] = value
    }
  },

  getDatumValue(type, datum) {
    let value = datum[type]
    if (value && type.startsWith("wattHours")) {
      value = math.divide(value, 1000)
    }
    return value
  },

  getDatumIdSourceId(datumId) {
    return datumId.split(DIVIDER)[0]
  },

  getDatumIdType(datumId) {
    return datumId.split(DIVIDER)[1]
  },
}

export default PowerDashboardGraphTools
