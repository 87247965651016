import React, { useEffect, useState } from "react"

interface TimedMessageProps {
  children: JSX.Element
  delay: number
}
const TimedMessage = ({ children, delay }: TimedMessageProps) => {
  const [show, setShow] = useState(true)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false)
    }, delay)

    return () => {
      clearTimeout(timeId)
    }
  }, [])

  if (!show) {
    return null
  }

  return <div>{children}</div>
}

export default TimedMessage
