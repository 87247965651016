import _, { uniqBy } from "lodash"
import moment from "moment-timezone"

var Utils = {
  isEmpty(value) {
    return value === null || value === undefined
  },

  getDefaultTimeZone() {
    return "America/New_York"
  },

  moment(date, format, timezone) {
    return moment.tz(date, format, timezone)
  },

  /**
   * The number to format. We apply a variable maximumFractionDigits based on the size of the number to keep the number readable in any given context.
   * @param {*} value The number to format
   * @param {*} options The Intl.NumberFormat() options to apply; overrides the defaults that are otherwise applied
   * @returns The formatted number
   */
  formatNumber(value, options) {
    if (isNaN(value) || this.isEmpty(value)) {
      return value
    }

    if (!options) {
      let maximumFractionDigits = 0
      if (Math.abs(value) < 1) {
        maximumFractionDigits = 3
      } else if (Math.abs(value) < 10) {
        maximumFractionDigits = 2
      } else if (Math.abs(value) < 100) {
        maximumFractionDigits = 1
      }
      options = { maximumFractionDigits: maximumFractionDigits }
    }
    return value.toLocaleString("en", options)
  },

  formatPercent(value) {
    if (!this.isEmpty(value)) {
      return this.formatNumber(value) + "%"
    } else {
      return value
    }
  },

  /**
   * Format a currency.
   * @param amount - The amount to format.
   * @param currency - The expected currency.
   * @param maxDigits - The maximum digits to display.
   * @param minDigits - The minimum digits to display.
   * @returns {string}
   */
  formatCurrency(amount, currency, maxDigits, minDigits) {
    if (amount || amount === 0) {
      const calculatedMax = Math.abs(amount) < 0.01 ? 4 : Math.abs(amount) < 0.1 ? 3 : 2

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: currency ? currency : "USD",
        minimumFractionDigits: minDigits || minDigits === 0 ? minDigits : 2,
        maximumFractionDigits: maxDigits ? maxDigits : calculatedMax,
      })
    }
  },

  /**
   * We typically use CSS for this but in some cases JS makes more sense
   * @param {string} string The string to capitalize
   */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  /**
   * Splits camel case strings into individual words
   * @param {string} string
   */
  splitCamelCase(string) {
    return string.replace(/([a-z])([A-Z])/g, "$1 $2")
  },

  sanitiseId(id) {
    return id.replace(/^[^a-z]+|[^\w.-]+/gi, "") // -> /^[^a-z]+|[^\w:.-]+/gi
  },

  removeDuplicates(array, property) {
    return uniqBy(array, property)
  },

  limitInputtextWords(inputText, limit) {
    const words = inputText.split(" ")
    if (words.length > limit) {
      return _.join(_.slice(words, 0, limit), " ")
    }
    return inputText
  },
}

export default Utils
