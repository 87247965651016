import React, { useState, useEffect, useCallback } from "react"
import { Button } from "src/components/ui/button"
import { Share1Icon } from "@radix-ui/react-icons"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "src/components/ui/dialog"
import { Creatable } from "src/components/ui/rcselect"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/form"
import { Checkbox } from "src/components/ui/checkbox"
import EmptyView from "src/components/ui/empty-view"
import LoadingButton from "src/components/ui/loading-button"
import { Auth } from "aws-amplify"
import { toast } from "react-toastify"
import { useUserOrganization } from "src/services/users"
import { useShareChecklistToPublic } from "src/services/checklist"
import { ChecklistSchemaContainer } from "@dashboard/process/views/checklists/ChecklistView"

export interface ChecklistDataContainer {
  ntpData?: unknown
  ptoData?: unknown
  icrData?: unknown
}

const formSchema = z.object({
  emails: z
    .array(z.object({ label: z.string().email(), value: z.string().email() }))
    .min(1, "At least one email is required"),
  ntpChecked: z.boolean(),
  ptoChecked: z.boolean(),
  icrChecked: z.boolean(),
})

type FormValues = z.infer<typeof formSchema>

const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

const useAuth = () => {
  const [sub, setSub] = useState<string | undefined>(undefined)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        setSub(user.attributes.sub)
      } catch (error) {
        console.error("Error fetching authenticated user:", error)
      }
    }
    fetchUser()
  }, [])

  return { sub }
}

interface ShareChecklistProps {
  metaData?: Partial<ChecklistDataContainer>
  checklistTabs: Array<"NTP" | "PTO" | "ICR">
  schema: ChecklistSchemaContainer
}

type SchemaProperty = {
  title?: string
  properties?: Record<string, SchemaProperty>
  "ui:order"?: string[]
}

type Schema = {
  properties: Record<string, SchemaProperty>
  "ui:order"?: string[]
}

type Data = Record<string, any>

const mapSchemaToData = (schema: Schema, data: Data): Data => {
  const mapProperty = (schemaProperty: SchemaProperty, propertyData: any): Data => {
    if (schemaProperty.properties) {
      const mappedData = Object.entries(schemaProperty.properties).reduce((acc, [childKey, childSchema]) => {
        if (propertyData[childKey]) {
          const newChildKey = childSchema.title || childKey
          return {
            ...acc,
            [newChildKey]: propertyData[childKey],
          }
        }
        return acc
      }, {})

      if (schemaProperty["ui:order"]) {
        return {
          ...mappedData,
          "ui:order": schemaProperty["ui:order"].map((property) => {
            if (schemaProperty.properties) {
              const relatedProperty = Object.keys(schemaProperty.properties).find((k) => k === property)
              if (relatedProperty) {
                return schemaProperty.properties[relatedProperty].title
              }
              return property
            }
            return property
          }),
        }
      }

      return mappedData
    }
    return propertyData
  }

  const mappedData = Object.entries(schema.properties).reduce((result, [key, schemaProperty]) => {
    if (data[key]) {
      const newKey = schemaProperty.title || key
      return {
        ...result,
        [newKey]: mapProperty(schemaProperty, data[key]),
      }
    }
    return result
  }, {})

  if (schema["ui:order"]) {
    return {
      ...mappedData,
      "ui:order": schema["ui:order"],
    }
  }

  return mappedData
}

export default function ShareChecklist({ metaData, schema, checklistTabs }: ShareChecklistProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { sub } = useAuth()
  const { data: org } = useUserOrganization(sub)
  const { mutate, isLoading } = useShareChecklistToPublic()

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emails: [],
      ntpChecked: false,
      ptoChecked: false,
      icrChecked: false,
    },
  })

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!org) {
        toast.error("Failed to fetch organization")
        return
      }

      const emailAddresses = values.emails.map((e) => e.value)
      const organization = {
        name: org.organization.name,
        logo: org.organization.settings?.logoUrl,
      }

      const dataToSend: Partial<ChecklistDataContainer> = {}
      if (values.ntpChecked && metaData?.ntpData && schema.ntpSchema) {
        dataToSend.ntpData = mapSchemaToData(schema.ntpSchema as Schema, metaData.ntpData as Data)
      }

      if (values.ptoChecked && metaData?.ptoData && schema.ptoSchema) {
        dataToSend.ptoData = mapSchemaToData(schema.ptoSchema as Schema, metaData.ptoData as Data)
      }

      if (values.icrChecked && metaData?.icrData && schema.icrSchema) {
        dataToSend.icrData = mapSchemaToData(schema.icrSchema as Schema, metaData.icrData as Data)
      }

      mutate(
        { metaData: dataToSend, emailAddresses, organization },
        {
          onSuccess: () => {
            toast.success("Documents shared successfully")
            setIsOpen(false)
            form.reset()
          },
          onError: (error) => {
            toast.error("Failed to share documents: " + error.message)
          },
        },
      )
    },
    [org, metaData, mutate, form, setIsOpen],
  )

  return (
    <div className="tw-ml-4">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button onClick={() => setIsOpen(true)}>
            <Share1Icon className="tw-mr-2 tw-h-4 tw-w-4" /> Share Checklist
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:tw-max-w-[80%]">
          <DialogHeader className="tw-mb-4 tw-p-2">
            <DialogTitle>Share checklist to public</DialogTitle>
            <DialogDescription>Share currently modified checklist to the public via email</DialogDescription>
          </DialogHeader>
          <div className="tw-p-2">
            {metaData ? (
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-8">
                  <div className="tw-flex tw-space-x-4">
                    {checklistTabs.includes("NTP") && (
                      <FormField
                        control={form.control}
                        name="ntpChecked"
                        render={({ field }) => (
                          <FormItem className="tw-flex tw-items-center tw-space-x-2">
                            <FormControl>
                              <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <FormLabel>NTP</FormLabel>
                          </FormItem>
                        )}
                      />
                    )}
                    {checklistTabs.includes("PTO") && (
                      <FormField
                        control={form.control}
                        name="ptoChecked"
                        render={({ field }) => (
                          <FormItem className="tw-flex tw-items-center tw-space-x-2">
                            <FormControl>
                              <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <FormLabel>PTO</FormLabel>
                          </FormItem>
                        )}
                      />
                    )}
                    {checklistTabs.includes("ICR") && (
                      <FormField
                        control={form.control}
                        name="icrChecked"
                        render={({ field }) => (
                          <FormItem className="tw-flex tw-items-center tw-space-x-2">
                            <FormControl>
                              <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <FormLabel>ICR</FormLabel>
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                  <FormField
                    control={form.control}
                    name="emails"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email Addresses</FormLabel>
                        <FormControl>
                          <Controller
                            name="emails"
                            control={form.control}
                            render={({ field }) => (
                              <Creatable
                                {...field}
                                isMulti={true}
                                noOptionsMessage={() => <div>Please enter a valid email</div>}
                                className="tw-mt-4"
                                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                isValidNewOption={(inputValue) => isValidEmail(inputValue)}
                              />
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <DialogFooter>
                    <LoadingButton type="submit" loading={isLoading} onClick={form.handleSubmit(onSubmit)}>
                      Share
                    </LoadingButton>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setIsOpen(false)
                        form.reset()
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            ) : (
              <EmptyView text="No checklist data for sharing" />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
