import React from "react"
import moment from "moment"

import type { AuditChange } from "../AuditView.d"
import Utils from "@common/utils/Utils"
import i18n from "src/i18n"

const AuditTable = ({ auditChanges }: { auditChanges: AuditChange[] }): JSX.Element => {
  const { t } = i18n
  return (
    <table className="audit-log">
      <thead>
        <tr>
          <th>{t("table_headings.type")}</th>
          <th>{t("table_headings.id")}</th>
          <th>{t("table_headings.name")}</th>
          <th>{t("table_headings.user")}</th>
          <th>{t("table_headings.timestamp")}</th>
          <th className="audit-changes">{t("table_headings.changes")}</th>
        </tr>
      </thead>
      <tbody>
        {/*   Table row for each Audit Change  */}
        {auditChanges.map((change, idx) => {
          if (change.diff && change.diff.length) {
            return (
              <tr key={idx}>
                <td style={{ textTransform: "capitalize" }}>{change.assetType}</td>
                <td>{change.id}</td>
                <td>{change.name}</td>
                <td title={change.userId}>{change.userName}</td>
                <td>{moment(change.timestamp).format("lll")}</td>
                <td>
                  <div>
                    {change.note ? <p>{change.note}</p> : null}

                    {/*   Left and Right Diff Table   */}
                    <table className="audit-diff">
                      <thead>
                        <tr>
                          <th>{t("table_headings.property")}</th>
                          <th>{t("table_headings.original_value")}</th>
                          <th>{t("table_headings.new_value")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {change.diff.map((diff, idx) => {
                          const lhs = diff.item ? diff.item.lhs : diff.lhs
                          const rhs = diff.item ? diff.item.rhs : diff.rhs
                          return (
                            <tr key={idx}>
                              <td className="property">{diff.path.join(".") + (diff.index && diff.index >= 0 ? `[${diff.index}]` : "")}</td>
                              <td className="original">{typeof lhs === "number" ? Utils.formatNumber(lhs, { maximumFractionDigits: 7 }) : JSON.stringify(lhs)}</td>
                              <td className="new">{typeof rhs === "number" ? Utils.formatNumber(rhs, { maximumFractionDigits: 7 }) : JSON.stringify(rhs)}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            )
          } else {
            return null
          }
        })}
      </tbody>
    </table>
  )
}

export default AuditTable
