import { getPluginType, insertNodes, PlateEditor, TElement } from "@udecode/plate-core"
import { ELEMENT_IMAGE } from "./createS3ImagePlugin"

/**
 * Insert a new S3 image into the editor.
 * @param editor - The PlateEditor to insert into.
 * @param url - The file identifier.
 * @param bucket - The bucket to retrieve from.
 */
export const s3InsertImage = (editor: PlateEditor, url: string, bucket: string | undefined): void => {
  const text = { text: "" }
  const image = {
    type: getPluginType(editor, ELEMENT_IMAGE),
    url,
    bucket,
    children: [text],
  }
  insertNodes<TElement>(editor, image)
}
