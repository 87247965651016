import React from "react"
import { Button, ButtonGroup, ButtonToolbar, Input } from "reactstrap"
import moment from "moment"

import { Loading, Message } from "@common/EcosuiteComponent"
import SelectAll, { evaluateSelectAllStatus } from "@common/input/button/SelectAll"
import EcosuiteView from "@common/module/EcosuiteView"
import Confirmation from "@common/input/button/Confirmation"
import DREventDetailsView from "./DREventDetailsView"
import DRService from "../DRService"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
function inProgress(event) {
  const now = moment(new Date())
  return moment(event.startDate) < now && moment(event.endDate) > now
}

function inPast(event) {
  const now = moment(new Date())
  return moment(event.endDate) < now
}

export default class DREventView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.state = {
      showTooltip: true,
      eventTableSelected: {},
      showEventEditor: false,
      eventSelected: null,
    }

    this.clearSelectedEvents = this.clearSelectedEvents.bind(this)
  }

  isReadOnly() {
    return false
  }

  toggleEvent(event) {
    if (this.isEventSelected(event)) {
      this.props.events.selectEvent() // deselect the event
    } else {
      this.props.events.selectEvent(event)
    }
  }

  setTooltipEvent(event) {
    this.setStateIfMounted({
      tooltipEvent: event,
    })
  }

  isEventSelected(event) {
    return this.props.event && this.props.event.id === event.id
  }

  /**
   * Clear the selected events.
   */
  clearSelectedEvents() {
    this.setStateIfMounted({
      eventTableSelected: {},
    })
  }

  renderViewControls() {
    return (
      <div className={"content-view-controls"}>
        <ButtonToolbar className="secondary-header-toolbar float-end">
          <ButtonGroup className="header-button-group">
            <Confirmation
              idSuffix={"delete-events"}
              text={`${t("labels.delete_selected")}`}
              color={"danger"}
              header={`${t("demand.messages.delete_DR_event")}`}
              onConfirm={() => {
                const toDelete = Object.values(this.state.eventTableSelected).map((p) => p.id)
                const actions = toDelete.map((id) => DRService.deleteEvent(id))

                Promise.all(actions).then(() => this.props.fetchEvents())
              }}
              body={`${t("event.messages.confirm_delete_selected_event")}`}
              toolTip={`${t("event.labels.delete_selected_events")}`}
              irreversible={true}
            />
            <Button
              className={this.props.event && !this.props.event.id ? "selected" : ""}
              onClick={() => {
                this.setState({
                  showEventEditor: true,
                  eventSelected: {},
                })
              }}
              size="sm"
              color="ecogy"
            >
              {t("demand.labels.new_DR_event")}
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }

  renderMainView() {
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.showEventEditor) {
      return (
        <DREventDetailsView
          fetchEvents={this.props.fetchEvents}
          event={this.state.eventSelected}
          allProjects={this.props.allProjects}
          onClose={() => {
            this.setState({
              showEventEditor: false,
              eventSelected: null,
            })
            this.loadEvents()
          }}
          onCancel={() => {
            this.setState({ showEventEditor: false })
          }}
        />
      )
    }

    const events = this.props.events

    if (events) {
      if (events.length > 0) {
        const tooltipEvent = this.state.tooltipEvent
        return (
          <div className="events">
            <table className="events-table">
              <thead>
                <tr>
                  <th>
                    <SelectAll
                      status={evaluateSelectAllStatus(Object.keys(events).length, Object.keys(this.state.eventTableSelected).length)}
                      allToggle={() => {
                        const currentEventTableSelected = structuredClone(this.state.eventTableSelected)

                        events.forEach((event) => {
                          currentEventTableSelected[`${event.id}`] = event
                        })

                        this.setStateIfMounted({
                          eventTableSelected: currentEventTableSelected,
                        })
                      }}
                      noneToggle={() => this.clearSelectedEvents()}
                    />
                  </th>
                  <th className="edit"></th>
                  <th>{t("table_headings.priority")}</th>
                  <th>{t("table_headings.name")}</th>
                  <th>{t("table_headings.notification_date")}</th>
                  <th>{t("table_headings.event_date")}</th>
                  <th>{t("table_headings.duration")}</th>
                  <th>{t("table_headings.participants")}</th>
                  <th>{t("table_headings.status")}</th>
                  {this.isReadOnly() ? null : <th></th>}
                </tr>
              </thead>
              <tbody>
                {events.map((event) => {
                  let selectedClass = tooltipEvent && tooltipEvent.id === event.id ? "selected" : null
                  return (
                    <tr key={event.id} className={selectedClass ? selectedClass : null} onMouseOver={() => this.setTooltipEvent(event)} onMouseOut={() => this.setTooltipEvent()}>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                        <Input
                          type={"checkbox"}
                          checked={event.id in this.state.eventTableSelected}
                          onChange={() => {
                            const currentEventTableSelected = structuredClone(this.state.eventTableSelected)

                            if (event.id in this.state.eventTableSelected) {
                              delete currentEventTableSelected[`${event.id}`]
                            } else {
                              currentEventTableSelected[`${event.id}`] = event
                            }

                            this.setStateIfMounted({
                              eventTableSelected: currentEventTableSelected,
                            })
                          }}
                        />
                      </td>
                      <td>
                        {this.isReadOnly() ? (
                          <Button
                            color="link"
                            size="sm"
                            title={`${t("demand.labels.view_DR_event")}`}
                            onClick={() => {
                              this.toggleEvent(event)
                            }}
                          >
                            <Icon icon="link" />
                          </Button>
                        ) : (
                          <Button
                            color="link"
                            size="sm"
                            title={`${t("demand.labels.edit_DR_event")}`}
                            onClick={() => {
                              this.setState({
                                showEventEditor: true,
                                eventSelected: event,
                              })
                            }}
                          >
                            <Icon icon="edit" />
                          </Button>
                        )}
                      </td>

                      <td className={"priority-" + event.priority}>{event.priority}</td>
                      <td>{event.name}</td>

                      <td>{moment(event.notificationDate).format("llll")}</td>
                      <td>{moment(event.startDate).format("llll")}</td>
                      <td>{moment(event.endDate).from(event.startDate, true)}</td>
                      <td>{event.participants.length}</td>

                      {inProgress(event) ? (
                        <td className={"status-in-progress"}>{t("labels.in_progress")}</td>
                      ) : inPast(event) ? (
                        <td className={"status-completed"}>{t("labels.completed")}</td>
                      ) : (
                        <td className={"status-planned"}>{t("labels.planned")}</td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {/*this.getTooltip(tooltipEvent)*/}
          </div>
        )
      } else {
        return <Message message={`${t("demand.labels.no_DR_events")}`} />
      }
    } else {
      return <Loading />
    }
  }
}
