import React from "react"
import { create, all } from "mathjs"

import EcosuiteComponent from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import Utils from "@common/utils/Utils"
import i18n from "src/i18n"
import ProFormaUtils from "./ProFormaUtils"
import { monthSorter } from "@common/DateSort"
import _ from "lodash"

const math = create(all)
const { t } = i18n

export default class ProFormaInputsDetails extends EcosuiteComponent {
  renderContent() {
    const { project, proForma } = this.props
    if (ProFormaUtils.isVersion(proForma)) {
      if (proForma.versionProject) {
        if (proForma.versionProject?.dcSize) {
          return (
            <>
              <tr color="info">
                <td>{t("economics.labels.Project/System Forecast")}:</td>
                {"TODO"}
              </tr>
              {this.renderProject(proForma.versionProject)}
            </>
          )
        } else {
          return (
            <>
              <tr color="warning">
                <td>{t("economics.labels.Pro Forma Forecast")}:</td>
              </tr>
              <tr>
                <td>{this.renderProForma()}</td>
              </tr>
            </>
          )
        }
      } else {
        //
      }
    } else {
      if (project?.dcSize) {
        return (
          <>
            <tr color="primary">
              <td>{t("economics.labels.Project/System Forecast")}:</td>
            </tr>
            {this.renderProject(project)}
          </>
        )
      } else {
        return (
          <>
            <tr color="warning">
              <td>{t("economics.labels.Pro Forma Forecast")}:</td>
            </tr>
            <tr>
              <td>{this.renderProForma()}</td>
            </tr>
          </>
        )
      }
    }
  }

  renderProject(project) {
    return Object.values(project.sites).map((site) => {
      return this.renderSite(site)
    })
  }

  renderSite(site) {
    return Object.values(site.systems)
      ?.filter((system) => system.type === "generation")
      .map((system) => {
        return (
          <React.Fragment key={site.code}>
            <tr className="sub-account">
              <td colSpan={3}>
                {site.name}: {system.name}
              </td>
            </tr>

            <tr className="sub-account-title">
              <td>{t("economics.labels.System Type")}</td>
              <td className="system-type">{system.subType}</td>
              <td></td>
            </tr>

            <React.Fragment key={system.code}>{this.renderSystem(system)}</React.Fragment>
          </React.Fragment>
        )
      })
  }

  renderSystem(system) {
    if (system?.forecast?.acEnergy?.enabled) {
      return this.renderACEnergyForecast(system)
    } else if (system?.forecast?.irradiance?.enabled) {
      return this.renderIrradianceForecast(system)
    } else {
      return this.renderSystemForecast(system)
    }
  }

  renderDegradation(system) {
    if (system?.solarArrays?.length) {
      // IrradianceUtils currently calculates this as a %
      const annualPanelDegradation = math.divide(
        system.solarArrays.reduce((degradationRate, solarArray) => {
          return (degradationRate += solarArray.annualPanelDegradation)
        }, 0),
        system.solarArrays.length,
      )

      return (
        <tr className="sub-account-title">
          <td>{t("economics.labels.Annual Degradation")}</td>
          <td>{annualPanelDegradation} %</td>
        </tr>
      )
    } else {
      return (
        <tr className="sub-account-title">
          <td>{t("economics.labels.Annual Degradation")}</td>
          <td>{system.degradation ? system.degradation / 100 : 0.0063} %</td>
        </tr>
      )
    }
  }

  /**
   * Render an annual total.
   * @param system - The system to render the total for.
   * @param total - The total to render.
   * @returns {JSX.Element}
   */
  renderAnnualTotal(system, total) {
    return (
      <tr className={"sub-account-title"}>
        <td>{t("economics.labels.annual_system_total")}</td>
        <td>{total.toLocaleString()}</td>
      </tr>
    )
  }

  renderACEnergyForecast(system) {
    let annualTotal = 0

    return (
      <>
        <tr className="sub-account-title">
          <td>{t("economics.labels.Forecast Type")}</td>
          <td colSpan={2}>{t("economics.messages.inputs_forecast_ac")}</td>
        </tr>
        {Object.keys(system.forecast.acEnergy.monthlyACEnergy)
          .sort((a, b) => monthSorter(a, b))
          .map((month) => {
            const monthTotal = system.forecast.acEnergy.monthlyACEnergy[month]
            annualTotal += monthTotal

            return (
              <tr className="sub-account-detail" key={month}>
                <td>{_.upperFirst(month)}</td>
                <td>{monthTotal.toLocaleString()}</td>
              </tr>
            )
          })}
        {this.renderAnnualTotal(system, annualTotal)}
        {this.renderDegradation(system)}
      </>
    )
  }

  renderIrradianceForecast(system) {
    return (
      <>
        <tr className="sub-account-title">
          <td>{t("economics.labels.Forecast Type")}</td>
          <td colSpan={2}>{t("economics.messages.inputs_forecast_irradiance")}</td>
        </tr>
        {Object.keys(system.forecast.irradiance.monthlyIrradiance)
          .sort((a, b) => monthSorter(a, b))
          .map((month) => {
            return (
              <tr className="sub-account-detail" key={month}>
                <td>{_.upperFirst(month)}</td>
                <td>{system.forecast.irradiance.monthlyIrradiance[month].toLocaleString()}</td>
              </tr>
            )
          })}
        {system.solarArrays.map((solarArray, idx) => {
          return (
            <React.Fragment key={idx}>
              <tr className="sub-account-title">
                <td>{`${t("economics.labels.Solar Array")} ${idx + 1}`}</td>
              </tr>
              <tr className="sub-account-detail">
                <td>{t("economics.labels.panel_area")}</td>
                <td>{solarArray.panelArea}</td>
              </tr>
              <tr className="sub-account-detail">
                <td>{t("economics.labels.panel_efficiency")}</td>
                <td>{solarArray.panelEfficiency}</td>
              </tr>
              <tr className="sub-account-detail">
                <td>{t("economics.labels.performance_ratio")}</td>
                <td>{solarArray.performanceRatio}</td>
              </tr>
              <tr className="sub-account-detail">
                <td>{t("economics.labels.array_tilt_factor")}</td>
                <td>{solarArray.arrayTiltFactor}</td>
              </tr>
              <tr className="sub-account-detail">
                <td>{t("economics.labels.Annual Degradation")}</td>
                <td>{solarArray.annualPanelDegradation} %</td>
              </tr>
            </React.Fragment>
          )
        })}
        {this.renderDegradation(system)}
      </>
    )
  }

  renderSystemForecast(system) {
    return (
      <>
        <tr className="sub-account-title">
          <td>{t("economics.labels.Forecast Type")}</td>
          <td colSpan={2}>{t("economics.messages.inputs_forecast_size")}</td>
        </tr>
        <tr className="sub-account-title">
          <td>{t("economics.labels.PV System Size")}</td>
          <td>{Utils.formatNumber(system.dcSize, { maximumFractionDigits: 2 })} kW DC</td>
          <td></td>
        </tr>
        <tr className="sub-account-title">
          <td>{t("economics.labels.Annual Production")}</td>
          <td>{Utils.formatNumber(system.productionPerPeak ? system.productionPerPeak : 1270, { maximumFractionDigits: 2 })} kWh/kWp</td>
          <td>{EnergyUtils.formatNumber(math.multiply(system.dcSize, system.productionPerPeak ? system.productionPerPeak : 1270))} kWh</td>
        </tr>
        {this.renderDegradation(system)}
      </>
    )
  }

  renderProForma() {
    const { proForma, systemTypes } = this.props

    return (
      <>
        <tr className="sub-account-title">
          <td>{t("economics.labels.Forecast Type")}</td>
          <td colSpan={2}>{t("economics.messages.inputs_forecast_size")}</td>
        </tr>
        <tr className="sub-account">
          <td>{t("economics.labels.System Type")}</td>
          <td className="system-type">{systemTypes.join(systemTypes.length > 1 ? ", " : "")}</td>
          <td></td>
        </tr>
        <tr className="sub-account">
          <td>{t("economics.labels.PV System Size")}</td>
          <td>{Utils.formatNumber(proForma.systemSize, { maximumFractionDigits: 2 })} kW DC</td>
          <td></td>
        </tr>
        <tr className="sub-account">
          <td>{t("economics.labels.Annual Production")}</td>
          <td>{Utils.formatNumber(proForma.systemProduction, { maximumFractionDigits: 2 })} kWh/kWp</td>
          <td>{EnergyUtils.formatNumber(math.multiply(proForma.systemSize, proForma.systemProduction))} kWh</td>
        </tr>
        <tr className="sub-account">
          <td>{t("economics.labels.Annual Degradation")}</td>
          <td>{Utils.formatPercent(proForma.degradation)}</td>
          <td></td>
        </tr>
      </>
    )
  }
}
