import React from "react"
import { Button, Container } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { FormError } from "@common/form/EcosuiteForm"
import Logger from "@common/Logger"

import OrganizationService from "./OrganizationAdminService"
import i18n from "src/i18n"

const { t } = i18n
export default class EditOrganization extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      formData: this.props.organization,
      loading: false,
      error: null,
      newOrganization: null,
      schema: null,
    }
    this.editOrganization = this.editOrganization.bind(this)
    this.deleteOrganization = this.deleteOrganization.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    OrganizationService.getOrganizationSchema().then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.organization && this.props.organization.id != prevProps.organization.id) {
      this.setState({ formData: this.props.organization })
    }
  }

  deleteOrganization() {
    const confirm = window.confirm(`${t("settings.messages.confirm_delete_organization")}: ${this.props.organization.name}?`)
    if (confirm) {
      this.setState({ loading: true })
      OrganizationService.deleteOrganization(this.props.organization.id).then(() => {
        this.props.organizationDeleted()
      })
    }
  }

  editOrganization(form) {
    this.setState({ error: undefined, formData: form.formData, loading: true })
    Logger.info("updating organization: " + JSON.stringify(form.formData))
    OrganizationService.editOrganization(form.formData)
      .then((response) => {
        this.setStateIfMounted((prev) => {
          return { ...prev, loading: false, newOrganization: response }
        })
        if (this.props.organizationChanged) {
          this.props.organizationChanged(form.formData)
        }
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, loading: false })
      })
  }

  renderContent() {
    if (this.state.loading || !this.state.schema) {
      return <Loading />
    }
    Logger.info("formData=" + JSON.stringify(this.state.formData))
    return (
      <Container fluid={true} className="Admin-content">
        <div className="ecogy-form admin-content-area">
          <EcosuiteForm schema={this.state.schema} uiSchema={OrganizationService.getUiSchema()} formData={this.state.formData} onSubmit={this.editOrganization} onChange={this.formDataChanged}>
            {this.renderMessages()}
            <div>
              <Button color="primary" type="submit">
                {t("buttons.submit")}
              </Button>
              <Button color="danger" onClick={this.deleteOrganization}>
                {t("buttons.delete")}
              </Button>
            </div>
          </EcosuiteForm>
        </div>
      </Container>
    )
  }

  renderMessages() {
    return (
      <FormError
        error={this.state.error}
        toggle={() => {
          this.setStateIfMounted({ error: null })
        }}
      />
    )
  }
}
