import Moment from "moment"
import { extendMoment } from "moment-range"

import Utils from "./Utils"

const moment = extendMoment(Moment)

const SEPARATOR = "/"

const PROJECT_IDX = 1
const SITE_IDX = 2
const SYSTEM_IDX = 3
const DEVICE_TYPE_IDX = 4
const DEVICE_ID_IDX = 5

var ProjectUtils = {
  getTimeZoneForProject(project) {
    return project && project.timezone ? project.timezone : Utils.getDefaultTimeZone()
  },

  projectMoment(project, date, format) {
    return Utils.moment(date, format, this.getTimeZoneForProject(project))
  },

  /**
   * Given a list of projects returns the earliest Production Start Date
   * (the production start is stored on the Sites, a Project start date is used if there are site data)
   * @param {Project[]} projects the lists to get the Production start date for
   */
  getProductionStartDate(projects) {
    return projects
      .reduce((earliestStartDate, project) => {
        // If 'production start date' is undefined use project 'start date' instead.
        const productionStartDate = moment(project?.productionStartDate ?? project?.startDate)
        if (earliestStartDate.isAfter(productionStartDate)) {
          return moment(productionStartDate)
        } else {
          return earliestStartDate
        }
      }, moment().startOf("day"))
      .toDate()
  },

  /**
   * Given a list of projects returns the earliest Project Start Date
   * @param {*} projects the lists to get the start date for
   */
  getProjectStartDate(projects) {
    let start = moment().startOf("day")

    projects.forEach((project) => {
      let projectStart = moment(project.startDate)

      if (projectStart.isBefore(start)) {
        start = projectStart
      }
    })
    return start.toDate()
  },

  getProjectCode(path) {
    return this.getPathElement(path, PROJECT_IDX)
  },

  getSiteCode(path) {
    return this.getPathElement(path, SITE_IDX)
  },

  getSystemCode(path) {
    return this.getPathElement(path, SYSTEM_IDX)
  },

  getDeviceType(path) {
    return this.getPathElement(path, DEVICE_TYPE_IDX)
  },

  getDeviceId(path) {
    return this.getPathElement(path, DEVICE_ID_IDX)
  },

  getPathElement(path, idx) {
    let parts = path.split(SEPARATOR)
    if (parts.length > idx) {
      return path.split(SEPARATOR)[idx]
    }
    return null
  },

  getPath(project, site, system, device) {
    let path = SEPARATOR + project.code
    if (site) {
      path += SEPARATOR + site.code
      if (system) {
        path += SEPARATOR + system.code
        if (device) {
          if (typeof device === "string") {
            // Expect in the form /<type>/<id> or <type>/<id>
            path += device[0] === SEPARATOR ? device : SEPARATOR + device
          } else if (typeof device === "object") {
            path += SEPARATOR + device.type + SEPARATOR + device.id
          }
        }
      }
    }
    return path
  },

  getAssetsFromPath(projects, path) {
    let assets = {}

    let projectId = this.getProjectCode(path)
    let siteId = this.getSiteCode(path)
    let systemId = this.getSystemCode(path)

    assets.project = projects.find((project) => {
      return project.code === projectId
    })

    if (assets.project && siteId) {
      assets.site = Object.values(assets.project.sites).find((site) => {
        return site.code === siteId
      })

      if (assets.site && systemId) {
        assets.system = Object.values(assets.site.systems).find((system) => {
          return system.code === systemId
        })
      }
    }

    return assets
  },

  getSystemCodesForProject(project) {
    let systemCodes = []
    Object.values(project.sites).forEach((site) => {
      Object.values(site.systems).forEach((system) => {
        systemCodes.push(this.getPath(project, site, system))
      })
    })
    return systemCodes.sort()
  },

  getSitesForProject(project) {
    let sites = []
    Object.values(project.sites).forEach((site) => {
      sites.push(site)
    })
    return sites
  },

  getSystemsForProject(project) {
    let systems = []
    Object.values(project.sites).forEach((site) => {
      Object.values(site.systems).forEach((system) => {
        systems.push(system)
      })
    })
    return systems
  },

  getSitesForProjects(projects) {
    let sites = []
    projects.forEach((project) => {
      sites = sites.concat(this.getSitesForProject(project))
    })
    return sites
  },

  getSystemsForProjects(projects) {
    let systems = []
    projects.forEach((project) => {
      systems = systems.concat(this.getSystemsForProject(project))
    })
    return systems
  },
}

export default ProjectUtils
