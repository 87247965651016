import React, { useState } from "react"
import ImageCarousel from "@common/display/ImageCarousel"
import { Modal } from "reactstrap"
import { getFile } from "@dashboard/process/views/media/MediaService"
import _ from "lodash"
import { Loading } from "@common/EcosuiteComponent"
import "./MediaCarousel.scss"
import LoadingModal from "@common/display/LoadingModal"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"

/**
 * A wrapper for the image carousel using media.
 * @param mediaKeys - The media keys.
 * @param showModal - Whether to show the modal.
 * @param setShowModal - The callback to set the show modal state.
 * @constructor
 */
export const MediaCarousel = ({ mediaKeys, showModal, setShowModal }: { mediaKeys: string[]; showModal: boolean; setShowModal: (open: boolean) => void }): JSX.Element => {
  // Whether the page is currently loading data.
  const [loadingData, setLoadingData] = useState<boolean>(true)

  // The media.
  const [media, setMedia] = useState<MediaResponse[]>([])

  useCurrentEffect(
    (isCurrent) => {
      setMedia([])
      initializeData(isCurrent)
    },
    [mediaKeys],
  )

  /**
   * Initialize the data.
   * @param isCurrent - Whether the render is current.
   */
  const initializeData = async (isCurrent: () => boolean) => {
    setLoadingData(true)

    const foundMedia = []
    for (const key of mediaKeys) {
      try {
        const found = await getFile(key)
        foundMedia.push(found)
        // eslint-disable-next-line no-empty
      } catch (_) {
        // If any media has an issue, just ignore it.
      }
    }

    if (isCurrent()) {
      setMedia(foundMedia)
      setLoadingData(false)
    }
  }

  return (
    <>
      {loadingData ? (
        <LoadingModal show={loadingData && showModal} exit={() => setShowModal.call(this, !showModal)} />
      ) : (
        <Modal centered={true} size={loadingData ? "sm" : "lg"} isOpen={showModal} toggle={() => setShowModal.call(this, !showModal)}>
          <>
            {loadingData ? (
              <div className={"loading-media-carousel"}>
                <Loading />
              </div>
            ) : (
              <ImageCarousel
                uriData={media.map((media) => media.data)}
                interval={false}
                altText={[]}
                captionHeader={media.map((media) => media.name)}
                captionText={media.map((media) => _.truncate(media.description, { length: 500 }))}
              />
            )}
          </>
        </Modal>
      )}
    </>
  )
}

export default MediaCarousel
