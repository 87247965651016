import React from "react"
import { Alert, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Typeahead } from "react-bootstrap-typeahead"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import RecordService from "../../RecordService"

import sanitize from "sanitize-filename"
import FileDropzone from "@common/input/files/FileDropzone"
import i18n from "src/i18n"

const { t } = i18n

const documentFolderHierarchy = require("@dashboard/data/record/record-document-folders.json")

export class RecordDocumentUpload extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state.folderNames = this.getFolderNames()
    this.state.folderName = ""
    this.state.files = []

    this.toggleModal = this.toggleModal.bind(this)
    this.uploadDocuments = this.uploadDocuments.bind(this)
  }

  toggleModal() {
    this.setStateIfMounted({
      showModal: !this.state.showModal,
      uploadError: null,
      uploadMessage: "",
      files: [],
      folderName: "",
    })
  }

  getFolderNames() {
    if (this.props.record && this.props.record.recordType && this.props.record.recordSubType) {
      // Prepopulate folderNames with default folder names
      const folderNames = new Set(documentFolderHierarchy[this.props.record.recordType][this.props.record.recordSubType])

      const recordTypeDocuments = this.props.typeHierarchy.find((typeObject) => typeObject.id === this.props.record.recordType)
      const recordSubTypeDocuments = recordTypeDocuments.subTypes.find((subTypeObject) => subTypeObject.id === this.props.record.recordSubType)

      if (recordSubTypeDocuments) {
        if (recordSubTypeDocuments.folders && recordSubTypeDocuments.folders.length > 0) {
          recordSubTypeDocuments.folders.forEach((folder) => folderNames.add(folder))
        }
      }

      // Remove the Archive folder as an option
      folderNames.delete("Archive")

      return Array.from(folderNames)
    }
  }

  async uploadDocuments() {
    if (!this.state.folderName) {
      this.setStateIfMounted({ uploadError: t("data.errorMsg.select_folder"), uploading: false })
    } else if (!this.state.files.length) {
      this.setStateIfMounted({ uploadError: t("data.errorMsg.upload_document"), uploading: false })
    } else if (this.state.files.some((file) => file.name !== sanitize(file.name))) {
      this.setStateIfMounted({ uploadError: t("data.errorMsg.file_name"), uploading: false })
    } else {
      this.setStateIfMounted({ uploading: true, uploadError: null, uploadMessage: null })
      await Promise.all(
        this.state.files.map((file) => {
          return RecordService.storeFile(this.props.record.id, this.state.folderName, file)
        }),
      )
        .then(() => {
          this.setStateIfMounted({ uploadMessage: t("messages.document_uploaded"), uploading: false, folderNames: this.getFolderNames() })
          this.props.actions.loadDocuments(this.props.record) // Reload the documents to pick up the newly uploaded ones
          this.toggleModal()
        })
        .catch((err) => {
          this.setStateIfMounted({ uploadError: `${t("data.errorMsg.upload_error")}: ${err.message}`, uploading: false })
        })
    }
  }

  render() {
    if (!this.props.recordDocuments) {
      return <Loading />
    }

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal} className="download" size="sm">
          {t("buttons.upload_document")}
        </Button>
        <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>{t("buttons.upload_document")}</ModalHeader>
          {this.state.uploading ? (
            <Loading message={t("loadingMsg.uploading_documents")} />
          ) : (
            <>
              <ModalBody>
                <Label>{t("labels.folder")}:</Label>
                <Typeahead
                  id="folderAutoComplete"
                  onChange={(selected) => {
                    this.setStateIfMounted({ folderName: selected && selected.length ? (typeof selected[0] === "object" ? selected[0].label : selected[0]) : null })
                  }}
                  options={this.state.folderNames ?? []}
                  selected={this.state.folderName ? [this.state.folderName] : []}
                  allowNew={true}
                  disabled={this.props.readonly}
                />

                <Label>{t("labels.documents")}:</Label>
                <FileDropzone onChange={(files) => this.setStateIfMounted({ files: files })} />
                {this.state.uploading ? <Loading message={t("loadingMsg.uploading_documents")} /> : null}
                {this.state.uploadMessage ? <Alert color="info">{this.state.uploadMessage}</Alert> : null}
                {this.state.uploadError ? <Alert color="danger">{this.state.uploadError}</Alert> : null}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.uploadDocuments}>
                  {t("buttons.upload")}
                </Button>{" "}
                <Button color="secondary" onClick={this.toggleModal}>
                  {t("buttons.cancel")}
                </Button>
              </ModalFooter>
            </>
          )}
        </Modal>
      </React.Fragment>
    )
  }
}
