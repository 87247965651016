import React from "react"
import { Badge, Button, Input, Form } from "reactstrap"
import EcosuiteComponent from "@common/EcosuiteComponent"
import Utils from "@common/utils/Utils"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export class Note extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      comment: "",
    }
  }

  renderContent() {
    const userName = this.props.note.userName ? this.props.note.userName : `${t("labels.unknown")}`
    return (
      <div className="note-content">
        <div className="note-header">
          <div className="note-info">
            <Badge color="light" pill>
              {userName.charAt(0)}
            </Badge>
            <span> {userName}</span>
            <p className="note-timestamp">{Utils.moment(this.props.note.created).format("lll")}</p>
          </div>

          <Button
            size="sm"
            className="resolve-note"
            onClick={() => {
              this.props.resolveNote(this.props.noteHolder, this.props.noteIdx)
            }}
          >
            {t("buttons.resolve")}
          </Button>
        </div>

        <p
          className="text"
          onClick={() => {
            this.props.editNote(this.props.noteHolder, this.props.noteIdx, this.props.note.note)
          }}
        >
          <Icon icon="edit" />
          {this.props.note.note}
        </p>

        <div className="comments">
          {this.props.note.comments
            ? this.props.note.comments.map((comment, idx) => {
                const userName = comment.userName ? comment.userName : "-"
                return (
                  <div key={idx} className="comment">
                    <Badge color="light" pill>
                      {userName.charAt(0)}
                    </Badge>
                    <span> {userName}</span>
                    <p className="note-timestamp">{Utils.moment(comment.created).format("lll")}</p>
                    <p
                      className="text"
                      onClick={() => {
                        this.props.editComment(this.props.noteHolder, this.props.noteIdx, idx, comment.comment)
                      }}
                    >
                      <Icon icon="edit" />
                      {comment.comment}
                    </p>
                  </div>
                )
              })
            : null}

          <div>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                this.props.addCommentToNote(this.props.noteHolder, this.props.noteIdx, this.state.comment).then(() => {
                  this.setStateIfMounted({ comment: "" })
                })
              }}
            >
              <Input type="submit" bsSize="sm" color="secondary" value="+" />
              <Input
                type="textarea"
                placeholder={`${t("process.labels.add_a_reply")}`}
                value={this.state.comment}
                onChange={(e) => {
                  this.setStateIfMounted({ comment: e.target.value })
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
