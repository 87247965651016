import React, { useEffect, useState } from "react"
import { PopoverBody, Table, UncontrolledPopover } from "reactstrap"
import i18n from "src/i18n"

import type { TableDataMap, TableData, TableDataKeyEnum } from "./ProjectionView.d"

const { t } = i18n
// Fixed height is here because the `sticky` property struggles styling correctly without it
const TABLE_HEIGHT = 600 //px

const TableDataContent = ({ data, dataKey }: { data: TableData; dataKey: TableDataKeyEnum }): JSX.Element | null => {
  // Hides zeros from table
  if (!data[dataKey]) {
    return null
  }

  switch (dataKey) {
    case "dcSize":
      return <span>{data[dataKey].toLocaleString("en-US", { maximumFractionDigits: 2 })}</span>
    case "projectCount":
      return <span>{data[dataKey]}</span>
    default:
      return null
  }
}

/**
 *
 * Table Popover Component
 *
 */
const TableDataPopover = ({
  // Props
  $id,
  data,
  dataKey,
}: {
  $id: string
  data: TableData
  dataKey: TableDataKeyEnum
}): JSX.Element => {
  return (
    <td id={$id} className="table__data-content">
      {/*   Display Table Data   */}
      <TableDataContent data={data} dataKey={dataKey} />

      {/*   Enable Popover if `data.projects` exist and is not empty   */}
      {data.projects && data.projects.length !== 0 && (
        <UncontrolledPopover target={$id} trigger="legacy" placement="right-start" offset={[0, 0]}>
          <PopoverBody>
            <Table dark striped>
              <thead>
                <tr>
                  <th>{t("table_headings.name")}</th>
                  <th>{t("table_headings.code")}</th>
                  <th className="right-align">{"DC (kW)"}</th>
                </tr>
              </thead>
              <tbody>
                {data.projects.map((project) => {
                  return (
                    <tr key={project.code}>
                      <td>{project.name}</td>
                      <td>/{project.code}</td>
                      <td className="right-align">{project.dcSize}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </td>
  )
}

/**
 *
 * Table Component
 *
 */
const ProjectionViewTable = ({
  tableHeaders,
  tableData,
  tableDataKey,
  tableRowNames,
}: {
  tableHeaders: string[]
  tableData: TableDataMap | undefined
  tableDataKey: [TableDataKeyEnum, string]
  tableRowNames: string[]
}): JSX.Element => {
  // If something goes wrong 100px default seems like a reasonable size
  const [tableRowHeight, setTableRowHeight] = useState(100)
  useEffect(() => setTableRowHeight(TABLE_HEIGHT / ((tableData?.size ?? 0) + 1)), [tableData?.size ?? 0])

  return (
    <div className="projection-view-table">
      <Table dark borderless striped style={{ height: `${TABLE_HEIGHT}px` }}>
        <tbody>
          <tr>
            <th className="table__sticky-headers">
              <div className="table__sticky-headers__content" style={{ height: `${tableRowHeight}px` }}>
                {`${tableDataKey[1]}`}
              </div>
            </th>

            {/*   Quarter Headers   */}
            {tableHeaders.map((quarter) => {
              return (
                <th key={quarter} className="table__quarter-headers">
                  {quarter}
                </th>
              )
            })}
          </tr>

          {tableData &&
            Array.from(tableData).map(([tableRow, tableRowData], idx) => {
              const tableRowHeader = tableRowNames[idx] ?? `${tableRow} (${tableDataKey[1]})`
              return (
                <tr key={tableRow}>
                  <th className="table__sticky-headers">
                    <div className="table__sticky-headers__content" style={{ height: `${tableRowHeight}px` }}>
                      {tableRowHeader}
                    </div>
                  </th>

                  {/*   Table Content   */}
                  {Object.entries(tableRowData).map(([quarter, entry]) => {
                    const $id = `${tableRow}-${quarter}`.replaceAll(" ", "-")

                    return <TableDataPopover key={$id} $id={$id} data={entry} dataKey={tableDataKey[0]} />
                  })}
                </tr>
              )
            })}
        </tbody>
      </Table>
    </div>
  )
}

export default ProjectionViewTable
