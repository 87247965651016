import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"

/**
 * Get the RFI schema.
 */
export const rfiSchema = (): Promise<Schema> => {
  return API.get<{ schema: Schema }>(`/schemas/rfi`)
    .then((response) => response.schema)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'rfiSchema': ${getErrorMessage(err)}` })
    })
}

/**
 * Get the RFI reply schema.
 */
export const rfiReplySchema = (): Promise<Schema> => {
  return API.get<{ schema: Schema }>(`/schemas/rfiReply`)
    .then((response) => response.schema)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'rfiReplySchema': ${getErrorMessage(err)}` })
    })
}

/**
 * List all RFI associated with a project code.
 * @param code - The project code.
 * @param version - The RFI version.
 */
export const getCodeRFI = (code: string, version = "v0") => {
  return API.get<{ rfi: unknown }>(`/rfi/thread/code/${code}/${version}`)
    .then((response) => response.rfi)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'getCodeRFI': ${getErrorMessage(err)}` })
    })
}

/**
 * Get an RFI.
 * @param id - The RFI ID.
 * @param version
 */
export const getRFI = (id: string, version = "v0"): Promise<RFIStored> => {
  return API.get<{ rfi: RFIStored }>(`/rfi/thread/${id}/${version}`)
    .then((response) => response.rfi)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'getRFI': ${getErrorMessage(err)}` })
    })
}

/**
 * Get all RFI replies.
 * @param id - The RFI ID.
 * @param version - The version.
 */
export const getAllRFIReplies = (id: string, version = "v0") => {
  return API.get<{ rfiReplies: RFIReplyStored[] }>(`/rfi/thread/${id}/replies/${version}`)
    .then((response) => response.rfiReplies)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'getAllRFIReplies': ${getErrorMessage(err)}` })
    })
}

/**
 * Create an RFI.
 * @param transit - The RFI details.
 */
export const createRFI = (transit: RFITransit): Promise<RFIStored> => {
  return API.post<{ rfi: RFIStored }, RFITransit>(`/rfi/thread`, transit)
    .then((response) => response.rfi)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'createRFI': ${getErrorMessage(err)}` })
    })
}

/**
 * Update an RFI.
 * @param id - The RFI ID.
 * @param transit - The RFI details.
 */
export const updateRFI = (id: string, transit: RFITransit): Promise<string> => {
  return API.put<{ latestVersion: string }, RFITransit>(`/rfi/thread/${id}`, transit)
    .then((response) => response.latestVersion)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'updateRFI': ${getErrorMessage(err)}` })
    })
}

/**
 * Resolve an RFI.
 * @param id - The RFI ID.
 * @param resolution - The new resolution status.
 */
export const resolveRFI = (id: string, resolution: boolean): Promise<string> => {
  return API.post<{ latestVersion: string }, never>(`/rfi/thread/resolve/${id}/${resolution}`)
    .then((response) => response.latestVersion)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'resolveRFI': ${getErrorMessage(err)}` })
    })
}

/**
 * Delete an RFI.
 * @param id - The RFI id.
 */
export const deleteRFI = (id: string): Promise<string> => {
  return API.delete<{ deleted: string }, never>(`/rfi/thread/${id}`)
    .then((response) => response.deleted)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'deleteRFI': ${getErrorMessage(err)}` })
    })
}

/**
 * Delete an RFI reply.
 * @param id - The RFI reply ID.
 */
export const deleteRFIReply = (id: string): Promise<string> => {
  return API.delete<{ deleted: string }, never>(`/rfi/reply/${id}`)
    .then((response) => response.deleted)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute ''deleteRFIReply': ${getErrorMessage(err)}` })
    })
}

/**
 * Create an RFI reply.
 * @param transit - The RFI reply details.
 */
export const createRFIReply = (transit: RFIReplyTransit): Promise<RFIReplyStored> => {
  return API.post<{ rfiReply: RFIReplyStored }, RFIReplyTransit>(`/rfi/reply`, transit)
    .then((response) => response.rfiReply)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'createRFIReply': ${getErrorMessage(err)}` })
    })
}

/**
 * Update an RFI reply
 * @param id - The RFI reply ID.
 * @param transit - The RFI reply details.
 */
export const updateRFIReply = (id: string, transit: RFIReplyTransit): Promise<string> => {
  return API.put<{ latestVersion: string }, RFIReplyTransit>(`/rfi/reply/${id}`, transit)
    .then((response) => response.latestVersion)
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to execute 'updateRFIReply': ${getErrorMessage(err)}` })
    })
}
