import React, { useState } from "react"
import "./DualListBox.scss"
import SelectAll from "../button/SelectAll"
import { Button, Input } from "reactstrap"
import { range } from "lodash"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
interface DualListItem {
  getID(): string

  getValues(): string[]
}

export type DualListBoxProps = {
  fields: string[]
  options: DualListItem[]
  selected: number[]
  onChange: (selected: string[]) => void
}

const renderBox = (
  prefix: string, // Used for key ids
  fields: string[], // Array of field names
  options: DualListItem[], // Array of selection options
  items: Set<number>, // Array of unselected options
  selected: Set<number>, // Array of selected options
  setSelected: React.Dispatch<React.SetStateAction<Set<number>>>, // Called to set selected options
): JSX.Element => {
  return (
    <div className="listbox">
      <table className="listbox-table">
        <thead>
          <tr>
            <th>
              <SelectAll status={selected.size == items.size ? 1 : selected.size > 0 ? 2 : 0} allToggle={() => setSelected(new Set(items))} noneToggle={() => setSelected(new Set<number>())} />
            </th>
            {fields.map((field) => {
              return <th key={prefix + "-" + field}>{t(`inputFields.${field}`)}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {Array.from(items.values()).map((x) => {
            const item = options[x]
            return (
              <tr key={prefix + "-row-" + x}>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <Input
                    type={"checkbox"}
                    checked={selected.has(x)}
                    onChange={() => {
                      const newSelected = new Set(selected)

                      if (newSelected.has(x)) {
                        newSelected.delete(x)
                      } else {
                        newSelected.add(x)
                      }

                      setSelected(newSelected)
                    }}
                  />
                </td>
                {item.getValues().map((value) => {
                  // eslint-disable-next-line react/jsx-key
                  return <td className={"td-field"}>{value}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export const DualListBox = (props: DualListBoxProps): JSX.Element => {
  const [selected, setSelected] = useState<Set<number>>(props.selected ? new Set(props.selected) : new Set())
  const [leftSelected, setLeftSelected] = useState<Set<number>>(new Set())
  const [rightSelected, setRightSelected] = useState<Set<number>>(new Set())

  // The available options is equal to the set of all options minus the selected options
  const available = new Set(range(0, props.options.length).filter((option) => !selected.has(option)))

  return (
    <div className={"listbox-container"}>
      <div className={"listbox-left"}>
        <h4>{t("labels.available")}</h4>
        {renderBox("left", props.fields, props.options, available, leftSelected, setLeftSelected)}
      </div>

      <div className={"listbox-middle"}>
        <div className={"mid-container"}>
          <Button
            style={{ margin: 0, marginRight: 0 }}
            className="icon-button add-button mid-button"
            onClick={() => {
              const newSelected = new Set(Array.from(selected).concat(Array.from(leftSelected)))
              setSelected(newSelected)
              setLeftSelected(new Set())
              if (props.onChange) {
                props.onChange(Array.from(newSelected.values()).map((i) => props.options[i].getID()))
              }
            }}
          >
            <Icon icon="arrow_right" />
          </Button>
          <Button
            style={{ marginRight: 0 }}
            className="icon-button add-button mid-button"
            onClick={() => {
              const newSelected = new Set(Array.from(selected).filter((x) => !rightSelected.has(x)))
              setSelected(newSelected)
              setRightSelected(new Set())
              if (props.onChange) {
                props.onChange(Array.from(newSelected.values()).map((i) => props.options[i].getID()))
              }
            }}
          >
            <Icon icon="arrow_left" />
          </Button>
        </div>
      </div>
      <div className={"listbox-right"}>
        <h4>{t("labels.selected")}</h4>
        {renderBox("right", props.fields, props.options, selected, rightSelected, setRightSelected)}
      </div>
    </div>
  )
}
