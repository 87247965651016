import React, { useState } from "react"
import moment from "moment"

import { Spinner, Button, Table, UncontrolledPopover, PopoverBody, PopoverHeader, UncontrolledTooltip } from "reactstrap"

import { ProForma, typeofArrayElements } from "@ecogy-types/main"

import "./LinkedCashFlowPopover.scss"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
import { EcosuiteComponentError } from "@common/EcosuiteComponent"

// These types would probably be better served inside of @ecogy-types/
type ProFormaCashFlow = typeofArrayElements<ProForma["cashFlows"]>
type CashFlowPayment = typeofArrayElements<NonNullable<ProFormaCashFlow>["payments"]>

// The typedefs inside the enum for `payments` do not get passed correctly from `main.d`
// so I have to extend `CashFlowPayment` with this template to make things functional
type ExtendedCashFlowPayment = CashFlowPayment & {
  notes?: string
  total?: number

  // Scheduled payment typedefs
  payments?: {
    amountDue?: number
    description?: string
    dueDate?: string
  }[]

  // Amortization payment typedefs
  startDate?: number
  rate?: number
  frequency?: string
  amount?: number
  amortization?: number
  term?: number

  // Recurring, Generation and Size payment typedefs
  recurrence?: {
    billedAtStart: boolean
    startDate: string
    frequency?: string
    term: number
    escalator?: number
    startRate?: number
    rateType: "fixed" | "variable"
    rates?: number[]
  }
}

// If this function gets larger / more complex it would probably be wise to separate
// each of the tables into their own components.
const { t } = i18n
const renderInnerTable = (payment: ExtendedCashFlowPayment): JSX.Element => {
  const { paymentType, payments, recurrence } = payment

  // TODO Add sub table for 'tariff' payment type
  // https://ecogyenergy.atlassian.net/browse/EP-2327

  // Scheduled Payment Inner Table
  if (paymentType === "scheduled") {
    return (
      <tbody>
        <tr>
          <td className="table__row-header" style={{ width: "60%" }}>
            {t("table_headings.description")}
          </td>
          <td className="table__row-header align-right" style={{ width: "20%" }}>
            {t("table_headings.amount")}
          </td>
          <td className="table__row-header align-right" style={{ width: "20%" }}>
            {t("table_headings.due_date")}
          </td>
        </tr>

        {payments?.map((transaction, idx) => {
          return (
            <tr key={`${payment.subAccount}-${idx}`}>
              <td>{transaction.description ?? "N/A"}</td>
              <td className="align-right">
                {transaction.amountDue?.toLocaleString("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                })}
              </td>
              <td className="align-right">{moment(transaction.dueDate).format("M/D/YY")}</td>
            </tr>
          )
        })}
      </tbody>
    )
  }
  // Amortization Payment Inner Table
  else if (paymentType === "amortization") {
    return (
      <tbody>
        <tr>
          <td className="table__row-header" style={{ width: "40%" }}>
            {t("table_headings.property")}
          </td>
          <td className="table__row-header align-right" style={{ width: "40%" }}>
            {t("table_headings.value")}
          </td>
          <td className="table__row-header align-right" style={{ width: "20%" }}></td>
        </tr>

        <tr>
          <td>{t("table_headings.start_date")}</td>
          <td className="align-right">{moment(payment?.startDate).format("M/D/YY")}</td>
          <td></td>
        </tr>
        <tr>
          <td>{t("table_headings.frequency")}</td>
          <td className="capitalize align-right">{payment.frequency}</td>
          <td></td>
        </tr>
        <tr>
          <td>{t("table_headings.term")}</td>
          <td className="align-right">{payment.term?.toLocaleString()}</td>
          <td></td>
        </tr>
        <tr>
          <td>{t("table_headings.rate")}</td>
          <td className="align-right">{payment.rate?.toLocaleString()}</td>
          <td></td>
        </tr>
        <tr>
          <td>{t("table_headings.amortization")}</td>
          <td className="align-right">{payment.amortization?.toLocaleString()}</td>
          <td></td>
        </tr>
        <tr>
          <td>{t("table_headings.amount")}</td>
          <td className="capitalize align-right">
            {payment.amount?.toLocaleString("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
            })}
          </td>
          <td></td>
        </tr>
      </tbody>
    )
  }
  // Recurring Payment Inner Table
  else if (paymentType === "recurring" || recurrence) {
    return (
      <tbody>
        <tr>
          <td className="table__row-header" style={{ width: "40%" }}>
            {t("table_headings.property")}
          </td>
          <td className="table__row-header align-right" style={{ width: "40%" }}>
            {t("table_headings.value")}
          </td>
          <td className="table__row-header align-right" style={{ width: "20%" }}></td>
        </tr>

        <tr>
          <td>{t("table_headings.start_date")}</td>
          <td className="align-right">{moment(recurrence?.startDate).format("M/D/YY")}</td>
          <td></td>
        </tr>
        <tr>
          <td>{t("table_headings.frequency")}</td>
          <td className="capitalize align-right">{recurrence?.frequency}</td>
          <td></td>
        </tr>
        <tr>
          <td>{t("table_headings.term")}</td>
          <td className="align-right">{recurrence?.term?.toLocaleString()}</td>
          <td></td>
        </tr>
        <tr>
          <td>{t("table_headings.rate_type")}</td>
          <td className="capitalize align-right">{recurrence?.rateType}</td>
          <td></td>
        </tr>

        {/* Fixed Rate Table Rows */}
        {recurrence?.rateType === "fixed" && (
          <>
            <tr>
              <td>{t("table_headings.starting_rate")}</td>
              <td className="capitalize align-right">{recurrence?.startRate?.toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td>{t("table_headings.escalator")}</td>
              <td className="capitalize align-right">{recurrence?.escalator?.toLocaleString()}</td>
              <td></td>
            </tr>
          </>
        )}

        {/* Variable Rate Table Rows */}
        {recurrence?.rateType === "variable" &&
          recurrence.rates?.map((rate, idx) => {
            return (
              <tr key={`variable-rate-${idx}`}>
                <td>{idx ? "" : "Rates:"}</td>
                <td className="align-right">{rate.toLocaleString()}</td>
                <td></td>
              </tr>
            )
          })}
      </tbody>
    )
  }
  // Render this warning when this component gets unexpected data
  else {
    return (
      <tbody>
        <tr>
          <td colSpan={3}>
            <Icon className="warning" icon="warning" />
            <span>{t("errors.failed_data_render")}</span>
          </td>
        </tr>
      </tbody>
    )
  }
}

const LinkedCashFlowPopover = ({ linkedCashFlow }: { linkedCashFlow: ProFormaCashFlow | EcosuiteComponentError | "Loading" }): JSX.Element | null => {
  if (linkedCashFlow === "Loading") {
    return (
      <div>
        <Spinner className="loading-spinner" size="sm" />
        <span style={{ fontSize: "1rem" }}>{t("loadingMsg.check_pro_forma")}</span>
      </div>
    )
  }
  if (linkedCashFlow instanceof EcosuiteComponentError) {
    return (
      <span>
        <UncontrolledTooltip target={"linked-cash-flow-error"}>{linkedCashFlow.getError()}</UncontrolledTooltip>
        <Icon icon="error_outline" id={"linked-cash-flow-error"} />
      </span>
    )
  }

  if (!linkedCashFlow) {
    return null
  }

  return (
    <div>
      <Button id="LinkedCashFlowToggle">{t("headings.view_related_cash_flow")}</Button>
      <UncontrolledPopover className="popover__styles" target="LinkedCashFlowToggle" trigger="click" offset={[20, 8]} hideArrow>
        <PopoverBody>
          <Table dark borderless>
            <tbody>
              <tr>
                <td>{t("table_headings.name")}</td>
                <td>{linkedCashFlow.name}</td>
              </tr>
              <tr>
                <td>{t("table_headings.category")}</td>
                <td>{linkedCashFlow.category}</td>
              </tr>
              <tr>
                <td>{t("table_headings.account")}</td>
                <td>{linkedCashFlow.account}</td>
              </tr>

              {/* Render Inner Tables */}
              {linkedCashFlow.payments.map((payment: ExtendedCashFlowPayment, idx) => {
                const [subAccountOpen, setSubAccountOpen] = useState(false)

                return (
                  <tr key={`payment-inner-table-${idx}`}>
                    <td colSpan={2} style={{ paddingTop: 0 }}>
                      <Table id={`notes_popover_${idx}`} className="popover__inner-table" dark borderless>
                        <thead>
                          <tr>
                            <th colSpan={3} className="hoverable capitalize" onClick={() => setSubAccountOpen(!subAccountOpen)}>
                              {payment.subAccount ?? payment.paymentType}
                              <Icon icon={subAccountOpen ? "expand_less" : "expand_more"} />
                            </th>
                          </tr>
                        </thead>

                        {subAccountOpen && renderInnerTable(payment)}
                      </Table>

                      {/* Render Notes Popover */}
                      {payment.notes && (
                        <UncontrolledPopover className="notes_popover" target={`notes_popover_${idx}`} placement="top-end" trigger="hover" offset={[0, 2]}>
                          <PopoverHeader>{t("headings.notes")}</PopoverHeader>
                          <PopoverBody>{payment.notes}</PopoverBody>
                        </UncontrolledPopover>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  )
}

export default LinkedCashFlowPopover
