import API from "./API"
import Keyv from "keyv"

const SCHEMA_CACHE = new Keyv()
const CACHE_TTL = 1000 * 60 * 10 // Expire in 10 minutes

class Schemas {
  static loadSchemas() {
    Schemas.getPortfolioSchema()
    Schemas.getProjectSchema()
    Schemas.getSiteSchema()
    Schemas.getSystemSchema()
    Schemas.getRecordSchema()
    Schemas.getNotificationSchema()
  }

  static getPortfolioSchema() {
    return Schemas.getSchema("/schemas/portfolio")
  }

  static getProjectSchema() {
    return Schemas.getSchema("/schemas/project")
  }

  static getSiteSchema() {
    return Schemas.getSchema("/schemas/site")
  }

  static getSystemSchema() {
    return Schemas.getSchema("/schemas/system")
  }

  static getRecordSchema() {
    return Schemas.getSchema("/schemas/record")
  }

  static getProFormaSchema() {
    return Schemas.getSchema("/schemas/pro-forma")
  }

  static getNotificationSchema() {
    return Schemas.getSchema("/schemas/notification")
  }

  static getFinanceSchema() {
    return Schemas.getSchema("/schemas/finance")
  }

  static getPaymentLogsSchema() {
    return Schemas.getSchema("/schemas/payment-logs")
  }

  static async getSchema(url: string) {
    const cached = await SCHEMA_CACHE.get(url)
    if (cached) {
      return Promise.resolve(cached)
    } else {
      return Schemas.cacheSchema(url)
    }
  }

  static cacheSchema(url: string) {
    return API.get(url).then((schema) => {
      SCHEMA_CACHE.set(url, schema, CACHE_TTL)
      return schema
    })
  }
}

export default Schemas
