import React from "react"
import { Button, Col, Container, Input, InputGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"
import UserGroups from "../UserGroups"
import Security from "../Security"
import UserAdminService from "../UserAdminService"

import CreateUser from "./CreateUser"
import EditUser from "./EditUser"
import { fuzzySearchStringList } from "@common/input/search/SearchGroup"
import UserList from "../widgets/UserList"
import Notifications from "src/profile/notifications/Notifications"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
// The user table entry padding.
//
// This is used to accurately align other items on the screen around the fact
// that the user table entries have extra right padding.
const userTablePadding = "20px"

class Users extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.refreshUser = this.refreshUser.bind(this)
    this.selectUser = this.selectUser.bind(this)
    this.loadUsers = this.loadUsers.bind(this)
    this.toggle = this.toggle.bind(this)
    this.resetUserGroup = this.resetUserGroup.bind(this)

    this.state = {
      users: null,
      conflictingUserGroups: false,
      activeTab:
        window.location.hash === "#security" ? "security" : window.location.hash === "#groups" ? "groups" : "user",
      _forceUpdateUserGroups: 0,
      loadingResetUserGroups: false,
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadUsers()
    this.loadUserTypes()
    this.loadGroups()
  }

  loadGroups() {
    this.setState({ groups: [] })

    UserAdminService.getGroups()
      .then((response) => {
        this.setStateIfMounted({
          groups: response.groups,
        })
      })
      .catch((error) => {
        Logger.error(error)
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  loadUserTypes() {
    this.setState({ userTypes: {} })

    UserAdminService.getUserTypes()
      .then((response) => {
        // Transform response into { userTypeId: userTypeName, }
        const userTypes = {}
        response.userTypes.map((userType) => {
          userTypes[userType.id] = { name: userType.name, defaultGroups: userType.defaultGroups }
        })

        this.setStateIfMounted({
          userTypes: userTypes,
        })
      })
      .catch((error) => {
        Logger.error(error)
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  loadUsers() {
    this.setState({ users: null })

    UserAdminService.getUsers()
      .then(async (response) => {
        const users = response.users.sort((a, b) => {
          return a.displayName.localeCompare(b.displayName)
        })
        let selectedUserId = this.props.selectedUserId
        let user = users.find((user) => {
          return user.id === selectedUserId
        })
        this.setStateIfMounted({
          users: users,
          selectedUser: user,
        })
      })
      .then((id) => {
        if (id) {
          this.props.selectUser(id)
        }
      })
  }

  refreshUser(user) {
    for (var i = 0; i < this.state.users.length; i++) {
      if (this.state.users[i].id === user.id) {
        this.state.users[i] = user
        break
      }
    }

    this.setState({ users: this.state.users, selectedUser: null })
  }

  componentDidUpdate(prevProps) {
    if (this.state.users && this.state.users.length > 0 && this.props.selectedUserId !== prevProps.selectedUserId) {
      let user = this.state.users.find((user) => {
        return user.id === this.props.selectedUserId
      })
      this.setStateIfMounted({
        selectedUser: user,
      })
    }
  }

  selectUser(user) {
    if (this.state.selectedUser && user && this.state.selectedUser.id === user.id) {
      user = null
    }
    this.setStateIfMounted({
      selectedUser: user,
    })
  }

  /**
   * Get all possible search terms. This currently returns a list of user
   * emails.
   */
  getPossibleSearchTerms() {
    return this.state.users ?? []
  }

  /**
   * Get a user in state using a provided email address.
   * @param email - The expected email address.
   */
  getUserByEmail(email) {
    return this.state.users.find((element) => element.attributes.email === email)
  }

  /**
   * Search the users given an onChange event.
   * @param event - The onChange event.
   */
  searchUsers(event) {
    const value = event.target.value
    let terms = []

    // If the user has input some value, then run the fuzzy search.
    if (value) {
      const results = fuzzySearchStringList(this.getPossibleSearchTerms(), value, {
        includeScore: true,
        threshold: 0.25,
        keys: ["attributes.email", "attributes.firstName", "attributes.lastName"],
      })
      results.forEach((result) => {
        terms.push(result.item)
      })
    }

    this.setStateIfMounted({
      searchTerm: value,
      searchTermResults: value ? terms : this.getPossibleSearchTerms(),
    })
  }

  /**
   * Resets user's user groups to the user's userType defaults
   * @returns {void}
   */
  resetUserGroup() {
    this.setStateIfMounted({
      loadingResetUserGroups: true,
    })
    UserAdminService.resetUserGroup(this.state.selectedUser)
      .then(() => {
        this.setStateIfMounted({
          _forceUpdateUserGroups: ++this.state._forceUpdateUserGroups,
        })
      })
      .catch(() => {
        this.setStateIfMounted({
          hasError: true,
          error: `${t("settings.messages.reset_user_groups_error")}`,
        })
      })
      .finally(() => {
        this.setStateIfMounted({
          conflictingUserGroups: false,
          loadingResetUserGroups: false,
        })
      })
  }

  /**
   * Clear the current search parameters.
   */
  clearSearch() {
    this.setStateIfMounted({
      searchTerm: "",
      searchTermResults: this.getPossibleSearchTerms(),
    })
  }

  renderContent() {
    if (this.state.selectedUser && !this.state.selectedUser.id) {
      return (
        <CreateUser
          user={this.state.selectedUser}
          selectUser={this.selectUser}
          reloadUsers={this.loadUsers}
          userTypes={this.state.userTypes}
          groups={this.state.groups}
          projects={this.props.projects}
          portfolios={this.props.portfolios}
        />
      )
    }

    if (!this.state.users) {
      return <Loading />
    }
    return this.state.selectedUser ? (
      <Row>
        <Container fluid={true} className="Admin-footer">
          <div className="admin-footer">
            {this.renderSearchBar()}
            <Button
              className="header-button float-end"
              color="danger"
              onClick={() => {
                this.selectUser()
              }}
            >
              {t("buttons.cancel")}
            </Button>
          </div>
        </Container>
        <Container fluid={true} className="Admin-content">
          <Row>
            <Col md="3">
              <UserList
                userId={this.state.selectedUser ? this.state.selectedUser.id : null}
                users={this.state.users}
                selectUser={(id) => this.selectUser(this.state.users.find((u) => u.id === id))}
                searchTermResults={this.state.searchTermResults}
                extended={false}
              />
            </Col>
            <Col md="9">{this.renderUserDetails()}</Col>
          </Row>
        </Container>
      </Row>
    ) : (
      <Row>
        <Container fluid={true} className="Admin-content">
          <UserList
            userId={this.state.selectedUser ? this.state.selectedUser.id : null}
            users={this.state.users}
            selectUser={(id) => this.selectUser(this.state.users.find((u) => u.id === id))}
            searchTermResults={this.state.searchTermResults}
            extended={true}
          />
        </Container>
        <Container fluid={true} className="Admin-footer">
          <div className="admin-footer">
            {this.renderSearchBar()}
            <Button
              className="header-button float-end"
              color="ecogy"
              onClick={() => {
                this.selectUser({})
              }}
            >
              {t("settings.labels.new_user")}
            </Button>
          </div>
        </Container>
      </Row>
    )
  }

  /**
   * Render the user search bar.
   * @returns {JSX.Element} - The user search bar.
   */
  renderSearchBar() {
    // TODO: move to SearchGroup component.
    return (
      <InputGroup
        style={{
          width: `calc(25% - ${userTablePadding})`,
          display: "inline-flex",
          paddingLeft: "2.5px",
        }}
        className={"float-start"}
      >
        <Input
          placeholder={`${t("settings.labels.search_users")}`}
          value={this.state.searchTerm ? this.state.searchTerm : ""}
          onChange={(event) => this.searchUsers(event)}
          on="true"
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              if (this.state.searchTermResults) {
                this.selectUser(this.getUserByEmail(this.state.searchTermResults[0]))
              }
            }
          }}
        />
        <Button
          id={"clearUserInput"}
          onClick={(e) => {
            e.preventDefault()
            this.clearSearch()
          }}
        >
          <Icon icon="clear" />
        </Button>
        <UncontrolledTooltip placement="bottom" target={"clearUserInput"}>
          {`${t("settings.labels.clear_input")}`}
        </UncontrolledTooltip>
      </InputGroup>
    )
  }

  renderUserDetails() {
    return (
      <div className="admin-content admin-tabs">
        {/*   User Admin Tabs   */}
        <Nav tabs>
          <NavItem>
            <NavLink
              className={this.state.activeTab === "user" ? "active" : null}
              onClick={() => {
                this.toggle("user")
              }}
            >
              {t("header.userMenu.profile")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab === "permissions" ? "active" : null}
              onClick={() => {
                this.toggle("permissions")
              }}
            >
              {t("settings.labels.permissions")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab === "security" ? "active" : null}
              onClick={() => {
                this.toggle("security")
              }}
            >
              {t("settings.labels.security")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab === "notifications" ? "active" : null}
              onClick={() => {
                this.toggle("notifications")
              }}
            >
              {t("header.userMenu.notifications")}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="user">
            <Row>
              <Col sm="12">
                <EditUser
                  user={this.state.selectedUser}
                  userChanged={this.refreshUser}
                  userDeleted={this.loadUsers}
                  projects={this.props.projects}
                  portfolios={this.props.portfolios}
                  conflictingUserGroups={this.state.conflictingUserGroups}
                  resetUserGroup={this.resetUserGroup}
                  loadingResetUserGroups={this.state.loadingResetUserGroups}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="permissions">
            <Row>
              <Col sm="12">
                <UserGroups
                  forceUpdate={this.state._forceUpdateUserGroups}
                  user={this.state.selectedUser}
                  groups={this.state.groups}
                  defaultGroups={
                    this.state.selectedUser?.attributes?.userType
                      ? this.state.userTypes[this.state.selectedUser.attributes.userType]?.defaultGroups
                      : undefined
                  }
                  setConflicting={(bool) => this.setStateIfMounted({ conflictingUserGroups: bool })}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="security">
            <Row>
              <Col sm="12">
                <Security user={this.state.selectedUser} userChanged={this.refreshUser} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="notifications">
            <Row>
              <Col sm="12">
                <div className="admin-tab-content-area">
                  <Notifications
                    admin={true}
                    userId={this.state.selectedUser.id}
                    groups={this.props.groups}
                    users={this.state.users}
                  />
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    )
  }
}

export default Users
