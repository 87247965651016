import React from "react"
import { Button, ButtonGroup } from "reactstrap"
import i18n from "src/i18n"

interface TariffToolbarProps {
  selectedTariff?: Tariff
  onDelete?: (tariff: Tariff) => void
  onNew?: () => void
}

const { t } = i18n

/**
 * The tariff toolbar.
 * @param props - The props.
 * @constructor
 */
export const TariffToolbar = (props: TariffToolbarProps) => {
  const { selectedTariff, onDelete, onNew } = props

  return (
    <div className={"content-view-controls"}>
      <div className={"float-end"}>
        {onDelete && (
          <ButtonGroup>
            {onDelete && selectedTariff && (
              <Button color={"danger"} onClick={() => onDelete(selectedTariff)}>
                {t("buttons.delete")}
              </Button>
            )}
          </ButtonGroup>
        )}
        {onNew && selectedTariff && (
          <Button color={"primary"} onClick={onNew}>
            {t("buttons.new")}
          </Button>
        )}
      </div>
    </div>
  )
}
