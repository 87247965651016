import React from "react"

import { Error } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"

import ServiceRequestService from "../ServiceRequestService"

import ServiceRequestDetails from "./service-request/ServiceRequestDetailsView"
import ServiceRequestListView from "./service-request/ServiceRequestListView"

class ServiceRequestView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.state = {
      searchTerm: "",
    }

    this.selectServiceRequest = this.selectServiceRequest.bind(this)
    this.serviceRequestUpdated = this.serviceRequestUpdated.bind(this)
    this.serviceRequestDeleted = this.serviceRequestDeleted.bind(this)
    this.search = this.search.bind(this)
  }

  isReadOnly() {
    return !this.props.groups.includes("event-write")
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search)

    super.componentDidMount()
    if (params.has("serviceRequestId")) {
      this.loadServiceRequest(params.get("serviceRequestId"))
    }
    if (params.has("new")) {
      this.selectServiceRequest({})
    }
    this.loadServiceRequestSchema()
  }

  componentDidUpdate(prevProps) {
    // Deselect the viewed service request if the selected project changes
    if (this.props.project !== prevProps.project) {
      this.selectServiceRequest()
    }
  }

  loadServiceRequestSchema() {
    ServiceRequestService.getServiceRequestSchema().then((schema) => {
      this.setStateIfMounted({
        serviceRequestSchema: schema,
      })
    })
  }

  loadServiceRequest(serviceRequestId) {
    ServiceRequestService.getServiceRequest(serviceRequestId).then((serviceRequest) => {
      this.selectServiceRequest(serviceRequest)
    })
  }

  selectServiceRequest(serviceRequest) {
    this.setStateIfMounted({ serviceRequest: serviceRequest })
  }

  serviceRequestUpdated(serviceRequest) {
    this.props.actions.serviceRequestUpdated(serviceRequest)
  }

  serviceRequestDeleted(serviceRequest) {
    this.props.actions.serviceRequestDeleted(serviceRequest)

    this.selectServiceRequest()
  }

  search(searchTerm) {
    this.setStateIfMounted({ searchTerm: searchTerm })
  }

  getServiceRequests() {
    var serviceRequests = this.props.serviceRequests
    if (serviceRequests) {
      let projectIds = this.props.projects.map((project) => project.code)

      // Filter down to the selected projects
      serviceRequests = serviceRequests.filter((serviceRequest) => {
        if (this.props.project) {
          return this.props.project.code === serviceRequest.location.project
        } else {
          return projectIds.indexOf(serviceRequest.location.project) >= 0
        }
      })

      // Filter using the search term if there is one
      if (this.state.searchTerm) {
        serviceRequests = serviceRequests.filter((serviceRequest) => {
          return (
            serviceRequest.taskSummary.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
            serviceRequest.context.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
            serviceRequest.path.toUpperCase().startsWith(this.state.searchTerm.toUpperCase())
          )
        })
      }

      return serviceRequests
    }
    return undefined
  }

  renderMainView() {
    if (this.state.serviceRequest) {
      return this.renderServiceRequest()
    } else {
      return this.renderServiceRequests()
    }
  }

  renderServiceRequest() {
    return (
      <ServiceRequestDetails
        userId={this.props.userId}
        username={this.props.username}
        events={this.props.events}
        groups={this.props.groups}
        serviceRequest={this.state.serviceRequest}
        serviceRequestSchema={this.state.serviceRequestSchema}
        projects={this.props.projects}
        project={this.props.project}
        actions={{
          serviceRequestUpdated: this.serviceRequestUpdated,
          serviceRequestDeleted: this.serviceRequestDeleted,
          selectView: this.selectView,
          selectServiceRequest: this.selectServiceRequest,
        }}
      />
    )
  }

  renderServiceRequests() {
    if (this.isContentError(this.props.serviceRequests)) {
      return <Error error={this.props.serviceRequests.getError()} />
    }
    return (
      <div className="content-view">
        <ServiceRequestListView
          groups={this.props.groups}
          project={this.props.project}
          projects={this.props.projects}
          serviceRequests={this.getServiceRequests()}
          serviceRequestSchema={this.state.serviceRequestSchema}
          serviceRequest={this.state.serviceRequest}
          searchTerm={this.state.searchTerm}
          actions={{
            serviceRequestUpdated: this.serviceRequestUpdated,
            selectView: this.selectView,
            selectRange: this.selectRange,
            selectProject: this.selectProject,
            selectServiceRequest: this.selectServiceRequest,
            search: this.search,
          }}
        />
      </div>
    )
  }
}

export default ServiceRequestView
