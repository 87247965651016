import React from "react"

import { EnergyProjectMarker, EnergyRadiusMarker } from "./EnergyView"

import ProjectMap from "@dashboard/ProjectMap"
import { MapView } from "@common/module/EcosuiteView"
import EnergyViewControls from "./EnergyViewControls"

class ProjectEnergyOverView extends MapView {
  renderViewControls() {
    return (
      <EnergyViewControls
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        rangeName={this.props.rangeName}
        customRange={this.props.customRange}
        project={this.props.project}
        projects={this.props.projects}
        viewControls={this.getEnergyViewControls()}
      />
    )
  }

  getEnergyViewControls() {
    return null
  }

  renderMainView() {
    return <ProjectMap markers={this.getMapMarkers()} defaultZoom={12} defaultCenter={{ lat: this.props.project.lat, lng: this.props.project.long }} />
  }

  getMapMarkers() {
    let siteMarkers =
      this.props.project == null
        ? []
        : Object.values(this.props.project.sites).map((site) => {
            let siteStatus = this.getSiteStatus(site.code)
            let siteReading = this.getSiteReading(site.code)

            return site.lat && site.long ? (
              <React.Fragment key={`site-${site.code}`}>
                ß
                <EnergyProjectMarker
                  type="Site"
                  project={site}
                  projectReading={siteReading}
                  projectStatus={siteStatus}
                  showGeneration={this.props.showGeneration}
                  showConsumption={this.props.showConsumption}
                  showStorage={this.props.showStorage}
                />
                <EnergyRadiusMarker
                  project={site}
                  projectReading={siteReading}
                  readings={this.props.readings}
                  projectStatus={siteStatus}
                  showGeneration={this.props.showGeneration}
                  showConsumption={this.props.showConsumption}
                  showStorage={this.props.showStorage}
                />
              </React.Fragment>
            ) : null
          })

    let project = this.props.project
    let projectMarker =
      project.lat && project.long ? (
        <React.Fragment key={project.code}>
          <EnergyProjectMarker
            project={project}
            projectReading={this.props.readings}
            projectStatus={this.props.projectStatus}
            showGeneration={this.props.showGeneration}
            showConsumption={this.props.showConsumption}
            showStorage={this.props.showStorage}
          />
        </React.Fragment>
      ) : null

    return [projectMarker, ...siteMarkers]
  }

  getSiteStatus(siteId) {
    if (this.props.projectStatus) {
      return this.props.projectStatus.sites[siteId]
    }
    return null
  }

  getSiteReading(siteId) {
    if (this.props.readings) {
      return this.props.readings.sites[siteId]
    }
    return null
  }
}

export default ProjectEnergyOverView
