import React from "react"
import Moment from "moment-timezone"
import { extendMoment } from "moment-range"
import Toggle from "react-toggle"
import { Button } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import Utils from "@common/utils/Utils"
import EcosuiteView from "@common/module/EcosuiteView"
import { EcosuiteComponentError, Loading, Error } from "@common/EcosuiteComponent"
import ProjectUtils from "@common/utils/ProjectUtils"
import Settings from "@common/Settings"

import EnergyUtils from "@dashboard/energy/EnergyUtils"

import FinanceService from "../FinanceService"
import EnergyService from "@dashboard/energy/EnergyService"
import { HistoricRates } from "./billing/HistoricRates"
import { BillingGeneration } from "./billing/BillingGeneration"

import "./billing/Billing.css"
import Logger from "@common/Logger"
import NiceModal from "@ebay/nice-modal-react"
import ConfirmDialog from "@common/display/ConfirmModal/ConfirmModal"
import i18n from "src/i18n"

const moment = extendMoment(Moment)
const { t } = i18n

export default class BillingView extends EcosuiteView {
  constructor(props) {
    super(props, "finance-billingreport")

    this.state = {
      showBillingDetails: Settings.getSetting("showBillingDetails", true) === "true",
      sources: {},
    }
    this.handleToggleBillingDetails = this.handleToggleBillingDetails.bind(this)
    this.resolveEvent = this.resolveEvent.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadMostRecentEnergyDatums()

    const range = this.getBillingRange()
    this.loadProjectBilling(range)
    this.loadExpectedGeneration(range)
    this.loadPredictedGeneration(range)
    this.fetchReadings()
  }

  fetchReadings() {
    const projectId = this.props.project.code
    const range = this.getBillingRange()

    EnergyService.getEnergyReadings(range, [projectId])
      .then((response) => {
        const projectsSources = response && response.projects[projectId].sources
        this.setState({
          sources: projectsSources,
        })
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range) && this.areFilteredProjectIdsCurrent([projectId])) {
          this.setStateIfMounted({
            sourceReading: new EcosuiteComponentError(err),
          })
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      const range = this.getBillingRange()
      this.loadProjectBilling(range)
      this.loadExpectedGeneration(range)
      this.loadPredictedGeneration(range)
      this.loadMostRecentEnergyDatums()
      this.fetchReadings()
    }
  }

  isEventsEditable() {
    return this.props.groups.includes("event-write")
  }

  handleToggleBillingDetails(event) {
    Settings.setSetting("showBillingDetails", Boolean(event.target.checked).toString())
    this.setStateIfMounted({ showBillingDetails: event.target.checked })
  }
  loadProjectBilling(range) {
    this.setStateIfMounted({ billing: null, billingEvents: null })

    const projectId = this.props.project.code
    FinanceService.getProjectBilling(projectId)
      .then((response) => {
        if (this.isProjectCurrent(projectId)) {
          this.loadProjectBillingEvents(range, response.lineItems)
          this.setStateIfMounted({
            billing: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for project: ${projectId}`)
        }
      })
      .catch((error) => {
        Logger.error(error)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            billing: new EcosuiteComponentError(error),
          })
        }
      })
  }

  loadProjectBillingEvents(range, lineItems) {
    this.setStateIfMounted({ billingEvents: null })
    const project = this.props.project
    FinanceService.getProjectBillingEvents(project, range, lineItems)
      .then((events) => {
        // Note we don't check that the range is current, as we're using a fixed billing range rather than the dynamic range control
        if (this.isProjectCurrent(project.code)) {
          this.setStateIfMounted({
            billingEvents: events,
          })
        } else {
          Logger.debug(`Ignoring out of date response`)
        }
      })
      .catch((error) => {
        Logger.error(error)
        if (this.isProjectCurrent(project.code)) {
          this.setStateIfMounted({
            billingEvents: new EcosuiteComponentError(error),
          })
        }
      })
  }

  loadMostRecentEnergyDatums() {
    EnergyService.getMostRecentEnergyDatums()
      .then((response) => {
        this.setStateIfMounted({
          mostRecentDatums: response.datums,
        })
      })
      .catch((err) => {
        this.setStateIfMounted({
          mostRecentDatums: new EcosuiteComponentError(err),
        })
      })
  }

  loadExpectedGeneration(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      expectedGeneration: undefined,
    })

    EnergyService.getExpectedGeneration(range, null, [this.props.project.code])
      .then((response) => {
        // Note we don't check that the range is current, as we're using a fixed billing range rather than the dynamic range control
        this.setStateIfMounted({
          expectedGeneration: response,
        })
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({
          expectedGeneration: new EcosuiteComponentError(err),
        })
      })
  }

  loadPredictedGeneration(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      predictedGeneration: undefined,
    })

    EnergyService.getPredictedGeneration(range, [this.props.project.code])
      .then((response) => {
        // Note we don't check that the range is current, as we're using a fixed billing range rather than the dynamic range control
        this.setStateIfMounted({
          predictedGeneration: response,
        })
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({
          predictedGeneration: new EcosuiteComponentError(err),
        })
      })
  }

  getBillingRange() {
    const start = ProjectUtils.projectMoment(this.props.project).subtract(1, "month").startOf("month")
    const end = moment(start).add(1, "month")
    return moment.range(start, end)
  }

  getAsset(lineItem) {
    const path = lineItem.path
    const project = this.props.project
    const siteId = ProjectUtils.getSiteCode(path)

    if (siteId) {
      const site = project.sites[siteId]
      const systemId = ProjectUtils.getSystemCode(path)

      if (site && systemId) {
        return site.systems[systemId]
      } else {
        return site
      }
    } else {
      return project
    }
  }

  async resolveEvent(event) {
    const month = ProjectUtils.projectMoment(this.props.project, this.state.billing.month).format("MMMM")
    const params = event.id.startsWith("invoice-issue")
      ? {
          title: `${t("economics.headings.invoice_issued_for", {
            month: month,
            projectCode: this.props.project.code,
          })}`,
          message: `${t("economics.messages.invoice_habeen_sent", {
            month: month,
            projectName: this.props.project.name,
            projectCode: this.props.project.code,
          })}`,
          confirmText: `${t("economics.messages.Invoice Issued")}`,
        }
      : {
          title: `${month} Invoice  Paid for ${this.props.project.code}?`,
          message: `${t("economics.messages.invoice_due", {
            month: month,
            projectName: this.props.project.name,
            projectCode: this.props.project.code,
            date: ProjectUtils.projectMoment(this.props.project, event.dueDate).format("ll"),
          })}`,
          confirmText: `${t("economics.messages.Payment Received")}`,
        }

    NiceModal.show(ConfirmDialog, {
      ...params,
      onConfirm: () => {
        this.setStateIfMounted({ billingEvents: null })
        FinanceService.resolveEvent(event).then(() => {
          this.loadProjectBillingEvents(this.getBillingRange(), this.state.billing.lineItems)
          this.props.actions.loadEvents(this.props.range) // As we've updated an event reload all the finance events
        })
      },
    })
  }

  renderViewControls() {
    return (
      <div className="content-view-controls">
        <div className="float-end">
          {this.state.billingEvents ? (
            this.state.billingEvents.invoiceEvent && this.isEventsEditable() ? (
              this.state.billingEvents.invoiceEvent.endDate ? (
                <React.Fragment>
                  {`${t("economics.messages.Invoice Issued")}: ${ProjectUtils.projectMoment(
                    this.props.project,
                    this.state.billingEvents.invoiceEvent.endDate,
                  ).format("ll")}; `}
                  {this.state.billingEvents.paymentEvents.length > 1 ? (
                    this.state.billingEvents.paymentEvents((paymentEvent) => {
                      return paymentEvent.endDate ? (
                        `${t("economics.messages.Invoice due")} ${ProjectUtils.projectMoment(
                          this.props.project,
                          paymentEvent.dueDate,
                        ).format("ll")} Paid: ${ProjectUtils.projectMoment(
                          this.props.project,
                          paymentEvent.endDate,
                        ).format("ll")} `
                      ) : (
                        <Button
                          key={paymentEvent.id}
                          color="ecogy"
                          size="sm"
                          onClick={() => {
                            this.resolveEvent(paymentEvent)
                          }}
                        >
                          {`${t("economics.messages.Invoice due")} ${ProjectUtils.projectMoment(
                            this.props.project,
                            paymentEvent.dueDate,
                          ).format("ll")} Paid`}
                        </Button>
                      )
                    })
                  ) : this.state.billingEvents.paymentEvents[0].endDate ? (
                    `${t("economics.messages.Payment Received")}: ${ProjectUtils.projectMoment(
                      this.props.project,
                      this.state.billingEvents.paymentEvents[0].endDate,
                    ).format("ll")} `
                  ) : (
                    <Button
                      color="ecogy"
                      size="sm"
                      onClick={() => {
                        this.resolveEvent(this.state.billingEvents.paymentEvents[0])
                      }}
                    >
                      {t("economics.messages.Payment Received")}
                    </Button>
                  )}
                </React.Fragment>
              ) : (
                <Button
                  color="ecogy"
                  size="sm"
                  onClick={() => {
                    this.resolveEvent(this.state.billingEvents.invoiceEvent)
                  }}
                >
                  {t("economics.messages.Invoice Issued")}
                </Button>
              )
            ) : (
              ""
            )
          ) : (
            "Loading events ..."
          )}
        </div>
        <div className="float-start">
          <label>
            <Toggle defaultChecked={this.state.showBillingDetails} onChange={this.handleToggleBillingDetails} />
            <span className="react-toggle-text">{t("labels.show_details")}</span>
          </label>
        </div>
      </div>
    )
  }

  renderLatestReadings(lineItem) {
    const noDatumsRecorded = t("economics.messages.no_datums_recorded")
    const datumsRecorded = t("economics.messages.datums_recorded")
    if (this.isContentError(this.state.mostRecentDatums)) {
      return <Error error={this.state.mostRecentDatums.getError()} />
    } else if (this.isContentValid(this.state.mostRecentDatums)) {
      const datums = this.state.mostRecentDatums
        .filter((datum) => datum.sourceId.startsWith(lineItem.path))
        .sort((a, b) => {
          return moment(a.created).valueOf() - moment(b.created).valueOf()
        })
      return (
        <table>
          <tbody>
            {datums.map((datum) => {
              const warning = moment(datum.created).isBefore(moment(this.state.billing.month).add(1, "month"))
              return (
                <tr
                  key={datum.sourceId}
                  className={warning ? `${t("labels.warning")}` : "ok"}
                  title={warning ? noDatumsRecorded : datumsRecorded}
                >
                  <td>{datum.sourceId}</td>
                  <td>{moment(datum.created).format("lll")}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    } else {
      return <Loading />
    }
  }

  renderBillingTooltip(lineItem, elementId) {
    if (this.state.billing.month) {
      const billingMonth = this.state.billing.month
      const month = moment(billingMonth).month()
      const year = moment(billingMonth).format("YYYY")
      const endOfMonth = moment().year(parseInt(year)).month(parseInt(month)).endOf("month").format("YYYY-MM-DD HH:mm")
      const endOfPrevMonth = moment(billingMonth).subtract(1, "months").endOf("month").format("YYYY-MM-DD HH:mm")
      const meterReadings =
        lineItem.current.generation &&
        Object.values(this.state.sources).find((source) => source.reading === lineItem?.current?.generation)

      if (meterReadings) {
        return (
          <UncontrolledTooltip target={elementId}>
            <p className="meter-reading">{t("economics.labels.Meter Reads")}</p>
            <div className="date">
              <p>
                {endOfPrevMonth}: {EnergyUtils.formatWattHoursAsKiloWattHours(meterReadings?.start)}
              </p>
              <p>
                {endOfMonth}: {EnergyUtils.formatWattHoursAsKiloWattHours(meterReadings?.end)}
              </p>
            </div>
          </UncontrolledTooltip>
        )
      }
      return
    }
  }

  renderMainView() {
    if (this.isContentError(this.state.billing)) {
      return <Error error={this.state.billing.getError()} />
    } else if (this.isContentValid(this.state.billing)) {
      return (
        <div>
          <h1>{moment(this.state.billing.month).format("MMMM YYYY")}</h1>
          {this.state.billing.lineItems.length ? (
            <table className="billing-table">
              <thead>
                <tr>
                  <th>{t("table_headings.path")}</th>
                  <th>{t("economics.labels.Asset Name")}</th>
                  <th>{t("economics.labels.Contract Name")}</th>
                  <th>{t("economics.labels.Invoice This Month?")}</th>
                  <th>{t("economics.labels.Payment Frequency")}</th>
                  <th>{t("economics.labels.Payment Terms (Days)")}</th>
                  <th>{t("labels.generation")}</th>
                  <th>{t("table_headings.rate")}</th>
                  <th>{t("table_headings.amount")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.billing.lineItems.map((lineItem) => {
                  const asset = this.getAsset(lineItem)
                  return (
                    <React.Fragment key={lineItem.recordId}>
                      <tr className={"billing-line-item" + (lineItem.paymentDue ? " due" : "")}>
                        <td>{lineItem.path}</td>
                        <td>{asset ? asset.name : ""}</td>
                        <td>{lineItem.name}</td>
                        <td>{lineItem.paymentDue ? t("labels.yes") : t("labels.no")}</td>
                        <td>{lineItem.frequency}</td>
                        <td>{lineItem.paymentTerms}</td>
                        <td id={`gen-${lineItem?.current && lineItem.current.generation}`}>
                          {lineItem.current && EnergyUtils.formatWattHoursAsKiloWattHours(lineItem.current.generation)}
                        </td>
                        {lineItem.current && this.renderBillingTooltip(lineItem, `gen-${lineItem.current.generation}`)}
                        <td>
                          {lineItem.current?.rate.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 20,
                          })}
                        </td>
                        <td>{Utils.formatCurrency(lineItem.current?.amount)}</td>
                      </tr>
                      {this.state.showBillingDetails ? (
                        <tr className="billing-line-item-readings">
                          <td colSpan="9">
                            <div className="historic-rates">
                              <h4>{t("economics.headings.Historic Generation")}</h4>
                              <HistoricRates
                                month={this.state.billing.month}
                                lineItem={lineItem}
                                project={this.props.project}
                              />
                            </div>
                            <div className="billing-generation">
                              <h4>{t("economics.headings.Expected & Forecast Generation")}</h4>
                              <BillingGeneration
                                month={this.state.billing.month}
                                lineItem={lineItem}
                                project={this.props.project}
                                expected={this.state.expectedGeneration}
                                predicted={this.state.predictedGeneration}
                              />
                            </div>
                            <div className="reading-status">
                              <h4>{t("economics.headings.Latest Recordings")}</h4>
                              {this.renderLatestReadings(lineItem)}
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div className="empty-message">{t("economics.messages.No Invoice due")}</div>
          )}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
