import * as React from "react"
import Sidebar from "../Onboarding/Sidebar"
import Header, { ThemeProvider } from "./Header"
import Logo from "src/img/ecosuite_logo.png"

const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <ThemeProvider>
      <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-background">
        <Header logo={Logo} />
        <div className="tw-flex ">
          <main className="tw-flex-grow tw-p-6">{children}</main>
        </div>
      </div>
    </ThemeProvider>
  )
}

export const LayoutWithSideBar = ({ children, sidebar }: { children: JSX.Element; sidebar?: JSX.Element }) => {
  return (
    <ThemeProvider>
      <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-background public-view">
        <Header logo={Logo} />
        <div className="tw-flex ">
          {sidebar && (
            <div className="lg:tw-w-[320px] tw-border-r tw-border-[var(--foreground)] tw-h-[calc(100vh-60px)]">
              {sidebar}
            </div>
          )}
          <main className="tw-flex-grow tw-p-6 tw-h-[calc(100vh-60px)]" style={{ overflowY: "auto" }}>
            {children}
          </main>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Layout
