import React from "react"
import { create, all } from "mathjs"
import moment from "moment"

import EcosuiteComponent from "@common/EcosuiteComponent"
import Utils from "@common/utils/Utils"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import FinanceUtils from "@dashboard/finance/FinanceUtils"
import i18n from "src/i18n"

const math = create(all)
const { t } = i18n

export default class RecordPayments extends EcosuiteComponent {
  renderContent() {
    return (
      <div>
        {this.props.recordPaymentDetails.paymentDetails.map((paymentDetails, paymentIdx) => {
          const payment = paymentDetails.proFormaCashFlow.payments[paymentIdx]
          if (FinanceUtils.isOperatingRevenuePayment(paymentDetails.proFormaCashFlow, payment)) {
            return <HistoricPerformanceTable key={paymentIdx} record={this.props.recordPaymentDetails.record} historicPerformance={paymentDetails.historicPerformance} />
          } else if (payment.paymentType === "tariff") {
            return <TariffTable key={paymentIdx} record={this.props.recordPaymentDetails.record} paymentDetails={paymentDetails} />
          } else {
            return null
          }
        })}
      </div>
    )
  }
}

class HistoricPerformanceTable extends EcosuiteComponent {
  renderContent() {
    return (
      <table id={`table-${this.props.record.id}`}>
        <thead>
          <tr>
            <th>{t("table_headings.date")}</th>
            <th>{t("economics.labels.Receivable $ (Cash Basis)")}</th>
            <th>{t("economics.labels.Forecast $ (Cash Basis)")}</th>
            <th>{`${t("labels.actual")} (kWh)`}</th>
            <th>{`${t("labels.expected")} (kWh)`}</th>
            <th>{`${t("labels.forecast")} (kWh)`}</th>
            <th>{`${t("labels.irradiance")} (Wh/m^2)`}</th>
          </tr>
        </thead>
        <tbody>
          {this.props.historicPerformance
            ? this.props.historicPerformance.totals.map((cashFlow, idx) => {
                return (
                  <tr key={idx}>
                    <td>{cashFlow.date}</td>
                    <td className="numerical">{Utils.formatCurrency(cashFlow.receivable)}</td>
                    <td className="numerical">{Utils.formatCurrency(cashFlow.forecastReceivable)}</td>
                    <td className="numerical">{math.round(cashFlow.reading)}</td>
                    <td className="numerical">{math.round(cashFlow.expectedReading)}</td>
                    <td className="numerical">{math.round(cashFlow.forecastReading)}</td>
                    <td className="numerical">{math.round(cashFlow.irradiance)}</td>
                  </tr>
                )
              })
            : null}
        </tbody>
      </table>
    )
  }
}

class TariffTable extends EcosuiteComponent {
  renderContent() {
    const now = moment()
    return (
      <table id={`table-${this.props.paymentDetails.proFormaCashFlow.id}`}>
        <thead>
          <tr>
            <th className="tartiff-title">{t("table_headings.date")}</th>
            <th className="tartiff-title" colSpan="2">
              kWh
            </th>
            <th className="tartiff-title" colSpan="2">
              {t("dataTitles.metered_cost")}
            </th>
            <th className="tartiff-title" colSpan="2">
              {t("table_headings.max")} kW
            </th>
            <th className="tartiff-title" colSpan="2">
              {t("table_headings.max")} kVA
            </th>
            <th className="tartiff-title" colSpan="2">
              {t("dataTitles.demand_charge")}
            </th>
            <th className="tartiff-title" colSpan="2">
              {t("table_headings.fixed_cost")}
            </th>
            <th className="tartiff-title" colSpan="2">
              {t("table_headings.total_cost")}
            </th>
          </tr>
          <tr>
            <th></th>
            <th>{t("labels.expected")}</th>
            <th>{t("labels.forecast")}</th>
            <th>{t("labels.expected")}</th>
            <th>{t("labels.forecast")}</th>
            <th>{t("labels.expected")}</th>
            <th>{t("labels.forecast")}</th>
            <th>{t("labels.expected")}</th>
            <th>{t("labels.forecast")}</th>
            <th>{t("labels.expected")}</th>
            <th>{t("labels.forecast")}</th>
            <th>{t("labels.expected")}</th>
            <th>{t("labels.forecast")}</th>
            <th>{t("labels.expected")}</th>
            <th>{t("labels.forecast")}</th>
          </tr>
        </thead>
        <tbody>
          {this.props.paymentDetails
            ? this.props.paymentDetails.dates.map((date, idx) => {
                const entryDate = moment(date)
                const expected = this.props.paymentDetails.expected
                const forecast = this.props.paymentDetails.forecast
                if (entryDate.isBefore(now)) {
                  return (
                    <tr key={idx}>
                      <td>{entryDate.format("YYYY-MM")}</td>
                      <td className="numerical">{EnergyUtils.formatNumber(expected.kiloWattHours[idx])}</td>
                      <td className="numerical">{EnergyUtils.formatNumber(forecast.kiloWattHours[idx])}</td>
                      <td className="numerical">{Utils.formatCurrency(Math.abs(expected.monthlyConsumptionCosts[idx]))}</td>
                      <td className="numerical">{Utils.formatCurrency(Math.abs(forecast.monthlyConsumptionCosts[idx]))}</td>
                      <td className="numerical">{EnergyUtils.formatNumber(expected.maxKiloWatts[idx])}</td>
                      <td className="numerical">{EnergyUtils.formatNumber(forecast.maxKiloWatts[idx])}</td>
                      <td className="numerical">{EnergyUtils.formatNumber(expected.maxApparentPower[idx])}</td>
                      <td className="numerical">{EnergyUtils.formatNumber(forecast.maxApparentPower[idx])}</td>
                      <td className="numerical">{Utils.formatCurrency(Math.abs(expected.monthlyDemandChargeCosts[idx]))}</td>
                      <td className="numerical">{Utils.formatCurrency(Math.abs(forecast.monthlyDemandChargeCosts[idx]))}</td>
                      <td className="numerical">{Utils.formatCurrency(Math.abs(expected.monthlyFixedCosts[idx]))}</td>
                      <td className="numerical">{Utils.formatCurrency(Math.abs(forecast.monthlyFixedCosts[idx]))}</td>
                      <td className="numerical">{Utils.formatCurrency(Math.abs(expected.monthlyTotalCosts[idx]))}</td>
                      <td className="numerical">{Utils.formatCurrency(Math.abs(forecast.monthlyTotalCosts[idx]))}</td>
                    </tr>
                  )
                } else {
                  return null
                }
              })
            : null}
        </tbody>
      </table>
    )
  }
}
