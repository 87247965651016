import * as Moment from "moment"
import { DateRange, extendMoment } from "moment-range"
import ProjectUtils from "./ProjectUtils"
import Aggregations from "@common/Aggregations"

const moment = extendMoment(Moment)

const AGGREGATION_LIMIT = 3 // the number of units before we move up to the next aggregation unit

export const DateRangeUtils = {
  isShowTimeForRange(range: DateRange): boolean {
    // @ts-ignore
    if (range && moment.isRange(range)) {
      return range.diff("days") < AGGREGATION_LIMIT
    }
    return false
  },

  getAggregateForRange(range: DateRange): string | null {
    // @ts-ignore
    if (range && moment.isRange(range)) {
      if (range.diff("hours") < AGGREGATION_LIMIT) {
        return Aggregations.FiveMinute
      } else if (range.diff("days") < AGGREGATION_LIMIT) {
        return Aggregations.Hour
      } else if (range.diff("months") < AGGREGATION_LIMIT) {
        return Aggregations.Day
      } else if (range.diff("years") < AGGREGATION_LIMIT) {
        return Aggregations.Month
      } else {
        return Aggregations.Year
      }
    }
    return null
  },

  getExclusiveRange(range: DateRange | null, includeTime?: boolean): DateRange | null {
    if (!range) {
      return range
    }

    let exclusiveRange = moment.range(moment(range.start).startOf("day"), moment(range.end).add(1, "day").startOf("day"))

    if (includeTime && this.isShowTimeForRange(range)) {
      const aggregate = this.getAggregateForRange(range)
      exclusiveRange = moment.range(
        moment(range.start),
        moment(range.end)
          .add(1, aggregate as Moment.DurationInputArg2)
          .startOf(aggregate as Moment.DurationInputArg2),
      )
    }

    return exclusiveRange
  },

  getRange(rangeName: string, customRange?: DateRange, projects?: Project[]): DateRange | undefined | null {
    if (rangeName === "custom") {
      return customRange
    } else if (rangeName === "allTime") {
      return moment.range(ProjectUtils.getProductionStartDate(projects ?? []), moment().endOf("day").toDate())
    } else {
      return this.getPrecannedRange(rangeName)
    }
  },

  getPrecannedRange(rangeName: string): DateRange | null {
    const now = moment()
    let startDate: Moment.MomentInput
    let endDate: Moment.MomentInput

    if (rangeName === "today") {
      endDate = now.endOf("day").toDate()
      startDate = now.startOf("day").toDate()
    } else if (rangeName === "yesterday") {
      endDate = now.subtract(1, "days").endOf("day").toDate()
      startDate = now.startOf("day").toDate()
    } else if (rangeName === "thisWeek") {
      endDate = now.endOf("day").toDate()
      startDate = now.startOf("week").toDate()
    } else if (rangeName === "last7Days") {
      endDate = now.endOf("day").toDate()
      startDate = now.subtract(6, "days").startOf("day").toDate()
    } else if (rangeName === "thisMonth") {
      endDate = now.endOf("day").toDate()
      startDate = now.startOf("month").toDate()
    } else if (rangeName === "last30Days") {
      endDate = now.endOf("day").toDate()
      startDate = now.subtract(29, "days").startOf("day").toDate()
    } else if (rangeName === "lastMonth") {
      endDate = now.subtract(1, "months").endOf("month").toDate()
      startDate = now.startOf("month").toDate()
    } else if (rangeName === "thisYear") {
      endDate = now.endOf("day").toDate()
      startDate = now.startOf("year").toDate()
    } else if (rangeName === "last12Months") {
      endDate = now.endOf("month").toDate()
      startDate = now.subtract(11, "months").startOf("month").toDate()
    } else if (rangeName === "lastYear") {
      endDate = now.subtract(1, "years").endOf("year").toDate()
      startDate = now.startOf("year").toDate()
    } else {
      return null
    }

    return moment.range(startDate, endDate)
  },

  getDateRange(date: Moment.Moment | Date, aggregation: string): DateRange {
    if (!date) {
      throw Error("No date provided for date range")
    } else if (!aggregation) {
      throw Error("No aggregation provided for date range")
    } else {
      if (aggregation === Aggregations.FifteenMinute || aggregation === Aggregations.FiveMinute) {
        aggregation = Aggregations.Hour
      }

      return moment.range(
        date,
        moment(date)
          .add(1, aggregation as Moment.DurationInputArg2)
          .subtract(1, "millisecond"), // We subtract a millisecond to make the date range inclusive
      )
    }
  },
}

export default DateRangeUtils
