import Table from "@common/display/Table"
import * as T from "@ecogy-types/main"
import * as React from "react"
import moment from "moment-timezone"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import FinanceUtils from "@dashboard/finance/FinanceUtils"
import WithToolTip from "@common/display/ToolTip"
import i18n from "src/i18n"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import ProjectAdminService from "@dashboard/data/project/ProjectAdminService"
import Logger from "@common/Logger"
import Utils from "@common/utils/Utils"
import { definedIcons, IconProps, IconMeaning, missingIcon, defaultIcon } from "./ProjectIconsData"
import { ProjectTableRow } from "./ProjectRowDataType"
import { ConfirmDeleteModal, IconListModal } from "@common/display/IconModals"
import { NoQualData } from "../Icons"

const { t } = i18n

interface Props {
  projects: Array<T.Project>
  cashFlowTotals: NonNullable<T.ValueOf<NonNullable<T.PathResponse<"/finance/cashflow-totals", 200>>>>
  hasDataWriteAccess: boolean
}

type CashFlowTotals = NonNullable<Props["cashFlowTotals"]>

const isCashFlowOutOfDate = (projectCode: string, cashFlowTotals: CashFlowTotals) => {
  const cashFlowTotal = cashFlowTotals ? cashFlowTotals[projectCode as keyof typeof cashFlowTotals] : undefined
  return cashFlowTotal && moment().diff(moment(cashFlowTotal.timestamp), "hour", true) > 1
}

const calculateTotals = (projects: Array<T.Project>, cashFlowTotals: CashFlowTotals) => {
  const totals = {
    dcSize: 0,
    asset: 0,
    expense: 0,
    income: 0,
    netIncome: 0,
    debt: 0,
    debtProceeds: 0,
    equity: 0,
    unleveredTotal: 0,
    leveredTotal: 0,
    retainedEarnings: 0,
    netSourcesUses: 0,
    totalValue: 0,
    grants: 0,
  }
  for (const project of projects) {
    const cashFlowTotal = cashFlowTotals ? cashFlowTotals[project.code as keyof typeof cashFlowTotals] : undefined
    totals.dcSize += project.dcSize ? project.dcSize : 0
    if (cashFlowTotal) {
      totals.asset += cashFlowTotal.asset ? cashFlowTotal.asset : 0
      totals.expense += cashFlowTotal.expense ? cashFlowTotal.expense : 0
      totals.income += cashFlowTotal.income ? cashFlowTotal.income : 0
      totals.netIncome += cashFlowTotal.netIncome ? cashFlowTotal.netIncome : 0
      totals.debt += cashFlowTotal.debt ? cashFlowTotal.debt : 0
      // @ts-ignore
      totals.debtProceeds += cashFlowTotal.debtProceeds ? cashFlowTotal.debtProceeds : 0
      totals.equity += cashFlowTotal.equity ? cashFlowTotal.equity : 0
      totals.unleveredTotal += cashFlowTotal.unleveredTotal ? cashFlowTotal.unleveredTotal : 0
      totals.leveredTotal += cashFlowTotal.leveredTotal ? cashFlowTotal.leveredTotal : 0
      totals.retainedEarnings += cashFlowTotal.retainedEarnings ? cashFlowTotal.retainedEarnings : 0
      totals.totalValue += cashFlowTotal.totalValue ? cashFlowTotal.totalValue : 0
      // @ts-ignore
      totals.grants += cashFlowTotal.grants ? cashFlowTotal.grants : 0
      totals.netSourcesUses += cashFlowTotal.netSources ? cashFlowTotal.netSources : 0
      totals.netSourcesUses -= cashFlowTotal.netUses ? cashFlowTotal.netUses : 0
    }
  }
  return totals
}

const getActualAndForecastToolTipValues = (value: number, cashFlowTotal: T.ValueOf<CashFlowTotals>, property: string) => {
  return (
    <div>
      {cashFlowTotal ? (
        <WithToolTip
          tooltip={
            <div>
              {cashFlowTotal.actual ? (
                <div>
                  {`${t("labels.actual")}: ${FinanceUtils.formatCurrency(
                    cashFlowTotal.actual[property as keyof typeof cashFlowTotal.actual] ? cashFlowTotal.actual[property as keyof typeof cashFlowTotal.actual] : 0,
                  )}`}
                </div>
              ) : null}
              {cashFlowTotal.forecast ? (
                <div>
                  {`${t("labels.forecast")}: ${FinanceUtils.formatCurrency(
                    cashFlowTotal.forecast[property as keyof typeof cashFlowTotal.actual] ? cashFlowTotal.forecast[property as keyof typeof cashFlowTotal.forecast] : 0,
                  )}`}
                </div>
              ) : null}
            </div>
          }
        >
          {FinanceUtils.formatCurrency(value)}
        </WithToolTip>
      ) : null}
    </div>
  )
}

export default function ProjectTableView(props: Props) {
  const { projects, cashFlowTotals } = props

  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalCellId, setModalCellId] = React.useState("")
  const [selectedIcons, setSelectedIcons] = React.useState<any>([])
  const [selectedIcon, setSelectedIcon] = React.useState<any>({})

  const [showInput, setShowInput] = React.useState<boolean>(false)
  const [iconTooltip, setIconTooltip] = React.useState<string>("")
  const [inputError, setInputError] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(false)
  const [showDeletePopup, setShowDeletePopup] = React.useState(false)
  const [iconToDelete, setIconToDelete] = React.useState<IconProps | undefined>(undefined)
  const [rowId, setRowId] = React.useState<number | undefined>(undefined)

  const data = projects.map((project: any, i) => {
    const cashFlowTotal = cashFlowTotals ? cashFlowTotals[project.code] : undefined
    return {
      ...project,
      id: i,
      cashFlowTotal,
      icons: project.icons ? getCorrespondingIcons(project.icons) : [],
    }
  })
  const [tableData, setTableData] = React.useState(data)

  const totals = React.useMemo(() => {
    return calculateTotals(projects, cashFlowTotals)
  }, [tableData])

  function handleCellContextMenu(event: React.MouseEvent, rowId: string) {
    event.preventDefault()
    setModalOpen(true)
    setModalCellId(rowId)
    const rowData = tableData.find((table: any) => table.id === rowId)
    setSelectedIcons(rowData.icons)
  }

  function getCorrespondingIcons(icons: { iconName: string; meaning: string; tooltip: string }[]) {
    if (!icons.length) return []
    return icons.map((icon) => {
      const correspondingIconName: string = IconMeaning[icon.meaning as keyof typeof IconMeaning]
      const iconName = correspondingIconName
      if (correspondingIconName) {
        const foundIcon = definedIcons.find((rIcon: IconProps) => rIcon.iconName === iconName)
        return { ...foundIcon, tooltip: icon.tooltip } || icon
      } else {
        return { ...missingIcon, tooltip: icon.tooltip } || icon
      }
    })
  }

  const handleIconClick = (selectedIcon: IconProps) => {
    const { meaning, iconName, type } = selectedIcon
    const newIcon = { meaning, iconName, tooltip: iconTooltip }
    if (!showInput) {
      setSelectedIcons((prevState: any) => {
        const isIconAlreadyAdded = prevState.some((prevIcon: IconProps) => prevIcon.iconName === iconName)
        const versionAlreadyExist = prevState.some((icon: IconProps) => icon.type === type)
        const icons = getCorrespondingIcons([...prevState, newIcon])
        if (isIconAlreadyAdded || versionAlreadyExist) {
          setShowAlert(true)

          setTimeout(() => {
            setShowAlert(false)
          }, 3000)

          return prevState
        } else {
          setShowInput(true)
          setSelectedIcon(selectedIcon)
          return icons
        }
      })
    }
  }

  const handleInputChange = (value: string) => {
    const limitedText = Utils.limitInputtextWords(value, 50)
    setIconTooltip(limitedText)
    setInputError(limitedText !== value)
  }

  const handleSubmitTooltip = () => {
    const { meaning, iconName } = selectedIcon
    const newIcon = { meaning, iconName, tooltip: iconTooltip }
    if (iconTooltip.trim() !== "") {
      setShowInput(false)
      const updatedTableData = tableData.map((row: ProjectTableRow) => {
        if (row.id === parseInt(modalCellId)) {
          const isIconAlreadyAdded = row.icons.length && row.icons.some((prevIcon: IconProps) => prevIcon.meaning === meaning)
          const updatedRow = {
            ...row,
            icons: isIconAlreadyAdded ? row.icons : [...row.icons.map((i: IconProps) => ({ meaning: i.meaning, iconName: i.iconName, tooltip: i.tooltip })), newIcon],
          }

          const rowIcons = getCorrespondingIcons(updatedRow.icons)
          const project = projects.find((p) => p.code === updatedRow.code)
          const payload = { formData: { ...project, icons: updatedRow.icons, updated: "" } }
          ProjectAdminService.editProject(updatedRow.code, payload, "Submit icons")
            .then((data) => {
              Logger.info("Edit Project Success:", data)
            })
            .catch((err) => {
              Logger.error("Edit Project Error:", err)
            })

          return { ...updatedRow, icons: rowIcons }
        }
        return row
      })
      setTableData(updatedTableData)
      setIconTooltip("")
    }
  }

  const handleIconRemove = (selectedIcon: IconProps, rowId?: number) => {
    const updatedTableData = tableData.map((row: any) => {
      const project = projects.find((p) => p.code === row.code)
      if (project && row.id === rowId) {
        const updatedIcons = row.icons.filter((icon: IconProps) => icon.iconName !== selectedIcon.iconName)
        const payload = { formData: { ...project, icons: updatedIcons, updated: "" } }
        ProjectAdminService.editProject(row.code, payload, "defafa")
          .then((data) => {
            Logger.info("Edit Project Success:", data)
          })
          .catch((err) => {
            Logger.error("Edit Project Error:", err)
          })

        return {
          ...row,
          icons: updatedIcons,
        }
      }
      return row
    })

    setSelectedIcons((prevSelected: any) => prevSelected.filter((i: IconProps) => i.iconName !== selectedIcon.iconName))
    setTableData(updatedTableData)
    setShowDeletePopup(false)
  }

  const closeModal = () => {
    setIconTooltip("")
    setShowInput(false)
    setModalOpen(false)
  }

  const handleDeleteModal = (event: React.MouseEvent, iconItem: IconProps, rowId: number) => {
    event.preventDefault()
    setShowDeletePopup(true)
    setIconToDelete(iconItem)
    setRowId(rowId)
  }

  const closeDeleteModal = () => {
    setShowDeletePopup(false)
  }

  React.useEffect(() => {
    const newData = projects.map((project: any, i) => {
      const cashFlowTotal = cashFlowTotals ? cashFlowTotals[project.code] : undefined
      return {
        ...project,
        id: i,
        cashFlowTotal,
        icons: project.icons ? getCorrespondingIcons(project.icons) : [],
      }
    })
    setTableData(newData)
  }, [projects, cashFlowTotals])

  const renderIcons = (rowData: ProjectTableRow) => {
    
    if(rowData.icons.length === 0){
      return (
        <div className="icon-list-container"> 
          <div id={`icon-default-${rowData.id}`}>
              {defaultIcon.icon}
          </div>
          <UncontrolledTooltip target={`icon-default-${rowData.id}`} placement="top">
            {defaultIcon.meaning}
          </UncontrolledTooltip>
        </div>
      )
    }

    return (
      <div className="icon-list-container">
        {rowData?.icons.map((iconItem: IconProps) => (
          <React.Fragment key={iconItem.iconName}>
            <div id={`icon-${iconItem.iconName}-${rowData.id}`} onContextMenu={(e) => handleDeleteModal(e, iconItem, rowData.id)}>
              {iconItem.icon}
            </div>
            {iconItem.tooltip && (
              <UncontrolledTooltip target={`icon-${iconItem.iconName}-${rowData.id}`} placement="top">
                {iconItem.tooltip}
              </UncontrolledTooltip>
            )}
          </React.Fragment>
        ))}
      </div>
    )
  }

  return (
    <>
      <div style={{ height: "100%"}} className="finance-table">
        <Table
          defaultColumn={{ accessorKey: "id", size: undefined }}
          data={tableData}
          columns={[
            {
              id: "code",
              accessorKey: "code",
              header: `${t("table_headings.code")}`,
              size: 50,
              footer: `${t("table_headings.total")}`,
            },
            {
              id: "name",
              accessorKey: "name",
              header: `${t("labels.name")}`,
              size: 250,
            },
            {
              id: "dcSize",
              accessorFn: (row) => row.dcSize,
              header: `${t("table_headings.dc_size")} (kW)`,
              cell: (info) => <div>{EnergyUtils.formatNumber(info.getValue(), { maximumFractionDigits: 2 })}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{EnergyUtils.formatNumber(totals.dcSize)}</div>,
            },
            {
              id: "fixedAssets",
              accessorFn: (row) => row.cashFlowTotal?.asset,
              header: `${t("table_headings.fixed_asset")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "asset")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.asset)}</div>,
            },
            {
              id: "expense",
              accessorFn: (row) => row.cashFlowTotal?.expense,
              header: `${t("economics.labels.Expense")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "expense")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.expense)}</div>,
            },
            {
              id: "income",
              accessorFn: (row) => row.cashFlowTotal?.income,
              header: `${t("economics.labels.Revenue")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "income")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.income)}</div>,
            },
            {
              id: "netIncome",
              accessorFn: (row) => row.cashFlowTotal?.netIncome,
              header: `${t("economics.labels.Net Income")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "netIncome")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.netIncome)}</div>,
            },
            {
              id: "debtProceeds",
              // @ts-ignore
              accessorFn: (row) => row.cashFlowTotal?.debtProceeds,
              header: `${t("economics.labels.Debt Proceeds")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "debtProceeds")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.debtProceeds)}</div>,
            },
            {
              id: "equity",
              accessorFn: (row) => row.cashFlowTotal?.equity,
              header: `${t("economics.labels.Equity")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "equity")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.equity)}</div>,
            },
            {
              id: "grants",
              // @ts-ignore
              accessorFn: (row) => row.cashFlowTotal?.grants,
              header: `${t("economics.labels.Grants")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "grants")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.grants)}</div>,
            },
            {
              id: "netSourcesUses",
              accessorFn: (row) => (row.cashFlowTotal?.netSources ? row.cashFlowTotal.netSources : 0) - (row.cashFlowTotal?.netUses ? row.cashFlowTotal?.netUses : 0),
              header: "Net Upfront S&U",
              cell: (info) => <div>{FinanceUtils.formatCurrency(info.getValue())}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.netSourcesUses)}</div>,
            },
            {
              id: "icons",
              accessorFn: (row) => row,
              header: "Q",
              cell: (info: any) => {
                const rowData = tableData.find((row: any) => row?.id === parseInt(info.row.id))
                return (
                  <React.Fragment>
                    {renderIcons(rowData)}
                  </React.Fragment>
                )
              },
              meta: {
                align: "center",
                tooltip: { text: `${t("economics.tooltips.qualitative_icons")}`, placement: "bottom" },
              },
              footer: () => <div></div>,
            },
            {
              id: "retainedEarnings",
              accessorFn: (row) => row.cashFlowTotal?.retainedEarnings,
              header: `${t("economics.labels.Retained Earnings")}`,
              cell: (info) => <div>{FinanceUtils.formatCurrency(info.getValue())}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.retainedEarnings)}</div>,
            },
            {
              id: "totalValue",
              accessorFn: (row) => row.cashFlowTotal?.totalValue,
              header: `${t("economics.labels.Total Value")}`,
              cell: (info) => <div>{FinanceUtils.formatCurrency(info.getValue())}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.totalValue)}</div>,
            },
            {
              id: "unleveredCashFlow",
              accessorFn: (row) => row.cashFlowTotal?.unleveredTotal,
              header: `${t("economics.labels.Unlevered Cash Flow")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "unleveredTotal")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.unleveredTotal)}</div>,
            },
            {
              id: "leveredCashFlow",
              accessorFn: (row) => row.cashFlowTotal?.leveredTotal,
              header: `${t("economics.labels.Levered Cash Flow")}`,
              cell: (info) => <div>{getActualAndForecastToolTipValues(info.getValue() as number, info.row.original.cashFlowTotal as CashFlowTotals, "leveredTotal")}</div>,
              meta: {
                align: "center",
              },
              footer: () => <div>{FinanceUtils.formatCurrency(totals.leveredTotal)}</div>,
            },
            {
              id: "unleveredIrr",
              accessorFn: (row) => row.cashFlowTotal?.unleveredIrr,
              header: `${t("economics.labels.Unlevered IRR")}`,
              cell: (info) => (
                <div>
                  <WithToolTip tooltip={`${t("economics.tooltips.value_calculation_explained")}`}>{info.getValue() || info.getValue() === 0 ? info.getValue() + "%" : null}</WithToolTip>
                </div>
              ),
              meta: {
                align: "center",
              },
            },
            {
              id: "leveredIrr",
              accessorFn: (row) => row.cashFlowTotal?.leveredIrr,
              header: `${t("economics.labels.Levered IRR")}`,
              cell: (info) => (
                <div>
                  <WithToolTip tooltip={`${t("economics.tooltips.value_calculation_explained")}`}>{info.getValue() || info.getValue() === 0 ? info.getValue() + "%" : null}</WithToolTip>
                </div>
              ),
              meta: {
                align: "center",
              },
            },
            {
              id: "updated",
              accessorFn: (row) => row.cashFlowTotal?.timestamp,
              header: `${t("table_headings.updated")}`,
              cell: (info) => {
                return <div className={isCashFlowOutOfDate(info.row.original.code, cashFlowTotals) ? "out-of-date" : ""}>{cashFlowTotals ? moment(info.getValue() as string).format("lll") : null}</div>
              },
              size: 170,
            },
          ]}
          handleCellContextMenu={handleCellContextMenu}
        />
      </div>
      {(modalOpen && props.hasDataWriteAccess) && (
        <IconListModal
          handleIconClick={handleIconClick}
          closeModal={closeModal}
          inputError={inputError}
          icons={definedIcons}
          onHide={() => setModalOpen(false)}
          selectedIcons={selectedIcons}
          showInput={showInput}
          iconTooltip={iconTooltip}
          handleSubmitTooltip={handleSubmitTooltip}
          handleInputChange={handleInputChange}
          showAlert={showAlert}
        />
      )}
      {showDeletePopup && <ConfirmDeleteModal iconToDelete={iconToDelete} rowId={rowId} showDeletePopup={showDeletePopup} closeDeleteModal={closeDeleteModal} handleIconRemove={handleIconRemove} />}
      <UncontrolledTooltip target="netSourcesUses">{t("economics.tooltips.net_sources_uses")}</UncontrolledTooltip>
    </>
  )
}
