import moment from "moment-timezone"

import Aggregations from "@common/Aggregations"

const GraphUtils = {
  dateFormat(time, range, aggregation) {
    const start = range.start ? range.start : moment(range.localStartDate)
    const end = range.end ? range.end : moment(range.localEndDate)
    if (aggregation === Aggregations.Year) {
      if (moment(time).isBefore(start)) {
        return `${start.format("DD MMM")} - ${moment(time).endOf(aggregation).format("DD MMM YYYY")}`
      }
      if (moment(time).add(1, aggregation).isAfter(end)) {
        return `${moment(time).format("DD MMM")} - ${end.subtract(1, "day").format("DD MMM YYYY")}`
      }
    } else if (aggregation === Aggregations.Month) {
      if (moment(time).isBefore(start)) {
        return `${start.format("DD")} - ${moment(time).endOf(aggregation).format("DD MMM")}`
      }
      if (moment(time).add(1, aggregation).isAfter(end)) {
        return `${moment(time).format("DD")} - ${end.subtract(1, "day").format("DD MMM")}`
      }
    }

    return moment(time).format(GraphUtils.getFormatForAggregation(aggregation))
  },

  getFormatForAggregation(aggregation) {
    switch (aggregation) {
      case Aggregations.Year:
        return "YYYY"
      case Aggregations.Month:
        return "MMM"
      case Aggregations.Day:
        return "MMM DD"
      case Aggregations.Hour:
      case Aggregations.FifteenMinute:
      case Aggregations.FiveMinute:
        return "HH:mm"
      case Aggregations.None:
        return "HH:mm:ss"
      default:
        return "lll"
    }
  },

  formatDateTooltip(time, localRange, aggregation) {
    const start = moment(localRange.localStartDate)
    const end = moment(localRange.localEndDate)

    switch (aggregation) {
      case Aggregations.Year:
        if (moment(time).isBefore(start)) {
          return `${start.format("DD MMM")} - ${moment(time).endOf(aggregation).format("DD MMM YYYY")}`
        }
        if (moment(time).add(1, aggregation).isAfter(end)) {
          return `${moment(time).format("DD MMM")} - ${end.subtract(1, "day").format("DD MMM YYYY")}`
        }

        return moment(time).format("YYYY")
      case Aggregations.Month:
        if (moment(time).isBefore(start)) {
          return `${start.format("DD")} - ${moment(time).endOf(aggregation).format("DD MMM YYYY")}`
        }
        if (moment(time).add(1, aggregation).isAfter(end)) {
          return `${moment(time).format("DD")} - ${end.subtract(1, "day").format("DD MMM YYYY")}`
        }
        return moment(time).format("MMM YYYY")
      case Aggregations.Day:
        return moment(time).format("MMM DD, YYYY")
      default:
        return moment(time).format("lll")
    }
  },
}

export default GraphUtils
