import React from "react"

import prettyBytes from "src/pretty-bytes"

import { MapView } from "@common/module/EcosuiteView"
import { ProjectMarker, ProjectRadiusMarker } from "@dashboard/ProjectMap"

export default class ConnectivityMapView extends MapView {
  getMapMarkers() {
    let totalData = 0

    if (this.props.connectivity) {
      totalData = Object.values(this.props.connectivity.projects).reduce((total, project) => {
        return (total += project.total)
      }, 0)
    }

    return this.props.projects == null
      ? []
      : this.props.projects.map((project) => {
          let projectData = this.props.connectivity ? this.props.connectivity.projects[project.code] : null
          let projectExpectedDailyData = Object.values(project.sites).reduce((total, site) => {
            return (total += site.expectedDailyData ? site.expectedDailyData : 0)
          }, 0)
          return project.lat && project.long ? (
            <React.Fragment key={project.code}>
              <ConnectivityProjectMarker project={project} projectData={projectData} />
              {this.props.connectivity && this.props.connectivity.projects[project.code] ? (
                <ConnectivityRadiusMarker project={project} projectData={projectData} projectExpectedDailyData={projectExpectedDailyData} totalData={totalData} />
              ) : null}
            </React.Fragment>
          ) : null
        })
  }
}

class ConnectivityProjectMarker extends ProjectMarker {
  renderProjectInfo() {
    return (
      <React.Fragment>
        <div>{"Project: " + this.props.project.name}</div>
        {this.props.projectData ? <div>{"Data: " + prettyBytes(this.props.projectData.total)}</div> : null}
      </React.Fragment>
    )
  }
}

class ConnectivityRadiusMarker extends ProjectRadiusMarker {
  getColor() {
    return this.props.projectData && this.props.projectData.total < this.props.projectExpectedDailyData ? "red" : "#0EB6DB"
  }

  calculateRadius() {
    return (Math.abs(this.props.projectData.total) / this.props.totalData) * 100 + 12
  }
}
