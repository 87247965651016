import React from "react"
import moment from "moment-timezone"

import { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"

import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"
import DateRangeGraph from "../DateRangeGraph"
import { EnergyGraphTooltip } from "../EnergyGraphTooltip"
import GraphUtils from "@common/utils/GraphUtils"
import Utils from "@common/utils/Utils"
import i18n from "src/i18n"

const { t } = i18n
export default class ConsumptionGenerationBarGraph extends DateRangeGraph {
  renderContent() {
    if (this.props.graphDatums) {
      let data = Object.keys(this.props.graphDatums).map((dateKey) => {
        let entry = this.props.graphDatums[dateKey]
        let date = moment(dateKey)

        return {
          date: date.toDate(),
          time: date.toDate().getTime(),
          name: date.format("lll"),
          predictedConsumption:
            this.props.predictedConsumptionDatums && this.props.predictedConsumptionDatums[dateKey] ? this.round(this.props.predictedConsumptionDatums[dateKey].predictedConsumption) : null,
          consumption: this.round(entry.consumption),
          max: entry.max,
          meteredCost: this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey] ? this.props.consumptionCostDatums[dateKey].meteredCost : null,
          fixedCost: this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey] ? this.props.consumptionCostDatums[dateKey].fixedCost : null,
        }
      })

      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"
      return (
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
            <XAxis
              dataKey="time"
              domain={this.getDomain(this.props.range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis />
            {this.props.showConsumption ? (
              <YAxis
                yAxisId="cost"
                orientation="right"
                tickFormatter={(amount) => {
                  return Utils.formatCurrency(amount)
                }}
              />
            ) : null}
            <Tooltip content={<EnergyGraphTooltip assets={this.props.assets} getDataTitle={getDataTitle} />} range={this.props.range} aggregation={this.props.aggregation} />
            <Legend iconType="plainline" />
            {this.props.showConsumption ? (
              <Bar name={getDataTitle("consumption")} type={graphType} dataKey="consumption" stroke={GRAPH_COLORS[0]} fill={GRAPH_COLORS[0]} dot={aggregation === "day"} />
            ) : null}
            {this.props.showConsumption ? (
              <Bar name={getDataTitle("predictedConsumption")} type={graphType} dataKey="predictedConsumption" stroke={GRAPH_COLORS[1]} fill={GRAPH_COLORS[1]} dot={aggregation === "day"} />
            ) : null}
            {this.props.showConsumption ? (
              <Line name={getDataTitle("meteredCost")} type={graphType} dataKey="meteredCost" stroke={GRAPH_COLORS[2]} fill={"none"} strokeDasharray="2 2" dot={false} strokeWidth={2} yAxisId="cost" />
            ) : null}
            {this.props.showConsumption ? (
              <Line name={getDataTitle("fixedCost")} type={graphType} dataKey="fixedCost" stroke={GRAPH_COLORS[3]} fill={"none"} strokeDasharray="4 4" dot={false} strokeWidth={2} yAxisId="cost" />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      )
    }

    return <Loading />
  }
}

export var getDataTitle = function (name) {
  switch (name) {
    case "predictedConsumption":
      return t("dataTitles.predicted_consumption")
    case "consumption":
      return t("dataTitles.consumption")
    case "consumptionCost":
      return t("dataTitles.consumption_cost")
    case "meteredCost":
      return t("dataTitles.metered_cost")
    case "demandCharge":
      return t("dataTitles.demand_charge")
    case "fixedCost":
      return t("dataTitles.fixed_cost")
    default:
      return name
  }
}
