import React from "react"
import moment from "moment-timezone"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, Legend, Line, ComposedChart } from "recharts"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import { Loading } from "@common/EcosuiteComponent"
import ProjectUtils from "@common/utils/ProjectUtils"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import Utils from "@common/utils/Utils"

export class HistoricRates extends DateRangeGraph {
  renderContent() {
    if (this.props.lineItem) {
      const start = ProjectUtils.getProductionStartDate([this.props.project])
      const end = moment(this.props.month).add(1, "month")
      const data = this.props.lineItem.historic.map((historicReading) => {
        historicReading.time = moment(historicReading.month).toDate().getTime()
        historicReading.kiloWattHours = EnergyUtils.convertToKilo(historicReading.generation)
        return historicReading
      })

      const previous = {}
      if (this.props.lineItem.previous) {
        Object.assign(previous, this.props.lineItem.previous)
        previous.kiloWattHours = EnergyUtils.convertToKilo(previous.generation)
        previous.time = moment(this.props.lineItem.previous.month).toDate().getTime()
      }

      data.push(previous)

      const current = {}
      Object.assign(current, this.props.lineItem.current)
      current.kiloWattHours = EnergyUtils.convertToKilo(current.generation)
      current.time = moment(this.props.lineItem.current && this.props.lineItem.current.month)
        .toDate()
        .getTime()

      data.push(current)

      return (
        <ResponsiveContainer width="100%" height={150}>
          <ComposedChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }}>
            <XAxis
              dataKey="time"
              domain={[start.valueOf(), end.valueOf()]}
              tickFormatter={(time) => {
                return moment(time).format("MMM YYYY")
              }}
              scale="time"
              type="number"
            />
            <YAxis />
            <YAxis
              yAxisId="amount"
              orientation="right"
              tickFormatter={(amount) => {
                return Utils.formatCurrency(amount)
              }}
            />

            <Tooltip
              wrapperStyle={{ color: "#000", pointerEvents: "auto" }}
              // eslint-disable-next-line no-unused-vars
              formatter={(value, name, props) => {
                if (name === "Generated") {
                  return EnergyUtils.formatKiloWattHours(value)
                } else if (name === "Estimated Bill") {
                  return `${Utils.formatCurrency(value)} @ ${Utils.formatCurrency(props.payload.rate)}/kWh`
                } else {
                  return value
                }
              }}
              labelFormatter={(label) => {
                return moment(label).format("MMM YYYY")
              }}
            />

            <Bar dataKey={"kiloWattHours"} fill={GRAPH_COLORS[0]} name="Generated" />
            <Line dataKey={"amount"} yAxisId="amount" stroke={GRAPH_COLORS[2]} name="Estimated Bill" />

            <Legend />
          </ComposedChart>
        </ResponsiveContainer>
      )
    }
    return <Loading />
  }
}
