import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"

import { Loading, Warning } from "@common/EcosuiteComponent"

import EventModule from "./module/event/EconodeEventModule"
import EnergyModule from "./module/energy/EconodeEnergyModule"
import EconomicsModule from "./module/economics/EconodeEconomicsModule"

export default class Content extends Component {
  constructor(props) {
    super(props)
    this.renderEventModule = this.renderEventModule.bind(this)
    this.renderEnergyModule = this.renderEnergyModule.bind(this)
    this.renderEconomicsModule = this.renderEconomicsModule.bind(this)
  }

  renderEventModule() {
    return (
      <EventModule
        projects={this.props.projects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        moduleViews={this.props.moduleViews["event"]}
      />
    )
  }

  renderEnergyModule() {
    return (
      <EnergyModule
        projects={this.props.projects}
        filteredProjects={this.props.filteredProjects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        moduleViews={this.props.moduleViews["energy"]}
        updateProject={this.props.updateProject}
      />
    )
  }

  renderEconomicsModule() {
    return (
      <EconomicsModule
        projects={this.props.projects}
        project={this.props.project}
        loadTime={this.props.loadTime}
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        actions={this.props.actions}
        userId={this.props.userId}
        moduleViews={this.props.moduleViews["finance"]}
      />
    )
  }

  renderNotFoundRoute() {
    return <Warning title="Notice" message="Unauthorised Access, you may need to contact an Administrator to grant you access" />
  }

  render() {
    if (this.props.moduleViews) {
      // Note we render the energy module by default, this allows us to handle AMS users switching to the Econode view while on other paths
      return (
        <div className="App-module">
          <Switch>
            {this.props.groups.includes("event") ? <Route path="/events" render={this.renderEventModule} /> : null}
            <Route path="/economics" render={this.renderEconomicsModule} />
            <Route path="*" render={this.renderEnergyModule} />
          </Switch>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
