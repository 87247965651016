import React, { Component } from "react"

import { Route } from "react-router-dom"

import { Loading } from "@common/EcosuiteComponent"
import DocumentService from "@dashboard/data/DocumentService"
import RecordDocumentPreview from "@dashboard/data/record/documents/RecordDocumentPreview"
import { getUserOrganizationId } from "@common/OrganizationUtils"

class Document extends Component {
  constructor(props) {
    super(props)

    this.state = {
      previewDocument: null,
      loading: false,
    }

    this.parseFileName = this.parseFileName.bind(this)
    this.renderDocument = this.renderDocument.bind(this)
  }

  /**
   * Try to parse a file name as a URI component.
   * @param documentKey - The document key to parse.
   * @returns {string}
   */
  parseFileName(documentKey) {
    let parsedDocumentKey = documentKey

    try {
      // We're expecting that the URI could be encoded.
      //
      // Try decoding it. If it doesn't work, then it may have already been decoded.
      parsedDocumentKey = decodeURIComponent(documentKey)
    } catch (e) {
      // Skip.
    }

    return parsedDocumentKey
  }

  async viewFile(parsedUrl) {
    const userOrgId = await getUserOrganizationId()

    if (!parsedUrl.startsWith(userOrgId)) {
      parsedUrl = userOrgId + "/" + parsedUrl
    }

    const documentParts = parsedUrl.split("/")
    DocumentService.viewFile(parsedUrl).then((url) => {
      this.setState({ previewDocument: { documentUrl: url, documentName: documentParts[documentParts.length - 1] } })
    })
  }

  render() {
    return (
      <React.Fragment>
        <Route path="/document/:path+" render={this.renderDocument} />
      </React.Fragment>
    )
  }

  renderDocument({ match }) {
    const parsedUrl = this.parseFileName(match.params.path)

    if (this.state && this.state.previewDocument) {
      return (
        <div className={"standalone-preview"}>
          <RecordDocumentPreview previewDocument={this.state.previewDocument} />
        </div>
      )
    } else if (this.state && !this.state.loading) {
      this.setState({ loading: true })
      this.viewFile("records/" + parsedUrl)
    } else {
      return <Loading />
    }
  }
}

export default Document
