import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"
import { StoredProjectChecklist } from "@dashboard/process/views/checklists/ChecklistView"

/**
 * Get the project checklist schema.
 * @param code - The code.
 * @param checklistType - The checklist type.
 */
export const getProjectChecklistSchema = (code: string, checklistType: string): Promise<Schema> => {
  const uri = `/schema/project-checklist/${code}/${checklistType}`
  return API.get(uri)
    .then((response) => {
      const data = response as { schema: unknown }
      return data.schema
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Get all project checklists of code.
 * @param code - The code.
 */
export const getCodesProjectChecklist = (code: string): Promise<StoredProjectChecklist[]> => {
  const uri = `/checklist/code/project-checklist/${code}`
  return API.get(uri)
    .then((response) => {
      const data = response as { checklists: StoredProjectChecklist[] }
      return data.checklists
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Overwrite a project checklist.
 * @param code - The code.
 * @param checklistType - The checklist type.
 * @param schema - The updated project checklist schema.
 */
export const overwriteProjectChecklist = (code: string, checklistType: string, schema: unknown): Promise<StoredProjectChecklist> => {
  const uri = `/checklist/project-checklist/${code}/${checklistType}`
  return API.put(uri, schema)
    .then((response) => {
      const data = response as { checklist: StoredProjectChecklist }
      return data.checklist
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to put '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Extract fields (notes, data, isChecked, record) from a given node.
 */
export function extractFields(node: Record<string, any>) {
  const result: Record<string, any> = {}

  const props = node.properties || {}

  // Handle notes
  if (props.notes && props.notes.default !== undefined && props.notes.default !== "") {
    result.notes = props.notes.default
  }

  // Handle data
  if (props.data && props.data.default !== undefined && props.data.default !== "") {
    result.data = props.data.default
  }

  // Handle isChecked (if no default, false)
  if (props.isChecked && props.isChecked.default !== undefined) {
    result.isChecked = props.isChecked.default
  } else {
    result.isChecked = false
  }

  // Handle record
  if (props.record && props.record.default !== undefined && props.record.default !== "") {
    result.record = props.record.default
  }

  return result
}

/**
 * Recursively traverse the schema.
 */
export function convertSchemaToChecklistFormData(schema: Record<string, any>) {
  const result: Record<string, any> = {}

  if (!schema.properties) {
    return result
  }

  for (const key in schema.properties) {
    const val = schema.properties[key]
    if (val.properties) {
      const subKeys = Object.keys(val.properties)
      const isLeaf = subKeys.some((k) => ["notes", "data", "isChecked", "record"].includes(k))

      if (isLeaf) {
        result[key] = extractFields(val)
      } else {
        const childResult = convertSchemaToChecklistFormData(val)
        if (Object.keys(childResult).length > 0) {
          result[key] = childResult
        } else {
          result[key] = { isChecked: false }
        }
      }
    } else {
      result[key] = { isChecked: false }
    }
  }

  return result
}
