import React from "react"

import EnergyView from "./EnergyView"

import PowerUserContent from "src/poweruser/PowerUserContent"
import DateRangeUtils from "@common/utils/DateRangeUtils"

export default class EnergyProjectPowerDashboardView extends EnergyView {
  renderMainView() {
    return (
      <div>
        <PowerUserContent projects={[this.props.project]} range={this.props.range} aggregation={DateRangeUtils.getAggregateForRange(this.props.range)} />
      </div>
    )
  }
}
