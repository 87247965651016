import React from "react"
import { Row, Col } from "reactstrap"
import jsprim from "jsprim"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import PortfolioForm from "./PortfolioForm"
import ReportTable from "./ReportTable"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
class PortfolioDetails extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.portfolioChanged = this.portfolioChanged.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.setStateIfMounted({ portfolio: this.props.portfolio })
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.portfolio !== prevProps.portfolio) {
      this.setStateIfMounted({ portfolio: this.props.portfolio })
    }
  }

  isModified() {
    return this.state.portfolio && !jsprim.deepEqual(this.state.portfolio, this.props.portfolio)
  }

  portfolioChanged(portfolio) {
    // We keep track of the portfolio changes from the form so that we can warn when closing the dialog
    this.setStateIfMounted({ portfolio: portfolio })
  }

  renderCloseDialog() {
    return (
      <div className={"item-details-title " + (this.props.portfolio.id ? "item-edit-title" : "item-create-title")}>
        <h2>
          {this.props.readonly
            ? `${t("settings.detailsTitles.viewPortfolio")}`
            : this.props.portfolio.id
              ? `${t("settings.detailsTitles.editPortfolio")}`
              : `${t("settings.detailsTitles.createPortfolio")}`}
          {this.props.portfolio.id ? <span className="header-id">{this.props.portfolio.id}</span> : null}
          <div
            className="float-end"
            onClick={() => {
              if (this.props.readonly || !this.isModified() || window.confirm("settings.messages.confirm_save")) {
                this.props.actions.selectPortfolio()
              }
            }}
          >
            <Icon icon="close" className="close-button" />
          </div>
        </h2>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }
    return (
      <React.Fragment>
        {this.state.previewDocument ? this.renderPreview() : null}

        <div className="item-details" style={{ display: this.state.previewDocument ? "none" : "block" }}>
          {this.renderCloseDialog()}

          <div className="item-details-content record-content">
            <Row>
              <Col sm={8}>
                <PortfolioForm portfolio={this.props.portfolio} projects={this.props.projects} readonly={this.props.readonly} portfolioChanged={this.portfolioChanged} actions={this.props.actions} />
              </Col>
              <Col sm={4} className="side-panel">
                <ReportTable portfolio={this.props.portfolio} />
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PortfolioDetails
