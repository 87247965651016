import React from "react"
import { useForm, Controller } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { Button } from "src/components/ui/button"
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "src/components/ui/card"
import { Checkbox } from "src/components/ui/checkbox"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select"
import { Input } from "src/components/ui/input"
import { Label } from "src/components/ui/label"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/form"
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "src/components/ui/accordion"
import UserAdminService from "./UserAdminService"
import { availableGroups } from "./ExternalUsers"
import { ExternalUser, ExternalUserUpdate, updateExternalUserApi } from "src/services/users"
import { Settings, ChevronDown } from "lucide-react"

interface EditUserFormData {
  accessPolicies: Record<string, boolean>
  userTypeId: string
}

interface UserType {
  id: string
  name: string
  defaultGroups: string[]
}

interface EditExternalUserProps {
  user: ExternalUser | null
  onClose: () => void
}

const EditExternalUser: React.FC<EditExternalUserProps> = ({ user, onClose }) => {
  const form = useForm<EditUserFormData>({
    defaultValues: {
      accessPolicies: {},
      userTypeId: user?.userType.id || "",
    },
  })
  const [userTypes, setUserTypes] = React.useState<UserType[]>([])
  const queryClient = useQueryClient()

  React.useEffect(() => {
    async function getUserTypes() {
      const fetchedUserTypes = (await UserAdminService.getUserTypes()) as {
        userTypes: UserType[]
      }
      setUserTypes(fetchedUserTypes.userTypes)
    }
    getUserTypes()
  }, [])

  // Update accessPolicies when userTypeId changes
  React.useEffect(() => {
    if (user && userTypes.length > 0) {
      const selectedUserType = userTypes.find((type) => type.id === form.getValues("userTypeId"))
      if (selectedUserType) {
        const defaultPolicies = availableGroups.reduce(
          (acc, group) => ({
            ...acc,
            [group]: selectedUserType.defaultGroups.includes(group),
          }),
          {},
        )
        form.setValue("accessPolicies", defaultPolicies)
      }
    }
  }, [form.watch("userTypeId"), user, userTypes])

  React.useEffect(() => {
    if (user && userTypes.length > 0) {
      form.reset({
        accessPolicies: availableGroups.reduce(
          (acc, group) => ({
            ...acc,
            [group]: user.accessPolicies.includes(group),
          }),
          {},
        ),
        userTypeId: user.userType.id,
      })
    }
  }, [user, userTypes])

  const mutation = useMutation(updateExternalUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(["externalUsers"])
      toast.success("Successfully updated external user!")
      onClose()
    },
    onError: (error) => {
      toast.error("Something went wrong while updating the user!")
      console.error("Error updating external user:", error)
    },
  })

  const onSubmit = (data: EditUserFormData) => {
    if (!user) return

    const selectedPolicies = Object.entries(data.accessPolicies)
      .filter(([_, isChecked]) => isChecked)
      .map(([policy]) => policy)

    const payload: ExternalUserUpdate = {
      userId: user.userId,
      updates: {
        accessPolicies: selectedPolicies,
        userType: {
          id: data.userTypeId,
          name: userTypes.find((ut) => ut.id === data.userTypeId)?.name || "",
        },
      },
    }

    mutation.mutate(payload)
  }

  if (!user) return null

  return (
    <Card className="tw-bg-background tw-text-foreground tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg">
      <CardHeader className="tw-border-b tw-border-gray-200">
        <CardTitle className="tw-text-xl tw-font-semibold">Edit External User</CardTitle>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-6">
          <CardContent className="tw-space-y-6 tw-pt-6">
            {/* Email Address Field */}
            <FormItem className="tw-space-y-2">
              <FormLabel className="tw-text-sm tw-font-medium">Email</FormLabel>
              <Input value={user.email} disabled className="tw-bg-gray-50" />
            </FormItem>

            {/* User Type Selection */}
            <FormField
              control={form.control}
              name="userTypeId"
              rules={{ required: "User Type is required" }}
              render={({ field }) => (
                <FormItem className="tw-space-y-2">
                  <FormLabel className="tw-text-sm tw-font-medium">User Type</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger className="tw-w-full">
                        <SelectValue placeholder="Select a user type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {userTypes.map((userType) => (
                        <SelectItem key={userType.id} value={userType.id}>
                          {userType.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage className="tw-text-red-500 tw-text-sm" />
                </FormItem>
              )}
            />

            {/* Access Policies Accordion */}
            <div className="tw-mt-4">
              <Accordion type="single" collapsible>
                <AccordionItem value="access-policies">
                  <AccordionTrigger className="tw-flex tw-items-center">
                    <div className="tw-flex">
                      <Settings className="tw-w-5 tw-h-5" />
                      <span className="tw-ml-2">Access Policies</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="tw-grid tw-grid-cols-2 tw-gap-2 tw-mt-2">
                      {availableGroups.map((group) => (
                        <FormField
                          key={group}
                          control={form.control}
                          name={`accessPolicies.${group}`}
                          render={({ field }) => (
                            <FormItem className="tw-flex tw-items-center">
                              <FormControl>
                                <Checkbox
                                  id={`accessPolicies.${group}`}
                                  checked={field.value || false}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                              <Label htmlFor={`accessPolicies.${group}`} className="tw-ml-2 tw-capitalize">
                                {group.replace(/-/g, " ")}
                              </Label>
                            </FormItem>
                          )}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </CardContent>
          <CardFooter className="tw-flex tw-justify-between tw-border-t tw-border-gray-200 tw-pt-6">
            <Button variant="secondary" onClick={onClose} type="button" className="tw-bg-gray-300 hover:tw-bg-gray-400">
              Cancel
            </Button>
            <Button type="submit" disabled={mutation.isLoading} className="tw-bg-blue-600 hover:tw-bg-blue-700">
              {mutation.isLoading ? "Updating..." : "Update"}
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  )
}

export default EditExternalUser
