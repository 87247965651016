import React from "react"

import EcosuiteComponent from "@common/EcosuiteComponent"
import Settings from "@common/Settings"

import EnergyUtils from "@dashboard/energy/EnergyUtils"

import AssetSummary from "../info/AssetSummary"
import i18n from "src/i18n"

const { t } = i18n
class SiteInfo extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      energyProjectGraphView: Settings.getSetting("energyProjectGraphView", "total"),
    }
    this.selectGraph = this.selectGraph.bind(this)
  }

  selectGraph(graph) {
    Settings.setSetting("energyProjectGraphView", graph)
    this.setState({ energyProjectGraphView: graph })
  }

  renderContent() {
    let siteStatus = this.props.siteStatus

    return (
      <div className="site-content">
        <h4 className={"content-title " + (this.isContentValid(siteStatus) && siteStatus.status ? siteStatus.status : "no-status")}>
          {t("labels.site")}: {this.props.site.name} ({this.props.site.code})
        </h4>

        <AssetSummary
          className="details-info"
          range={this.props.range}
          asset={this.props.site}
          project={this.props.project}
          site={this.props.site}
          status={siteStatus}
          readings={this.props.readings}
          consumptionCost={this.props.consumptionCost}
          predictedConsumption={this.props.predictedConsumption}
          expectedGeneration={this.props.expectedGeneration}
          predictedGeneration={this.props.predictedGeneration}
          showGeneration={this.props.showGeneration}
          showConsumption={this.props.showConsumption}
          showStorage={this.props.showStorage}
        />

        {this.props.site.contacts && this.props.site.contacts.length > 0 ? (
          <table className="contacts">
            <thead>
              <tr>
                <th width="20%">{t("labels.contact")}</th>
                <th width="20%">{t("labels.type")}</th>
                <th width="20%">{t("table_headings.phone")}</th>
                <th>{t("table_headings.voip")}</th>
                <th>{t("table_headings.email")}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.site.contacts.map((contact, idx) => {
                return (
                  <tr key={idx}>
                    <td>{contact.name}</td>
                    <td>{contact.type}</td>
                    <td>{contact.phone ? <a href={"tel:" + contact.phone}>{contact.phone}</a> : null}</td>
                    <td>
                      {contact.voip ? (
                        <a href={contact.voip} target="_blank" rel="noreferrer noopener">
                          {contact.voip}
                        </a>
                      ) : null}
                    </td>
                    <td>{contact.email}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : null}

        {EnergyUtils.getSystems(this.props.site).map((system) => {
          let systemStatus = this.isContentValid(siteStatus) ? siteStatus.systems[system.code] : undefined
          let systemReadings = this.isContentValid(this.props.readings) ? this.props.readings.systems[system.code] : this.props.readings

          return (
            <React.Fragment key={system.code}>
              <h6 className={"content-subtitle " + (systemStatus && systemStatus.status ? systemStatus.status : "no-status")}>
                {t("labels.system")}: {system.name} ({system.code})
              </h6>
              <AssetSummary
                className="details-info"
                range={this.props.range}
                readings={systemReadings}
                asset={system}
                project={this.props.project}
                site={this.props.site}
                system={system}
                status={systemStatus}
                consumptionCost={this.props.consumptionCost}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                showStorage={this.props.showStorage}
              />
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}

export default SiteInfo
