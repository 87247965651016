import React, { useEffect } from "react"
import { Table } from "reactstrap"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import ProjectService from "@dashboard/data/i2X/ProjectService"
import { Loading } from "@common/EcosuiteComponent"
import i18n from "../../../../i18n"

export interface I2XViewProps {
  project: Project
}

const { t } = i18n

export const I2XView = (props: I2XViewProps) => {
  const [initializingData, setInitializingData] = React.useState<boolean>(false)
  const [i2x, setI2X] = React.useState<Record<string, unknown>>({})

  useCurrentEffect((isCurrent) => {
    init(isCurrent).then()
  }, [props.project.code])


  /**
   * Initializes the data.
   * @param isCurrent - Whether the component is current.
   */
  async function init(isCurrent: () => boolean) {
    setInitializingData(true)

    const dateNow = new Date()

    const dateMinusOneDay = new Date(dateNow)
    dateMinusOneDay.setDate(dateNow.getDate() - 1)

    const data = await ProjectService.getI2XData(props.project.code, dateMinusOneDay.toISOString(), dateNow.toISOString())
    if (isCurrent()) setI2X(data)

    setInitializingData(false)
  }

  if (initializingData) {
    return <Loading/>
  }

  return <>
    <Table>
      <tbody>
        {Object.keys(i2x).map((key) => {
          return <tr key={key}>
            <td>{t(`data.i2x.${key}`)}</td>
            <td>{i2x[key]}</td>
          </tr>
        })}
      </tbody>
    </Table>
  </>
}

export default I2XView
