import { Auth } from "aws-amplify"
import aws from "aws-sdk"

import API from "@common/API"
import { getUserOrganizationId } from "@common/OrganizationUtils"

const ReportService = {
  async listOperatingReports(project) {
    const organizationId = await getUserOrganizationId()

    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Prefix: `${organizationId}/report/operating/${project.code}/`,
      }

      return s3.listObjects(params).promise()
    })
  },

  /**
   * List the Econode reports.
   * @param sourceId - The source ID of the Econode.
   * @returns {Promise<S3.ListObjectsOutput & {$response: Response<S3.ListObjectsOutput, any>}>}
   */
  async listEconodeReports(sourceId) {
    const organizationId = await getUserOrganizationId()

    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      const params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Prefix: `${organizationId}/report/econode-consumption-monitoring/${encodeURIComponent(sourceId)}/`,
      }

      return s3.listObjects(params).promise()
    })
  },

  /**
   * @param project
   * @param {{
   *  sendEmail: Boolean,
   *  targetMonth: dateString,
   * }} args
   */
  async generateOperatingReport(project, args) {
    return API.post(`/report/operating/project/${project.code}?sendEmail=${args.sendEmail}&targetMonth=${args.targetMonth}`)
  },

  /**
   * Generate the Econode report.
   * @param sourceId - The source ID of the Econode.
   * @param sendEmail - Whether to send the report to the configured recipients.
   * @param targetMonth - The target month to generate the report for.
   * @returns {Promise<CognitoUserSession>}
   */
  async generateEconodeReport(sourceId, sendEmail, targetMonth) {
    return API.post(`/report/econode/${encodeURIComponent(sourceId)}?sendEmail=${sendEmail}&targetMonth=${targetMonth}`)
  },

  async viewFile(key) {
    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Key: key,
      }

      return s3.getSignedUrlPromise("getObject", params)
    })
  },

  downloadFile(key) {
    Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Key: key,
      }

      s3.getSignedUrl("getObject", params, (err, url) => {
        window.open(url)
      })
    })
  },
}

export default ReportService
