import React, { ReactNode } from "react"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

interface WithToolTipProps {
  tooltip: string | JSX.Element
  children: ReactNode
  id?: string
}

const WithToolTip: React.FC<WithToolTipProps> = ({ tooltip, children, id }) => {
  if(tooltip === undefined){return <>{children}</>}
  const tooltipId = id || `tooltip-${Math.floor(Math.random() * 1000000)}`

  return (
    <>
      <span id={tooltipId}>{children}</span>
      <UncontrolledTooltip target={tooltipId}>{tooltip}</UncontrolledTooltip>
    </>
  )
}

export default WithToolTip
