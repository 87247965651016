import React from "react"
import classnames from "classnames"

export interface IconProps extends React.HTMLAttributes<HTMLElement> {
  icon?: string
  className?: string
}

const Icon: React.FunctionComponent<IconProps> = ({ className, icon, ...otherProps }) => {
  // add icon prefix classname to use icons from another set
  // eg: classes = classnames("material-icons", "uil", className)
  const classes = classnames("material-icons", className)
  return (
    <i className={classes} {...otherProps}>
      {icon}
    </i>
  )
}

export default Icon
