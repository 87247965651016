import React from "react"
import { create, all } from "mathjs"
import moment from "moment"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"

import ProjectsTableHeaders from "./ProjectsTableHeaders"
import ProjectsTableRow from "./ProjectsTableRow"
import ProjectsTableProjectMenu from "./ProjectsTableProjectMenu"
import ProjectsTableSumsAvgs from "./ProjectsTableSumsAvgs"
import ProjectTableTooltips from "./ProjectTableTooltips"

import "@dashboard/react-contextmenu.css"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
import { get } from "lodash"

const math = create(all)
const { t } = i18n

const sortMapper = {
  "col-code": "code",
  "col-name": "name",
  "col-storage": "projectReading.storage",
  "col-consumption": "projectReading.consumption",
  "col-consumption-predicted": "predictedConsumption.predictedConsumption",
  "col-dc-size": "dcSize",
  "col-ac-size": "acSize",
  "col-peak-power": "peakPower",
  "col-peak-consumption": "peakConsumption",
  "col-consumption-cost": "consumptionCost",
  "col-generation": "projectReading.generation",
  "col-generation-predicted": "predictedGeneration",
  "col-generation-expected": "expectedGeneration.expectedGeneration",
  "specific-yield": "specificYield",
  "col-generation-normalised": "normalisedGeneration",
  "col-generation-peak": "peakGeneration",
  "col-consumption-predicted-percent": "predictedConsumptionPercent",
  "col-generation-expected-percent": "expectedGenerationPercent",
  "col-generation-predicted-percent": "generationPredictedPercent",
}

class ProjectsTable extends EcosuiteComponent {
  state = {
    colname: null,
    order: "asc",
  }

  sortProjects = (projects) => {
    const sortKey = sortMapper[this.state.colname] // key i.e. project[sortKey] = value
    const sortOrder = this.state.order // "asc" | "desc"

    return projects.sort((a, b) => {
      if ((get(a, sortKey) || 0) < (get(b, sortKey) || 0)) {
        return sortOrder === "asc" ? -1 : 1
      }
      if ((get(a, sortKey) || 0) > (get(b, sortKey) || 0)) {
        return sortOrder === "asc" ? 1 : -1
      }
      return 0 // equals
    })
  }

  fillExtraData(projects) {
    let days = this.isContentValid(this.props.readings)
      ? moment(this.props.readings.range.localEndDate).diff(moment(this.props.readings.range.localStartDate), "days")
      : 0
    if (!days) days = 1 // cater for zooming into hour
    return projects.map((project) => {
      let projectStatus = this.getProjectStatus(project)
      let projectReading = this.getProjectReading(project.code)
      let predictedConsumption = this.getPredictedConsumption(project.code)
      let expectedGeneration = this.getExpectedGeneration(project.code)
      let predictedGeneration = this.getPredictedGeneration(project.code)?.predictedGeneration
      let normalisedGeneration = projectReading
        ? math.round(
            math.divide(
              math.divide(EnergyUtils.convertToKilo(projectReading.generation), project.dcSize ? project.dcSize : 0),
              days,
            ),
            2,
          )
        : null
      let peakGeneration = this.getPeakGeneration(project.code)
      let peakConsumption = this.getPeakConsumption(project.code)
      let consumptionCost = this.getConsumptionCost(project.code)
      let expectedGenerationContent =
        expectedGeneration && expectedGeneration.expectedGeneration
          ? EnergyUtils.formatNumberAsKilo(expectedGeneration.expectedGeneration)
          : ""
      let specificYield = projectReading?.generation && math.round(projectReading.generation / project.dcSize, 2)
      let predictedConsumptionPercent = EnergyUtils.getPercent(
        projectReading?.consumption,
        predictedConsumption?.predictedConsumption,
      )
      let expectedGenerationPercent = EnergyUtils.getPercent(
        projectReading?.generation,
        expectedGeneration?.expectedGeneration,
      )
      let generationPredictedPercent = EnergyUtils.getPercent(projectReading?.generation, predictedGeneration)
      let isIrradianceComplete = EnergyUtils.isIrradianceCompleteForRange(project, null, null, this.props.range)
      return {
        ...project,
        projectStatus,
        predictedConsumptionPercent,
        expectedGenerationPercent,
        generationPredictedPercent,
        projectReading,
        predictedConsumption,
        expectedGeneration,
        predictedGeneration,
        normalisedGeneration,
        peakGeneration,
        peakConsumption,
        consumptionCost,
        expectedGenerationContent,
        isIrradianceComplete,
        specificYield,
      }
    })
  }

  renderContent() {
    let days = this.isContentValid(this.props.readings)
      ? moment(this.props.readings.range.localEndDate).diff(moment(this.props.readings.range.localStartDate), "days")
      : 0
    if (!days) days = 1 // cater for zooming into hour

    const totals = {}
    const specificYieldValues = []
    return this.props.projects ? (
      <div className="projects-table">
        <table>
          <ProjectsTableHeaders
            showGeneration={this.props.showGeneration}
            showConsumption={this.props.showConsumption}
            showStorage={this.props.showStorage}
            sortState={this.state}
            setSortState={(state) => this.setState(state)}
          />
          <tbody>
            {this.sortProjects(this.fillExtraData(this.props.projects)).map((project) => {
              let projectStatus = this.getProjectStatus(project)
              let projectReading = this.getProjectReading(project.code)
              let predictedConsumption = this.getPredictedConsumption(project.code)
              let expectedGeneration = this.getExpectedGeneration(project.code)
              let predictedGeneration = this.getPredictedGeneration(project.code)?.predictedGeneration
              let normalisedGeneration = projectReading
                ? math.round(
                    math.divide(
                      math.divide(
                        EnergyUtils.convertToKilo(projectReading.generation),
                        project.dcSize ? project.dcSize : 0,
                      ),
                      days,
                    ),
                    2,
                  )
                : null
              let peakGeneration = this.getPeakGeneration(project.code)
              let peakConsumption = this.getPeakConsumption(project.code)
              let consumptionCost = this.getConsumptionCost(project.code)
              let expectedGenerationContent =
                expectedGeneration && expectedGeneration.expectedGeneration
                  ? EnergyUtils.formatNumberAsKilo(expectedGeneration.expectedGeneration)
                  : ""
              let isIrradianceComplete = EnergyUtils.isIrradianceCompleteForRange(project, null, null, this.props.range)
              let specificYield =
                projectReading?.generation && math.round(projectReading.generation / project.dcSize, 2)
              if (specificYield !== undefined && specificYield !== 0) {
                specificYieldValues.push(specificYield)
              }
              if (!isIrradianceComplete && expectedGeneration && expectedGeneration.expectedGeneration) {
                expectedGenerationContent = (
                  <span>
                    <Icon icon="warning" id={`incomplete-irr-${project.code}`} />
                    <UncontrolledTooltip placement="right" target={`incomplete-irr-${project.code}`}>
                      {t("energy.tooltips.expected_gen", {
                        date: EnergyUtils.formatWattHoursAsKiloWattHours(expectedGeneration.expectedGeneration),
                      })}
                    </UncontrolledTooltip>
                  </span>
                )
              }

              /**
               * -- Philip --
               * DCsize - total
               * ACsize - total
               *
               * Generation - total
               * Expected - total
               * % - weighted average on DCsize
               *
               * Forecast - total
               * % - weighted average on DCsize
               *
               * kWh/kW - weighted average on DCsize
               * Peak kW - total
               * % - weighted average on ACsize
               *
               * When calculating weighted averages skip sources that have missing generation values.
               * (undefined || 0) => 0, ensuring the sums remain a number instead of NaN or undefined
               */
              totals.totalDCSize = (totals.totalDCSize || 0) + (project?.dcSize || 0)
              totals.totalACSize = (totals.totalACSize || 0) + (project?.acSize || 0)
              totals.totalGen =
                (totals.totalGen || 0) + ((projectReading?.generation && projectReading.generation > 0) || 0)
              totals.totalExpectedGen =
                (totals.totalExpectedGen || 0) +
                ((expectedGeneration?.expectedGeneration && expectedGeneration?.expectedGeneration > 0) || 0)
              totals.totalForecastGen = (totals.totalForecastGen || 0) + (predictedGeneration || 0)
              totals.totalPeakGen = (totals.totalPeakGen || 0) + (peakGeneration || 0)
              //   weighted expectation and expectation weights
              const expectedGenPercent =
                (projectReading?.generation || 0) / (expectedGeneration?.expectedGeneration || Infinity)
              totals.weightedExpectation =
                (totals.weightedExpectation || 0) + (project?.dcSize || 0) * expectedGenPercent
              totals.expectationWeights = (totals.expectationWeights || 0) + (expectedGenPercent ? project.dcSize : 0)
              //   weighted forecast and forecast weights
              const forecastGenPercent = (projectReading?.generation || 0) / (predictedGeneration || Infinity)
              totals.weightedForecast = (totals.weightedForecast || 0) + (project?.dcSize || 0) * forecastGenPercent
              totals.forecastWeights = (totals.forecastWeights || 0) + (forecastGenPercent ? project.dcSize : 0)
              //   weighted kWh/kW and kWh/kW weights
              totals.weightedNormalisedGen =
                (totals.weightedNormalisedGen || 0) + (project?.dcSize || 0) * (normalisedGeneration || 0)
              totals.normalisedGenWeights =
                (totals.normalisedGenWeights || 0) + (normalisedGeneration ? project.dcSize : 0)
              //   weighted peak generation and peak generation weights
              //     peakGenPercent = peakGeneration / project.acSize,
              //     but weightedPeakGenPercent = peakGenPercent * project.acSize
              //     so they cancel out and only peakGeneration is left
              totals.weightedPeakGen = (totals.weightedPeakGen || 0) + EnergyUtils.convertToKilo(peakGeneration || 0)
              totals.peakGenWeights = (totals.peakGenWeights || 0) + (peakGeneration ? project.acSize : 0)
              // Render table Row
              return (
                <ProjectsTableRow
                  key={project.code}
                  project={project}
                  projectStatus={projectStatus?.status}
                  projectReading={projectReading}
                  projectEnergyData={{
                    predictedConsumption: predictedConsumption?.predictedConsumption,
                    expectedGeneration: expectedGeneration?.expectedGeneration,
                    predictedGeneration,
                    normalisedGeneration,
                    peakGeneration,
                    peakConsumption,
                    consumptionCost,
                  }}
                  expectedGenerationContent={expectedGenerationContent}
                  isIrradianceComplete={isIrradianceComplete}
                  showGeneration={this.props.showGeneration}
                  showConsumption={this.props.showConsumption}
                  showStorage={this.props.showStorage}
                  specificYield={specificYield}
                />
              )
            })}

            <ProjectsTableSumsAvgs
              showGeneration={this.props.showGeneration}
              showConsumption={this.props.showConsumption}
              showStorage={this.props.showStorage}
              totals={totals}
              specificYieldValues={specificYieldValues}
            />
          </tbody>
        </table>

        <ProjectTableTooltips {...this.props} />

        {this.props.projects.map((project) => {
          return (
            <ProjectsTableProjectMenu
              key={project.code}
              groups={this.props.groups}
              project={project}
              projectStatus={this.getProjectStatus(project)}
            />
          )
        })}
      </div>
    ) : (
      <Loading />
    )
  }

  getProjectStatus(project) {
    if (this.isContentValid(this.props.projectsStatus)) {
      return this.props.projectsStatus.projects.find((projectStatus) => {
        return projectStatus.code === project.code
      })
    }
    return null
  }

  getProjectReading(projectId) {
    if (this.isContentValid(this.props.readings)) {
      return this.props.readings.projects[projectId]
    }
    return null
  }

  getPredictedConsumption(projectId) {
    if (this.isContentValid(this.props.predictedConsumption)) {
      return this.props.predictedConsumption.projects[projectId]
    }
    return null
  }

  getExpectedGeneration(projectId) {
    if (this.isContentValid(this.props.expectedGeneration)) {
      return this.props.expectedGeneration.projects[projectId]
    }
    return null
  }

  getPredictedGeneration(projectId) {
    if (this.isContentValid(this.props.predictedGeneration)) {
      return this.props.predictedGeneration.projects[projectId]
    }
    return null
  }

  getLastMonthsProjectReading(projectId) {
    if (this.isContentValid(this.props.lastMonthsEnergyReadings)) {
      return this.props.lastMonthsEnergyReadings.projects[projectId]
    }
    return null
  }

  getPeakGeneration(projectId) {
    if (this.isContentValid(this.props.datums) && this.props.datums.projects[projectId]) {
      return this.props.datums.projects[projectId].peakGeneration
    }
    return null
  }

  getPeakConsumption(projectId) {
    if (this.isContentValid(this.props.datums) && this.props.datums.projects[projectId]) {
      return this.props.datums.projects[projectId].peakConsumption
    }
    return null
  }

  getConsumptionCost(projectId) {
    if (this.isContentValid(this.props.consumptionCost)) {
      return this.props.consumptionCost.projects[projectId].consumptionCost.totalCost
    }
    return null
  }
}

export default ProjectsTable
