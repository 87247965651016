import React from "react"

/**
 * A simple display link that shows a user's email.
 * @param user - The user.
 * @constructor
 */
export const UserDisplayEmail = ({ user }: { user: User }): JSX.Element => {
  return (
    <>
      <a href={"#"} onClick={() => window.open(`mailto:${user.attributes.email}`)}>{`<${user.attributes.email}>`}</a>
    </>
  )
}

export default UserDisplayEmail
