import { s3ImageUpload } from "@common/input/editor/image/nodes/s3ImageUpload"
import { toast } from "react-toastify"

const isDataUrl = (str: string | undefined) => {
  if (!str) return false
  if (!str.startsWith("data")) return false
  return true
}

export async function S3LogoHandler(dataUrl: string | undefined) {
  if (dataUrl && isDataUrl(dataUrl)) {
    try {
      const imageName = await s3ImageUpload!.s3UploadImage!(dataUrl, process.env.REACT_APP_ASSETS_BUCKET!)
      const imageUrl = `https://${process.env.REACT_APP_ASSETS_BUCKET}.s3.amazonaws.com/${imageName}`
      return imageUrl
    } catch (e) {
      toast.error("Failed to upload logo!")
      return undefined
    }
  } else {
    return undefined
  }
}
