import React from "react"

import EcosuiteModule from "@common/module/EcosuiteModule"
import DateRangeUtils from "@common/utils/DateRangeUtils"

import EnergyService from "./EnergyService"
import UserAlertService from "../event/UserAlertService"

import { EcosuiteComponentError, Loading } from "@common/EcosuiteComponent"
import Aggregations from "@common/Aggregations"
import "./EnergyModule.css"
import { EnergyProjectViews, EnergyModuleViews } from "./EnergyViews"
import Logger from "@common/Logger"
import moment from "moment"

export default class EnergyModule extends EcosuiteModule {
  constructor(props) {
    super(props, "energy")

    this.state.dateRangeIncludesTime = true
  }

  componentDidMount() {
    super.componentDidMount()

    this.load()

    let interval = setInterval(() => {
      this.loadInstantaneous()
      this.loadEnergyStatus()
    }, 300000) // We update the "live" data every regularly, 300000 = 5 minutes
    this.setStateIfMounted({ interval: interval })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    // We recalculate the date range for "All Time" so that the graphs can be updated if necessary
    this.recalculateAllTimeRange(prevProps)

    if (this.props.loadTime !== prevProps.loadTime) {
      // Load all the module data
      this.load()
    } else if (JSON.stringify(this.props.filteredProjects.map((p) => p.code)) !== JSON.stringify(prevProps.filteredProjects.map((p) => p.code))) {
      // Portfolio has been changed or the projects filtered, reload
      Logger.debug(`load datums for portfolio projects: ${this.props.filteredProjects.map((p) => p.code)}`)
      this.loadProjects()
    } else if (!this.props.portfolio && this.props.project !== prevProps.project) {
      // When a portfolio is selected, we just use the loaded datums loaded for the portfolios projects; if not we need to load the details for the selected project
      Logger.debug(`load Project datums`)
      this.loadProjects()
    }
  }

  selectRange(rangeName, customRange) {
    let range = super.selectRange(rangeName, customRange)
    this.setStateIfMounted({ aggregation: undefined }, () => {
      this.loadRange(range)
    })
  }

  /**
   * Loads all the data required by the module.
   */
  load() {
    this.loadRange(this.getExclusiveRange())

    this.loadLastMonthsEnergyReadings()
    this.loadTodaysEnergyDatums()
    this.loadInstantaneous()
    this.loadEnergyStatus()
  }

  /**
   * Loads the data required when the filtered projects changes; when the portfolio, filter or project selection changes, it means that project specific data should be loaded
   */
  loadProjects() {
    /*
     * EP-1850 We try to not load the data for all projects at once as this is no longer performant, this is a WIP.
     *
     * EP-2167: we currently load project data for all the currently filtered projects, ie for all those in the current "portfolio"; when a portfolio isn't selected we only load a single project.
     * This is because when we load a portfolio, we then pass the project data into the project view so that it can be viewed instantly without needing to load additional data.
     *
     * Currently we're NOT taking the selected projects into account, ie we load the filtered projects (all projects in the "portfolio") and are NOT taking the selections into account;
     * this means that the totals currently shown are for the entire portfolio, not just the currently selected projects in the portfolio.
     * As this is how the totals have always worked (ie not taking selections into account) this is fine for now; in the future we may want to take selections into account.
     * When we do we can either make an additional call to get the data for the selected projects as well, which will mean that the server calculates the total or,
     * do the filtering client side from the filtered projects, only totalling the filtered projects that we know to be selected (as selected is a subset of filtered).
     *
     * */

    // We only reload the range data that is project specific
    const range = this.getExclusiveRange()
    this.loadRange(range)
    this.loadEnergyDatums(range)
    this.loadEnergyReadings(range)

    this.loadLastMonthsEnergyReadings()
    this.loadTodaysEnergyDatums()
    this.loadInstantaneous()
    this.loadEnergyStatus()
  }

  /**
   * Load all the data dependent on the selected range.
   */
  loadRange(range) {
    this.loadEnergyDatums(range)
    this.loadPredictedConsumption(range)
    this.loadConsumptionCost(range)
    this.loadExpectedGeneration(range)
    this.loadPredictedGeneration(range)
    this.loadEnergyReadings(range)
    this.loadUserAlerts(range)

    const aggregate = this.getAggregateForRange(range)

    this.loadConsumptionDatums(range, aggregate)
    this.loadPreviousEnergyReadings(range, aggregate)
  }

  getAggregateForRange(range) {
    let aggregate = DateRangeUtils.getAggregateForRange(range)
    if (range.diff("days", true) <= 1) {
      aggregate = Aggregations.FifteenMinute
    }
    return aggregate
  }

  loadConsumptionDatums(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        econodeDatums: undefined,
        econodeDatumsError: undefined,
      },
      () => {
        if (this.props.project) {
          EnergyService.getProjectConsumptionDatums(range, aggregate, this.props.project.code)
            .then((response) => {
              if (this.isRangeCurrent(range)) {
                this.setStateIfMounted({
                  econodeDatums: response,
                })
              } else {
                Logger.debug(`Ignoring out of date response for range: ${range}`)
              }
            })
            .catch((err) => {
              Logger.error(err)
              if (this.isRangeCurrent(range)) {
                this.setStateIfMounted({
                  econodeDatums: new EcosuiteComponentError(err),
                })
              }
            })
        }
      },
    )
  }

  loadPreviousEnergyReadings(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      econodeActualRange: undefined,
      econodePreviousRange: undefined,
      econodePreviousReadings: undefined,
    })

    const now = moment()
    if (range.start.isAfter(now)) {
      // We set to null to indicate that there is no actual/previous data to load for the selected dates
      this.setStateIfMounted({
        econodeActualRange: null,
        econodePreviousRange: null,
        econodePreviousReadings: null,
      })
    } else {
      let actualRange = moment.range(range.start, range.end.isAfter(now) ? now.add(1, aggregate).startOf(aggregate) : range.end)
      let rangeLength = actualRange.end - actualRange.start
      let previousRange = moment.range(actualRange.start - rangeLength, actualRange.end - rangeLength)

      EnergyService.getEnergyReadings(previousRange)
        .then((response) => {
          if (this.isRangeCurrent(range)) {
            this.setStateIfMounted({
              econodeActualRange: actualRange,
              econodePreviousRange: previousRange,
              econodePreviousReadings: response,
            })
          } else {
            Logger.debug(`Ignoring out of date response for range: ${range}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          if (this.isRangeCurrent(range)) {
            this.setStateIfMounted({
              econodeActualRange: actualRange,
              econodePreviousRange: previousRange,
              econodePreviousReadings: new EcosuiteComponentError(err),
            })
          }
        })
    }
  }

  loadUserAlerts(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        userAlerts: undefined,
      },
      () => {
        UserAlertService.getUserAlerts(this.props.userId, range)
          .then((alerts) => {
            if (this.isRangeCurrent(range)) {
              this.setStateIfMounted({
                userAlerts: alerts,
              })
            } else {
              Logger.debug(`Ignoring out of date response for range: ${range}`)
            }
          })
          .catch((err) => {
            Logger.error(err)
            if (this.isRangeCurrent(range)) {
              this.setStateIfMounted({
                userAlerts: new EcosuiteComponentError(err),
              })
            }
          })
      },
    )
  }

  /**
   *
   * @returns {[string]} the filtered project codes
   */
  getFilteredProjectIds() {
    return (this.props.project && !this.props.portfolio ? [this.props.project] : this.props.filteredProjects).map((p) => p.code)
  }

  areFilteredProjectIdsCurrent(filteredProjectIds) {
    const latestProjectIds = this.getFilteredProjectIds()
    return JSON.stringify(filteredProjectIds) === JSON.stringify(latestProjectIds)
  }

  loadEnergyDatums(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        datums: undefined,
      },
      () => {
        const projectIds = this.getFilteredProjectIds()
        Logger.debug(`load datums for projects: ${projectIds}`)
        EnergyService.getEnergyDatums(range, projectIds)
          .then((response) => {
            if (this.isRangeCurrent(range) && this.areFilteredProjectIdsCurrent(projectIds)) {
              this.setStateIfMounted({
                datums: response,
              })
            } else {
              Logger.debug(`Ignoring out of date response for range: ${range} and projects: ${projectIds}`)
            }
          })
          .catch((err) => {
            Logger.error(err)
            if (this.isRangeCurrent(range) && this.areFilteredProjectIdsCurrent(projectIds)) {
              this.setStateIfMounted({
                datums: new EcosuiteComponentError(err),
              })
            }
          })
      },
    )
  }

  loadTodaysEnergyDatums() {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      todaysEnergyDatums: undefined,
    })

    const projectIds = this.getFilteredProjectIds()
    EnergyService.getEnergyDatums(DateRangeUtils.getRange("today"), projectIds)
      .then((response) => {
        if (this.areFilteredProjectIdsCurrent(projectIds)) {
          this.setStateIfMounted({
            todaysEnergyDatums: response,
          })
        }
      })
      .catch((err) => {
        if (this.areFilteredProjectIdsCurrent(projectIds)) {
          this.setStateIfMounted({
            todaysEnergyDatums: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadPredictedConsumption(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      predictedConsumption: undefined,
    })

    EnergyService.getPredictedConsumption(range)
      .then((response) => {
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            predictedConsumption: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            predictedConsumption: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadConsumptionCost(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      consumptionCost: undefined,
    })

    EnergyService.getConsumptionCost(range)
      .then((response) => {
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            consumptionCost: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            consumptionCost: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadExpectedGeneration(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      expectedGeneration: undefined,
    })

    EnergyService.getExpectedGeneration(range, null, this.getFilteredProjectIds())
      .then((response) => {
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            expectedGeneration: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            expectedGeneration: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadPredictedGeneration(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      predictedGeneration: undefined,
    })

    const projectIds = this.getFilteredProjectIds()
    EnergyService.getPredictedGeneration(range, projectIds)
      .then((response) => {
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            predictedGeneration: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            predictedGeneration: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadEnergyReadings(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      readings: undefined,
    })

    if (range === "lastMonth") {
      // The load for 'lastMonth' is handled by loadLastMonthsEnergyReadings()
      if (this.isRangeCurrent(range)) {
        this.setStateIfMounted({
          readings: this.state.lastMonthsEnergyReadings,
        })
      } else {
        Logger.debug(`Ignoring out of date response for range: ${range}`)
      }
    } else {
      const projectIds = this.getFilteredProjectIds()
      EnergyService.getEnergyReadings(range, projectIds)
        .then((response) => {
          if (this.isRangeCurrent(range) && this.areFilteredProjectIdsCurrent(projectIds)) {
            this.setStateIfMounted({
              readings: response,
            })
          } else {
            Logger.debug(`Ignoring out of date response for range: ${range}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          if (this.isRangeCurrent(range) && this.areFilteredProjectIdsCurrent(projectIds)) {
            this.setStateIfMounted({
              readings: new EcosuiteComponentError(err),
            })
          }
        })
    }
  }

  loadLastMonthsEnergyReadings() {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      lastMonthsEnergyReadings: undefined,
    })

    const projectIds = this.getFilteredProjectIds()
    EnergyService.getEnergyReadings(DateRangeUtils.getRange("lastMonth"), projectIds)
      .then((response) => {
        if (this.areFilteredProjectIdsCurrent(projectIds)) {
          this.setStateIfMounted({
            lastMonthsEnergyReadings: response,
          })
          if (this.state.rangeName === "lastMonth") {
            // If the view is for the last month we also populate the energy here
            this.setStateIfMounted({
              readings: response,
            })
          }
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.areFilteredProjectIdsCurrent(projectIds)) {
          this.setStateIfMounted({
            lastMonthsEnergyReadings: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadInstantaneous() {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      instantaneous: undefined,
    })

    const projectIds = this.getFilteredProjectIds()
    EnergyService.getInstantaneous(projectIds)
      .then((response) => {
        if (this.areFilteredProjectIdsCurrent(projectIds)) {
          this.setStateIfMounted({
            instantaneous: response,
          })
        }
      })
      .catch((err) => {
        if (this.areFilteredProjectIdsCurrent(projectIds)) {
          this.setStateIfMounted({
            instantaneous: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadEnergyStatus() {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      projectsStatus: undefined,
    })

    const projectIds = this.getFilteredProjectIds()
    EnergyService.getStatus(projectIds)
      .then((response) => {
        if (this.areFilteredProjectIdsCurrent(projectIds)) {
          this.setStateIfMounted({
            projectsStatus: response,
          })
        }
      })
      .catch((err) => {
        if (this.areFilteredProjectIdsCurrent(projectIds)) {
          this.setStateIfMounted({
            projectsStatus: new EcosuiteComponentError(err),
          })
        }
      })
  }

  getProjectStatus(project) {
    if (this.isContentValid(this.state.projectsStatus)) {
      return this.state.projectsStatus.projects.find((projectStatus) => {
        return projectStatus.code === project.code
      })
    }
    return this.state.projectsStatus
  }

  renderProjectView() {
    let project = this.props.project
    return (
      <div className="EnergyView">
        <EnergyProjectViews
          restrictions={this.props.restrictions}
          groups={this.props.groups}
          view={this.state.projectView}
          range={this.getExclusiveRange()}
          rangeName={this.state.rangeName}
          customRange={this.getCustomRange()}
          selectRange={this.selectRange}
          project={project}
          projects={[project]}
          projectStatus={this.getProjectStatus(project)}
          datums={this.isContentValid(this.state.datums) ? this.state.datums.projects[project.code] : this.state.datums}
          datumsRange={this.isContentValid(this.state.datums) ? this.state.datums.range : this.state.datums}
          datumsAggregation={this.isContentValid(this.state.datums) ? this.state.datums.aggregation : this.state.datums}
          consumptionCost={this.isContentValid(this.state.consumptionCost) ? this.state.consumptionCost.projects[project.code] : this.state.consumptionCost}
          predictedConsumption={this.isContentValid(this.state.predictedConsumption) ? this.state.predictedConsumption.projects[project.code] : this.state.predictedConsumption}
          expectedGeneration={this.isContentValid(this.state.expectedGeneration) ? this.state.expectedGeneration.projects[project.code] : this.state.expectedGeneration}
          predictedGeneration={this.isContentValid(this.state.predictedGeneration) ? this.state.predictedGeneration.projects[project.code] : this.state.predictedGeneration}
          instantaneous={this.isContentValid(this.state.instantaneous) ? this.state.instantaneous.projects[project.code] : this.state.instantaneous}
          instantaneousTimestamp={this.isContentValid(this.state.instantaneous) ? this.state.instantaneous.timestamp : this.state.instantaneous}
          readings={this.isContentValid(this.state.readings) ? this.state.readings.projects[project.code] : this.state.readings}
          lastMonthsEnergyReadings={this.isContentValid(this.state.lastMonthsEnergyReadings) ? this.state.lastMonthsEnergyReadings.projects[project.code] : this.state.lastMonthsEnergyReadings}
          userAlerts={this.state.userAlerts}
          showGeneration={this.isGenerationVisible()}
          showConsumption={this.isConsumptionVisible()}
          showStorage={this.isStorageVisible()}
          econodeActualRange={this.state.econodeActualRange}
          econodePreviousRange={this.state.econodePreviousRange}
          econodePreviousReadings={this.state.econodePreviousReadings?.projects[project.code]}
          econodeDatums={this.state.econodeDatums}
        />
      </div>
    )
  }

  renderModuleView() {
    if (this.isContentValid(this.state.readings)) {
      // EP-2167 we have a check to ensure that the readings are loaded for the current projects
      const projectIds = this.props.filteredProjects.map((p) => p.code).sort()
      const readingProjectIds = Object.keys(this.state.readings.projects).sort()
      if (JSON.stringify(projectIds) !== JSON.stringify(readingProjectIds)) {
        Logger.debug(`Current projects: ${projectIds}`)
        Logger.debug(`Ignoring out of date readings for projects: ${readingProjectIds}`)
        return <Loading />
      }
    }

    return (
      <div className="EnergyView">
        <EnergyModuleViews
          restrictions={this.props.restrictions}
          groups={this.props.groups}
          view={this.state.moduleView}
          range={this.getExclusiveRange()}
          rangeName={this.state.rangeName}
          customRange={this.getCustomRange()}
          selectProject={this.selectProject}
          selectRange={this.selectRange}
          projects={this.props.projects}
          instantaneous={this.state.instantaneous}
          recentDatums={this.state.todaysEnergyDatums}
          projectsStatus={this.state.projectsStatus}
          readings={this.state.readings}
          lastMonthsEnergyReadings={this.state.lastMonthsEnergyReadings}
          datums={this.state.datums}
          consumptionCost={this.state.consumptionCost}
          predictedConsumption={this.state.predictedConsumption}
          expectedGeneration={this.state.expectedGeneration}
          predictedGeneration={this.state.predictedGeneration}
          userAlerts={this.state.userAlerts}
          showGeneration={this.isGenerationVisible()}
          showConsumption={this.isConsumptionVisible()}
          showStorage={this.isStorageVisible()}
        />
      </div>
    )
  }
}
