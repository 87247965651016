import React, { useEffect, useRef } from "react"
import { Input } from "reactstrap"
import "./Button.css"

/**
 * The valid SelectAll properties.
 */
export interface SelectAllProps {
  allToggle: () => void
  noneToggle: () => void
  status: number
}

/**
 * A select all input box.
 * @param props - The configuration properties.
 * @constructor
 */
export const SelectAll = (props: SelectAllProps): JSX.Element => {
  const inputRef = useRef()

  useEffect(() => {
    // If the status is 2, then set the visual appearance to indeterminate ("-").
    //
    // This has to be ignored, because the `current` field is marked as being of never type, when in reality it is
    // defined AFTER the component renders.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef.current.indeterminate = props.status === 2
  })

  return (
    <>
      <Input
        type={"checkbox"}
        onChange={() => {
          if (props.status === 1 || props.status === 2) {
            props.noneToggle.call(this)
          } else {
            props.allToggle.call(this)
          }
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        innerRef={inputRef}
        checked={props.status === 1}
      />
    </>
  )
}

/**
 * Evaluate the select all status.
 * @param total - The total number of entries.
 * @param numberSelected - The number of entries selected.
 */
export const evaluateSelectAllStatus = (total: number, numberSelected: number): number => {
  if (numberSelected === 0) {
    return 0
  } else if (total === numberSelected) {
    return 1
  }

  return 2
}

export default SelectAll
