import ProjectUtils from "@common/utils/ProjectUtils"

var EconodeUtils = {
  filterEconodeProjects(projects) {
    return projects.filter((project) => this.isEconodeProject(project))
  },

  filterEconodeSystems(systems) {
    return systems.filter((system) => this.isEconodeSystem(system))
  },

  isEconodeProject(project) {
    let systems = ProjectUtils.getSystemsForProject(project)
    return systems.find((system) => this.isEconodeSystem(system)) !== undefined
  },

  isEconodeSystem(system) {
    return system.type === "consumption"
  },

  getEconodesForProjects(projects) {
    let econodes = []
    projects.forEach((project) => {
      econodes = econodes.concat(this.getEconodesForProject(project))
    })
    return econodes
  },

  getEconodesForProject(project) {
    let econodes = []
    if (project.sites) {
      Object.values(project.sites).forEach((site) => {
        if (site.systems) {
          Object.values(site.systems).forEach((system) => {
            if (this.isEconodeSystem(system)) {
              econodes.push({ code: ProjectUtils.getPath(project, site, system), name: system.name })
            }
          })
        }
      })
    }
    return econodes
  },

  mapEconodeDatums(project, datums) {
    let econodDatums = {}
    if (project.sites) {
      Object.values(project.sites).forEach((site) => {
        if (site.systems) {
          Object.values(site.systems).forEach((system) => {
            if (this.isEconodeSystem(system) && datums.sites && datums.sites[site.code] && datums.sites[site.code].systems) {
              econodDatums[ProjectUtils.getPath(project, site, system)] = datums.sites[site.code].systems[system.code]
            }
          })
        }
      })
    }
    return econodDatums
  },
}

export default EconodeUtils
