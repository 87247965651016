import React from "react"

import Logger from "@common/Logger"
import EcosuiteView from "@common/module/EcosuiteView"
import { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"

import AuditService from "./AuditService"
import AuditTable from "./components/AuditTable"
import AuditViewControls from "./components/AuditViewControls"

import "./Audit.css"
import i18n from "src/i18n"

const { t } = i18n
/**
 * Base class for the different Document views.
 */
export default class DocumentView extends EcosuiteView {
  constructor(props, name) {
    super(props, name ? name : "audit")
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadAuditChanges()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadAuditChanges()
    }
  }

  renderViewControls() {
    return null
  }

  loadAuditChanges() {
    this.setStateIfMounted({ allAuditChanges: null })

    if (this.props.project) {
      const projectId = this.props.project.code
      AuditService.getAuditChangesForProject(projectId)
        .then((response) => {
          if (this.isProjectCurrent(projectId)) {
            // Concat the response object into a single array of diffs
            const allAuditChanges = [].concat
              .apply([], Object.values(response.diff))
              .sort((a, b) => b.timestamp - a.timestamp)

            this.setStateIfMounted({
              allAuditChanges: allAuditChanges,

              // Get all of the audit types from response and set them to be shown
              auditTypes: allAuditChanges.reduce((total, current) => {
                return {
                  ...total,
                  [current.assetType]: true,
                }
              }, {}),
            })
          } else {
            Logger.debug(`${t("errors.audit")} ${projectId}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({ allAuditChanges: new EcosuiteComponentError(err) })
          }
        })
    }
  }

  renderMainView() {
    if (this.isContentError(this.state.allAuditChanges)) {
      return <Error error={this.state.allAuditChanges.getError()} />
    } else if (this.isContentValid(this.state.allAuditChanges)) {
      return (
        <>
          <AuditTable
            auditChanges={this.state.allAuditChanges.filter((change) => this.state.auditTypes[change.assetType])}
          />
          <AuditViewControls
            auditTypes={this.state.auditTypes}
            toggleAuditType={(toggledAuditType) => {
              this.setStateIfMounted((prev) => ({
                auditTypes: {
                  ...prev.auditTypes,
                  ...toggledAuditType,
                },
              }))
            }}
          />
        </>
      )
    } else {
      return <Loading />
    }
  }
}
