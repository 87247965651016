import React from "react"
import { Card, ListGroup } from "reactstrap"
import RFITile from "./RFITile"
import { RFIStored } from "@ecogy-types/openapi-types"

/**
 * The RFI selector.
 * @param allRFI - All available RFI.
 * @param selectedRFI - The currently selected RFI.
 * @param selectRFI - The callback to select a new RFI.
 * @param getUser - The function to get a user from an ID.
 * @constructor
 */
export const RFISelector = ({
  shownRFI,
  selectedRFI,
  selectRFI,
  getUser,
}: {
  shownRFI: RFIStored[]
  selectedRFI: RFIStored | undefined
  selectRFI: (rfi: RFIStored) => void
  getUser: (id: string) => User | undefined
}): JSX.Element => {
  return (
    <>
      <Card>
        <ListGroup>
          {shownRFI
            .sort((a, b) => (a.number > b.number ? -1 : 1))
            .map((rfi) => (
              <RFITile key={rfi.id} rfi={rfi} author={getUser(rfi.author)} selected={selectedRFI ? selectedRFI.id === rfi.id : false} onSelect={() => selectRFI.call(this, rfi)} />
            ))}
        </ListGroup>
      </Card>
    </>
  )
}

export default RFISelector
