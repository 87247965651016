import React from "react"
import { Col, Button, Row, Label, Input } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { EcosuiteFieldTemplate, EcosuiteArrayFieldTemplate } from "@common/form/EcosuiteForm"
import EcosuiteNumberField from "@common/form/EcosuiteNumberField"
import Schemas from "@common/Schemas"
import Logger from "@common/Logger"

import ProjectAdminService from "../ProjectAdminService"
import ProjectDetails from "./ProjectDetails"
import { MediaField } from "@dashboard/data/media/MediaField"
import hideSchemaFields from "@common/form/HideSchemaFields"
import getUiSchema from "./ProjectUISchema"
import i18n from "src/i18n"

const { t } = i18n
class CreateProject extends EcosuiteComponent {
  renderContent() {
    return <NewProject formData={this.props.formData} projectAdded={this.props.projectAdded} groups={this.props.groups} />
  }
}

class NewProject extends ProjectDetails {
  constructor(props) {
    super(props)
    this.state = { formData: this.props.formData ?? {}, slackChannel: "" }
    this.createProject = this.createProject.bind(this)
    this.formCallback = this.formCallback.bind(this)
    this.handleSlackChannelChange = this.handleSlackChannelChange.bind(this)
  }

  loadSchema() {
    Schemas.getProjectSchema().then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
  }

  createProject(form) {
    const slackChannelName = this.state.slackChannel.includes(" ") ? this.state.slackChannel.toLowerCase().replace(/ /g, "-") : this.state.slackChannel.toLowerCase()
    const updatedSlackChannel = `${form.formData?.code.toLowerCase()}_${slackChannelName}`
    const updatedForm = { ...form, formData: { ...form.formData, slackChannel: updatedSlackChannel } }
    this.setState({ error: undefined, loading: true, formData: updatedForm.formData })

    if (form.formData.tags) {
      updatedForm.formData.tags = ["DevCo Owned", ...form.formData.tags]
    } else {
      updatedForm.formData.tags = ["DevCo Owned"]
    }

    ProjectAdminService.createProject(updatedForm)
      .then((data) => {
        let project = data.project
        this.props.projectAdded(project) // notify that a new project has been created
        this.setStateIfMounted({ loading: false })
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, loading: false })
      })
  }

  formCallback(form) {
    this.form = form
  }

  handleSlackChannelChange(event) {
    this.setState({ slackChannel: event.target.value })
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.schema) {
      return (
        <div className="ecogy-form project-content">
          <EcosuiteForm
            schema={hideSchemaFields(super.getSchemaFieldsToHideBasedOnPermission(this.props.groups), this.state.schema)}
            uiSchema={getUiSchema.call(this, this.state.formData)}
            formData={this.state.formData}
            onChange={this.formDataChanged}
            onSubmit={this.createProject}
            FieldTemplate={EcosuiteFieldTemplate}
            ArrayFieldTemplate={EcosuiteArrayFieldTemplate}
            formMounted={this.formCallback}
            fields={{ media: MediaField, NumberField: EcosuiteNumberField }}
            widgets={{
              addressLookup: this.addressLookup,
            }}
          >
            <Row className="ecogy-form-buttons">
              <Col className="message-section" sm="12">
                {this.renderMessages()}
              </Col>
              <Col className="button-section" sm="12">
                <Button color="primary" type="submit" disabled={this.props.readonly}>
                  {t("buttons.submit")}
                </Button>
              </Col>
            </Row>

            {this.renderMessages()}
            <div className="row form-group field">
              <Label className="control-label col-sm-2 col-form-label">{t("labels.slack_channel")}</Label>
              <div className="col-sm-10">
                <Input type="text" placeholder={`${t("labels.enter_slack_channel_name")}`} onChange={this.handleSlackChannelChange} value={this.state.slackChannel} />
              </div>
            </div>
          </EcosuiteForm>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

export default CreateProject
