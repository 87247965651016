import React from "react"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm from "@common/form/EcosuiteForm"
import { Card, CardBody, Alert, Button } from "reactstrap"
import SoftLedgerAdminService from "./SoftLedgerAdminService"
import Schemas from "@common/Schemas"
import i18n from "src/i18n"
import WithToolTip from "@common/display/ToolTip"

const jsprim = require("jsprim")
class FinanceSettings extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.saveSettings = this.saveSettings.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadLocations()
  }

  saveSettings(form) {
    this.props.actions.saveSettings(form.formData)
  }

  async loadLocations() {
    const schema = await Schemas.getFinanceSchema().then((financeSchema) => {
      // We create a copy of the schema and remove the properties that we don't want to maintain here
      const settingsSchema = jsprim.deepCopy(financeSchema)
      delete settingsSchema.properties.id
      delete settingsSchema.properties.created
      delete settingsSchema.properties.updated
      delete settingsSchema.properties.path
      delete settingsSchema.properties.disabledAccounts
      delete settingsSchema.properties.disabledCategories
      return settingsSchema
    })
    this.setStateIfMounted({
      schema: schema,
      locationsMessage: "Loading locations from SoftLedger ...",
      locationsError: null,
    })

    // The SoftLedger locations can take a long time to load so we process them after the schema rather than in parallel
    SoftLedgerAdminService.getLocations()
      .then((locations) => {
        if (locations) {
          this.setStateIfMounted({ schema: null })
          const softledgerPropertiesSchema = schema.dependencies.provider.oneOf.find(
            (dependency) => dependency.properties.provider.enum[0] === "softledger",
          )

          softledgerPropertiesSchema.properties.locationId.title = "SoftLedger Location ID"
          softledgerPropertiesSchema.properties.locationId.enum = []
          softledgerPropertiesSchema.properties.locationId.enumNames = []

          locations.forEach((location, idx) => {
            softledgerPropertiesSchema.properties.locationId.enum[idx] = location.id
            softledgerPropertiesSchema.properties.locationId.enumNames[idx] = location.name
          })
          this.setStateIfMounted({ schema: schema, locationsMessage: null })
        }
      })
      .catch(() => {
        this.setStateIfMounted({ locationsError: "Unable to load locations from SoftLedger", locationsMessage: null })
      })
  }

  renderContent() {
    if (this.props.settings && this.state.schema) {
      const { t } = i18n
      return (
        <React.Fragment>
          <div className="ecogy-form">
            <Card>
              <CardBody>
                <EcosuiteForm
                  schema={this.state.schema}
                  formData={this.props.settings}
                  onSubmit={this.saveSettings}
                  disabled={this.props.readonly}
                  onChange={this.props.actions.changeSettings}
                >
                  <WithToolTip tooltip="IRR categories need to be updated seperately using the update button below">
                    <Button color="primary" type="submit" disabled={this.props.readonly}>
                      {t("buttons.submit")}
                    </Button>
                  </WithToolTip>
                </EcosuiteForm>
              </CardBody>
            </Card>
            {this.state.locationsMessage ? <Alert color="info">{this.state.locationsMessage}</Alert> : null}
            {this.state.locationsError ? <Alert color="danger">{this.state.locationsError}</Alert> : null}
          </div>
        </React.Fragment>
      )
    } else {
      return <Loading />
    }
  }
}

export default FinanceSettings
