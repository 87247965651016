import React from "react"
import { MapView } from "@common/module/EcosuiteView"
import { ProjectMarker, ProjectRadiusMarker } from "@dashboard/ProjectMap"

import FinanceUtils from "../FinanceUtils"
import i18n from "src/i18n"
import FinanceService from "../FinanceService"
import Settings from "@common/Settings"
import Logger from "@common/Logger"

const { t } = i18n

export default class FinanceMapView extends MapView {
  constructor(props) {
    super(props, "finance-map")

    this.state.mode = Settings.getSetting("financeMapMode", "forecast")
    this.state.flags = Settings.getSetting("financeDashboardFlags", ["debt"])

    this.selectMode = this.selectMode.bind(this)
    this.toggleFlag = this.toggleFlag.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadCashFlowTotals()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (this.props.loadTime !== prevProps.loadTime) {
      // A change in loadTime means that the project list has been refreshed so we should make sure we have the latest cash flow totals
      this.loadCashFlowTotals()
    }
  }

  loadCashFlowTotals() {
    this.setState(
      {
        cashFlowTotals: undefined,
      },
      () => {
        const mode = this.state.mode
        const flags = this.state.flags
        FinanceService.getCashFlowTotals(mode, flags)
          .then((response) => {
            if (mode === this.state.mode && flags === this.state.flags) {
              this.setStateIfMounted({
                cashFlowTotals: response.projectTotals,
              })
            } else {
              Logger.debug(`Ignoring out of date response for: ${mode} ${flags}`)
            }
          })
          .catch((error) => {
            this.setState({ hasError: true, error: error })
          })
      },
    )
  }

  selectMode(mode) {
    this.setStateIfMounted({ mode: mode }, () => {
      Settings.setSetting("financeMapMode", mode)
      this.loadCashFlowTotals()
    })
  }

  toggleFlag(event) {
    const flags = this.state.flags.filter((flag) => flag !== event.target.value) // Remove the flag from the selections
    if (event.target.checked) {
      flags.push(event.target.value) // Add the flag to the selections
    }

    this.setState({ flags: flags }, () => {
      Settings.setSetting("financeDashboardFlags", flags)
      this.loadCashFlowTotals()
    })
  }

  getMapMarkers() {
    let totalNetIncome = 0
    if (this.isContentValid(this.state.cashFlowTotals)) {
      totalNetIncome = this.props.projects.reduce((total, project) => {
        let projectCashFlowTotals = this.state.cashFlowTotals[project.code]
        if (projectCashFlowTotals) {
          return (total += Math.abs(projectCashFlowTotals.netIncome))
        } else {
          return total
        }
      }, totalNetIncome)
    }

    return this.props.projects == null
      ? []
      : this.props.projects.map((project) => {
          return project.lat && project.long ? (
            <React.Fragment key={project.code}>
              <FinanceProjectMarker project={project} cashFlowTotals={this.state.cashFlowTotals} />
              {this.state.cashFlowTotals && this.state.cashFlowTotals[project.code] ? (
                <FinanceRadiusMarker project={project} projectCashFlowTotal={this.state.cashFlowTotals[project.code]} totalNetIncome={totalNetIncome} />
              ) : null}
            </React.Fragment>
          ) : null
        })
  }
}

class FinanceProjectMarker extends ProjectMarker {
  renderProjectInfo() {
    let cashFlowTotals = this.isContentValid(this.state.cashFlowTotals) ? this.state.cashFlowTotals[this.props.project.code] : undefined

    return (
      <React.Fragment>
        <div>{`${t("labels.project")}: ` + this.props.project.name}</div>
        {cashFlowTotals ? <div>{`${t("economics.labels.Net Income")} ` + FinanceUtils.formatCurrency(cashFlowTotals.netIncome, cashFlowTotals.currency)}</div> : null}
      </React.Fragment>
    )
  }
}

class FinanceRadiusMarker extends ProjectRadiusMarker {
  getColor() {
    return this.props.projectCashFlowTotal.netIncome < 0 ? "red" : "#0EB6DB"
  }

  calculateRadius() {
    return (Math.abs(this.props.projectCashFlowTotal.netIncome) / this.props.totalNetIncome) * 100 + 12
  }
}
