import ConfirmDialog from "@common/display/ConfirmModal/ConfirmModal"
import Table from "@common/display/Table"
import NiceModal from "@ebay/nice-modal-react"
import { t } from "i18next"
import * as React from "react"
import { Tooltip } from "react-tooltip"
import { Button } from "reactstrap"
import "../../../../FinanceModule.css"

export default function ActualDetailsTooltip({ id, actualProfitLoss }: any) {
  if (!actualProfitLoss.transactions[0]) return null
  const extraDataCount = actualProfitLoss.transactions[0].length - 10

  const handleDialogOpen = () => {
    NiceModal.show(ConfirmDialog, {
      size: "xl",
      title: "Actual transaction details",
      message: (
        <div className="Module">
          <div className="module-content" style={{ width: "100%" }}>
            <div className="content" style={{ width: "100%" }}>
              <div className="finance-table" style={{ width: "100%", height: "calc(100vh - 120px)" }}>
                <Table
                  defaultColumn={{ accessorKey: "id" }}
                  data={actualProfitLoss.transactions[0]}
                  rowCount={actualProfitLoss.transactions[0].length}
                  columns={[
                    {
                      id: "name",
                      accessorKey: "name",
                      header: () => <span>{t("table_headings.name")}</span>,
                      cell: ({ getValue }) => <div style={{ width: "400px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{getValue() || "-"}</div>,
                    },
                    {
                      id: "credit",
                      accessorKey: "credit",
                      meta: {
                        align: "right",
                      },
                    },
                    {
                      id: "debit",
                      accessorKey: "debit",
                      meta: {
                        align: "right",
                      },
                    },
                    {
                      id: "date",
                      accessorKey: "date",
                    },
                    {
                      id: "type",
                      accessorKey: "type",
                    },
                    {
                      id: "status",
                      accessorKey: "status",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      ),
      confirmText: "Close",
    })
  }

  return (
    <Tooltip anchorSelect={`#${id}`} clickable place="right" style={{ fontSize: 16, fontWeight: 300, zIndex: 10000 }}>
      <div style={{ width: "600px" }}>
        {extraDataCount > 0 && "10 rows displayed"}
        <Table
          defaultColumn={{ accessorKey: "id" }}
          data={actualProfitLoss.transactions[0].slice(0, 10)}
          rowCount={actualProfitLoss.transactions[0].length}
          columns={[
            {
              id: "name",
              accessorKey: "name",
              header: () => <span>{t("table_headings.name")}</span>,
              cell: ({ getValue }) => <div style={{ width: "240px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{getValue() || "-"}</div>,
              size: 240,
            },
            {
              id: "credit",
              accessorKey: "credit",
              size: 100,
              meta: {
                align: "right",
              },
            },
            {
              id: "debit",
              accessorKey: "debit",
              size: 100,
              meta: {
                align: "right",
              },
            },
          ]}
        />
      </div>
      {actualProfitLoss.transactions[0].length > 0 && <Button onClick={handleDialogOpen}>See Full Data</Button>}
    </Tooltip>
  )
}
