import React from "react"
import ExcelJS from "exceljs"
import { Button } from "reactstrap"

const sanitizeSheetName = (name: string) => {
  return name.replace(/[*?:\\/[\]]/g, "-")
}

const exportToExcel = async (dataset: Array<any>) => {
  const workbook = new ExcelJS.Workbook()

  dataset.forEach((data, index) => {
    const sanitizedSheetName = sanitizeSheetName(data.name)
    const worksheet = workbook.addWorksheet(`${sanitizedSheetName} ${index + 1}`)

    worksheet.columns = [
      { header: "Name", key: "name", width: 15 },
      { header: "0-30", key: "0-30", width: 10 },
      { header: "31-60", key: "31-60", width: 10 },
      { header: "61-90", key: "61-90", width: 10 },
      { header: "91-120", key: "91-120", width: 10 },
      { header: "121-150", key: "121-150", width: 10 },
      { header: "150+", key: "150+", width: 10 },
      { header: "Total", key: "total", width: 10 },
    ]

    worksheet.addRow({
      name: data.name,
      "0-30": data["0-30"],
      "31-60": data["31-60"],
      "61-90": data["61-90"],
      "91-120": data["91-120"],
      "121-150": data["121-150"],
      "150+": data["150+"],
      total: data.total,
    })

    data.subRows.forEach((subRow: any) => {
      worksheet.addRow({
        name: `  -> ${subRow.name}`,
        "0-30": subRow["0-30"],
        "31-60": subRow["31-60"],
        "61-90": subRow["61-90"],
        "91-120": subRow["91-120"],
        "121-150": subRow["121-150"],
        "150+": subRow["150+"],
        total: subRow.total,
      })
    })
  })

  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  const link = document.createElement("a")
  link.href = window.URL.createObjectURL(blob)
  link.download = "PortfolioCashflow.xlsx"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const ExportCashFlowPortfolio = ({ data }: { data: any }) => (
  <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
    <Button onClick={() => exportToExcel(data)} style={{ marginBottom: "8px", marginRight: "16px" }}>
      Export to Excel
    </Button>
  </div>
)

export default ExportCashFlowPortfolio
