const RecordUtils = {
  groupDocuments(recordDocuments) {
    const fileGroups = { folders: {}, unassigned: [] }
    if (recordDocuments) {
      recordDocuments.forEach((recordDocument) => {
        if (recordDocument.folderName) {
          if (!fileGroups.folders[recordDocument.folderName]) {
            fileGroups.folders[recordDocument.folderName] = []
          }
          fileGroups.folders[recordDocument.folderName].push(recordDocument)
        } else {
          fileGroups.unassigned.push(recordDocument)
        }
      })
    }
    return fileGroups
  },

  convertTypeHierarchyToTypes(typeHierarchy) {
    const types = {}

    if (typeHierarchy) {
      typeHierarchy.forEach((type) => {
        types[type.id] = {}
        type.subTypes.forEach((subType) => {
          if (subType.folders) {
            types[type.id][subType.id] = {}
            subType.folders.forEach((folder) => {
              types[type.id][subType.id][folder] = true // enable all sub types by default
            })
          } else {
            types[type.id][subType.id] = true // enable all sub types by default
          }
        })
      })
    }

    return types
  },
}

export default RecordUtils
