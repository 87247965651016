import React from "react"
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import _ from "lodash"
import { DateTime } from "luxon"
import "./RFITile.scss"

/**
 * An RFI tile.
 * @param rfi - The RFI.
 * @param author - The RFI author.
 * @param selected - Whether the RFI is currently selected.
 * @param onSelect - The callback for when the RFI tile is selected.
 * @constructor
 */
export const RFITile = ({ rfi, author, selected, onSelect }: { rfi: RFIStored; author: User; selected: boolean; onSelect: () => void }): JSX.Element => {
  const isPastDue = DateTime.fromISO(rfi.dueDateTime).diffNow().milliseconds < 0
  return (
    <>
      {rfi.resolved || (isPastDue && <UncontrolledTooltip target={rfi.id}>{rfi.resolved ? "RFI has been resolved" : "RFI is past due date"}</UncontrolledTooltip>)}
      <ListGroupItem
        id={rfi.id}
        className={selected ? "rfi-tile-selected" : rfi.resolved ? "rfi-tile-resolved" : isPastDue ? "rfi-tile-past-due" : "rfi-tile"}
        active={selected}
        onClick={() => onSelect.call(this)}
        href={"#"}
        tag={"a"}
      >
        <ListGroupItemHeading>
          #{rfi.number} - {rfi.name}
        </ListGroupItemHeading>
        <ListGroupItemText>{`${DateTime.fromISO(rfi.createdDateTime).toLocaleString()} by ${author ? `${author.attributes.firstName} ${author.attributes.lastName}` : "Unknown"}`}</ListGroupItemText>
        {_.truncate(rfi.description && rfi.description !== "" ? rfi.description : "No description provided.", { length: 100 })}
      </ListGroupItem>
    </>
  )
}

export default RFITile
