import { useQuery } from "react-query"
import { get } from "../setup"

type Rates = any

export const getProformaRatesApi = (projectId: string) => get<Array<Rates>>(`/finance/projects/${projectId}/pro-forma/rates`)

export function useGetProformaRates(projectId: string) {
  return useQuery({
    queryKey: [`proformaRates-${projectId}`],
    queryFn: async () => {
      const response = await getProformaRatesApi(projectId)
      return response
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  })
}
