import React, { Component } from "react"

import { NavLink } from "react-router-dom"

import ProjectMenu from "@common/project/ProjectMenu"
import { ModuleSelector } from "src/dashboard/SideBar"
import i18n from "src/i18n"

const { t } = i18n
export default class SideBar extends Component {
  render() {
    return (
      <div className="App-sidebar">
        <ProjectMenu {...this.props} showFilters={false} />
        <EconodeModuleSelector groups={this.props.groups} version={this.props.version} moduleViews={this.props.moduleViews} />
      </div>
    )
  }
}

class EconodeModuleSelector extends ModuleSelector {
  render() {
    return (
      <div className="nav">
        {this.checkAccess("event") ? (
          <NavLink className="content-selector" to="/events">
            {t("views.Events")}
          </NavLink>
        ) : null}
        {this.checkAccess("energy") ? (
          <NavLink className="content-selector" to="/energy">
            {t("views.Energy")}
          </NavLink>
        ) : null}
        {this.checkAccess("finance") ? (
          <NavLink className="content-selector" to="/economics">
            {t("views.Economics")}
          </NavLink>
        ) : null}
        <div className="build">{"v" + this.props.version}</div>
      </div>
    )
  }
}
