import { v4 as uuid } from "uuid"
import { AWSError } from "aws-sdk"
import { CustomError } from "ts-custom-error"

/**
 * Create a generic unique key for a component.
 */
export const uniqueKey = (): string => {
  return uuid()
}

/**
 * Raised if parsing results in an ambiguous result.
 */
class AmbiguousResultError extends CustomError {
  public constructor(message?: string) {
    super(message)
  }
}

/**
 * Try and parse an error response as an error.
 * @param error - The entity to evaluate.
 * @param unknownErrorMessage - The message to use if the object cannot be evaluated.
 * @param acceptAmbiguous - Whether to accept an ambiguous string type.
 */
export const getErrorMessage = (error: string | { message: string } | AWSError | Error | unknown, acceptAmbiguous = true, unknownErrorMessage = "An unknown error has occurred"): string => {
  // If the object is already an error, return the message.
  if (error instanceof Error) return error.message

  // Check if it is a string.
  if (typeof error === "string") {
    if (acceptAmbiguous) return error
    throw new AmbiguousResultError(`Error results in ambiguous message '${error}' of type 'string'`)
  }

  // If the error has a message key, then use that.
  try {
    const errorObject = error as { message: string }
    if (errorObject.message) {
      return errorObject.message
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  try {
    // Try and make it an error.
    const errorObjectAsError = error as Error
    return errorObjectAsError.message
  } catch (e) {
    return unknownErrorMessage
  }
}

/**
 * Get the error code from an error response.
 * @param error - The error.
 * @param unknownErrorCode - The code to return if none is found.
 * @param acceptAmbiguous - Whether to accept an ambiguous string type.
 */
export const getErrorCode = (error: string | { code: string } | AWSError, acceptAmbiguous = true, unknownErrorCode?: string): string | undefined => {
  // Check if it is a string.
  if (typeof error === "string") {
    if (acceptAmbiguous) return error
    throw new AmbiguousResultError(`Error results in ambiguous code '${error}' of type 'string'`)
  }

  // Try and return either a raw object, or AWSError.
  if (error.code) return error.code

  try {
    // Try and make it an AWSError.
    const errorObjectAsAWSError = error as AWSError
    return errorObjectAsAWSError.code
  } catch (e) {
    return unknownErrorCode
  }
}
