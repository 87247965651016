import React from "react"
import moment from "moment"
import _ from "lodash"

import EcosuiteComponent, { EcosuiteComponentError, Loading } from "@common/EcosuiteComponent"
import AuditService from "./AuditService"
import UserAuditViewControls from "./UserAuditViewControls"
import i18n from "src/i18n"

const { t } = i18n
export default class UserAudit extends EcosuiteComponent {
  componentDidMount() {
    this.loadAuditChanges()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.loadAuditChanges()
    }
  }

  async loadAuditChanges() {
    this.setState({ allAuditChanges: null })

    AuditService.getAuditChangesForUsers()
      .then((response) => {
        const userDiff = response[0]?.diff
        const userGroupDiff = response[1]?.diff
        const userTypeDiff = response[2]?.diff

        // Merge userDiffs and userGroupDiffs into one diff object since they have the same IDs
        const mergedChanges = _.mergeWith(userDiff, userGroupDiff, (currValue, srcValue) => {
          if (_.isArray(currValue)) {
            return currValue.concat(srcValue)
          }
        })

        // Sort All change by timestamp with newest first
        const allAuditChanges = [].concat.apply([], Object.values({ ...mergedChanges, ...userTypeDiff })).sort((a, b) => b.timestamp - a.timestamp)

        // Create objects that will be used to filter
        const auditChangeTypes = {}
        const auditChangesByAssetType = {}
        allAuditChanges.map((change) => {
          auditChangeTypes[change.assetType] = true

          // Create filters for each entry of the assetType
          auditChangesByAssetType[change.assetType] = {
            ...auditChangesByAssetType[change.assetType],
            [change.name]: true,
          }
        })

        this.setState({
          allAuditChanges: allAuditChanges,
          auditChangeTypes: auditChangeTypes,
          auditChangesByAssetType: auditChangesByAssetType,
        })
      })
      .catch((err) => {
        this.setState({ allAuditChanges: new EcosuiteComponentError(err) })
      })
  }

  /**
   * @param {Object} diffs - diffs object that is returned by API
   * @returns {Object} - formattedDiffs = { "combinedPath": { lhs: [prevChanges], rhs: [currChanges] }}
   */
  formatDiffs(diffs) {
    const formattedDiffs = {}
    // Group each diff from the response into buckets
    // whose names are the diff's combined path
    diffs.forEach((diff) => {
      if (typeof diff.path.at(-1) == "number") {
        diff.path.pop()
      }
      const fullPath = diff.path.join(".").replace("modules.", "")
      if (formattedDiffs[fullPath]) {
        formattedDiffs[fullPath].push({ ...diff })
      } else {
        formattedDiffs[fullPath] = [diff]
      }
    })

    // Flatten lhs and rhs into Arrays for each diff bucket
    Object.keys(formattedDiffs).forEach((key) => {
      const lhs = []
      const rhs = []
      formattedDiffs[key].forEach((change) => {
        if (change.item) {
          lhs.push(change.item.lhs)
          rhs.push(change.item.rhs)
        } else {
          lhs.push(change.lhs)
          rhs.push(change.rhs)
        }
      })

      // Removes falsey values like undefined and empty string so they don't get rendered
      formattedDiffs[key] = { lhs: lhs.filter(Boolean), rhs: rhs.filter(Boolean) }
    })

    return formattedDiffs
  }

  renderDiff(diffs) {
    const formattedDiffs = this.formatDiffs(diffs)
    return (
      <table className="audit-diff">
        <thead>
          <tr>
            <th>{t("table_headings.priority")}</th>
            <th>{t("table_headings.original_value")}</th>
            <th>{t("table_headings.new_value")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(formattedDiffs).map((path) => {
            const lhs = formattedDiffs[path].lhs
            const rhs = formattedDiffs[path].rhs

            return (
              <tr key={path}>
                <td>{path}</td>
                <td className="original">
                  <pre>
                    {
                      // Remove brackets and quotes from JSON string
                      JSON.stringify(lhs, null, 1).replace(/[[\]"]+/g, "")
                    }
                  </pre>
                </td>
                <td className="new">
                  <pre>{JSON.stringify(rhs, null, 1).replace(/[[\]"]+/g, "")}</pre>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderContent() {
    if (this.state.allAuditChanges) {
      return (
        <div className="Admin-content">
          <table className="audit-log">
            <thead>
              <tr>
                <th>{t("table_headings.type")}</th>
                <th>{t("table_headings.id")}</th>
                <th>{t("table_headings.name")}</th>
                <th>{t("table_headings.user")}</th>
                <th>{t("table_headings.timestamp")}</th>
                <th className="audit-changes">{t("table_headings.changes")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.allAuditChanges
                .filter((change) => {
                  return (
                    // Verify that the asset type is toggled
                    this.state.auditChangeTypes[change.assetType] &&
                    // Verify that the name of this property is toggled
                    this.state.auditChangesByAssetType[change.assetType][change.name]
                  )
                })
                .map((change, idx) => {
                  if (change.diff && change.diff.length) {
                    return (
                      <tr key={`${idx}`}>
                        <td>{change.assetType}</td>
                        <td>{change.id}</td>
                        <td>{change.name}</td>
                        <td title={change.userId}>{change.userName}</td>
                        <td>{moment(change.timestamp).format("lll")}</td>
                        <td>
                          <div>
                            {change.note ? <p>{change.note}</p> : null}
                            {this.renderDiff(change.diff)}
                          </div>
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
            </tbody>
          </table>
          <UserAuditViewControls
            auditFilters={[
              // Filters by asset type
              { filters: this.state.auditChangeTypes, key: "auditChangeTypes", name: "Audit Types" },
              // Filters by each entry in the asset type
              ...Object.entries(this.state.auditChangesByAssetType ?? {}).map(([key, filters]) => {
                return { filters: filters, key: key, name: `${key.replaceAll("-", " ")}s` }
              }),
            ]}
            toggleAuditFilters={(key, toggledFilter) => {
              if (key === "auditChangeTypes") {
                // Update Asset Type Filter
                this.setState({ auditChangeTypes: toggledFilter })
              } else {
                // Update asset type Entry Filter
                this.setState((prev) => ({
                  auditChangesByAssetType: { ...prev.auditChangesByAssetType, [key]: toggledFilter },
                }))
              }
            }}
          />
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
