import React, { useEffect, useState } from "react"
import BackdropDialog, { BackdropOptionProps, OptionInfo, OptionInfoBundle } from "@common/input/button/BackdropDialog"

import { type Project } from "@ecogy-types/openapi-types"
import SearchService from "@common/project/SearchService"
import i18n from "src/i18n"

interface SalesforceProjectImportDialogProps {
  showDialog: boolean
  setShowDialog: () => void
  dialogOnConfirm: (project: Project) => void
}
interface ProjectData {
  full_address: string
  town: string
  state: string
  account_owner: string
}

interface ProjectDataMap {
  [name: string]: ProjectData
}

interface Option {
  [key: string]: string
}
interface SalesforceAccountOwner {
  Name: string
  attributes: object
}

interface SalesforceRecord {
  ShippingStreet: string
  ShippingCity: string
  ShippingState: string
  Name: string
  Owner: SalesforceAccountOwner
}

interface SalesforceData {
  [name: string]: SalesforceRecord
}
const { t } = i18n
const SalesforceProjectImportDialog = ({ showDialog, setShowDialog, dialogOnConfirm }: SalesforceProjectImportDialogProps) => {
  const defaultOptionInfoBundle: OptionInfoBundle = {
    color: "",
    text: "",
    performColor: "",
    performText: "",
  }
  const defaultOptionInfo: OptionInfo = {
    "": defaultOptionInfoBundle,
  }
  const defaultBackdropOptionProps: BackdropOptionProps = {
    optionInfo: defaultOptionInfo,
  }

  const defaultProjectData: ProjectData = {
    full_address: "",
    town: "",
    state: "",
    account_owner: "",
  }

  const defaultProjectDataMap: ProjectDataMap = {
    "": defaultProjectData,
  }
  const [searchTerm, setSearchTerm] = useState("")
  const [disableConfirm, setDisableConfirm] = useState(true)
  const [optionProps, setOptionProps] = useState(defaultBackdropOptionProps)
  const [disableLoader, setDisableLoader] = useState(true)
  const [allProjects, setAllProjects] = useState(defaultProjectDataMap)

  const getOptions = (data: SalesforceData) => {
    const options: Option = {}
    const projectDataMap: ProjectDataMap = {}
    Object.keys(data).map((key) => {
      const current_key: string = data[key].Name.toString()
      projectDataMap[current_key] = {
        full_address: data[key].ShippingStreet,
        town: data[key].ShippingCity,
        state: data[key].ShippingState,
        account_owner: data[key].Owner.Name,
      }
      options[current_key] = current_key
    })
    setAllProjects(projectDataMap)
    return options
  }

  const getOptionInfos = (data: SalesforceData) => {
    const optionInfo: OptionInfo = {}
    Object.keys(data).map((key) => {
      const current_key: string = data[key].Name.toString()
      optionInfo[current_key] = {
        color: "info",
        text: current_key,
        performText: current_key,
        performColor: "info",
      }
    })
    return optionInfo
  }

  const resetDialogData = () => {
    setOptionProps(defaultBackdropOptionProps)
    setAllProjects(defaultProjectDataMap)
  }

  useEffect(() => {
    if (optionProps && optionProps.options && typeof optionProps.options[searchTerm] !== "undefined") {
      return
    }
    resetDialogData()
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        setDisableLoader(false)
        setDisableConfirm(true)
        SearchService.searchSalesforceProject(searchTerm).then((data) => {
          setDisableLoader(true)
          const options_values: Option = getOptions(data.records as SalesforceData)
          const option_infos: OptionInfo = getOptionInfos(data.records as SalesforceData)
          const defaultKey = Object.keys(options_values)[0]
          const projects = () => {
            const project: BackdropOptionProps = {
              options: options_values,
              defaultOptionKey: defaultKey,
              optionInfo: option_infos,
            }
            return project
          }

          setOptionProps(projects())
        })
      }
    }, 3000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  useEffect(() => {
    if (optionProps && optionProps.options && typeof optionProps.options[searchTerm] !== "undefined") {
      setDisableConfirm(false)
    } else {
      setDisableConfirm(true)
    }
  }, [optionProps && optionProps.options && typeof optionProps.options[searchTerm] !== "undefined"])

  return (
    <BackdropDialog
      isOpen={showDialog}
      type={"datalist"}
      bodyText={`${t("data.dialogs.enter_account_name_to_import_from_salesforce")}`}
      title={`${t("data.dialogs.import_from_salesforce")}`}
      onCancel={() => {
        resetDialogData()
        setShowDialog()
      }}
      disableConfirm={disableConfirm}
      disableLoader={disableLoader}
      onConfirm={() => {
        setShowDialog()
        const project: Project = {
          code: "",
          status: "New Project",
          name: searchTerm,
          address: allProjects[searchTerm].full_address,
          town: allProjects[searchTerm].town,
          state: allProjects[searchTerm].state,
          bizDevOwner: allProjects[searchTerm].account_owner,
          lat: 0,
          long: 0,
          startDate: "",
          timezone: "",
        }
        dialogOnConfirm(project)
      }}
      inputOnChangeHandler={async (value) => {
        if (value !== "" && typeof value === "string" && value != searchTerm) {
          setSearchTerm(value)
        }
      }}
      optionProps={optionProps}
    />
  )
}

export default SalesforceProjectImportDialog
