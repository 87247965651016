import API from "@common/API"
import Logger from "@common/Logger"

const SlackServices = {
  async getSlackChannels(): Promise<unknown> {
    return API.get<{ slackChannels: unknown }>("/slack/channels")
      .then((data) => {
        return data
      })
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: "Unable to retrieve slack channels" })
      })
  },
}

export default SlackServices
