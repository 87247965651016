import React from "react"
import { ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import EcosuiteComponent from "@common/EcosuiteComponent"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const ENABLED = "e"
const DISABLED = "d"
const INTERMEDIATE = "i"
const { t } = i18n

/**
 * The RecordDocumentFilter is an extension of the TypeFilter renders controls to select elements of a type hierarchy. The structure looks like:
 * [
 *  {
 *    id: <type ID>,
 *    name: <type name>,
 *    subTypes: [
 *      {
 *        id: <sub type ID>,
 *        name: <sub type name>
 *        folders: [string]
 *      },
 *      ...
 *    ]
 *  },
 * ...
 * ]
 */
export default class RecordDocumentFilter extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      openTypes: {},
    }

    this.toggleFilter = this.toggleFilter.bind(this)
    this.toggleSubType = this.toggleSubType.bind(this)
    this.showSubType = this.showSubType.bind(this)
    this.hideSubType = this.hideSubType.bind(this)
  }

  toggleFilter(type) {
    let openTypes = this.state.openTypes
    openTypes[type] = !openTypes[type]
    this.setStateIfMounted({ openTypes: openTypes })
  }

  getTypeStatus(type) {
    if (type === undefined || type === null) {
      return DISABLED
    } else if (typeof type === "boolean") {
      return type ? ENABLED : DISABLED
    } else if (typeof type === "object") {
      var enabled = false
      var disabled = false
      var intermediate = false
      Object.values(type).forEach((subType) => {
        const subTypeStatus = this.getTypeStatus(subType)
        if (subTypeStatus === ENABLED) {
          enabled = true
        } else if (subTypeStatus === DISABLED) {
          disabled = true
        } else if (subTypeStatus === INTERMEDIATE) {
          intermediate = true
        } else {
          throw Error(`Unexpected status: ${subTypeStatus}`)
        }
      })

      if (intermediate || (enabled && disabled)) {
        return INTERMEDIATE
      } else if (enabled) {
        return ENABLED
      } else if (disabled) {
        return DISABLED
      } else {
        throw Error(`Unexpected status`)
      }
    } else {
      throw Error(`Unexpected typeof: ${typeof type} for: ${type}`)
    }
  }

  getTypeName(typeId, subTypeId, folderName) {
    if (folderName) {
      return folderName
    }
    const type = this.props.typeHierarchy.find((type) => type.id === typeId)
    if (subTypeId) {
      const subType = type.subTypes.find((subType) => subType.id === subTypeId)
      return subType.name
    } else {
      return type.name
    }
  }

  toggleSubType() {
    this.setState({
      subTypeId: !this.state.subTypeId,
    })
  }

  showSubType(subTypeId) {
    this.setState({ subTypeId: subTypeId })
  }

  hideSubType() {
    this.setState({ subTypeId: null })
  }

  /**
   * The following props are expected:
   * selectedTypes The state of the selected elements in the hierarchy
   *
   * toggleType Used to toggle if a type is enabled or not
   * toggleSubType Used to toggle is a types sub type is enabled or not
   */
  renderContent() {
    if (this.props.availableTypes && this.props.selectedTypes && this.props.typeHierarchy) {
      return (
        <ButtonGroup className="header-button-group type-filter">
          {Object.keys(this.props.availableTypes).map((typeId) => {
            const type = this.props.availableTypes[typeId]
            const selectedType = this.props.selectedTypes[typeId]
            const typeStatus = this.getTypeStatus(selectedType)
            return (
              <ButtonDropdown
                isOpen={this.state.openTypes && this.state.openTypes[typeId]}
                toggle={() => {
                  this.toggleFilter(typeId)
                }}
                size="sm"
                key={typeId}
              >
                <DropdownToggle className={`type-${typeId}`} color={`type-${typeId}`} caret>
                  <span>
                    {this.props.toggleType ? (
                      <Icon
                        icon={typeStatus === INTERMEDIATE ? "indeterminate_check_box" : typeStatus === ENABLED ? "check_box" : "check_box_outline_blank"}
                        onClick={(e) => {
                          this.props.toggleType(e, typeId)
                        }}
                      />
                    ) : null}
                    {t(`typeNames.${this.getTypeName(typeId)}`)}
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  {Object.keys(this.props.availableTypes[typeId])
                    .sort()
                    .map((subTypeId) => {
                      const subType = type[subTypeId]
                      const selectedSubType = selectedType[subTypeId]
                      const subTypeStatus = this.getTypeStatus(selectedSubType)

                      if (typeof subType === "object") {
                        return (
                          <ButtonDropdown
                            isOpen={this.state.subTypeId === subTypeId}
                            onMouseOver={() => {
                              this.showSubType(subTypeId)
                            }}
                            onMouseLeave={this.hideSubType}
                            toggle={this.toggleSubType}
                            key={subTypeId}
                            direction="end"
                            className="menu-sub-type"
                          >
                            <DropdownToggle caret>
                              <Icon
                                onClick={(e) => {
                                  this.props.toggleSubType(e, typeId, subTypeId)
                                }}
                                icon={subTypeStatus === INTERMEDIATE ? "indeterminate_check_box" : subTypeStatus === ENABLED ? "check_box" : "check_box_outline_blank"}
                              />{" "}
                              {t(`types.${this.getTypeName(typeId, subTypeId)}`)}
                            </DropdownToggle>
                            <DropdownMenu end>
                              {Object.keys(subType)
                                .sort()
                                .map((folder) => {
                                  const folderStatus = this.getTypeStatus(selectedSubType[folder])
                                  return (
                                    <DropdownItem key={folder} toggle={false}>
                                      <Icon
                                        onClick={(e) => {
                                          this.props.toggleFolder(e, typeId, subTypeId, folder)
                                        }}
                                        icon={folderStatus === INTERMEDIATE ? "indeterminate_check_box" : folderStatus === ENABLED ? "check_box" : "check_box_outline_blank"}
                                      />{" "}
                                      {folder.trim()}
                                    </DropdownItem>
                                  )
                                })}
                            </DropdownMenu>
                          </ButtonDropdown>
                        )
                      } else {
                        return (
                          <DropdownItem
                            key={subTypeId}
                            onClick={(e) => {
                              this.props.toggleSubType(e, typeId, subTypeId)
                            }}
                            toggle={false}
                          >
                            <Icon icon={subTypeStatus === INTERMEDIATE ? "indeterminate_check_box" : subTypeStatus === ENABLED ? "check_box" : "check_box_outline_blank"} />{" "}
                            {this.getTypeName(typeId, subTypeId)}
                          </DropdownItem>
                        )
                      }
                    })}
                </DropdownMenu>
              </ButtonDropdown>
            )
          })}
        </ButtonGroup>
      )
    }
    return null
  }
}
