import React from "react"
import moment from "moment"

import { Loading, Message } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import i18n from "src/i18n"

const { t } = i18n
export default class DRNotificationView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.state = {
      showTooltip: true,
      notificationTableSelected: {},
      notificationSelected: null,
    }

    this.clearSelectedNotifications = this.clearSelectedNotifications.bind(this)
  }

  isReadOnly() {
    return false
  }

  setTooltipNotification(notification) {
    this.setStateIfMounted({
      tooltipNotification: notification,
    })
  }

  /**
   * Clear the selected notifications.
   */
  clearSelectedNotifications() {
    this.setStateIfMounted({
      notificationTableSelected: {},
    })
  }

  renderViewControls() {
    return null
  }

  renderMainView() {
    if (this.state.loading) {
      return <Loading />
    }

    const notifications = this.props.notifications
    const users = this.props.users

    if (notifications && users) {
      if (notifications.length > 0) {
        const tooltipNotification = this.state.tooltipNotification
        return (
          <div className="notifications">
            <table className="notifications-table">
              <thead>
                <tr>
                  <th>{t("table_headings.event")}</th>
                  <th>{t("table_headings.relevant_projects")}</th>
                  <th>{t("table_headings.notification_date")}</th>
                  <th>{t("table_headings.user")}</th>
                  <th>{t("labels.user")}</th>
                  <th>{t("labels.protocol")}</th>
                  {this.isReadOnly() ? null : <th></th>}
                </tr>
              </thead>
              <tbody>
                {notifications.map((notification) => {
                  let selectedClass = tooltipNotification && tooltipNotification.id === notification.id ? "selected" : null
                  const user = users.find((user) => user.id === notification.user)

                  return (
                    <tr
                      key={notification.id}
                      className={selectedClass ? selectedClass : null}
                      onMouseOver={() => this.setTooltipNotification(notification)}
                      onMouseOut={() => this.setTooltipNotification()}
                    >
                      <td>{notification.event}</td>
                      <td>{notification.targets.length > 1 ? notification.targets[0] + " and " + (notification.targets.length - 1) + " others" : notification.targets[0]}</td>
                      <td>{moment(notification.date).format("llll")}</td>
                      <td>{user ? user.displayName : `${t("labels.unknown")}`}</td>
                      <td>{notification.protocol}</td>
                      <td>{notification.endpoint}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {/*this.getTooltip(tooltipNotification)*/}
          </div>
        )
      } else {
        return <Message message={`${t("messages.no_notification")}`} />
      }
    } else {
      return <Loading />
    }
  }
}
