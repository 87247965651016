import React, { useEffect, useState } from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { Auth } from "aws-amplify"
import { Spinner } from "src/components/ui/spinner"
import { toast } from "react-toastify"

export const ONBOARDING_USER_TYPE = "default-user-type-ONBOARDING"

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    checkAuthState()
  }, [])

  async function checkAuthState() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user.attributes["custom:userType"] !== ONBOARDING_USER_TYPE) {
        return <Redirect to="/" />
      }
      setIsAuthenticated(true)
    } catch (error) {
      setIsAuthenticated(false)
    }
    setIsLoading(false)
  }

  if (isLoading) {
    return (
      <div className="public-view tw-bg-background tw-text-foreground tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Spinner size="large">Loading...</Spinner>
      </div>
    )
  }

  if (!isAuthenticated) {
    return <Redirect to="/onboarding/login" />
  }

  return children
}

export default PrivateRoute
