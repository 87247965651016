import React from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { InviteUserPayload, inviteUserToOrganizationApi } from "src/services/users"
import { toast } from "react-toastify"
import { Button } from "src/components/ui/button"
import { Input } from "src/components/ui/input"
import { Label } from "src/components/ui/label"
import { Checkbox } from "src/components/ui/checkbox"
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from "src/components/ui/select"
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "src/components/ui/accordion"
import UserAdminService from "./UserAdminService"
import { Settings, ChevronDown } from "lucide-react"

export const availableGroups = [
  "connectivity",
  "consumption",
  "contacts",
  "data-analysis",
  "data-write",
  "demandresponse",
  "energy",
  "event",
  "event-write",
  "finance",
  "generation",
  "legal",
  "notes",
  "power-user",
  "processes",
  "reseller",
  "solarnetwork",
  "storage",
  "user-admin",
  "user-limited-admin",
]

interface FormData extends Omit<InviteUserPayload, "accessPolicies"> {
  accessPolicies: Record<string, boolean>
}

interface UserType {
  id: string
  name: string
  defaultGroups: string[]
}

const InviteUserToOrganization: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      accessPolicies: {},
    },
  })

  const [userTypes, setUserTypes] = React.useState<UserType[]>([])

  const queryClient = useQueryClient()

  React.useEffect(() => {
    async function getAndSetUserTypes() {
      const fetchedUserTypes = (await UserAdminService.getUserTypes()) as {
        userTypes: UserType[]
      }
      setUserTypes(fetchedUserTypes.userTypes)
    }
    getAndSetUserTypes()
  }, [])

  const mutation = useMutation(inviteUserToOrganizationApi, {
    onSuccess: () => {
      queryClient.refetchQueries(["externalUsers"], { active: false })
      reset()
      toast.success("User invited successfully!")
    },
    onError: (error: Error) => {
      toast.error(`An error occurred: ${error.message}`)
    },
  })

  const userTypeId = watch("userTypeId")

  React.useEffect(() => {
    if (userTypeId) {
      const selectedUserType = userTypes.find((type) => type.id === userTypeId)
      if (selectedUserType) {
        const defaultPolicies: Record<string, boolean> = {}
        availableGroups.forEach((group) => {
          defaultPolicies[group] = selectedUserType.defaultGroups.includes(group)
        })
        setValue("accessPolicies", defaultPolicies)
      }
    }
  }, [userTypeId, userTypes, setValue])

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const selectedPolicies = Object.entries(data.accessPolicies)
      .filter(([_, isChecked]) => isChecked)
      .map(([policy]) => policy)

    const payload: InviteUserPayload = {
      ...data,
      accessPolicies: selectedPolicies,
    }

    mutation.mutate(payload)
  }

  return (
    <div
      className="dark  tw-bg-background tw-text-foreground tw-px-4 tw-py-6 tw-h-[calc(100vh-80px)])"
      style={{ height: "calc(100vh - 80px)" }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-4">
        {/* Email Address Field */}
        <Controller
          name="userEmail"
          control={control}
          rules={{ required: "Email is required" }}
          render={({ field }) => (
            <div>
              <Label htmlFor="email">Email Address</Label>
              <Input
                id="email"
                type="email"
                placeholder="john@example.com"
                {...field}
                className={errors.userEmail ? "tw-border-red-500" : ""}
              />
              {errors.userEmail && <p className="tw-text-red-500 tw-text-sm">{errors.userEmail.message}</p>}
            </div>
          )}
        />

        {/* User Type Selection */}
        <Controller
          name="userTypeId"
          control={control}
          rules={{ required: "User Type is required" }}
          render={({ field }) => (
            <div>
              <Label htmlFor="userType">User Type</Label>
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger id="userType">
                  <SelectValue placeholder="Select a user type" />
                </SelectTrigger>
                <SelectContent>
                  {userTypes.map((userType) => (
                    <SelectItem key={userType.id} value={userType.id}>
                      {userType.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {errors.userTypeId && <p className="tw-text-red-500 tw-text-sm">{errors.userTypeId.message}</p>}
            </div>
          )}
        />

        {/* Access Policies Accordion */}
        <Accordion type="single" collapsible className="tw-mt-4">
          <AccordionItem value="access-policies">
            <AccordionTrigger className="tw-flex tw-items-center">
              <div className="tw-flex">
                <Settings className="tw-w-5 tw-h-5" />
                <span className="tw-ml-2">Access Policies</span>
              </div>
              {/* <ChevronDown
                className="tw-w-5 tw-h-5 tw-ml-auto tw-transform tw-transition-transform tw-duration-200 group-open:tw-rotate-180"
                aria-hidden="true"
              /> */}
            </AccordionTrigger>
            <AccordionContent>
              <div className="tw-grid tw-grid-cols-2 tw-gap-2 tw-mt-2">
                {availableGroups.map((group) => (
                  <Controller
                    key={group}
                    name={`accessPolicies.${group}`}
                    control={control}
                    render={({ field }) => (
                      <div className="tw-flex tw-items-center">
                        <Checkbox
                          id={`accessPolicies.${group}`}
                          checked={field.value || false}
                          onCheckedChange={field.onChange}
                        />
                        <Label htmlFor={`accessPolicies.${group}`} className="tw-ml-2 tw-capitalize">
                          {group.replace(/-/g, " ")}
                        </Label>
                      </div>
                    )}
                  />
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {/* Submit Button */}
        <div>
          <Button type="submit" className="tw-mt-4" disabled={mutation.isLoading}>
            {mutation.isLoading ? "Inviting..." : "Invite User"}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default InviteUserToOrganization
