import { Organization } from "src/services/organization"

export class CredentialService {
  /**
   * Returns a list of configured SolarNetwork credential aliases
   * @param organization The organization to get credentials from
   * @returns Array of credential aliases/names
   */
  static getConfiguredCredentialNames(organization?: Organization): string[] {
    if (!organization?.solarNetworkCredentials?.length) {
      return []
    }

    return organization.solarNetworkCredentials
      .filter(cred => cred.alias)
      .map(cred => cred.alias as string)
  }
}

