import React from "react"
import { TextField as AmplifyTextField, PasswordField as AmplifyPasswordField } from "@aws-amplify/ui-react"

export const TextField = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof AmplifyTextField>>(
  (props, ref) => {
    return (
      <AmplifyTextField
        {...props}
        label={props.label && <div style={{ marginBottom: "-8px" }}>{props.label}</div>}
        ref={ref}
        inputStyles={{
          backgroundColor: "#FFFFFF",
          height: "32px",
          marginBottom: "8px",
        }}
      />
    )
  },
)

export const PasswordField = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof AmplifyPasswordField>>(
  (props, ref) => {
    return (
      <AmplifyPasswordField
        {...props}
        label={props.label && <div style={{ marginBottom: "-8px" }}>{props.label}</div>}
        ref={ref}
        inputStyles={{
          backgroundColor: "#FFFFFF",
          height: "32px",
          marginBottom: "8px",
        }}
      />
    )
  },
)
