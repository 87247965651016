interface Settings {
  getSetting<T>(setting: string, defaultValue: T, application?: string): T
  setSetting<T>(setting: string, value: T, application?: string): void
}

const Settings: Settings = {
  getSetting<T>(setting: string, defaultValue: T, application?: string): T {
    const localStorageKey = application ? `${application}-${setting}` : setting
    const value = JSON.parse(localStorage.getItem(localStorageKey) || "null") as T | null
    if (value === null) {
      return defaultValue
    }
    return value
  },

  setSetting<T>(setting: string, value: T, application?: string): void {
    const localStorageKey = application ? `${application}-${setting}` : setting
    if (localStorage) {
      if (value !== null && value !== undefined) {
        localStorage.setItem(localStorageKey, JSON.stringify(value))
      } else {
        localStorage.removeItem(localStorageKey)
      }
    }
  },
}

export default Settings
