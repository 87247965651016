import React, { useState } from "react"
import { getErrorMessage } from "@common/SharedComponentUtils"
import { Alert, Button, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"

const { t } = i18n
export interface TwoStepDialogProps {
  title: string
  body?: JSX.Element

  inputTooltip?: string
  onConfirm?: () => void
  onComplete?: () => void
  onCancel?: () => void

  performText?: string
  performTextColor?: string

  confirmText?: string
  confirmTooltip?: string
  cancelTooltip?: string

  displayText?: string
  displayTextColor?: string

  displayResult?: JSX.Element
  displayResultBgColor?: string

  isOpen?: boolean
  disabled?: boolean

  twoStepTooltip?: string
  twoStepText?: string

  onTwoStep?: () => void

  disableTwoStep?: boolean
}

export const TwoStepDialog = (props: TwoStepDialogProps): JSX.Element => {
  const [performingActionPlainText, setPerformingActionPlainText] = useState<string | undefined>("")
  const [successful, setSuccessful] = useState<boolean>(false)

  const [errorResponse, setErrorResponse] = useState<string | undefined>(undefined)
  const [hideTwoStep, setHideTwoStep] = useState<boolean>(false)

  const confirmId = "backdrop-dialog-confirm"
  const cancelId = "backdrop-dialog-cancel"
  const twoStepId = "backdrop-dialog-twostep"

  /**
   * Display an error.
   * @param e - The error.
   */
  const displayError = (e: unknown) => {
    setErrorResponse(getErrorMessage(e))
  }

  /**
   * Check if an action is currently being performed.
   */
  const isAction = (): boolean => {
    return performingActionPlainText !== ""
  }

  /**
   * Clear any action being performed.
   */
  const clearAction = (): void => {
    setPerformingActionPlainText("")
  }

  /**
   * Check if currently disabled.
   *
   * This includes any current input.
   */
  const isDisabled = (): boolean => {
    if (isAction()) {
      return true
    }

    return props.disabled !== undefined && !props.disabled
  }

  return (
    <>
      <Modal isOpen={props.isOpen}>
        <ModalHeader>
          <p className={"backdrop-dialog-header"}>{props.title}</p>
        </ModalHeader>
        <ModalBody>
          {props.body}
          {errorResponse && (
            <Alert color={"danger"} toggle={() => setErrorResponse(undefined)}>
              {errorResponse}
            </Alert>
          )}
        </ModalBody>
        {props.displayText && <Alert color={props.displayTextColor ?? "info"}>{props.displayText}</Alert>}
        {performingActionPlainText && <Alert color={props.performTextColor ?? "warning"}>{performingActionPlainText}</Alert>}
        {props.displayResult && <Alert color={props.displayResultBgColor}>{props.displayResult}</Alert>}
        <ModalFooter>
          <InputGroup>
            <UncontrolledTooltip target={confirmId}>{props.confirmTooltip ?? `${t("buttons.confirm")}`}</UncontrolledTooltip>
            <Button
              id={confirmId}
              disabled={isDisabled()}
              color={"primary"}
              onClick={async () => {
                setErrorResponse(undefined)
                setHideTwoStep(false)

                // Display the text associated with performing some action.
                setPerformingActionPlainText(props.performText)
                try {
                  await props.onConfirm?.call(this)

                  clearAction()
                  setSuccessful(true)
                  await props.onComplete?.call(this)
                } catch (e) {
                  clearAction()
                  setHideTwoStep(true)
                  displayError(e)
                }
              }}
            >
              {props.confirmText ?? `${t("buttons.confirm")}`}
            </Button>
            <UncontrolledTooltip target={twoStepId}>{props.twoStepTooltip ?? `${t("labels.continue")}`}</UncontrolledTooltip>
            <Button
              id={twoStepId}
              disabled={isDisabled() || hideTwoStep || !successful || props.disableTwoStep}
              color={"primary"}
              onClick={async () => {
                setErrorResponse(undefined)

                // Display the text associated with performing some action.
                setPerformingActionPlainText(props.performText)
                try {
                  await props.onTwoStep?.call(this)

                  clearAction()
                } catch (e) {
                  clearAction()
                  displayError(e)
                }
              }}
            >
              {props.twoStepText ?? `${t("labels.continue")}`}
            </Button>
            <UncontrolledTooltip target={cancelId}>{props.cancelTooltip ?? `${t("buttons.cancel")}`}</UncontrolledTooltip>
            <Button
              id={cancelId}
              disabled={isDisabled()}
              className={"backdrop-dialog-cancel"}
              color={"danger"}
              onClick={async () => {
                try {
                  await props.onCancel?.call(this)
                } catch (e) {
                  clearAction()
                  displayError(e)
                }
              }}
            >
              {t("buttons.cancel")}
            </Button>
          </InputGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}
