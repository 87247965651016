import * as React from "react"
import { useQuery } from "react-query"
import { Table, DataType, SortingMode } from "ka-table"
import { getUsers, useGetOnboardingUsers, User } from "src/services/onboarding"
import { H4 } from "src/components/ui/typography"
import { Spinner } from "src/components/ui/spinner"
import { Button } from "src/components/ui/button"
import { useHistory } from "react-router-dom"

const ActionButton: React.FC<{ userId: string }> = ({ userId }) => {
  const history = useHistory()

  const handleViewDocuments = () => {
    history.push(`/admin/onboarding/${userId}`)
  }

  return (
    <Button size="sm" onClick={handleViewDocuments}>
      View User Documents
    </Button>
  )
}

const OnboardingUsersTable: React.FC<{ users: User[] }> = ({ users }) => {
  return (
    <div className="twx-ka-table tw-pt-4">
      <Table
        columns={[
          { key: "id", title: "ID", dataType: DataType.String },
          { key: "created", title: "Created", dataType: DataType.String },
          { key: "displayName", title: "Name", dataType: DataType.String },
          { key: "action", title: "Action", dataType: DataType.String },
        ]}
        data={users.map((user) => ({
          ...user,
          created: new Date(user.created).toLocaleString(),
        }))}
        noData={{ text: "No onboarding users found!" }}
        rowKeyField="id"
        sortingMode={SortingMode.Single}
        childComponents={{
          cellText: {
            content: (props) => {
              if (props.column.key === "action") {
                return <ActionButton userId={props.rowData.id} />
              }
              return props.value
            },
          },
          tableWrapper: {
            elementAttributes: () => ({ style: { height: "calc(100vh - 128px)" } }),
          },
        }}
      />
    </div>
  )
}

export default function Onboarding() {
  const { data: onboardingUsers, isLoading, error } = useGetOnboardingUsers()

  if (isLoading) {
    return (
      <div className="public-view tw-bg-background tw-text-foreground tw-h-screen tw-flex tw-items-center tw-justify-center dark">
        <Spinner size="large">Loading...</Spinner>
      </div>
    )
  }

  if (error || !onboardingUsers) {
    return <div>Error loading users. Please try again later.</div>
  }

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-background dark tw-pt-4">
      <H4 className="tw-px-4">Onboarding Users</H4>
      {onboardingUsers.length === 0 ? (
        <p>No onboarding users found.</p>
      ) : (
        <OnboardingUsersTable users={onboardingUsers} />
      )}
    </div>
  )
}
