import React from "react"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"

export default class PreviousPeriodConsumptionComparison extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentError(this.props.previousReadings)) {
      return <Error error={this.props.previousReadings.getError()} />
    }
    if (
      this.isContentValid(this.props.readings) &&
      this.props.readings.consumption !== undefined &&
      this.isContentValid(this.props.previousReadings) &&
      this.props.previousReadings.consumption !== undefined
    ) {
      const diff = -(100 - (this.props.readings.consumption / this.props.previousReadings.consumption) * 100)
      const positive = diff >= 0
      return <div className={`sidebar-heading ${positive ? "negative" : "positive"}`} id={this.props.id}>{`${positive ? "+" : ""}${diff ? Math.round(diff === Infinity ? 0 : diff) : 0} %`}</div>
    } else if (this.props.previousReadings !== null) {
      return <Loading />
    } else {
      return null
    }
  }
}
