import API from "@common/API"

const ProfileService = {
  async getProfileSchema() {
    return API.getFromCache("/schemas/profile")
  },

  async getProfile() {
    return API.get<{ profile: unknown }>(`/profile`).then((response) => response.profile)
  },

  async editProfile(form: { formData: unknown }) {
    return API.put<{ profile: unknown }, unknown>("/profile", form.formData).then((response) => response.profile)
  },
}

export default ProfileService
