import React, { Component } from "react"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"

const { t } = i18n
class ProjectTableTooltips extends Component {
  render() {
    return (
      <React.Fragment>
        <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-code">
          {t("energy.tooltips.project_code")}
          <p className="tooltip-detail">
            <span className="text-ok">{t("energy.energyStatus.green")}</span>: {t("energy.messages.no_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-warning">{t("energy.energyStatus.orange")}</span>: {t("energy.messages.connectivity_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-error">{t("energy.energyStatus.red")}</span>: {t("energy.messages.issues_need_tobe_resolved")}
          </p>
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-name">
          {t("energy.tooltips.project_name")}
          <p className="tooltip-detail">
            <span className="text-ok">{t("energy.energyStatus.green")}</span>: {t("energy.messages.no_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-warning">{t("energy.energyStatus.orange")}</span>: {t("energy.messages.connectivity_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-error">{t("energy.energyStatus.red")}</span>: {t("energy.messages.issues_need_tobe_resolved")}
          </p>
        </UncontrolledTooltip>
        {this.props.showGeneration && !this.props.showConsumption ? (
          <React.Fragment>
            {/* Table Header Tooltips */}
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-dc-size">
              {t("energy.tooltips.combined_dc_size")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-ac-size">
              {t("energy.tooltips.combined_ac_size")}
            </UncontrolledTooltip>

            {/* Totals and Averages Tooltips */}
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__total-dc-size">
              {t("energy.tooltips.total_ac_size")}{" "}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__total-ac-size">
              {t("energy.tooltips.total_dc_size")}{" "}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : null}
        {this.props.showConsumption && !this.props.showGeneration ? (
          <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-peak-power">
            {t("energy.tooltips.combined_expected_peak")}
          </UncontrolledTooltip>
        ) : null}

        {this.props.showConsumption ? (
          <React.Fragment>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-consumption">
              {t("energy.tooltips.project_level_consumption")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-consumption-predicted">
              <p className="tooltip-detail">{t("energy.tooltips.calculation_explanation")}</p>
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-consumption-predicted-percent">
              {t("energy.tooltips.actual_to_forecast_consumption")}
            </UncontrolledTooltip>
            {!this.props.showGeneration ? (
              <React.Fragment>
                <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-peak-consumption">
                  {t("energy.tooltips.observed_peak_consumption")}
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-peak-consumption-percent">
                  {t("energy.tooltips.expected_peak_to_peak_consumption")}
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-consumption-cost">
                  {t("energy.tooltips.observed_consumption_cost")}
                  <p className="tooltip-detail">{t("energy.tooltips.observed_consumption_cost_calculation")}</p>
                </UncontrolledTooltip>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}

        {this.props.showGeneration ? (
          <React.Fragment>
            {/* Table Header Tootltips */}
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-generation">
              {t("energy.tooltips.project_level_generation")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-generation-expected">
              {t("energy.tooltips.weather_adjusted_expected_gen")}
              <p className="tooltip-detail">{t("energy.tooltips.weather_adjusted_expected_gen_calculation")}</p>
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-generation-expected-percent">
              {t("energy.tooltips.actual_expected_generation")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-generation-predicted">
              {t("energy.tooltips.forecast_project_level_gen")}
              <p className="tooltip-detail">{t("energy.tooltips.forecast_project_level_gen_calculation")}</p>
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-generation-predicted-percent">
              {t("energy.tooltips.actual_forecast_gen")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="specific-yield">
              {t("energy.tooltips.specific_yield")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-generation-normalised">
              {t("energy.tooltips.actual_generation_normalised")}
            </UncontrolledTooltip>

            {/* Totals and Averages Tooltips */}
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__total-gen">
              {t("energy.tooltips.total_generation")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__total-gen-expected">
              {t("energy.tooltips.weather_adjusted_expected_gen_total")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__average-gen-expected-percent">
              {t("energy.tooltips.actual_forecast_gen_average")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__total-gen-forecast">
              {t("energy.tooltips.total_forecasted_generation")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__average-gen-forecast-percent">
              {t("energy.tooltips.actual_forecast_gen_average")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__average-gen-normalised">
              {t("energy.tooltips.actual_normalized_gen_average")}
            </UncontrolledTooltip>

            {!this.props.showConsumption ? (
              <React.Fragment>
                {/* Table Header Tootltips */}
                <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-generation-peak">
                  {t("energy.tooltips.observed_peak_generation")}
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-generation-peak-percent">
                  {t("energy.tooltips.peak_generation_percentage")}
                </UncontrolledTooltip>

                {/* Totals and Averages Tooltips */}
                <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__total-gen-peak">
                  {t("energy.tooltips.combined_total_observed_peak")}
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="projects-table__average-gen-peak-percent">
                  {t("energy.tooltips.average_peak_gen_weighted")}
                </UncontrolledTooltip>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }
}

export default ProjectTableTooltips
