import React, { useState, useMemo, useCallback } from "react"
import { Table, DataType, SortingMode } from "ka-table"
import { Button } from "src/components/ui/button"
import { EyeOpenIcon, DownloadIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { useParams } from "react-router-dom"
import { Spinner } from "src/components/ui/spinner"
import { useGetPublicDocuments } from "src/services/record/documents"
import "./public-view.scss"
import { H3, H4, P } from "src/components/ui/typography"
import Header, { ThemeProvider } from "../Components/Header"
import Fuse from "fuse.js"
import { Input } from "src/components/ui/input"
import { isEmpty } from "lodash"
import moment from "moment"
import { Project } from "../types"
import { downloadFile, PreviewDialog, useFilePreview } from "../Components/Preview"

interface Document {
  fileKey?: string
  fileName?: string
  fileUrl?: string
  folderName?: string
  recordName: string
  recordPath: string
  project: string
  site?: string
  recordType: string
}

const useDocuments = (uuid: string | undefined) => {
  const { data, isLoading } = useGetPublicDocuments(uuid)

  const flattenedData = useMemo(
    () =>
      data?.records.flatMap((record) =>
        record.documents.map((doc) => ({
          ...doc,
          recordName: record.recordName,
          recordPath: record.recordPath,
          recordType: record.recordType as string,
          project: record.project,
          site: record.site,
        })),
      ) || [],
    [data],
  )

  const fuse = useMemo(
    () =>
      new Fuse(flattenedData, {
        keys: ["fileName", "recordPath", "project", "site", "recordName", "recordType"],
        threshold: 0.3,
      }),
    [flattenedData],
  )

  const getFilteredData = useCallback(
    (searchTerm: string) => (!searchTerm ? flattenedData : fuse.search(searchTerm).map((result) => result.item)),
    [flattenedData, fuse],
  )

  const getSelectedProjects = useCallback(
    () => data?.selectedPortfolio?.projects || (data?.selectedProject ? [data.selectedProject] : []),
    [data],
  )

  return {
    data,
    isLoading,
    flattenedData,
    getFilteredData,
    getSelectedProjects,
  }
}

const PublicViewSharedDocuments: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const { data, isLoading, getFilteredData, getSelectedProjects } = useDocuments(uuid)
  const { previewFile, setPreviewFile, pdfLoadError, handlePreview, isLoading: loadingPreview } = useFilePreview()
  const [searchTerm, setSearchTerm] = useState<string>("")

  const filteredData = useMemo(() => getFilteredData(searchTerm), [getFilteredData, searchTerm])
  const selectedProjects = useMemo(() => getSelectedProjects(), [getSelectedProjects])

  const handleDownload = useCallback((file: Document) => {
    if (file.fileUrl && file.fileName) {
      downloadFile(file.fileUrl, file.fileName)
    }
  }, [])

  const handleDownloadAll = useCallback(() => {
    filteredData.forEach((file: Document) => {
      if (file.fileUrl && file.fileName) {
        downloadFile(file.fileUrl, file.fileName)
      }
    })
  }, [filteredData])

  if (isLoading)
    return (
      <ThemeProvider>
        <div className="public-view tw-bg-background tw-text-foreground tw-h-screen tw-flex tw-items-center tw-justify-center">
          <Spinner size="large">Loading...</Spinner>
        </div>
      </ThemeProvider>
    )
  if (!data)
    return (
      <div className="public-view tw-bg-background tw-text-foreground tw-h-screen tw-flex tw-items-center tw-justify-center">
        No data available
      </div>
    )

  return (
    <ThemeProvider>
      <div className="public-view tw-bg-background tw-text-foreground">
        <Header logo={data.organization.logo} orgName={data.organization.name} />
        <div className="tw-flex">
          <Sidebar selectedPortfolio={data.selectedPortfolio?.name} selectedProjects={selectedProjects} />
          <MainContent
            createdAt={data.createdAt!}
            orgName={data.organization.name}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filteredData={filteredData}
            handlePreview={handlePreview}
            handleDownload={handleDownload}
            handleDownloadAll={handleDownloadAll}
          />
        </div>
        <PreviewDialog
          previewFile={previewFile}
          setPreviewFile={setPreviewFile}
          pdfLoadError={pdfLoadError}
          isLoading={loadingPreview}
        />
      </div>
    </ThemeProvider>
  )
}

const Sidebar: React.FC<{ selectedPortfolio?: string; selectedProjects: Project[] }> = ({
  selectedPortfolio,
  selectedProjects,
}) => (
  <div className="tw-h-100vh tw-w-[300px] sidebar tw-p-4">
    <H4>Selected Portfolio</H4>
    <P>- {selectedPortfolio || "-"}</P>
    <H4 className="tw-mt-8">Selected Projects</H4>
    {isEmpty(selectedProjects)
      ? "-"
      : selectedProjects.map((project) => (
          <P key={project.code}>
            - {project.name} ({project.code})
          </P>
        ))}
  </div>
)

const ExpiryDateMessage: React.FC<{ createdAt: string }> = ({ createdAt }) => {
  const expirationDate = moment(createdAt).add(30, "days")
  const now = moment()
  const remainingDuration = moment.duration(expirationDate.diff(now))
  const remainingDays = Math.floor(remainingDuration.asDays())
  const remainingHours = Math.floor(remainingDuration.asHours() % 24)

  return (
    <div className="tw-text-sm tw-text-muted-foreground tw-mb-4">
      This link will expire on {expirationDate.format("MMMM D, YYYY")} ({remainingDays} days : {remainingHours} hours
      remaining)
    </div>
  )
}

const MainContent: React.FC<{
  orgName: string
  searchTerm: string
  setSearchTerm: (term: string) => void
  filteredData: Document[]
  handlePreview: (file: Document) => void
  handleDownload: (file: Document) => void
  handleDownloadAll: () => void
  createdAt: string
}> = ({ orgName, searchTerm, setSearchTerm, filteredData, handlePreview, handleDownload, handleDownloadAll, createdAt }) => (
  <div className="tw-flex-1 tw-overflow-hidden">
    <div className="tw-flex tw-justify-between tw-items-start tw-py-8 tw-px-4">
      <div>
        <H3 className="tw-text-centre">Documents shared with you by {orgName}!</H3>
        <ExpiryDateMessage createdAt={createdAt} />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Button 
          variant="outline" 
          className="tw-w-full" 
          onClick={handleDownloadAll}
          disabled={filteredData.length === 0}
        >
          <DownloadIcon className="tw-h-4 tw-w-4 tw-mr-2" />
          Download All ({filteredData.length})
        </Button>
      </div>
    </div>
    <DocumentTable filteredData={filteredData} handlePreview={handlePreview} handleDownload={handleDownload} />
  </div>
)

const SearchInput: React.FC<{ searchTerm: string; setSearchTerm: (term: string) => void }> = ({
  searchTerm,
  setSearchTerm,
}) => (
  <div className="tw-w-[300px]">
    <div className="tw-relative">
      <MagnifyingGlassIcon className="tw-absolute tw-left-3 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-text-muted-foreground" />
      <Input
        type="text"
        placeholder="Search documents..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="tw-pl-10 tw-pr-4 tw-w-full"
      />
    </div>
  </div>
)

const DocumentTable: React.FC<{
  filteredData: Document[]
  handlePreview: (file: Document) => void
  handleDownload: (file: Document) => void
}> = ({ filteredData, handlePreview, handleDownload }) => (
  <div className="twx-ka-table">
    <Table
      columns={[
        { key: "actions", title: "Actions", width: 140, isSortable: false },
        { key: "fileName", title: "Document Name", dataType: DataType.String },
        { key: "recordPath", title: "Path", width: 180, dataType: DataType.String },
        { key: "project", title: "Project", width: 300, dataType: DataType.String },
        { key: "site", title: "Site", width: 300, dataType: DataType.String },
        { key: "recordName", title: "Record", width: 380, dataType: DataType.String },
        { key: "recordType", title: "Type", width: 180, dataType: DataType.String },
      ]}
      groups={[{ columnKey: "recordName" }]}
      groupPanel={{
        enabled: true,
        text: "For grouping, drag a column here...",
      }}
      columnResizing={true}
      data={filteredData}
      rowKeyField="fileKey"
      sortingMode={SortingMode.Single}
      childComponents={{
        cellText: {
          content: (props) => {
            if (props.column.key === "actions") {
              return (
                <ActionButtons rowData={props.rowData} handlePreview={handlePreview} handleDownload={handleDownload} />
              )
            }
            return props.value
          },
        },
        tableWrapper: {
          elementAttributes: () => ({ style: { maxHeight: "calc(100vh - 320px)" } }),
        },
      }}
    />
  </div>
)

const ActionButtons: React.FC<{
  rowData: Document
  handlePreview: (file: Document) => void
  handleDownload: (file: Document) => void
}> = ({ rowData, handlePreview, handleDownload }) => (
  <div className="tw-flex">
    <Button size="sm" variant="outline" className="tw-p-2" onClick={() => handlePreview(rowData)}>
      <EyeOpenIcon className="tw-h-4 tw-w-4" />
    </Button>
    <Button size="sm" variant="outline" className="tw-p-2 tw-ml-2" onClick={() => handleDownload(rowData)}>
      <DownloadIcon className="tw-h-4 tw-w-4" />
    </Button>
  </div>
)

export default PublicViewSharedDocuments
