import React from "react"
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap"

interface TariffSelectorProps {
  tariffs: Tariff[]
  selectedTariff?: Tariff
  onChange: (tariff: Tariff) => void
}

/**
 * The tariff selector.
 * @param props - The props.
 * @constructor
 */
export const TariffSelector = (props: TariffSelectorProps) => {
  const { tariffs, selectedTariff, onChange } = props

  return (
    <ListGroup>
      {tariffs.map((tariff, index) => (
        <TariffTile key={index} tariff={tariff} isSelected={tariff.id === selectedTariff?.id} onChange={onChange} />
      ))}
    </ListGroup>
  )
}

interface TariffTileProps {
  tariff: Tariff
  isSelected: boolean
  onChange: (tariff: Tariff) => void
}

/**
 * A tariff tile.
 * @param props - The props.
 * @constructor
 */
const TariffTile = (props: TariffTileProps) => {
  const { tariff, isSelected, onChange } = props

  /**
   * Get the header text.
   */
  function getHeader(): string {
    if (tariff.name) {
      return tariff.name
    } else if (tariff.rate) {
      return tariff.rate
    } else {
      return "Unnamed"
    }
  }

  return (
    <ListGroupItem onClick={() => onChange(tariff)} color={isSelected ? "primary" : undefined}>
      <ListGroupItemHeading>{getHeader()}</ListGroupItemHeading>
      <ListGroupItemText>{tariff.id}</ListGroupItemText>
    </ListGroupItem>
  )
}
