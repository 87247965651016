import React from "react"
import { Button } from "reactstrap"
import { Resizable, Charts, ChartContainer, ChartRow, YAxis, LineChart, styler, EventMarker, Baseline } from "react-timeseries-charts"
import { TimeRange, TimeSeries } from "pondjs"

import { Client } from "paho-mqtt"
import { ZeroMQ as ascii85 } from "ascii85"
import { v4 as uuid } from "uuid"
import CBOR from "cbor-sync"

import Logger from "@common/Logger"
import EcosuiteComponent, { Error } from "@common/EcosuiteComponent"
import ProjectUtils from "@common/utils/ProjectUtils"

import EnergyService from "../EnergyService"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import moment from "moment"
import EnergyUtils from "../EnergyUtils"
import i18n from "src/i18n"

const SOLARFLUX_TIMEOUT_MILLISECONDS = 1800000 // 30 minutes
const { t } = i18n

const NullMarker = () => {
  return <g />
}

export default class SolarFluxGraph extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      entries: [],
      trackerEvents: [],
    }

    this.showSolarFluxErrorMessage = this.showSolarFluxErrorMessage.bind(this)
    this.connectError = this.connectError.bind(this)
    this.connectSuccess = this.connectSuccess.bind(this)
    this.subscribeError = this.subscribeError.bind(this)
    this.subscribeSuccess = this.subscribeSuccess.bind(this)
    this.onConnectionLost = this.onConnectionLost.bind(this)
    this.onMessageArrived = this.onMessageArrived.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this.connect = this.connect.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.connect()
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.disconnect()
  }

  getClientId(credentials) {
    const tokenId = credentials.userName
    if (this.state.clientId && this.state.clientId.startsWith(tokenId)) {
      // re-use existing client ID
      return this.state.clientId
    }

    // generate new client ID from token ID + random 20 character string
    const rnd = []
    uuid(undefined, rnd)
    const suffix = ascii85.encode(rnd).toString()
    var clientId = tokenId + suffix
    return clientId
  }

  async connect() {
    this.disconnect()

    Logger.debug("Starting disconnect timer")
    let deadline = moment().add(SOLARFLUX_TIMEOUT_MILLISECONDS, "milliseconds")
    let deadlineTimerId = setTimeout(this.disconnect, deadline.diff(moment(), "milliseconds"))
    this.setStateIfMounted({ deadline: deadline, deadlineTimerId: deadlineTimerId })

    Logger.debug("Enable SolarFlux hyper mode")
    let responses = await Promise.all([EnergyService.enableSolarFluxHyperMode(this.props.project, this.props.site, deadline.valueOf()), EnergyService.getSolarfluxCredentials()])
    let solarfluxCredentials = responses[1]

    Logger.debug("Connect to SolarFlux")

    const options = Object.assign({}, solarfluxCredentials.credentials)
    options.onFailure = this.connectError
    options.onSuccess = this.connectSuccess
    options.useSSL = solarfluxCredentials.environment.protocol === "wss" ? true : false

    let clientId = this.getClientId(solarfluxCredentials.credentials)
    var client = new Client(solarfluxCredentials.environment.host, solarfluxCredentials.environment.port, "/mqtt", clientId)
    client.onConnectionLost = this.onConnectionLost
    client.onMessageArrived = this.onMessageArrived

    let topics = "node/+/datum/0" + ProjectUtils.getPath(this.props.project, this.props.site) + "/#" //"node/+/datum/0/#"

    this.setStateIfMounted(
      {
        client: client,
        clientId: clientId,
        topics: topics,
        environment: solarfluxCredentials.environment,
        tokenId: solarfluxCredentials.credentials.userName,
      },
      () => {
        Logger.debug("Connecting to SolarFlux MQTT ...")
        this.state.client.connect(options)
      },
    )
  }

  /**
   * Disconnects us from SolarFlux but does NOT disable hyper mode.
   * We rely on the timeout passed with the enable operation to the node to cancel the hyper mode on the node.
   *
   * @see disableHyperMode to explicitly disable the hyper mode on the node
   */
  disconnect() {
    Logger.debug("Disconnect from SolarFlux")
    if (this.state.client) {
      try {
        this.state.client.disconnect()
      } catch (e) {
        Logger.debug("Error disconnecting client; ignoring: " + e)
      }
    }

    Logger.debug("Stopping disconnect timer")
    clearTimeout(this.state.deadlineTimerId)
    this.setStateIfMounted({ deadlineTimerId: null })
  }

  /**
   * Immediately disables the hyper mode on the nodes rather than waiting for the timeout to expire and disconnects from SolarFlux.
   */
  disableHyperMode() {
    this.disconnect()
    Logger.debug("Disable SolarFlux hyper mode")
    EnergyService.disableSolarFluxHyperMode(this.props.project, this.props.site)
  }

  connectSuccess() {
    Logger.debug(`Connected to MQTT on ${this.state.client.host}:${this.state.client.port}${this.state.client.path} as ${this.state.clientId}`)
    Logger.debug(`Subscribing to topics: ${this.state.topics}`)

    const subOptions = {
      onSuccess: this.subscribeSuccess,
      onFailure: this.subscribeError,
    }
    this.state.client.subscribe(this.state.topics, subOptions)
  }

  connectError(error) {
    let msg = `Error connecting to ${this.state.environment.host}:${this.state.environment.port} (${error.errorCode}): ${error.errorMessage}`
    Logger.error(msg)
    this.showSolarFluxErrorMessage(msg)

    this.disconnect()
  }

  subscribeSuccess() {
    Logger.debug("Subscribed to MQTT topics " + this.state.topics)
  }

  subscribeError(error) {
    let msg = `Subscribe error for topics [${this.state.topics}] (${error.errorCode}): ${error.errorMessage}`
    Logger.debug(msg)
    if (error.errorCode && error.errorCode.length > 0 && error.errorCode[0] === 128) {
      // permission denied
      msg = `Permission denied for topics [${this.state.topics}]. Check that the security policy for token [${this.state.tokenId}] allows access to the node and source IDs included in the topic pattern. Note that topic wildcards only work if the token's security policy does not restrict node or source IDs.`
    }
    this.showSolarFluxErrorMessage(msg)
  }

  onConnectionLost(resp) {
    if (resp.errorCode !== 0) {
      Logger.error("Connection Lost: " + resp.errorMessage)
      this.showSolarFluxErrorMessage("Connection Lost: " + resp.errorMessage)
    }
  }

  onMessageArrived(message) {
    var body = ""
    var bytes = message.payloadBytes
    if (bytes) {
      try {
        legacyDecodeMode = false
        body = decodeCbor(bytes)
        if (body && !(body._v && body._v > 1)) {
          // legacy datum with CBOR bug; work around
          legacyDecodeMode = true
          body = decodeCbor(bytes)
        }
      } catch (e) {
        body = message.payloadString
        Logger.debug("Message does not appear to be CBOR: " + e)
      }
    }

    let start = moment().subtract(15, "minutes")
    this.setStateIfMounted({
      entries: [...this.state.entries.filter((entry) => moment(entry.created).isSameOrAfter(start)), body], // TODO filter out expired entries: this.state.entries.filter(entry => compare date)
    })
  }

  showSolarFluxErrorMessage(errorMessage) {
    this.setStateIfMounted({
      solarFluxErrorMessage: errorMessage,
    })
  }

  renderContent() {
    if (this.state.solarFluxErrorMessage) {
      return <Error error={this.state.solarFluxErrorMessage} />
    } else {
      let sources = this.getSources(this.state.entries)
      let timeseries = Object.values(sources).map((event) => new TimeSeries(event))
      let now = moment()

      return (
        <div>
          <div className="timeseries-counter">
            <Timer deadline={this.state.deadline} />
            <div className="timeseries-buttons">
              <Button onClick={this.connect} color="primary" size="sm">
                {this.state.deadline && moment().isBefore(this.state.deadline) ? `${t("labels.keep_alive")}` : `${t("table_headings.connect")}`}
              </Button>
              <Button
                onClick={() => {
                  this.disableHyperMode()
                  this.setStateIfMounted({ deadline: null })
                }}
                color="danger"
                size="sm"
              >
                {t("buttons.disable")}
              </Button>
            </div>
          </div>
          <Resizable>
            <ChartContainer
              timeRange={new TimeRange(new moment(now).subtract(15, "minutes"), now)}
              utc={false}
              onTrackerChanged={(t) => {
                this.handleTrackerChanged(t, timeseries)
              }}
            >
              <ChartRow height="250">
                <YAxis id="readings-axis" label="Kilo Watts" type="linear" min={-this.getMaxReadingSize()} max={this.getMaxReadingSize()} />
                <Charts>
                  <Baseline axis="readings-axis" value={0} />
                  {timeseries.map((series, idx) => {
                    return (
                      <LineChart
                        key={idx}
                        axis="readings-axis"
                        series={series}
                        columns={["reading"]}
                        style={styler([
                          {
                            key: "reading",
                            color: GRAPH_COLORS[idx],
                            width: 2,
                          },
                        ])}
                      />
                    )
                  })}
                  {this.renderMarker()}
                </Charts>
              </ChartRow>
            </ChartContainer>
          </Resizable>

          <ul className="timeseries-legend">
            {Object.keys(sources).map((sourceId, idx) => {
              return (
                <li key={sourceId} style={{ color: GRAPH_COLORS[idx] }}>
                  {sourceId}
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  getMaxReadingSize() {
    return Object.values(this.props.site.systems).reduce((size, system) => {
      if (system.dcSize && system.dcSize > size) {
        size = system.dcSize
      }
      if (system.peakPower && system.peakPower > size) {
        size = system.peakPower
      }
      return size
    }, 0)
  }

  getSources(readings) {
    // TODO can be much smarter here, don't need to calculate this every time, instead store on load
    return readings.reduce((sources, entry) => {
      if ((EnergyUtils.isGeneratingSource(entry.sourceId) && this.props.showGeneration) || (EnergyUtils.isConsumingSource(entry.sourceId) && this.props.showConsumption)) {
        if (!sources[entry.sourceId]) {
          sources[entry.sourceId] = {
            name: entry.sourceId,
            columns: ["time", "reading"],
            points: [],
          }
        }
        sources[entry.sourceId].points.push([entry.created, entry.watts / 1000])
      }

      return sources
    }, {})
  }

  handleTrackerChanged(t, timeseries) {
    let trackerEvents = []
    if (t && timeseries) {
      timeseries.forEach((series, idx) => {
        const e = series.atTime(t)
        const eventTime = new Date(e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2)
        const eventValue = e.get("reading")
        const v = EnergyUtils.formatKiloWatts(eventValue)
        trackerEvents.push({
          tracker: eventTime,
          trackerValue: v,
          trackerEvent: e,
          color: GRAPH_COLORS[idx],
        })
      })
    }
    trackerEvents.sort((a, b) => a.tracker - b.tracker) // EP-672 we sort events by their time to ensure the correct ordering required by TimeSeries
    this.setState({ trackerEvents: trackerEvents })
  }

  renderMarker = () => {
    if (this.state.trackerEvents.length === 0) {
      return <NullMarker />
    }

    return this.state.trackerEvents.map((event) => {
      return (
        <EventMarker
          key={event.trackerEvent}
          type="point"
          axis="readings-axis"
          event={event.trackerEvent}
          column="reading"
          markerLabel={event.trackerValue}
          markerLabelAlign="left"
          markerLabelStyle={{ fill: event.color, stroke: "white" }}
          markerRadius={3}
          markerStyle={{ fill: event.color }}
        />
      )
    })
  }
}

class Timer extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = { message: t("loadingMsg.connecting") }

    this.tick = this.tick.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    setInterval(this.tick, 1000)
  }

  tick() {
    if (this.props.deadline) {
      let now = moment()
      if (now.isBefore(this.props.deadline)) {
        let seconds = this.props.deadline.diff(now, "seconds")
        let secondspart = seconds % 60
        if (secondspart < 10) secondspart = "0" + secondspart
        if (seconds < 60) {
          this.setStateIfMounted({
            message: <span style={{ color: "red" }}>{`${t("energy.connection_expires")}: ` + this.props.deadline.diff(now, "minutes") + ":" + secondspart}</span>,
          })
        } else {
          this.setStateIfMounted({
            message: `${t("energy.connection_expires")}: ` + this.props.deadline.diff(now, "minutes") + ":" + secondspart,
          })
        }
      } else {
        this.setStateIfMounted({
          message: <span style={{ color: "orange" }}>{t("alertsInfo.connection_timedout", { deadline: this.props.deadline.format("lll") })}</span>,
        })
      }
    } else {
      this.setStateIfMounted({ message: t("alertsInfo.disconnected") })
    }
  }

  renderContent() {
    return this.state.message
  }
}

var legacyDecodeMode = false

// handle decimal floats, which arrive as array of 2 elements; https://tools.ietf.org/html/rfc7049#section-2.4.3
CBOR.addSemanticDecode(4, function (data) {
  // handle decimal floats, which arrive as array of 2 elements; https://tools.ietf.org/html/rfc7049#section-2.4.3
  var e
  if (Array.isArray(data) && data.length > 1) {
    e = data[0]
    if (legacyDecodeMode) {
      // work around generated CBOR bug https://github.com/FasterXML/jackson-dataformats-binary/issues/139
      e = -e
    }
    return data[1] * Math.pow(10, e)
  }
  return data
})

var bytesToHex = function (bytes) {
  var s = "",
    b
  for (var i = 0; i < bytes.length; i += 1) {
    b = bytes[i]
    if (b < 0x10) {
      s += "0"
    }
    s += b.toString(16)
  }
  return s
}

function decodeCbor(bytes) {
  if (bytes.buffer) {
    let hex = bytesToHex(bytes)
    return CBOR.decode(hex, "hex")
  } else {
    return CBOR.decode(bytes)
  }
}
