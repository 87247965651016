import React from "react"
import { Alert, FormGroup } from "reactstrap"
import Icon from "@common/display/Icon"
import { SECURE_GROUPS } from "src/App"
import i18n from "src/i18n"

const { t } = i18n
// Checkbox widget override that displays CognitoGroups in a list
const UserGroupSelector = ({ id, schema, options, ...props }) => {
  // Currently only Array Type Schema Elements can be checkboxes
  if (schema.type !== "array") {
    return undefined
  }

  // Get group order from schema
  const categoryOrder = schema.categoryOrder

  // Separate the CognitoGroups into their individual categories by converting string to object notation.
  // ex: "Product: Generation" => { Product: [{ label: "Generation", value: "generation" }] }
  const categories = {}
  options.enumOptions?.map((option) => {
    const [groupType, groupDescription] = option.label.split(": ")
    if (groupDescription) {
      categories[groupType] = categories[groupType] ?? []
      categories[groupType].push({ label: groupDescription, value: option.value })
    } else {
      categories.admin = categories.admin ?? []
      categories.admin.push({ label: groupType, value: option.value })
    }
  })

  function isCategoryComplete(category, values) {
    return category.every((checkbox) => {
      return values.includes(checkbox.value)
    })
  }

  function disjoinCategory(category, values) {
    const categoryValues = category.map((el) => el.value)
    return values.filter((value) => {
      return !categoryValues.includes(value)
    })
  }

  return (
    <>
      {/* Render Title and Warning */}
      <Alert color="info" className="info ecogy-form__default-permissions__info">
        <Icon icon="info" />
        {schema.items.description}
      </Alert>
      <h2 style={{ fontSize: "2rem", fontWeight: "500" }} id={id}>
        {schema.items.title}
      </h2>
      <hr />

      {/* Sort Categories and render title with checkboxes */}
      {Object.keys(categories)
        .sort((a, b) => categoryOrder.indexOf(a.toLowerCase()) - categoryOrder.indexOf(b.toLowerCase()))
        .map((category) => {
          return (
            <div key={`${category}`}>
              <table className="user-groups">
                <thead>
                  <tr>
                    <td>
                      <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <h4>{category}</h4>
                        <input
                          style={{ display: "inline", verticalAlign: "middle" }}
                          type={"checkbox"}
                          checked={isCategoryComplete(categories[category], props.value)}
                          onChange={() => {
                            if (isCategoryComplete(categories[category], props.value)) {
                              props.onChange(disjoinCategory(categories[category], props.value))
                            } else {
                              const valuePropCopy = [...props.value]
                              props.onChange(
                                valuePropCopy.concat(
                                  categories[category].map((option) => {
                                    return option.value
                                  }),
                                ),
                              )
                            }
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {categories[category].map((option, idx) => {
                    return (
                      <tr key={`${option}_${idx}`}>
                        <label className="checkbox-inline">
                          <td className="user-group-name">
                            <FormGroup check>
                              <input
                                style={{ marginRight: "5px" }}
                                type="checkbox"
                                value={option.value}
                                checked={props.value.includes(option.value)}
                                onChange={() => {
                                  if (props.value.includes(option.value)) {
                                    // make a copy of the value prop to avoid direct mutations
                                    const valuePropCopy = [...props.value]
                                    valuePropCopy.splice(props.value.indexOf(option.value), 1)
                                    props.onChange(valuePropCopy)
                                  } else {
                                    props.onChange([...props.value, option.value])
                                  }
                                }}
                              />
                              <span>{option.value}</span>
                            </FormGroup>
                          </td>
                          <td>{`${option.label}${SECURE_GROUPS.includes(option.value) ? `${t("settings.messages.will_require_MFA")}` : ""}`}</td>
                        </label>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        })}
    </>
  )
}

export default UserGroupSelector
