import React from "react"
import { Button, ButtonToolbar, ButtonGroup } from "reactstrap"
import EcosuiteView from "@common/module/EcosuiteView"
import { Error, Loading } from "@common/EcosuiteComponent"

import EventView from "@dashboard/event/views/EventView"
import EventListView from "@dashboard/event/views/event/EventListView"
import EventViewControls from "@dashboard/event/views/event/EventViewControls"
import i18n from "src/i18n"

const { t } = i18n
export default class FinanceEventsView extends EcosuiteView {
  constructor(props) {
    super(props, "finance-events")
  }

  renderMainView() {
    return (
      <FinancialEventView
        {...this.props}
        actions={{
          eventUpdated: () => {
            this.props.actions.loadEvents(this.props.range)
          },
          eventDeleted: () => {
            this.props.actions.loadEvents(this.props.range)
          },
        }}
      />
    )
  }
}

class FinancialEventView extends EventView {
  renderEvents() {
    if (this.isContentError(this.props.events)) {
      return <Error error={this.props.events.getError()} />
    }

    if (!this.props.events) {
      return <Loading message={t("loadingMsg.loading_events")} />
    }

    return (
      <div className="content-view">
        <FinancialEventListView
          groups={this.props.groups}
          projects={this.props.projects}
          events={this.getEvents()}
          eventSchema={this.state.eventSchema}
          event={this.state.event}
          searchTerm={this.state.searchTerm}
          types={this.state.types}
          selectedTypes={this.state.selectedTypes}
          eventSubTypes={Array.from(this.state.subTypes ?? "")}
          selectedEventSubTypes={Array.from(this.state.selectedSubTypes ?? "")}
          priorities={this.state.priorities}
          selectedPriorities={this.state.selectedPriorities}
          filters={{
            showDueOnly: this.state.showDueOnly,
            showUnresolvedOnly: this.state.showUnresolvedOnly,
          }}
          actions={{
            eventUpdated: this.eventUpdated,
            selectView: this.selectView,
            selectRange: this.selectRange,
            selectProject: this.selectProject,
            selectEvent: this.selectEvent,
            search: this.search,
            toggleType: this.toggleType,
            toggleSubTypes: this.toggleSubTypes,
            togglePriority: this.togglePriority,
            toggleUnresolved: this.toggleUnresolved,
            toggleDue: this.toggleDue,
          }}
        />
      </div>
    )
  }
}

class FinancialEventListView extends EventListView {
  renderResolveButton(event) {
    if (event.endDate) {
      return null
    } else if (event.id.startsWith("invoice-issue-")) {
      return (
        <Button
          color="ecogy"
          size="sm"
          title="Record that the invoice has been issued"
          onClick={() => {
            this.resolveEvent(event)
          }}
        >
          {t("economics.messages.Invoice Issued")}
        </Button>
      )
    } else if (event.id.startsWith("invoice-payment-")) {
      const parts = event.id.split("-")
      parts[1] = "issue"
      parts.splice(2, 1)
      const issueId = parts.join("-")

      const issueEvent = this.props.events.find((event) => event.id === issueId)
      const paymentDue = issueEvent && issueEvent.endDate !== undefined

      return (
        <Button
          color="ecogy"
          size="sm"
          title={paymentDue ? `${t("economics.messages.invoice_payment_received")}` : `${t("economics.messages.invoice_not_issued")}`}
          disabled={!paymentDue}
          onClick={() => {
            this.resolveEvent(event)
          }}
        >
          {t("economics.messages.Payment Received")}
        </Button>
      )
    } else {
      return super.renderResolveButton(event)
    }
  }

  renderViewControls() {
    return <FinancialEventViewControls {...this.props} hashtags={this.getHashTags()} readonly={this.isReadOnly()} />
  }
}

class FinancialEventViewControls extends EventViewControls {
  renderEventButtons() {
    if (this.props.readonly) {
      return null
    }

    return (
      <ButtonToolbar className="secondary-header-toolbar float-end">
        <ButtonGroup className="header-button-group">
          <Button
            className={this.props.event && !this.props.event.id ? "selected" : ""}
            onClick={() => {
              this.props.actions.selectEvent({ type: "finance" })
            }}
            size="sm"
            color="ecogy"
          >
            {t("event.labels.new_event")}
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    )
  }
}
