import API from "@common/API"
import Logger from "@common/Logger"
import ProjectUtils from "@common/utils/ProjectUtils"

const NoteService = {
  async getProjectRecord(projectId: string, recordId: string) {
    return API.get<{ records: Array<{ id: string }> }>(`/projects/${projectId}/records`).then((response) => {
      return response.records.find((record) => record.id === recordId)
    })
  },

  addNote(record: { id: string; notes: Array<{ note: string }> }, note: string) {
    Logger.debug(`Adding note: "${note}" to record: ${record.id}`)
    if (record.notes) {
      record.notes.push({ note: note })
    } else {
      record.notes = [{ note: note }]
    }
    return API.put(`${constructPath(record)}/records/${record.id}?note=${encodeURIComponent("Added new note")}`, record)
  },

  editNote(record: { notes: Array<{ note: string }>; id: string }, noteIdx: number, text: string) {
    Logger.debug(`Editing note: "${noteIdx}" of record: ${record.id}`)
    record.notes[noteIdx].note = text
    return API.put(`${constructPath(record)}/records/${record.id}?note=${encodeURIComponent("Edited note")}`, record)
  },

  resolveNote(record: { notes: Array<{ resolved: boolean }>; id: string }, noteIdx: number) {
    Logger.debug(`Resolving note: ["${noteIdx}"] on record: ${record.id}`)
    record.notes[noteIdx].resolved = true
    return API.put(`${constructPath(record)}/records/${record.id}?note=${encodeURIComponent(`Resolved note: ["${noteIdx}"]`)}`, record)
  },

  addCommentToNote(record: { notes: Array<{ comments: Array<{ comment: string }> }>; id: string }, noteIdx: number, comment: string) {
    Logger.debug(`Adding comment to note: "${noteIdx}" on record: ${record.id}`)
    const note = record.notes[noteIdx]
    if (note.comments) {
      note.comments.push({ comment: comment })
    } else {
      note.comments = [{ comment: comment }]
    }
    return API.put(`${constructPath(record)}/records/${record.id}?note=${encodeURIComponent("Added new note comment")}`, record)
  },

  editNoteComment(record: { notes: Array<{ comments: Array<{ comment: string }> }>; id: number }, noteIdx: number, commentIdx: number, text: string) {
    Logger.debug(`Editing comment: ${commentIdx} of note: "${noteIdx}" on record: ${record.id}`)
    record.notes[noteIdx].comments[commentIdx].comment = text
    return API.put(`${constructPath(record)}/records/${record.id}?note=${encodeURIComponent("Edited note comment")}`, record)
  },
}

const constructPath = function (record: any) {
  let path = "/projects/" + ProjectUtils.getProjectCode(record.path)
  const siteId = ProjectUtils.getSiteCode(record.path)
  if (siteId) {
    path += "/sites/" + siteId
  }
  const systemId = ProjectUtils.getSystemCode(record.path)
  if (systemId) {
    path += "/systems/" + systemId
  }

  return path
}

export default NoteService
