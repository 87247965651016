import React, { useState } from "react"
import { Button, ButtonGroup, Popover, PopoverBody, PopoverHeader } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import "./Button.css"
import { Placement } from "@popperjs/core"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
/**
 * The valid Confirmation properties.
 */
export interface ConfirmationProps {
  idSuffix: string
  text: string
  color: string
  header: string
  onConfirm: () => void
  body: string
  toolTip: string
  headerColor?: string
  headerIcon?: string
  disabled?: boolean
  onDecline?: () => void
  irreversible?: boolean
  trigger?: string
  placement?: string
  confirmColor?: string
  confirmIcon?: string
  size?: string
}

/**
 * A simple confirmation dialog.
 * @param props - The confirmation properties.
 * @constructor
 */
export const Confirmation = (props: ConfirmationProps): JSX.Element => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const confirmationId = `confirmation-${props.idSuffix}`
  const confirmButtonId = `${confirmationId}-confirm`
  const declineButtonId = `${confirmationId}-decline`

  return (
    <>
      <Button size={props.size} id={confirmationId} color={props.color} onClick={() => setIsPopoverOpen(!isPopoverOpen)} disabled={props.disabled ?? false}>
        {props.text}
      </Button>
      <UncontrolledTooltip target={confirmationId}>{props.toolTip}</UncontrolledTooltip>
      <Popover
        target={confirmationId}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        toggle={function noRefCheck() {}}
        isOpen={isPopoverOpen}
        placement={(props.placement as Placement) ?? "top"}
      >
        <PopoverHeader className={"confirmation-popover-header"}>
          <span>{props.header}</span>
          {props.headerIcon && <Icon className={"float-end"} icon={props.headerIcon} />}
        </PopoverHeader>
        <PopoverBody>
          {props.body}
          {props.irreversible && <span style={{ fontWeight: "bold" }}>{`\n${t("notes.action_cannot_be_reversed")}`}</span>}
        </PopoverBody>
        <ButtonGroup className={"d-flex justify-content-center"}>
          <Button
            className={"confirmation-button"}
            id={declineButtonId}
            size={"lg"}
            color={props.confirmColor ?? "danger"}
            outline
            onClick={() => {
              if (props.onDecline) props.onDecline.call(this)
              setIsPopoverOpen(false)
            }}
          >
            <Icon icon={props.confirmIcon ?? "close"} />
          </Button>
          <UncontrolledTooltip target={declineButtonId}>{t("buttons.decline")}</UncontrolledTooltip>
          <Button
            className={"confirmation-button"}
            id={confirmButtonId}
            size={"lg"}
            color={props.confirmColor ?? "success"}
            outline
            onClick={() => {
              props.onConfirm.call(this)
              setIsPopoverOpen(false)
            }}
          >
            <Icon icon={props.confirmIcon ?? "check"} />
          </Button>
          <UncontrolledTooltip target={confirmButtonId}>{t("buttons.confirm")}</UncontrolledTooltip>
        </ButtonGroup>
      </Popover>
    </>
  )
}

export default Confirmation
