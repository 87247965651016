import React from "react"
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap"
import UserDisplayEmail from "@common/display/UserDisplayEmail"
import { DateTime } from "luxon"
import "./RFIResolutionTile.scss"
import i18n from "src/i18n"

/**
 * The RFI resolution tile.
 * @param resolutionDateTime - When the resolution took place.
 * @param author - The author of the resolution.
 * @param resolved - Whether the RFI was resolved.
 * @constructor
 */
const { t } = i18n
export const RFIResolutionTile = ({ resolutionDateTime, author, resolved }: { resolutionDateTime: string; author: User; resolved: boolean }): JSX.Element => {
  return (
    <>
      <ListGroupItem className={"rfi-resolution-tile"}>
        <ListGroupItemHeading>
          {author ? `${author.attributes.firstName} ${author.attributes.lastName} ` : `${t("labels.unknown")}`}
          {author && <UserDisplayEmail user={author} />}
          {` ${resolved ? `${t("process.messages.resolved_this_rfi")}` : `${t("process.messages.opened_this_rfi")}`}`}
        </ListGroupItemHeading>
        <ListGroupItemText>{DateTime.fromISO(resolutionDateTime).toLocaleString()}</ListGroupItemText>
        <hr />
      </ListGroupItem>
    </>
  )
}

export default RFIResolutionTile
