import React from "react"
import { Table } from "reactstrap"
import i18n from "src/i18n"
import MediaTableEntry from "./MediaTableEntry"

/**
 * The media table.
 * @param operatingMedia - The operating media.
 * @param deleteAndRemoveMedia - Function to delete and remove media.
 * @constructor
 */
const { t } = i18n
export const MediaTable = ({ operatingMedia, deleteAndRemoveMedia }: { operatingMedia: MediaResponse[]; deleteAndRemoveMedia: (key: string) => void }): JSX.Element => {
  return (
    <>
      <div className={"media-table"}>
        <Table hover={true} borderless={true} dark>
          <thead>
            <tr className={"media-table-header"}>
              <th>{t("table_headings.type")}</th>
              <th>{t("table_headings.size")} (MB)</th>
              <th>{t("table_headings.name")}</th>
              <th>{t("table_headings.upload_date")}</th>
              <th>{t("table_headings.description")}</th>
              <th>{t("table_headings.tags")}</th>
              <th>{t("table_headings.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {operatingMedia.map((media, index) => (
              <MediaTableEntry media={media} key={index} deleteAndRemoveMedia={deleteAndRemoveMedia} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default MediaTable
