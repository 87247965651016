import API from "@common/API"
import { Organization } from "@ecogy-types/openapi-types"
import { Auth } from "aws-amplify"

interface OrganizationResponse {
  response: {
    body: Organization
  }
}

/**
 * Service for administering organizations.
 */
const OrganizationService = {
  getUiSchema() {
    return { id: { "ui:widget": "hidden", "ui:description": " " } }
  },

  async deleteOrganization(id: string): Promise<any> {
    return API.delete("/organizations/" + id)
  },

  async getOrganizations(): Promise<Organization[]> {
    return API.get<Organization[]>("/organizations")
  },

  async getOrganizationSchema(): Promise<object> {
    return API.getFromCache("/schemas/organization")
  },

  async getOrganization(organizationId: string): Promise<OrganizationResponse> {
    return API.getFromCache<OrganizationResponse>("/organizations/" + organizationId)
  },

  async createOrganization(form: any): Promise<OrganizationResponse> {
    return API.post("/organizations", form)
  },

  async editOrganization(organization: Organization): Promise<OrganizationResponse> {
    return API.put("/organizations", organization)
  },

  async getorgUXSettings(): Promise<unknown> {
    return Auth.currentSession()
      .then((session) => {
        //@ts-ignore
        return API.get<any>("/organizations/" + session.idToken.payload["custom:organizationId"] + "/ux-settings").then((data) => data)})
  },
}

export default OrganizationService
