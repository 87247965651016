import React from "react"
import { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import { Button, ButtonGroup, ButtonToolbar } from "reactstrap"

import EcosuiteView from "@common/module/EcosuiteView"
import RecordService from "../record/RecordService"
import ProjectUtils from "@common/utils/ProjectUtils"
import ProFormaService from "../pro-forma/ProFormaService"
import Logger from "@common/Logger"
import { deepEquals } from "@rjsf/core/lib/utils"
import Utils from "@common/utils/Utils"
import i18n from "src/i18n"

const ignoredDataKeys = {
  // ignore these key to avoid duplicates of Data
  project: ["code", "sites"],
  site: ["code", "systems"],
  system: ["code"],
  proforma: ["id", "assetType"],
  record: ["id", "assetType"],
}
const { t } = i18n

export default class RawDataView extends EcosuiteView {
  constructor(props) {
    super(props, "raw-data-list")

    this.state.rawData = []
    this.state.loading = t("loadingMsg.loading_data")

    this.loadProForma = this.loadProForma.bind(this)
    this.loadProjectRecords = this.loadProjectRecords.bind(this)
    this.formatData = this.formatData.bind(this)
    this.flushData = this.flushData.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    if (this.props.project) {
      this.loadProject()
    } else if (this.props.projects) {
      this.loadPortfolio()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.project && (!deepEquals(prevProps.project, this.props.project) || (prevProps.projects && !this.props.projects))) {
      this.setStateIfMounted({ rawData: [], loading: t("loadingMsg.loading_data") })
      this.loadProject()
    } else if ((this.props.projects && !deepEquals(prevProps.projects, this.props.projects)) || (prevProps.project && !this.props.project)) {
      this.setStateIfMounted({ rawData: [], loading: t("loadingMsg.loading_data") })
      this.loadPortfolio()
    }
  }

  loadProject() {
    const pending = []

    this.getProjectData(pending, this.props.project)
    this.getSiteData(pending, this.props.project)
    this.getSystemData(pending, this.props.project)

    this.loadProForma(pending, this.props.project)
      .then(() => this.loadProjectRecords(pending, this.props.project))
      .then(() => {
        this.flushData(pending)
      })
  }

  loadPortfolio() {
    const pending = []
    const promises = []

    for (const project of this.props.projects) {
      this.getProjectData(pending, project)
      this.getSiteData(pending, project)
      this.getSystemData(pending, project)

      promises.push(this.loadProForma(pending, project))
      promises.push(this.loadProjectRecords(pending, project))
    }

    Promise.all(promises).then(() => {
      this.flushData(pending)
    })
  }

  isProjectRelevant(projectId) {
    if (this.props.project) {
      return this.isProjectCurrent(projectId)
    } else {
      return this.props.projects.some((project) => project.code === projectId)
    }
  }

  getProjectData(pending, project) {
    this.formatData(pending, project.code, "Project", ProjectUtils.getPath(project), "", project)
  }

  getSiteData(pending, project) {
    ProjectUtils.getSitesForProject(project).forEach((site) => {
      this.formatData(pending, project.code, "Site", ProjectUtils.getPath(project, site), "", site)
    })
  }

  getSystemData(pending, project) {
    ProjectUtils.getSystemCodesForProject(project).forEach((systemCode) => {
      this.formatData(pending, project.code, "System", systemCode, "", ProjectUtils.getAssetsFromPath([project], systemCode).system)
    })
  }

  async loadProForma(pending, project) {
    const projectId = project.code

    return ProFormaService.getProjectProForma(projectId)
      .then((proforma) => {
        if (this.isProjectRelevant(projectId)) {
          this.formatData(pending, project.code, "Proforma", proforma.id, "", proforma)
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((error) => {
        Logger.error(error)
        if (this.isProjectRelevant(projectId)) {
          this.setStateIfMounted({
            error: new EcosuiteComponentError(error),
          })
        }
      })
  }

  async loadProjectRecords(pending, project) {
    const projectId = project.code

    return RecordService.getProjectRecords(projectId)
      .then((data) => {
        if (this.isProjectRelevant(projectId)) {
          data.records.forEach((record) => {
            this.formatData(pending, project.code, "Record", record.id, "", record)
          })
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((error) => {
        Logger.error(error)
        if (this.isProjectRelevant(projectId)) {
          this.setStateIfMounted({
            error: new EcosuiteComponentError(error),
          })
        }
      })
  }

  flushData(pending) {
    this.setStateIfMounted({
      rawData: pending,
      loading: "",
    })
  }

  formatData(pending, projectCode, assetType, assetCode, assetPath, assetObj) {
    Object.keys(assetObj).forEach((key) => {
      const formattedPath = isNaN(key) ? `${assetPath}.${key}` : `${assetPath}[${key}]`

      if (key == assetCode || ignoredDataKeys[assetType.toLowerCase()].includes(key)) {
        return
      } else if (typeof assetObj[key] == "object") {
        this.formatData(pending, projectCode, assetType, assetCode, formattedPath, assetObj[key])
      } else {
        pending.push({ projectCode: projectCode, assetType: assetType, assetCode: assetCode, propertyPath: formattedPath.substring(1), propertyValue: assetObj[key] })
      }
    })
  }

  renderTable() {
    // This should probably be moved to its own file / component
    return (
      <table>
        <thead>
          <tr>
            <th>{t("table_headings.project_code")}</th>
            <th>{t("table_headings.asset_type")}</th>
            <th>{t("table_headings.asset_code")}</th>
            <th>{t("table_headings.property_path")}</th>
            <th>{t("table_headings.property_value")}</th>
          </tr>
        </thead>

        <tbody>
          {this.state.rawData.map((datum) => {
            return (
              <tr key={`${datum.projectCode}-${datum.assetCode}-${datum.propertyPath}`}>
                <td>{`${datum.projectCode}`}</td>
                <td>{`${datum.assetType}`}</td>
                <td>{`${datum.assetCode}`}</td>
                <td>{`${datum.propertyPath}`}</td>
                <td>{`${typeof datum.propertyValue === "number" ? Utils.formatNumber(datum.propertyValue, { maximumFractionDigits: 2 }) : datum.propertyValue}`}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderViewControls() {
    if (!this.state.loading) {
      return (
        <div className="content-view-controls">
          <ButtonToolbar className="float-end">
            <ButtonGroup className="header-button-group">
              <Button
                size="sm"
                color="ecogy"
                onClick={() => {
                  const json = JSON.stringify(this.state.rawData)
                  const a = document.createElement("a")
                  a.href = `data:text/json;charset=utf-8,${encodeURIComponent(json)}`
                  a.download = "raw-data.json"
                  a.click()
                }}
              >
                {t("buttons.download_json")}
              </Button>
              <Button
                size="sm"
                color="ecogy"
                onClick={() => {
                  const headers = "Project Code,Asset Type,Asset Code,Property Path,Property Value"
                  const data = this.state.rawData.reduce((csvData, datum) => {
                    csvData.push([datum.projectCode, datum.assetType, datum.assetCode, datum.propertyPath, datum.propertyValue].join(","))
                    return csvData
                  }, [])
                  const csv = [headers, ...data].join("\n")
                  const a = document.createElement("a")
                  a.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
                  a.download = "raw-data.csv"
                  a.click()
                }}
              >
                {t("buttons.download_csv")}
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      )
    }
  }

  renderMainView() {
    if (this.state.loading) {
      return <Loading message={this.state.loading} />
    } else if (this.isContentError(this.state.error)) {
      return <Error error={this.state.error.getError()} />
    } else if (this.state.rawData) {
      return this.renderTable()
    } else {
      return <Loading message={this.state.loading} />
    }
  }
}
