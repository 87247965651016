import React from "react"
import moment from "moment"
import { ButtonGroup, ButtonToolbar, Button, Col, Container, Row, Label, Input, Form, FormGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import Toggle from "react-toggle"
import { create, all } from "mathjs"

import { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import Settings from "@common/Settings"
import Utils from "@common/utils/Utils"
import Logger from "@common/Logger"
import ReportsService from "../FinanceReportService"
import ProFormaService from "@dashboard/data/pro-forma/ProFormaService"
import i18n from "src/i18n"

const math = create(all)
const { t } = i18n

const FORMAT_TWO_DP = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const FLAGS = {
  backend: "Back-End Cash Flows",
  debt: "Forecasted Perm Debt",
}

class SourcesUsesView extends EcosuiteView {
  constructor(props) {
    super(props, "project-sources-uses")

    this.state.showSubAccounts = Settings.getSetting("showSubAccounts", "false") === "true"
    this.state.mode = Settings.getSetting("sourcesUsesMode", "toDate")
    this.state.flags = Settings.getSetting("sourcesUsesFlags", ["debt"])

    this.load = this.load.bind(this)
    this.selectMode = this.selectMode.bind(this)
    this.toggleFlag = this.toggleFlag.bind(this)
    this.toggleSubAccounts = this.toggleSubAccounts.bind(this)
    this.selectProFormaVersion = this.selectProFormaVersion.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.load()

    this.loadProForma()
    this.loadProFormaVersions()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.proFormaVersion !== prevProps.proFormaVersion ||
      JSON.stringify(this.getProjects(this.props).map((project) => project.code)) !== JSON.stringify(this.getProjects(prevProps).map((project) => project.code))
    ) {
      this.load()

      this.loadProForma()
      this.loadProFormaVersions()
    }
  }

  load() {
    this.setStateIfMounted({ sourcesUses: undefined })

    const projectIds = this.getProjects().map((project) => project.code)
    const mode = this.state.mode
    const flags = this.state.flags
    Logger.debug(`load ${projectIds} ${this.state.mode}`)

    if (this.props.project) {
      // When a project is selected we make a call that allows a project specific pro forma to be selected
      const proFormaVersion = this.props.proFormaVersion
      Logger.debug(`Project: ${this.props.project.code} Version: ${proFormaVersion}`)
      ReportsService.getProjectSourcesUses(this.props.project.code, mode, flags, proFormaVersion)
        .then((response) => {
          if (this.areProjectsCurrent(projectIds) && mode === this.state.mode && flags === this.state.flags && proFormaVersion === this.props.proFormaVersion) {
            this.setStateIfMounted({ sourcesUses: response })
          } else {
            Logger.debug(`Ignoring out of date response for projectIds: ${projectIds} ${mode} ${proFormaVersion}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          if (this.areProjectsCurrent(projectIds)) {
            this.setStateIfMounted({ sourcesUses: new EcosuiteComponentError(err) })
          }
        })
    } else {
      ReportsService.getSourcesUses(projectIds, mode, flags)
        .then((response) => {
          if (this.areProjectsCurrent(projectIds) && mode === this.state.mode && flags === this.state.flags) {
            this.setStateIfMounted({ sourcesUses: response })
          } else {
            Logger.debug(`Ignoring out of date response for projectIds: ${projectIds} ${mode}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          if (this.areProjectsCurrent(projectIds)) {
            this.setStateIfMounted({ sourcesUses: new EcosuiteComponentError(err) })
          }
        })
    }
  }

  async loadProForma() {
    this.setStateIfMounted({ proForma: null })
    if (this.props.project) {
      const projectId = this.props.project.code
      return ProFormaService.getProjectProForma(projectId)
        .then((proForma) => {
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({ proForma: proForma })
          } else {
            Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({ proForma: new EcosuiteComponentError(err) })
          }
        })
    }
  }

  async loadProFormaVersions() {
    this.setStateIfMounted({ proFormaVersions: null })
    if (this.props.project) {
      const projectId = this.props.project.code
      ReportsService.getProFormaVersions(projectId).then((versions) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ proFormaVersions: versions })
        } else {
          Logger.debug(`Ignoring out of date response for project: ${projectId}`)
        }
      })
    }
  }

  getProjects(props = this.props) {
    return props.project ? [props.project] : props.projects
  }

  getDCSizeWatts() {
    const dcSize = this.getProjects(this.props).reduce((total, project) => math.add(total, project.dcSize || 0), 0)
    return dcSize * 1000
  }

  selectMode(mode) {
    Settings.setSetting("sourcesUsesMode", mode)
    this.setStateIfMounted({ mode: mode }, () => {
      this.load()
    })
  }

  toggleFlag(event) {
    const flags = this.state.flags.filter((flag) => flag !== event.target.value) // Remove the flag from the selections
    if (event.target.checked) {
      flags.push(event.target.value) // Add the flag to the selections
    }

    this.setState({ flags: flags }, () => {
      Settings.setSetting("sourcesUsesFlags", flags)
      this.load()
    })
  }

  toggleSubAccounts() {
    const showSubAccounts = !this.state.showSubAccounts
    Settings.setSetting("showSubAccounts", showSubAccounts.toString())
    this.setStateIfMounted({ showSubAccounts: showSubAccounts })
  }

  selectProFormaVersion(version) {
    Logger.debug("Selecting Pro Forma version: " + version)
    this.props.actions.setProFormaVersion(version, () => {
      this.load()
    })
  }

  renderViewControls() {
    return (
      <div className="content-view-controls">
        <ButtonToolbar className="float-start">
          <ButtonGroup className="header-button-group">
            <Button
              className={this.state.mode === "toDate" ? "selected" : null}
              onClick={() => {
                this.selectMode("toDate")
              }}
              title={`${t("aggregates.To Date")}`}
              size="sm"
            >
              {t("aggregates.To Date")}
            </Button>
            <Button
              className={this.state.mode === "lifetime" ? "selected" : null}
              onClick={() => {
                this.selectMode("lifetime")
              }}
              title={`${t("aggregates.Lifetime")}`}
              size="sm"
            >
              {t("aggregates.Lifetime")}
            </Button>
          </ButtonGroup>
        </ButtonToolbar>

        <span className="report-flag-controls">
          <UncontrolledDropdown direction="up" className="float-start" size="sm">
            &nbsp;
            <DropdownToggle caret id="cash-flow-flags">
              {t("labels.show")}
            </DropdownToggle>
            <DropdownMenu>
              <Form>
                {Object.keys(FLAGS).map((flag) => {
                  return (
                    <FormGroup key={flag} check>
                      <Label check>
                        {FLAGS[flag]}
                        <Input type="checkbox" value={flag} onChange={this.toggleFlag} checked={this.state.flags?.includes(flag)} />
                      </Label>
                    </FormGroup>
                  )
                })}
              </Form>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledTooltip placement="left" target="cash-flow-flags">
            {t("economics.tooltips.select_the_flagged")}
          </UncontrolledTooltip>
        </span>

        <FormGroup className="float-start" check inline>
          <Label className="irr-report-account-controls">
            <Toggle defaultChecked={this.state.showSubAccounts} onChange={this.toggleSubAccounts} />
            <span className="react-toggle-text">{t("labels.sub_accounts")}</span>
          </Label>
        </FormGroup>

        {this.state.proFormaVersions && this.state.proFormaVersions.length ? (
          <Form className="irr-report-column-controls" style={{ marginTop: "-6px" }}>
            <FormGroup check inline>
              <span className="control-forecast-label">{t("labels.forecast")}:</span>
              <Label className={"form-check-label-color"} id="pro-forma-toggle">
                <Input
                  type="select"
                  bsSize="sm"
                  value={this.props.proFormaVersion}
                  onChange={(e) => {
                    this.selectProFormaVersion(e.target.value)
                  }}
                >
                  <option value="">
                    {t("labels.current")} {`(${moment(this.state.proForma?.updated).format("lll")})`}
                  </option>
                  {this.state.proFormaVersions.map((version) => {
                    const versionName = `${version.id.split("/")[1]} (${moment(version.created).format("lll")})`
                    return (
                      <option key={version.id} value={version.id}>
                        {versionName}
                      </option>
                    )
                  })}
                </Input>
              </Label>
            </FormGroup>
            <UncontrolledTooltip placement="top" target="pro-forma-toggle" style={{ textAlign: "left" }}>
              {t("economics.messages.forecast_using_one_of")}:
              <ul>
                <li>
                  <b>{t("economics.messages.current_proforma_explained")}</b>:
                </li>
                <li>
                  <b>{t("economics.labels.Pro Forma [version]")}</b>: {t("economics.messages.proforma_version_explained")}
                </li>
              </ul>
            </UncontrolledTooltip>
          </Form>
        ) : null}
      </div>
    )
  }

  renderMainView() {
    if (this.isContentError(this.state.sourcesUses)) {
      return <Error error={this.state.sourcesUses.getError()} />
    } else if (this.isContentValid(this.state.sourcesUses)) {
      return (
        <Container className="sources-uses" fluid>
          <Row>
            <Col md={6}>{this.renderTable("Sources", this.state.sourcesUses.sources)}</Col>
            <Col md={6}>{this.renderTable("Uses", this.state.sourcesUses.uses)}</Col>
          </Row>
        </Container>
      )
    } else {
      return <Loading />
    }
  }

  renderTable(title, data) {
    const dcSizeWatts = this.getDCSizeWatts()
    const showActual = this.state.mode === "toDate"
    return (
      <table>
        <thead>
          <tr>
            <th colSpan={showActual ? 4 : 7}>{title}</th>
          </tr>
          <tr>
            <th>{t("table_headings.account")}</th>
            {showActual ? <th colSpan={3}>{t("labels.actual")}</th> : null}
            <th colSpan={3}>{t("labels.forecast")}</th>
          </tr>
          <tr>
            <th></th>
            {showActual ? (
              <>
                <th>$</th>
                <th>$/W</th>
                <th>%</th>
              </>
            ) : null}
            <th>$</th>
            <th>$/W</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>{this.renderSourcesUses(data)}</tbody>
        <tfoot>
          <tr>
            <td id={`net-${title}`}>{title === "Sources" ? `${t("economics.labels.net_upfront_sources")}` : `${t("economics.labels.net_external_upfront_uses")}`}</td>
            <UncontrolledTooltip target={`net-${title}`}>
              {title === "Sources" ? `${t("economics.labels.sources_calculation")}` : `${t("economics.labels.non_sources_calculation")}`}
            </UncontrolledTooltip>
            {showActual ? (
              <>
                <td className="numerical">{Utils.formatNumber(data.net.actual, FORMAT_TWO_DP)}</td>
                <td className="numerical">{Utils.formatNumber(data.net.actual / dcSizeWatts, FORMAT_TWO_DP)}</td>
                <td className="numerical">-</td>
              </>
            ) : null}
            <td className="numerical">{Utils.formatNumber(data.net.forecast, FORMAT_TWO_DP)}</td>
            <td className="numerical">{Utils.formatNumber(data.net.forecast / dcSizeWatts, FORMAT_TWO_DP)}</td>
            <td className="numerical">-</td>
          </tr>
          <tr>
            <td>{t("economics.labels.total_title", { title: title })}</td>
            {showActual ? (
              <>
                <td className="numerical">{Utils.formatNumber(data.actual, FORMAT_TWO_DP)}</td>
                <td className="numerical">{Utils.formatNumber(data.actual / dcSizeWatts, FORMAT_TWO_DP)}</td>
                <td className="numerical">100</td>
              </>
            ) : null}
            <td className="numerical">{Utils.formatNumber(data.forecast, FORMAT_TWO_DP)}</td>
            <td className="numerical">{Utils.formatNumber(data.forecast / dcSizeWatts, FORMAT_TWO_DP)}</td>
            <td className="numerical">100</td>
          </tr>
        </tfoot>
      </table>
    )
  }

  renderSourcesUses(data) {
    const dcSizeWatts = this.getDCSizeWatts()
    const showActual = this.state.mode === "toDate"
    return Object.values(data.categories)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((category, idx) => {
        return (
          <React.Fragment key={idx}>
            <tr>
              <td>{category ? (category.name === "Income" ? "Revenue" : category.name) : ""}</td>
              {showActual ? (
                <>
                  <td className="numerical">{category && category.actual ? Utils.formatNumber(category.actual, FORMAT_TWO_DP) : ""}</td>
                  <td className="numerical">{category && category.actual ? Utils.formatNumber(category.actual / dcSizeWatts, FORMAT_TWO_DP) : ""}</td>
                  <td className="numerical">{category && category.actual ? Utils.formatNumber((category.actual / data.actual) * 100, FORMAT_TWO_DP) : ""}</td>
                </>
              ) : null}

              <td className="numerical">{category && category.forecast ? Utils.formatNumber(category.forecast, FORMAT_TWO_DP) : ""}</td>
              <td className="numerical">{category && category.forecast ? Utils.formatNumber(category.forecast / dcSizeWatts, FORMAT_TWO_DP) : ""}</td>
              <td className="numerical">{category && category.forecast ? Utils.formatNumber((category.forecast / data.forecast) * 100, FORMAT_TWO_DP) : ""}</td>
            </tr>
            {this.renderAccounts(data, Object.values(category.accounts))}
          </React.Fragment>
        )
      })
  }

  renderAccounts(data, accounts) {
    const dcSizeWatts = this.getDCSizeWatts()
    const showActual = this.state.mode === "toDate"
    return Object.values(accounts)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((account, idx) => {
        return (
          <React.Fragment key={idx}>
            <tr className="account">
              <td>{account ? account.name : ""}</td>
              {showActual ? (
                <>
                  <td className="numerical">{account && account.actual ? Utils.formatNumber(account.actual, FORMAT_TWO_DP) : ""}</td>
                  <td className="numerical">{account && account.actual ? Utils.formatNumber(account.actual / dcSizeWatts, FORMAT_TWO_DP) : ""}</td>
                  <td className="numerical">{account && account.actual ? Utils.formatNumber((account.actual / data.actual) * 100, FORMAT_TWO_DP) : ""}</td>
                </>
              ) : null}

              <td className="numerical">{account && account.forecast ? Utils.formatNumber(account.forecast, FORMAT_TWO_DP) : ""}</td>
              <td className="numerical">{account && account.forecast ? Utils.formatNumber(account.forecast / dcSizeWatts, FORMAT_TWO_DP) : ""}</td>
              <td className="numerical">{account && account.forecast ? Utils.formatNumber((account.forecast / data.forecast) * 100, FORMAT_TWO_DP) : ""}</td>
            </tr>
            {this.renderSubAccounts(data, Object.values(account.subAccounts))}
          </React.Fragment>
        )
      })
  }

  renderSubAccounts(data, subAccounts) {
    if (this.state.showSubAccounts) {
      const dcSizeWatts = this.getDCSizeWatts()
      const showActual = this.state.mode === "toDate"
      return subAccounts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((subAccount, idx) => {
          return (
            <tr key={idx} className="sub-account">
              <td>{subAccount ? ` - ${subAccount.name}` : ""}</td>
              {showActual ? (
                <>
                  <td className="numerical">{subAccount && subAccount.actual ? Utils.formatNumber(subAccount.actual, FORMAT_TWO_DP) : ""}</td>
                  <td className="numerical">{subAccount && subAccount.actual ? Utils.formatNumber(subAccount.actual / dcSizeWatts, FORMAT_TWO_DP) : ""}</td>
                  <td className="numerical">{subAccount && subAccount.actual ? Utils.formatNumber((subAccount.actual / data.actual) * 100, FORMAT_TWO_DP) : ""}</td>
                </>
              ) : null}
              <td className="numerical">{subAccount && subAccount.forecast ? Utils.formatNumber(subAccount.forecast, FORMAT_TWO_DP) : ""}</td>
              <td className="numerical">{subAccount && subAccount.forecast ? Utils.formatNumber(subAccount.forecast / dcSizeWatts, FORMAT_TWO_DP) : ""}</td>
              <td className="numerical">{subAccount && subAccount.forecast && subAccount.forecast ? Utils.formatNumber((subAccount.forecast / data.forecast) * 100, FORMAT_TWO_DP) : ""}</td>
            </tr>
          )
        })
    }
  }
}

export default SourcesUsesView
