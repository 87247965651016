import React, { Component } from "react"

import { Loading } from "@common/EcosuiteComponent"
import AssetStatus from "@dashboard/energy/views/info/AssetStatus"

import EnergyView from "./EconodeEnergyView"

import LiveSite from "./live/LiveSite"

class ProjectEnergyOverView extends EnergyView {
  getSiteStatus(siteId) {
    if (this.props.projectStatus) {
      return this.props.projectStatus.sites[siteId]
    }
    return null
  }

  getSiteReading(siteId) {
    if (this.props.readings) {
      return this.props.readings.sites[siteId]
    }
    return null
  }

  renderMainView() {
    if (this.props.project) {
      return (
        <div>
          <Project {...this.props} />

          {Object.values(this.props.project.sites).map((site) => {
            let siteStatus = this.isContentValid(this.props.projectStatus) ? this.props.projectStatus.sites[site.code] : this.props.projectStatus

            return (
              <LiveSite
                key={site.code}
                groups={this.props.groups}
                project={this.props.project}
                site={site}
                siteStatus={siteStatus}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
              />
            )
          })}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

class Project extends Component {
  render() {
    return (
      <div className="project-details">
        <AssetStatus className="project-details-info" asset={this.props.project} status={this.props.projectStatus} />
      </div>
    )
  }
}

export default ProjectEnergyOverView
