import React, { Component } from "react"

import { ProjectFilter } from "./menu/ProjectFilter"
import { ProjectBrowser } from "./menu/ProjectBrowser"
import { ProjectSearch } from "./menu/ProjectSearch"
import PortfolioSelector from "./menu/PortfolioSelector"

/**
 * The projects selector is used at the top level to allow the projects that are being viewed to be grouped by selecting and deselecting the ones to show.
 */
export default class ProjectMenu extends Component {
  render() {
    return (
      <div className="selector">
        <PortfolioSelector {...this.props} />
        <ProjectSearch actions={this.props.actions} searchTerm={this.props.searchTerm} />
        <ProjectFilter {...this.props} />
        <ProjectBrowser
          projects={this.props.projects}
          selectedProject={this.props.selectedProject}
          disabledProjects={this.props.disabledProjects}
          actions={this.props.actions}
          portfolio={this.props.portfolio}
          portfoliosEnabled={this.props.portfoliosEnabled}
        />
      </div>
    )
  }
}
