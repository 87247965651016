import React from "react"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, LineChart, Line } from "recharts"
import moment from "moment-timezone"

import { Loading, Error } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import DateRangeGraph from "../DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"
import { BatteryInfo, LargeBatteryInfo } from "@dashboard/finance/views/Battery"
import { produce } from "immer"

export default class StoreageLineGraph extends DateRangeGraph {
  getData() {
    let wattHours = 0
    let revenue = 0
    let bounds = {
      floor: 200000,
      ceiling: 0,
    }

    const calculateRevenue = (watts, energyPrice) => {
      if (watts >= 0) {
        return 0
      }
      const kwh = watts / 1000
      const rev = kwh * (energyPrice / 1000)
      return Number((-rev).toFixed(2))
    }

    const updateBounds = (newRevenue) => {
      bounds.ceiling = Math.max(bounds.ceiling, newRevenue)
      bounds.floor = Math.min(bounds.floor, newRevenue)
    }

    let data = Object.entries(this.props.graphDatums).map(([dateKey, datum]) => {
      let date = moment(dateKey)
      wattHours += datum.storage ? datum.storage : 0

      const newRevenue = calculateRevenue(datum.storage || 0, datum.energyPrice)
      revenue += newRevenue
      updateBounds(newRevenue)

      return {
        date: date.toDate(),
        time: date.toDate().getTime(),
        name: date.format("lll"),
        wattHours,
        watts: datum.storage || 0,
        availWattHours: datum.availWattHours,
        soc: datum.soc,
        soh: datum.soh,
        capacityWattHours: datum.capacityWattHours,
        revenue,
      }
    })

    if (data.length > 0) {
      const lastEntry = data[data.length - 1]
      lastEntry.scaleFrom0 = Math.max(bounds.ceiling, Math.abs(bounds.floor))
      lastEntry.floor = bounds.floor
      lastEntry.ceiling = bounds.ceiling
    }

    return this.generateEmptyGraphDatums(data)
  }

  generateEmptyGraphDatums(datums) {
    const newDatums = produce(datums, (draft) => {
      const neededExtras = Math.floor((datums.length - 1) / 2)
      const remainder = ((datums.length - 1) / 2) % 1
      const interval = datums[1].time - datums[0].time
      let timeIdx = datums[datums.length - 1].time
      for (let i = 0; i <= neededExtras; ++i) {
        if (i === neededExtras) {
          timeIdx += interval * remainder
        } else {
          timeIdx += interval
        }
        draft.push({ time: timeIdx })
      }
    })
    return newDatums
  }

  adjustEndDate(range, endLimit) {
    if (range.end.isAfter(moment(endLimit))) {
      return { start: range.start, end: moment(endLimit) }
    }
    return range
  }

  setHoveringState(state) {
    this.setState({ isHovering: state })
  }

  renderContent() {
    if (this.props.graphDatums) {
      if (Object.keys(this.props.graphDatums).length === 0) {
        return <Error error={"No Batteries configured for project"} />
      }
      let data = this.getData()
      let aggregation = this.props.aggregation
      const graphType = "monotone"
      let scale = 0
      const finalValue = produce(
        data.findLast((element) => element.soc !== undefined),
        (draft) => {
          scale = draft.ceiling - draft.floor
          if (scale < 3) {
            scale = 3
            draft.floor = draft.revenue - 3
          }
        },
      )
      let range = this.adjustEndDate(this.props.range, finalValue.date)

      return (
        <div style={{ marginTop: "20px", display: "inline-flex", width: "100%" }}>
          <ResponsiveContainer className="battery-graph" width="100%" height={250 * 2}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 30, left: 0, bottom: 10 }}
              onClick={this.zoomDateRange}
              onMouseLeave={() => this.setHoveringState(true)}
            >
              <XAxis
                dataKey="time"
                type="number"
                scale="time"
                minTickGap={2}
                domain={[range.start, range.end]}
                tickFormatter={(time) => {
                  return GraphUtils.dateFormat(time, range, this.props.aggregation)
                }}
              />
              <YAxis
                domain={[finalValue.floor, finalValue.revenue + scale / 2]}
                tickFormatter={(revenue) => {
                  if (revenue < 0) {
                    return "-$" + Math.abs(revenue).toFixed(2)
                  }
                  return "$" + Math.abs(revenue).toFixed(2)
                }}
              />

              <Tooltip
                content={(props) => {
                  if (props.payload[0]) {
                    if (this.state.isHovering && props.payload[0].payload) {
                      this.setHoveringState(false)
                    }
                    return (
                      <BatteryInfo
                        soc={props.payload[0].payload.soc}
                        watts={props.payload[0].payload.watts}
                        wattHours={props.payload[0].payload.wattHours}
                        revenue={props.payload[0].payload.revenue}
                      />
                    )
                  } else if (!this.state.isHovering) {
                    this.setHoveringState(true)
                  }
                  return null
                }}
              />
              <Line type={graphType} hide dataKey={"soc"} stroke={GRAPH_COLORS[7]} dot={aggregation === "day"} />
              <Line
                type={graphType}
                dataKey={"revenue"}
                stroke={GRAPH_COLORS[7]}
                strokeWidth={4}
                dot={false}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
          {this.state.isHovering ? (
            <div style={{ position: "absolute", width: "100%" }}>
              <LargeBatteryInfo
                soc={finalValue.soc}
                soh={finalValue.soh}
                watts={finalValue.watts}
                revenue={finalValue.revenue}
                availWattHours={finalValue.availWattHours}
                capacityWattHours={finalValue.capacityWattHours}
                style={{ top: "-8.5px", left: "66.4%", position: "absolute" }}
              />
            </div>
          ) : null}
        </div>
      )
    }
    return <Loading />
  }
}
