import React from "react"
import { Button, ButtonGroup, ButtonToolbar, Input } from "reactstrap"

import { Loading, Message } from "@common/EcosuiteComponent"
import SelectAll, { evaluateSelectAllStatus } from "@common/input/button/SelectAll"
import EcosuiteView from "@common/module/EcosuiteView"
import Confirmation from "@common/input/button/Confirmation"
import DRImportParticipantsView from "./DRImportParticipantsView"
import DRService from "../DRService"
import i18n from "src/i18n"

const { t } = i18n
export default class DRParticipantView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.state = {
      showTooltip: true,
      participantTableSelected: {},
      showImportView: null,
    }

    this.clearSelectedParticipants = this.clearSelectedParticipants.bind(this)
    this.renderImportDialog = this.renderImportDialog.bind(this)
    this.renderViewControls = this.renderViewControls.bind(this)
  }

  isReadOnly() {
    return false
  }

  setTooltipEvent(participant) {
    this.setStateIfMounted({
      tooltipProgram: participant,
    })
  }

  /**
   * Clear the selected participants.
   */
  clearSelectedParticipants() {
    this.setStateIfMounted({
      participantTableSelected: {},
    })
  }

  renderViewControls() {
    return (
      <div className={"content-view-controls"}>
        <ButtonToolbar className="secondary-header-toolbar float-end">
          <ButtonGroup className="header-button-group">
            <Confirmation
              idSuffix={"delete-participants"}
              text={t("labels.delete_selected")}
              color={"danger"}
              header={t("demand.labels.delete_participants")}
              onConfirm={() => {
                const toDelete = Object.values(this.state.participantTableSelected).map((p) => p.id)
                const actions = toDelete.map((id) => DRService.deleteParticipant(id))

                Promise.all(actions).then(() => this.props.fetchParticipants())
              }}
              body={t("demand.messages.confirm_participants_deletion")}
              toolTip={t("demand.labels.delete_selected_participants")}
              irreversible={true}
            />
            <Button className={this.props.participant && !this.props.participant.id ? "selected" : ""} onClick={() => this.setState({ showImportView: true })} size="sm" color="ecogy">
              {t("demand.labels.import_participants")}
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }

  renderImportDialog() {
    return null
  }

  renderMainView() {
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.showImportView) {
      return (
        <DRImportParticipantsView
          fetchParticipants={this.props.fetchParticipants}
          projects={this.props.projects}
          participants={this.props.participants}
          onCancel={() => {
            this.setState({ showImportView: false })
          }}
        />
      )
    }

    const participants = this.props.participants
    if (participants) {
      if (participants.length > 0) {
        const tooltipEvent = this.state.tooltipEvent
        return (
          <div className="participants">
            <table className="participants-table">
              <thead>
                <tr>
                  <th>
                    <SelectAll
                      status={evaluateSelectAllStatus(Object.keys(participants).length, Object.keys(this.state.participantTableSelected).length)}
                      allToggle={() => {
                        const currentEventTableSelected = structuredClone(this.state.participantTableSelected)

                        participants.forEach((participant) => {
                          currentEventTableSelected[`${participant.id}`] = participant
                        })

                        this.setStateIfMounted({
                          participantTableSelected: currentEventTableSelected,
                        })
                      }}
                      noneToggle={() => this.clearSelectedParticipants()}
                    />
                  </th>
                  <th>{t("table_headings.code")}</th>
                  <th>{t("table_headings.name")}</th>
                  <th>{t("labels.contact_user")}</th>
                  <th>{t("labels.phone_number")}</th>
                  {this.isReadOnly() ? null : <th></th>}
                </tr>
              </thead>
              <tbody>
                {participants.map((participant) => {
                  let selectedClass = tooltipEvent && tooltipEvent.id === participant.id ? "selected" : null
                  const project = this.props.projects.find((project) => project.code === participant.code)

                  return project ? (
                    <tr key={participant.id} className={selectedClass ? selectedClass : null} onMouseOver={() => this.setTooltipEvent(participant)} onMouseOut={() => this.setTooltipEvent()}>
                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                        <Input
                          type={"checkbox"}
                          checked={participant.id in this.state.participantTableSelected}
                          onChange={() => {
                            const currentEventTableSelected = structuredClone(this.state.participantTableSelected)

                            if (participant.id in this.state.participantTableSelected) {
                              delete currentEventTableSelected[`${participant.id}`]
                            } else {
                              currentEventTableSelected[`${participant.id}`] = participant
                            }

                            this.setStateIfMounted({
                              participantTableSelected: currentEventTableSelected,
                            })
                          }}
                        />
                      </td>
                      <td>{participant.code}</td>
                      <td>{project.name}</td>
                      <td>{participant.contactUser}</td>
                      <td>{participant.phoneContact}</td>
                    </tr>
                  ) : null
                })}
              </tbody>
            </table>

            {/*this.getTooltip(tooltipEvent)*/}
          </div>
        )
      } else {
        return <Message message={t("messages.no_notification")} />
      }
    } else {
      return <Loading />
    }
  }
}
