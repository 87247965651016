import API, { IRange } from "@common/API"

const ServiceRequestService = {
  warmCache() {
    API.cacheGet("/schemas/service-request")
  },

  getServiceRequestSchema() {
    return API.getFromCache("/schemas/service-request")
  },

  listServiceRequests(range: IRange, projectId: string, siteId: string) {
    let url = API.addRangeToUrl("/service-requests", range)
    if (projectId) {
      url += "&project=" + projectId
    }
    if (siteId) {
      url += "&site=" + siteId
    }
    return API.get(url as string)
  },

  async getServiceRequest(serviceRequestId: string) {
    return API.get<{ serviceRequest: unknown }>("/service-requests/" + serviceRequestId).then((response) => response.serviceRequest)
  },

  /**
   * Generate a service request to Google Docs.
   * @param serviceRequestId - The service request identifier.
   * @param shareWith - The users to share the document with.
   * @returns {Promise<T>} - A promise representing the API response.
   */
  async generateServiceRequest(serviceRequestId: string, shareWith: unknown) {
    return await API.post("/service-requests/" + serviceRequestId + "/generate", {
      shareWith: shareWith,
    })
  },

  async createServiceRequest(form: { formData: unknown }) {
    return API.post<{ serviceRequest: unknown }, unknown>("/service-requests", form.formData).then((response) => response.serviceRequest)
  },

  async updateServiceRequest(serviceRequestId: string, form: { formData: unknown }) {
    return API.put<{ serviceRequest: unknown }, unknown>("/service-requests/" + serviceRequestId, form.formData).then((response) => response.serviceRequest)
  },

  resolveServiceRequest(serviceRequestId: string) {
    return API.put("/service-requests/" + serviceRequestId + "/resolve")
  },

  deleteServiceRequest(serviceRequestId: string) {
    return API.delete("/service-requests/" + serviceRequestId)
  },
}

export default ServiceRequestService
