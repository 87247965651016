var DatumUtils = {
  groupSystemSourceDatums(systems) {
    if (systems) {
      let datums = systems.reduce((datums, system) => {
        if (system.sourceDatums) {
          Object.keys(system.sourceDatums).forEach((sourceId) => {
            let sourceDatums = system.sourceDatums[sourceId]

            Object.keys(sourceDatums).forEach((dateKey) => {
              let datum = sourceDatums[dateKey]

              // Create new date entry if needed
              if (!datums[dateKey]) {
                // Create an object to store the datums for all assets, this will be added to for the date for each asset
                let assetDatums = {}
                assetDatums[sourceId] = datum

                // Set up the datum for the date
                datums[dateKey] = {
                  assetDatums: assetDatums,
                }
              } else {
                // Add a record for this source to the datums
                datums[dateKey].assetDatums[sourceId] = datum
              }
            })
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },
}

export default DatumUtils
