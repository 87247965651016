import React from "react"
import { create, all } from "mathjs"

import EnergyView from "./EconodeEnergyView"
import EcosuiteComponent, { Loading, Error, Message } from "@common/EcosuiteComponent"
import ProjectUtils from "@common/utils/ProjectUtils"
import EconodeUtils from "src/econode/EconodeUtils"
import { SideBarSection } from "@common/module/EcosuiteView"
import { LAYOUT_CONTENT_RIGHT } from "@common/module/EcosuiteView"
import { EnergyPieGraph } from "@dashboard/energy/graphs/EnergyGraphs"
import Emissions from "@dashboard/energy/views/info/Emissions"

import PreviousPeriodConsumptionComparison from "./widgets/PreviousPeriodConsumptionComparison"
import PreviousPeriodConsumption from "./widgets/PreviousPeriodConsumption"
import ActualVsPredictedConsumption from "./widgets/ActualVsPredictedConsumption"
import PredictedConsumption from "./widgets/PredictedConsumption"
import Consumption from "./widgets/Consumption"
import DownloadModal from "@dashboard/energy/DownloadModal"
import i18n from "src/i18n"

const { t } = i18n
const math = create(all)

export default class EconodeEnergyDashboard extends EnergyView {
  getLayout() {
    return LAYOUT_CONTENT_RIGHT
  }

  additionalViewActionButtons() {
    return this.props.restrictions.preventDownload === "yes" ? null : <DownloadModal label={t("buttons.download")} size="sm" className="download-modal" {...this.props} />
  }

  renderMainView() {
    if (!this.props.projects) {
      return <Loading />
    } else if (this.props.projects.length < 1) {
      return <Message message={`${t("alertsInfo.no_filtered_projects")}`} />
    }

    let sites = ProjectUtils.getSitesForProjects(this.props.projects)
    let systems = ProjectUtils.getSystemsForProjects(this.props.projects)
    let econodes = EconodeUtils.filterEconodeSystems(systems)

    return (
      <div className="EnergyView">
        <table className="econode-info">
          <tbody>
            <tr>
              <td>
                <p className="highlight">{this.props.projects.length}</p>
                <p>{t("labels.projects")}</p>
              </td>
              <td>
                <p className="highlight">{sites.length}</p>
                <p>{t("labels.sites")}</p>
              </td>
              <td>
                <p className="highlight">{econodes.length}</p>
                <p>{t("econode.labels.econodes")}</p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderSiderBar() {
    let predictedConsumptionReading = this.props.predictedConsumption
      ? Object.values(this.props.predictedConsumption.projects).reduce((total, projectReading) => {
          if (projectReading.predictedConsumption) {
            return math.add(total, projectReading.predictedConsumption)
          } else {
            return total
          }
        }, 0)
      : null

    let predictedConsumption = { predictedConsumption: predictedConsumptionReading }

    return (
      <React.Fragment>
        {this.props.showConsumption ? (
          <SideBarSection title={`${t("econode.dataTitles.Actual This Period")}`} range={this.props.actualRange} content={<Consumption readings={this.props.readings} />} />
        ) : null}
        {this.props.showConsumption ? (
          <SideBarSection
            title={`${t("econode.dataTitles.Actual Last Period")}`}
            range={this.props.previousRange}
            content={<PreviousPeriodConsumption previousReadings={this.props.previousReadings} />}
          />
        ) : null}
        {this.props.showConsumption ? (
          <SideBarSection
            title={`${t("econode.dataTitles.Compared to Last Period")}`}
            content={<PreviousPeriodConsumptionComparison readings={this.props.readings} previousReadings={this.props.previousReadings} />}
          />
        ) : null}
        {this.props.showConsumption ? (
          <SideBarSection
            title={`${t("econode.dataTitles.Forecast This Period")}`}
            range={this.props.actualRange}
            content={<PredictedConsumption readings={this.props.readings} predictedConsumption={predictedConsumption} />}
          />
        ) : null}
        {this.props.showConsumption ? (
          <SideBarSection
            title={`${t("econode.dataTitles.Compared to Forecast")}`}
            content={<ActualVsPredictedConsumption readings={this.props.readings} predictedConsumption={predictedConsumption} />}
          />
        ) : null}

        {/* 
          EP-2803
          https://ecogyenergy.atlassian.net/jira/software/c/projects/EP/boards/2?modal=detail&selectedIssue=EP-2803&assignee=6254fe3ffdb60b006927952d  
        */}
        {/* {this.props.showConsumption ? (
          <SideBarSection title="Trend This Period" content={<ActualPredictedConsumptionDifference readings={this.props.readings} predictedConsumption={predictedConsumption} />} />
        ) : null} */}

        {this.props.showConsumption ? (
          <SideBarSection title={`${t("econode.dataTitles.Energy Consumed")}`} content={<EnergyConsumedPieGraph readings={this.props.readings} projects={this.props.projects} />} />
        ) : null}
        {this.props.showConsumption ? <Emissions readings={this.props.readings} sourceType="consumption" /> : null}
      </React.Fragment>
    )
  }
}

class EnergyConsumedPieGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentValid(this.props.readings)) {
      let data = this.props.projects.map((project) => {
        let projectReadings = this.props.readings.projects[project.code]
        return { name: project.code, value: projectReadings ? projectReadings.consumption : null }
      })
      return <EnergyPieGraph data={data} />
    } else {
      return <Loading />
    }
  }
}
