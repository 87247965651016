import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import "./FileDropzone.scss"
import { Progress } from "reactstrap"
import JSZip, { JSZipObject } from "jszip"

import i18n from "src/i18n"

const { t } = i18n
/**
 * The FileDropzone properties.
 */
export interface FileDropzoneProps {
  onChange?: (files: File[]) => void
  uploadProgress?: Record<string, number>
  disabled?: boolean
  waitText?: string
}

export async function fullyExpand(files: File[]): Promise<File[]> {
  const retval = []

  for (const file of files) {
    if (file.type != "application/zip") {
      retval.push(file)
    } else {
      // is a zip, let's unzip it and add it
      const zipFile = await JSZip.loadAsync(file.arrayBuffer())

      for (const [name, zFile] of Object.entries(zipFile.files)) {
        if (zFile.dir) {
          continue
        }

        const blob = await zFile.async("blob")
        const f = new File([blob], name.replaceAll("/", "-"), { type: "application/pdf" })
        retval.push(f)
      }
    }
  }

  return retval
}

/**
 * A simple file dropzone.
 * @param props - The FileDropzone properties.
 * @constructor
 */
export const FileDropzone = (props: FileDropzoneProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    if (props.disabled === false) setFiles([])
  }, [props.disabled])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: props.disabled === false,
    onDropAccepted: (rawFiles, event) => {
      fullyExpand(rawFiles)
        .then((newFiles) => {
          let newSelection: File[] = []
          const newSelectionTemporary: File[] = []

          // Keep a set of all current file names.
          const currentFileNameSet = new Set<string>()
          files.forEach((file) => {
            currentFileNameSet.add(file.name)
          })

          if (event) {
            switch (event.type) {
              case "change":
                // If a file is explicitly chosen, set that as the new sole selection.
                newSelection = newFiles
                setFiles(newSelection)
                break
              case "drop":
                // If a file is dropped, add it to the current selections.
                newFiles.forEach((newFile) => {
                  if (!currentFileNameSet.has(newFile.name)) {
                    newSelectionTemporary.push(newFile)
                  }
                  currentFileNameSet.add(newFile.name)
                })
                newSelection = [...files, ...newSelectionTemporary]
                setFiles(newSelection)
            }
          } else {
            // Chromium doesn't seem to provide an event when a file is just selected, so in this case just perform the
            // action of if the event was "change".
            newSelection = newFiles
            setFiles(newSelection)
          }

          // Notify the parent of the change.
          //props.onChange?.call(this, newSelection)
          return newSelection
        })
        .then((selection) => {
          props.onChange?.call(this, selection)
        })
    },
  })

  /**
   * Calculate the progress average.
   * @param values - The values of the file progress.
   */
  const calculateProgressAverage = (values: number[]): number => {
    const sum = values.reduce((a, b) => a + b, 0) * 100
    return sum / values.length || 0
  }

  /**
   * Get the class name according to whether the box is currently being dragged.
   * @param className - The default class name.
   * @param isDragActive - Whether drag is currently enabled.
   */
  const getDragClassName = (className: string, isDragActive: boolean): string => {
    return isDragActive ? `${className}-drag` : className
  }

  const currentProgress = props.uploadProgress ? calculateProgressAverage(Object.values(props.uploadProgress)) : 0

  return (
    <>
      <div className={getDragClassName("file-dropzone", isDragActive)}>
        <div {...getRootProps({ className: "dropzone" })}>
          <input className="input-zone" {...getInputProps()} />

          <div className="text-center">
            <p className={getDragClassName("dropzone-content", isDragActive)}>
              {!props.disabled && props.waitText
                ? props.waitText
                : isDragActive
                  ? `${t("notes.drop_files_here")}`
                  : files.length > 0
                    ? files.length == 1
                      ? `${t("notes.selected_file", { selectedFiles: files.length })}`
                      : `${t("notes.selected_files", { selectedFiles: files.length })}`
                    : `${t("notes.select_files")}`}
            </p>
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <aside className={"dropzone-preview"}>
          <ul>
            {files.map((file) => (
              <li key={file.name}>{file.name}</li>
            ))}
          </ul>
        </aside>
      )}
      {props.uploadProgress && Object.keys(props.uploadProgress).length > 0 && (
        <>
          <>
            <Progress
              animated={true}
              striped={true}
              value={currentProgress}
            >{`${currentProgress.toFixed()}%`}</Progress>
          </>
        </>
      )}
    </>
  )
}

export default FileDropzone
