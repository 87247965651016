import React from "react"

import moment from "moment-timezone"
import PropTypes from "prop-types"

import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import Settings from "@common/Settings"
import Logger from "@common/Logger"
import ProjectUtils from "@common/utils/ProjectUtils"
import Aggregations from "@common/Aggregations"

import GraphSelector from "@dashboard/GraphSelector"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import EnergyService from "@dashboard/energy/EnergyService"
import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"

import EnergyView from "./EconodeEnergyView"
import GraphUtils from "@common/utils/GraphUtils"
import DateRangeUtils from "@common/utils/DateRangeUtils"

export default class EnergyProjectSourceGraphsView extends EnergyView {
  constructor(props) {
    super(props, "econode-energy-project-source-graphs")

    this.state.sourceGraphView = Settings.getSetting("sourceGraphView", "kW")

    this.loadSourceDatums = this.loadSourceDatums.bind(this)
    this.selectGraph = this.selectGraph.bind(this)

    this.loadSourceDatums(this.props.range)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.range.isSame(prevProps.range) || this.props.project !== prevProps.project) {
      Logger.debug(`Range updated from ${prevProps.range} to ${this.props.range}, loading source datums`)
      this.loadSourceDatums(this.props.range)
    }
  }

  loadSourceDatums(range) {
    let aggregate = range.end.diff(range.start, "days") < 2 && range.end.diff(range.start, "hours") > 1 ? Aggregations.FifteenMinute : null
    Logger.debug(`Loading source datums for range: ${range.start.format("lll")} ${range.end.format("lll")} with aggregate: ${aggregate}`)

    this.setStateIfMounted({ datums: null, range: null, aggregation: null })
    EnergyService.getNodesDatums(range, aggregate, this.props.project.code, null, null, null).then((response) => {
      this.setStateIfMounted({ datums: response.data, range: response.range, aggregation: response.aggregation })
    })
  }

  selectGraph(graph) {
    Settings.setSetting("sourceGraphView", graph)
    this.setStateIfMounted({ sourceGraphView: graph })
  }

  getSources(unit, systemId) {
    // Logger.debug(`Loading sources for unit: ${unit} and system: ${systemId}`)
    let datums = this.state.datums.filter((datum) => {
      let systemFilter = systemId ? datum.sourceId.startsWith(systemId) : true
      let genFilter = EnergyUtils.isGeneratingSource(datum.sourceId) && this.props.showGeneration
      let conFilter = EnergyUtils.isConsumingSource(datum.sourceId) && this.props.showConsumption
      return systemFilter && (genFilter || conFilter)
    })

    let sources = {}
    let date = moment(this.state.range.localStartDate)
    let end = moment(this.state.range.localEndDate)
    while (date.isBefore(end)) {
      let dateKey = date.format("YYYY-MM-DDTHH:mm")
      sources[dateKey] = {
        time: moment(dateKey).valueOf(),
      }
      date.add(Aggregations.getSize(this.state.aggregation), Aggregations.getUnits(this.state.aggregation))
    }

    let sourceIds = new Set()
    datums.forEach((datum) => {
      sourceIds.add(datum.sourceId) // keep track of any sourceIds we come across
      let dateKey = `${datum.localDate}T${datum.localTime}`
      if (sources[dateKey]) {
        sources[dateKey][datum.sourceId] = datum[unit]
      } else {
        Logger.warning(`Ignoring unexpected local date in results: ${dateKey} for source: ${datum.sourceId}`)
      }
    })

    // Logger.debug(`Loaded data for sources: ${Array.from(sourceIds)}`)

    return {
      sourceIds: Array.from(sourceIds),
      data: Object.values(sources),
    }
  }

  renderMainView() {
    if (this.state.datums) {
      let systemIds = ProjectUtils.getSystemCodesForProject(this.props.project)
      return (
        <div>
          {systemIds.map((systemId) => {
            let graphs = {
              kW: {
                graph: (
                  <SourceGraph
                    {...this.props}
                    range={this.state.range}
                    aggregation={this.state.aggregation}
                    sources={this.getSources("watts", systemId)}
                    selectRange={this.props.selectRange}
                    unit="watts"
                  />
                ),
                name: `${systemId} kW`,
              },
              kWH: {
                graph: (
                  <SourceGraph
                    {...this.props}
                    range={this.state.range}
                    aggregation={this.state.aggregation}
                    sources={this.getSources("wattHours", systemId)}
                    selectRange={this.selectRange}
                    unit="wattHours"
                  />
                ),
                name: `${systemId} kWh`,
              },
            }

            return (
              <GraphSelector key={systemId} selectedGraph={this.state.sourceGraphView} selectGraph={this.selectGraph} graphs={graphs} range={this.props.range} selectRange={this.props.selectRange} />
            )
          })}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

class SourceGraph extends DateRangeGraph {
  zoomDateRange(selection) {
    if (selection && this.props.aggregation !== Aggregations.MINIMUM) {
      let milliseconds = selection.activeLabel
      if (typeof milliseconds === "string") {
        milliseconds = moment(milliseconds).valueOf() // get the milliseconds from the date
      }
      if (milliseconds > 0) {
        const zoomRange = DateRangeUtils.getDateRange(moment(milliseconds), this.props.aggregation)
        this.props.selectRange("custom", zoomRange)
      }
    }
  }

  renderContent() {
    let sources = this.props.sources
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={sources.data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
          <XAxis
            dataKey="time"
            type="number"
            scale="time"
            domain={this.getDomain(this.props.range, this.props.aggregation)}
            tickFormatter={(time) => {
              return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
            }}
          />
          <YAxis />
          <Tooltip content={<SourceTooltip unit={this.props.unit} range={this.props.range} aggregation={this.props.aggregation} />} />
          <Legend iconType="plainline" />
          {sources.sourceIds.map((sourceId, idx) => {
            let color = GRAPH_COLORS[idx % sources.sourceIds.length]
            return <Area key={sourceId} name={sourceId} type="monotone" dataKey={sourceId} stroke={color} fill={color} dot={true} isAnimationActive={false} />
          })}
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}

class SourceTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {payload.map((data) => {
              return (
                <React.Fragment key={data.dataKey}>
                  <p className="label" style={{ color: data.fill }}>
                    {`${data.name} : ${this.props.unit === "watts" ? EnergyUtils.formatKiloWatts(data.value ? data.value : 0) : EnergyUtils.formatKiloWattHours(data.value ? data.value : 0)}`}
                  </p>
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }
    return null
  }
}
