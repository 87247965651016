import React from "react"

import EcosuiteComponent from "@common/EcosuiteComponent"

import EnergyUtils from "@dashboard/energy/EnergyUtils"

import SolarFluxGraph from "@dashboard/energy/graphs/SolarFluxGraph"

import LiveSystem from "./LiveSystem"
import LiveSourceTable from "./LiveSourceTable"
import i18n from "src/i18n"

const { t } = i18n
class Site extends EcosuiteComponent {
  renderContent() {
    let siteStatus = this.props.siteStatus

    let unmatchedNodes = this.isContentValid(siteStatus) ? EnergyUtils.filterOutChildNodes(siteStatus, Object.values(siteStatus.systems)) : []

    return (
      <div className="site-content">
        <h4 className={"content-title " + (this.isContentValid(siteStatus) && siteStatus.status ? siteStatus.status : "no-status")}>
          {this.props.site.name} ({this.props.site.code})
        </h4>

        {EnergyUtils.getSystems(this.props.site).map((system) => {
          let systemStatus = this.isContentValid(siteStatus) ? siteStatus.systems[system.code] : undefined
          let systemReadings = this.isContentValid(this.props.readings) ? this.props.readings.systems[system.code] : this.props.readings
          let systemConsumptionCost = this.isContentValid(this.props.consumptionCost) ? this.props.consumptionCost.systems[system.code] : this.props.consumptionCost
          let systemPredictedConsumption = this.isContentValid(this.props.predictedConsumption) ? this.props.predictedConsumption.systems[system.code] : this.props.predictedConsumption
          let systemExpectedGeneration = this.isContentValid(this.props.expectedGeneration) ? this.props.expectedGeneration.systems[system.code] : this.props.expectedGeneration
          return (
            <LiveSystem
              key={system.code}
              project={this.props.project}
              site={this.props.site}
              system={system}
              systemStatus={systemStatus}
              readings={systemReadings}
              datums={this.props.datums}
              consumptionCost={systemConsumptionCost}
              predictedConsumption={systemPredictedConsumption}
              expectedGeneration={systemExpectedGeneration}
              range={this.props.range}
              showGeneration={this.props.showGeneration}
              showConsumption={this.props.showConsumption}
            />
          )
        })}

        {unmatchedNodes.length > 0 ? (
          <React.Fragment>
            <h5 className="content-subtitle">{t("energy.headings.unmatched_sources")}</h5>
            <LiveSourceTable nodesStatus={unmatchedNodes} showGeneration={this.props.showGeneration} showConsumption={this.props.showConsumption} range={this.props.range} datums={this.props.datums} />
          </React.Fragment>
        ) : null}

        <SolarFluxGraph project={this.props.project} site={this.props.site} showGeneration={this.props.showGeneration} showConsumption={this.props.showConsumption} />
      </div>
    )
  }
}

export default Site
