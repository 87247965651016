import React, { useState } from "react"
import { Modal as BootstrapModal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap"
import "../LoadingModal.scss"
import Modal, { useModal } from "@ebay/nice-modal-react"

interface ConfirmModalProps {
  confirmText: string
  title: string
  message: string
  onConfirm: () => void
  size?: "sm" | "lg" | "xl"
  cancelText: string
}

/** 
    ConfirmDialog is a modal component that displays a confirm message with a confirm button.
    @param {ConfirmModalProps} props - The props for the component.
    @property {string} props.confirmText - The text to display on the confirm button.
    @property {string} props.title - The title to display on the modal.
    @property {string} props.message - The message to display in the body of the modal.
    @property {function} props.onConfirm - A callback function to be called when the confirm button is clicked.
*/
const ConfirmDialog = Modal.create((props: ConfirmModalProps) => {
  const modal = useModal()

  const { confirmText, title, message, onConfirm, size = "sm", cancelText } = props

  const [clicked, setClicked] = useState(false)

  return (
    <BootstrapModal centered={true} size={size} isOpen={modal.visible} toggle={modal.visible ? () => modal.hide() : undefined}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>

      <ModalFooter>
        <div style={{ display: "flex" }}>
          {confirmText && (
            <Button
              disabled={clicked}
              color="primary"
              onClick={async () => {
                setClicked(true)
                if (onConfirm) await onConfirm()
                modal.hide()
              }}
            >
              {clicked ? "Confirming..." : confirmText}
            </Button>
          )}
          {cancelText && <Button onClick={() => modal.hide()} style={{marginLeft: "8px"}}>Cancel</Button>}
        </div>
      </ModalFooter>
    </BootstrapModal>
  )
})

export default ConfirmDialog
