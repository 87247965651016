import React from "react"
import moment from "moment"

import EcosuiteView from "@common/module/EcosuiteView"
import { Loading } from "@common/EcosuiteComponent"
import i18n from "src/i18n"

const { t } = i18n
export default class UserAlertsView extends EcosuiteView {
  isReadOnly() {
    return !this.props.groups.includes("event-write")
  }

  renderMainView() {
    if (this.props.userAlerts) {
      return (
        <table>
          <thead>
            <tr>
              <th>{t("labels.source")}</th>
              <th>{t("event.labels.local_timestamp")}</th>
              <th>{t("labels.message")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.userAlerts.map((alert, idx) => {
              return (
                <tr key={idx}>
                  <td>{alert.sourceId}</td>
                  <td>{moment(alert.localTimestamp).format("lll")}</td>
                  <td>{alert.message}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    } else {
      return <Loading />
    }
  }
}
