import React, { useState } from "react"
import { ChevronRight, Upload, FolderOpen, LogOut, HelpCircle } from "lucide-react"
import { Button } from "src/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu"
import { useHistory, useLocation } from "react-router-dom"
import { Auth } from "aws-amplify"

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleMenu = () => setIsOpen(!isOpen)

  return (
    <div className="tw-w-full">
      {/* Hamburger menu for small screens */}
      <div className="lg:tw-hidden">
        <Button
          variant="outline"
          size="icon"
          className="tw-fixed tw-top-4 tw-left-4 tw-z-50 tw-bg-background tw-text-foreground tw-rounded-full"
          onClick={toggleMenu}
        >
          <ChevronRight className="tw-h-4 tw-w-4" />
        </Button>
        {/* Overlay */}
        {isOpen && (
          <div
            className="tw-fixed tw-inset-0 tw-bg-background/80 tw-backdrop-blur-sm tw-z-[9998]"
            onClick={toggleMenu}
          />
        )}
        {/* Slide-out menu */}
        <div
          className={`
          tw-fixed tw-top-0 tw-left-0 tw-h-full tw-w-full tw-max-w-[16rem] tw-bg-background tw-border-r tw-z-[9999]
          tw-transform tw-transition-transform tw-duration-300 tw-ease-in-out
          ${isOpen ? "tw-translate-x-0" : "tw--translate-x-full"}
        `}
        >
          <SidebarContent />
        </div>
      </div>
      {/* Full sidebar for large screens */}
      <div className="tw-hidden lg:tw-block tw-h-screen tw-bg-background tw-border-r tw-border-border">
        <SidebarContent />
      </div>
    </div>
  )
}

const SidebarContent = () => {
  const menuItems = [
    { icon: Upload, label: "Upload new documents", link: "/onboarding/upload" },
    { icon: FolderOpen, label: "View uploaded documents", link: "/onboarding/view" },
  ]

  const [userEmail, setUserEmail] = React.useState<string | null>(null)

  React.useEffect(() => {
    const getUser = async () => {
      const user = await Auth.currentAuthenticatedUser()
      setUserEmail(user.attributes.email)
    }
    getUser()
  }, [])

  const history = useHistory()
  const location = useLocation()

  return (
    <div className="tw-flex tw-flex-col tw-h-full lg:tw-h-[calc(100vh-76px)] tw-text-foreground tw-pt-4">
      <nav className="tw-flex-1 tw-overflow-y-auto tw-py-4">
        <ul className="tw-space-y-2">
          {menuItems.map((item, index) => {
            const isActive = location.pathname === item.link
            return (
              <li key={index} className="tw-relative">
                <Button
                  variant="ghost"
                  className={`
                    tw-w-full tw-justify-start tw-text-foreground
                    hover:tw-bg-accent hover:tw-text-accent-foreground
                    ${isActive ? "tw-text-primary tw-bg-primary/5 tw-font-medium" : ""}
                  `}
                  style={{ display: "flex", justifyContent: "flex-start", paddingLeft: "32px" }}
                  onClick={() => history.push(item.link)}
                >
                  <item.icon className="tw-mr-2 tw-h-4 tw-w-4" />
                  {item.label}
                </Button>
                {isActive && (
                  <div className="tw-absolute tw-left-0 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-w-1 tw-h-8 tw-bg-primary tw-rounded-r" />
                )}
              </li>
            )
          })}
        </ul>
      </nav>
      <div className="tw-w-full tw-px-4 tw-pb-4 ">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              size="lg"
              variant="outline"
              className="tw-w-full tw-justify-between tw-text-foreground hover:tw-bg-accent hover:tw-text-accent-foreground"
            >
              <span className="tw-truncate">{userEmail}</span>
              <ChevronRight className="tw-h-4 tw-w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="tw-w-[var(--radix-dropdown-menu-trigger-width)] tw-max-w-none"
            align="center"
            sideOffset={5}
          >
            <DropdownMenuItem
              className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
              onClick={() => {
                Auth.signOut()
                history.push("/onboarding/login")
              }}
            >
              <span className="tw-flex tw-items-center">
                <LogOut className="tw-mr-2 tw-h-4 tw-w-4" />
                <span>Logout</span>
              </span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default Sidebar
