import React from "react"
import EcosuiteComponent from "@common/EcosuiteComponent"
import NotificationService from "src/profile/notifications/NotificationService"
import { Auth } from "aws-amplify"
import { ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from "reactstrap"
import BackdropDialog from "@common/input/button/BackdropDialog"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n

class RecordNotifications extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.toggleNotification = this.toggleNotification.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    if (this.props.project) {
      this.loadNotifications()
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.project && this.props.project !== prevProps.project) {
      this.loadNotifications()
    }
  }

  loadNotifications() {
    NotificationService.getUserNotifications(this.props.userId).then((notifications) => {
      if (!this.props.project) {
        return
      }

      this.setStateIfMounted({
        notifications: {
          upload: {
            disabled: false,
            active: notifications.find((n) => n.notificationType === "report" && n.when === "upload" && n.projects.find((p) => p === this.props.project.code)),
            text: t("messages.send_notifications"),
          },
          day: {
            disabled: false,
            active: notifications.find((n) => n.notificationType === "report" && n.when === "day" && n.projects.find((p) => p === this.props.project.code)),
            text: t("messages.send_daily_report"),
          },
          week: {
            disabled: false,
            active: notifications.find((n) => n.notificationType === "report" && n.when === "week" && n.projects.find((p) => p === this.props.project.code)),
            text: t("messages.send_weekly_report"),
          },
        },
      })
    })
  }

  async toggleNotification(type) {
    const old = this.state.notifications

    old[type].disabled = true
    this.setStateIfMounted({
      notifications: old,
    })

    const session = await Auth.currentSession()
    const userId = session.idToken.payload.sub
    const notifications = await NotificationService.getUserNotifications(userId)

    const root = notifications.find((n) => {
      return n.notificationType === "report" && n.when === type
    })

    if (!old[type].active) {
      const rootNotPresent = !root
      const notConfigured = rootNotPresent ? true : !root.projects.find((p) => p === this.props.project.code)

      if (rootNotPresent) {
        this.setStateIfMounted({
          showNotificationProtocol: true,
          notificationType: type,
        })
      } else if (notConfigured) {
        root.projects.push(this.props.project.code)
        await NotificationService.updateUserNotification(userId, root.id, root)
      }
    } else {
      // This might fail if the user fiddles around with their notifications
      if (root) {
        root.projects = root.projects.filter((p) => p !== this.props.project.code)
        await NotificationService.updateUserNotification(userId, root.id, root)
      }
    }

    old[type].disabled = false
    old[type].active = !old[type].active
    this.setStateIfMounted({
      notifications: old,
    })
  }

  renderNotificationCreation() {
    return (
      <>
        <BackdropDialog
          isOpen={this.state.showNotificationProtocol}
          type={"option"}
          optionProps={{
            options: {
              sms: "SMS",
              email: "Email",
            },
            defaultOptionKey: "email",
          }}
          body={
            <>
              <p>{t("messages.no_existing_notifications")}</p>
              <p>{t("messages.to_continue_protocol")}</p>
            </>
          }
          title={t("messages.select_notifications")}
          onCancel={() =>
            this.setStateIfMounted({
              showNotificationProtocol: false,
            })
          }
          onConfirm={async (selection) => {
            this.setStateIfMounted({
              showNotificationProtocol: false,
              showNotificationEndpoint: true,
              notificationProtocol: selection,
            })
          }}
        />
        <BackdropDialog
          isOpen={this.state.showNotificationEndpoint}
          type={"input"}
          body={
            <>
              <p>{t("messages.no_existing_notifications")}</p>
              <p>
                {t("messages.to_continue_endpoint")} {this.state.notificationProtocol} protocol.
              </p>
            </>
          }
          inputProps={{
            inputPlaceholder: t("labels.endpoint"),
            performText: t("loadingMsg.creating_notification"),
          }}
          title={t("messages.enter_notifications_endpoint")}
          onCancel={() =>
            this.setStateIfMounted({
              showNotificationEndpoint: false,
            })
          }
          onConfirm={async (selection) => {
            this.setStateIfMounted({
              notificationEndpoint: selection,
            })

            const session = await Auth.currentSession()
            const userId = session.idToken.payload.sub
            await NotificationService.createUserNotification(userId, {
              notificationType: "report",
              name: "Report Notification",
              protocol: this.state.notificationProtocol,
              endpoint: this.state.notificationEndpoint,
              when: this.state.notificationType,
              projects: [this.props.project.code],
              pending: [],
            })

            this.setStateIfMounted({
              showNotificationEndpoint: false,
            })
          }}
        />
      </>
    )
  }

  render() {
    return this.state.notifications && this.props.project ? (
      <>
        {this.renderNotificationCreation()}
        <Nav tabs>
          <NavItem className="ms-auto">
            <ButtonGroup className="header-button-group type-filter">
              <ButtonDropdown
                isOpen={this.state.notificationsOpen}
                toggle={() => {
                  this.setStateIfMounted({
                    notificationsOpen: !this.state.notificationsOpen,
                  })
                }}
                size={"sm"}
              >
                <DropdownToggle caret>{t("labels.notifications")}</DropdownToggle>

                <DropdownMenu>
                  {Object.entries(this.state.notifications).map(([k, v]) => {
                    return (
                      <DropdownItem
                        key={k}
                        disabled={v["disabled"]}
                        onClick={async () => {
                          await this.toggleNotification(k)
                        }}
                      >
                        <Icon
                          style={{ "padding-right": "0.5em" }}
                          icon={v["active"] ? "check_box" : "check_box_outline_blank"}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.toggleNotification(k)
                          }}
                        />
                        {v["text"]}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            </ButtonGroup>
          </NavItem>
        </Nav>
      </>
    ) : null
  }
}

export default RecordNotifications
