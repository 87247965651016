import React from "react"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import FinanceUtils from "@dashboard/finance/FinanceUtils"

import ReportSection from "../ReportSection"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class PortfolioCashFlowDifferences extends ReportSection {
  render() {
    return this.renderCategories()
  }

  // shouldComponentUpdate(nextProps) {
  //   //nextState
  //   // We only want to render if the content has changed, this is an expensive operation as there's a lot of data
  //   return nextProps.projects !== this.props.projects || nextProps.report !== this.props.report
  // }

  renderCategories() {
    const projects = this.props.projects
    const report = this.props.report

    return (
      <React.Fragment>
        <Category
          categoryName={t("economics.labels.Fixed Asset")}
          categoryKey={"Fixed Asset"}
          projects={projects}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />
        <Category
          categoryName={t("economics.labels.Revenue")}
          categoryKey={"Income"}
          projects={projects}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />
        <Category
          categoryName={t("economics.labels.Expense")}
          categoryKey={"Expense"}
          projects={projects}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />

        <SectionTotal
          id="net-income"
          projects={projects}
          totalKey="netIncomes"
          totalName="Net Income"
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />
        <SectionTotal
          id="unlevered-totals"
          projects={projects}
          totalKey="unleveredTotals"
          totalName={t("economics.labels.Unlevered Cash Flow")}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />
        <SectionTotal
          projects={projects}
          id="unlevered-irr-cash-flow-cumulative"
          totalKey="cumulativeUnleveredTotals"
          totalName={t("economics.labels.Cumulative Unlevered IRR CF")}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />
        <IRRSectionTotal
          id="unlevered-irr"
          projects={projects}
          totalKey="unleveredIrr"
          totalName="Unlevered IRR"
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />

        <Category
          categoryName={t("economics.labels.Liability")}
          categoryKey={"Liability"}
          projects={projects}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />
        <SectionTotal
          id="levered-totals"
          projects={projects}
          totalKey="leveredTotals"
          totalName={t("economics.labels.Levered Cash Flow")}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />
        <IRRSectionTotal
          id="levered-irr"
          projects={projects}
          totalKey="leveredIrr"
          totalName="Levered IRR"
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />

        <Category
          categoryName={t("economics.labels.Equity")}
          categoryKey={"Equity"}
          projects={projects}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />

        <SectionTotal
          projects={projects}
          id="retained-earnings"
          totalKey="retainedEarnings"
          totalName={t("economics.labels.Retained Earnings")}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />

        <SectionTotal
          projects={projects}
          id="total-value"
          totalKey="totalValue"
          totalName={t("economics.labels.Total Value")}
          report={report}
          toggleProjects={this.props.toggleProjects}
          paths={this.props.paths}
        />

        <UncontrolledTooltip target="net-income">{t("economics.tooltips.expense")}</UncontrolledTooltip>
        <UncontrolledTooltip target="unlevered-totals">
          {t("economics.tooltips.unlevered_cash_flow")}
        </UncontrolledTooltip>
        <UncontrolledTooltip target="levered-totals">{t("economics.tooltips.levered_cash_flow")}</UncontrolledTooltip>
        <UncontrolledTooltip placement="top" target="unlevered-irr-cash-flow-cumulative">
          {t("economics.tooltips.monthly_cumulation")}
        </UncontrolledTooltip>
      </React.Fragment>
    )
  }
}

const ComparisonAverages = (props) => {
  return (
    <div className="cash-flow">
      {props.totals.map((total, idx) => {
        return (
          <div key={idx} className={`${getTotalClass(idx)}`}>
            {FinanceUtils.formatCurrency(total / props.projects.length)}
          </div>
        )
      })}
    </div>
  )
}

const Totals = (props) => {
  return (
    <div className="cash-flow">
      {props.totals.map((total, idx) => {
        return (
          <div key={idx} className={getTotalClass(idx)}>
            {FinanceUtils.formatCurrency(total)}
          </div>
        )
      })}
    </div>
  )
}

const IRRTotals = (props) => {
  return (
    <div className="cash-flow">
      {props.totals.map((total, idx) => {
        return (
          <div key={idx} className={getTotalClass(idx)}>
            {FinanceUtils.formatIRR(total)}
          </div>
        )
      })}
    </div>
  )
}

const getTotalClass = (idx) => {
  switch (idx % 5) {
    case 0:
      return `cash-flow-forecast`
    case 1:
      return `cash-flow-expected`
    case 2:
      return `cash-flow-actual`
    case 3:
      return `cash-flow-latestBestEstimate`
    case 4:
      return `cash-flow-difference`

    default:
      return ""
  }
}

const SectionTotal = (props) => {
  const path = `${props.totalKey}`
  return (
    <div className="line inline">
      <div className="title" id={props.id}>
        {props.totalName}
      </div>

      <div className="line-body">
        <div>
          <div
            className="subtitle"
            onClick={() => {
              props.toggleProjects(path)
            }}
          >
            {t("table_headings.total")} <Icon icon={props.paths.has(path) ? "arrow_drop_up" : "arrow_drop_down"} />
          </div>
          <Totals totals={props.report[props.totalKey].totals} />
        </div>
        {props.paths.has(path) ? (
          <React.Fragment>
            <div>
              <div className="subtitle">{t("labels.average")}</div>
              <ComparisonAverages totals={props.report[props.totalKey].totals} projects={props.projects} />
            </div>
            {props.projects.map((project) => {
              return (
                <div key={project.code} className="inline project">
                  <div className="subtitle">{project.name}</div>
                  <Totals totals={props.report[props.totalKey].projects[project.name].totals} />
                </div>
              )
            })}
          </React.Fragment>
        ) : null}
      </div>
    </div>
  )
}

const IRRSectionTotal = (props) => {
  const path = `${props.totalKey}`
  return (
    <div className="line inline">
      <div className="title" id={props.id}>
        {props.totalName}
      </div>

      <div className="line-body">
        <div>
          <div
            className="subtitle"
            onClick={() => {
              props.toggleProjects(path)
            }}
          >
            {t("table_headings.total")} <Icon icon={props.paths.has(path) ? "arrow_drop_up" : "arrow_drop_down"} />
          </div>
          <IRRTotals totals={props.report[props.totalKey].totals} />
        </div>
        {props.paths.has(path) ? (
          <React.Fragment>
            {props.projects.map((project) => {
              return (
                <div key={project.code} className="inline project">
                  <div className="subtitle">{project.name}</div>
                  <IRRTotals totals={props.report[props.totalKey].projects[project.name].totals} />
                </div>
              )
            })}
          </React.Fragment>
        ) : null}
      </div>
    </div>
  )
}

const Category = (props) => {
  if (props.report.categories[props.categoryKey]) {
    const path = `categories[${props.categoryKey}]`
    const categoryTotals = props.report.categories[props.categoryKey].totals

    return (
      <React.Fragment>
        <div className="line category">
          <div className="title">{props.categoryName}</div>
          <div className="line-body">
            <div>
              <div
                className="subtitle"
                onClick={() => {
                  props.toggleProjects(path)
                }}
              >
                {t("table_headings.total")} <Icon icon={props.paths.has(path) ? "arrow_drop_up" : "arrow_drop_down"} />
              </div>
              <Totals totals={categoryTotals} />
            </div>

            {props.paths.has(path) ? (
              <React.Fragment>
                <div>
                  <div className="subtitle">{t("labels.average")}</div>
                  <ComparisonAverages totals={categoryTotals} projects={props.projects} />
                </div>
                {props.projects.map((project) => {
                  const projectTotals = props.report.categories[props.categoryKey].projects[project.name].totals
                  return (
                    <div key={project.code} className="project">
                      <div className="subtitle">{project.name}</div>
                      <Totals totals={projectTotals} />
                    </div>
                  )
                })}
              </React.Fragment>
            ) : null}
          </div>
        </div>

        {props.report.categories[props.categoryKey]
          ? Object.keys(props.report.categories[props.categoryKey].accounts).map((accountName) => {
              return (
                <Account
                  key={accountName}
                  categoryName={props.categoryName}
                  categoryKey={props.categoryKey}
                  accountName={accountName}
                  projects={props.projects}
                  report={props.report}
                  toggleProjects={props.toggleProjects}
                  paths={props.paths}
                />
              )
            })
          : null}
      </React.Fragment>
    )
  } else {
    return null
  }
}

const Account = (props) => {
  const path = `categories[${props.categoryKey}].accounts[${props.accountName}]`
  const account = props.report.categories[props.categoryKey].accounts[props.accountName]
  const accountTotals = account.totals

  return (
    <React.Fragment key={`${props.categoryKey}-${props.accountName}`}>
      <div className="line account">
        <div className="title">{props.accountName && props.accountName.trim() ? props.accountName : "???"}</div>

        <div className="line-body">
          {account.proceeds ? (
            <div>
              <div className="subtitle">{t("economics.labels.Loan Proceeds")}</div>
              <Totals totals={account.proceeds} />
            </div>
          ) : null}

          {account.payments ? (
            <div>
              <div className="subtitle">{t("economics.labels.Debt Service")}</div>
              <Totals totals={account.payments} />
            </div>
          ) : null}

          <div>
            <div
              className="subtitle"
              onClick={() => {
                props.toggleProjects(path)
              }}
            >
              <Icon icon={props.paths.has(path) ? "arrow_drop_up" : "arrow_drop_down"} />
            </div>
            <Totals totals={accountTotals} />
          </div>

          {props.paths.has(path) ? (
            <React.Fragment>
              <div>
                <div className="subtitle">{t("labels.average")}</div>
                <ComparisonAverages totals={accountTotals} projects={props.projects} />
              </div>
              {props.projects.map((project) => {
                const projectTotals = account.projects[project.name].totals
                return (
                  <React.Fragment key={project.code}>
                    <div key={project.code} className="project">
                      <div className="subtitle">{project.name}</div>
                      <Totals totals={projectTotals} />
                    </div>
                    {account.projects[project.name].proceeds ? (
                      <div>
                        <div className="subtitle">- {t("economics.labels.Loan Proceeds")}</div>
                        <Totals totals={account.projects[project.name].proceeds} />
                      </div>
                    ) : null}

                    {account.projects[project.name].payments ? (
                      <div>
                        <div className="subtitle">- {t("economics.labels.Debt Service")}</div>
                        <Totals totals={account.projects[project.name].payments} />
                      </div>
                    ) : null}
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          ) : null}
        </div>
      </div>

      {props.report.categories[props.categoryKey]
        ? Object.keys(account.subAccounts).map((subAccountName) => {
            const subAccount = account.subAccounts[subAccountName]
            return (
              <SubAccount
                key={subAccountName}
                categoryName={props.categoryName}
                categoryKey={props.categoryKey}
                accountName={props.accountName}
                subAccountName={subAccountName}
                subAccount={subAccount}
                projects={props.projects}
                report={props.report}
                toggleProjects={props.toggleProjects}
                paths={props.paths}
              />
            )
          })
        : null}
    </React.Fragment>
  )
}

const SubAccount = (props) => {
  const path = `categories[${props.categoryKey}].accounts[${props.accountName}].subAccounts[${props.subAccountName}]`
  const accountTotals = props.subAccount.totals
  const subAccountName = props.subAccountName && props.subAccountName.trim() ? props.subAccountName : "???"

  return (
    <React.Fragment key={`${props.categoryKey}-${props.accountName}-${props.subAccountName}`}>
      <div className="line sub-account">
        <div className="subaccount-title" title={subAccountName}>
          {subAccountName}
        </div>

        <div className="line-body">
          <div>
            <div
              className="subtitle"
              onClick={() => {
                props.toggleProjects(path)
              }}
            >
              {t("table_headings.total")}
              <Icon icon={props.paths.has(path) ? "arrow_drop_up" : "arrow_drop_down"} />
            </div>
            <Totals totals={accountTotals} />
          </div>

          {props.paths.has(path) ? (
            <React.Fragment>
              <div>
                <div className="subtitle">{t("labels.average")}</div>
                <ComparisonAverages totals={accountTotals} projects={props.projects} />
              </div>
              {props.projects.map((project) => {
                const projectTotals = props.subAccount.projects[project.name].totals
                return (
                  <div key={project.code} className="project">
                    <div className="subtitle">{project.name}</div>
                    <Totals totals={projectTotals} />
                  </div>
                )
              })}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}
