import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"
import { StoredProjectChecklistTemplate } from "@dashboard/process/views/checklists/ChecklistView"

/**
 * Import to checklist.
 * @param code - The code.
 */
export const importToChecklist = (code: string): Promise<StoredProjectChecklistTemplate[]> => {
  const uri = `/import/to-checklist/${code}`
  return API.put(uri)
    .then((response) => {
      const data = response as { checklists: StoredProjectChecklistTemplate[] }
      return data.checklists
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to put '${uri}': ${getErrorMessage(err)}` })
    })
}
