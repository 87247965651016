import React from "react"
import { Button, Row, Col, Input, InputGroup, Alert } from "reactstrap"

import EcosuiteView from "@common/module/EcosuiteView"

import "react-datetime/css/react-datetime.css"
import { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm from "@common/form/EcosuiteForm"
import { DualListBox } from "@common/input/select/DualListBox"
import UserAdminService from "@admin/users/UserAdminService"
import DRService from "../DRService"
import DRUtils from "../DRUtils"
import i18n from "src/i18n"

const { t } = i18n
class DRImportParticipantsView extends EcosuiteView {
  ArrayComponent(props) {
    return (
      <div className={props.className}>
        <fieldset>
          <legend id={props.idSchema.$id}>
            {props.title}
            {props.required ? <span className="required">*</span> : null}
          </legend>
          <DualListBox fields={["Code", "Name"]} options={props.uiSchema["ui:projects"]} onChange={(items) => props.onChange(items)} />
        </fieldset>
      </div>
    )
  }

  setInfoMessage(text) {
    this.setState({
      alert: {
        text: text,
        color: "info",
      },
    })
  }

  setSuccessMessage(text) {
    this.setState({
      alert: {
        text: text,
        color: "success",
      },
    })
  }

  setErrorMessage(text) {
    this.setState({
      alert: {
        text: text,
        color: "danger",
      },
    })
  }

  async loadUser(displayName) {
    this.setInfoMessage("Fetching user information for " + displayName + " ..")

    const users = await UserAdminService.getUsers()
    const user = users.users.find((v) => v.displayName === displayName)

    if (!user) {
      throw Error("Failed to fetch user " + displayName)
    }

    const data = await UserAdminService.getUser(user.id)

    if (!data) {
      throw Error("Failed to fetch data for user " + displayName)
    }

    this.setState({ displayName: displayName })
    return this.buildProjectOptions(await DRUtils.allowedProjects(this.props.projects, user))
  }

  buildUiSchema(projects) {
    return {
      projects: {
        "ui:projects": projects,
      },
      contact: {
        "ui:widget": (props) => {
          return (
            <InputGroup>
              <Input placeholder={`${t("demand.labels.user_email_address")}`} value={props.value} onChange={(event) => props.onChange(event.target.value)} />
              <Button
                color={"info"}
                onClick={async () => {
                  try {
                    const loaded = await this.loadUser(props.value)
                    this.setSuccessMessage(t("demand.labels.loaded_projects", { loaded: loaded }))
                  } catch (e) {
                    this.setErrorMessage(`${t("labels.failure")}: ` + e.toString())
                  }

                  props.onChange(props.value)
                }}
              >
                {t("labels.select_user")}
              </Button>
            </InputGroup>
          )
        },
      },
    }
  }

  constructor(props) {
    super(props)
    this.ArrayComponent = this.ArrayComponent.bind(this)
    this.importParticipants = this.importParticipants.bind(this)
    this.loadUser = this.loadUser.bind(this)

    this.state = {
      uiSchema: this.buildUiSchema([]),
      alert: {
        text: null,
        color: null,
      },
      formData: {},
    }
  }

  isReadOnly() {
    return false
  }

  componentDidMount() {
    super.componentDidMount()

    let formData = this.props.import
    this.setState({ formData: formData })
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (prevProps.projects !== this.props.projects && this.state.displayName) {
      this.loadUser(this.state.displayName)
    }
  }

  buildProjectOptions(projects) {
    // For each project code, get the matching name and build a DuaListBox
    // option for it
    const options = projects
      .filter((project) => {
        // If this choice has a matching participant, skip
        for (const p of this.props.participants) {
          if (p.code === project) {
            return false
          }
        }
        return true
      })
      .map((project) => {
        const name = this.props.projects.find((p) => p.code === project).name
        return {
          getID() {
            return project
          },
          getValues() {
            return [project, name]
          },
        }
      })

    // Sort by code
    options.sort((a, b) => a.getID().localeCompare(b.getID()))

    this.setState({
      uiSchema: this.buildUiSchema(options),
    })

    return options.length
  }

  getUiSchema() {
    return {}
  }

  importParticipants(form) {
    this.setState({ formData: form.formData })

    this.setInfoMessage(t("demand.messages.importing_participants", { numberOfParticipants: form.formData.projects.length }))

    DRService.createParticipant(form.formData.contact, form.formData.phoneContact, form.formData.projects)
      .then(() => {
        this.props.fetchParticipants()
        this.props.onCancel()
      })
      .catch((e) => this.setErrorMessage(`${t("demand.messages.failed_to_import_participants")}: ` + e.toString()))
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }

    const schema = {
      type: "object",
      required: ["contact", "phoneContact", "projects"],
      properties: {
        contact: {
          type: "string",
          title: "Contact User",
        },
        phoneContact: {
          type: "string",
          title: "Phone Number Contact",
        },
        projects: {
          type: "array",
          title: "Projects",
          items: {
            type: "string",
          },
        },
      },
    }

    return (
      <div className="item-details">
        <div className={"item-details-title"}>
          <h2>{t("demand.labels.import_participants")}</h2>
        </div>

        <div className="dr-event-details-content">
          {this.state.alert.text && <Alert color={this.state.alert.color}>{this.state.alert.text}</Alert>}

          <Row>
            <Col>
              <EcosuiteForm
                className="ecogy-form"
                schema={schema}
                fields={{ ArrayField: this.ArrayComponent }}
                formData={this.state.formData}
                uiSchema={this.state.uiSchema}
                onSubmit={(form) => this.importParticipants(form)}
                disabled={this.isReadOnly()}
              >
                <Row className="ecogy-form-buttons">
                  <React.Fragment>
                    <Col className="button-section" sm="12">
                      <Button color="primary" type="submit" disabled={this.isReadOnly()}>
                        {t("buttons.import")}
                      </Button>
                      <Button color="secondary" type="button" onClick={this.props.onCancel}>
                        {t("buttons.cancel")}
                      </Button>
                    </Col>
                  </React.Fragment>
                </Row>
              </EcosuiteForm>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default DRImportParticipantsView
