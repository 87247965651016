const energy = require("./module/energy/views.json")
const event = require("./module/event/views.json")
const economics = require("./module/economics/views.json")

const DashboardModules = {
  getModules() {
    return {
      event: event,
      energy: energy,
      finance: economics,
    }
  },
}

export default DashboardModules
