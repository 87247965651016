import React, { Component } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import { Loading } from "@common/EcosuiteComponent"

export default class GraphSelector extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false,
    }
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    // Default to the first graph if the selected one isn't available
    let selectedGraph = this.getSelectedGraph()
    return (
      <React.Fragment>
        <div className="content-graph-menu">
          {selectedGraph ? (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle caret>{selectedGraph.name}</DropdownToggle>
              <DropdownMenu>
                {Object.keys(this.props.graphs).map((graphId) => {
                  return (
                    <DropdownItem
                      className={graphId === this.props.selectedGraph ? "selected" : null}
                      key={graphId}
                      onClick={() => {
                        this.props.selectGraph(graphId)
                      }}
                    >
                      {this.props.graphs[graphId].name}
                    </DropdownItem>
                  )
                })}
              </DropdownMenu>
            </Dropdown>
          ) : null}
        </div>
        {selectedGraph ? <div className={selectedGraph.graph.type.name == "SourceGraph" ? "content-source-graph" : "content-graph"}>{selectedGraph.graph}</div> : <Loading />}
      </React.Fragment>
    )
  }
  getSelectedGraph() {
    if (this.props.graphs && this.props.selectedGraph) {
      return this.props.graphs[this.props.selectedGraph] ? this.props.graphs[this.props.selectedGraph] : Object.values(this.props.graphs)[0]
    }
  }
}
