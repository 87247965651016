import React, { useState } from "react"
import { Button, Input } from "reactstrap"
import moment from "moment"

import i18n from "src/i18n"
import type { FormData } from "./ParameterStore"
import type { ArrayFieldTemplateProps } from "@rjsf/core"
import Icon from "@common/display/Icon"
import { SelectField } from "@aws-amplify/ui-react"

const { t } = i18n
type formContext = {
  addToForm: (data: FormData) => void
  updateFormData: (data: FormData) => void
  validateData: (data: FormData) => Record<keyof FormData, string>
  putParameter: (param: FormData) => void
}

export const ParameterStoreArrayTemplate = ({
  // ...props,
  formData,
  items,
  formContext,
}: Omit<ArrayFieldTemplateProps<FormData[]>, "formContext"> & { formContext: formContext }): JSX.Element => {
  return (
    <>
      {items &&
        items.map((element, idx) => {
          return (
            <div key={element.key as string} className="Admin__parameter-store__param-container">
              <ParameterStoreWidget {...formData[idx]} actions={formContext} />
              <hr />
            </div>
          )
        })}
      <SelectField
        label={" "}
        onChange={(e) => {
          if (e.target.value === undefined || e.target.value === " ") { return }
          if(formData.find((datum) => datum.paramName === e.target.value)){return}
          const newParam = { path: "Settings/" + e.target.value, paramName: e.target.value, paramValue: " ", lastModified: new Date().toString() }
          formContext.addToForm(newParam)
        }}
      >
        <option value={" "}>
          Add new constant
        </option>
        <option value={"DEFAULT_DCAC_RATIO"}>
        DEFAULT_DCAC_RATIO
        </option>
        <option value={"DISCOUNT_RATE"}>
          DISCOUNT_RATE
        </option>
      </SelectField>
    </>
  )
}

const ParameterStoreWidget = ({ path, paramName, paramValue, lastModified, actions }: FormData & { actions: formContext }): JSX.Element => {
  const [changed, setChanged] = useState(false)
  const [formErrors, setFormErrors] = useState<Record<string, string> | undefined>(undefined)

  return (
    <>
      {/* Inputs */}
      <div className="Admin__parameter-store__param-fields">
        {/*   Param Name Field   */}
        <div className="Admin__parameter-store__param-field">
          <span>{t("settings.messages.param_name")}</span>
          <Input defaultValue={paramName || undefined} readOnly />
        </div>

        {/*   Param Value Field   */}
        <div className="Admin__parameter-store__param-field">
          <span>{t("table_headings.value")}</span>
          <Input
            defaultValue={paramValue || undefined}
            placeholder="Parameter Value"
            onChange={(e) => {
              if (formErrors?.paramValue) {
                setFormErrors((prev) => ({ ...prev, paramValue: "" }))
              }

              if (!changed) {
                setChanged(true)
              }

              actions.updateFormData({ path, paramName, paramValue: e.target.value || null, lastModified })
            }}
          />
          {formErrors?.paramValue ? <li>{formErrors.paramValue}</li> : null}
        </div>

        <div className="Admin__parameter-store__param-controls">
          <Button
            className="icon-button"
            color={changed ? "primary" : "success"}
            onClick={() => {
              const errors = actions.validateData({ path, paramName, paramValue })
              setFormErrors(errors)

              if (Object.keys(errors).length === 0) {
                setChanged(false)
                actions.putParameter({ path, paramName, paramValue, lastModified: new Date().toString() })
              }
            }}
          >
            <Icon icon="check_circle" />
          </Button>
          <span>{lastModified ? `${t("labels.last_updated")}: ${moment(lastModified).format("lll")}` : ""}</span>
        </div>
      </div>
    </>
  )
}
