import PortfolioService from "@admin/settings/portfolio/PortfolioService"

const DRUtils = {
  async allowedProjects(projects, user) {
    const allowed = new Set(projects.map((project) => project.code))

    if (user.attributes.access.restrictProjects === "no") {
      return [...allowed]
    } else if (user.attributes.access.restrictProjects === "yes") {
      return user.attributes.access.projects.filter((code) => allowed.has(code))
    } else {
      let retval = new Set()

      // For each portfilio, push to retval those which are present in allowed
      const relevantIds = new Set(user.attributes.access.portfolios)
      const portfolios = await PortfolioService.getPortfolios()
      const userPortfolios = portfolios.filter((portfolio) => relevantIds.has(portfolio.id))

      userPortfolios.forEach((portfolio) => {
        portfolio.projects.forEach((project) => {
          if (allowed.has(project)) {
            retval.add(project)
          }
        })
      })

      return [...retval]
    }
  },
}

export default DRUtils
