import { create, all } from "mathjs"

import Utils from "@common/utils/Utils"

const math = create(all)

const EconomicsUtils = {
  getFormattedTotalCost(consumptionCost) {
    const totalCost = this.getTotalCost(consumptionCost)
    if (totalCost) {
      return Utils.formatCurrency(totalCost)
    } else {
      return "-"
    }
  },

  getTotalCost(consumptionCost) {
    if (consumptionCost) {
      if (consumptionCost.projects) {
        // Assume the request is for all projects
        const totalCost = Object.values(consumptionCost.projects).reduce((total, projectCost) => {
          return projectCost.consumptionCost.totalCost ? math.add(total, projectCost.consumptionCost.totalCost) : total
        }, 0)
        return totalCost
      } else {
        // Assume the request for a single project
        return consumptionCost.consumptionCost.totalCost
      }
    } else {
      return NaN
    }
  },
}

export default EconomicsUtils
