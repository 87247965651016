import React from "react"

import EcosuiteComponent from "@common/EcosuiteComponent"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import ProFormaUtils from "./pro-forma-inputs/ProFormaUtils"
import ProFormaIRR from "./pro-forma-inputs/ProFormaIRR"
import Icon from "@common/display/Icon"

export default class ProFormaIRRTooltip extends EcosuiteComponent {
  renderContent() {
    const { project, proForma } = this.props
    return (
      <>
        <Icon icon={ProFormaUtils.isVersion(proForma) && !proForma.versionProject ? "warning" : "info"} id={this.props.id} />
        <UncontrolledTooltip target={this.props.id} placement="right">
          {ProFormaIRR.renderForecastGenerationInfo(project, proForma)}
        </UncontrolledTooltip>
      </>
    )
  }
}
