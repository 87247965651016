import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"

import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"

export default class PowerFactorBarGraph extends DateRangeGraph {
  renderContent() {
    if (this.props.range && this.props.graphDatums) {
      let data = Object.keys(this.props.graphDatums).map((dateKey) => {
        let datum = this.props.graphDatums[dateKey]
        let date = moment(dateKey)

        let data = {
          date: date.toDate(),
          time: date.toDate().getTime(),
          name: date.format("lll"),
        }

        Object.keys(datum.assetDatums).forEach((assetCode) => {
          data[assetCode] = datum.assetDatums[assetCode].powerFactor
          data[`${assetCode}-max`] = datum.assetDatums[assetCode].powerFactorMax
          data[`${assetCode}-min`] = datum.assetDatums[assetCode].powerFactorMin
        })

        return data
      })

      if (data.length < 1) {
        return <div className="content-graph-empty">{"Power Factor Not Recorded"}</div>
      }

      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"
      return (
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
            <XAxis
              dataKey="time"
              type="number"
              scale="time"
              domain={this.getDomain(this.props.range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis />
            <Legend iconType="plainline" />
            <Tooltip content={<GraphTooltip range={this.props.range} aggregation={this.props.aggregation} />} />

            {this.props.assets.map((asset, idx) => {
              return (
                <Line
                  key={`${idx}-max`}
                  type={graphType}
                  label={`${asset.code} (Max)`}
                  dataKey={`${asset.code}-max`}
                  strokeDasharray="4 4"
                  stroke={GRAPH_COLORS[idx % GRAPH_COLORS.length]}
                  dot={aggregation === "day"}
                />
              )
            })}
            {this.props.assets.map((asset, idx) => {
              return <Line key={idx} type={graphType} dataKey={asset.code} stroke={GRAPH_COLORS[idx % GRAPH_COLORS.length]} strokeWidth={2} dot={aggregation === "day"} />
            })}
            {this.props.assets.map((asset, idx) => {
              return (
                <Line
                  key={`${idx}-min`}
                  type={graphType}
                  label={`${asset.code} (Min)`}
                  dataKey={`${asset.code}-min`}
                  strokeDasharray="1 1"
                  stroke={GRAPH_COLORS[idx % GRAPH_COLORS.length]}
                  dot={aggregation === "day"}
                />
              )
            })}

            {this.props.referenceLine ? this.props.referenceLine : null}
            {this.renderUserAlerts()}
            {this.renderAssetEvents()}
          </LineChart>
        </ResponsiveContainer>
      )
    }

    return <Loading />
  }
}

class GraphTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {payload.map((data) => {
              return (
                <React.Fragment key={data.dataKey}>
                  <p className="label" style={{ color: data.stroke }}>
                    {`${data.name} : ${data.value ? data.value : 0}`}
                  </p>
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }
    return null
  }
}
