import API, { IRange as Range } from "@common/API"

const ConnectivityService = {
  getHealthDatums(range: Range, aggregation: string) {
    let url = API.addRangeToUrl("/energy/datums/status", range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    return API.get(url as string)
  },

  getMostRecentHealthDatums() {
    return API.get("/energy/datums/status/most-recent")
  },

  getNodeMetadata() {
    return API.get("/energy/node-metadata")
  }
}

export default ConnectivityService
