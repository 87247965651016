import moment from "moment-timezone"

const SEPARATOR = "/"

var EventUtils = {
  formatEventDate(projects, event, date, format) {
    let timezone = EventUtils.getTimeZone(projects, event.path)
    return moment(date).tz(timezone).format(format)
  },

  getTimeZone(projects, path) {
    let project, site
    if (projects && path) {
      let parts = path.split(SEPARATOR)
      if (parts.length > 1) {
        project = projects.find((project) => {
          return project.code === parts[1]
        })
      }
      if (parts.length > 2) {
        site = Object.values(project.sites).find((site) => {
          return site.code === parts[2]
        })
      }

      if (site && site.timezone) {
        return site.timezone
      }
      if (project && project.timezone) {
        return project.timezone
      }
    }

    return this.getTimeZoneForProject(project, site)
  },

  getTimeZoneForProject(project, site) {
    if (site && site.timezone) {
      return site.timezone
    }
    if (project && project.timezone) {
      return project.timezone
    }
    return "America/New_York" // default to New York, while timezone is now a required property it hasn't been configured on projects yet
  },

  isEventDue(event) {
    return !event.endDate && event.dueDate && moment(event.dueDate).isBefore(moment())
  },
}

export default EventUtils
