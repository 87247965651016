import React, { useState } from "react"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import ReportService from "@dashboard/data/project/ReportService"
import ProjectUtils from "@common/utils/ProjectUtils"
import { Alert, Button, ButtonDropdown, DropdownMenu, DropdownToggle, Input } from "reactstrap"
import Icon from "@common/display/Icon"
import { ListObjectsOutput } from "aws-sdk/clients/s3"
import { DateTime } from "luxon"
import { getErrorMessage } from "@common/SharedComponentUtils"
import { Error } from "@common/EcosuiteComponent"
import Datetime from "react-datetime"
import i18n from "src/i18n"

interface EconodeReportTableProps {
  project: Project
  site: Site
  system: System
}

/**
 * The Econode report table.
 * @param props - The props.
 * @constructor
 */

const { t } = i18n
export const EconodeReportTable = (props: EconodeReportTableProps) => {
  const { project, site, system } = props

  const dateFormat = "yyyy-MM"

  /**
   * The Econode report entry.
   */
  interface EconodeReportEntry {
    sourceId: string
    reports: string[]
  }

  /**
   * Extracts the Econode paths.
   */
  function extractEconodePaths(): string[] {
    const econodePaths: string[] = []

    if (system) {
      const validDevices = system?.devices?.filter((device: any) => {
        return device.type === "CON"
      })
      if (validDevices) {
        econodePaths.push(...validDevices.map((device: any) => ProjectUtils.getPath(project, site, system, device)))
      }
    }

    return econodePaths
  }

  const econodeSourceIds = extractEconodePaths()
  const [initializingData, setInitializingData] = useState<boolean>(true)
  const [currentError, setCurrentError] = useState<string>()

  const [econodeReports, setEconodeReports] = useState<EconodeReportEntry[]>([])

  // The date controls.
  const latestMonth = DateTime.now().minus({ months: 1 }).toFormat(dateFormat)
  const [targetMonth, setTargetMonth] = useState<string>(latestMonth)
  const [targetMonthDropdownOpen, setTargetMonthDropdownOpen] = useState<boolean>(false)

  useCurrentEffect(
    (isCurrent) => {
      init(isCurrent).then()

      // Load the latest reports every minute.
      const interval = setInterval(() => {
        init(isCurrent).then()
      }, 60 * 1000)

      return () => clearInterval(interval)
    },
    [system.code],
  )

  /**
   * Initializes the data.
   * @param isCurrent - Whether the component is current.
   */
  async function init(isCurrent: () => boolean) {
    setInitializingData(true)

    if (econodeSourceIds && econodeSourceIds.length > 0) {
      const econodeReports = await loadEconodeReports(econodeSourceIds)
      if (isCurrent()) setEconodeReports(econodeReports)
    }

    setInitializingData(false)
  }

  /**
   * Loads the Econode reports.
   * @param sourceIds - The source IDs.
   */
  async function loadEconodeReports(sourceIds: string[]): Promise<EconodeReportEntry[]> {
    return await Promise.all(
      sourceIds.map(async (sourceId) => {
        const reports: ListObjectsOutput = await ReportService.listEconodeReports(sourceId)
        const keys: string[] = []

        reports.Contents?.forEach((content) => {
          if (content.Key) keys.push(content.Key)
        })

        return {
          sourceId,
          reports: keys,
        }
      }),
    )
  }

  /**
   * Generates an Econode report.
   * @param sourceId - The source ID.
   * @param sendEmail - Whether to send an email.
   * @param targetMonth - The target month.
   */
  async function generateEconodeReport(sourceId: string, sendEmail: boolean, targetMonth: string) {
    setCurrentError(undefined)
    const confirmationMessage = sendEmail
      ? `${t("data.confirmation_msg.generate_report_and_email", { targetMonth: targetMonth })}`
      : `${t("data.confirmation_msg.generate_report", { targetMonth: targetMonth })}`

    if (window.confirm(confirmationMessage)) {
      await ReportService.generateEconodeReport(sourceId, sendEmail, targetMonth)

      // Reload the Econode reports.
      try {
        const econodeReports = await loadEconodeReports([sourceId])
        setEconodeReports(econodeReports)
      } catch (e) {
        setCurrentError(getErrorMessage(e))
      }
    }

    await ReportService.generateEconodeReport(sourceId, sendEmail, targetMonth)
  }

  /**
   * Check if all the data has been entered to generate the report (from the perspective of a source ID).
   * @param sourceId - The source ID.
   */
  function sourceIdDataIssues(sourceId: string) {
    const issues: string[] = []
    const projectCode = ProjectUtils.getProjectCode(sourceId)
    const siteCode = ProjectUtils.getSiteCode(sourceId)
    const systemCode = ProjectUtils.getSystemCode(sourceId)

    // Check the client details.
    if (!project.clientNumber) issues.push(`Client Number (Project '${projectCode}' Details)`)
    if (!site.utility) issues.push(`Utility (Site '${siteCode}' Details)`)
    if (!site.serviceId) issues.push(`Service ID (Site '${siteCode}' Details)`)
    if (!system.meterNumber) issues.push(`Client Name (System '${systemCode}' Details)`)
    if (!system.contractedDemand) issues.push(`Contracted Demand (System '${systemCode}' Details)`)

    return issues
  }

  /**
   * Gets the file name from the report path.
   * @param reportPath - The report path.
   */
  function getFileName(reportPath: string) {
    return reportPath.substring(reportPath.lastIndexOf("/") + 1)
  }

  return (
    <div className={"side-panel-content"}>
      <h4>{t("econode.headings.econode_reports")}</h4>
      <div className={"project-reports"}>
        {econodeSourceIds.length === 0 ? (
          t("alertsInfo.no_econode_associated")
        ) : initializingData && econodeReports.length === 0 ? (
          t("alertsInfo.no_reports_available")
        ) : (
          <>
            {econodeReports.map((entry, index) => {
              const sourceIDIssues = sourceIdDataIssues(entry.sourceId)

              // Render for each source ID.
              return (
                <>
                  <h5>{entry.sourceId}</h5>
                  {
                    // Check if all the data has been entered (from the source ID perspective).
                    sourceIDIssues.length > 0 && (
                      <Alert color={"warning"}>
                        <div>{t("econode.alerts.before_generating")}</div>
                        <ul>
                          {sourceIDIssues.map((issue, index) => (
                            <li key={index}>{issue}</li>
                          ))}
                        </ul>
                      </Alert>
                    )
                  }
                  {!initializingData && entry.reports.length === 0 ? (
                    <div>{t("alertsInfo.no_reports_available")}</div>
                  ) : (
                    entry.reports?.map((report) => {
                      const fileName = getFileName(report)

                      // Render the individual report.
                      return (
                        <div key={index} className={"report"}>
                          <div className={"document"}>
                            <Button title={`${t("labels.download_file", { fileName: fileName })}`} className={"download float-end"} onClick={() => ReportService.downloadFile(report)}>
                              <Icon icon={"cloud-download"} />
                            </Button>
                            {fileName}
                          </div>
                        </div>
                      )
                    })
                  )}
                  <p className={"my-3"}>{t("data.tooltips.auto_refresh", { seconds: 60 })}</p>

                  <Button onClick={() => generateEconodeReport(entry.sourceId, false, latestMonth)} disabled={sourceIDIssues.length > 0}>
                    {t("buttons.generate_latest_report")}
                  </Button>
                  <br />
                  <ButtonDropdown isOpen={targetMonthDropdownOpen} toggle={() => setTargetMonthDropdownOpen(!targetMonthDropdownOpen)} className={""}>
                    <DropdownToggle caret style={{ borderRadius: "0.25rem" }} disabled={sourceIDIssues.length > 0}>
                      {t("buttons.generate_custom_report")}
                    </DropdownToggle>
                    <DropdownMenu className={"date-range-menu"} end>
                      <div className={"custom-date-range"}>
                        <div className={"custom-date"}>
                          <Datetime
                            value={targetMonth}
                            timeFormat={false}
                            dateFormat="YYYY-MM"
                            open={true}
                            onChange={(date) => {
                              const parsedDate = typeof date === "string" ? DateTime.fromISO(date).toFormat(dateFormat) : date.format(dateFormat)
                              setTargetMonth(parsedDate)
                            }}
                            isValidDate={(date) => date.isSameOrBefore(targetMonth, "month")}
                            renderInput={(props) => <Input {...props} readOnly={true} disabled={true} />}
                          />
                        </div>
                        <div className="date-range-buttons">
                          <Button color="primary" className="date-range-button" onClick={() => generateEconodeReport(entry.sourceId, false, targetMonth)} disabled={sourceIDIssues.length > 0}>
                            {t("buttons.generate_report")}
                          </Button>
                          <Button color="primary" className="date-range-button" onClick={() => generateEconodeReport(entry.sourceId, true, targetMonth)} disabled={sourceIDIssues.length > 0}>
                            {t("buttons.generate_and_email_latest_report")}
                          </Button>
                        </div>
                      </div>
                    </DropdownMenu>
                  </ButtonDropdown>
                </>
              )
            })}
          </>
        )}

        {currentError ? <Error error={currentError} /> : null}
      </div>
    </div>
  )
}

export default EconodeReportTable
