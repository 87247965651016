import moment from "moment"
import TimezoneSelect from "@common/input/select/TimezoneSelect"
import * as React from "react"
import i18n from "src/i18n"
import { Button, Input } from "reactstrap"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Modal as BootstrapModal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import WithToolTip from "@common/display/ToolTip"

const { t } = i18n

const ProductionStartDateUpdateDialog = NiceModal.create(({ value, onChange }) => {
  const modal = useModal()

  const [date, setDate] = React.useState(value)

  return (
    <BootstrapModal
      centered={true}
      size={"md"}
      isOpen={modal.visible}
      toggle={modal.visible ? () => modal.hide() : undefined}
    >
      <ModalHeader>{t("data.dialogs.prod_start_date_dialog")}</ModalHeader>
      <ModalBody>
        <div>
          <div>{t("data.dialogs.prod_start_date_dialog")}</div>
          <br />
          <input type="date" onChange={(e) => setDate(e.target.value)} value={date} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            modal.hide()
            onChange(date)
          }}
        >
          {t("buttons.change")}
        </Button>
      </ModalFooter>
    </BootstrapModal>
  )
})

export default function getUiSchema(project) {
  const order = ["code", "status", "paused"]
  if (project && project.paused) {
    order.push("pausedDate")
  }
  order.push("cancelled")
  if (project && project.cancelled) {
    order.push("cancelledDate")
  }
  order.push("*")

  return {
    "ui:order": order,
    code: {
      "ui:disabled": this.props.project && this.props.project.code !== undefined,
      "ui:custom": (props) => {
        if (!props) return
        return (
          <div className="field-with-help">
            <Input
              value={props.formData}
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
            <a
              title="AMS New Project Guide"
              href="https://docs.ecosuite.io/user-guide/modules/data/project/new-project-guide"
              target="_blank"
              rel="noreferrer noopener"
            >
              <i className="material-icons">help</i>
            </a>
          </div>
        )
      },
    },
    address: { "ui:widget": "addressLookup" },
    systemStatus: {
      "ui:description": (
        <a href="https://docs.ecosuite.io/user-guide/modules/data/project/status/project-and-system-status-and-project-flag-filtering">
          {" "}
          {t("formDesc.learn_about_system_status")}{" "}
        </a>
      ),
    },
    productionStartDate: {
      "ui:widget": (props) => {
        const value = props.value
        const onChange = props.onChange
        return (
          <WithToolTip tooltip={t("data.tooltips.production_start_date")}>
            <Input
              style={{ width: "calc(100% - 80px)", display: "inline-block" }}
              type="date"
              value={props.value}
              onChange={(value) => {
                props.onChange(value)
              }}
            />
            <Button
              style={{ padding: "6px", marginBottom: "4px" }}
              onClick={() => NiceModal.show(ProductionStartDateUpdateDialog, { value, onChange })}
            >
              {t("buttons.change")}
            </Button>
          </WithToolTip>
        )
      },
    },
    companyName: {
      "ui:custom": (props) => {
        if (!props) return
        return (
          <WithToolTip tooltip={t("data.tooltips.company_name")}>
            <Input
              value={props.formData}
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          </WithToolTip>
        )
      },
    },
    name: {
      "ui:custom": (props) => {
        if (!props) return
        return (
          <WithToolTip tooltip={t("data.tooltips.given_project_name")}>
            <Input
              value={props.formData}
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          </WithToolTip>
        )
      },
    },
    clientNumber: {
      "ui:custom": (props) => {
        if (!props) return
        return (
          <WithToolTip tooltip={t("data.tooltips.client_number")}>
            <Input
              value={props.formData}
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          </WithToolTip>
        )
      },
    },
    timezone: {
      "ui:widget": (props) => {
        return (
          <TimezoneSelect
            value={props.value}
            onChange={(value) => {
              props.onChange(value)
            }}
            disabled={this.props.readonly}
          />
        )
      },
    },
    archived: {
      "ui:options": {
        label: false,
      },
      "ui:widget": () => {
        return null
      },
    },
    icons: {
      "ui:options": {
        label: false,
      },
      "ui:widget": () => {
        return null
      },
    },
    //setting this description manually because the use of ' and () in the api's description makes i18 get confused
    reportStartDate: {
      "ui:description": "report_start_date",
    },
    discountRate: {
      "ui:description":
        "Can be used to specify a project specific discount rate in calculations such as retained earnings. If not specified the system default (8%) will be used.",
    },
    links: {
      "ui:item-label": t("buttons.links"),
      items: {
        "ui:options": {
          label: false,
        },
        url: {
          "ui:linkself": true,
        },
      },
    },
    tags: {
      "ui:layout": {
        type: "list",
        inline: true,
        buttonsClassName: "field-array-field-buttons",
      },
      items: {
        "ui:options": {
          label: false,
        },
      },
    },
    notes: {
      "ui:item-label": t("buttons.notes"),
      "ui:collapse": {
        collapsed: true,
        renderTitle: (props, idx) => {
          const entry = props.items[idx].children.props.formData
          if (entry.note) {
            return <>{entry.note}</>
          } else {
            return null
          }
        },
        isNewItem(props, idx) {
          const entry = props.items[idx].children.props.formData
          return !entry.created
        },
      },
      items: {
        "ui:options": {
          label: false,
        },
        note: {
          "ui:widget": "textarea",
          "ui:options": {
            rows: 10,
          },
        },
        userName: {
          "ui:disabled": true,
        },
        created: {
          "ui:disabled": true,
        },
        comments: {
          "ui:item-label": t("labels.comment"),
          items: {
            "ui:options": {
              label: false,
            },
            comment: {
              "ui:widget": "textarea",
            },
            userName: {
              "ui:disabled": true,
            },
            created: {
              "ui:disabled": true,
            },
          },
        },
      },
    },
    slackChannel: {
      "ui:options": {
        label: false,
      },
      "ui:widget": () => {
        return null
      },
    },
    media: {
      "ui:options": {
        label: false,
      },
      banner: {
        "ui:value": this.props.project && this.props.project.media ? this.props.project.media.banner : undefined, // make sure we update when the project is changed
        "ui:field": "media",
      },
    },
    milestones: {
      "ui:options": {
        toolTip: this.props.orgUXSettings?.googleMilestonesEnabled
          ? `Changing milestone dates here is not recomended as your org(${this.props.orgUXSettings?.name}) is using Milestone sync via Google sheets for its milestone data`
          : undefined,
        label: false,
      },
      initiated: {
        "ui:custom": (props) => {
          return (
            <Input
              className={this.props.orgUXSettings?.googleMilestonesEnabled ? "disabled" : ""}
              value={props.formData}
              type="date"
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          )
        },
      },
      interconnection: {
        "ui:custom": (props) => {
          return (
            <Input
              className={this.props.orgUXSettings?.googleMilestonesEnabled ? "disabled" : ""}
              value={props.formData}
              type="date"
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          )
        },
      },
      permitting: {
        "ui:custom": (props) => {
          return (
            <Input
              className={this.props.orgUXSettings?.googleMilestonesEnabled ? "disabled" : ""}
              value={props.formData}
              type="date"
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          )
        },
      },
      preConstruction: {
        "ui:custom": (props) => {
          return (
            <Input
              className={this.props.orgUXSettings?.googleMilestonesEnabled ? "disabled" : ""}
              value={props.formData}
              type="date"
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          )
        },
      },
      construction: {
        "ui:custom": (props) => {
          return (
            <Input
              className={this.props.orgUXSettings?.googleMilestonesEnabled ? "disabled" : ""}
              value={props.formData}
              type="date"
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          )
        },
      },
      pto: {
        "ui:custom": (props) => {
          return (
            <Input
              className={this.props.orgUXSettings?.googleMilestonesEnabled ? "disabled" : ""}
              value={props.formData}
              type="date"
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          )
        },
      },
      operational: {
        "ui:custom": (props) => {
          return (
            <Input
              className={
                this.props.orgUXSettings?.googleMilestonesEnabled
                  ? "disabled"
                  : moment(project?.milestones?.operational).isAfter(moment())
                    ? "future-date"
                    : ""
              }
              value={props.formData}
              type="date"
              onChange={(e) => {
                props.onChange(e.target.value)
              }}
            />
          )
        },
        "warning:match": {
          contextKey: "__self__",
          property: "productionStartDate",
          valueFormatter: (v) => (v ? moment(v).format("YYYY-MM-DD") : "-"),
          setValueToComparedValue: true,
        },

        "ui:options": {
          toolTip: "data.tooltips.operational_date",
        },
      },
    },
    reports: {
      "ui:options": {
        label: false,
      },
      operating: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
        "ui:description": "Generate Operating Reports for this Project",
      },
      operatingContacts: {
        "ui:item-label": t("labels.contact"),
        items: {
          "ui:options": {
            label: false,
          },
          email: {
            // UI Options for email field
          },
          reportType: {
            // UI Options for report type field
          },
        },
      },
    },
  }
}
