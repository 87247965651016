import React, { useState } from "react"
import { Button, ButtonGroup, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, ListGroup } from "reactstrap"
import { DateTime } from "luxon"
import { getAllRFIReplies } from "@dashboard/process/views/rfi/RFIService"
import { Loading } from "@common/EcosuiteComponent"
import RFIReplyTile from "@dashboard/process/views/rfi/RFIReplyTile"
import MediaCarousel from "@common/display/MediaCarousel"
import "./RFIDetails.scss"
import { RFIEditorValue } from "@dashboard/process/views/rfi/RFIEditor"
import RFIResolutionTile from "@dashboard/process/views/rfi/RFIResolutionTile"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import i18n from "src/i18n"

/**
 * The RFI details page.
 * @param rfi - The selected RFI.
 * @param author - The RFI author.
 * @param getUser - The function for getting a user from state by ID.
 * @param currentUser - The current user.
 * @param setEditing - Set the editing object.
 * @param onDeleteRFIReply - The callback for deleting an RFI reply.
 * @constructor
 */
const { t } = i18n
export const RFIDetails = ({
  rfi,
  author,
  getUser,
  currentUser,
  setEditing,
  onDeleteRFIReply,
}: {
  rfi: RFIStored
  author: User
  getUser: (id: string) => User | undefined
  currentUser: User
  setEditing: (object: RFIStored | RFIReplyStored, value: RFIEditorValue) => void
  onDeleteRFIReply: (id: string) => void
}): JSX.Element => {
  // All RFI replies.
  const [allRFIReplies, setAllRFIReplies] = useState<RFIReplyStored[]>()

  // Whether the page is currently loading data.
  const [loadingData, setLoadingData] = useState<boolean>(true)

  // Whether to show the image modal.
  const [showImageModal, setShowImageModal] = useState<boolean>(false)

  // The media URI to display.
  const [mediaKeyToDisplay, setMediaKeyToDisplay] = useState<string[]>([])

  useCurrentEffect(
    (isCurrent) => {
      initializeData(isCurrent)
    },
    [rfi.id, rfi.lastUpdatedDateTime],
  )

  /**
   * Get all RFI replies.
   */
  const initializeData = async (isCurrent: () => boolean) => {
    setLoadingData(true)

    const rfiReplies = await getAllRFIReplies(rfi.id)

    if (isCurrent()) {
      setAllRFIReplies(rfiReplies)
      setLoadingData(false)
    }
  }

  /**
   * Build the RFI activity.
   * @param rfiReplies - The RFI replies.
   */
  const buildActivity = (rfiReplies: RFIReplyStored[]) => {
    const timeline: Map<string, JSX.Element> = new Map()
    rfiReplies.forEach((rfiReply) =>
      timeline.set(
        rfiReply.createdDateTime,
        <RFIReplyTile
          key={rfiReply.id}
          rfi={rfi}
          rfiReply={rfiReply}
          author={getUser(rfiReply.author)}
          currentUser={currentUser}
          showMediaKeys={(keys) => {
            setShowImageModal(true)
            setMediaKeyToDisplay(keys)
          }}
          setEditing={setEditing}
          onDeleteRFIReply={onDeleteRFIReply}
        />,
      ),
    )
    if (rfi.resolutionHistory) {
      for (const timeKey of Object.keys(rfi.resolutionHistory)) {
        timeline.set(timeKey, <RFIResolutionTile resolutionDateTime={timeKey} author={getUser(rfi.resolutionHistory[timeKey].author)} resolved={rfi.resolutionHistory[timeKey].resolved} />)
      }
    }

    const sortedKeys = Array.from(timeline.keys()).sort()
    return (
      <>
        <ListGroup flush={true}>
          {sortedKeys.length > 0 && <hr />}
          {sortedKeys.map((key) => timeline.get(key))}
        </ListGroup>
      </>
    )
  }

  const isPastDue = DateTime.fromISO(rfi.dueDateTime).diffNow().milliseconds < 0

  return (
    <>
      <MediaCarousel mediaKeys={mediaKeyToDisplay} showModal={showImageModal} setShowModal={setShowImageModal} />
      <Card className={"rfi-details"} outline={true} color={rfi.resolved ? "success" : isPastDue ? "danger" : "primary"}>
        <CardHeader className={rfi.resolved ? "rfi-details-header-resolved" : isPastDue ? "rfi-details-header-past-due" : "rfi-details-header"}>
          <Col>
            <CardTitle tag={"h5"}>{`RFI ${rfi.number}`}</CardTitle>
            <CardSubtitle className={"mb-2"} tag={"h6"}>
              {`${t("process.labels.created_by_author", {
                createdDateTime: DateTime.fromISO(rfi.createdDateTime).toLocaleString(),
                authorName: author ? `${author.attributes.firstName} ${author.attributes.lastName}` : `${t("labels.unknown")}`,
              })}`}
            </CardSubtitle>
          </Col>
        </CardHeader>
        <CardBody>
          <Col>
            <h2>{rfi.name}</h2>
            <p className={"subtitle"}>{t("labels.assigned")}</p>
            {rfi.assigned && rfi.assigned.length > 0 ? <p>{rfi.assigned.join()}</p> : <p className={"placeholder-text"}>{t("process.messages.rfi_not_assigned_to_domains")}</p>}
            <p className={"subtitle"}>{t("table_headings.description")}</p>
            {rfi.description && rfi.description != "" ? <p className={"display-linebreak"}>{rfi.description}</p> : <p className={"placeholder-text"}>{t("process.messages.no_desc_provided")}</p>}
            <p className={"subtitle"}>{t("table_headings.due_date")}</p>
            <p>{DateTime.fromISO(rfi.dueDateTime).toLocaleString()}</p>
            {rfi.costImpacts && rfi.costImpacts !== "" && <p className={"subtitle"}>{t("labels.cost_impacts")}</p>}
            <p>{rfi.costImpacts}</p>
            {rfi.scheduleImpacts && rfi.scheduleImpacts !== "" && <p className={"subtitle"}>{t("labels.schedule_impacts")}</p>}
            <p>{rfi.scheduleImpacts}</p>
            <ButtonGroup>
              {rfi.media.length > 0 && (
                <Button
                  onClick={() => {
                    setShowImageModal(true)
                    setMediaKeyToDisplay(rfi.media)
                  }}
                >
                  {t("process.labels.view_attatchments")}
                </Button>
              )}
              <Button color={"primary"} disabled={rfi.resolved} onClick={() => setEditing.call(this, rfi, RFIEditorValue.RFI)}>
                {t("buttons.cancel")}
              </Button>
            </ButtonGroup>
            {loadingData ? <Loading /> : <>{allRFIReplies && allRFIReplies?.length > 0 && buildActivity(allRFIReplies)}</>}
          </Col>
        </CardBody>
      </Card>
    </>
  )
}

export default RFIDetails
