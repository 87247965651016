import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"

/**
 * Import from Coda.
 * @param projectId - The project ID.
 * @param applyChanges - Whether to apply changes.
 */
export const importCoda = (projectId: string, applyChanges = false): Record<string, unknown> | Promise<Record<string, unknown>> => {
  return API.put<string, unknown>(`/coda/${projectId}/import`, { applyChanges: applyChanges })
    .then((data) => {
      return JSON.parse(data)
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to import coda: ${getErrorMessage(err)}` })
    })
}

export const importProjectMilestones = (): Promise<any> => {
  return API.put(`/coda/milestones?isFromAms=true`)
    .then((data) => {
      return data
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to import project milestones: ${getErrorMessage(err)}` })
    })
}
