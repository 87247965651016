import React from "react"
import moment from "moment"
import { Button, ButtonToolbar, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import Settings from "@common/Settings"
import Logger from "@common/Logger"

import PortfolioIRRReport from "./report/PortfolioIRRReport"
import ReportViewControls from "./report/ReportViewControls"
import ReportService from "../FinanceReportService"
import i18n from "src/i18n"

export const COLUMNS = ["forecast", "expected", "actual", "latestBestEstimate", "difference"]
export const COLUMN_NAMES = ["Forecast", "Anticipated", "Actual", "LBE", "Difference"]

export const PROJECT_LIMIT = 50
const { t } = i18n

const FLAGS = {
  backend: "Back-End Cash Flows",
  debt: "Forecasted Perm Debt",
}

class ReportView extends EcosuiteView {
  constructor(props) {
    super(props, "finance-multi-report")

    this.state.loading = false
    this.state.flags = Settings.getSetting("irrReportFlags", [])
    this.state.aggregate = Settings.getSetting("irrReportAggregate", "year")
    this.state.normaliseDates = Settings.getSetting("irrReportDateMode", "true") === "true"
    this.state.downloadAggregate = this.state.aggregate
    this.state.columns = Settings.getSetting("irrReportColumns", COLUMNS).filter((col) => {
      // If columnToggles is undefined default to displaying all columns
      return (this.props.restrictions?.columnToggles ?? COLUMNS).includes(col)
    })
    this.state.showAccounts = Settings.getSetting("showAccounts", "true") === "true"
    this.state.showSubAccounts = Settings.getSetting("showSubAccounts", "true") === "true"
    this.state.showModal = false
    this.state.includeProjectBreakdown = false
    this.state.includeSubAccounts = false

    this.selectAggregate = this.selectAggregate.bind(this)
    this.toggleFlag = this.toggleFlag.bind(this)
    this.toggleDateMode = this.toggleDateMode.bind(this)
    this.toggleColumn = this.toggleColumn.bind(this)
    this.toggleAccounts = this.toggleAccounts.bind(this)
    this.toggleSubAccounts = this.toggleSubAccounts.bind(this)
    this.toggleDownloadModal = this.toggleDownloadModal.bind(this)
    this.load = this.load.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.projects.map((project) => project.code)) !== JSON.stringify(prevProps.projects.map((project) => project.code))) {
      this.resetReport()
    }
  }

  /**
   * Clears the report so that it can be loaded again. Note we don't just reload the report as this can be a tu=ime consuming option that blocks the UI for large data sets
   */
  resetReport() {
    this.setStateIfMounted({ report: undefined, error: undefined, loading: false })
  }

  load() {
    this.loadIRRReport()
  }

  loadIRRReport() {
    const start = moment()
    this.setStateIfMounted({ report: undefined, error: undefined, loading: true }, () => {
      const projectIds = this.props.projects.map((project) => project.code)
      const aggregate = this.state.aggregate
      const normaliseDates = this.state.normaliseDates
      const flags = this.state.flags
      Logger.debug(`loadIRRReport for projects: ${projectIds} & aggregate: ${this.state.aggregate}`)

      if (projectIds.length && projectIds.length <= PROJECT_LIMIT) {
        // SERVER side aggregation
        ReportService.getFinanceReports(projectIds, aggregate, normaliseDates ? "normalised" : "actual", this.state.flags)
          .then((response) => {
            Logger.debug(`loadIRRReport took: ${moment().diff(start, "milliseconds")} for projects: ${projectIds} & aggregate: ${response.aggregate}`)

            if (this.areProjectsCurrent(projectIds) && aggregate == this.state.aggregate && normaliseDates === this.state.normaliseDates && flags === this.state.flags) {
              this.setStateIfMounted({ report: response.report, aggregate: response.aggregate, error: null, loading: false })
            } else {
              this.setStateIfMounted({ loading: false })
            }
          })
          .catch((err) => {
            this.setStateIfMounted({ report: new EcosuiteComponentError(err), loading: false })
          })
      }
    })
  }

  selectAggregate(aggregate) {
    this.setStateIfMounted({ aggregate: aggregate, downloadAggregate: aggregate }, () => {
      Settings.setSetting("irrReportAggregate", aggregate)
    })
    this.resetReport()
  }

  toggleFlag(event) {
    const flags = this.state.flags.filter((flag) => flag !== event.target.value) // Remove the flag from the selections
    if (event.target.checked) {
      flags.push(event.target.value) // Add the flag to the selections
    }

    this.setState({ flags: flags }, () => {
      Settings.setSetting("irrReportFlags", flags)
      this.resetReport()
    })
  }

  toggleDateMode() {
    const normaliseDates = !this.state.normaliseDates
    this.setStateIfMounted({ normaliseDates: normaliseDates }, () => {
      Settings.setSetting("irrReportDateMode", normaliseDates.toString())
    })
    this.resetReport()
  }

  toggleColumn(column) {
    let columns = this.state.columns
    if (columns.indexOf(column) >= 0) {
      columns = columns.filter((docType) => {
        return docType !== column
      })
    } else {
      columns.push(column)
    }

    this.setStateIfMounted({ columns: columns }, () => {
      Settings.setSetting("irrReportColumns", columns)
    })
  }

  toggleAccounts() {
    const showAccounts = !this.state.showAccounts
    this.setStateIfMounted({ showAccounts: showAccounts }, () => {
      Settings.setSetting("showAccounts", showAccounts.toString())
    })
  }

  toggleSubAccounts() {
    const showSubAccounts = !this.state.showSubAccounts
    this.setStateIfMounted({ showSubAccounts: showSubAccounts }, () => {
      Settings.setSetting("showSubAccounts", showSubAccounts.toString())
    })
  }

  toggleDownloadModal() {
    this.setStateIfMounted({ showModal: !this.state.showModal })
  }

  renderViewControls() {
    return (
      <ReportViewControls
        projects={this.props.projects}
        aggregate={this.state.aggregate}
        normaliseDates={this.state.normaliseDates}
        columns={this.state.columns}
        showAccounts={this.state.showAccounts}
        showSubAccounts={this.state.showSubAccounts}
        flags={FLAGS}
        selectedFlags={this.state.flags}
        actions={{
          selectAggregate: this.selectAggregate,
          toggleFlag: this.toggleFlag,
          toggleDateMode: this.toggleDateMode,
          toggleColumn: this.toggleColumn,
          toggleAccounts: this.toggleAccounts,
          toggleSubAccounts: this.toggleSubAccounts,
        }}
        columnToggles={this.props.restrictions?.columnToggles ?? COLUMNS}
        toolbar={
          <ButtonToolbar className="float-end">
            <Button color="primary" size="sm" onClick={this.toggleDownloadModal}>
              {t("buttons.download")}
            </Button>
            {this.renderDownloadModal()}
          </ButtonToolbar>
        }
      />
    )
  }

  renderDownloadModal() {
    return (
      <Modal isOpen={this.state.showModal} toggle={this.toggleDownloadModal}>
        <ModalHeader toggle={this.toggleDownloadModal}>{t("economics.labels.Export Report")}</ModalHeader>
        <ModalBody>
          <p>{t("economics.messages.export_irr_explained", { projectsLength: this.props.projects.length })}</p>
          <FormGroup>
            <Label>{t("economics.labels.Aggregate by")}:</Label>
            <Input
              type="select"
              value={this.state.downloadAggregate}
              onChange={(e) => {
                e.stopPropagation()
                this.setStateIfMounted({ downloadAggregate: e.target.value })
              }}
            >
              <option value="month">{t("labels.month")}</option>
              <option value="quarter">{t("labels.quarter")}</option>
              <option value="year">{t("labels.year")}</option>
              <option value="total">{t("labels.total")}</option>
            </Input>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.includeSubAccounts}
                onChange={(e) => {
                  e.stopPropagation()
                  this.setStateIfMounted({ includeSubAccounts: !this.state.includeSubAccounts })
                }}
              />{" "}
              {t("economics.labels.Include Sub Accounts?")}
            </Label>
            <br />
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.includeProjectBreakdown}
                onChange={(e) => {
                  e.stopPropagation()
                  this.setStateIfMounted({ includeProjectBreakdown: !this.state.includeProjectBreakdown })
                }}
              />{" "}
              {t("economics.labels.Include project breakdown?")}
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              ReportService.exportReport(
                this.props.projects.map((project) => project.code),
                this.state.downloadAggregate,
                this.state.columns,
                this.state.includeProjectBreakdown,
                this.state.normaliseDates ? "normalised" : "actual",
                this.state.includeSubAccounts,
                this.state.flags,
              )
                .then(() => {
                  this.toggleDownloadModal()
                })
                .catch((err) => {
                  alert(t("economics.messages.unable_toInitiate_report"))
                  Logger.error(err)
                })
            }}
          >
            {t("buttons.export")}
          </Button>{" "}
          <Button color="secondary" onClick={this.toggleDownloadModal}>
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderMainView() {
    if (this.state.loading) {
      return <Loading message={`${t("loadingMsg.loading_report_for_projects", { projectsLength: this.props.projects.length })}`} />
    } else if (this.state.report) {
      if (this.isContentError(this.state.report)) {
        return (
          <>
            <Error error={this.state.report.getError()} />
            <div className="load-view">
              <Button onClick={this.load}></Button>
            </div>
          </>
        )
      } else {
        return (
          <div className="finance-project">
            <PortfolioIRRReport
              projects={this.props.projects}
              report={this.state.report}
              aggregate={this.state.aggregate}
              columns={this.state.columns}
              showAccounts={this.state.showAccounts}
              showSubAccounts={this.state.showSubAccounts}
            />
          </div>
        )
      }
    } else {
      return (
        <div className="load-view">
          <Button onClick={this.load}>{t("buttons.view_report")}</Button>
        </div>
      )
    }
  }
}

export default ReportView
