/* eslint-disable no-undef */
import React from "react"
import EcosuiteComponent from "@common/EcosuiteComponent"
import { ProjectMapRoot } from "./ProjectMapRoot"
import { Marker, InfoWindow } from "@react-google-maps/api"
import { t } from "i18next"

class ProjectMarker extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  close = () => {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    let project = this.props.project
    return (
      <Marker
        position={{ lat: project.lat, lng: project.long }}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 12,
          fillColor: this.getColor(),
          fillOpacity: 1,
          strokeWeight: 0.4,
          primaryColor: "#FFF",
        }}
        onClick={this.toggleOpen}
        label={{ text: project.code, color: "#FFF" }}
        title={project.name}
        zIndex={2}
      >
        {this.state.isOpen && (
          <InfoWindow onCloseClick={this.close} options={{ closeBoxURL: ``, enableEventPropagation: true }}>
            <div style={{ color: "#000" }}>{this.renderProjectInfo()}</div>
          </InfoWindow>
        )}
      </Marker>
    )
  }

  getColor() {
    return "#0EB6DB"
  }

  renderProjectInfo() {
    return <div>{`${t("labels.project")} ${this.props.project.name}`}</div>
  }
}

class ProjectRadiusMarker extends EcosuiteComponent {
  render() {
    return (
      <Marker
        position={{ lat: this.props.project.lat, lng: this.props.project.long }}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: this.calculateRadius(),
          fillColor: this.getColor(),
          fillOpacity: 0.5,
          strokeWeight: 0.4,
        }}
        zIndex={1}
      />
    )
  }

  getColor() {
    return "#0EB6DB"
  }

  calculateRadius() {
    return 0 // by default we show nothing
  }
}

export { ProjectMarker, ProjectRadiusMarker }
export default ProjectMapRoot
