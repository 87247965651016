import React from "react"

import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import EconodeEconomicsUtils from "../../EconodeEconomicsUtils"

export default class Consumption extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.consumptionCost)) {
      return <Error error={this.props.consumptionCost.getError()} />
    }
    if (this.isContentValid(this.props.consumptionCost)) {
      return <div className="sidebar-heading">{EconodeEconomicsUtils.getFormattedTotalCost(this.props.consumptionCost)}</div>
    } else {
      return <Loading />
    }
  }
}
