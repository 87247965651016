import DashboardModules from "./dashboard/ModuleConfig.js"
import EconodeModules from "./econode/ModuleConfig.js"

var jsprim = require("jsprim")

const AppConfig = {
  getConfig() {
    return {
      ams: {
        modules: DashboardModules.getModules(),
      },
      econode: {
        modules: EconodeModules.getModules(),
      },
    }
  },

  checkGroups(requiredGroups, userGroups) {
    var allowed = true
    if (requiredGroups && requiredGroups.length) {
      requiredGroups.forEach((group) => {
        if (!userGroups.includes(group)) {
          allowed = false // The user doesn't have access to a required group
        }
      })
    }
    return allowed
  },

  getConfigForApplication(userType, application, userGroups) {
    const applicationConfig = jsprim.deepCopy(this.getConfig()[application])

    if (userType.modules) {
      // Filter the modules based on the users permissions
      Object.keys(applicationConfig.modules).forEach((moduleId) => {
        const module = applicationConfig.modules[moduleId]

        // Add viewable recordTypes to data moduleView object
        if (moduleId === "data" && userType.modules.ams) {
          module.recordTypes = userType.modules.ams?.data?.recordTypes
        }

        if (!this.checkGroups(module.groups, userGroups)) {
          delete applicationConfig.modules[moduleId]
        }
      })

      // We filter out the views that the user doesn't have access to
      Object.keys(applicationConfig.modules).forEach((moduleId) => {
        applicationConfig.modules[moduleId].portfolio.views = applicationConfig.modules[moduleId].portfolio.views.filter((view) => {
          return (
            userType.modules[application] &&
            userType.modules[application][moduleId] &&
            userType.modules[application][moduleId].portfolioViews &&
            userType.modules[application][moduleId].portfolioViews.includes(view.id) &&
            this.checkGroups(view.groups, userGroups)
          )
        })
        applicationConfig.modules[moduleId].project.views = applicationConfig.modules[moduleId].project.views.filter((view) => {
          return (
            userType.modules[application] &&
            userType.modules[application][moduleId] &&
            userType.modules[application][moduleId].projectViews &&
            userType.modules[application][moduleId].projectViews.includes(view.id) &&
            this.checkGroups(view.groups, userGroups)
          )
        })
      })
    }

    return applicationConfig
  },
}

export default AppConfig
