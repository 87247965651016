import React from "react"
import ExcelJS from "exceljs"
import { Button } from "reactstrap"

interface Data {
  advisedAmount: number
  advisedReadig: number
  end: string
  expectedAmount: number
  id: string
  reading: number
  start: string
}

const exportToExcel = async (dataArray: Array<Data>, name: string) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet("My Sheet")

  worksheet.columns = [
    { header: "ID", key: "id" },
    { header: "Start", key: "start" },
    { header: "End", key: "end" },
    { header: "Actual Reading", key: "reading" },
    { header: "Anticipated Amount", key: "expectedAmount" },
    { header: "Advised Reading", key: "advisedReading" },
    { header: "Advised Amount", key: "advisedAmount" },
    { header: "Actual Amount", key: "actualAmount" },
  ]

  worksheet.addRows(dataArray)

  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  const link = document.createElement("a")
  link.href = window.URL.createObjectURL(blob)
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const sanitizeSheetName = (name: string) => {
  return name.replace(/[*?:\\/[\]]/g, "-")
}

const exportToExcelFull = async (data: Record<string, Array<Data>>, downloadName: string) => {
  const workbook = new ExcelJS.Workbook()
  for (const [name, dataArray] of Object.entries(data)) {
    const worksheet = workbook.addWorksheet(sanitizeSheetName(name))
    worksheet.columns = [
      { header: "ID", key: "id" },
      { header: "Start", key: "start" },
      { header: "End", key: "end" },
      { header: "Actual Reading", key: "reading" },
      { header: "Anticipated Amount", key: "expectedAmount" },
      { header: "Advised Reading", key: "advisedReading" },
      { header: "Advised Amount", key: "advisedAmount" },
      { header: "Actual Amount", key: "actualAmount" },
    ]
    worksheet.addRows(dataArray)
  }

  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  const link = document.createElement("a")
  link.href = window.URL.createObjectURL(blob)
  link.download = downloadName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const ExportCashFlowPaymentLogs = ({ data, name }: { data: Array<Data>; name: string }) => (
  <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
    <Button onClick={() => exportToExcel(data, name)} style={{ marginBottom: "8px", marginRight: "16px" }}>
      Export to Excel
    </Button>
  </div>
)

export const ExportCashFlowPaymentLogsFull = ({ data, name }: { data: Record<string, Array<Data>>; name: string }) => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <Button onClick={() => exportToExcelFull(data, name)} style={{ marginTop: "8px", marginBottom: "8px", marginRight: "16px" }}>
        Export All Accounts to Excel
      </Button>
    </div>
  )
}

export default ExportCashFlowPaymentLogs
