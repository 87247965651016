import React from "react"
import i18n from "src/i18n"

const { t } = i18n

const ProjectsTableHeaders = ({ showGeneration, showConsumption, showStorage, sortState, setSortState }) => {
  const { colname, order } = sortState

  const getSortIndicator = (columnId) => {
    return colname === columnId ? (order === "asc" ? " 🔼" : " 🔽") : ""
  }

  const handleSortChange = (columnId) => {
    const newOrder = sortState.colname === columnId && sortState.order === "asc" ? "desc" : "asc"
    setSortState({ colname: columnId, order: newOrder })
  }

  return (
    <thead>
      <tr>
        <th id="col-code" onClick={() => handleSortChange("col-code")}>
          {t("table_headings.code")}
          {getSortIndicator("col-code")}
        </th>
        <th id="col-name" onClick={() => handleSortChange("col-name")}>
          {t("table_headings.name")}
          {getSortIndicator("col-name")}
        </th>

        {showGeneration && !showConsumption ? (
          <>
            <th id="col-dc-size" onClick={() => handleSortChange("col-dc-size")}>
              {t("table_headings.dc_size")}
              {getSortIndicator("col-dc-size")}
            </th>
            <th id="col-ac-size" onClick={() => handleSortChange("col-ac-size")}>
              {t("table_headings.ac_size")}
              {getSortIndicator("col-ac-size")}
            </th>
          </>
        ) : null}

        {showConsumption && !showGeneration ? (
          <th id="col-peak-power" onClick={() => handleSortChange("col-peak-power")}>
            {t("table_headings.expected_peak")}
            {getSortIndicator("col-peak-power")}
          </th>
        ) : null}

        {showStorage ? (
          <th id="col-storage" onClick={() => handleSortChange("col-storage")}>
            {`${t("labels.storage")} (kWh)`}
            {getSortIndicator("col-storage")}
          </th>
        ) : null}

        {showConsumption ? (
          <>
            <th id="col-consumption" onClick={() => handleSortChange("col-consumption")}>
              {`${t("labels.consumption")} (kWh)`}
              {getSortIndicator("col-consumption")}
            </th>
            <th id="col-consumption-predicted" onClick={() => handleSortChange("col-consumption-predicted")}>
              {`${t("labels.forecast")} (kWh)`}
              {getSortIndicator("col-consumption-predicted")}
            </th>
            <th id="col-consumption-predicted-percent" onClick={() => handleSortChange("col-consumption-predicted-percent")}>
              %{getSortIndicator("col-consumption-predicted-percent")}
            </th>
            {!showGeneration ? (
              <>
                <th id="col-peak-consumption" onClick={() => handleSortChange("col-peak-consumption")}>
                  {`${t("labels.peak")} (kW)`}
                  {getSortIndicator("col-peak-consumption")}
                </th>
                <th id="col-peak-consumption-percent" onClick={() => handleSortChange("col-peak-consumption-percent")}>
                  %{getSortIndicator("col-peak-consumption-percent")}
                </th>
                <th id="col-consumption-cost" onClick={() => handleSortChange("col-consumption-cost")}>
                  {t("labels.cost")}
                  {getSortIndicator("col-consumption-cost")}
                </th>
              </>
            ) : null}
          </>
        ) : null}

        {showGeneration ? (
          <>
            <th id="col-generation" onClick={() => handleSortChange("col-generation")}>
              {`${t("labels.generation")} (kWh)`}
              {getSortIndicator("col-generation")}
            </th>
            <th id="col-generation-expected" onClick={() => handleSortChange("col-generation-expected")}>
              {`${t("labels.expected")} (kWh)`}
              {getSortIndicator("col-generation-expected")}
            </th>
            <th id="col-generation-expected-percent" onClick={() => handleSortChange("col-generation-expected-percent")}>
              %{getSortIndicator("col-generation-expected-percent")}
            </th>
            <th id="col-generation-predicted" onClick={() => handleSortChange("col-generation-predicted")}>
              {`${t("labels.forecast")} (kWh)`}
              {getSortIndicator("col-generation-predicted")}
            </th>
            <th id="col-generation-predicted-percent" onClick={() => handleSortChange("col-generation-predicted-percent")}>
              %{getSortIndicator("col-generation-predicted-percent")}
            </th>
            <th id="specific-yield" onClick={() => handleSortChange("specific-yield")}>
              kWh/kWp
              {getSortIndicator("specific-yield")}
            </th>
            <th id="col-generation-normalised" onClick={() => handleSortChange("col-generation-normalised")}>
              {`${t("energy.agn")}`}
              {getSortIndicator("col-generation-normalised")}
            </th>
            {!showConsumption ? (
              <>
                <th id="col-generation-peak" onClick={() => handleSortChange("col-generation-peak")}>
                  {`${t("labels.peak")} (kW)`}
                  {getSortIndicator("col-generation-peak")}
                </th>
                <th id="col-generation-peak-percent" onClick={() => handleSortChange("col-generation-peak-percent")}>
                  %{getSortIndicator("col-generation-peak-percent")}
                </th>
              </>
            ) : null}
          </>
        ) : null}
      </tr>
    </thead>
  )
}

export default ProjectsTableHeaders
