import * as React from "react"
import { forwardRef, useState } from "react"
import { EyeIcon, EyeOffIcon } from "lucide-react"
import { Button } from "src/components/ui/button"
import { Input, InputProps } from "src/components/ui/input"
import { cn } from "src/lib"

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false)
  const disabled = props.value === "" || props.value === undefined || props.disabled

  return (
    <div className="tw-relative">
      <Input
        type={showPassword ? "text" : "password"}
        className={cn("tw-hide-password-toggle tw-pr-10", className)}
        ref={ref}
        {...props}
      />
      <Button
        type="button"
        variant="ghost"
        size="sm"
        className="tw-absolute tw-right-0 tw-top-0 tw-h-full tw-px-3 tw-py-2 hover:tw-bg-transparent"
        onClick={() => setShowPassword((prev) => !prev)}
        disabled={disabled}
      >
        {showPassword && !disabled ? (
          <EyeIcon className="tw-h-4 tw-w-4" aria-hidden="true" />
        ) : (
          <EyeOffIcon className="tw-h-4 tw-w-4" aria-hidden="true" />
        )}
        <span className="tw-sr-only">{showPassword ? "Hide password" : "Show password"}</span>
      </Button>
      {/* hides browsers password toggles */}
      <style>{`
        .tw-hide-password-toggle::-ms-reveal,
        .tw-hide-password-toggle::-ms-clear {
          visibility: hidden;
          pointer-events: none;
          display: none;
        }
      `}</style>
    </div>
  )
})

PasswordInput.displayName = "PasswordInput"

export { PasswordInput }
