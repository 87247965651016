import React from "react"
import logo from "./img/ecosuite_logo.png"
import "./About.css"

import EcosuiteComponent from "@common/EcosuiteComponent"

export default class About extends EcosuiteComponent {
  constructor(props) {
    super(props)
  }

  renderContent() {
    return (
      <div className="popout">
        <div className="popoutWindow">
          <div className="popoutHeader">
            <button
              onClick={() => {
                this.props.close()
              }}
            >
              X
            </button>
          </div>
          <div className="popoutBody">
            <img src={logo} alt="logo" style={{ marginRight: 20, width: 400 }} />

            <h3>Version {this.props.version}</h3>
            <p>Copyright © 2024. All Rights Reserved.</p>
            <p>
              Ecosuite is an ecosystem for the management of diverse enterprise scale DER portfolios that leverages
              award winning open-source foundations and AI technology. Combined with industry wide collaboration tools,
              it massively increases deployment and operational efficiencies.
            </p>
            <div className="carousel-block">
              <div className="carousel-container">
                <p className="text-carousel">
                  Ecosuite Software Developers: Elijah, Thomas, Amogh, Max, Sandy, Matt, David, Damo, Dmitriy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCard(name) {
    return <p className="text-carousel">{name}</p>
  }
}
