import React, { useState } from "react"
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Row } from "reactstrap"
import SearchGroup, { fuzzySearchStringList } from "@common/input/search/SearchGroup"
import LoadingModal from "@common/display/LoadingModal"
import { resolveRFI } from "@dashboard/process/views/rfi/RFIService"
import i18n from "src/i18n"

/**
 * The RFI view controls.
 * @param allRFI - All available RFI.
 * @param shownRFI - The shown RFI.
 * @param selectedRFI - Set selected RFI.
 * @param setSelectedRFI - Set the selected RFI.
 * @param setShownRFI - Set the shown RFI.
 * @param showResolved - Whether to show resolved RFI.
 * @param setShowResolved - Set whether to show resolved RFI.
 * @param onCreateNewRFI - Callback for indicating the user wishes to create an RFI.
 * @param onCreateNewRFIReply - Callback for indicating the user wishes to create an RFI reply.
 * @param onResolveRFI - Callback for resolving the selected RFI.
 * @param onDeleteRFI - Callback for deleting RFI.
 * @constructor
 */
const { t } = i18n
export const RFIViewControls = ({
  allRFI,
  shownRFI,
  selectedRFI,
  setSelectedRFI,
  setShownRFI,
  showResolved,
  setShowResolved,
  onCreateNewRFI,
  onCreateNewRFIReply,
  onResolveRFI,
  onDeleteRFI,
}: {
  allRFI: RFIStored[]
  shownRFI: RFIStored[]
  selectedRFI: RFIStored | undefined
  setSelectedRFI: (rfi: RFIStored) => void
  setShownRFI: (rfi: RFIStored[]) => void
  showResolved: boolean
  setShowResolved: (show: boolean) => void
  onCreateNewRFI: undefined | (() => void)
  onCreateNewRFIReply: undefined | (() => void)
  onResolveRFI: (id: string) => void
  onDeleteRFI: undefined | ((id: string) => void)
}): JSX.Element => {
  // Whether the editor is currently submitting something.
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [currentSearchValue, setCurrentSearchValue] = useState<string>("")

  const resolveCurrentRFI = async () => {
    setIsSubmitting(true)
    await resolveRFI(selectedRFI.id, !selectedRFI.resolved)
    setIsSubmitting(false)
  }

  return (
    <>
      {isSubmitting && <LoadingModal show={isSubmitting} exit={undefined} />}
      <div className={"content-view-controls"}>
        <Row>
          <Col md={3}>
            <SearchGroup
              searchTerm={currentSearchValue}
              searchTermResults={shownRFI}
              onSearch={(event) => {
                const value = event.target.value
                setCurrentSearchValue(value)
                if (value === "" || undefined) {
                  setCurrentSearchValue("")
                  setShownRFI.call(this, allRFI)
                } else {
                  const nameResult = fuzzySearchStringList(
                    allRFI.map((rfi) => rfi.name),
                    currentSearchValue,
                  )

                  const newSet = new Set()
                  nameResult.forEach((name) =>
                    allRFI.forEach((rfi) => {
                      if (rfi.name === name.item) {
                        newSet.add(rfi)
                      }
                    }),
                  )

                  setShownRFI.call(this, Array.from(newSet))
                }
              }}
              onEnter={(result) => {
                if (result) {
                  setSelectedRFI.call(this, result)
                }
              }}
              onClear={() => {
                setCurrentSearchValue("")
                setShownRFI.call(this, allRFI)
              }}
            />
          </Col>
          <Col>
            <FormGroup switch={true}>
              <Input style={{marginTop: "7px"}} type={"switch"} onChange={() => setShowResolved(!showResolved)} checked={showResolved} />
              <Label check>{t("process.labels.show_resolved")}</Label>
            </FormGroup>
          </Col>
          <Col>
            <div className={"float-end"}>
              {onCreateNewRFI && (
                <Button size="sm" color={"primary"} onClick={() => onCreateNewRFI.call(this)}>
                  {t("process.labels.create_rfi")}
                </Button>
              )}
              <ButtonGroup>
                {onDeleteRFI && (
                  <Button size="sm" color={"danger"} onClick={() => onDeleteRFI?.call(this, selectedRFI.id)}>
                    {t("buttons.delete")}
                  </Button>
                )}
                {onCreateNewRFIReply && (
                  <Button size="sm" color={"primary"} disabled={selectedRFI.resolved} onClick={() => onCreateNewRFIReply.call(this)}>
                    {t("process.labels.create_reply")}
                  </Button>
                )}
                {selectedRFI && (
                  <Button
                    size="sm"
                    color={selectedRFI.resolved ? undefined : "success"}
                    onClick={async () => {
                      await resolveCurrentRFI()
                      onResolveRFI.call(this, selectedRFI.id)
                    }}
                  >
                    {selectedRFI.resolved ? `${t("process.labels.open_rfi")}` : `${t("process.labels.resolve_rfi")}`}
                  </Button>
                )}
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default RFIViewControls
