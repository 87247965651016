import * as React from "react"
import moment from "moment"
import { useGetProformaRates } from "src/services/proforma"

const calculateDates = (startDate, frequency, numberOfRates) => {
  let dates = []
  let currentDate = moment(startDate)

  for (let i = 0; i < numberOfRates; i++) {
    if (frequency === "quarterly") {
      dates.push(currentDate.format("YYYY-MM-DD"))
      currentDate.add(3, "months")
    } else if (frequency === "monthly") {
      dates.push(currentDate.format("YYYY-MM-DD"))
      currentDate.add(1, "months")
    } else if (frequency === "annually") {
      dates.push(currentDate.format("YYYY-MM-DD"))
      currentDate.add(1, "years")
    }
  }

  return dates
}

const TableRow = ({ rates, account, payment, name }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true)
  const dates = calculateDates(payment.recurrence.startDate, payment.recurrence.frequency, rates.length)

  return (
    <React.Fragment>
      <tr style={{ backgroundColor: "#f2f2f2" }} onClick={() => setIsCollapsed(!isCollapsed)}>
        <td style={{ color: "black" }}>
          {account} {name} {isCollapsed ? "+" : "-"}
        </td>
        <td></td>
        <td></td>
      </tr>
      {!isCollapsed &&
        rates.map((rate, index) => (
          <tr key={index} style={{ display: isCollapsed ? "none" : "" }}>
            <td></td>
            <td>{dates[index]}</td>
            <td style={{ textAlign: "right", padding: "8px" }}>${rate.toFixed(4)}</td>
          </tr>
        ))}
    </React.Fragment>
  )
}

const RatesTable = ({ projectCode }) => {
  const { data, isLoading } = useGetProformaRates(projectCode)

  return (
    <div style={{ maxHeight: "calc(100vh - 220px)", overflowY: "auto" }}>
      <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ddd" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "left", padding: "8px" }}>Account</th>
            <th style={{ textAlign: "left", padding: "8px" }}>Date</th>
            <th style={{ textAlign: "right", padding: "8px" }}>Rates $</th>
          </tr>
        </thead>
        <tbody>
          {(data?.rates || []).map((data, index) => (
            <TableRow key={index} account={data.cashFlow.account} rates={data.rates} payment={data.payment} name={data.cashFlow.name}/>
          ))}
        </tbody>
      </table>
      {isLoading ? <div style={{ textAlign: "center" }}>Loading Data ...</div> : null}
      {!isLoading && data?.rates.length === 0 && <div style={{ textAlign: "center" }}>No data for this project!</div>}
    </div>
  )
}

export default function Rates({ projectCode }) {
  return (
    <div>
      <h1>Rates Table</h1>
      <RatesTable projectCode={projectCode} />
    </div>
  )
}
