import API from "@common/API"
import Logger from "@common/Logger"

var NoteService = {
  addNote(project, note) {
    Logger.debug(`Adding note: "${note}" to project: ${project.code}`)
    if (project.notes) {
      project.notes.push({ note: note })
    } else {
      project.notes = [{ note: note }]
    }
    return API.put(`/projects/${project.code}?note=${encodeURIComponent("Added new note")}`, project)
  },

  editNote(project, noteIdx, text) {
    Logger.debug(`Editing note: "${noteIdx}" of project: ${project.code}`)
    project.notes[noteIdx].note = text
    return API.put(`/projects/${project.code}?note=${encodeURIComponent("Edited note")}`, project)
  },

  resolveNote(project, noteIdx) {
    Logger.debug(`Resolving note: ["${noteIdx}"] on project: ${project.code}`)
    project.notes[noteIdx].resolved = true
    return API.put(`/projects/${project.code}?note=${encodeURIComponent(`Resolved note: ["${noteIdx}"]`)}`, project)
  },

  addCommentToNote(project, noteIdx, comment) {
    Logger.debug(`Adding comment to note: "${noteIdx}" on project: ${project.code}`)
    const note = project.notes[noteIdx]
    if (note.comments) {
      note.comments.push({ comment: comment })
    } else {
      note.comments = [{ comment: comment }]
    }
    return API.put(`/projects/${project.code}?note=${encodeURIComponent("Added new note comment")}`, project)
  },

  editNoteComment(project, noteIdx, commentIdx, text) {
    Logger.debug(`Editing comment: ${commentIdx} of note: "${noteIdx}" on project: ${project.code}`)
    project.notes[noteIdx].comments[commentIdx].comment = text
    return API.put(`/projects/${project.code}?note=${encodeURIComponent("Edited note comment")}`, project)
  },
}

export default NoteService
