import React from "react"
import { Button, ButtonGroup, ButtonToolbar } from "reactstrap"

import Logger from "@common/Logger"
import EcosuiteView from "@common/module/EcosuiteView"
import Settings from "@common/Settings"
import ReportsService from "../FinanceReportService"
import FinanceService from "../FinanceService"

import IRRReport from "./report/IRRReport"
import ReportDownload from "./report/ReportDownload"
import ReportViewControls from "./report/ReportViewControls"

import { COLUMNS } from "./ReportView"
import Icon from "@common/display/Icon"
import ProFormaService from "@dashboard/data/pro-forma/ProFormaService"
import i18n from "src/i18n"
import { EcosuiteComponentError } from "@common/EcosuiteComponent"
import logoRound from "src/img/logo.png"

const FLAGS = {
  backend: "Back-End Cash Flows",
  debt: "Forecasted Perm Debt",
}
const { t } = i18n

class ReportView extends EcosuiteView {
  constructor(props) {
    super(props, "finance-report")

    this.state.flags = Settings.getSetting("irrReportFlags", [])
    this.state.aggregate = Settings.getSetting("irrReportAggregate", "month")
    this.state.columns = Settings.getSetting("irrReportColumns", COLUMNS).filter((col) => {
      // If columnToggles is undefined default to displaying all columns
      return (this.props.restrictions?.columnToggles ?? COLUMNS).includes(col)
    })
    this.state.showAccounts = Settings.getSetting("showAccounts", "true") === "true"
    this.state.showSubAccounts = Settings.getSetting("showSubAccounts", "false") === "true"
    this.state.proFormaVersion = ""

    this.toggleFlag = this.toggleFlag.bind(this)
    this.selectAggregate = this.selectAggregate.bind(this)
    this.selectProFormaVersion = this.selectProFormaVersion.bind(this)
    this.toggleColumn = this.toggleColumn.bind(this)
    this.toggleAccounts = this.toggleAccounts.bind(this)
    this.toggleSubAccounts = this.toggleSubAccounts.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.load()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.project !== prevProps.project || this.props.proFormaVersion !== prevProps.proFormaVersion) {
      this.load()
    }
  }

  load() {
    const projectId = this.props.project.code
    ProFormaService.getProjectProForma(projectId).then((proForma) => {
      if (this.isProjectCurrent(projectId)) {
        this.setStateIfMounted({ proForma: proForma })
      } else {
        Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
      }
    })
    ReportsService.getProFormaVersions(projectId).then((versions) => {
      if (this.isProjectCurrent(projectId)) {
        this.setStateIfMounted({ proFormaVersions: versions })
      } else {
        Logger.debug(`Ignoring out of date response for project: ${projectId}`)
      }
    })
    this.loadCategories()
    this.loadIRRReport()
  }

  loadIRRReport() {
    this.setStateIfMounted({ report: undefined })

    const projectId = this.props.project.code
    const proFormaVersion = this.props.proFormaVersion
    const flags = this.state.flags

    ReportsService.getFinanceReport(projectId, this.props.proFormaVersion, this.state.flags)
      .then((response) => {
        if (
          this.isProjectCurrent(projectId) &&
          proFormaVersion === this.props.proFormaVersion &&
          flags === this.state.flags
        ) {
          this.setStateIfMounted({ timestamp: response.timestamp, report: response.report })
        } else {
          Logger.warning(
            `Ingoring report for project ${projectId} as ${this.props.project.code} is the current project`,
          )
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ report: new EcosuiteComponentError(err) })
        }
      })
  }

  loadCategories() {
    this.setStateIfMounted({ categories: null })
    if (this.props.project) {
      const projectId = this.props.project.code
      FinanceService.getIrrCategories(projectId).then((response) => {
        if (this.isProjectCurrent(projectId)) {
          const categories = {}
          Object.values(response.categories).forEach((category) => {
            categories[category.name] = {
              name: category.name,
              categories: Object.values(category.categories).map((account) => {
                return {
                  name: account.name,
                  categories: account.categories
                    ? Object.values(account.categories).map((subAccount) => {
                        return {
                          name: subAccount.name,
                        }
                      })
                    : [],
                }
              }),
            }
          })

          delete categories["Equity"]
          delete categories["Liability"]

          this.setStateIfMounted({ categories: categories })
        } else {
          Logger.debug(`Ignoring out of date response for project: ${projectId}`)
        }
      })
    }
  }

  toggleFlag(event) {
    const flags = this.state.flags.filter((flag) => flag !== event.target.value) // Remove the flag from the selections
    if (event.target.checked) {
      flags.push(event.target.value) // Add the flag to the selections
    }

    this.setState({ flags: flags }, () => {
      Settings.setSetting("irrReportFlags", flags)
      this.loadIRRReport()
    })
  }

  selectAggregate(aggregate) {
    this.setState({ aggregate: aggregate })
    Settings.setSetting("irrReportAggregate", aggregate)
  }

  selectProFormaVersion(version) {
    Logger.debug("Selecting Pro Forma version: " + version)
    this.props.actions.setProFormaVersion(version, () => {
      this.loadIRRReport()
    })
  }

  toggleColumn(column) {
    let columns = this.state.columns
    if (columns.indexOf(column) >= 0) {
      columns = columns.filter((docType) => {
        return docType !== column
      })
    } else {
      columns.push(column)
    }

    Settings.setSetting("irrReportColumns", columns)
    this.setState({ columns: columns })
  }

  toggleAccounts() {
    const showAccounts = !this.state.showAccounts
    Settings.setSetting("showAccounts", showAccounts.toString())
    this.setStateIfMounted({ showAccounts: showAccounts })
  }

  toggleSubAccounts() {
    const showSubAccounts = !this.state.showSubAccounts
    Settings.setSetting("showSubAccounts", showSubAccounts.toString())
    this.setStateIfMounted({ showSubAccounts: showSubAccounts })
  }

  renderViewControls() {
    return (
      <ReportViewControls
        timestamp={this.state.timestamp}
        report={this.state.report}
        project={this.props.project}
        aggregate={this.state.aggregate}
        columns={this.state.columns}
        showAccounts={this.state.showAccounts}
        showSubAccounts={this.state.showSubAccounts}
        proForma={this.state.proForma}
        proFormaVersions={this.state.proFormaVersions}
        proFormaVersion={this.props.proFormaVersion}
        flags={FLAGS}
        selectedFlags={this.state.flags}
        actions={{
          toggleFlag: this.toggleFlag,
          selectAggregate: this.selectAggregate,
          toggleColumn: this.toggleColumn,
          toggleAccounts: this.toggleAccounts,
          toggleSubAccounts: this.toggleSubAccounts,
          selectProFormaVersion: this.selectProFormaVersion,
        }}
        columnToggles={this.props.restrictions?.columnToggles ?? COLUMNS}
        toolbar={
          <ButtonToolbar className="float-end">
            {this.state.report ? (
              <ButtonGroup>
                {this.state.reportRefreshing ? (
                  <div className="loading-inline">
                    <p className="loading-message">Updating report...</p>
                    <img src={logoRound} className="App-logo" alt="logo" />
                  </div>
                ) : null}
                <Button
                  size="sm"
                  title={`${t("labels.request_update")}`}
                  onClick={() => {
                    const sendReport = confirm(
                      t("economics.messages.sendReport_confirm_msg", { projectName: this.props.project.name }),
                    )
                    if (sendReport) {
                      this.setStateIfMounted({
                        reportRefreshing: true,
                      })
                      ReportsService.updateFinanceReport(this.props.project.code)
                        .then(() => {
                          Logger.info(`Report update for project: ${this.props.project.name} is ready`)
                          const reload = confirm(
                            t("economics.messages.updateReport_confirm_msg", { projectName: this.props.project.name }),
                          )
                          if (reload) {
                            this.loadIRRReport()
                          }
                          this.setStateIfMounted({
                            reportRefreshing: false,
                          })
                        })
                        .catch(async (err) => {
                          Logger.error(`Report update for project: ${this.props.project.name} failed`)
                          Logger.error(err)

                          //If update doesent complete before Node.js times out the request, wait 60 seconds before asking the user if they want to refresh
                          await new Promise((resolve) => {
                            setTimeout(() => {
                              resolve("resolved")
                            }, 60000)
                          })
                          const reload = confirm(
                            t("economics.messages.updateReport_confirm_msg", { projectName: this.props.project.name }),
                          )
                          if (reload) {
                            this.loadIRRReport()
                          }
                          this.setStateIfMounted({
                            reportRefreshing: false,
                          })
                        })
                    }
                  }}
                >
                  <Icon icon="update" />
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    ReportDownload.downloadReport(
                      this.props.project,
                      null,
                      null,
                      this.state.report,
                      null,
                      this.state.categories,
                      this.state.flags,
                      this.state.columns,
                      this.state.aggregate,
                      {
                        showSubAccounts: this.state.showSubAccounts,
                      },
                    )
                  }}
                  disabled={!this.state.categories}
                >
                  {t("buttons.download")}
                </Button>
              </ButtonGroup>
            ) : null}
          </ButtonToolbar>
        }
      />
    )
  }

  renderMainView() {
    return (
      <div className="finance-project">
        <IRRReport
          project={this.props.project}
          report={this.state.report}
          aggregate={this.state.aggregate}
          columns={this.state.columns}
          showAccounts={this.state.showAccounts}
          showSubAccounts={this.state.showSubAccounts}
          proFormaVersion={this.props.proFormaVersion}
          projectDiscountRate={this.props.projectDiscountRate}
        />
      </div>
    )
  }
}

export default ReportView
