import React from "react"

import moment from "moment-timezone"

import { Loading } from "@common/EcosuiteComponent"
import Settings from "@common/Settings"
import Logger from "@common/Logger"
import ProjectUtils from "@common/utils/ProjectUtils"
import Aggregations from "@common/Aggregations"

import GraphSelector from "@dashboard/GraphSelector"
import EnergyUtils from "../EnergyUtils"
import EnergyService from "../EnergyService"
import EnergyView from "./EnergyView"
import SourceGraph from "./source/SourceGraph"
import SourceCumulativeGraph from "./source/SourceCumulativeGraph"
import i18n from "src/i18n"

const { t } = i18n
export default class EnergyProjectSourceGraphsView extends EnergyView {
  constructor(props) {
    super(props, "energy-project-source-graphs")

    this.state.sourceGraphView = Settings.getSetting("sourceGraphView", "kW")

    this.loadSourceDatums = this.loadSourceDatums.bind(this)
    this.selectGraph = this.selectGraph.bind(this)

    this.loadSourceDatums(this.props.range)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.range.isSame(prevProps.range) || this.props.project !== prevProps.project) {
      Logger.debug(`Range updated from ${prevProps.range} to ${this.props.range}, loading source datums`)
      this.loadSourceDatums(this.props.range)
    }
  }

  loadSourceDatums(range) {
    let aggregate = range.end.diff(range.start, "days") < 2 && range.end.diff(range.start, "hours") > 1 ? Aggregations.FifteenMinute : null
    Logger.debug(`Loading source datums for range: ${range.start.format("lll")} ${range.end.format("lll")} with aggregate: ${aggregate}`)

    this.setStateIfMounted({ datums: null, range: null, aggregation: null })
    const projectId = this.props.project.code
    EnergyService.getNodesDatums(range, aggregate, projectId, null, null, null).then((response) => {
      if (this.isRangeCurrent(range) && this.isProjectCurrent(projectId)) {
        this.setStateIfMounted({ datums: response.data, range: response.range, aggregation: response.aggregation })
      } else {
        Logger.debug(`Ignoring out of date response`)
      }
    })
  }

  selectGraph(graph) {
    Settings.setSetting("sourceGraphView", graph)
    this.setStateIfMounted({ sourceGraphView: graph })
  }

  getSources(unit, systemId) {
    // Logger.debug(`Loading sources for unit: ${unit} and system: ${systemId}`)
    let datums = this.state.datums.filter((datum) => {
      let systemFilter = systemId ? datum.sourceId.startsWith(systemId) : true
      let genFilter = EnergyUtils.isGeneratingSource(datum.sourceId) && this.props.showGeneration
      let conFilter = EnergyUtils.isConsumingSource(datum.sourceId) && this.props.showConsumption
      return systemFilter && (genFilter || conFilter)
    })

    let sources = {}
    let date = moment(this.state.range.localStartDate)
    let end = moment(this.state.range.localEndDate)
    while (date.isBefore(end)) {
      let dateKey = date.format("YYYY-MM-DDTHH:mm")
      sources[dateKey] = {
        time: moment(dateKey).valueOf(),
      }
      date.add(Aggregations.getSize(this.state.aggregation), Aggregations.getUnits(this.state.aggregation))
    }

    let sourceIds = new Set()
    datums.forEach((datum) => {
      sourceIds.add(datum.sourceId) // keep track of any sourceIds we come across
      let dateKey = `${datum.localDate}T${datum.localTime}`
      if (sources[dateKey]) {
        sources[dateKey][datum.sourceId] = datum[unit]
      } else {
        Logger.warning(`Ignoring unexpected local date in results: ${dateKey} for source: ${datum.sourceId}`)
      }
    })

    // Logger.debug(`Loaded data for sources: ${Array.from(sourceIds)}`)

    return {
      sourceIds: Array.from(sourceIds),
      data: Object.values(sources),
    }
  }

  renderMainView() {
    return (
      <div>
        <h2 className="content-title">{t("energy.cumulative_source_comparison")}</h2>
        <SourceCumulativeGraph project={this.props.project} />

        <h2 className="content-title">{t("headings.sources")}</h2>
        {this.renderSourceDatums()}
      </div>
    )
  }

  renderSourceDatums() {
    if (this.state.datums) {
      let systemIds = ProjectUtils.getSystemCodesForProject(this.props.project)
      return (
        <div>
          {systemIds.map((systemId) => {
            let graphs = {
              kW: {
                graph: (
                  <SourceGraph
                    {...this.props}
                    range={this.state.range}
                    aggregation={this.state.aggregation}
                    sources={this.getSources("watts", systemId)}
                    selectRange={this.props.selectRange}
                    unit="watts"
                  />
                ),
                name: `${systemId} kW`,
              },
              kWH: {
                graph: (
                  <SourceGraph
                    {...this.props}
                    range={this.state.range}
                    aggregation={this.state.aggregation}
                    sources={this.getSources("wattHours", systemId)}
                    selectRange={this.props.selectRange}
                    unit="wattHours"
                  />
                ),
                name: `${systemId} kWh`,
              },
            }

            return (
              <GraphSelector key={systemId} selectedGraph={this.state.sourceGraphView} selectGraph={this.selectGraph} graphs={graphs} range={this.props.range} selectRange={this.props.selectRange} />
            )
          })}
        </div>
      )
    } else {
      return <Loading message={`${t("loadingMsg.loading_datums")}`} />
    }
  }
}
