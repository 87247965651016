import React from "react"
import { ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import EcosuiteComponent from "@common/EcosuiteComponent"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
const { t } = i18n
/**
 * The TypeFilter renders controls to select elements of a type hierarchy. The structure looks like:
 * [
 *  {
 *    id: <type ID>,
 *    name: <type name>,
 *    subTypes: [
 *      {
 *        id: <sub type ID>,
 *        name: <sub type name>
 *      },
 *      ...
 *    ]
 *  },
 * ...
 * ]
 */
export default class TypeFilter extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      openTypes: {},
      hasSubTypes: false,
      subTypeId: null,
      selectedSubtypes: [],
    }

    this.toggleFilter = this.toggleFilter.bind(this)
  }

  toggleFilter(type) {
    let openTypes = this.state.openTypes
    openTypes[type] = !openTypes[type]
    this.setStateIfMounted({ openTypes: openTypes })
  }

  toggleSubType(subt) {
    const selectedSubtypes = this.state?.selectedSubtypes || []
    if (selectedSubtypes.length) {
      if (!selectedSubtypes.includes(subt)) {
        this.setState({ selectedSubtypes: [...selectedSubtypes, subt] })
      } else {
        this.setState({ selectedSubtypes: selectedSubtypes.filter((selected) => selected !== subt) })
      }
    }
  }

  showSubType(subTypeId) {
    this.setState({ subTypeId: subTypeId })
  }

  hideSubType() {
    this.setState({ subTypeId: null })
  }

  toggleMultipleSubTypes(subType) {
    if (this.state.selectedSubtypes.length) {
      this.setState({ selectedSubtypes: [] })
    } else {
      this.setState({ selectedSubtypes: subType?.subTypes })
    }
  }
  /**
   * The following props are expected:
   * typeHierarchy The type hierarchy to render
   * selectedTypes The state of the selected elements in the hierarchy
   * openTypes The state of which
   *
   * toggleType Used to toggle if a type is enabled or not
   * toggleSubType Used to toggle is a types sub type is enabled or not
   */

  renderContent() {
    if (this.props.typeHierarchy && this.props.selectedTypes) {
      return (
        <ButtonGroup className="header-button-group type-filter">
          {this.props.typeHierarchy.map((type) => {
            let disabledSubs = Object.keys(this.props.selectedTypes[type.id]).find((subType) => !this.props.selectedTypes[type.id][subType])
            let enabledSubs = Object.keys(this.props.selectedTypes[type.id]).find((subType) => this.props.selectedTypes[type.id][subType])
            return (
              <ButtonDropdown
                isOpen={this.state.openTypes && this.state.openTypes[type.id]}
                toggle={() => {
                  this.toggleFilter(type.id)
                }}
                size="sm"
                key={type.id}
              >
                <DropdownToggle caret className={type.id} color={type.id}>
                  <span>
                    {this.props.toggleType ? (
                      <Icon
                        icon={enabledSubs && disabledSubs ? "indeterminate_check_box" : enabledSubs ? "check_box" : "check_box_outline_blank"}
                        onClick={(e) => {
                          this.props.toggleType(e, type.id)
                        }}
                      />
                    ) : null}
                    {t(`typeNames.${type.name}`)} {!this.props.toggleType ? `${Object.values(this.props.selectedTypes[type.id]).filter((subType) => subType).length}/${type.subTypes.length}` : null}
                  </span>
                </DropdownToggle>

                <DropdownMenu>
                  {type.subTypes.map((subType) => {
                    if (typeof subType?.subTypes === "object") {
                      return (
                        <ButtonDropdown
                          isOpen={this.state.subTypeId === subType.id}
                          onMouseOver={() => {
                            this.showSubType(subType.id)
                          }}
                          onMouseLeave={() => this.hideSubType()}
                          toggle={() => this.toggleMultipleSubTypes(subType)}
                          key={subType.id}
                          direction="end"
                          className="menu-sub-type"
                        >
                          <DropdownToggle caret>
                            <Icon
                              onClick={(e) => {
                                this.props.toggleSubType(e, type.id, subType.id)
                              }}
                              icon={Number(this.state.selectedSubtypes.length) === Number(subType?.subTypes.length) ? "check_box" : "indeterminate_check_box"}
                            />{" "}
                            {t(`types.${subType.name}`)}
                          </DropdownToggle>

                          <DropdownMenu end>
                            {subType?.subTypes.map((innerSubType) => {
                              return (
                                <DropdownItem key={innerSubType} toggle={false}>
                                  <Icon
                                    onClick={(e) => {
                                      this.toggleSubType(innerSubType)
                                      this.props.toggleSubType(e, type.id, subType.id)
                                    }}
                                    icon={this.state.selectedSubtypes.includes(innerSubType) ? "check_box" : "check_box_outline_blank"}
                                  />{" "}
                                  {innerSubType}
                                </DropdownItem>
                              )
                            })}
                          </DropdownMenu>
                        </ButtonDropdown>
                      )
                    }

                    return (
                      <DropdownItem
                        key={subType.id}
                        onClick={(e) => {
                          this.props.toggleSubType(e, type.id, subType.id)
                        }}
                        toggle={false}
                      >
                        <Icon icon={this.props.selectedTypes[type.id][subType.id] ? "check_box" : "check_box_outline_blank"} /> {subType.name ? `${t(`types.${subType.name}`)}` : subType.id}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            )
          })}
        </ButtonGroup>
      )
    }
    return null
  }
}
