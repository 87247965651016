import React from "react"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import Content from "./EconodeContent"
import SideBar from "./EconodeSideBar"

import "./Econode.css"

export default class Econode extends EcosuiteComponent {
  renderContent() {
    if (this.props.selectedProjects && this.props.moduleViews) {
      return (
        <div className="Dashboard Econode">
          <SideBar
            refinements={this.props.refinements}
            selectProjectSortMethod={this.props.selectProjectSortMethod}
            autoCompletes={this.state.autoCompletes}
            selectedFilter={this.props.selectedFilter}
            selectedRefinements={this.props.selectedRefinements}
            disabledProjects={this.props.disabledProjects}
            searchTerm={this.props.searchTerm}
            portfolios={this.props.portfolios}
            portfolio={this.props.portfolio}
            allProjects={this.props.allProjects}
            projects={this.props.projects}
            selectedProject={this.props.selectedProject}
            groups={this.props.groups}
            moduleViews={this.props.moduleViews}
            restrictions={this.props.restrictions}
            loadTime={this.props.loadTime}
            actions={this.props.actions}
            version={this.props.version}
            portfoliosEnabled={this.props.portfoliosEnabled}
          />
          <Content
            projects={this.props.selectedProjects}
            project={this.props.selectedProject}
            groups={this.props.groups}
            loadTime={this.props.loadTime}
            actions={this.props.actions}
            moduleViews={this.props.moduleViews}
            restrictions={this.props.restrictions}
            userId={this.props.userId}
            portfoliosEnabled={this.props.portfoliosEnabled}
            updateProject={this.props.updateProject}
          />
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
