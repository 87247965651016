import API, { IRange } from "@common/API"
import Logger from "@common/Logger"
import EventService from "../event/EventService"
import axios from "axios"

interface PresignedResponse {
  fields: Record<string, string>
  url: string
  key: string
}

const FinanceService = {
  getIrrCategories(projectId: string) {
    return API.getFromCache("/finance/projects/" + projectId + "/irr-categories")
  },

  getCashFlowTotals(mode: "actual" | "lbe" | "forecast", flags: [string]) {
    let url = "/finance/cashflow-totals"
    if (mode) {
      url = API.addParamToUrl(url, "mode", mode)
    }
    if (flags && flags.length) {
      url = API.addParamToUrl(url, "flags", flags.join(","))
    }
    // @ts-ignore
    return API.getFromCache(url, API.getDefaultCacheTimeout())
  },

  getProjectBilling(projectId: string) {
    return API.get(`/finance/projects/${projectId}/billing`)
  },

  async getProjectBillingEvents(project: any, range: IRange, lineItems: Array<any>) {
    // @ts-ignore
    const invoiceDateString = range.start.format("YYYY-MM")
    const invoiceId = `invoice-issue-${project.code}-${invoiceDateString}`

    const paymentTerms = new Set()
    lineItems.forEach((lineItem) => {
      paymentTerms.add(lineItem.paymentTerms)
    })

    const [invoiceEvent, paymentEvents] = await Promise.all([
      EventService.getEvent(invoiceId),
      await Promise.all(
        Array.from(paymentTerms).map((paymentTerm) => {
          const paymentId = `invoice-payment-${paymentTerm}-${project.code}-${invoiceDateString}`
          return EventService.getEvent(paymentId)
        }),
      ),
    ])

    return { invoiceEvent: invoiceEvent, paymentEvents: paymentEvents }
  },

  resolveEvent(event: { id: string }) {
    return EventService.resolveEvent(event.id)
  },

  getCashFlowPaymentLogs(projectId: string, cashFlowId: string) {
    Logger.debug(`Getting payments for cashFlowId: ${projectId} ${cashFlowId}`)
    const encodeURL = (url: string): string => {
      return url.replace(/#/g, "%23")
    }
    const encoded = encodeURL(`/finance/projects/${projectId}/cash-flows/${cashFlowId}/payment-logs`)
    return API.get(encoded)
  },

  getCashFlowTimeSeriesArrears(projectId: string): Promise<any> {
    Logger.debug(`Getting cash flow time series arrears for projectId: ${projectId}`)
    return API.get(`/finance/projects/${projectId}/cash-flow-time-series-arrears`)
  },

  updateCashFlowPaymentLogs(projectId: string, cashFlowId: string, paymentLogs: unknown) {
    Logger.debug(`Getting payments for cashFlowId: ${projectId} ${cashFlowId}`)
    return API.put(`/finance/projects/${projectId}/cash-flows/${cashFlowId}/payment-logs`, paymentLogs)
  },

  /**
   * Upload a document against a cash flow payment.
   * @param file - The file.
   * @param onProgress - The progress call back.
   * @param onFinish - The finish callback.
   * @param projectId - The project ID.
   * @param cashFlowId - The cash flow ID.
   * @param paymentId - THe payment ID.
   * @returns {Promise<string>}
   */
  async uploadDocument(
    file: File,
    onProgress: (...args: any) => void,
    onFinish: (...args: any) => void,
    projectId: string,
    cashFlowId: string,
    paymentId: string,
  ) {
    // 1. Get a signed post request.
    const presigned = await API.post<PresignedResponse, unknown>(`/finance/payment-logs/document`, {
      projectId: projectId,
      cashFlowId: cashFlowId,
      paymentId: paymentId,
      documentName: file.name,
      contentType: file.type,
    })

    // 2. Store the file with the post request.
    const formData: any = new FormData()

    // Push our data into our FormData object
    for (const [name, value] of Object.entries(presigned.fields)) {
      formData.append(name, value)
    }

    // Append the expected file.
    formData.append("file", file)

    axios
      .request({
        method: "POST",
        url: presigned.url,
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
        onUploadProgress: (p) => {
          if (p.progress !== 1.0) onProgress.call(this, file.name, p.progress)
        },
      })
      .then(() => {
        onFinish.call(this, file.name)
      })

    return `${presigned.url}${presigned.key}`
  },
}

export default FinanceService
