import { S3 } from "aws-sdk"
import React from "react"
import { Button, ButtonGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap"
import S3DocumentServiceInstance from "@common/input/files/S3DocumentService"
import prettyBytes from "pretty-bytes"
import i18n from "src/i18n"

const { t } = i18n
export const DocumentTile = ({ path, data, deleteFile }: { path: string; data: S3.Types.GetObjectOutput; deleteFile: (key: string) => void }) => {
  const separatedPath = path.split("/")
  const name = separatedPath[separatedPath.length - 1]

  return (
    <ListGroupItem key={path}>
      <ListGroupItemHeading>{name}</ListGroupItemHeading>
      <>
        <ListGroupItemText>{`${data.ContentType ? `${data.ContentType}, ` : ""} ${data.ContentLength ? prettyBytes(data.ContentLength) : ""}`}</ListGroupItemText>
        {data.LastModified && <ListGroupItemText>{t("messages.uploaded_data_info", { date: data.LastModified.toLocaleString() })}</ListGroupItemText>}
      </>
      <ButtonGroup>
        <Button color={"primary"} onClick={async () => await S3DocumentServiceInstance.downloadFile(path)}>
          {t("buttons.download")}
        </Button>
        <Button
          color={"danger"}
          onClick={async () => {
            await S3DocumentServiceInstance.deleteFile(path)
            await deleteFile.call(this, path)
          }}
        >
          {t("buttons.delete")}
        </Button>
      </ButtonGroup>
    </ListGroupItem>
  )
}

export default DocumentTile
