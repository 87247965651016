import React, { Component } from "react"
import i18n from "src/i18n"
import logoRound from "src/img/logo.png"
import Icon from "./display/Icon"

const { t } = i18n

class EcosuiteComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, note: "" }

    this.saveNote = this.saveNote.bind(this)
    this.formDataChanged = this.formDataChanged.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  isComponentMounted() {
    return this._isMounted
  }

  setStateIfMounted(state, callback) {
    if (this.isComponentMounted()) {
      super.setState(state, callback)
    }
  }

  componentDidCatch(error) {
    this.setStateIfMounted({ hasError: true, error: error })
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // A change in props may remove the error so we reset hasError
  //   return { hasError: false, error: undefined };
  // }

  componentDidUpdate() {
    // A change in props may remove the error so we reset hasError
    return { hasError: false, error: undefined }
  }

  saveNote(e) {
    this.setStateIfMounted({ note: e.target.value })
  }

  formDataChanged(form) {
    this.setStateIfMounted({ formData: form.formData })
  }

  areProjectsCurrent(projectIds) {
    const latestProjectIds = this.props.projects.map((project) => project.code)
    return JSON.stringify(projectIds) === JSON.stringify(latestProjectIds)
  }

  isProjectCurrent(projectId) {
    return projectId === this.props.project?.code
  }

  isSiteCurrent(siteId) {
    return siteId === this.props.site.code
  }

  isRangeCurrent(range) {
    return range.isSame(this.props.range)
  }

  isAggregationCurrent(aggregation) {
    return aggregation === this.props.aggregation
  }

  isContentValid(data) {
    if (data && !this.isContentError(data)) {
      return true
    }
    return false
  }

  isContentError(data) {
    return data instanceof EcosuiteComponentError
  }

  render() {
    if (this.state.hasError) {
      return this.renderError()
    } else {
      return this.renderContent()
    }
  }

  renderError() {
    return <Error error={this.state.error} />
  }

  renderLoading() {
    return <Loading />
  }

  renderContent() {
    return this.renderLoading()
  }
}

class TODO extends Component {
  render() {
    return (
      <div className="App-todo">
        <img src={logoRound} className="App-logo" alt="logo" />
        <div>{t("labels.coming_soon")}</div>
      </div>
    )
  }
}

/**
 * @prop message - the displayed message
 */
class Loading extends Component {
  render() {
    return (
      <div className="loading">
        <img src={logoRound} className="App-logo" alt="logo" />
        <p className="loading-message">{this.props.message}</p>
      </div>
    )
  }
}

class Error extends Component {
  render() {
    return (
      <div className="Error">
        <h2 className="error-title">
          <Icon icon="error_outline" /> {t("labels.error")}
        </h2>
        {this.props.error ? (
          <p className="error-message">
            {typeof this.props.error === "string" ? this.props.error : this.props.error.message}
          </p>
        ) : null}
      </div>
    )
  }
}

class Warning extends Component {
  render() {
    return (
      <div className="Warning">
        <h2 className="warning-title">
          <Icon icon="warning" /> {this.props.title ? this.props.title : `${t("labels.warning")}`}
        </h2>
        <p className="warning-message">{this.props.message}</p>
        {this.props.error ? <p className="warning-message">{this.props.error.message}</p> : null}
      </div>
    )
  }
}

class Message extends Component {
  render() {
    return (
      <div className="Message" style={this.props.style}>
        <p>{this.props.message}</p>
      </div>
    )
  }
}

/**
 * Used to identify that an error has occured and that the componnet should report this.
 */
class EcosuiteComponentError {
  constructor(error) {
    this.error = error
  }

  getError() {
    return this.error
  }
}

export { Loading, TODO, Error, Warning, Message, EcosuiteComponentError }
export default EcosuiteComponent
