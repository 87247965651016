import {
  createDndPlugin,
  createHistoryPlugin,
  createParagraphPlugin,
  createPlugins,
  createSoftBreakPlugin,
  createTrailingBlockPlugin,
  ELEMENT_IMAGE,
  Plate,
  TNode,
  usePlateStore,
} from "@udecode/plate"
import React from "react"
import { Container } from "reactstrap"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { createS3ImagePlugin } from "./image/nodes/createS3ImagePlugin"
import { s3ImageUpload } from "./image/nodes/s3ImageUpload"
import { S3ImageElement } from "./image/ui/S3ImageElement"
import "./Editor.scss"
import Icon from "@common/display/Icon"

export interface NodeEditorProps {
  editorIdPrefix: string
  value?: TNode[]
  onChange?: (event: TNode[]) => void
  s3ImageBucket?: string
  copyIndicator?: boolean
}

/**
 * A more complex editor that currently has the following functionality:
 *  - Images (and image upload to S3)
 *  - History
 *  - Drag and Drop
 * @constructor
 * @param props - The NodeEditor properties.
 */
export const NodeEditor = (props: NodeEditorProps): JSX.Element => {
  const realEditorId = `${props.editorIdPrefix}-NodeEditor`

  // This is a workaround for the fact that sometimes the editor can be initialized with an outdated value, and then
  // require restarts to actually show the real value.
  //
  // Doing this just makes the process a bit smoother.
  if (props.value) {
    try {
      usePlateStore(realEditorId).set.value(props.value)
    } catch (e) {
      // Don't worry about it.
    }
  }

  /**
   * The plugins associated with the NodeEditor.
   */
  const plugins = createPlugins(
    [
      createHistoryPlugin(),
      createParagraphPlugin(),
      createS3ImagePlugin({
        // Specify that images uploaded should follow the s3ImageUpload function.
        options: {
          s3UploadImage: s3ImageUpload.s3UploadImage,
          s3Bucket: props.s3ImageBucket ?? "",
        },
      }),
      createTrailingBlockPlugin(),
      createDndPlugin(),
      createSoftBreakPlugin(),
    ],
    {
      components: {
        // Make sure that any ELEMENT_IMAGE elements are rendered using the S3ImageElement.
        [ELEMENT_IMAGE]: S3ImageElement,
      },
    },
  )

  /**
   * Get the class name according to whether the rich editor is currently selected.
   * @param name - The default class name.
   * @param isSelected - Whether the editor is currently selected.
   * @returns {string|*} - The resulting class name.
   */
  const getClassName = (name: string, isSelected: boolean) => {
    if (isSelected) {
      return `${name}-active`
    }

    return name
  }

  // If needed, provide a copy indicator to show that this editor can be copied
  // into.
  if (props.copyIndicator) {
    return (
      <>
        {" "}
        <Icon icon={"content_copy"} />
        <Container
          // TODO: Check for selected.
          className={getClassName("node-editor", false)}
        >
          <DndProvider backend={HTML5Backend}>
            <Plate
              id={realEditorId}
              enabled={!!(props.value && props.onChange)}
              initialValue={props.value && props.value}
              value={props.value && props.value}
              onChange={(newValue) => {
                props.onChange && props.onChange(newValue)
              }}
              plugins={plugins}
            />
          </DndProvider>
        </Container>
      </>
    )
  }

  return (
    <>
      <Container
        // TODO: Check for selected.
        className={getClassName("node-editor", false)}
      >
        <DndProvider backend={HTML5Backend}>
          <Plate
            id={realEditorId}
            enabled={!!(props.value && props.onChange)}
            initialValue={props.value && props.value}
            value={props.value && props.value}
            onChange={(newValue) => {
              props.onChange && props.onChange(newValue)
            }}
            plugins={plugins}
          />
        </DndProvider>
      </Container>
    </>
  )
}

/**
 * Some useful utilities for using NodeEditor.
 */
export const NodeEditorUtils = {
  /**
   * The default editor value.
   */
  defaultValue: [{ children: [{ text: "" }] }],

  /**
   * Get the corresponding string representation of an editor value.
   *
   * Note that this is a somewhat hacky way around the fact that our current implementation of sending data through UI
   * schema forms doesn't allow for custom components of the type array or object.
   *
   * Broadly speaking, this is a symptom of the fact that, in the context of a form, the user isn't actually entering in
   * only text, or numbers, or a specified array. Therefore, the component sort of breaks the design of what the schema
   * is intended to provide.
   *
   * However, it seems appropriate to send the raw data. This is because the frontend may not know how to actually parse
   * all of this information at once.
   *
   * This all means that when receiving this data, it will have to be converted from a string representation to an
   * object representation.
   * @param value - The editor value
   */
  valueToJSONString: function (value: (TNode | undefined)[] | undefined): string | undefined {
    if (!value) return

    return JSON.stringify(value)
  },

  /**
   * Get the corresponding editor value representation given a string.
   *
   * @see RichEditorUtils.valueToJSONString
   * @param stringValue - The editor value in string format.
   * @param rawStringCompatible - Whether to interpret raw string input and convert it to a value. This is mainly for
   * backwards compatibility, where old data may be formatted as raw strings.
   */
  jsonStringToValue: function (stringValue: string | undefined, rawStringCompatible: boolean): TNode[] | unknown {
    if (!stringValue) return this.defaultValue

    // If whatever is currently stored cannot be interpreted, then return an empty value.
    try {
      return JSON.parse(stringValue)
    } catch (SyntaxError) {
      if (rawStringCompatible) {
        const newLines = []

        // Add each line of the string as a text object.
        const lineArray: string[] = stringValue.split("\n")
        for (let i = 0; i < lineArray.length; i++) {
          newLines.push({ children: [{ text: lineArray[i] }] })
        }

        return newLines
      }
      return this.defaultValue
    }
  },

  /**
   * Check if a value represents empty input (all text fields, but no text).
   * @param value - The Node Editor value.
   */
  isValueEmpty: function (value: (TNode | undefined)[] | undefined): boolean {
    if (!value) return true

    let empty = true
    JSON.stringify(value, (_, nestedValue) => {
      // Consider the node editor not empty if:
      if (nestedValue) {
        // there is text somewhere
        if (nestedValue.text && nestedValue.test != "") {
          empty = false
        }
        // there is an image
        if (nestedValue.type && nestedValue.type === "img") {
          empty = false
        }
      }
      return nestedValue
    })

    return empty
  },
}

export default NodeEditor
