import API from "@common/API"
import Logger from "@common/Logger"

const ProFormaService = {
  async getProjectProForma(projectId: string) {
    return API.get<{ proForma: unknown }>(`/projects/${projectId}/pro-forma`)
      .then((data: { proForma: unknown }) => {
        return data.proForma
      })
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: "Unable to retrieve proForma for project: " + projectId })
      })
  },

  async getProFormas() {
    return API.get<{ proFormas: unknown }>(`/projects/pro-formas`)
      .then((data: { proFormas: unknown }) => {
        return data.proFormas
      })
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: "Unable to retrieve proFormas" })
      })
  },

  /**
   * Gets the IRR Report for a project.
   *
   * @param {string} projectId The ID of the project to get the report for
   * @param {string} proFormaVersionId (optional) The ID of the Pro Forma version to apply to the report; if not specified the current pro forma is used
   * @returns the IRR report for the project
   */
  async getProjectProFormaIRR(projectId: string, proFormaVersionId?: string): Promise<unknown> {
    let url = `/finance/projects/${projectId}/pro-forma/irr`
    if (proFormaVersionId) {
      url += `?proFormaVersionId=${encodeURIComponent(proFormaVersionId)}`
    }
    return API.get(url)
  },

  async saveProjectProForma(projectId: string, proForma: unknown, note: string) {
    return API.put<{ proForma: unknown }, unknown>(`/projects/${projectId}/pro-forma?note=${encodeURIComponent(note)}`, proForma).then((response) => response.proForma)
  },

  async saveProjectProFormaVersion(projectId: string, proForma: unknown, version: string, note: string) {
    return API.put<{ proForma: unknown }, unknown>(`/projects/${projectId}/pro-forma?version=${encodeURIComponent(version)}&note=${encodeURIComponent(note)}`, proForma).then(
      (response) => response.proForma,
    )
  },

  async deleteProjectProFormaVersion(projectId: string, version: string): Promise<unknown> {
    return API.delete(`/projects/${projectId}/pro-forma?version=${version}`)
  },

  async getVersions(projectId: string) {
    return API.get<{
      versions: Array<{ created: string }>
    }>(`/projects/${projectId}/pro-forma/versions`).then((response: { versions: Array<{ created: string }> }) =>
      response.versions.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()),
    )
  },
}

export default ProFormaService
