import { create, all } from "mathjs"

import ReportUtils from "./ReportUtils"

const math = create(all)

var add = function (a, b) {
  return math.add(a ? a : 0, b ? b : 0)
}

const ReportTotalUtils = {
  initReportTotals(report) {
    const aggregatedReport = {
      actual: { netIncome: 0, unleveredTotal: 0, leveredTotal: 0, unleveredIrr: undefined, leveredIrr: undefined, retainedEarnings: undefined, categories: {} },
      expected: { netIncome: 0, unleveredTotal: 0, leveredTotal: 0, unleveredIrr: undefined, leveredIrr: undefined, retainedEarnings: undefined, categories: {} },
      forecast: { netIncome: 0, unleveredTotal: 0, leveredTotal: 0, unleveredIrr: undefined, leveredIrr: undefined, retainedEarnings: undefined, categories: {} },
      latestBestEstimate: { netIncome: 0, unleveredTotal: 0, leveredTotal: 0, unleveredIrr: undefined, leveredIrr: undefined, retainedEarnings: undefined, categories: {} },
    }

    Object.keys(report.forecast.categories).forEach((categoryId) => {
      const category = report.forecast.categories[categoryId]
      const groupCategory = this.initCategory(category)
      aggregatedReport.forecast.categories[categoryId] = groupCategory
    })

    Object.keys(report.expected.categories).forEach((categoryId) => {
      const category = report.expected.categories[categoryId]
      const groupCategory = this.initCategory(category)
      aggregatedReport.expected.categories[categoryId] = groupCategory
    })

    Object.keys(report.actual.categories).forEach((categoryId) => {
      const category = report.actual.categories[categoryId]
      const groupCategory = this.initCategory(category)
      aggregatedReport.actual.categories[categoryId] = groupCategory
    })

    Object.keys(report.latestBestEstimate.categories).forEach((categoryId) => {
      const category = report.latestBestEstimate.categories[categoryId]
      const groupCategory = this.initCategory(category)
      aggregatedReport.latestBestEstimate.categories[categoryId] = groupCategory
    })

    return aggregatedReport
  },

  initCategory(category) {
    let groupCategory = {
      name: category.name,
      categories: {},
    }
    Object.keys(category.categories).forEach((subCategoryId) => {
      let subCategory = category.categories[subCategoryId]
      groupCategory.categories[subCategoryId] = {
        name: subCategory.name,
        categories: {},
      }

      if (subCategory.categories) {
        Object.keys(subCategory.categories).forEach((accountId) => {
          groupCategory.categories[subCategoryId].categories[accountId] = {
            name: subCategory.categories[accountId].name,
            categories: {},
          }
        })
      }
    })

    return groupCategory
  },

  calculateReportTotals(report) {
    const futureIdx = ReportUtils.getFutureIndex(report, "month")
    const toDateTotals = this.initReportTotals(report)
    const lifetimeTotals = this.initReportTotals(report)

    report.dates.forEach((date, idx) => {
      if (idx <= futureIdx) {
        this.addSectionTotals(report.forecast, idx, toDateTotals.forecast)
        this.addSectionTotals(report.expected, idx, toDateTotals.expected)
        this.addSectionTotals(report.actual, idx, toDateTotals.actual)
        this.addSectionTotals(report.latestBestEstimate, idx, toDateTotals.latestBestEstimate)
      }

      this.addSectionTotals(report.forecast, idx, lifetimeTotals.forecast)
      this.addSectionTotals(report.latestBestEstimate, idx, lifetimeTotals.latestBestEstimate)
    })

    return {
      toDateTotals: toDateTotals,
      lifetimeTotals: lifetimeTotals,
    }
  },

  addSectionTotals(reportSection, reportIdx, reportTotalsSection) {
    reportTotalsSection.netIncome = add(reportTotalsSection.netIncome, reportSection.netIncomes[reportIdx])
    reportTotalsSection.unleveredTotal = add(reportTotalsSection.unleveredTotal, reportSection.unleveredTotals[reportIdx])
    reportTotalsSection.leveredTotal = add(reportTotalsSection.leveredTotal, reportSection.leveredTotals[reportIdx])
    reportTotalsSection.unleveredIrr = reportSection.unleveredIrr[reportIdx]
    reportTotalsSection.leveredIrr = reportSection.leveredIrr[reportIdx]
    reportTotalsSection.retainedEarnings = reportSection.retainedEarnings[reportIdx]

    Object.keys(reportSection.categories).forEach((categoryId) => {
      const category = reportSection.categories[categoryId]
      reportTotalsSection.categories[categoryId].totals = add(reportTotalsSection.categories[categoryId].totals, category.totals[reportIdx])

      Object.keys(category.categories).forEach((subCategoryId) => {
        const subCategory = category.categories[subCategoryId]
        reportTotalsSection.categories[categoryId].categories[subCategoryId].totals = add(reportTotalsSection.categories[categoryId].categories[subCategoryId].totals, subCategory.totals[reportIdx])

        if (subCategory.categories) {
          Object.keys(subCategory.categories).forEach((accountId) => {
            const account = subCategory.categories[accountId]
            reportTotalsSection.categories[categoryId].categories[subCategoryId].categories[accountId].totals = add(
              reportTotalsSection.categories[categoryId].categories[subCategoryId].categories[accountId].totals,
              account.totals[reportIdx],
            )
          })
        }
      })
    })
  },
}

export default ReportTotalUtils
