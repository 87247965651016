import React, { useState } from "react"
import { Button, Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap"
import i18n from "src/i18n"
import "./ImageCarousel.scss"

const { t } = i18n
/**
 * An image carousel.
 * @param uriData - The list of URI to display.
 * @param interval - The interval in which the slides are changed.
 * @param altText - The alternative text to display.
 * @param caption - The caption text to display.
 * @constructor
 */
export const ImageCarousel = ({
  uriData,
  interval,
  altText,
  captionHeader,
  captionText,
}: {
  uriData: string[]
  interval: number | boolean | undefined
  altText: string[]
  captionHeader: string[]
  captionText: string[]
}): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [animating, setAnimating] = useState<boolean>(false)
  const [srcErrors, setSrcErrors] = useState<Record<string, boolean>>({})

  const items = uriData.map((uri, index) => {
    return {
      id: index,
      src: uri,
      altText: altText.length >= index ? altText[index] : "",
      captionHeader: captionHeader.length >= index ? captionHeader[index] : "",
      captionText: captionText.length >= index ? captionText[index] : "",
    }
  })

  /**
   * Go to the next image.
   */
  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  /**
   * Go to the previous image.
   */
  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  /**
   * Go to a specific index.
   * @param newIndex - The new index.
   */
  const goToIndex = (newIndex: number) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  // Create the carousel slides.
  const slides = items.map((item) => {
    return (
      <CarouselItem key={item.id} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} slide={false}>
        {srcErrors[item.id.toString()] ? (
          <Button onClick={() => window.open(item.src, "_blank", "noopener,noreferrer")}>{t("labels.open_file_in_browser")}</Button>
        ) : (
          <img
            className={"image-carousel-image"}
            src={item.src}
            alt={item.altText}
            onError={() => {
              setSrcErrors({ ...srcErrors, [item.id]: true })
            }}
          />
        )}
        <CarouselCaption captionHeader={item.captionHeader} captionText={item.captionText} />
      </CarouselItem>
    )
  })

  return (
    <>
      <div className={"image-carousel"}>
        <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={interval ?? false} slide={false}>
          {slides}
        </Carousel>
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      </div>
    </>
  )
}

export default ImageCarousel
