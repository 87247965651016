import React, { useState } from "react"
import MediaTable from "./MediaTable"
import { Loading } from "@common/EcosuiteComponent"
import { deleteFile, getTags, listCodeFiles } from "./MediaService"
import MediaSearch from "./MediaSearch"
import { fuzzySearchStringList } from "@common/input/search/SearchGroup"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"

/**
 * The media view.
 * @param code - The project code.
 * @constructor
 */
export const MediaView = ({ code }: { code: string }): JSX.Element => {
  // All media.
  const [allMedia, setAllMedia] = useState<MediaResponse[]>([])
  // All media tags.
  const [allMediaTags, setAllMediaTags] = useState<MediaTagResponse[]>([])

  // Whether the view is initializing.
  const [initializingData, setInitializingData] = useState<boolean>(true)

  // All current media.
  const [currentMedia, setCurrentMedia] = useState<MediaResponse[]>([])
  // All enabled tags.
  const [enabledTagsIndex, setEnabledTagsIndex] = useState(allMediaTags.map((_, index) => index))

  useCurrentEffect(
    (isCurrent) => {
      initializeData(isCurrent)
    },
    [code],
  )

  const initializeData = async (isCurrent: () => boolean) => {
    setInitializingData(true)

    // Get all media.
    const allMedia = await listCodeFiles(code)

    // Get all media tags.
    const allMediaTags = await getTags(code)

    if (isCurrent()) {
      setAllMedia(allMedia)
      setCurrentMedia(allMedia)

      setAllMediaTags(allMediaTags)
      setEnabledTagsIndex(allMediaTags.map((_: unknown, index: number) => index))

      setInitializingData(false)
    }
  }

  /**
   * Search all media.
   * @param searchTerm - The search term.
   */
  const searchMedia = (searchTerm: string): MediaResponse[] => {
    // If no search term is provided, then reset the results.
    if (searchTerm === "") {
      setCurrentMedia(allMedia)
      return allMedia
    }

    const options = {
      isCaseSensitive: false,
      threshold: 0.2,
      includeScore: true,
    }

    // Evaluate based upon:
    //  - name
    //  - key
    const mediaNames: string[] = []
    const mediaKeys: string[] = []

    allMedia.forEach((media) => {
      mediaNames.push(media.name)
      mediaKeys.push(media.key)
    })

    const nameResults = fuzzySearchStringList(mediaNames, searchTerm, options)
    const keysResults = fuzzySearchStringList(mediaKeys, searchTerm, options)

    const matchingMediaSet: Set<MediaResponse> = new Set()

    allMedia.forEach((media) => {
      nameResults.forEach((result) => {
        if (media.name === result.item) {
          matchingMediaSet.add(media)
        }
      })
      keysResults.forEach((result) => {
        if (media.key === result.item) {
          matchingMediaSet.add(media)
        }
      })
    })

    const resultArray = Array.from(matchingMediaSet)
    setCurrentMedia(resultArray)
    return resultArray
  }

  /**
   * Delete and remove media.
   * @param key - The media key.
   */
  const deleteAndRemoveMedia = async (key: string): Promise<void> => {
    // Delete the file.
    await deleteFile(key)

    // Remove the file from the cached memory.
    const newAllMedia = allMedia.filter((media) => media.key !== key)
    const newCurrentMedia = currentMedia.filter((media) => media.key !== key)

    setAllMedia(newAllMedia)
    setCurrentMedia(newCurrentMedia)
  }

  if (initializingData) {
    return <Loading />
  }

  // Filter the media by selected tags.
  const selectedTags = allMediaTags.filter((tag, index) => enabledTagsIndex.includes(index))
  const selectedTagLabels = selectedTags.map((tag) => tag.label)
  const operatingMedia = currentMedia.filter((media) => {
    return media.tags.filter((tag: string) => selectedTagLabels.includes(tag)).length > 0
  })

  // Create an interface to check if the media has been filtered.
  const areTagsFiltered = selectedTags.length !== allMediaTags.length

  return (
    <>
      <MediaSearch allMediaTags={allMediaTags} parentSearchMedia={searchMedia} enabledTagsIndex={enabledTagsIndex} setEnabledTagsIndex={setEnabledTagsIndex} areTagsFiltered={areTagsFiltered} />
      <MediaTable operatingMedia={operatingMedia} deleteAndRemoveMedia={deleteAndRemoveMedia} />
    </>
  )
}

export default MediaView
