import { Heading, Text } from "@aws-amplify/ui-react"
import { I18n } from "aws-amplify"
import * as React from "react"
import i18n from "src/i18n"
import "./Login.css"
import LoginHeader from "./LoginHeader"

const { t } = i18n
export default {
  Header() {
    return (
      <div className="auth-header">
        <LoginHeader />
      </div>
    )
  },
  SignIn: {
    Header() {
      const message = "Sign in to your account"
      return (
        <Heading level={5} className="auth-signin-header">
          {I18n.get(message)}
        </Heading>
      )
    },
  },
  ConfirmSignIn: {
    Header() {
      return (
        <>
          <Heading level={5}>{t("labels.confirm_TOTP_code")}</Heading>
          <Text>{t("notes.enter_six_digits")}:</Text>
        </>
      )
    },
  },
  ResetPassword: {
    Header() {
      return (
        <Heading level={5} className="auth-resetpassword-header">
          {t("labels.reset_password")}
        </Heading>
      )
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <Heading level={5} className="auth-confirm-resetpassword-header">
          {t("labels.reset_your_password")}
        </Heading>
      )
    },
  },
}
