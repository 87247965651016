import React from "react"
import { Button, ButtonGroup, ButtonToolbar, Form, Input, InputGroup, InputGroupText } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import ClearButton from "@common/input/search/ClearButton"
import i18n from "src/i18n"

const { t } = i18n
/**
 * Service request specific view controls.
 * @param project - The current project.
 * @param serviceRequest - The selected service request.
 * @param selectServiceRequest - Callback function to select a service request.
 * @param searchServiceRequest - Callback function to search a service request.
 * @param searchTerm - The search term.
 * @param readOnly - Whether the component should consider itself read only.
 * @constructor
 */
export const ServiceRequestViewControls = ({
  project,
  serviceRequest,
  selectServiceRequest,
  searchServiceRequest,
  searchTerm,
  readOnly,
}: {
  project: Project
  serviceRequest: ServiceRequest
  selectServiceRequest: (serviceRequest: ServiceRequest) => void
  searchServiceRequest: (value: string) => void
  searchTerm: string
  readOnly: boolean
}): JSX.Element => {
  const newServiceRequestButtonId = "new-service-request"

  return (
    <>
      {
        // Render the action button bar.
      }
      <div className="content-view-controls search-bar">
        <ButtonToolbar className="secondary-header-toolbar float-end">
          <ButtonGroup className="header-button-group">
            <Button
              id={newServiceRequestButtonId}
              className={serviceRequest && !serviceRequest.id ? "selected" : ""}
              onClick={() => {
                selectServiceRequest({})
              }}
              size="sm"
              color="ecogy"
              // Disable this button if there is no project selected, or marked as read only.
              disabled={!project || readOnly}
            >
              {t("event.messages.new_service_request")}
            </Button>
            {project && (
              <UncontrolledTooltip target={newServiceRequestButtonId} placement={"top"}>
                {t("event.messages.create_project_service_request", { projectCode: project.code })}
              </UncontrolledTooltip>
            )}
          </ButtonGroup>
        </ButtonToolbar>
        {
          // Render the search bar.
          // TODO: move to SearchGroup component
        }
        <Form
          inline
          className="float-end"
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <InputGroup size="sm">
            <InputGroupText>{t("labels.search")}:</InputGroupText>
            <Input type="text" name="search" placeholder="e.g. 'outage' or /PR/ST/SYS" value={searchTerm} onChange={(e) => searchServiceRequest(e.target.value)} />
            <InputGroupText
              onClick={() => {
                searchServiceRequest("")
              }}
            >
              X
            </InputGroupText>
          </InputGroup>
        </Form>
      </div>
      )
    </>
  )
}

export default ServiceRequestViewControls
