import React, { useState } from "react"
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem, InputGroup, Input, ListGroup, ListGroupItem } from "reactstrap"
import i18n from "src/i18n"

const { t } = i18n
// Checkbox widget override that adds Project Search Bar, Select All and Removed Selected button dropdown
export const PortfolioSelector = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  let { id, schema, options } = props

  // Currently only Array Type Schema Elements can be checkboxes
  if (schema.type !== "array") {
    return undefined
  }

  return (
    <>
      {/* Search bar with nested Select All and Removed Selected dropdown */}
      <InputGroup size="sm">
        <Input placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen((dropdownOpen) => !dropdownOpen)}>
          <DropdownToggle className="ecogy-form__portfolios__dropdown-toggle" caret />
          <DropdownMenu end>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault()
                props.onChange([...schema.items.enum])
              }}
            >
              {t("labels.select_all")}
            </DropdownItem>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault()
                props.onChange([])
              }}
            >
              {t("labels.removed_selected")}
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </InputGroup>

      {/* List of responses from the search result */}
      {searchTerm.length > 2 && (
        <ListGroup className="ecogy-form__portfolios__list-group">
          {options?.enumOptions
            ?.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
            ?.map((option) => {
              return (
                <ListGroupItem
                  key={option.value}
                  className="ecogy-form__portfolios__list-group-item"
                  tag="button"
                  action
                  onClick={() => {
                    setSearchTerm("")
                    if (props.value.includes(option.value)) {
                      // make a copy of the value prop to avoid direct mutations
                      const valuePropCopy = [...props.value]
                      valuePropCopy.splice(props.value.indexOf(option.value), 1)
                      props.onChange(valuePropCopy)
                    } else {
                      props.onChange([...props.value, option.value])
                    }
                  }}
                >
                  {option.label}
                </ListGroupItem>
              )
            })}
        </ListGroup>
      )}

      {/* Copy of react-jsonform-schema checkbox widget */}
      <div id={id} className="checkboxes" style={{ margin: "5px 0px 0px 0px" }}>
        {options?.enumOptions?.map((option, idx) => {
          return (
            <label key={`${id}_${idx}`} className="checkbox-inline">
              <span>
                <input
                  id={`${id}_${idx}`}
                  type="checkbox"
                  checked={props.value.includes(option.value)}
                  onChange={() => {
                    if (props.value.includes(option.value)) {
                      // make a copy of the value prop to avoid direct mutations
                      const valuePropCopy = [...props.value]
                      valuePropCopy.splice(props.value.indexOf(option.value), 1)
                      props.onChange(valuePropCopy)
                    } else {
                      props.onChange([...props.value, option.value])
                    }
                  }}
                />
                <span>{option.label}</span>
              </span>
            </label>
          )
        })}
      </div>
    </>
  )
}
