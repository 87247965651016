import React from "react"
import { Auth, I18n } from "aws-amplify"
import LoginHeader from "./LoginHeader"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import "./Login.css"
import { QRCodeSVG } from "qrcode.react"
import { Heading, TextField } from "@aws-amplify/ui-react"
import { Button } from "reactstrap"
import i18n from "src/i18n"
import { Trans } from "react-i18next"

const { t } = i18n
export default class MFA extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.updateCredentials = this.updateCredentials.bind(this)
    this.verifyCredentials = this.verifyCredentials.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.setup()
  }

  setup() {
    Auth.currentAuthenticatedUser().then((user) => {
      Auth.setupTOTP(user).then((code) => {
        this.setStateIfMounted({ user, code })
        // You can directly display the `code` to the user or convert it to a QR code to be scanned.
        // E.g., use following code sample to render a QR code with `qrcode.react` component:
        //      import QRCode from 'qrcode.react';
        //      const str = "otpauth://totp/AWSCognito:"+ username + "?secret=" + code + "&issuer=" + issuer;
        //      <QRCode value={str}/>
      })
    })
  }

  updateCredentials(e) {
    this.setStateIfMounted({ challengeAnswer: e.target.value })
  }

  verifyCredentials(e) {
    e.preventDefault()
    this.setStateIfMounted({ loading: true })

    // Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
    // Use the generated one-time password to verify the setup
    Auth.verifyTotpToken(this.state.user, this.state.challengeAnswer)
      .then(() => {
        // don't forget to set TOTP as the preferred MFA method
        Auth.setPreferredMFA(this.state.user, "TOTP").then(() => {
          this.props.loadMfaType()
          this.setStateIfMounted({ loading: false })
        })
      })
      .catch((e) => {
        // Token is not verified
        this.setStateIfMounted({ error: e, loading: false })
      })
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }
    if (this.state.user && this.state.code && !this.state.loading) {
      const str =
        "otpauth://totp/AWSCognito:" +
        this.state.user.attributes.email +
        "?secret=" +
        this.state.code +
        "&issuer=Ecosuite"
      return (
        <div>
          <LoginHeader />
          <div className="login-mfa">
            <div className="login-mfa-wrapper">
              <Heading level={5}>{I18n.get("Set up multi-factor authentication")}</Heading>
              <div className="login-mfa-body">
                <p>
                  <Trans i18nKey="notes.account_auth_step_one">
                    <b>Step 1:</b> Your account has administrative privileges and requires MFA(multi-factor
                    authentication) to be enabled using an app such as{" "}
                  </Trans>
                  <a
                    href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS&oco=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("labels.google_auth")}
                  </a>{" "}
                  or{" "}
                  <a href="https://authy.com/download/" target="_blank" rel="noopener noreferrer">
                    Authy
                  </a>
                  {t("notes.if_have_no_account")}
                </p>

                <div style={{ width: "100%" }}>
                  <Trans i18nKey="notes.account_auth_step_two">
                    <b>Step 2:</b>
                    <br />
                    <p>
                      To setup multi-factor authentication, open your authenticator app and scan the QR code below.{" "}
                    </p>
                  </Trans>
                  <QRCodeSVG value={str} style={{ margin: "auto", display: "flex", marginBottom: "8px" }} />
                  <p>
                    <Trans i18nKey="notes.enter_code_on_auth">
                      <b>OR</b>, enter the code <b>{t("notes.enter_code_on_auth", { code: this.state.code })}</b> on
                      your authenticator app.
                    </Trans>
                  </p>
                </div>

                <div>
                  <Trans i18nKey="notes.account_auth_step_three">
                    <p>
                      <b>Step 3:</b> Once you have scanned the QR code or entered the code, complete the setup by
                      entering the 6 digit code listed in your authenticator app.
                    </p>
                  </Trans>
                  <TextField placeholder={t("labels.auth_code")} onChange={this.updateCredentials} />
                </div>

                {this.state.error ? (
                  <p className="error">
                    {this.state.error.message ? this.state.error.message : `${t("errors.unable_to_verify_code")}`}
                  </p>
                ) : null}
              </div>
              <Button onClick={this.verifyCredentials} isFullWidth className="login-mfa-confirm-button">
                {t("labels.confirm")}
              </Button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <LoginHeader />
          <Loading />
        </div>
      )
    }
  }
}
