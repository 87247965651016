import React, { useState } from "react"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import { EcosuiteComponentError, Error, Loading, Message } from "@common/EcosuiteComponent"
import FinanceService from "@dashboard/data/project/finance/FinanceService"
import Logger from "@common/Logger"
import i18n from "src/i18n"

const { t } = i18n

/**
 * The SmartsheetSheetView props.
 */
interface SmartsheetSheetViewProps {
  code: string
}

/**
 * Smartsheets view.
 * @param code - The code.
 * @constructor
 */
export const SmartsheetSheetView = ({ code }: SmartsheetSheetViewProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [projectFinanceSettings, setProjectFinanceSettings] = useState<ProjectFinanceSettings | undefined>()
  const [projectFinanceSettingsError, setProjectFinanceSettingsError] = useState<EcosuiteComponentError | undefined>()

  useCurrentEffect(
    (isCurrent) => {
      setIsLoading(true)
      setProjectFinanceSettings(undefined)
      setProjectFinanceSettingsError(undefined)

      // Get the project finance settings.
      FinanceService.getProjectSettings(code)
        .then((projectFinanceSettings) => {
          if (isCurrent()) {
            setIsLoading(false)
            setProjectFinanceSettings(projectFinanceSettings)
          }
        })
        .catch((err) => {
          Logger.error(err)

          if (isCurrent()) {
            setIsLoading(false)
            setProjectFinanceSettingsError(new EcosuiteComponentError(err))
          }
        })
    },
    [code],
  )

  // If an error occurred, show it.
  if (projectFinanceSettingsError) {
    return <Error error={projectFinanceSettingsError.getError()} />
  } else if (isLoading) {
    return <Loading message={t("loadingMsg.ganttChart")} />
  } else if (projectFinanceSettings) {
    // If there are settings associated with this project, show the Gantt Chart.
    if (projectFinanceSettings.smartsheetSheetUrl) {
      return (
        <iframe src={`${projectFinanceSettings.smartsheetSheetUrl}`} width="100%" height="100%">
          {t("headings.view_gantt_chart")} {projectFinanceSettings.smartsheetSheetUrl}
        </iframe>
      )
    }
  }

  // Display a message if there is no URL associated with this project.
  return <Message message={t("messages.no_associated_smartsheet_sheet")} />
}

export default SmartsheetSheetView
