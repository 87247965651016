import React from "react"
import EcosuiteComponent from "@common/EcosuiteComponent"
import moment from "moment-timezone"
import { create, all } from "mathjs"

import Aggregations from "@common/Aggregations"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import ProjectUtils from "@common/utils/ProjectUtils"
import { Label, ReferenceLine } from "recharts"
import i18n from "src/i18n"

const math = create(all)
const { t } = i18n

var d3_scale = require("d3-scale")
var d3_time = require("d3-time")

export default class DateRangeGraph extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.zoomDateRange = this.zoomDateRange.bind(this)
    this.renderAssetEvents = this.renderAssetEvents.bind(this)
  }

  round(value) {
    if (value) {
      return math.round(math.divide(value, 1000), 3)
    } else {
      return 0
    }
  }

  zoomDateRange(selection) {
    if (selection && this.props.aggregation && this.props.aggregation !== this.getMinimumAggregation()) {
      let milliseconds = selection.activeLabel
      if (typeof milliseconds === "string") {
        milliseconds = moment(milliseconds).valueOf() // get the milliseconds from the date
      }
      if (milliseconds > 0) {
        const zoomRange = DateRangeUtils.getDateRange(moment(milliseconds), this.props.aggregation)
        this.props.selectRange("custom", zoomRange)
      }
    }
  }

  getMinimumAggregation() {
    return Aggregations.MINIMUM
  }

  /**
   * Calculate the ticks for the x axis data
   * @param {*} data The list of datums being graphed
   * @param {*} range The local date range provided with the datums
   * @param {*} aggregation The aggregation provided with the datums
   */
  getTicks(data, range, aggregation) {
    if (!data || !data.length) {
      return []
    }

    const startDate = moment(range.localStartDate)
    const endDate = moment(range.localEndDate).subtract(
      Aggregations.getSize(aggregation),
      Aggregations.getUnits(aggregation),
    )

    const scale = d3_scale.scaleTime().domain([startDate, endDate]).range([0, 1])

    let ticks = null
    if (Aggregations.isMinuteLevel(aggregation)) {
      ticks = scale.ticks(d3_time.timeMinute, 1)
    } else if (aggregation === Aggregations.Day) {
      ticks = scale.ticks(d3_time.timeDay, 1)
    } else {
      ticks = scale.ticks(d3_time.timeHour, 3)
    }

    return ticks.map((entry) => +entry)
  }

  /**
   * Gets the domain for the supplied range to control the x axis
   * @param {*} range  The local date range provided with the datums
   * @return {[AxisDomain,AxisDomain]}
   */
  getDomain(range, aggregation) {
    return [
      moment(range.localStartDate).valueOf(),
      moment(range.localEndDate)
        .subtract(Aggregations.getSize(aggregation), Aggregations.getUnits(aggregation))
        .valueOf(),
    ]
  }

  renderUserAlerts() {
    if (this.props.userAlerts && this.props.project) {
      const path = ProjectUtils.getPath(this.props.project, this.props.site, this.props.system)

      return this.props.userAlerts
        .filter((alert) => alert.message.startsWith(path) || (alert.sourceId && alert.sourceId.startsWith(path)))
        .map((alert, idx) => {
          return (
            <ReferenceLine
              key={"user-alert-" + idx}
              x={moment(alert.localTimestamp).valueOf()}
              stroke="white"
              label={{ position: "right", value: alert.message, fill: "white" }}
            />
          )
        })
    }
  }

  renderAssetEvents() {
    const assetEvents = []
    const project = this.props.project
    if (project) {
      Object.values(project.sites).forEach((site) => {
        if (site.startDate) {
          assetEvents.push(
            <ReferenceLine
              key={`${ProjectUtils.getPath(project, site)}`}
              x={ProjectUtils.projectMoment(project, site.startDate).toDate().getTime()}
              stroke="white"
              color="white"
              label={
                <Label
                  value={`${ProjectUtils.getPath(project, site)} ${t("energy.production_started")}`}
                  fill={"white"}
                  position="right"
                />
              }
            />,
          )
        }
        if (site.rise !== undefined) {
          assetEvents.push(this.renderDaylightMarkers(site))
        }
        Object.values(site.systems).map((system) => {
          if (system.startDate) {
            assetEvents.push(
              <ReferenceLine
                key={`${ProjectUtils.getPath(project, site, system)}`}
                x={ProjectUtils.projectMoment(project, system.startDate).toDate().getTime()}
                stroke="white"
                color="white"
                label={
                  <Label
                    value={`${ProjectUtils.getPath(project, site, system)} ${t("energy.production_started")}`}
                    fill={"white"}
                    position="right"
                  />
                }
              />,
            )
          }
        })
      })
      return assetEvents
    } else {
      return null
    }
  }

  renderDaylightMarkers(site) {
    let rise = moment("2022-09-13T07:00").toDate().getTime()
    return (
      <>
        <ReferenceLine x={rise} fill="red" label="Rise" />
        <ReferenceLine x={site.set} fill="red" label="Set" />
      </>
    )
  }

  renderCurrentTime() {
    const project = this.props.project

    if (project) {
      const localMoment = moment()
      const nyMoment = moment().tz(project["timezone"])
      const offsetMs = localMoment.utcOffset() * 60000 - nyMoment.utcOffset() * 60000

      return <ReferenceLine x={Date.now() - offsetMs} label={<Label value="now" fill="white" position="right" />} />
    } else {
      return null
    }
  }
}
