import React from "react"
import PropTypes from "prop-types"

import EcosuiteComponent from "@common/EcosuiteComponent"
import Utils from "@common/utils/Utils"

import EnergyUtils from "../EnergyUtils"
import GraphUtils from "@common/utils/GraphUtils"
import i18n from "src/i18n"
import { cloneDeep } from "lodash"

const { t } = i18n

const applyConfig = (payload, config) => {
  if (!config) return payload
  return payload.map((p) => {
    const hasConfigKey = config[p.dataKey]
    if (!hasConfigKey) return p

    let payloadItem = cloneDeep(p)

    if (config[p.dataKey].override) {
      payloadItem = config[p.dataKey].override(p, payload)
    }

    return payloadItem
  })
}

export class EnergyGraphTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label, config } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {applyConfig(payload, config).map((data) => {
              if (data.hide) return null
              if (
                data.dataKey === "consumptionCost" ||
                data.dataKey === "fixedCost" ||
                data.dataKey === "meteredCost" ||
                data.dataKey === "demandCost" ||
                data.dataKey === "demandCharge" ||
                data.dataKey === "peakDemandCharge"
              ) {
                return (
                  <p key={data.dataKey} className="label" style={{ color: data.stroke }}>
                    {`${this.props.getDataTitle(data.name)} : ${Utils.formatCurrency(data.value ? data.value : 0)}`}
                  </p>
                )
              }
              if (data.dataKey === "difference") {
                return (
                  <p key={data.dataKey} className="label" style={{ color: data.stroke }}>
                    {`${this.props.getDataTitle(data.name)} : ${data.value ? (data.value >= 0 ? "+" : "") + Math.round(data.value) + "%" : " -"}`}
                  </p>
                )
              }
              return (
                <React.Fragment key={data.dataKey}>
                  <p className="label" style={{ color: data.stroke }}>
                    {`${this.props.getDataTitle(data.name)} : ${EnergyUtils.formatKiloWattHours(data.value ? data.value : 0)}`}
                  </p>

                  {this.getMaxReading(data)}
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }
    return null
  }

  getMaxReading(data) {
    if (typeof data.payload.max === "number") {
      let max = data.payload.max[data.dataKey]
      if (max && this.props.assets && this.props.assets.length > 1) {
        return (
          <p key={data.dataKey + "-most"} className="label" style={{ color: data.stroke }}>
            {`${t("energy.largest_contribution")}: ${max.asset} (${EnergyUtils.formatWattHoursAsKiloWattHours(max.watts ? max.watts : 0)})`}
          </p>
        )
      }
    }
    return null
  }
}
