import { create, all } from "mathjs"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import ProjectUtils from "@common/utils/ProjectUtils"
import FinanceUtils from "@dashboard/finance/FinanceUtils"
import Utils from "@common/utils/Utils"
import ProFormaUtils from "./ProFormaUtils"

const math = create(all)

const InputsDownload = {
  addProFormaInputsWorksheet(workbook, project, proForma, proFormaIrr, report, reportTotals) {
    const worksheet = workbook.addWorksheet("Pro Forma Inputs", { headerFooter: { firstHeader: "Ecosuite Pro Forma Inputs for: " + project.companyName } })
    const [, version] = proForma.id.split("/")

    // Set up the sheet headers
    worksheet.getRow(1).font = { bold: true }
    worksheet.getRow(1).height = 20
    worksheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" }

    worksheet.mergeCells(1, 1, 1, 3)
    worksheet.getColumn(1).width = 30
    worksheet.getColumn(2).width = 20
    worksheet.getColumn(3).width = 20
    worksheet.getCell(1, 1).font = { bold: true, color: { argb: "FFFFFFFF" } }
    worksheet.getCell(1, 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(1, 1).value = `${project.name} ${version ? `(${version})` : ""}`

    worksheet.mergeCells(1, 5, 1, 7)
    worksheet.getColumn(5).width = 30
    worksheet.getColumn(6).width = 20
    worksheet.getColumn(7).width = 20
    worksheet.getCell(1, 5).font = { bold: true, color: { argb: "FFFFFFFF" } }
    worksheet.getCell(1, 5).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(1, 5).value = `Forecast Project Chart of Accounts for ${project.name}`

    this.addProFormaInputs(worksheet, project, proForma, report, reportTotals, proFormaIrr)

    this.addChartOfAccounts(worksheet, project, proForma, report, reportTotals)
  },

  addProFormaInputs(worksheet, project, proForma, report, reportTotals, proFormaIrr) {
    const types = new Set()
    Object.values(project.sites).forEach((site) => {
      Object.values(site.systems).forEach((system) => {
        if (system.subType) {
          types.add(system.subType)
        }
      })
    })
    const systemTypes = Array.from(types).sort()

    const incomeCashFlows = proForma.cashFlows.filter((cashFlow) => cashFlow.category === "Income")
    const generationCashFlows = incomeCashFlows.filter((cashFlow) => cashFlow.account === "PPA/FIT" || cashFlow.account === "SREC Revenue" || cashFlow.account.startsWith("Community Solar"))
    const grantCashFlows = incomeCashFlows.filter((cashFlow) => cashFlow.account.startsWith("Grant"))

    const expenseCashFlows = proForma.cashFlows.filter((cashFlow) => cashFlow.category === "Expense")

    var row = 2

    worksheet.mergeCells(row, 1, row, 3)
    worksheet.getCell(row, row).font = { color: { argb: "FFFFFFFF" } }
    worksheet.getCell(row, 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(row, 1).alignment = { vertical: "middle", horizontal: "center" }
    worksheet.getCell(row, 1).value = project.address
    row++

    worksheet.getCell(row, 1).alignment = { indent: 1 }
    worksheet.getCell(row, 1).value = "Unlevered IRR"
    worksheet.getCell(row, 2).alignment = { horizontal: "right" }
    worksheet.getCell(row, 2).value = proFormaIrr.unleveredIrr ? proFormaIrr.unleveredIrr / 100 : "-"
    worksheet.getCell(row, 2).numFmt = "0.00%"
    row++

    worksheet.getCell(row, 1).alignment = { indent: 1 }
    worksheet.getCell(row, 1).value = "State / Province"
    worksheet.getCell(row, 2).alignment = { horizontal: "right" }
    worksheet.getCell(row, 2).value = project.state
    row++

    worksheet.getCell(row, 1).alignment = { indent: 1 }
    worksheet.getCell(row, 1).value = "PV System Size"
    worksheet.getCell(row, 2).alignment = { horizontal: "right" }
    worksheet.getCell(row, 2).value = `${proForma.systemSize} kW DC`
    row++

    worksheet.getCell(row, 1).alignment = { indent: 1 }
    worksheet.getCell(row, 1).value = "Annual Production"
    worksheet.getCell(row, 2).alignment = { horizontal: "right" }
    worksheet.getCell(row, 2).value = `${proForma.systemProduction} kWh/kWp`
    worksheet.getCell(row, 3).alignment = { horizontal: "right" }
    worksheet.getCell(row, 3).value = `${EnergyUtils.formatNumber(math.multiply(proForma.systemSize, proForma.systemProduction))} kWh`
    row++

    worksheet.getCell(row, 1).alignment = { indent: 1 }
    worksheet.getCell(row, 1).value = "Annual Degradation"
    worksheet.getCell(row, 2).alignment = { horizontal: "right" }
    worksheet.getCell(row, 2).value = proForma.degradation / 100
    worksheet.getCell(row, 2).numFmt = "0.00%"
    row++

    worksheet.getCell(row, 1).alignment = { indent: 1 }
    worksheet.getCell(row, 1).value = "System Type"
    worksheet.getCell(row, 2).alignment = { horizontal: "right" }
    worksheet.getCell(row, 2).value = `${systemTypes.map((systemType) => systemType.replace(/^\w/, (c) => c.toUpperCase())).join(systemTypes.length > 1 ? ", " : "")}`
    row++

    worksheet.getCell(row, 1).alignment = { indent: 1 }
    worksheet.getCell(row, 1).value = "PTO/COD Date"
    worksheet.getCell(row, 2).alignment = { horizontal: "right" }
    worksheet.getCell(row, 2).value = `${ProFormaUtils.getPTO_CODDate(project, proForma).format("MM/DD/YYYY")}`
    worksheet.getCell(row, 2).numFmt = "mm/dd/yyyy"
    row++

    worksheet.getCell(row, 1).alignment = { indent: 1 }
    worksheet.getCell(row, 1).value = "Project Life"
    worksheet.getCell(row, 2).alignment = { horizontal: "right" }
    worksheet.getCell(row, 2).value = `${proForma.projectLife} Years`
    row++

    worksheet.getCell(row, 1).font = { bold: true }
    worksheet.getCell(row, 1).value = "Operational Revenues"
    row++

    generationCashFlows.forEach((generationCashFlow) => {
      row = this.addGenerationPayments(worksheet, row, project, generationCashFlow)
    })
    grantCashFlows.forEach((grantCashFlow) => {
      row = this.addScheduledPayments(worksheet, row, project, grantCashFlow)
    })

    worksheet.getCell(row, 1).font = { bold: true }
    worksheet.getCell(row, 1).value = "Operational Expenses"
    row++

    expenseCashFlows.forEach((expenseCashFlow) => {
      row = this.addExpense(worksheet, row, project, expenseCashFlow)
    })
  },

  addGenerationPayments(worksheet, row, project, cashFlow) {
    const payments = cashFlow.payments.filter((payment) => FinanceUtils.isOperatingRevenuePayment(cashFlow, payment) && (payment.recurrence.rateType !== "fixed" || payment.recurrence.startRate))

    if (payments.length) {
      worksheet.getCell(row, 1).alignment = { indent: 1 }
      worksheet.getCell(row, 1).value = `${cashFlow.name}`
      row++

      payments.forEach((payment) => {
        worksheet.getCell(row, 1).alignment = { indent: 2 }
        worksheet.getCell(row, 1).value = `${Utils.capitalizeFirstLetter(payment.paymentType)}`
        row++

        row = this.addRate(worksheet, row, payment)

        worksheet.getCell(row, 1).alignment = { indent: 3 }
        worksheet.getCell(row, 1).value = `Start`
        worksheet.getCell(row, 2).alignment = { horizontal: "right" }
        worksheet.getCell(row, 2).value = `${ProjectUtils.projectMoment(project, payment.recurrence.startDate).format("MM/DD/YYYY")}`
        worksheet.getCell(row, 2).numFmt = "mm/dd/yyyy"
        row++

        worksheet.getCell(row, 1).alignment = { indent: 3 }
        worksheet.getCell(row, 1).value = `Term`
        worksheet.getCell(row, 2).value = payment.recurrence.term
        row++
      })
    }

    return row
  },

  addRate(worksheet, row, payment) {
    if (payment.recurrence.rateType === "fixed") {
      worksheet.getCell(row, 1).alignment = { indent: 3 }
      worksheet.getCell(row, 1).value = `Rate${ProFormaUtils.getPaymentUnit(payment)}`
      worksheet.getCell(row, 2).numFmt = payment.paymentType === "recurring" ? "$#,##0.00" : "$#,##0.000"
      worksheet.getCell(row, 2).value = payment.recurrence.startRate
      row++

      worksheet.getCell(row, 1).alignment = { indent: 3 }
      worksheet.getCell(row, 1).value = `Frequency`
      worksheet.getCell(row, 2).alignment = { horizontal: "right" }
      worksheet.getCell(row, 2).value = Utils.capitalizeFirstLetter(payment.recurrence.frequency)
      row++

      worksheet.getCell(row, 1).alignment = { indent: 3 }
      worksheet.getCell(row, 1).value = `Escalator`
      worksheet.getCell(row, 2).value = payment.recurrence.escalator ? payment.recurrence.escalator / 100 : 0
      worksheet.getCell(row, 2).numFmt = "0.00%"
      row++
    } else if (payment.recurrence.rateType === "variable") {
      worksheet.getCell(row, 1).alignment = { indent: 3 }
      worksheet.getCell(row, 1).value = `Year Rates${ProFormaUtils.getPaymentUnit(payment)}`
      worksheet.getCell(row, 2).value = `${payment.recurrence.rates.map((rate) => `$${rate}`).join(", ")}`
      row++
    }
    return row
  },

  addScheduledPayments(worksheet, row, project, cashFlow) {
    const payments = cashFlow.payments.filter((payment) => payment.paymentType === "scheduled" && ProFormaUtils.getTotalScheduledPayments(payment))

    if (payments.length) {
      worksheet.getCell(row, 1).alignment = { indent: 1 }
      worksheet.getCell(row, 1).value = `${cashFlow.name}`
      row++

      payments.forEach((payment) => {
        worksheet.getCell(row, 1).alignment = { indent: 2 }
        worksheet.getCell(row, 1).value = `${Utils.capitalizeFirstLetter(payment.paymentType)}`
        worksheet.getCell(row, 2).numFmt = "$#,##0.00"
        worksheet.getCell(row, 2).value = ProFormaUtils.getTotalScheduledPayments(payment)
        row++
      })
    }

    return row
  },

  addExpense(worksheet, row, project, cashFlow) {
    if (cashFlow.payments.length) {
      worksheet.getCell(row, 1).alignment = { indent: 1 }
      worksheet.getCell(row, 1).value = `${cashFlow.name}`
      row++

      cashFlow.payments.forEach((payment) => {
        switch (payment.paymentType) {
          case "size":
          case "generation":
          case "recurring":
            worksheet.getCell(row, 1).alignment = { indent: 2 }
            worksheet.getCell(row, 1).value = `${Utils.capitalizeFirstLetter(payment.paymentType)}`
            row++

            row = this.addRate(worksheet, row, payment)

            worksheet.getCell(row, 1).alignment = { indent: 3 }
            worksheet.getCell(row, 1).value = `Start`
            worksheet.getCell(row, 2).alignment = { horizontal: "right" }
            worksheet.getCell(row, 2).value = `${ProjectUtils.projectMoment(project, payment.recurrence.startDate).format("MM/DD/YYYY")}`
            worksheet.getCell(row, 2).numFmt = "mm/dd/yyyy"
            row++

            worksheet.getCell(row, 1).alignment = { indent: 3 }
            worksheet.getCell(row, 1).value = `Term`
            worksheet.getCell(row, 2).value = payment.recurrence.term
            row++

            break

          case "scheduled":
            worksheet.getCell(row, 1).alignment = { indent: 2 }
            worksheet.getCell(row, 1).value = `${Utils.capitalizeFirstLetter(payment.paymentType)}`
            worksheet.getCell(row, 2).numFmt = "$#,##0.00"
            worksheet.getCell(row, 2).value = ProFormaUtils.getTotalScheduledPayments(payment)
            row++

            break
          default:
            break
        }
      })
    }
    return row
  },

  addChartOfAccounts(worksheet, project, proForma, report, reportTotals) {
    const fixedAssets = reportTotals.lifetimeTotals.forecast.categories["Fixed Asset"]
    const dcSizeWatts = proForma.systemSize * 1000
    var row = 2
    const col = 5

    worksheet.getCell(row, col).font = { bold: true, color: { argb: "FFFFFFFF" } }
    worksheet.getCell(row, col).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(row, col).value = "Account"
    worksheet.getCell(row, col + 1).font = { bold: true, color: { argb: "FFFFFFFF" } }
    worksheet.getCell(row, col + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(row, col + 1).value = "Amount $"
    worksheet.getCell(row, col + 2).font = { bold: true, color: { argb: "FFFFFFFF" } }
    worksheet.getCell(row, col + 2).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(row, col + 2).value = "$/W DC"
    row++

    Object.values(fixedAssets.categories).forEach((account) => {
      worksheet.getCell(row, col).font = { bold: true }
      worksheet.getCell(row, col).value = account.name
      worksheet.getCell(row, col + 1).font = { bold: true }
      worksheet.getCell(row, col + 1).numFmt = "$#,##0.00"
      worksheet.getCell(row, col + 1).value = Math.abs(account.totals)
      worksheet.getCell(row, col + 2).font = { bold: true }
      worksheet.getCell(row, col + 2).numFmt = "$#,##0.00"
      worksheet.getCell(row, col + 2).value = Math.abs(account.totals / dcSizeWatts)
      row++

      Object.values(account.categories).map((subAccount) => {
        worksheet.getCell(row, col).alignment = { indent: 1 }
        worksheet.getCell(row, col).value = subAccount.name
        worksheet.getCell(row, col + 1).numFmt = "$#,##0.00"
        worksheet.getCell(row, col + 1).value = Math.abs(subAccount.totals)
        worksheet.getCell(row, col + 2).numFmt = "$#,##0.00"
        worksheet.getCell(row, col + 2).value = Math.abs(subAccount.totals / dcSizeWatts)
        row++
      })
    })

    worksheet.getCell(row, col).font = { bold: true, color: { argb: "FFFFFFFF" } }
    worksheet.getCell(row, col).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(row, col).value = "Total"
    worksheet.getCell(row, col + 1).font = { bold: true, color: { argb: "FFFFFFFF" } }
    worksheet.getCell(row, col + 1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(row, col + 1).numFmt = "$#,##0.00"
    worksheet.getCell(row, col + 1).value = Math.abs(fixedAssets.totals)
    worksheet.getCell(row, col + 2).font = { bold: true, color: { argb: "FFFFFFFF" } }
    worksheet.getCell(row, col + 2).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FF4bb3d8" } }
    worksheet.getCell(row, col + 2).numFmt = "$#,##0.00"
    worksheet.getCell(row, col + 2).value = Math.abs(fixedAssets.totals / dcSizeWatts)
  },
}

export default InputsDownload
