import { createPluginFactory } from "@udecode/plate-core"
import { S3ImagePlugin } from "./types"
import { withS3ImageUpload } from "./withImageUpload"

export const ELEMENT_IMAGE = "img"

/**
 * Enables support for images.
 */
export const createS3ImagePlugin = createPluginFactory<S3ImagePlugin>({
  key: ELEMENT_IMAGE,
  isElement: true,
  isVoid: true,
  withOverrides: withS3ImageUpload,
  then: (editor, { type }) => ({
    deserializeHtml: {
      rules: [
        {
          validNodeName: "IMG",
        },
      ],
      getNode: (el) => ({
        type,
        url: el.getAttribute("src"),
      }),
    },
  }),
})
