import React from "react"
import moment from "moment-timezone"
import { Input, Label } from "reactstrap"
import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from "recharts"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"

import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import EnergyService from "@dashboard/energy/EnergyService"
import { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import Logger from "@common/Logger"
import i18n from "src/i18n"

const { t } = i18n
export default class SourceCumulativeGraph extends DateRangeGraph {
  constructor(props) {
    super(props, "source-cumulative-graph")

    this.state.cumulationOption = "year"

    this.selectCumulationOption = this.selectCumulationOption.bind(this)
    this.selectSource = this.selectSource.bind(this)
    this.loadSourceCumulativeDatums = this.loadSourceCumulativeDatums.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadSourceCumulativeDatums()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadSourceCumulativeDatums()
    }
  }

  loadSourceCumulativeDatums() {
    // Logger.debug(`Loading source cumulative datums for range: ${range.start.format("lll")} ${range.end.format("lll")} with aggregate: ${aggregate}`)
    const cumulationOption = this.state.cumulationOption
    const cumulation = cumulationOption === "year" ? cumulationOption : "month"
    const cumulationDate = cumulation === "month" ? cumulationOption : null

    this.setStateIfMounted({ datums: null, range: null, aggregation: null })
    const projectId = this.props.project.code
    EnergyService.getProjectCumulativeDatums(projectId, cumulation, cumulationDate)
      .then((response) => {
        if (this.isProjectCurrent(projectId) && cumulationOption === this.state.cumulationOption) {
          if (response.sourceIds && response.sourceIds.length) {
            response.sourceId = response.sourceIds[0] // Select the first source
          }
          response.cumulationOption = response.cumulationDate ? response.cumulationDate : "year"
          this.setStateIfMounted(response)
        } else {
          Logger.debug(`Ignoring out of date response for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId) && cumulationOption === this.state.cumulationOption) {
          this.setStateIfMounted({
            datums: new EcosuiteComponentError(err),
          })
        }
      })
  }

  selectCumulationOption(cumulationOption) {
    this.setStateIfMounted({ cumulationOption: cumulationOption }, this.loadSourceCumulativeDatums)
  }

  selectSource(sourceId) {
    this.setStateIfMounted({ sourceId: sourceId })
  }

  zoomDateRange() {
    // Do nothing, we don't support zooming
  }

  renderContent() {
    if (this.state.datums) {
      return (
        <div>
          <div className="cumulative-graph-controls">
            <div className="inline-graph-controls">
              <Label for="cumulation">{t("labels.cumulate")}: </Label>
              <Input
                id="cumulation"
                type="select"
                value={this.state.cumulationOption}
                onChange={(e) => {
                  this.selectCumulationOption(e.target.value)
                }}
              >
                <option value="year">{t("months.Year")}</option>
                <option value="01">{t("months.January")}</option>
                <option value="02">{t("months.February")}</option>
                <option value="03">{t("months.March")}</option>
                <option value="04">{t("months.April")}</option>
                <option value="05">{t("months.May")}</option>
                <option value="06">{t("months.June")}</option>
                <option value="07">{t("months.July")}</option>
                <option value="08">{t("months.August")}</option>
                <option value="09">{t("months.September")}</option>
                <option value="10">{t("months.October")}</option>
                <option value="11">{t("months.November")}</option>
                <option value="12">{t("months.December")}</option>
              </Input>
              {this.state.sourceIds ? (
                <React.Fragment>
                  <Label>{t("labels.source")}:</Label>
                  <Input
                    type="select"
                    value={this.state.sourceId}
                    onChange={(e) => {
                      this.selectSource(e.target.value)
                    }}
                  >
                    {this.state.sourceIds.sort().map((sourceId) => {
                      return (
                        <option key={sourceId} value={sourceId}>
                          {sourceId}
                        </option>
                      )
                    })}
                  </Input>
                </React.Fragment>
              ) : null}
            </div>
          </div>

          {this.isContentError(this.state.datums) ? <Error error={this.state.datums.getError()} /> : <div>{this.renderGraph()}</div>}
        </div>
      )
    } else {
      // We style the height the same as the graph so that content doesn't about when we're loading
      return (
        <div style={{ height: "500px" }}>
          <Loading message={t("loadingMsg.cumulating_datums")} />
        </div>
      )
    }
  }

  getDataName(sourceDatum) {
    switch (this.state.aggregation) {
      case "month":
        return moment(sourceDatum[this.state.aggregation], "MM").format("MMM")
      case "day":
        return moment(sourceDatum[this.state.aggregation], "DD").format("DD")
      default:
        throw new Error(`${t("energy.errorMsgs.unsupported_aggregation")}: ${this.state.aggregation}`)
    }
  }

  renderGraph() {
    const yearsSet = new Set()
    const sourceData = {}

    this.state.datums.forEach((datum) => {
      yearsSet.add(datum.year)
      if (datum.sources[this.state.sourceId]) {
        datum.sources[this.state.sourceId].forEach((sourceDatum) => {
          const dataName = this.getDataName(sourceDatum)
          if (!sourceData[dataName]) {
            sourceData[dataName] = { name: dataName, unit: sourceDatum[this.state.aggregation] }
          }

          sourceData[dataName][datum.year] = EnergyUtils.convertToKilo(sourceDatum.cumulativeWattHours)
        })
      }
    })

    const data = Object.values(sourceData).sort((a, b) => a.unit - b.unit)
    const years = [...yearsSet]

    return (
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={data} margin={{ top: 5, right: 50, left: 50, bottom: 10 }}>
          <XAxis dataKey="name" />
          <YAxis unit="kWh" />
          <Legend
            iconType="plainline"
            onClick={(e) => {
              const sourceId = e.dataKey
              // Toggle the source's visibility
              this.setState({ visibility: { ...this.state.visibility, [sourceId]: !this.state.visibility[sourceId] } })
            }}
            formatter={(sourceId) => {
              return <span style={{ cursor: "pointer", userSelect: "none", opacity: 1 }}>{sourceId}</span>
            }}
          />
          <Tooltip />
          {years.map((year, idx) => {
            const color = GRAPH_COLORS[idx % years.length]
            return <Line key={idx} type="monotone" dataKey={year} stroke={color} />
          })}
        </LineChart>
      </ResponsiveContainer>
    )
  }
}
