import { Auth } from "aws-amplify"
import aws from "aws-sdk"

var MediaService = {
  async storeFile(path, file) {
    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Body: file,
        Bucket: process.env.REACT_APP_ASSETS_BUCKET,
        Key: path,
        ContentType: file.type,
      }

      return s3
        .putObject(params)
        .promise()
        .then(() => {
          return `https://${process.env.REACT_APP_ASSETS_BUCKET}.s3.amazonaws.com/${path}`
        })
    })
  },
}

export default MediaService
