import moment from "moment"
import React from "react"
import { Alert, Button, Label } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"

import UserAdminService from "./UserAdminService"
import i18n from "src/i18n"

const { t } = i18n
class UserGroups extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.resetMfa = this.resetMfa.bind(this)
    this.resend = this.resend.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadMfaType()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.user !== prevProps.user) {
      this.loadMfaType()
    }
  }

  loadMfaType() {
    this.setStateIfMounted({ mfaType: null, error: null, message: null })
    if (this.props.user) {
      UserAdminService.getMfa(this.props.user)
        .then((data) => {
          this.setStateIfMounted({ mfaType: data.mfa })
        })
        .catch((err) => {
          this.setStateIfMounted({ error: err })
        })
    }
  }

  resetMfa() {
    this.setStateIfMounted({ message: `${t("settings.labels.resetting_MFA")}`, error: null })
    UserAdminService.resetMfa(this.props.user)
      .then((data) => {
        this.setStateIfMounted({ mfaType: data.mfa, message: `${t("settings.labels.MFA_reset")}` })
      })
      .catch((err) => {
        this.setStateIfMounted({ error: err, message: null })
      })
  }

  resend() {
    this.setStateIfMounted({ confirmationMessage: `${t("settings.labels.resending_confirmation")}`, confirmationError: null })
    UserAdminService.resend(this.props.user)
      .then((response) => {
        Logger.debug(response)
        this.setStateIfMounted({ confirmationMessage: `${t("settings.labels.confirmation_request_resent")}` })
        this.props.userChanged(response.user)
      })
      .catch((err) => {
        this.setStateIfMounted({ confirmationError: err, confirmationMessage: null })
      })
  }

  renderContent() {
    return (
      <div>
        {this.renderConfirmation()}
        {this.renderMFA()}
      </div>
    )
  }

  renderMFA() {
    let error = this.getError()
    if ((this.props.user && this.state.mfaType) || error) {
      return (
        <div className="ecogy-form">
          <div>
            <Label>{t("settings.labels.MFA")}: </Label> {this.state.mfaType}
          </div>
          {this.state.message ? <Alert color="info">{this.state.message}</Alert> : null}
          {this.state.success ? <Alert color="success">{this.state.success}</Alert> : null}
          {error ? <Alert color="danger">{error}</Alert> : null}
          <div>
            {this.state.mfaType && this.state.mfaType !== "NOMFA" ? (
              <Button color="primary" onClick={this.resetMfa}>
                {t("buttons.reset")}
              </Button>
            ) : null}
          </div>
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderConfirmation() {
    const expired = this.isRegistrationExpired(this.props.user)
    let error = this.state && this.state.confirmationError ? (this.state.confirmationError.message ? this.state.confirmationError.message : this.state.confirmationError) : null
    if (this.props.user) {
      return (
        <div className="ecogy-form">
          <div>
            <Label>{t("table_headings.status")}: </Label> <span className={expired ? "user-registration-expired" : ""}>{expired ? "EXPIRED" : this.props.user.status}</span>
          </div>
          {this.state.confirmationMessage ? <Alert color="info">{this.state.confirmationMessage}</Alert> : null}
          {error ? <Alert color="danger">{error}</Alert> : null}
          <div>
            {this.props.user.status === "FORCE_CHANGE_PASSWORD" ? (
              <Button color="primary" onClick={this.resend}>
                {t("settings.labels.resend_confirmation")}
              </Button>
            ) : null}
          </div>
        </div>
      )
    } else {
      return <Loading />
    }
  }

  isRegistrationExpired(user) {
    return moment().isAfter(moment(user.updated).add(7, "days")) && user.status === "FORCE_CHANGE_PASSWORD"
  }

  isGroupSelected(group) {
    let userGroup = this.state.userGroups.find((userGroup) => {
      return userGroup.id === group.id
    })
    return userGroup !== undefined
  }

  getError() {
    if (this.state && this.state.error) {
      return this.state.error.message ? this.state.error.message : this.state.error
    }
    return null
  }
}

export default UserGroups
