import React, { useState } from "react"
import { getErrorMessage } from "@common/SharedComponentUtils"
import { Alert, Button, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"

const { t } = i18n
/**
 * The valid FeedbackBackdropDialog properties.
 */
export interface FeedbackBackdropDialogProps {
  title: string
  body?: JSX.Element

  inputTooltip?: string
  onConfirm?: (bool: boolean, input: unknown | undefined) => void
  onComplete?: () => void
  onCancel?: () => void

  inputPlaceholder?: string
  performText?: string
  performTextColor?: string

  inputValidator?: (value: unknown | undefined) => boolean
  onValidationErrorMessage?: string

  confirmText?: string
  confirmTooltip?: string
  cancelTooltip?: string

  displayText?: string
  displayTextColor?: string

  displayResult?: JSX.Element
  displayResultBgColor?: string

  isOpen?: boolean
  disabled?: boolean
}

export const FeedbackBackdropDialog = (props: FeedbackBackdropDialogProps): JSX.Element => {
  const defaultBool = true
  const defaultValue = undefined

  const [currentBool, setCurrentBool] = useState<boolean>(defaultBool)

  const [currentValue, setCurrentValue] = useState<string | undefined>(defaultValue)
  const [performingActionPlainText, setPerformingActionPlainText] = useState<string | undefined>("")

  const [errorResponse, setErrorResponse] = useState<string | undefined>(undefined)

  const boolId = "backdrop-dialog-bool"
  const inputId = "backdrop-dialog-input"
  const confirmId = "backdrop-dialog-confirm"
  const cancelId = "backdrop-dialog-cancel"

  /**
   * Display an error.
   * @param e - The error.
   */
  const displayError = (e: unknown) => {
    setErrorResponse(getErrorMessage(e))
  }

  /**
   * Check if an action is currently being performed.
   */
  const isAction = (): boolean => {
    return performingActionPlainText !== ""
  }

  /**
   * Clear any action being performed.
   */
  const clearAction = (): void => {
    setPerformingActionPlainText("")
  }

  /**
   * Clear all input.
   */
  const clearInput = (): void => {
    setCurrentBool(defaultBool)
    setCurrentValue(defaultValue)
  }

  /**
   * Check if currently disabled.
   *
   * This includes any current input.
   */
  const isDisabled = (): boolean => {
    if (isAction()) {
      return true
    }

    return props.disabled !== undefined && !props.disabled
  }

  return (
    <>
      <Modal isOpen={props.isOpen}>
        <ModalHeader>
          <p className={"backdrop-dialog-header"}>{props.title}</p>
        </ModalHeader>
        <ModalBody>
          {props.body}
          <Form>
            <FormGroup row>
              <Label for={boolId}>
                <Input id={boolId} type={"switch"} onChange={() => setCurrentBool(!currentBool)} defaultChecked={currentBool} /> {t("labels.send_anonymously")}
              </Label>
            </FormGroup>
            <FormGroup row>
              <Label for={inputId}>{t("header.userMenu.feedback")}</Label>
              {props.inputTooltip && <UncontrolledTooltip target={inputId}>{props.inputTooltip}</UncontrolledTooltip>}
              <Input
                id={inputId}
                type={"textarea"}
                disabled={isDisabled()}
                placeholder={props.inputPlaceholder}
                value={currentValue}
                onChange={(value) => setCurrentValue(value.target.value.toString())}
              />
            </FormGroup>
          </Form>
          {errorResponse && (
            <Alert color={"danger"} toggle={() => setErrorResponse(undefined)}>
              {errorResponse}
            </Alert>
          )}
        </ModalBody>
        {props.displayText && <Alert color={props.displayTextColor ?? "info"}>{props.displayText}</Alert>}
        {performingActionPlainText && <Alert color={props.performTextColor ?? "warning"}>{performingActionPlainText}</Alert>}
        {props.displayResult && <Alert color={props.displayResultBgColor}>{props.displayResult}</Alert>}
        <ModalFooter>
          <InputGroup>
            <UncontrolledTooltip target={confirmId}>{props.confirmTooltip ?? "Confirm"}</UncontrolledTooltip>
            <Button
              id={confirmId}
              disabled={isDisabled()}
              color={"primary"}
              onClick={async () => {
                setErrorResponse(undefined)

                // See if the value passes validation.
                if (props.inputValidator) {
                  try {
                    const valid = props.inputValidator.call(this, currentValue)
                    if (!valid) {
                      displayError(props.onValidationErrorMessage ?? `${t("errors.input_failed_validation")}`)
                      return
                    }
                  } catch (e) {
                    clearAction()
                    displayError(e)
                  }
                }

                // Display the text associated with performing some action.
                setPerformingActionPlainText(props.performText)
                try {
                  await props.onConfirm?.call(this, currentBool, currentValue)

                  clearAction()
                  clearInput()
                  await props.onComplete?.call(this)
                } catch (e) {
                  clearAction()
                  displayError(e)
                }
              }}
            >
              {props.confirmText ?? `${t("buttons.confirm")}`}
            </Button>
            <UncontrolledTooltip target={cancelId}>{props.cancelTooltip ?? `${t("buttons.cancel")}`}</UncontrolledTooltip>
            <Button
              id={cancelId}
              disabled={isDisabled()}
              className={"backdrop-dialog-cancel"}
              color={"danger"}
              onClick={async () => {
                try {
                  await props.onCancel?.call(this)
                } catch (e) {
                  clearAction()
                  displayError(e)
                }
              }}
            >
              {t("buttons.cancel")}
            </Button>
          </InputGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default FeedbackBackdropDialog
