import React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import Table from '@common/display/Table'
import { useGetChecklistAudit } from 'src/services/checklist'

interface Diff {
  kind: 'E' | 'N' | 'D'
  path: string[]
  lhs: string | number | boolean | null
  rhs: string | number | boolean | null
}

interface FormattedDiff {
  property: string
  originalValue: string
  newValue: string
}

interface AuditTableProps {
  projectId: string
}

const AuditTable: React.FC<AuditTableProps> = (props) => {
  const { projectId } = props
  const { data, isLoading, isError } = useGetChecklistAudit(projectId)

  const formatDiffs = React.useCallback((diffsString: string): FormattedDiff[] => {
    try {
      const diffs: Diff[] = JSON.parse(diffsString)
      return diffs
        .filter(diff => {
          const property = diff.path.join('.')
          return !property.includes('lastUpdatedDateTime') && !property.includes('version') && !property.includes('updatedBy') && !property.includes('updatedByName')
        })
        .map((diff) => ({
          property: diff.path.join('.'),
          originalValue: String(diff.lhs),
          newValue: String(diff.rhs),
        }))
    } catch (e) {
      return []
    }
  }, [])

  const tableData = React.useMemo(() => {
    if (!data) return []
    return data.map((record, index) => ({
      id: index,
      type: record.assetType,
      project: record.project,
      name: record.userName,
      userId: record.userId,
      timestamp: moment(record.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      diffs: formatDiffs(record.diffs),
    }))
  }, [data, formatDiffs])

  const columns: ColumnDef<typeof tableData[0]>[] = [
    {
      accessorKey: 'type',
      header: 'Type',
      size: 40,
    },
    {
      accessorKey: 'project',
      header: 'ID (Project)',
      size: 40,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      size: 60,
    },
    {
      accessorKey: 'timestamp',
      header: 'Timestamp',
      size: 80,
    },
    {
      accessorKey: 'diffs',
      header: 'Changes',
      size: 300,
      cell: ({ getValue }) => {
        const diffs = getValue() as FormattedDiff[]
        return (
          <div className='tw-w-full'>
            <div className='tw-grid tw-grid-cols-3 tw-gap-2 tw-mb-2 tw-font-semibold tw-text-sm tw-border-b tw-pb-1'>
              <div>Property</div>
              <div>Original Value</div>
              <div>New Value</div>
            </div>
            <div className='tw-space-y-2'>
              {diffs.map((diff, index) => (
                <div key={index} className='tw-grid tw-grid-cols-3 tw-gap-2 tw-py-1 tw-border-b tw-border-gray-100'>
                  <div className='tw-text-sm tw-truncate' title={diff.property}>
                    {diff.property}
                  </div>
                  <div className='tw-text-sm tw-text-red-500 tw-truncate' title={diff.originalValue}>
                    {diff.originalValue}
                  </div>
                  <div className='tw-text-sm tw-text-green-500 tw-truncate' title={diff.newValue}>
                    {diff.newValue}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      },
    },
  ]

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error loading audit data</div>
  }

  return (
    <div className='Module'>
      <div className='module-content' style={{ width: '100%' }}>
        <div className='content' style={{ width: '100%' }}>
          <div className='finance-table' style={{ width: '100%', height: 'calc(100vh - 120px)' }}>
            <Table
              data={tableData}
              columns={columns}
              defaultColumn={{}}
              initialState={{
                sorting: [{ id: 'timestamp', desc: true }],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuditTable
