import moment from "moment"
import { add } from "mathjs"

import ProjectUtils from "@common/utils/ProjectUtils"

export default {
  isVersion(proForma) {
    return proForma && proForma.id && proForma.id.indexOf("/") > 0
  },

  getPTO_CODDate(project, proForma) {
    const pto_codDate = proForma.cashFlows.reduce((pto_codDate, cashFlows) => {
      const cashFlowDate = cashFlows.payments.reduce((cashFlowPTO_CDODate, payment) => {
        if (payment.paymentType === "generation") {
          const paymentStartDate = moment(payment.recurrence.startDate)
          if (!cashFlowPTO_CDODate) {
            return paymentStartDate
          } else {
            if (paymentStartDate.isBefore(cashFlowPTO_CDODate)) {
              return paymentStartDate
            }
          }
          return cashFlowPTO_CDODate
        }
      }, null)

      if (!pto_codDate) {
        return cashFlowDate
      } else {
        if (cashFlowDate && cashFlowDate.isBefore(pto_codDate)) {
          return cashFlowDate
        }
      }
      return pto_codDate
    }, null)

    if (pto_codDate) {
      return ProjectUtils.projectMoment(project, pto_codDate)
    } else {
      // Default to the system start date
      return moment(ProjectUtils.getProductionStartDate([project]))
    }
  },

  getTotalScheduledPayments(payment) {
    return payment.payments.reduce((total, scheduledPayment) => {
      return add(total, scheduledPayment.amountDue)
    }, 0)
  },

  getPaymentUnit(payment) {
    switch (payment.paymentType) {
      case "size":
        switch (payment.size) {
          case "size":
            return " / kW / Month"
          case "sizeWattsYear":
            return " / W / Year"
          default:
            return ""
        }
      case "generation":
        return " / kWh"
      default:
        return ""
    }
  },
}
