import * as React from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import { timezones } from "./timezones"

interface TimezoneSelectProps {
  value: string
  onChange: (v: string) => void
  disabled?: boolean
  placeholder?: string
}

const timezonesArray = Object.entries(timezones).map(([k, v]) => ({ value: v, label: k }))

export default function TimezoneSelect(props: TimezoneSelectProps) {
  const { value, onChange, disabled = false, placeholder = "Select timezone..." } = props

  const selectedTimezone = timezonesArray.find((tz) => tz.value === value)

  return (
    <Typeahead
      id="timezone-select"
      multiple={false}
      onChange={(v) => {
        if (v && v[0]) {
          onChange(v[0].value)
        } else {
          onChange("")
        }
      }}
      filterBy={(option, v) => {
        if (value) {
          return true
        }
        return option.label.toLowerCase().indexOf(v.text.toLowerCase()) !== -1
      }}
      selected={selectedTimezone && [selectedTimezone]}
      disabled={disabled}
      options={timezonesArray}
      placeholder={placeholder}
      labelKey={(o) => o.label}
      className="timezone-picker"
    />
  )
}
