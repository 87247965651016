import React, { Component } from "react"
import moment from "moment"
import { create, all } from "mathjs"
import { Button, FormGroup, Form, Label, Input } from "reactstrap"

import { Loading, Error } from "@common/EcosuiteComponent"
import Settings from "@common/Settings"

import EnergyView from "./EnergyView"
import EnergyUtils from "../EnergyUtils"
import SourceStatusTooltips from "./source/SourceStatusTooltips"
import DeviceUtils from "@common/utils/DeviceUtils"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const math = create(all)
const { t } = i18n

const STATUS_TYPES = ["error", "warning", "ok"]

/**
 * Expects the following props:
 *
 * projects - list of the projects that should be displayed
 * projectsStatus - list of the status of projects

 * readings - map of project readings
 * lastMonthsEnergyReadings - map of last months project readings

 * datums - map of project datums
 * datumsFilter - the filter for the project datums

 * predictedConsumption - the forecast consumption datums
 * expectedGeneration - the expected generation datums
 * predictedGeneration - the predicted generation datums

 * instantaneous - map of instantaneous project readings

 * selectProject={this.selectProject}
 */
export default class EnergySourceView extends EnergyView {
  constructor(props) {
    super(props, "energy-status")

    this.state.selectedTypes = Settings.getSetting("energyStatusViewTypes", STATUS_TYPES)

    this.toggleType = this.toggleType.bind(this)
  }

  toggleType(type) {
    let selectedTypes = this.state.selectedTypes
    if (selectedTypes.indexOf(type) >= 0) {
      selectedTypes = selectedTypes.filter((docType) => {
        return docType !== type
      })
    } else {
      selectedTypes.push(type)
    }

    Settings.setSetting("energyStatusViewTypes", selectedTypes)
    this.setState({ selectedTypes: selectedTypes })
  }

  isShowSource(nodeStatus) {
    if (this.state.selectedTypes.indexOf(nodeStatus.status) < 0) {
      return false
    }
    switch (EnergyUtils.getDeviceType(nodeStatus)) {
      case "GEN":
      case "INV":
      case "PYR":
        return this.props.showGeneration
      case "CON":
      case "LOAD":
      case "MET":
      case "EXP":
        return this.props.showConsumption
      default:
        return true
    }
  }

  getEnergyViewControls() {
    return (
      <Form inline>
        <React.Fragment>
          {STATUS_TYPES.map((type) => {
            return <TypeFilter key={type} type={type} name={t(`labels.${type}`)} selectedTypes={this.state.selectedTypes} toggleType={this.toggleType} />
          })}
        </React.Fragment>
      </Form>
    )
  }

  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th id="col-source-id">{t("labels.source")}</th>
          <th id="col-source-node">{t("table_headings.node")}</th>
          <th id="col-source-latest-reading">{t("table_headings.latest_reading_date")}</th>
          <th id="col-source-last-hour">{`${t("table_headings.last_hour_reading")} (kWh)`}</th>
          <th id="col-source-last-hour-expected">{`${t("table_headings.last_hour_expected")} (kWh)`}</th>
          <th id="col-source-last-hour-percentage">%</th>
          <th id="col-source-last-24">{`${t("table_headings.last_24_reading")} (kWh)`}</th>
          <th id="col-source-last-24-expected">{`${t("table_headings.last_24_expected")} (kWh)`}</th>
          <th id="col-source-last-24-percentage">%</th>
          {this.props.showGeneration ? <th id="col-source-dc-size">{`${t("table_headings.dc_size")} (kW)`}</th> : null}
          {this.props.showGeneration ? <th id="col-source-ac-size">{`${t("table_headings.ac_size")} (kW)`}</th> : null}
          {this.props.showConsumption ? <th id="col-source-expected-peak">{`${t("table_headings.expected_peak")} (kW)`}</th> : null}
          <th id="col-source-message">{t("table_headings.message")}</th>
          <th>{t("table_headings.connect")}</th>
        </tr>
      </thead>
    )
  }

  renderMainView() {
    if (this.isContentError(this.props.projectsStatus)) {
      return <Error error={this.props.projectsStatus.getError()} />
    }
    if (this.isContentValid(this.props.projectsStatus)) {
      return (
        <React.Fragment>
          <table>
            {this.renderTableHeader()}
            <tbody>
              {this.props.projects.map((project) => {
                let projectStatus = this.props.projectsStatus.projects.find((projectStatus) => project.code === projectStatus.code)
                return EnergyUtils.getSites(projectStatus).map((site) => {
                  return EnergyUtils.getSystems(site).map((system) => {
                    if (system.nodesStatus) {
                      return system.nodesStatus.map((nodeStatus) => {
                        if (this.isShowSource(nodeStatus)) {
                          return (
                            <Source
                              key={nodeStatus.nodeId + "-" + nodeStatus.sourceId}
                              nodeStatus={nodeStatus}
                              project={project}
                              system={system}
                              groups={this.props.groups}
                              selectProject={this.props.selectProject}
                              showGeneration={this.props.showGeneration}
                              showConsumption={this.props.showConsumption}
                              showStorage={this.props.showStorage}
                            />
                          )
                        } else {
                          return null
                        }
                      })
                    } else {
                      return null
                    }
                  })
                })
              })}
            </tbody>
          </table>
          <SourceStatusTooltips />
        </React.Fragment>
      )
    } else {
      return <Loading />
    }
  }
}

class TypeFilter extends Component {
  render() {
    return (
      <FormGroup inline check>
        <Label check className={this.props.type}>
          <Input
            type="checkbox"
            value={this.props.type}
            checked={this.props.selectedTypes.indexOf(this.props.type) >= 0}
            onChange={() => {
              this.props.toggleType(this.props.type)
            }}
          />{" "}
          {this.props.name}
        </Label>
      </FormGroup>
    )
  }
}

export class Source extends Component {
  formatSourceReading(nodeStatus, reading) {
    if (EnergyUtils.isIrradianceDatum(nodeStatus)) {
      return reading || reading === 0 ? EnergyUtils.formatNumberAsKilo(reading) + " /m2" : undefined
    } else if (reading || reading === 0) {
      return EnergyUtils.formatNumberAsKilo(reading)
    }
  }

  render() {
    const nodeStatus = this.props.nodeStatus
    const size = DeviceUtils.getDeviceDCSize(this.props.system, nodeStatus.sourceId)
    const acSize = DeviceUtils.getDeviceACSize(this.props.system, nodeStatus.sourceId)
    const peakPower = DeviceUtils.getDevicePeakPower(this.props.system, nodeStatus.sourceId)

    return (
      <tr className={nodeStatus.status}>
        <td>{nodeStatus.sourceId}</td>
        <td>{nodeStatus.nodeId}</td>
        <td>{moment(nodeStatus.latestDatumDate).format("lll")}</td>
        <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.hourReading)}</td>
        <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.hourExpected)}</td>
        <td className="numerical">{nodeStatus.hourExpected && nodeStatus.hourReading ? math.round((nodeStatus.hourReading / nodeStatus.hourExpected) * 100) + "%" : null}</td>
        <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.reading)}</td>
        <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.expected)}</td>
        <td className="numerical">{nodeStatus.expected && nodeStatus.reading ? math.round((nodeStatus.reading / nodeStatus.expected) * 100) + "%" : null}</td>
        {this.props.showGeneration ? <td className={"numerical"}>{size ? EnergyUtils.formatNumber(size) : ""}</td> : null}
        {this.props.showGeneration ? <td className={"numerical"}>{acSize ? EnergyUtils.formatNumber(acSize) : ""}</td> : null}
        {this.props.showConsumption ? <td className={"numerical"}>{peakPower ? EnergyUtils.formatNumber(peakPower) : ""}</td> : null}
        <td>
          {nodeStatus.causes
            ? nodeStatus.causes.map((cause) => {
                return <div key={cause.message}>{cause.message}</div>
              })
            : null}
        </td>
        <td className="source-connect">
          {this.props.groups.includes("solarnetwork") ? (
            <Button
              onClick={(e) => {
                e.stopPropagation()
                this.openNodeTerminal(nodeStatus.nodeId, nodeStatus.sourceId, this.props.project.code)
              }}
              size="sm"
              color="primary"
            >
              SSH <Icon icon="open_in_new" />
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                e.stopPropagation()
                this.openNodeConsole(nodeStatus.nodeId)
              }}
              size="sm"
            >
              SSH <Icon icon="open_in_new" />
            </Button>
          )}
          <Button
            onClick={(e) => {
              e.stopPropagation()
              this.openNodeDashboard(nodeStatus.nodeId)
            }}
            size="sm"
          >
            Node <Icon icon="open_in_new" />
          </Button>
        </td>
      </tr>
    )
  }

  openNodeTerminal(nodeId, sourceId, projectId) {
    window.open(`/terminal?nodeId=${nodeId}&sourceId=${sourceId}&projectId=${projectId}`, "ssh-" + nodeId)
  }

  openNodeConsole(nodeId) {
    window.open(process.env.REACT_APP_SOLARNETWORK_HOST + "/solarssh/?nodeId=" + nodeId, "solar-ssh-" + nodeId)
  }

  openNodeDashboard(nodeId) {
    window.open(process.env.REACT_APP_SOLARNETWORK_HOST + "/node-dashboard/login?nodeId=" + nodeId, "solar-dashboard-" + nodeId)
  }
}
