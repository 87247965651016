import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import Aggregations from "@common/Aggregations"
import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import EcosuiteComponent from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"

export default class SourceGraph extends DateRangeGraph {
  constructor(props) {
    super(props, "source-graphs")
    this.state.visibility = {}
    // Set visible state on all sources to true by default
    this.props.sources.sourceIds.map((sourceId) => {
      this.state.visibility[sourceId] = true
    })
  }

  zoomDateRange(selection) {
    if (selection && this.props.aggregation !== Aggregations.MINIMUM) {
      let milliseconds = selection.activeLabel
      if (typeof milliseconds === "string") {
        milliseconds = moment(milliseconds).valueOf() // get the milliseconds from the date
      }
      if (milliseconds > 0) {
        const zoomRange = DateRangeUtils.getDateRange(moment(milliseconds), this.props.aggregation)
        this.props.selectRange("custom", zoomRange)
      }
    }
  }

  renderContent() {
    let sources = this.props.sources
    return (
      <ResponsiveContainer width="100%" height={500}>
        <AreaChart
          data={sources.data}
          margin={{ top: 5, right: 50, left: 50, bottom: 10 }}
          onClick={this.zoomDateRange}
        >
          <XAxis
            dataKey="time"
            type="number"
            scale="time"
            domain={this.getDomain(this.props.range, this.props.aggregation)}
            tickFormatter={(time) => {
              return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
            }}
          />
          <YAxis tickCount={8} />
          <Legend
            iconType="plainline"
            onClick={(e) => {
              const sourceId = e.dataKey
              // Toggle the source's visibility
              this.setState({ visibility: { ...this.state.visibility, [sourceId]: !this.state.visibility[sourceId] } })
            }}
            formatter={(sourceId) => {
              return <span style={{ cursor: "pointer", userSelect: "none", opacity: 1 }}>{sourceId}</span>
            }}
          />
          <Tooltip
            content={
              <SourceTooltip unit={this.props.unit} range={this.props.range} aggregation={this.props.aggregation} />
            }
          />
          {sources.sourceIds.map((sourceId, idx) => {
            const color = GRAPH_COLORS[idx % sources.sourceIds.length]
            const isSourceVisible = this.state.visibility[sourceId]
            return (
              <Area
                key={sourceId}
                name={sourceId}
                className={isSourceVisible ? "visible" : "hidden"} // Keeps remaining graphs the same if larger ones are toggled off
                hide={!isSourceVisible} // Scales up remaining graphs if larger ones are toggled off
                activeDot={isSourceVisible}
                type="monotone"
                dataKey={sourceId}
                stroke={isSourceVisible ? color : "gray"}
                fill={color}
                fillOpacity={0.05}
                dot={true}
                isAnimationActive={false}
              />
            )
          })}
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}
class SourceTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {payload.map((data) => {
              // Remove source from tooltip if it has been toggled off
              if (data.className == "hidden") return null

              return (
                <React.Fragment key={data.dataKey}>
                  <p className="label" style={{ color: data.fill }}>
                    {`${data.name} : ${
                      this.props.unit === "watts"
                        ? EnergyUtils.formatKiloWatts(data.value ? data.value : 0)
                        : EnergyUtils.formatKiloWattHours(data.value ? data.value : 0)
                    }`}
                  </p>
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }
    return null
  }
}
