import React from "react"
import moment from "moment-timezone"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, Legend, Line, ComposedChart } from "recharts"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import { Loading, Message } from "@common/EcosuiteComponent"

import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"
import FinanceUtils from "@dashboard/finance/FinanceUtils"
import i18n from "src/i18n"

const { t } = i18n

export default class CashFlowPaymentLogsGraph extends DateRangeGraph {
  /**
    Calculates and adds arrears data to each datum in the given dataSet.
    @param {Array<Object>} dataSet - An array of objects representing data for each datum.
    Each object should have the properties 'expected' and 'actual'.
    @returns {Array<Object>} An array of objects representing data for each datum,
    with additional properties 'actualArrear' and 'cumulativeArrears' added to each datum.
    'actualArrear' is the difference between the 'expected' and 'actual' values for that datum,
    and 'cumulativeArrears' is the sum of all the 'actualArrear' values up to and including that datum.
  */
  calculateAndAddArrears(dataSet) {
    let cumulativeArrears = 0

    const datumWithArrears = dataSet.map((datum) => {
      const actualArrear = datum.expected - datum.actual
      cumulativeArrears += actualArrear
      return { ...datum, actualArrear, cumulativeArrears }
    })

    return datumWithArrears
  }

  createDataSet(payments) {
    return payments
      .slice(0, 12)
      .map((payment) => {
        return {
          month: payment.start,
          time: moment(payment.start).toDate().getTime(),
          expected: payment.expectedAmount,
          advised: payment.advisedAmount,
          actual: payment.actualAmount || 0,
        }
      })
      .reverse()
  }

  renderContent() {
    if (this.isContentValid(this.props.cashFlowPaymentLogs)) {
      const data = this.calculateAndAddArrears(this.createDataSet(this.props.cashFlowPaymentLogs.paymentLogs.payments))

      if (data.length) {
        return (
          <ResponsiveContainer width="100%" height={350}>
            <ComposedChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 10 }}>
              <XAxis
                dataKey="time"
                tickFormatter={(time) => {
                  return moment(time).format("MMM YYYY")
                }}
              />
              <YAxis />
              <Tooltip
                wrapperStyle={{ color: "#000", pointerEvents: "auto" }}
                // eslint-disable-next-line no-unused-vars
                formatter={(value, name, props) => {
                  return FinanceUtils.formatCurrencyWithCents(value)
                }}
                labelFormatter={(label) => {
                  return moment(label).format("MMM YYYY")
                }}
              />

              <Bar dataKey={"expected"} fill={GRAPH_COLORS[0]} name={t("labels.anticipated")} />
              <Bar dataKey={"advised"} fill={GRAPH_COLORS[1]} name={t("labels.advised")} />
              <Bar dataKey={"actual"} fill={GRAPH_COLORS[2]} name={t("labels.actual")} />

              <Legend />
              <Line key="arrears" type="linear" dataKey={"cumulativeArrears"} dot={true} name="Arrears" />
            </ComposedChart>
          </ResponsiveContainer>
        )
      } else {
        return <Message message={t("economics.messages.no_cashflow_payment_logs")} />
      }
    } else {
      return <Loading />
    }
  }
}
