import API from "@common/API"

/**
 * Service for administering Ecosuite SolarNetwork settings.
 */
const SettingsService = {
  getAllNodes(): Promise<unknown> {
    return API.get("/solarnetwork/nodes?all=true")
  },

  getNodeIds(): Promise<unknown> {
    return API.get("/solarnetwork/settings/node-ids")
  },

  setNodeIds(enabledNodes: string[], commissionedNodes: string[]): Promise<unknown> {
    const nodeIds = { enabledNodes, commissionedNodes }
    return API.post("/solarnetwork/settings/node-ids", nodeIds)
  },

  getSettingsFormSchema(): Promise<unknown> {
    return API.get("/settings/params/form-schema")
  },

  getStoreParamFromName(name: string, path = "Settings"): Promise<unknown> {
    const encodedName = encodeURIComponent(`/${path}/${name}`)
    return API.get(`/settings/param?name=${encodedName}`)
  },

  getParamsFromPaths(paths: string): Promise<unknown> {
    return API.get(`/settings/params?paths=${paths}`)
  },

  putStoreParam(formData: unknown): Promise<unknown> {
    return API.put("/settings/param", formData)
  },

  deleteStoreParam(name: string): Promise<unknown> {
    return API.delete(`/settings/param?name=${name}`)
  },
}

export default SettingsService
