import React from "react"
import BackdropDialog from "@common/input/button/BackdropDialog"
import ExportService from "@dashboard/data/project/ExportService"

import { type Project } from "@ecogy-types/openapi-types"
import i18n from "src/i18n"

const { t } = i18n
interface ProjectExportDialogProps {
  project: Project
  showExportDialog: boolean
  setShowExportDialog: () => void
}

const ProjectExportDialog = ({ project, showExportDialog, setShowExportDialog }: ProjectExportDialogProps) => {
  return (
    <BackdropDialog
      isOpen={showExportDialog}
      type={"option"}
      optionProps={{
        options: {
          ob: "Orange Button",
        },
        defaultOptionKey: "csv",
        optionInfo: {
          ob: {
            color: "info",
            text: `${t("notes.export_project_data")}`,
            performText: `${t("loadingMsg.exporting_project_data")}`,
            performColor: "info",
          },
        },
      }}
      bodyText={`${t("notes.select_project_format")}`}
      title={`${t("labels.export_project")}`}
      onCancel={setShowExportDialog}
      onConfirm={async () => {
        return ExportService.exportProject(project.code).then((resp) => {
          const blob = new Blob([JSON.stringify(resp, null, 4)], { type: "application/json" })
          const url = window.URL.createObjectURL(blob)
          const anchor = document.createElement("a")
          anchor.href = url
          anchor.download = `${project.code}-orange-button.json`
          anchor.click()
          window.URL.revokeObjectURL(url)
        })
      }}
    />
  )
}

export default ProjectExportDialog
