import React from "react"
import { Button } from "reactstrap"
import _ from "lodash"

import EcosuiteView from "@common/module/EcosuiteView"

import recordListConfig from "./record-list-config.json"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

// Order is listed here: https://ecogyenergy.atlassian.net/browse/EP-2333
// Order is reversed on init so subtypes not in the config get pushed to the bottom when sorting.
const subTypeOrder = [...recordListConfig.subTypeOrder].reverse()
const { t } = i18n

export default class RecordContent extends EcosuiteView {
  /**
   * @param {Object} unmadeRecordTypes - recordFilters with already existing recordSubTypes removed.
   * @returns {Array} [ 0: [ recordSubType, recordType ], ... ] - Array of ghost records
   */
  createGhostRecords = (unmadeRecordTypes) => {
    const ghostRecords = []
    _.entries(unmadeRecordTypes).map(([recordType, subTypes]) => {
      _.entries(subTypes).map(([subType, displayRecord]) => {
        if (displayRecord) {
          ghostRecords.push([subType, recordType])
        }
      })
    })
    return ghostRecords
  }

  /**
   * settings.naRecords is being saved as an Object instead of an Array so that it has O(1) lookup
   * @param {String} category - target ghost record to flip N/A setting.
   */
  saveSettings(category) {
    const naRecords = this.props.naRecords
      ? { ...this.props.naRecords, [category]: !this.props.naRecords[category] }
      : { [category]: true }
    this.props.actions.saveSettings({ naRecords: naRecords })
  }

  displayRecordSubType(subType) {
    switch (subType) {
      case "oandm":
        return <td>O&M</td>
      case "ppa":
        return <td>PPA</td>
      default:
        return <td style={{ textTransform: "capitalize" }}>{_.startCase(subType)}</td>
    }
  }

  renderContent() {
    const records = this.props.records?.filter((record) => {
      // Display only if the recordType and the recordSubType are viewable
      return (
        this.props.viewableRecordTypes[record.recordType]?.length &&
        this.props.viewableRecordTypes[record.recordType]?.includes(record.recordSubType)
      )
    })

    const { newRecord, selectRecord } = this.props.actions
    let unusedRecordTypes = { ...this.props.recordFilters }

    return (
      <table>
        <thead>
          <tr>
            <th className="edit"></th>
            {this.props.groups.includes("data-write") ? <th className="edit"></th> : null}
            <th>{t("table_headings.path")}</th>
            <th>{t("table_headings.name")}</th>
            <th>{t("table_headings.type")}</th>
            <th>{t("table_headings.subtype")}</th>
            <th>{t("table_headings.is_contract")}</th>
            <th>{t("table_headings.counterparty")}</th>
            <th>{t("table_headings.verified")}</th>
          </tr>
        </thead>

        <tbody>
          {records
            ?.sort((a, b) => {
              if (subTypeOrder.indexOf(a.recordSubType) === subTypeOrder.indexOf(b.recordSubType)) {
                return 0
              } else if (subTypeOrder.indexOf(a.recordSubType) > subTypeOrder.indexOf(b.recordSubType)) {
                return -1
              } else {
                return 1
              }
            })
            .map((record) => {
              unusedRecordTypes[record.recordType] = _.omit(unusedRecordTypes[record.recordType], record.recordSubType)

              return (
                <tr key={record.id}>
                  <td>
                    <Button
                      color="link"
                      size="sm"
                      title={t("buttons.view_record")}
                      onClick={() => {
                        selectRecord(record, true)
                      }}
                    >
                      <Icon icon="link" />
                    </Button>
                  </td>
                  {this.props.groups.includes("data-write") ? (
                    <td>
                      <Button
                        color="link"
                        size="sm"
                        title={t("buttons.edit_record")}
                        onClick={() => {
                          selectRecord(record, false)
                        }}
                      >
                        <Icon icon="edit" />
                      </Button>
                    </td>
                  ) : null}
                  <td>{record.path}</td>
                  <td>{record.name}</td>
                  <td style={{ textTransform: "capitalize" }}>{record.recordType}</td>
                  {this.displayRecordSubType(record.recordSubType)}
                  <td>{record.isContract}</td>
                  <td>{record.counterparty}</td>
                  <td style={{ textAlign: "center" }}>{record.verified ? <Icon icon="check_circle" /> : "-"}</td>
                </tr>
              )
            })}

          {/* 
            When viewing a portfolio you would get an error because this.props.project is null
            Disable ghost records while inside portfolio view for the time being
          */}
          {this.props.project &&
            this.props.showUnmadeRecords &&
            this.createGhostRecords(unusedRecordTypes)
              ?.filter(([recordSubType]) => this.props.showNonApplicableRecords || !this.props.naRecords[recordSubType])
              ?.sort(([recordSubTypeOfA], [recordSubTypeOfB]) => {
                if (subTypeOrder.indexOf(recordSubTypeOfA) === subTypeOrder.indexOf(recordSubTypeOfB)) {
                  return 0
                } else if (subTypeOrder.indexOf(recordSubTypeOfA) > subTypeOrder.indexOf(recordSubTypeOfB)) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(([recordSubType, recordType]) => {
                // Display Ghost Records only if the recordType and the recordSubType are viewable
                if (
                  this.props.viewableRecordTypes[recordType]?.length &&
                  this.props.viewableRecordTypes[recordType]?.includes(recordSubType)
                ) {
                  return (
                    <tr key={`${recordType}-${recordSubType}`}>
                      <td></td>
                      {this.props.groups.includes("data-write") ? (
                        <td>
                          <Button
                            color="link"
                            size="sm"
                            title={t("buttons.make_record")}
                            onClick={() => {
                              newRecord({ recordType: recordType, recordSubType: recordSubType })
                            }}
                          >
                            <Icon icon="edit" />
                          </Button>
                        </td>
                      ) : null}
                      <td></td>
                      <td>
                        <div className="ghost-record">{this.props.recordSubTypeNames[recordSubType]}</div>
                      </td>
                      <td>
                        <div className="ghost-record">{recordType}</div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="ghost-record-toggle">
                        <span>{this.props.naRecords[recordSubType] ? "N/A" : "-"}</span>
                        <Button
                          color="N/A"
                          size="sm"
                          title={t("buttons.toggle_N/A")}
                          onClick={(e) => {
                            this.saveSettings(recordSubType)
                            e.currentTarget.blur()
                          }}
                        >
                          <Icon icon={`toggle${this.props.naRecords[recordSubType] ? "_off" : "_on"}`} />
                        </Button>
                      </td>
                    </tr>
                  )
                }
              })}
        </tbody>
      </table>
    )
  }
}
