import React from "react"
import Moment from "moment"
import { extendMoment } from "moment-range"

import EcosuiteComponent, { EcosuiteComponentError, Error } from "@common/EcosuiteComponent"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import Aggregations from "@common/Aggregations"

import EnergyService from "@dashboard/energy/EnergyService"

import EconodeModule from "@econode/EconodeModule"

import EconodeEconomicsDashboardView from "./views/EconodeEconomicsDashboardView"
import EconodeEconomicsDashboardProjectView from "./views/EconodeEconomicsDashboardProjectView"

import "./EconodeEconomics.css"
import Logger from "@common/Logger"
import i18n from "src/i18n"

const { t } = i18n
const moment = extendMoment(Moment)

class EnergyModule extends EconodeModule {
  constructor(props) {
    super(props, "econode-economics")

    this.state.dateRangeIncludesTime = true
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadRange(this.getExclusiveRange(), true)
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    // We recalculate the date range for "All Time" so that the graphs can be updated if necessary
    this.recalculateAllTimeRange(prevProps)

    if (this.props.loadTime !== prevProps.loadTime) {
      this.loadRange(this.getExclusiveRange())
    } else if (this.props.project !== prevProps.project) {
      this.loadRange(this.getExclusiveRange())
    }
  }

  getProducts() {
    return []
  }

  getSelectedProductIds() {
    return []
  }

  getAggregateForRange(range) {
    let aggregate = DateRangeUtils.getAggregateForRange(range)
    if (range.diff("days", true) <= 1) {
      aggregate = Aggregations.FifteenMinute
    }
    return aggregate
  }

  selectRange(rangeName, customRange) {
    let range = super.selectRange(rangeName, customRange)
    this.loadRange(range)
  }

  loadRange(range) {
    const aggregate = this.getAggregateForRange(range)

    this.loadEnergyReadings(range, aggregate)
    this.loadConsumptionCost(range, aggregate)
    this.loadPreviousConsumptionCost(range, aggregate)
  }

  loadEnergyReadings(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      readings: undefined,
    })

    EnergyService.getEnergyReadings(range)
      .then((response) => {
        if (this.isRangeCurrent(range, aggregate)) {
          this.setStateIfMounted({
            readings: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range, aggregate)) {
          this.setStateIfMounted({
            readings: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadConsumptionCost(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      consumptionCost: undefined,
    })

    EnergyService.getConsumptionCost(range)
      .then((response) => {
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            consumptionCost: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({
          consumptionCost: new EcosuiteComponentError(err),
        })
      })
  }

  loadPreviousConsumptionCost(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      actualRange: undefined,
      previousRange: undefined,
      previousConsumptionCost: undefined,
    })

    const now = moment()
    if (range.start.isAfter(now)) {
      // We set to null to indicate that there is no actual/previous data to load for the selected dates
      this.setStateIfMounted({
        actualRange: null,
        previousRange: null,
        previousConsumptionCost: null,
      })
    } else {
      let actualRange = moment.range(range.start, range.end.isAfter(now) ? now.add(1, aggregate).startOf(aggregate) : range.end)
      let rangeLength = actualRange.end - actualRange.start
      let previousRange = moment.range(actualRange.start - rangeLength, actualRange.end - rangeLength)

      EnergyService.getConsumptionCost(previousRange)
        .then((response) => {
          if (this.isRangeCurrent(range)) {
            this.setStateIfMounted({
              actualRange: actualRange,
              previousRange: previousRange,
              previousConsumptionCost: response,
            })
          }
        })
        .catch((err) => {
          this.setStateIfMounted({
            actualRange: actualRange,
            previousRange: previousRange,
            previousConsumptionCost: new EcosuiteComponentError(err),
          })
        })
    }
  }

  renderProjectView() {
    const project = this.props.project
    return (
      <div className="EconomicsView">
        <EnergyProjectViews
          {...this.props}
          restrictions={this.props.restrictions}
          groups={this.props.groups}
          view={this.state.projectView}
          range={this.getExclusiveRange()}
          rangeName={this.state.rangeName}
          customRange={this.getCustomRange()}
          actualRange={this.state.actualRange}
          previousRange={this.state.previousRange}
          selectRange={this.selectRange}
          project={project}
          projects={[project]}
          readings={this.isContentValid(this.state.readings) ? this.state.readings.projects[project.code] : this.state.readings}
          datumsRange={this.isContentValid(this.state.consumptionCost) ? this.state.consumptionCost.range : this.state.datums}
          datumsAggregation={this.isContentValid(this.state.consumptionCost) ? this.state.consumptionCost.aggregation : "unknown"}
          projectCosts={this.isContentValid(this.state.consumptionCost) ? this.state.consumptionCost.projects[project.code] : this.state.consumptionCost}
          previousConsumptionCost={this.isContentValid(this.state.previousConsumptionCost) ? this.state.previousConsumptionCost.projects[project.code] : this.state.previousConsumptionCost}
          showConsumption={true} //this.isConsumptionVisible()
          showStorage={this.isStorageVisible()}
        />
      </div>
    )
  }

  renderModuleView() {
    return (
      <div className="EconomicsView">
        <EnergyViews
          {...this.props}
          view={this.state.moduleView}
          range={this.getExclusiveRange()}
          rangeName={this.state.rangeName}
          customRange={this.getCustomRange()}
          actualRange={this.state.actualRange}
          previousRange={this.state.previousRange}
          selectRange={this.selectRange}
          readings={this.state.readings}
          consumptionCost={this.state.consumptionCost}
          previousConsumptionCost={this.state.previousConsumptionCost}
          showGeneration={this.isGenerationVisible()}
          showConsumption={true} //this.isConsumptionVisible()
          showStorage={this.isStorageVisible()}
        />
      </div>
    )
  }
}

class EnergyViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "dashboard":
        return <EconodeEconomicsDashboardView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ${this.props.view}` }} />
    }
  }
}
class EnergyProjectViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "dashboard":
        return <EconodeEconomicsDashboardProjectView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ${this.props.view}` }} />
    }
  }
}

export default EnergyModule
