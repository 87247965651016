import API from "@common/API"
import Logger from "@common/Logger"
import i18n from "src/i18n"

const { t } = i18n
const ProjectService = {
  async getProjects(): Promise<unknown> {
    return API.get<{ projects: unknown }>("/projects/")
      .then((data) => {
        return data.projects
      })
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: `${t("errors.unable_retrieve_projects")}` })
      })
  },

  async getContacts(): Promise<unknown> {
    return API.get<any>("/contacts/all").then((data) => data)
  },

  async getTimezoneForLatLong(lat: number, long: number): Promise<unknown> {
    return API.get<any>("/getTimezoneForLatLong" + "?lat=" + lat +"&long=" + long)
      .then((data) => data)
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: "failed to get timezone"})
      })
  }
}

export default ProjectService
