import React from "react"
import { Button } from "reactstrap"
import moment from "moment"

import EcosuiteView from "@common/module/EcosuiteView"

import DocumentService from "@dashboard/data/DocumentService"
import ProjectUtils from "@common/utils/ProjectUtils"
import RecordDocumentPreview from "./RecordDocumentPreview"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

import DistillPopover from "./DistillPopover"
import { cloneDeep } from "lodash"

const { t } = i18n

const DistilleryWorkStatusActive = 0

export default class RecordDocuments extends EcosuiteView {
  constructor(props) {
    super(props, "record-document-list")

    this.viewFile = this.viewFile.bind(this)
    this.state = {
      orderingDistillery: "ascending",
      orderingRegular: "ascending",
    }
  }

  async viewFile(documentKey) {
    DocumentService.viewFile(documentKey).then((url) => {
      const documentParts = documentKey.split("/")
      this.setStateIfMounted({
        previewDocument: {
          documentUrl: url,
          documentName: documentParts[documentParts.length - 1],
        },
      })
    })
  }

  async distillFile(documentKey) {
    DocumentService.viewFile(documentKey).then((url) => {
      const documentParts = documentKey.split("/")
      this.setStateIfMounted({
        previewDocument: {
          documentUrl: url,
          documentName: documentParts[documentParts.length - 1],
        },
      })
    })
  }

  renderDocument(recordDocument, document, index) {
    const documentPath = recordDocument?.documents?.length ? recordDocument.recordPath : undefined
    const projectId = ProjectUtils.getProjectCode(recordDocument.recordPath)
    const siteId = ProjectUtils.getSiteCode(recordDocument.recordPath)

    const project = this.props.projects.find((project) => project.code === projectId)
    const site = project && siteId ? project.sites[siteId] : null

    return [
      document.fileKey,
      <tr
        key={recordDocument.recordId + "-" + document.fileKey + "-" + index}
        className={`document-type type-${recordDocument.recordType}`}
      >
        <td className="document-download">
          <Button
            style={{ padding: "5px" }}
            color="link"
            size="sm"
            onClick={() => {
              this.viewFile(document.fileKey)
            }}
            title={t("labels.view")}
          >
            <Icon icon="preview" />
          </Button>
        </td>
        {this.props.restrictions.preventDownload === "no" ? (
          <td className="document-download">
            <Button
              style={{ padding: "5px" }}
              color="link"
              size="sm"
              onClick={() => {
                DocumentService.downloadFile(document.fileKey)
              }}
              title={t("buttons.download")}
            >
              <Icon icon="cloud_download" />
            </Button>
          </td>
        ) : null}

        {this.isReadOnly() || !this.props.groups.includes("data-write") ? null : (
          <td className="document-download">
            <Button
              style={{ padding: "5px" }}
              color="link"
              size="sm"
              onClick={() => {
                if (window.confirm(`${t("data.confirmation_msg.delete_document")}: ` + document.fileName)) {
                  DocumentService.deleteFile(document.fileKey, this.props.groups).then(() => {
                    this.props.loadRecordDocuments()
                  })
                }
              }}
              title={t("buttons.delete")}
            >
              <Icon icon="delete" />
            </Button>
          </td>
        )}
        <td>{document.fileName}</td>
        <td>{documentPath}</td>
        <td>{project.name}</td>
        <td>{site ? site.name : ""}</td>
        <td>{recordDocument.recordName}</td>
        <td>{document.folderName}</td>
        <td>{moment(document.updated).format("lll")}</td>
      </tr>,
    ]
  }

  renderDistilleryDocument(document) {
    let opacity = 1.0
    let ready = false
    let waiting = false

    if (this.props.distilleryMap && this.props.distilleryMap.has(document.fileKey)) {
      const job = this.props.distilleryMap.get(document.fileKey)
      if (job.status == DistilleryWorkStatusActive) {
        opacity = 0.4
        waiting = true
      } else {
        ready = true
      }
    } else {
      opacity = 0.4
    }

    // If we have a distilleryMap, and this document doesn't have a job, have the DistillPopover
    // button start the job
    //
    // |ready| indicates that there is a job and it's finished, while |waiting| indicates that
    // there's a pending job. If |ready| and |waiting| are both false, we don't have any jobs.

    const parts = document.fileKey.split("/")
    const projectId = parts[parts.length - 2]
    const search = this.props.projects ? this.props.projects : [this.props.project]
    const project = search.find((p) => p.code === projectId)

    if (!project) {
      return
    }

    return (
      <tr key={"distillery-" + document.name} className={`document-type `}>
        <td className="document-download">
          <DistillPopover
            fileKey={document.fileKey}
            fileName={document.name}
            distilleryMap={this.props.distilleryMap}
            ready={ready}
            waiting={waiting}
          />
        </td>
        <td style={{ opacity: opacity }} className="document-download">
          <Button
            style={{ padding: "5px" }}
            color="link"
            size="sm"
            onClick={() => {
              this.viewFile(document.fileKey)
            }}
            title={t("labels.view")}
          >
            <Icon icon="preview" />
          </Button>
        </td>
        {this.props.restrictions.preventDownload === "no" ? (
          <td style={{ opacity: opacity }} className="document-download">
            <Button
              style={{ padding: "5px" }}
              color="link"
              size="sm"
              onClick={() => {
                DocumentService.downloadFile(document.fileKey)
              }}
              title={t("buttons.download")}
            >
              <Icon icon="cloud_download" />
            </Button>
          </td>
        ) : null}
        {this.isReadOnly() || !this.props.groups.includes("data-write") ? null : (
          <td style={{ opacity: opacity }} className="document-download">
            <Button
              style={{ padding: "5px" }}
              color="link"
              size="sm"
              onClick={() => {
                if (window.confirm(`${t("data.confirmation_msg.delete_document")}: ` + document.name)) {
                  DocumentService.deleteFile(document.fileKey, this.props.groups).then(() => {
                    this.props.loadRecordDocuments()
                  })
                }
              }}
              title={t("buttons.delete")}
            >
              <Icon icon="delete" />
            </Button>
          </td>
        )}
        <td style={{ opacity: opacity }}>{document.name}</td>
        <td style={{ opacity: opacity }}>{project.name}</td>
        <td style={{ opacity: opacity }}>{moment(document.updated).format("lll")}</td>
      </tr>
    )
  }

  renderDocuments(recordDocuments, ordering) {
    // Flatten the documents with their recordDocument.
    let allDocuments = []
    for (const recordDocument of recordDocuments) {
      for (const document of recordDocument.documents) {
        allDocuments.push({
          recordDocument,
          document,
        })
      }
    }

    // Sort the documents based on the 'updated' date.
    if (ordering) {
      allDocuments.sort((a, b) => {
        const updatedLeft = new Date(a.document.updated)
        const updatedRight = new Date(b.document.updated)
        if (ordering === "ascending") {
          return updatedLeft - updatedRight
        } else {
          return updatedRight - updatedLeft
        }
      })
    }

    // Render components based on sorted documents.
    return allDocuments.map((item, index) => {
      const { recordDocument, document } = item
      // eslint-disable-next-line no-unused-vars
      const [_key, component] = this.renderDocument(recordDocument, document, index)
      return component
    })
  }

  renderDistilleryDocuments(documents, ordering) {
    let components = []
    let sorted = cloneDeep(documents)

    if (documents) {
      // Sort the documents based on the 'updated' date.
      if (ordering) {
        sorted.sort((a, b) => {
          const updatedLeft = new Date(a.updated)
          const updatedRight = new Date(b.updated)
          if (ordering === "ascending") {
            return updatedLeft - updatedRight
          } else {
            return updatedRight - updatedLeft
          }
        })
      }

      for (const doc of sorted) {
        const component = this.renderDistilleryDocument(doc)
        components.push(component)
      }
    }

    return components
  }

  renderContent() {
    if (this.state.previewDocument) {
      return (
        <RecordDocumentPreview
          previewDocument={this.state.previewDocument}
          onCancel={() => this.setState({ previewDocument: null })}
        />
      )
    }

    let recordDocuments = this.props.recordDocuments
    let rankedDocuments = this.props.rankedDocuments
    let distilleryDocuments = this.props.distilleryDocuments ? this.props.distilleryDocuments : []

    // Filter documents based on project
    const selectedProjectCodes = new Set(
      this.props.project ? [this.props.project.code] : this.props.projects.map((p) => p.code),
    )
    distilleryDocuments = distilleryDocuments.filter((f) => {
      const path = f.fileKey
      const components = path.split("/")
      const projectComponent = components[components.length - 2]
      return selectedProjectCodes.has(projectComponent)
    })

    let documentComponents = new Map()
    for (const recordDocument of recordDocuments) {
      const documents = recordDocument.documents.map((document) => this.renderDocument(recordDocument, document))
      for (const [key, component] of documents) {
        documentComponents.set(key, component)
      }
    }

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th className="edit"></th>
              <th className="edit"></th>
              {this.props.restrictions.preventDownload === "yes" ? null : <th className="edit"></th>}
              {this.isReadOnly() ? null : <th className="edit"></th>}
              <th>{"Distillery Document"}</th>
              <th>{t("table_headings.project")}</th>
              <th
                onClick={() => {
                  const orderingDistillery = this.state.orderingDistillery
                  this.setStateIfMounted({
                    orderingDistillery: orderingDistillery === "ascending" ? "descending" : "ascending",
                  })
                }}
              >
                {t("table_headings.updated")}
                {this.state.orderingDistillery === "ascending" ? (
                  <Icon icon={"arrow_upward"} />
                ) : (
                  <Icon icon={"arrow_downward"} />
                )}
              </th>
            </tr>
          </thead>

          <tbody>{this.renderDistilleryDocuments(distilleryDocuments, this.state.orderingDistillery)}</tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th className="edit"></th>
              {this.props.restrictions.preventDownload === "yes" ? null : <th className="edit"></th>}
              {this.isReadOnly() ? null : <th className="edit"></th>}
              <th>{t("table_headings.document")}</th>
              <th>{t("table_headings.path")}</th>
              <th>{t("table_headings.project")}</th>
              <th>{t("table_headings.site")}</th>
              <th>{t("table_headings.record")}</th>
              <th>{t("table_headings.folder")}</th>
              <th
                onClick={() => {
                  const orderingRegular = this.state.orderingRegular
                  this.setStateIfMounted({
                    orderingRegular: orderingRegular === "ascending" ? "descending" : "ascending",
                  })
                }}
              >
                {t("table_headings.updated")}
                {this.state.orderingRegular === "ascending" ? (
                  <Icon icon={"arrow_upward"} />
                ) : (
                  <Icon icon={"arrow_downward"} />
                )}
              </th>
            </tr>
          </thead>

          <tbody>
            {rankedDocuments
              ? rankedDocuments.map((rankedDocument) => {
                  return documentComponents.get(rankedDocument.fileKey)
                })
              : this.renderDocuments(recordDocuments, this.state.orderingRegular)}
          </tbody>
        </table>
      </div>
    )
  }
}
