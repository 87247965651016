import React from "react"
import { Button, Alert, Label } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import UserAdminService from "../UserAdminService"

import EcosuiteForm, { FormError } from "@common/form/EcosuiteForm"
import Logger from "@common/Logger"
import UserSchema from "./UserSchema"
import Icon from "@common/display/Icon"
import TimedMessage from "@common/display/TimedMessage"
import i18n from "src/i18n"

const { t } = i18n

class EditUser extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.editUser = this.editUser.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.getUserFormData = this.getUserFormData.bind(this)

    this.state = {
      formData: this.getUserFormData(),
    }
  }

  componentDidMount() {
    super.componentDidMount()
    UserAdminService.getUserSchema(this.props.projects).then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
  }

  getUserFormData() {
    // Ignore the `organizationId` and `rootuser` fields.
    // eslint-disable-next-line no-unused-vars
    const { organizationId, rootuser, ...attributes } = this.props.user.attributes
    return attributes
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setState({
        formData: this.getUserFormData(),
        error: undefined,
        success: undefined,
      })
    }
  }

  editUser(form) {
    this.setState({ error: undefined, success: false, loading: true, formData: form.formData })
    let exportForm = JSON.stringify(form)
    exportForm = JSON.parse(exportForm)
    exportForm.formData.lastLogin = undefined
    UserAdminService.editUser(this.props.user.id, exportForm)
      .then((response) => {
        this.setStateIfMounted({ success: true, loading: false })
        response.user.lastLogin = form.formData.lastLogin
        response.user.attributes.lastLogin = form.formData.lastLogin
        this.props.userChanged(response.user)
      })
      .catch((err) => {
        Logger.error(err)
        this.setState({ error: err, success: false, loading: false })
      })
  }

  deleteUser() {
    const confirm = window.confirm(`${t("settings.messages.confirm_delete_user")}: ${this.props.user.displayName}?`)
    if (confirm) {
      this.setState({ loading: true })
      UserAdminService.deleteUser(this.props.user.id).then(() => {
        this.props.userDeleted()
      })
    }
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.schema) {
      return (
        <div className="ecogy-form admin-tab-content-area">
          {/*   Conflicting User Groups / Default Groups Warning   */}
          {this.props.conflictingUserGroups && (
            <Alert color="warning" className="admin-tab__conflict-warning">
              <div className="warning-wrapper">
                <Icon icon="warning" />
                <p>{`${t("settings.messages.wrong_user_permissions")}`}</p>
              </div>
              <Button onClick={this.props.resetUserGroup} disabled={this.props.loadingResetUserGroups}>
                {this.props.loadingResetUserGroups
                  ? `${t("settings.labels.resetting")}`
                  : `${t("settings.labels.reset_permissions_to default")}`}
              </Button>
            </Alert>
          )}
          <Alert color="info" className="info">
            <Icon icon="info" />
            {`${t("settings.messages.changes_not_reflected")}`}
          </Alert>
          {this.renderMessages()}
          <Label>{t("settings.labels.account_status")}: </Label> {this.props.user.status}
          <hr />
          <EcosuiteForm
            schema={UserSchema.getSchema(
              this.state.schema,
              this.props.projects,
              this.props.portfolios,
              this.state.formData,
            )}
            uiSchema={UserSchema.getUiSchema()}
            formData={this.state.formData}
            onChange={this.formDataChanged}
            onSubmit={this.editUser}
          >
            <div className="admin-footer">
              <Button color="primary" type="submit">
                {t("buttons.submit")}
              </Button>
              <Button color="danger" onClick={this.deleteUser}>
                {t("buttons.delete")}
              </Button>
            </div>
          </EcosuiteForm>
          {this.state.success ? (
            <TimedMessage delay={8000}>
              <Alert color="info">{t("settings.messages.user_updated")}</Alert>
            </TimedMessage>
          ) : null}
          {this.renderMessages()}
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderMessages() {
    return (
      <FormError
        error={this.state.error}
        toggle={() => {
          this.setStateIfMounted({ error: null })
        }}
      />
    )
  }
}

export default EditUser
