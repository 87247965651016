import React from "react"
import { create, all } from "mathjs"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import EcosuiteComponent from "@common/EcosuiteComponent"

import Utils from "@common/utils/Utils"
import i18n from "src/i18n"

const { t } = i18n
const math = create(all)

export default class EconodeConsumptionCosts extends EcosuiteComponent {
  renderContent() {
    if (this.isContentValid(this.props.consumptionCost)) {
      const graphDatums = this.props.consumptionCost.consumptionCost.aggregatedTotals

      let maxMeteredCost
      let minMeteredCost
      let averageMeteredCost

      if (graphDatums) {
        Object.values(graphDatums).forEach((datum) => {
          // Calculate the Metered Cost max/average/min
          if (!maxMeteredCost || datum.meteredCost > maxMeteredCost) {
            maxMeteredCost = datum.meteredCost
          }
          if (!minMeteredCost || datum.meteredCost < minMeteredCost) {
            minMeteredCost = datum.meteredCost
          }
          if (datum.meteredCost || datum.meteredCost === 0) {
            if (!averageMeteredCost) {
              averageMeteredCost = datum.meteredCost
            } else {
              averageMeteredCost = math.add(averageMeteredCost, datum.meteredCost)
            }
          }
        })

        let datumCount = Object.values(graphDatums).length

        if (averageMeteredCost && datumCount > 0) {
          averageMeteredCost = math.divide(averageMeteredCost, datumCount)
        }
      }

      return (
        <table className="econode-readings">
          <thead>
            <tr>
              <th></th>
              <th>{t("labels.highest")}</th>
              <th>{t("labels.average")}</th>
              <th>{t("labels.lowest")}</th>
            </tr>
          </thead>
          <tbody>
            <tr id="tooltip-metered">
              <UncontrolledTooltip placement="bottom" target="tooltip-metered">
                {t("econode.labels.tooltip_metered", { datumsAggregation: this.props.datumsAggregation })}
              </UncontrolledTooltip>
              <td className="label">{t("econode.dataTitles.Metered Charges")}:</td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("meteredCostReferenceLine", maxMeteredCost, `${t("econode.dataTitles.Highest Metered Cost")}`)
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("meteredCostReferenceLine")
                }}
              >
                {Utils.formatCurrency(maxMeteredCost)}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("meteredCostReferenceLine", averageMeteredCost, `${t("econode.dataTitles.Average Metered Cost")}`)
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("meteredCostReferenceLine")
                }}
              >
                {Utils.formatCurrency(averageMeteredCost)}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("meteredCostReferenceLine", minMeteredCost, `${t("econode.dataTitles.Lowest Metered Cost")}`)
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("meteredCostReferenceLine")
                }}
              >
                {Utils.formatCurrency(minMeteredCost)}
              </td>
            </tr>
          </tbody>
        </table>
      )
    }
  }
}
