import { Auth } from "aws-amplify"
import aws from "aws-sdk"
import moment from "moment"

import API, { IRange as Range } from "@common/API"
import ProjectUtils from "@common/utils/ProjectUtils"

const PhotoService = {
  async listPhotos(project: unknown, range: Range) {
    return this.getNodeIds(project).then(async (nodeIds) => {
      let photos: Array<aws.S3.Object | undefined> = []
      await Promise.all(
        nodeIds.map((nodeId) => {
          return this.listNodePhotos(project, nodeId as string, range).then((nodePhotos) => {
            photos = photos.concat(nodePhotos)
          })
        }),
      )
      return photos
    })
  },

  async listNodePhotos(project: unknown, nodeId: string, range: Range) {
    const credentials = await Auth.currentCredentials();
    const s3 = new aws.S3({
      apiVersion: "2013-04-01",
      region: "us-west-2",
      credentials: Auth.essentialCredentials(credentials),
    });

    const params = {
      Bucket: "ecogy-solarnodes",
      Prefix: `solarnode-resource-upload/${nodeId}/`,
    };

    const start = moment(range.start);
    const end = moment(range.end);

    let allPhotos: aws.S3.ObjectList = [];
    let isTruncated = true;
    let continuationToken: string | undefined;

    while (isTruncated) {
      const response = await s3
        .listObjectsV2({
          ...params,
          ContinuationToken: continuationToken,
        })
        .promise();

      const photos = response.Contents?.filter((file) => {
        const name = file.Key?.split("/")[2];
        const date = ProjectUtils.projectMoment(project, moment.utc(name?.split("-").at(-2)), "YYYYMMDDHHmmss");
        return date.isSameOrAfter(start) && date.isSameOrBefore(end);
      });

      allPhotos = allPhotos.concat(photos || []);

      isTruncated = response.IsTruncated || false;
      continuationToken = response.NextContinuationToken;
    }

    return allPhotos.map((file) => {
      const params = {
        Bucket: "ecogy-solarnodes",
        Key: file.Key,
      };

      const name = file.Key?.split("/")[2];
      // @ts-ignore
      file.date = ProjectUtils.projectMoment(project, moment.utc(name.split("-").at(-2)), "YYYYMMDDHHmmss");
      // @ts-ignore
      file.signedUrl = s3.getSignedUrl("getObject", params);
      // @ts-ignore
      file.nodeId = file.Key?.split("/")[1];
      return file;
    });
  },

  async getNodeIds(project: any) {
    return API.get("/solarnetwork/devices").then((response: any) => {
      const nodeIds = new Set()
      if (response.projects[project.code]) {
        Object.values(response.projects[project.code].sites).forEach((site: any) => {
          Object.values(site.systems).forEach((system: any) => {
            if (system.nodes) {
              Object.keys(system.nodes).forEach((nodeId) => {
                nodeIds.add(nodeId)
              })
            }
          })
        })
      }
      return Array.from(nodeIds)
    })
  },
}

export default PhotoService
