import EcosuiteForm from "@common/form/EcosuiteForm"
import * as React from "react"
import { Contact, useContact, useCreateContact, useSchema, useUpdateContact } from "src/services/contacts"
import { Button } from "reactstrap"
import { useHistory } from "react-router-dom"

export default function CreateContact({ id, noNavigate = false, afterSave = () => ({}) }: { id: string | undefined; noNavigate?: boolean; afterSave?: (v: any) => void }) {
  const { mutateAsync, isLoading, error } = useCreateContact()
  const { mutateAsync: updateMutateAsync, isLoading: updateIsLoading } = useUpdateContact()
  const { data: ContactSchema } = useSchema()
  const { data: contactData } = useContact(id)

  const history = useHistory()
  const [formState, setFormState] = React.useState(
    (id && contactData) ||
      ({
        name: "",
        type: "",
        email: "",
        phone: "",
        voip: "",
        notes: "",
      } as Omit<Contact, "id">),
  )

  React.useEffect(() => {
    if (contactData && id) {
      setFormState(contactData)
    }
  }, [contactData])

  if (!ContactSchema) return null

  return (
    <div style={{ padding: "16px" }}>
      <EcosuiteForm schema={JSON.parse(JSON.stringify(ContactSchema))} onChange={({ formData }) => setFormState(formData)} formData={formState}>
        <React.Fragment />
      </EcosuiteForm>
      <Button
        disabled={isLoading || updateIsLoading}
        color="primary"
        style={{ marginTop: "16px" }}
        onClick={async () => {
          let response = null
          if (id) {
            response = await updateMutateAsync({ ...formState, id })
          } else {
            response = await mutateAsync(formState)
          }
          if (!noNavigate) {
            history.push("/admin/contacts")
          }
          afterSave(response)
        }}
      >
        {isLoading ? "Submitting..." : "Submit"}
      </Button>
    </div>
  )
}
