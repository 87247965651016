import React, { useState } from "react"
import { Alert, Button, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import { getErrorMessage, uniqueKey } from "@common/SharedComponentUtils"
import "./Button.scss"
import Datetime from "react-datetime"
import Moment from "moment"
import i18n from "src/i18n"

/**
 * All valid BackdropDialog properties.
 */
export interface BackdropDialogProps {
  type: string
  title: string
  body?: JSX.Element
  bodyText?: string

  // Simple specific properties.
  simpleProps?: BackdropDialogSimpleProps
  // Input specific properties.
  inputProps?: BackdropDialogInputProps
  // Option specific properties.
  optionProps?: BackdropOptionProps
  // Date specific properties.
  dateProps?: BackdropDateProps

  inputTooltip?: string
  inputValidator?: (value: unknown | undefined) => boolean
  inputOnChangeHandler?: (input: unknown | undefined) => void
  onValidationErrorMessage?: string

  onConfirm?: (input: unknown | undefined) => void
  onComplete?: () => void
  onCancel?: () => void

  disableLoader?: boolean
  disableConfirm?: boolean
  confirmText?: string
  confirmTooltip?: string
  cancelTooltip?: string

  displayText?: string
  displayTextColor?: string

  displayResult?: JSX.Element
  displayResultBgColor?: string

  isOpen?: boolean
}

/**
 * All valid simple properties.
 */
export interface BackdropDialogSimpleProps {
  performText?: string
  performTextColor?: string
}

/**
 * The valid input properties.
 */
export interface BackdropDialogInputProps {
  inputPlaceholder?: string
  performText?: string
  performTextColor?: string
}

/**
 * The valid option properties.
 */
export interface BackdropOptionProps {
  options?: Record<string, string>
  defaultOptionKey?: string
  optionInfo: OptionInfo
  inputValidation?: (option: string | undefined) => void
  confirmText?: string
  onConfirm?: (selection: string | undefined) => void
}

/**
 * The associated option information.
 */
export interface OptionInfo {
  [key: string]: OptionInfoBundle
}

/**
 * The possible alert options.
 */
export interface OptionInfoBundle {
  color: string
  text: string
  performColor: string
  performText: string
}

export interface BackdropDateProps {
  inputPlaceholder?: string
  performText?: string
  performTextColor?: string
}

/**
 * The valid dialog functions.
 */
const { t } = i18n
export enum BackdropDialogFunction {
  Simple = "simple",
  Input = "input",
  Option = "option",
  Date = "date",
  DataList = "datalist", //combination of textbox and dropdown
}

/**
 * A backdrop dialog that currently supports plain input and option selection.
 * @param props - The properties.
 * @constructor
 */
export const BackdropDialog = (props: BackdropDialogProps): JSX.Element => {
  // Used if input function.
  const [currentValue, setCurrentValue] = useState<string | undefined>(undefined)

  // Used if option function.
  const [selectedOption, setSelectedOption] = useState<string | undefined>(props.optionProps?.defaultOptionKey ?? undefined)
  if (selectedOption === undefined && props.optionProps?.defaultOptionKey) setSelectedOption(props.optionProps?.defaultOptionKey)

  const [displayOptionWarning, setDisplayOptionWarning] = useState(false)

  // Used if date function.
  const [currentMoment, setCurrentMoment] = useState<Moment.Moment | string>()

  // Action related properties.
  const [performingAction, _setPerformingAction] = useState("")
  const [performingActionPlainText, _setPerformingActionPlainText] = useState("")

  // Display an error message.
  const [errorResponse, setErrorResponse] = useState<string | undefined>(undefined)

  const inputId = "backdrop-dialog-input"
  const confirmId = "backdrop-dialog-confirm"
  const cancelId = "backdrop-dialog-cancel"

  /**
   * Construct the options given from the properties.
   * @param options - The valid options.
   * @param defaultKey - The default option.
   */
  const constructOptions = (options: { [key: string]: string }, defaultKey?: string | undefined) => {
    const optionElements = []

    for (const [key, value] of Object.entries(options)) {
      // Remove the default from the list of options.
      if (key === defaultKey) continue

      optionElements.push(
        <option key={`backdrop-dialog-${uniqueKey()}`} value={key}>
          {value}
        </option>,
      )
    }

    return optionElements
  }

  /**
   * Display the given action, depending on the function of the dialog.
   * @param backdropFunction - The function of the dialog.
   */
  const displayAction = (backdropFunction: BackdropDialogFunction | string) => {
    switch (backdropFunction) {
      case BackdropDialogFunction.Simple:
        if (props.simpleProps?.performText) _setPerformingActionPlainText(props.simpleProps?.performText)
        break
      case BackdropDialogFunction.Input:
        if (props.inputProps?.performText) _setPerformingActionPlainText(props.inputProps?.performText)
        break
      case BackdropDialogFunction.Option || BackdropDialogFunction.DataList:
        if (selectedOption) {
          if (props.optionProps?.optionInfo?.[selectedOption].performText) _setPerformingAction(selectedOption)
        }
        break
      case BackdropDialogFunction.Date:
        if (props.dateProps?.performText) _setPerformingActionPlainText(props.dateProps?.performText)
    }
  }

  /**
   * Clear any actions.
   */
  const clearAction = () => {
    _setPerformingActionPlainText("")
    _setPerformingAction("")
  }

  /**
   * Check if there is any action currently occurring.
   */
  const isAction = () => {
    return performingActionPlainText !== "" || performingAction !== ""
  }

  /**
   * Display an error.
   * @param e - The error.
   */
  const displayError = (e: unknown) => {
    setErrorResponse(getErrorMessage(e))
  }

  switch (props.type) {
    case BackdropDialogFunction.Simple:
      return (
        <>
          <Modal isOpen={props.isOpen}>
            <ModalHeader>
              <p className={"backdrop-dialog-header"}>{props.title}</p>
            </ModalHeader>
            {props.body ? <ModalBody>{props.body}</ModalBody> : props.bodyText ? <ModalBody>{props.bodyText}</ModalBody> : undefined}
            {
              // Display some text to the user if desired.
              props.displayText && <Alert color={props.displayTextColor ?? "info"}>{props.displayText}</Alert>
            }
            {performingActionPlainText && <Alert color={props.simpleProps?.performTextColor ?? "warning"}>{performingActionPlainText}</Alert>}
            {props.displayResult && <Alert color={props.displayResultBgColor}>{props.displayResult}</Alert>}
            {errorResponse && (
              <Alert color={"danger"} toggle={() => setErrorResponse(undefined)}>
                {errorResponse}
              </Alert>
            )}
            <ModalFooter>
              <InputGroup>
                <UncontrolledTooltip target={confirmId}>{props.confirmTooltip ?? `${t("buttons.confirm")}`}</UncontrolledTooltip>
                <Button
                  id={confirmId}
                  disabled={isAction()}
                  color={"primary"}
                  onClick={async () => {
                    // Clear any previous errors.
                    setErrorResponse(undefined)

                    // See if the value passes validation.
                    if (props.inputValidator) {
                      try {
                        const valid = props.inputValidator.call(this, currentValue)
                        if (!valid) {
                          displayError(props.onValidationErrorMessage ?? `${t("errors.input_failed_validation")}`)
                          return
                        }
                      } catch (e) {
                        clearAction()
                        displayError(e)
                      }
                    }

                    // Display the text associated with performing some action.
                    displayAction(props.type)
                    try {
                      await props.onConfirm?.call(this, currentValue)

                      clearAction()
                      await props.onComplete?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {props.confirmText ?? `${t("buttons.confirm")}`}
                  {props.disableConfirm ?? false}
                </Button>
                <UncontrolledTooltip target={cancelId}>{props.cancelTooltip ?? `${t("buttons.cancel")}`}</UncontrolledTooltip>
                <Button
                  id={cancelId}
                  disabled={isAction()}
                  className={"backdrop-dialog-cancel"}
                  color={"danger"}
                  onClick={async () => {
                    try {
                      await props.onCancel?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {t("buttons.cancel")}
                </Button>
              </InputGroup>
            </ModalFooter>
          </Modal>
        </>
      )

    // INPUT DIALOG
    case BackdropDialogFunction.Input:
      return (
        <>
          <Modal isOpen={props.isOpen}>
            <ModalHeader>
              <p className={"backdrop-dialog-header"}>{props.title}</p>
            </ModalHeader>
            {props.body ? <ModalBody>{props.body}</ModalBody> : props.bodyText ? <ModalBody>{props.bodyText}</ModalBody> : undefined}
            {
              // Display some text to the user if desired.
              props.displayText && <Alert color={props.displayTextColor ?? "info"}>{props.displayText}</Alert>
            }
            {performingActionPlainText && <Alert color={props.inputProps?.performTextColor ?? "warning"}>{performingActionPlainText}</Alert>}
            {errorResponse && (
              <Alert color={"danger"} toggle={() => setErrorResponse(undefined)}>
                {errorResponse}
              </Alert>
            )}
            <ModalFooter>
              <InputGroup>
                {props.inputTooltip && <UncontrolledTooltip target={inputId}>{props.inputTooltip}</UncontrolledTooltip>}
                <Input
                  id={inputId}
                  disabled={isAction()}
                  placeholder={props.inputProps?.inputPlaceholder}
                  value={currentValue}
                  onChange={(value) => {
                    setCurrentValue(value.target.value.toString())
                    props.inputOnChangeHandler?.call(this, value.target.value.toString())
                  }}
                />
                <UncontrolledTooltip target={confirmId}>{props.confirmTooltip ?? `${t("buttons.confirm")}`}</UncontrolledTooltip>
                <Button
                  id={confirmId}
                  disabled={props.disableConfirm || isAction()}
                  color={"primary"}
                  onClick={async () => {
                    // Clear any previous errors.
                    setErrorResponse(undefined)

                    // See if the value passes validation.
                    if (props.inputValidator) {
                      try {
                        const valid = props.inputValidator.call(this, currentValue)
                        if (!valid) {
                          displayError(props.onValidationErrorMessage ?? `${t("errors.input_failed_validation")}`)
                          return
                        }
                      } catch (e) {
                        clearAction()
                        displayError(e)
                      }
                    }

                    // Display the text associated with performing some action.
                    displayAction(props.type)
                    try {
                      await props.onConfirm?.call(this, currentValue)

                      clearAction()
                      await props.onComplete?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {props.confirmText ?? `${t("buttons.confirm")}`}
                </Button>
                <UncontrolledTooltip target={cancelId}>{props.cancelTooltip ?? `${t("buttons.cancel")}`}</UncontrolledTooltip>
                <Button
                  id={cancelId}
                  disabled={isAction()}
                  className={"backdrop-dialog-cancel"}
                  color={"danger"}
                  onClick={async () => {
                    try {
                      await props.onCancel?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {t("buttons.cancel")}
                </Button>
              </InputGroup>
            </ModalFooter>
          </Modal>
        </>
      )
    case BackdropDialogFunction.Option:
      // OPTION DIALOG
      return (
        <>
          <Modal isOpen={props.isOpen}>
            <ModalHeader>
              <p className={"backdrop-dialog-header"}>{props.title}</p>
            </ModalHeader>
            {props.body ? <ModalBody>{props.body}</ModalBody> : props.bodyText ? <ModalBody>{props.bodyText}</ModalBody> : undefined}
            {
              // Display some text to the user if desired.
              props.displayText && <Alert color={props.displayTextColor ?? "info"}>{props.displayText}</Alert>
            }
            {performingAction
              ? props.optionProps?.optionInfo?.[performingAction].performText && (
                  <Alert color={props.optionProps?.optionInfo?.[performingAction].performColor ?? "warning"}>{props.optionProps?.optionInfo?.[performingAction].performText}</Alert>
                ) // Display associated information as an alert.
              : selectedOption &&
                props.optionProps?.optionInfo &&
                props.optionProps?.optionInfo?.[selectedOption] && <Alert color={props.optionProps?.optionInfo?.[selectedOption].color}>{props.optionProps?.optionInfo?.[selectedOption].text}</Alert>}
            {
              // Display a warning if the selection is invalid.
              displayOptionWarning && <Alert color={"danger"}>{t("alertsInfo.must_select_valid_option")}</Alert>
            }
            {errorResponse && (
              <Alert color={"danger"} toggle={() => setErrorResponse(undefined)}>
                {errorResponse}
              </Alert>
            )}
            <ModalFooter>
              <InputGroup>
                {props.inputTooltip && <UncontrolledTooltip target={inputId}>{props.inputTooltip}</UncontrolledTooltip>}
                <Input
                  type={"select"}
                  id={inputId}
                  disabled={isAction()}
                  value={selectedOption}
                  onChange={(option) => {
                    // Get rid of any warning if it is still present.
                    setDisplayOptionWarning(false)
                    setSelectedOption(option.target.value.toString())
                  }}
                >
                  {
                    // If provided with a default option, get it from the provided options.
                    props.optionProps?.defaultOptionKey && <option value={props.optionProps?.defaultOptionKey}>{props.optionProps?.options?.[props.optionProps?.defaultOptionKey]}</option>
                  }
                  {
                    // Display the given options.
                    props.optionProps?.options && constructOptions(props.optionProps?.options, props.optionProps?.defaultOptionKey)
                  }
                </Input>
                <UncontrolledTooltip target={confirmId}>{props.confirmTooltip ?? `${t("buttons.confirm")}`}</UncontrolledTooltip>
                <Button
                  id={confirmId}
                  disabled={performingAction !== ""}
                  color={"primary"}
                  onClick={async () => {
                    // Clear any previous errors.
                    setErrorResponse(undefined)

                    // See if the value passes validation.
                    if (props.inputValidator) {
                      try {
                        const valid = props.inputValidator.call(this, selectedOption)
                        if (!valid) {
                          displayError(props.onValidationErrorMessage ?? `${t("errors.input_failed_validation")}`)
                          return
                        }
                      } catch (e) {
                        clearAction()
                        displayError(e)
                      }
                    }

                    // If no option is selected, display the option warning.
                    if (!selectedOption) {
                      setDisplayOptionWarning(true)
                      return
                    }

                    // Display the text associated with performing some action.
                    displayAction(props.type)
                    try {
                      await props.onConfirm?.call(this, selectedOption)

                      clearAction()
                      await props.onComplete?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {props.inputProps ?? `${t("buttons.confirm")}`}
                </Button>
                <UncontrolledTooltip target={cancelId}>{props.cancelTooltip ?? `${t("buttons.cancel")}`}</UncontrolledTooltip>
                <Button
                  id={cancelId}
                  disabled={isAction()}
                  className={"backdrop-dialog-cancel"}
                  color={"danger"}
                  onClick={async () => {
                    try {
                      await props.onCancel?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {t("buttons.cancel")}
                </Button>
              </InputGroup>
            </ModalFooter>
          </Modal>
        </>
      )
    case BackdropDialogFunction.DataList:
      // OPTION DIALOG
      return (
        <>
          <Modal isOpen={props.isOpen}>
            <ModalHeader>
              <p className={"backdrop-dialog-header"}>{props.title}</p>
            </ModalHeader>
            {props.body ? <ModalBody>{props.body}</ModalBody> : props.bodyText ? <ModalBody>{props.bodyText}</ModalBody> : undefined}
            {errorResponse && (
              <Alert color={"danger"} toggle={() => setErrorResponse(undefined)}>
                {errorResponse}
              </Alert>
            )}
            {!props.disableLoader && <span className={"loader-spinner sf-loader-position"}></span>}
            <ModalFooter>
              <InputGroup>
                {props.inputTooltip && <UncontrolledTooltip target={inputId}>{props.inputTooltip}</UncontrolledTooltip>}

                <datalist id={inputId}>
                  {
                    // If provided with a default option, get it from the provided options.
                    props.optionProps?.defaultOptionKey && <option value={props.optionProps?.defaultOptionKey}>{props.optionProps?.options?.[props.optionProps?.defaultOptionKey]}</option>
                  }
                  {
                    // Display the given options.
                    props.optionProps?.options && constructOptions(props.optionProps?.options, props.optionProps?.defaultOptionKey)
                  }
                </datalist>
                <Input
                  autoComplete="on"
                  list={inputId}
                  id={inputId}
                  disabled={isAction()}
                  placeholder={props.inputProps?.inputPlaceholder}
                  value={currentValue}
                  onChange={(value) => {
                    setCurrentValue(value.target.value.toString())
                    props.inputOnChangeHandler?.call(this, value.target.value.toString())
                  }}
                />

                <UncontrolledTooltip target={confirmId}>{props.confirmTooltip ?? `${t("buttons.confirm")}`}</UncontrolledTooltip>
                <Button
                  id={confirmId}
                  disabled={props.disableConfirm || performingAction !== ""}
                  color={"primary"}
                  onClick={async () => {
                    // Clear any previous errors.
                    setErrorResponse(undefined)

                    // See if the value passes validation.
                    if (props.inputValidator) {
                      try {
                        const valid = props.inputValidator.call(this, selectedOption)
                        if (!valid) {
                          displayError(props.onValidationErrorMessage ?? `${t("errors.input_failed_validation")}`)
                          return
                        }
                      } catch (e) {
                        clearAction()
                        displayError(e)
                      }
                    }

                    // If no option is selected, display the option warning.
                    if (!selectedOption) {
                      setDisplayOptionWarning(true)
                      return
                    }

                    // Display the text associated with performing some action.
                    displayAction(props.type)
                    try {
                      await props.onConfirm?.call(this, selectedOption)

                      clearAction()
                      await props.onComplete?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {props.inputProps ?? `${t("buttons.confirm")}`}
                </Button>
                <UncontrolledTooltip target={cancelId}>{props.cancelTooltip ?? `${t("buttons.cancel")}`}</UncontrolledTooltip>
                <Button
                  id={cancelId}
                  disabled={isAction()}
                  className={"backdrop-dialog-cancel"}
                  color={"danger"}
                  onClick={async () => {
                    try {
                      await props.onCancel?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {t("buttons.cancel")}
                </Button>
              </InputGroup>
            </ModalFooter>
          </Modal>
        </>
      )
    case BackdropDialogFunction.Date:
      // DATE DIALOG
      return (
        <>
          <Modal isOpen={props.isOpen}>
            <ModalHeader>
              <p className={"backdrop-dialog-header"}>{props.title}</p>
            </ModalHeader>
            {props.body ? <ModalBody>{props.body}</ModalBody> : props.bodyText ? <ModalBody>{props.bodyText}</ModalBody> : undefined}
            {
              // Display some text to the user if desired.
              props.displayText && <Alert color={props.displayTextColor ?? "info"}>{props.displayText}</Alert>
            }
            {performingActionPlainText && <Alert color={props.dateProps?.performTextColor ?? "warning"}>{performingActionPlainText}</Alert>}
            {
              // Display a warning if the selection is invalid.
              displayOptionWarning && <Alert color={"danger"}>{t("alertsInfo.must_select_valid_option")}</Alert>
            }
            {errorResponse && (
              <Alert color={"danger"} toggle={() => setErrorResponse(undefined)}>
                {errorResponse}
              </Alert>
            )}
            <ModalFooter>
              <InputGroup>
                <Datetime inputProps={{ placeholder: props.dateProps?.inputPlaceholder }} closeOnSelect={true} onChange={(value) => setCurrentMoment(value)} />
                <Button
                  id={confirmId}
                  disabled={isAction()}
                  color={"primary"}
                  onClick={async () => {
                    // Clear any previous errors.
                    setErrorResponse(undefined)

                    // See if the value passes validation.
                    if (props.inputValidator) {
                      try {
                        let valid
                        if (currentMoment) {
                          valid = props.inputValidator.call(this, currentMoment)
                        }
                        if (!valid) {
                          displayError(props.onValidationErrorMessage ?? `${t("errors.input_failed_validation")}`)
                          return
                        }
                      } catch (e) {
                        clearAction()
                        displayError(e)
                      }
                    }

                    // Display the text associated with performing some action.
                    displayAction(props.type)
                    try {
                      await props.onConfirm?.call(this, currentMoment)

                      clearAction()
                      await props.onComplete?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {props.confirmText ?? `${t("buttons.confirm")}`}
                </Button>
                <UncontrolledTooltip target={cancelId}>{props.cancelTooltip ?? `${t("buttons.cancel")}`}</UncontrolledTooltip>
                <Button
                  id={cancelId}
                  disabled={isAction()}
                  className={"backdrop-dialog-cancel"}
                  color={"danger"}
                  onClick={async () => {
                    try {
                      await props.onCancel?.call(this)
                    } catch (e) {
                      clearAction()
                      displayError(e)
                    }
                  }}
                >
                  {t("buttons.cancel")}
                </Button>
              </InputGroup>
            </ModalFooter>
          </Modal>
        </>
      )
    default:
      // INVALID
      throw `${t("errors.incorrect_unknown_type", { type: props.type })}`
  }
}

export default BackdropDialog
