import API from "@common/API"

var EnergyPricingService = {
  getSitePricing(projectId, siteId, range, aggregate, sourceIds) {
    let url = API.addRangeToUrl("/finance/energy-price-datums/projects/" + projectId + "/sites/" + siteId, range)
    if (aggregate) {
      url = API.addParamToUrl(url, "aggregation", aggregate)
    }
    if (sourceIds) {
      url = API.addParamToUrl(url, "sourceIds", sourceIds)
    }
    return API.getFromCache(url, API.getDefaultCacheTimeout())
  },
}

export default EnergyPricingService
