import React from "react"
import { Alert, Button, Card, CardBody, CardTitle, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"
import RecordService from "@dashboard/data/record/RecordService"
import NoteService from "./RecordNoteService"
import { Note } from "../note/Note"
import i18n from "src/i18n"

const { t } = i18n
export default class Notes extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.loadNotes = this.loadNotes.bind(this)
    this.recordUpdated = this.recordUpdated.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadNotes()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate()

    if (prevProps.project !== this.props.project) {
      this.loadNotes()
    }
  }

  loadNotes() {
    this.setStateIfMounted({ records: null })
    const projectId = this.props.project.code
    RecordService.getProjectRecords(projectId).then((response) => {
      if (this.isProjectCurrent(projectId)) {
        this.setStateIfMounted({ records: response.records })
      } else {
        Logger.debug(`Ignoring out of date response for project: ${projectId}`)
      }
    })
  }

  recordUpdated(latestRecord) {
    const idx = this.state.records.findIndex((record) => record.id === latestRecord.id)
    this.state.records[idx] = latestRecord
    this.setStateIfMounted({ records: this.state.records })
  }

  renderContent() {
    if (this.state.records) {
      return (
        <div className="notes">
          {this.state.records.map((record, idx) => (
            <RecordNotes key={idx} record={record} project={this.props.project} loadNotes={this.loadNotes} recordUpdated={this.recordUpdated} />
          ))}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

class RecordNotes extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      note: "",
    }

    this.newNote = this.newNote.bind(this)
    this.editNote = this.editNote.bind(this)
    this.editComment = this.editComment.bind(this)
    this.resolveNote = this.resolveNote.bind(this)
    this.addCommentToNote = this.addCommentToNote.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.save = this.save.bind(this)
  }

  newNote(record) {
    this.setStateIfMounted({ record: record, note: undefined, noteIdx: undefined, commentIdx: undefined }, () => {
      this.toggleModal()
    })
  }

  editNote(record, noteIdx, text) {
    this.setStateIfMounted({ record: record, note: text, noteIdx: noteIdx, commentIdx: undefined }, () => {
      this.toggleModal()
    })
  }

  editComment(record, noteIdx, commentIdx, text) {
    this.setStateIfMounted({ record: record, note: text, noteIdx: noteIdx, commentIdx: commentIdx }, () => {
      this.toggleModal()
    })
  }

  save() {
    this.setStateIfMounted({ loading: true, error: undefined })
    const record = this.state.record
    const projectId = this.props.project.code
    const note = this.state.note
    const noteIdx = this.state.noteIdx
    const commentIdx = this.state.commentIdx

    if (isNaN(noteIdx)) {
      //We are creating a new note
      // We get the latest version of the record and add the note to it
      NoteService.getProjectRecord(projectId, record.id)
        .then((record) => {
          if (record) {
            NoteService.addNote(record, note).then(() => {
              this.setStateIfMounted({ record: undefined, note: undefined, loading: false, error: undefined })
              this.toggleModal()
              this.props.loadNotes()
            })
          } else {
            this.setStateIfMounted({ error: `${t("process.messages.no_existing_project")}`, loading: false })
          }
        })
        .catch((err) => {
          Logger.error(`Unable to create note: ${note} to record: ${record.id}`)
          Logger.error(err)
          this.setStateIfMounted({ error: `${t("process.messages.unable_tocreate_note")}: ${err.message}`, loading: false })
        })
    } else {
      if (isNaN(commentIdx)) {
        // We are edditing an exising note
        NoteService.editNote(record, noteIdx, note)
          .then((response) => {
            this.setStateIfMounted({ record: undefined, noteIdx: undefined, note: undefined, loading: false, error: undefined })
            this.toggleModal()
            this.props.recordUpdated(response.record)
          })
          .catch((err) => {
            Logger.error(`Unable to update note: ${note} on record: ${record.id}`)
            Logger.error(err)
            this.setStateIfMounted({ error: `${t("process.messages.unable_toupdate_note")}: ${err.message}`, loading: false })
          })
      } else {
        // We are editing an existing comment
        NoteService.editNoteComment(record, noteIdx, commentIdx, note)
          .then((response) => {
            this.setStateIfMounted({ record: undefined, noteIdx: undefined, commentIdx: undefined, note: undefined, loading: false, error: undefined })
            this.toggleModal()
            this.props.recordUpdated(response.record)
          })
          .catch((err) => {
            Logger.error(`Unable to update comment: ${note} on record: ${record.id}`)
            Logger.error(err)
            this.setStateIfMounted({ error: `${t("process.messages.unable_toupdate_comment")}: ${err.message}`, loading: false })
          })
      }
    }
  }

  resolveNote(record, noteIdx) {
    this.setStateIfMounted({ error: undefined })

    NoteService.resolveNote(record, noteIdx)
      .then((response) => {
        // The note has been resolved and filtered out
        this.props.recordUpdated(response.record)
      })
      .catch((err) => {
        Logger.error(`Unable to resolve note: ${noteIdx} to record: ${record.id}`)
        Logger.error(err)
        alert(`${t("process.messages.unable_toresolve_note")}: ${err.message}`)
      })
  }

  async addCommentToNote(record, noteIdx, comment) {
    this.setStateIfMounted({ error: undefined })

    return NoteService.addCommentToNote(record, noteIdx, comment)
      .then((response) => {
        this.props.recordUpdated(response.record)
      })
      .catch((err) => {
        Logger.error(`Unable to comment on note: ${noteIdx} of record: ${record.id}`)
        Logger.error(err)
        alert(`${t("process.messages.unable_toadd_comment_to_note")}: ${err.message}`)
      })
  }

  renderContent() {
    return (
      <Card className="note">
        <CardTitle>{this.props.record.name}</CardTitle>
        <CardBody>
          {this.props.record.notes
            ? this.props.record.notes.map((note, idx) => {
                if (note.resolved) {
                  return null // we do this to maintain the indexes
                } else {
                  return (
                    <Note
                      key={idx}
                      noteHolder={this.props.record}
                      noteIdx={idx}
                      note={note}
                      resolveNote={this.resolveNote}
                      addCommentToNote={this.addCommentToNote}
                      editNote={this.editNote}
                      editComment={this.editComment}
                    />
                  )
                }
              })
            : null}
          <Button
            size="sm"
            onClick={() => {
              this.newNote(this.props.record)
            }}
          >
            {t("process.labels.new_record_note")}
          </Button>
          {this.renderModal()}
        </CardBody>
      </Card>
    )
  }

  toggleModal() {
    this.setStateIfMounted({ open: !this.state.open, error: undefined })
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.open} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          {isNaN(this.state.noteIdx) ? `${t("labels.add")}` : `${t("buttons.edit")}`} {this.state.record ? `"${this.state.record.name}"` : ""}{" "}
          {isNaN(this.state.commentIdx) ? `${t("labels.note")}` : `${t("labels.comment")}`}
        </ModalHeader>
        {this.state.loading ? (
          <Loading message={`${isNaN(this.state.commentIdx)}` ? `${t("process.messages.saving_note")}` : `${t("process.messages.saving_comment")}`} />
        ) : (
          <React.Fragment>
            <ModalBody>
              <Input
                type="textarea"
                value={this.state.note}
                onChange={(e) => {
                  this.setStateIfMounted({ note: e.target.value })
                }}
              />

              {this.state.error ? <Alert color="danger">{this.state.error}</Alert> : null}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                {isNaN(this.state.noteIdx) ? `${t("labels.add")}` : `${t("buttons.update")}`} {isNaN(this.state.commentIdx) ? `${t("labels.note")}` : `${t("labels.comment")}`}
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleModal}>
                {t("buttons.cancel")}
              </Button>
            </ModalFooter>
          </React.Fragment>
        )}
      </Modal>
    )
  }
}
