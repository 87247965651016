import React from "react"
import { Card, CardContent } from "src/components/ui/card"
import { DashboardIcon } from "@radix-ui/react-icons"

const EmptyView = ({ text = "No data available" }) => {
  return (
    <Card className="twx-font tw-max-w-md tw-mx-auto tw-mt-8 tw-border-none tw-bg-gray-50 tw-shadow-none">
      <CardContent className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pb-12 tw-pt-12">
        <DashboardIcon className="tw-w-12 tw-h-12 tw-text-gray-400 tw-mb-4" />
        <p className="tw-text-sm tw-text-gray-500 tw-text-center">{text}</p>
      </CardContent>
    </Card>
  )
}

export default EmptyView
