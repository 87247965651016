import { isUrl } from "@udecode/plate"

/**
 * Check if a document is a Google document.
 * https://stackoverflow.com/a/30877431
 * @param uri - The URI.
 */
export const isGoogleDocument = (uri: string): boolean => {
  if (!isUrl(uri)) return false
  const parsedUri = new URL(uri)

  return parsedUri.host === "docs.google.com"
}
