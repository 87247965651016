import React from "react"
import { Button, Row, Col, Alert, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import Logger from "@common/Logger"
import EcosuiteComponent, { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { EcosuiteFieldTemplate, EcosuiteArrayFieldTemplate, FormError } from "@common/form/EcosuiteForm"
import HiddenFieldsObjectFieldTemplate from "@common/form/HiddenFieldsObjectFieldTemplate"
import EcosuiteObjectField from "@common/form/EcosuiteObjectField"
import EcosuiteNumberField from "@common/form/EcosuiteNumberField"
import Schemas from "@common/Schemas"

import RecordService from "../RecordService"
import getRecordUiSchema from "./RecordFormUiSchema"
import { deletionConfirmation } from "@common/DeleteConfirmationUtils"

import ProjectUtils from "@common/utils/ProjectUtils"
import hideFields from "@common/form/HideSchemaFields"
import ChangeNoteModal from "@common/form/ChangeNoteModal"
import i18n from "src/i18n"
import { Trans } from "react-i18next"
import TimedMessage from "@common/display/TimedMessage"
import { omit } from "lodash"

const { t } = i18n
/**
 * Form for editing and creating records.
 * @extends EcosuiteComponent
 */
export default class RecordForm extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.submitFormRef = React.createRef()
    this.state = { note: "" } // Explicitly set note to make it a controlled component

    this.createRecord = this.createRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.onRecordChange = this.onRecordChange.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.setStateIfMounted({ formData: this.props.record })
    this.loadSchema()
  }

  componentDidUpdate(prevProps) {
    if (this.props.record !== prevProps.record) {
      this.setStateIfMounted({
        error: undefined,
        success: undefined,
        formData: this.props.record,
      })
    }
  }

  loadSchema() {
    Schemas.getRecordSchema().then((schema) => {
      /* 
        EP-1972 The server side validation requires discriminator configuration to ensure that the correct validation is appliced.
        The application validation however doesn't like the duplicate required property validation that this requires so we need to remove it.
      */
      schema.dependencies.recordType.required = schema.dependencies.recordType.required.filter(
        (required) => required !== "recordType",
      )
      schema.dependencies.recordType.oneOf.forEach((oneOf) => {
        oneOf.dependencies.recordSubType.required = schema.dependencies.recordType.required.filter(
          (required) => required !== "recordSubType",
        )
      })

      this.setStateIfMounted({
        recordSchema: schema,
      })
    })
  }

  onRecordChange(form) {
    const record = form.formData

    this.props.recordModified(record)

    this.setStateIfMounted({ formData: record })
  }

  createRecord(form) {
    if (!this.props.project) {
      this.setStateIfMounted({ error: t("errors.project_required") })
      return
    }
    this.setStateIfMounted({ error: undefined, loading: true, success: false, formData: form.formData })

    // Remove fields from hiddenFields array if they are populated with data
    // Default hiddenFields to empty array if it doesn't exist.
    form.formData.hiddenFields =
      form.formData.hiddenFields?.filter((field) => {
        return form.formData[field] === undefined
      }) ?? []

    RecordService.createRecord(this.props.project, this.props.site, this.props.system, {
      ...form,
      formData: omit(form.formData, "__fileKey"),
    })
      .then(async (data) => {
        if (form.formData["__fileKey"]) {
          const distilledFile = await RecordService.distilleryAttachDocument(form.formData["__fileKey"])
          await RecordService.storeFile(data.record.id, "distilled", distilledFile)
        }
        this.setStateIfMounted({
          loading: false,
          formData: undefined,
        })
        this.props.recordAdded(data.record)
        this.props.recordModified(data.record) // Pass this up so we have an up to date view of the record
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false })
      })
  }

  editRecord(form) {
    if (!this.props.project) {
      this.setStateIfMounted({ error: t("errors.project_required") })
      return
    } else if (!this.state.note) {
      this.setStateIfMounted({ error: t("notes.change_note") })
      return
    }

    this.setStateIfMounted({ error: undefined, loading: true, success: false, formData: form.formData })

    // Remove fields from hiddenFields array if they are populated with data
    // Default hiddenFields to empty array if it doesn't exist.
    form.formData.hiddenFields =
      form.formData.hiddenFields?.filter((field) => {
        return form.formData[field] === undefined
      }) ?? []

    RecordService.updateRecord(
      this.props.project,
      this.props.site,
      this.props.system,
      this.props.record.id,
      form,
      this.state.note,
    )
      .then((data) => {
        this.setStateIfMounted({ loading: false, success: true, note: "" })
        this.props.recordUpdated(data.record)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false })
      })
  }

  confirmDelete(e) {
    if (!this.props.project) {
      this.setStateIfMounted({ error: t("errors.project_required") })
    } else {
      this.setState({ showModal: !this.state.showModal })
    }
    e.preventDefault()
  }

  deleteRecord(e) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: t("notes.change_note") })
      return
    }

    this.setStateIfMounted({ error: undefined, loading: true })

    RecordService.deleteRecord(this.props.record.id, this.state.note)
      .then(() => {
        this.setStateIfMounted({ loading: false, deleted: true, note: "" })
        this.props.recordDeleted(this.props.record)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false })
      })
    e.preventDefault()
  }

  /**
   * @param {(() => void) | undefined} callback - what to do after the state updates
   */
  toggleChangeNoteModal(callback) {
    this.setStateIfMounted({ isChangeNoteOpen: !this.state.isChangeNoteOpen }, callback)
  }

  /**
   Determines which fields of a schema should be hidden based on the user's permissions.
   @param {string[]} permissions - An array of permissions granted to the user.
   @returns {string[]} - An array of field names to hide from the schema based on the user's permissions.
   */
  getSchemaFieldsToHideBasedOnPermission(permissions) {
    const ret = []
    if (!permissions?.includes("notes")) {
      ret.push("notes")
    }
    return ret
  }

  renderContent() {
    if (this.state.loading || this.props.linkedCashFlow === "Loading") {
      return <Loading />
    }
    if (this.props.linkedCashFlow instanceof EcosuiteComponentError) {
      return <Error error={this.props.linkedCashFlow.getError()} />
    }
    if (this.state.deleted) {
      return <div>{t("messages.deleted")}</div>
    }

    if (this.state.recordSchema && this.state.formData) {
      return (
        <div className="ecogy-form">
          <EcosuiteForm
            schema={hideFields(this.getSchemaFieldsToHideBasedOnPermission(this.props.groups), this.state.recordSchema)}
            uiSchema={getRecordUiSchema(
              this.state.formData,
              this.props.readonly,
              this.props.autoCompletes,
              this.props.viewableRecordTypes,
              this.props.disabledSubTypes,
              this.state.formData,
              (contactIds) => {
                this.setState({ formData: { ...this.state.formData, contactIds } })
              },
            )}
            formData={this.state.formData}
            onSubmit={this.props.record.id ? this.editRecord : this.createRecord}
            onChange={this.onRecordChange}
            FieldTemplate={EcosuiteFieldTemplate}
            ObjectFieldTemplate={HiddenFieldsObjectFieldTemplate}
            ArrayFieldTemplate={EcosuiteArrayFieldTemplate}
            fields={{ ObjectField: EcosuiteObjectField, NumberField: EcosuiteNumberField }}
            formContext={{
              record: this.state.formData,
              readonly: this.props.readonly,
              startDate: this.props.project ? ProjectUtils.getProjectStartDate([this.props.project]) : undefined,
              toggleHiddenField: (fieldName) => {
                // Make a copy of the current hidden fields (default to empty array)
                const hiddenFields = [...(this.state.formData?.hiddenFields ?? [])]
                // Toggle hidden field's state
                if (hiddenFields.includes(fieldName)) {
                  hiddenFields.splice(hiddenFields.indexOf(fieldName), 1)
                } else {
                  hiddenFields.push(fieldName)
                }
                this.setStateIfMounted((prev) => ({ formData: { ...prev.formData, hiddenFields } }))
              },
            }}
            disabled={this.props.readonly}
          >
            {this.props.readonly ? null : (
              <Row className="ecogy-form-buttons">
                {this.props.record.id ? (
                  <>
                    <Col className="message-section" sm="10">
                      {this.renderMessages()}
                    </Col>

                    <Col className="button-section">
                      <Button
                        color="primary"
                        onClick={() => this.toggleChangeNoteModal()}
                        disabled={this.props.readonly}
                      >
                        {t("buttons.submit")}
                      </Button>
                      <Button color="danger" onClick={this.confirmDelete} disabled={this.props.readonly}>
                        {t("buttons.delete")}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <React.Fragment>
                    <Col className="message-section" sm="11">
                      {this.renderMessages()}
                    </Col>
                    <Col className="button-section" sm="12">
                      <Button color="primary" type="submit" disabled={this.props.readonly}>
                        {t("buttons.submit")}
                      </Button>
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            )}

            {/* react-jsonschema-form doesn't expose submit() or requestSubmit() inside of its ref */}
            {/* So a reference to this button is needed to submit from inside a modal / nested component */}
            <button ref={(ref) => (this.submitFormRef = ref)} style={{ display: "none" }} />
          </EcosuiteForm>

          {this.renderModal()}
          <ChangeNoteModal
            isOpen={this.state.isChangeNoteOpen}
            toggle={() => this.toggleChangeNoteModal()}
            submit={() => this.toggleChangeNoteModal(() => this.submitFormRef.click())}
            canSubmit={!!this.state.note}
          >
            <Col className="audit-section">
              <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
              <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
            </Col>
          </ChangeNoteModal>
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.showModal} toggle={this.confirmDelete}>
        <ModalHeader toggle={this.confirmDelete}>{`${t("data.delete_record")}: ${
          this.props.record.name
        }?`}</ModalHeader>
        <ModalBody>
          {this.props.linkedCashFlow ? (
            // Render this danger alert and disabled the delete button if there is a linkedCashFlow
            <Alert color="danger">
              <Alert color="danger">{t("alertsInfo.record_being_referenced", { name: this.props.record.name })}</Alert>
            </Alert>
          ) : (
            <>
              <Alert color="warning">{t("alertsInfo.warning_alert")}</Alert>
              <Trans i18nKey="notes.cannot_be_undone">
                <p>
                  This action <b>cannot</b> be undone.
                </p>
              </Trans>
              <Trans i18nKey="notes.permanent_action">
                <p>
                  This action will <b>permanently</b> delete this record from our database.
                </p>
              </Trans>
              <p>{t("data.confirm_msg")}</p>
              <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
              <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
              <br />

              <span>
                {t("notes.to_delete", { name: "Record" })}{" "}
                <b style={{ color: "red" }}>{`Delete ${this.props.record.name}`}</b>
              </span>
              <Input
                value={this.state.deleteConfirmation}
                onChange={(e) => this.setState({ deleteConfirmation: e.target.value })}
                className="audit-note"
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={this.deleteRecord}
            disabled={
              !!this.props.linkedCashFlow ||
              !this.state.note ||
              !deletionConfirmation(this.state.deleteConfirmation, this.props.record.name)
            }
          >
            {t("buttons.delete_record")}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              this.setState({ showModal: false })
            }}
          >
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderMessages() {
    return (
      <React.Fragment>
        {this.state.success ? (
          <TimedMessage delay={8000}>
            <Alert color="info">{t("alertsInfo.record_updated")}</Alert>
          </TimedMessage>
        ) : null}
        <FormError
          error={this.state.error}
          toggle={() => {
            this.setStateIfMounted({ error: null })
          }}
        />
      </React.Fragment>
    )
  }
}
