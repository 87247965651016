type Aggregation = "5minute" | "15minute" | "hour" | "day" | "month" | "year" | "none"

export const Aggregations = {
  FiveMinute: "5minute" as const,
  FifteenMinute: "15minute" as const,
  Hour: "hour" as const,
  Day: "day" as const,
  Month: "month" as const,
  Year: "year" as const,
  NONE: "none" as const,
  MINIMUM: "5minute" as const,

  getSize(aggregation: Aggregation): number {
    if (aggregation === Aggregations.NONE) {
      return 1 // For calculation purposes we treat none as minutely data though in practice it could be more or even less
    } else if (aggregation === Aggregations.FiveMinute) {
      return 5
    } else if (aggregation === Aggregations.FifteenMinute) {
      return 15
    } else {
      return 1
    }
  },

  getUnit(aggregation: Aggregation): string {
    if (aggregation === Aggregations.NONE || aggregation === Aggregations.FiveMinute || aggregation === Aggregations.FifteenMinute) {
      // For calculation purposes we treat none as minutely data though in practice it could be more or even less
      return "minute"
    } else {
      return aggregation
    }
  },

  getUnits(aggregation: Aggregation): string {
    return Aggregations.getUnit(aggregation) + "s"
  },

  isMinuteLevel(aggregation: Aggregation): boolean {
    return !aggregation || aggregation === Aggregations.NONE || aggregation === Aggregations.FiveMinute || aggregation === Aggregations.FifteenMinute
  },
}

export default Aggregations
