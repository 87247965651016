import React from "react"
import { Table, DataType, SortingMode, SortDirection } from "ka-table"
import { DocumentItem, useGetOnboardingDocuments, useGetUserOnboardingData } from "src/services/onboarding"
import { H4 } from "src/components/ui/typography"
import WheelLoader from "./Loader"
import { Spinner } from "src/components/ui/spinner"
import { Button } from "src/components/ui/button"
import { DownloadIcon, EyeOpenIcon } from "@radix-ui/react-icons"
import { useFilePreview, PreviewDialog, PreviewFile, downloadFile, viewFile } from "../Components/Preview"
import { toast } from "react-toastify"

const calculateDocumentProgress = (documentAge: number, isDone: boolean): number => {
  if (isDone) return 1

  const ageInMinutes = documentAge / (1000 * 60)
  const maxTimeBasedProgress = 0.3 // Maximum time-based progress
  const maxInitialProgress = maxTimeBasedProgress * 0.2 // Max initial progress (20% of maxTimeBasedProgress)

  // Rapid initial increase: linearly increase to 20% of maxTimeBasedProgress in the first 30 minutes
  const initialProgress = Math.min(maxInitialProgress, (ageInMinutes / 60) * maxInitialProgress)

  // Slower increase after the first 30 minutes
  const remainingProgress = maxTimeBasedProgress - maxInitialProgress // Remaining progress for long-term
  const ageAfterFirstHalfHour = Math.max(0, ageInMinutes - 60) // Time after the first 30 minutes
  const tau = 225 // Time constant in minutes

  const longTermProgress = remainingProgress * (1 - Math.exp(-ageAfterFirstHalfHour / tau)) // Exponential increase

  const totalTimeBasedProgress = initialProgress + longTermProgress

  const maxProgress = 0.99 // Max progress unless marked done

  // Combine time-based progress and cap it at 30%
  const totalProgress = Math.min(totalTimeBasedProgress, maxTimeBasedProgress)

  return Math.min(totalProgress, maxProgress)
}

const calculateProgress = (documents: DocumentItem[], markedDone: string[]): number => {
  if (documents.length === 0) return 0

  const now = new Date().getTime()
  const progressPerDocument = 100 / documents.length

  let totalProgress = 0

  documents.forEach((doc) => {
    const documentAge = now - new Date(doc.updated).getTime()
    const isDone = markedDone.includes(doc.fileKey)
    const documentProgress = calculateDocumentProgress(documentAge, isDone)
    totalProgress += documentProgress * progressPerDocument
  })

  // Round to one decimal place
  return Math.round(totalProgress * 10) / 10
}

const ActionButtons: React.FC<{
  rowData: DocumentItem
}> = ({ rowData }) => {
  const [loadingDownload, setLoadingDownload] = React.useState(false)

  const { previewFile, setPreviewFile, pdfLoadError, handlePreview, isLoading: loadingPreview } = useFilePreview()

  const handleDownload = async (file: PreviewFile) => {
    try {
      setLoadingDownload(true)
      const url = await viewFile(file.fileKey!)
      await downloadFile(url, file.fileName!)
      toast.success("Succesfully downloaded file! Check your downloads folder.")
    } catch (error) {
      toast.error("Download failed!")
    } finally {
      setLoadingDownload(false)
    }
  }

  return (
    <>
      <PreviewDialog
        previewFile={previewFile}
        setPreviewFile={setPreviewFile}
        pdfLoadError={pdfLoadError}
        isLoading={loadingPreview}
      />
      <div className="tw-flex">
        <Button
          size="sm"
          variant="outline"
          className="tw-p-2"
          disabled={loadingPreview}
          onClick={() => handlePreview({ fileName: rowData.name, fileKey: rowData.fileKey })}
        >
          {loadingPreview ? <Spinner className="tw-h-4 tw-w-4" /> : <EyeOpenIcon className="tw-h-4 tw-w-4" />}
        </Button>
        <Button
          size="sm"
          variant="outline"
          className="tw-p-2 tw-ml-2"
          disabled={loadingDownload}
          onClick={() => handleDownload({ fileName: rowData.name, fileKey: rowData.fileKey })}
        >
          {loadingDownload ? <Spinner className="tw-h-4 tw-w-4" /> : <DownloadIcon className="tw-h-4 tw-w-4" />}
        </Button>
      </div>
    </>
  )
}

export const OnboardingDocumentsTable: React.FC = () => {
  const { data, isLoading } = useGetOnboardingDocuments()

  if (isLoading) {
    return (
      <div className="tw-py-16">
        <Spinner />
      </div>
    )
  }

  const tableData =
    data?.documents.map((doc) => ({
      fileKey: doc.fileKey,
      name: doc.name,
      updated: new Date(doc.updated).toLocaleString(),
    })) || []

  return (
    <div className="twx-ka-table tw-pt-4">
      <Table
        columns={[
          { key: "action", title: "Actions", dataType: DataType.String, width: 160 },
          { key: "name", title: "Document Name", dataType: DataType.String },
          {
            key: "updated",
            title: "Last Updated",
            dataType: DataType.String,
            width: 280,
            sortDirection: SortDirection.Ascend,
          },
        ]}
        virtualScrolling={{ enabled: true }}
        noData={{ text: "No documents found! Please upload documents from upload tab!" }}
        data={tableData}
        rowKeyField="fileKey"
        sortingMode={SortingMode.Single}
        childComponents={{
          cellText: {
            content: (props) => {
              if (props.column.key === "action") {
                return <ActionButtons rowData={props.rowData} />
              }
              return props.value
            },
          },
          tableWrapper: {
            elementAttributes: () => ({ style: { maxHeight: "calc(100vh - 580px)" } }),
          },
        }}
      />
    </div>
  )
}

export default function ListMyDocuments() {
  const { data: documentsData, isLoading: documentsLoading } = useGetOnboardingDocuments()
  const { data: metaData, isLoading: metaDataLoading } = useGetUserOnboardingData()
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    if (!documentsData || !metaData) return

    const updateProgress = () => {
      const currentProgress = calculateProgress(documentsData.documents || [], metaData.markedDone || [])
      setProgress(currentProgress)
    }

    updateProgress() // Update immediately

    const interval = setInterval(() => {
      updateProgress()
    }, 1000) // Update every second

    return () => clearInterval(interval)
  }, [documentsData, metaData])

  if (documentsLoading || metaDataLoading || !documentsData || !metaData) {
    return (
      <div className="public-view tw-bg-background tw-text-foreground tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Spinner size="large">Loading...</Spinner>
      </div>
    )
  }

  const userHasDocuments = documentsData.documents.length > 0

  return (
    <div>
      <H4>
        {userHasDocuments
          ? "Your uploaded documents are being distilled. Your org will be set-up automatically soon. This process takes a number of days, hence the progress bar will reflect that."
          : "Please upload your documents! Uploaded documents will be distilled and your org will be set-up automatically from these distilled data. This typically will take a few days!"}
      </H4>
      <div className="tw-mt-8 lg:tw-mt-32">
        <WheelLoader progress={Number(progress.toFixed(2))} notStarted={!userHasDocuments} />
      </div>
      <H4 className="tw-mt-8">Your uploaded documents</H4>
      <OnboardingDocumentsTable />
    </div>
  )
}
