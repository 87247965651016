import React from "react"
import { Button, ButtonProps } from "src/components/ui/button"
import { ReloadIcon } from "@radix-ui/react-icons"

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
  children: React.ReactNode
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  children,
  loading = false,
  disabled = false,
  onClick,
  ...props
}) => {
  return (
    <Button onClick={onClick} disabled={disabled || loading} {...props}>
      {loading && <ReloadIcon className="tw-mr-2 tw-h-4 tw-w-4 tw-animate-spin" />}
      {children}
    </Button>
  )
}

export default LoadingButton
