import React from "react"
import { ButtonToolbar, ButtonGroup, Button } from "reactstrap"
import moment from "moment"

import { Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import Utils from "@common/utils/Utils"
import SyncProjectMilestones from "./SyncProjectMilestones"
import i18n from "src/i18n"

const { t } = i18n
const TABLE_HEADERS = [
  `${t("table_headings.code")}`,
  `${t("table_headings.name")}`,
  `${t("table_headings.status")}`,
  `${t("table_headings.ac_size")} (kW)`,
  `${t("table_headings.dc_size")} (kW)`,
  `${t("table_headings.system_types")}`,
  `${t("table_headings.state")}`,
  `${t("table_headings.utilities")}`,
  `${t("table_headings.initiated")}`,
  `${t("table_headings.interconnection")}`,
  `${t("table_headings.permitting")}`,
  `${t("table_headings.construction")}`,
  `${t("table_headings.PTO")}`,
  `${t("table_headings.operational")}`,
]

export default class ProjectList extends EcosuiteView {
  constructor(props) {
    super(props, "project-list")
  }

  systemTypeSet(project) {
    if (!project.sites) {
      return
    }

    return Object.values(project.sites).reduce((systemTypeSet, site) => {
      if (site.systems) {
        let hasStorage = false

        Object.values(site.systems).map((system) => {
          if (system.type === "storage") {
            hasStorage = true
          }
          if (system.subType) {
            systemTypeSet.add(`${Utils.capitalizeFirstLetter(Utils.splitCamelCase(system.subType))}`)
          }
        })

        if (hasStorage) {
          systemTypeSet.add("Storage")
        }
      }

      return systemTypeSet
    }, new Set())
  }

  utilityTypeSet(project) {
    if (!project.sites) {
      return
    }

    return Object.values(project.sites).reduce((projectUtilitySet, site) => {
      if (site.utility) {
        projectUtilitySet.add(`${Utils.capitalizeFirstLetter(Utils.splitCamelCase(site.utility))}`)
      }

      return projectUtilitySet
    }, new Set())
  }

  renderMainView() {
    if (this.props.projects) {
      if (this.props.projects.length > 0) {
        return (
          <table className="project-table">
            <thead>
              <tr>
                {TABLE_HEADERS.map((header) => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.projects.map((project) => {
                return (
                  <tr key={project.code}>
                    <td>{project.code}</td>
                    <td>{project.name}</td>
                    <td>{project.status}</td>
                    <td className="numerical">
                      {project.acSize?.toLocaleString("en-US", { maximumFractionDigits: 1 })}
                    </td>
                    <td className="numerical">
                      {project.dcSize?.toLocaleString("en-US", { maximumFractionDigits: 1 })}
                    </td>
                    <td className="mutli-line-cell">{Array.from(this.systemTypeSet(project)).join("\n")}</td>
                    <td>{project.state}</td>
                    <td className="mutli-line-cell">{Array.from(this.utilityTypeSet(project)).join("\n")}</td>
                    <td className="numerical">
                      {project.milestones?.initiated ? moment(project.milestones.initiated).format("ll") : ""}
                    </td>
                    <td className="numerical">
                      {project.milestones?.interconnection
                        ? moment(project.milestones.interconnection).format("ll")
                        : ""}
                    </td>
                    <td className="numerical">
                      {project.milestones?.permitting ? moment(project.milestones.permitting).format("ll") : ""}
                    </td>
                    <td className="numerical">
                      {project.milestones?.construction ? moment(project.milestones.construction).format("ll") : ""}
                    </td>
                    <td className="numerical">
                      {project.milestones?.pto ? moment(project.milestones.pto).format("ll") : ""}
                    </td>
                    <td className="numerical">
                      {project.milestones?.operational ? moment(project.milestones.operational).format("ll") : ""}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )
      } else {
        return <div className="no-documents">{t("alertsInfo.no_projects")}</div>
      }
    } else {
      return <Loading />
    }
  }

  renderViewControls() {
    if (this.props.groups.includes("data-write")) {
      return (
        <div className="content-view-controls">
          <SyncProjectMilestones />
          <ButtonToolbar className="float-end">
            <ButtonGroup className="header-button-group">
              <Button
                color="ecogy"
                onClick={() => {
                  this.props.actions.selectProject({})
                }}
                size="sm"
              >
                {t("labels.new_project")}
              </Button>
            </ButtonGroup>

            {/* Download JSON and CSV buttons */}
            {this.props.projects && this.props.projects.length > 0 && (
              <ButtonGroup>
                <Button
                  color="ecogy"
                  size="sm"
                  onClick={() => {
                    const projectTableMap = this.props.projects.map((project) => {
                      return {
                        Code: project.code,
                        Name: project.name,
                        Status: project.status,
                        "AC Size (kW)": project.acSize,
                        "DC Size (kW)": project.dcSize,
                        "System Types": this.systemTypeSet(project),
                        State: project.state,
                        Utilities: this.utilityTypeSet(project),
                        Initiated: project.milestones?.initiated,
                        Interconnection: project.milestones?.interconnection,
                        Permitting: project.milestones?.permitting,
                        Construction: project.milestones?.construction,
                        PTO: project.milestones?.pto,
                        Operational: project.milestones?.operational,
                      }
                    })
                    const json = JSON.stringify(projectTableMap)
                    const a = document.createElement("a")
                    a.href = `data:text/json;charset=utf-8,${encodeURIComponent(json)}`
                    a.download = `${this.props.portfolio?.name ?? "unknown"}-projects-table.json`
                    a.click()
                  }}
                >
                  {t("buttons.download_json")}
                </Button>
                <Button
                  color="ecogy"
                  size="sm"
                  onClick={() => {
                    const data = this.props.projects.reduce((csvData, project) => {
                      csvData.push(
                        [
                          project.code,
                          project.name,
                          project.status,
                          project.acSize,
                          project.dcSize,
                          `"${Array.from(this.systemTypeSet(project)).join(",")}"`,
                          project.state,
                          `"${Array.from(this.utilityTypeSet(project)).join(",")}"`,
                          project.milestones?.initiated,
                          project.milestones?.interconnection,
                          project.milestones?.permitting,
                          project.milestones?.construction,
                          project.milestones?.pto,
                          project.milestones?.operational,
                        ].join(","),
                      )
                      return csvData
                    }, [])
                    const csv = [TABLE_HEADERS, ...data].join("\n")
                    const a = document.createElement("a")
                    a.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
                    a.download = `${this.props.portfolio?.name ?? "unknown"}-projects-table.csv`
                    a.click()
                  }}
                >
                  {t("buttons.download_csv")}
                </Button>
              </ButtonGroup>
            )}
          </ButtonToolbar>
        </div>
      )
    } else {
      return null
    }
  }
}
