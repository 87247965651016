import React from "react"
import { useUserOrganization, useUpdateOrganization } from "../../services/users"
import { ThemeProvider } from "@aws-amplify/ui-react"
import OrganizationSettingsForm from "./OrganizationSettingsForm"
import { theme } from "src"

interface OrganizationSettingsProps {
  userId: string
  projects: Project[]
}

const OrganizationSettings: React.FC<OrganizationSettingsProps> = ({ userId, projects }) => {
  const { data, isLoading, isError } = useUserOrganization(userId)


  React.useEffect(() => {
    document.title = "Org Preferences - Ecosuite"
  })

  if (!data) return null

  return (
    <ThemeProvider theme={theme}>
      <OrganizationSettingsForm projects={projects} organizationId={data.organization.id} />
    </ThemeProvider>
  )
}

export default OrganizationSettings
