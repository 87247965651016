import { Button, TextField, View } from "@aws-amplify/ui-react";
import * as React from "react";
import { toast } from "react-toastify";

interface SolarNetworkObject {
  id?: string | number;
  name: string;
  enabled: boolean;
  level?: number;
  index?: number;
  [key: string]: any;
}

export interface Field {
  name: string;
  label: string;
  type: 'text' | 'number' | 'checkbox' | 'select';
  options?: { label: string; value: string | number }[];
}

interface ChildConfig<T> {
  title: string;
  fields: (parents: Record<string, any>[]) => Field[];
  parentIdField?: string;
  hideEdit?: boolean;
  onAdd: (parentId: string | number, newObject: Partial<T>, parents: Record<string, any>[]) => Promise<void>;
  onUpdate: (parentId: string | number, object: T, index: number, parents: Record<string, any>[]) => Promise<void>;
  onDelete: (parentId: string | number, object: T, index: number, parents: Record<string, any>[]) => Promise<void>;
  getChildren: (parentId: string | number) => Promise<T[]>;
  childConfigs?: Record<string, ChildConfig<any>>;
}

interface SolarNetworkObjectListProps<T extends SolarNetworkObject, C extends Record<string, any> = object> {
  title: string;
  objects: T[];
  fields: (parents: Record<string, any>[]) => Field[];
  hideEdit?: boolean;
  onAdd: (newObject: Partial<T>, parents: Record<string, any>[]) => Promise<void>;
  onUpdate: (object: T, parents: Record<string, any>[]) => Promise<void>;
  onDelete: (object: T, parents: Record<string, any>[]) => Promise<void>;
  isLoading?: boolean;
  childConfigs?: Record<string, ChildConfig<any>>;
  parents?: Record<string, any>[];
}

export function SolarNetworkObjectList<T extends SolarNetworkObject, C extends Record<string, any> = object>({
  title,
  objects,
  fields,
  hideEdit,
  onAdd,
  onUpdate,
  onDelete,
  isLoading,
  childConfigs,
  parents = []
}: SolarNetworkObjectListProps<T, C>) {
  const [showNewForm, setShowNewForm] = React.useState(false);
  const [newObject, setNewObject] = React.useState<Partial<T>>({} as T);
  const [editingObject, setEditingObject] = React.useState<Record<string | number, boolean>>({});
  const [editedObjects, setEditedObjects] = React.useState<Record<string | number, T>>({});
  const [childrenByParent, setChildrenByParent] = React.useState<Record<string | number, Partial<C>>>({});
  const [loadingChildren, setLoadingChildren] = React.useState<Record<string, Record<string | number, boolean>>>({});
  const [expandedChildren, setExpandedChildren] = React.useState<Record<string, Record<string | number, boolean>>>({});

  const handleAdd = async () => {
    try {
      await onAdd(newObject, parents);
      setShowNewForm(false);
      setNewObject({} as T);
      toast.success(`${title} added successfully`);
    } catch (error) {
      console.error(`Failed to add ${title}:`, error);
      toast.error(`Failed to add ${title}`);
    }
  };

  const getObjectKey = (object: T) => {
    // Create a unique key that includes both the ID, any parent ID, and the object's level in the hierarchy
    const parts = [];
    if (object.level) parts.push(`level${object.level}`);
    if (object.parentId) parts.push(`parent${object.parentId}`);
    if (object.id) parts.push(`id${object.id}`);
    return parts.join('-');
  };

  const handleUpdate = async (object: T) => {
    try {
      const objectKey = getObjectKey(object);
      if (!objectKey) return;
      
      await onUpdate(editedObjects[objectKey], parents);
      setEditingObject(prev => ({ ...prev, [objectKey]: false }));
      toast.success(`${title} updated successfully`);
    } catch (error) {
      console.error(`Failed to update ${title}:`, error);
      toast.error(`Failed to update ${title}`);
    }
  };

  const handleDelete = async (object: T) => {
    if (!confirm(`Are you sure you want to delete this ${title}?`)) return;

    try {
      await onDelete(object, parents);
      toast.success(`${title} deleted successfully`);
    } catch (error) {
      console.error(`Failed to delete ${title}:`, error);
      toast.error(`Failed to delete ${title}`);
    }
  };

  const renderField = (field: Field, value: any, onChange: (value: any) => void) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <label>
            <input
              type="checkbox"
              checked={value ?? false}
              onChange={(e) => onChange(e.target.checked)}
              style={{ marginRight: '0.5rem' }}
            />
            {field.label}
          </label>
        );
      case 'select':
        return (
          <select
            value={value ?? ''}
            onChange={(e) => onChange(e.target.value)}
            style={{ width: '100%', padding: '0.5rem', backgroundColor: 'rgba(255, 255, 255, 0.05)' }}
          >
            <option value="">Select {field.label}</option>
            {field.options?.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <TextField
            label={field.label}
            value={value ?? ''}
            onChange={(e) => onChange(e.target.value)}
            type={field.type}
            style={{ color: 'white' }}
            className="amplify-input--light"
          />
        );
    }
  };

  const loadChildren = async (parentId: string | number, childType: string) => {
    if (!childConfigs?.[childType]) return;
    
    setLoadingChildren(prev => ({
      ...prev,
      [childType]: { ...(prev[childType] || {}), [parentId]: true }
    }));

    try {
      const children = await childConfigs[childType].getChildren(parentId);
      setChildrenByParent(prev => ({
        ...prev,
        [parentId]: {
          ...(prev[parentId] || {}),
          [childType]: children
        }
      }));
    } catch (error) {
      console.error(`Failed to load ${childConfigs[childType].title}:`, error);
      toast.error(`Failed to load ${childConfigs[childType].title}`);
    } finally {
      setLoadingChildren(prev => ({
        ...prev,
        [childType]: { ...(prev[childType] || {}), [parentId]: false }
      }));
    }
  };

  // Get fields with parent context
  const currentFields = fields(parents);

  if (isLoading) {
    return <div>Loading {title}...</div>;
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h5>{title}</h5>
        <Button
          onClick={() => setShowNewForm(!showNewForm)}
          style={{ color: 'white' }}
        >
          Add New {title}
        </Button>
      </div>

      {showNewForm && (
        <View marginBottom="1rem" padding="1rem" backgroundColor="rgba(255, 255, 255, 0.05)" borderRadius="4px">
          <h6>New {title}</h6>
          {currentFields.map(field => (
            <div key={field.name} style={{ marginBottom: '1rem' }}>
              {renderField(field, newObject[field.name], (value) => {
                setNewObject(prev => ({ ...prev, [field.name]: value }));
              })}
            </div>
          ))}
          <Button onClick={handleAdd} style={{ marginRight: '0.5rem', color: 'white' }}>
            Add
          </Button>
          <Button onClick={() => setShowNewForm(false)} style={{ color: 'white' }}>
            Cancel
          </Button>
        </View>
      )}

      <div style={{ display: 'grid', gap: '1rem' }}>
        {objects.map((object, idx) => {
          const objectKey = getObjectKey(object);
          const isEditing = objectKey ? editingObject[objectKey] : false;
          
          return (
            <div 
              key={idx}
              style={{ 
                padding: '1rem',
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: 'rgba(255, 255, 255, 0.05)'
              }}
            >
              <div style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '0.5rem'
              }}>
                <h6 style={{ margin: 0 }}>{object.name}</h6>
                <div>
                  {isEditing ? (
                    <>
                      <Button
                        onClick={() => handleUpdate(object)}
                        style={{ marginRight: '0.5rem', color: 'white' }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          if (objectKey) {
                            setEditingObject(prev => ({ ...prev, [objectKey]: false }));
                          }
                        }}
                        style={{ color: 'white' }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      {!hideEdit && (
                        <Button
                          onClick={() => {
                            if (objectKey) {
                              setEditingObject(prev => ({ ...prev, [objectKey]: true }));
                              setEditedObjects(prev => ({ ...prev, [objectKey]: { ...object } }));
                            }
                          }}
                          style={{ marginRight: '0.5rem', color: 'white' }}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        onClick={() => handleDelete(object)}
                        variation="destructive"
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </div>
              </div>

              {isEditing ? (
                <div style={{ display: 'grid', gap: '1rem' }}>
                  {currentFields.map(field => (
                    <div key={field.name}>
                      {renderField(
                        field,
                        objectKey ? editedObjects[objectKey]?.[field.name] : null,
                        (value) => {
                          if (objectKey) {
                            setEditedObjects(prev => ({
                              ...prev,
                              [objectKey]: { ...prev[objectKey], [field.name]: value }
                            }));
                          }
                        }
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div style={{ 
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                  gap: '0.5rem',
                  fontSize: '0.9em',
                  color: '#fff'
                }}>
                  {currentFields.map(field => (
                    <div key={field.name} style={{
                      padding: '0.5rem',
                      backgroundColor: 'rgba(255, 255, 255, 0.03)',
                      borderRadius: '4px'
                    }}>
                      <div style={{ 
                        color: '#aaa',
                        fontSize: '0.8em',
                        marginBottom: '0.25rem'
                      }}>
                        {field.label}
                      </div>
                      <div style={{ fontWeight: 500 }}>
                        {object[field.name]?.toString() || '-'}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {childConfigs && Object.entries(childConfigs).map(([childType, config]) => {
                const parentId = object[config.parentIdField || 'id']!;
                const objs = childrenByParent[parentId]?.[childType] || [];
                
                return (
                  <div key={childType} style={{ marginLeft: '2rem', marginTop: '1rem' }}>
                    <Button
                      onClick={() => {
                        if (expandedChildren[childType]?.[parentId]) {
                          setExpandedChildren(prev => ({
                            ...prev,
                            [childType]: { ...(prev[childType] || {}), [parentId]: false }
                          }));
                        } else {
                          loadChildren(parentId, childType);
                          setExpandedChildren(prev => ({
                            ...prev,
                            [childType]: { ...(prev[childType] || {}), [parentId]: true }
                          }));
                        }
                      }}
                      style={{ color: 'white', marginBottom: '1rem' }}
                    >
                      {loadingChildren[childType]?.[parentId] 
                        ? `Loading ${config.title}s...`
                        : `${expandedChildren[childType]?.[parentId] ? 'Hide' : 'Show'} ${config.title}`}
                    </Button>

                    {expandedChildren[childType]?.[parentId] && childrenByParent[parentId]?.[childType] && (
                      <SolarNetworkObjectList
                        title={config.title}
                        objects={(objs as any[]).map((obj: any, index) => ({
                          ...obj,
                          level: ((object.level || 0) + 1),
                          index: index
                        }))}
                        hideEdit={config.hideEdit}
                        fields={config.fields}
                        onAdd={(newObject) => config.onAdd(parentId, newObject, parents)}
                        onUpdate={(childObject) => {
                          const { index, level, ...objectWithoutIndex } = childObject;
                          return config.onUpdate(parentId, objectWithoutIndex, index, parents);
                        }}
                        onDelete={(childObject) => {
                          const { index, level, ...objectWithoutIndex } = childObject;
                          return config.onDelete(parentId, objectWithoutIndex, index, parents);
                        }}
                        childConfigs={config.childConfigs}
                        parents={[...parents, objectKey ? editedObjects[objectKey] || object : object]}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
