import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import Utils from "@common/utils/Utils"
import i18n from "src/i18n"

import EnergyUtils from "../EnergyUtils"

import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"
import DateRangeGraph from "./DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"

const { t } = i18n
export default class TotalAreaGraph extends DateRangeGraph {
  renderContent() {
    if (this.props.graphDatums) {
      let data = Object.keys(this.props.graphDatums).map((dateKey) => {
        let entry = this.props.graphDatums[dateKey]
        let date = moment(dateKey)

        return {
          date: date.toDate(),
          time: date.toDate().getTime(),
          name: date.format("lll"),
          generation: this.round(entry.generation),
          storage: this.round(entry.storage),
          predictedConsumption:
            this.props.predictedConsumptionDatums && this.props.predictedConsumptionDatums[dateKey] ? this.round(this.props.predictedConsumptionDatums[dateKey].predictedConsumption) : null,
          expectedGeneration: this.props.expectedGenerationDatums && this.props.expectedGenerationDatums[dateKey] ? this.round(this.props.expectedGenerationDatums[dateKey].expectedGeneration) : null,
          predictedGeneration:
            this.props.predictedGenerationDatums && this.props.predictedGenerationDatums[dateKey] ? this.round(this.props.predictedGenerationDatums[dateKey].predictedGeneration) : null,
          consumption: this.round(entry.consumption),
          max: entry.max,
          meteredCost: this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey] ? this.props.consumptionCostDatums[dateKey].meteredCost : null,
          fixedCost: this.props.consumptionCostDatums && this.props.consumptionCostDatums[dateKey] ? this.props.consumptionCostDatums[dateKey].fixedCost : null,
        }
      })

      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"
      return (
        <ResponsiveContainer width="100%" height={250} className="econode-tooltip">
          <AreaChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
            <XAxis
              dataKey="time"
              type="number"
              scale="time"
              domain={this.getDomain(this.props.range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis />
            {this.props.showConsumption ? (
              <YAxis
                yAxisId="cost"
                orientation="right"
                tickFormatter={(amount) => {
                  return Utils.formatCurrency(amount)
                }}
              />
            ) : null}
            <Tooltip content={<ConsumptionGenerationAreaGraphTooltip assets={this.props.assets} range={this.props.range} aggregation={this.props.aggregation} />} />
            <Legend iconType="plainline" />
            {this.props.showGeneration ? (
              <Area name={getDataTitle("generation")} type={graphType} dataKey="generation" stroke={GRAPH_COLORS[0]} fill={GRAPH_COLORS[0]} dot={aggregation === "day"} />
            ) : null}
            {this.props.showGeneration ? (
              <Area name={getDataTitle("expectedGeneration")} type={graphType} dataKey="expectedGeneration" stroke={GRAPH_COLORS[0]} fill={"none"} strokeDasharray="4 4" dot={false} strokeWidth={2} />
            ) : null}
            {this.props.showGeneration ? (
              <Area
                name={getDataTitle("predictedGeneration")}
                type={graphType}
                dataKey="predictedGeneration"
                stroke={GRAPH_COLORS[0]}
                fill={"none"}
                strokeDasharray="2 4"
                dot={false}
                strokeWidth={1}
              />
            ) : null}
            {this.props.showConsumption ? (
              <Area name={getDataTitle("consumption")} type={graphType} dataKey="consumption" stroke={GRAPH_COLORS[1]} fill={GRAPH_COLORS[1]} dot={aggregation === "day"} />
            ) : null}
            {this.props.showConsumption ? (
              <Area
                name={getDataTitle("predictedConsumption")}
                type={graphType}
                dataKey="predictedConsumption"
                stroke={GRAPH_COLORS[1]}
                fill={"none"}
                strokeDasharray="4 4"
                dot={false}
                strokeWidth={2}
              />
            ) : null}
            {this.props.showConsumption ? (
              <Area name={getDataTitle("meteredCost")} type={graphType} dataKey="meteredCost" stroke={GRAPH_COLORS[2]} fill={"none"} strokeDasharray="2 2" dot={false} strokeWidth={2} yAxisId="cost" />
            ) : null}
            {this.props.showConsumption ? (
              <Area name={getDataTitle("fixedCost")} type={graphType} dataKey="fixedCost" stroke={GRAPH_COLORS[2]} fill={"none"} strokeDasharray="4 4" dot={false} strokeWidth={2} yAxisId="cost" />
            ) : null}

            {this.props.showStorage ? (
              <Area name={getDataTitle("storage")} type={graphType} dataKey="storage" stroke={GRAPH_COLORS[3]} fill={"none"} strokeDasharray="4 4" dot={aggregation === "day"} />
            ) : null}

            {this.renderUserAlerts()}
            {this.renderCurrentTime()}
            {this.renderAssetEvents()}
          </AreaChart>
        </ResponsiveContainer>
      )
    }

    return <Loading />
  }
}

var getDataTitle = function (name) {
  const { t } = i18n
  switch (name) {
    case "predictedGeneration":
      return `${t("dataTitles.predicted_generation")}`
    case "expectedGeneration":
      return `${t("dataTitles.expected_generation")}`
    case "generation":
      return `${t("dataTitles.generation")}`
    case "predictedConsumption":
      return `${t("dataTitles.predicted_consumption")}`
    case "consumption":
      return `${t("dataTitles.consumption")}`
    case "meteredCost":
      return `${t("dataTitles.metered_cost")}`
    case "demandCharge":
      return `${t("dataTitles.demand_charge")}`
    case "fixedCost":
      return `${t("dataTitles.fixed_cost")}`
    case "storage":
      return `${t("dataTitles.storage")}`
    default:
      return name
  }
}

class ConsumptionGenerationAreaGraphTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {payload.map((data) => {
              if (data.name === getDataTitle("consumptionCost") || data.name === getDataTitle("fixedCost") || data.name === getDataTitle("meteredCost") || data.name === getDataTitle("demandCharge")) {
                return (
                  <p key={data.dataKey} className="label" style={{ color: GRAPH_COLORS[2] }}>
                    {`${getDataTitle(data.name)} : ${Utils.formatCurrency(data.value ? data.value : 0)}`}
                  </p>
                )
              }
              return (
                <React.Fragment key={data.dataKey}>
                  <p className="label" style={{ color: data.fill }}>
                    {`${getDataTitle(data.name)} : ${EnergyUtils.formatKiloWattHours(data.value ? data.value : 0)}`}
                  </p>

                  {this.getMaxReading(data)}
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }
    return null
  }

  getMaxReading(data) {
    let max = data.payload.max[data.dataKey]
    if (max && this.props.assets && this.props.assets.length > 1) {
      return (
        <p key={data.dataKey + "-most"} className="label" style={{ color: data.fill }}>
          {`${t("energy.largest_contribution")}: ${max.asset} (${EnergyUtils.formatWattHoursAsKiloWattHours(max.watts ? max.watts : 0)})`}
        </p>
      )
    }
    return null
  }
}
