import React from "react"

import { Button, Row, Col, Alert, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import Logger from "@common/Logger"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { EcosuiteFieldTemplate, EcosuiteArrayFieldTemplate, FormError, EcosuiteObjectFieldTemplate } from "@common/form/EcosuiteForm"
import EcosuiteObjectField from "@common/form/EcosuiteObjectField"
import { PortfolioSelector } from "./PortfolioFormWidgets"
import Schemas from "@common/Schemas"

import PortfolioService from "./PortfolioService"
import ChangeNoteModal from "@common/form/ChangeNoteModal"
import i18n from "src/i18n"
import TimedMessage from "@common/display/TimedMessage"

const jsprim = require("jsprim")
const { t } = i18n
/**
 * Form for editing and creating portfolios.
 * @extends EcosuiteComponent
 */
export default class PortfolioForm extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.submitFormRef = React.createRef()
    this.state = { note: "" } // Explicitly set note to make it a controlled component

    this.createPortfolio = this.createPortfolio.bind(this)
    this.editPortfolio = this.editPortfolio.bind(this)
    this.deletePortfolio = this.deletePortfolio.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.setStateIfMounted({ formData: this.getPortfolio() })
    this.loadSchema()
  }

  componentDidUpdate(prevProps) {
    if (this.props.portfolio !== prevProps.portfolio || this.props.projects !== prevProps.projects) {
      this.setStateIfMounted({
        error: undefined,
        success: undefined,
        formData: this.getPortfolio(),
      })
    }
  }

  getPortfolio() {
    const portfolio = jsprim.deepCopy(this.props.portfolio)
    // Filter out duplicate and non existent projects
    portfolio.projects = [...new Set(portfolio.projects)]
    if (this.props.projects) {
      const projectCodes = this.props.projects.map((project) => project.code)
      portfolio.projects = portfolio.projects.filter((projectId) => projectCodes.includes(projectId))
    }

    return portfolio
  }

  loadSchema() {
    Schemas.getPortfolioSchema().then((schema) => {
      this.setStateIfMounted({
        portfolioSchema: schema,
      })
    })
  }

  createPortfolio(form) {
    this.setStateIfMounted({ error: undefined, loading: true, success: false, formData: form.formData })

    PortfolioService.createPortfolio(form.formData)
      .then((data) => {
        this.setStateIfMounted({
          loading: false,
          formData: undefined,
        })
        this.props.actions.portfolioUpdated(data.portfolio)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false })
      })
  }

  editPortfolio(form) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: "Please enter a Change Note explaining the changes that have been made" })
      return
    }

    this.setStateIfMounted({ error: undefined, loading: true, success: false, formData: form.formData })

    PortfolioService.updatePortfolio(this.props.portfolio.id, form.formData, this.state.note)
      .then((data) => {
        this.setStateIfMounted({ loading: false, success: true, note: "" })
        this.props.actions.portfolioUpdated(data.portfolio)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false })
      })
  }

  confirmDelete(e) {
    this.setState({ showModal: !this.state.showModal })

    e.preventDefault()
  }

  deletePortfolio(e) {
    if (!this.state.note) {
      this.setStateIfMounted({ error: "Please enter a Change Note explaining the changes that have been made" })
      return
    }

    this.setStateIfMounted({ error: undefined, loading: true })

    PortfolioService.deletePortfolio(this.props.portfolio.id, this.state.note)
      .then(() => {
        this.setStateIfMounted({ loading: false, deleted: true, note: "" })
        this.props.actions.portfolioDeleted(this.props.portfolio.id)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false, loading: false })
      })
    e.preventDefault()
  }

  /**
   * @param {(() => void) | undefined} callback - what to do after the state updates
   */
  toggleChangeNoteModal(callback) {
    this.setStateIfMounted({ isChangeNoteOpen: !this.state.isChangeNoteOpen }, callback)
  }

  getPortfolioUiSchema() {
    return {
      projects: {
        "ui:widget": "portfolioSelector",
        "ui:options": {
          inline: true,
        },
      },
      reports: {
        "ui:options": {
          label: false,
        },
        operating: {
          "ui:options": {
            label: false,
            labelPlaceholder: true,
          },
          "ui:description": "Generate Operating Reports for this Portfolio",
        },
        operatingContacts: {
          items: {
            "ui:options": {
              label: false,
            },
            email: {
              // UI Options for email field
            },
            reportType: {
              // UI Options for report type field
            },
          },
        },
      },
    }
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    }
    if (this.state.deleted) {
      return <div>{t("messages.deleted")}</div>
    }

    if (this.state.portfolioSchema) {
      return (
        <div className="ecogy-form">
          <EcosuiteForm
            schema={this.state.portfolioSchema}
            uiSchema={this.getPortfolioUiSchema()}
            formData={this.state.formData}
            onSubmit={this.props.portfolio.id ? this.editPortfolio : this.createPortfolio}
            onChange={(form) => {
              this.formDataChanged(form)
              this.props.portfolioChanged(form.formData)
            }}
            FieldTemplate={EcosuiteFieldTemplate}
            ObjectFieldTemplate={EcosuiteObjectFieldTemplate}
            ArrayFieldTemplate={EcosuiteArrayFieldTemplate}
            fields={{ ObjectField: EcosuiteObjectField }}
            widgets={{ portfolioSelector: PortfolioSelector }}
            formContext={{ portfolio: this.state.formData, projects: this.props.projects, readonly: this.props.readonly }}
            disabled={this.props.readonly}
          >
            {this.props.readonly ? null : (
              <Row className="ecogy-form-buttons">
                {this.props.portfolio.id ? (
                  <>
                    <Col className="message-section" sm="10">
                      {this.renderMessages()}
                    </Col>

                    <Col className="button-section">
                      <Button color="primary" onClick={() => this.toggleChangeNoteModal()} disabled={this.props.readonly}>
                        {t("buttons.submit")}
                      </Button>
                      <Button color="danger" onClick={this.confirmDelete} disabled={this.props.readonly}>
                        {t("buttons.delete")}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="message-section" sm="12">
                      {this.renderMessages()}
                    </Col>
                    <Col className="button-section" sm="12">
                      <Button color="primary" type="submit" disabled={this.props.readonly}>
                        {t("buttons.save")}
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            )}

            {this.renderMessages()}

            {/* react-jsonschema-form doesn't expose submit() or requestSubmit() inside of its ref */}
            {/* So a reference to this button is needed to submit from inside a modal / nested component */}
            <button ref={(ref) => (this.submitFormRef = ref)} style={{ display: "none" }} />
          </EcosuiteForm>

          {this.renderModal()}
          <ChangeNoteModal
            isOpen={this.state.isChangeNoteOpen}
            toggle={() => this.toggleChangeNoteModal()}
            submit={() => this.toggleChangeNoteModal(() => this.submitFormRef.click())}
            canSubmit={!!this.state.note}
          >
            <Col className="audit-section">
              <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
              <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />
            </Col>
          </ChangeNoteModal>
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.showModal} toggle={this.confirmDelete}>
        <ModalHeader toggle={this.confirmDelete}>{`Delete Portfolio: ${this.props.portfolio.name}?`}</ModalHeader>
        <ModalBody>
          <p>{t("settings.messages.confirm_delete", { portfolioName: this.props.portfolio.name })}</p>
          <Alert color="info">{t("alertsInfo.alert_audit")}</Alert>
          <Input type="textarea" value={this.state.note} onChange={this.saveNote} className="audit-note" />

          <br />

          <span>
            {t("settings.messages.before_delete_warning")} <b style={{ color: "red" }}>{`${t("buttons.delete_portfolio")} ${this.props.portfolio.name}`}</b>
          </span>
          <Input value={this.state.deleteConfirmation} onChange={(e) => this.setState({ deleteConfirmation: e.target.value })} className="audit-note" />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.deletePortfolio} disabled={!this.state.note || this.state.deleteConfirmation !== `Delete Portfolio ${this.props.portfolio.name}`}>
            {t("buttons.delete_portfolio")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.setState({ showModal: false })
            }}
          >
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderMessages() {
    return (
      <React.Fragment>
        {this.state.success ? (
          <TimedMessage delay={8000}>
            <Alert color="info">{t("messages.portfolio_updated")}</Alert>
          </TimedMessage>
        ) : null}
        <FormError
          error={this.state.error}
          toggle={() => {
            this.setStateIfMounted({ error: null })
          }}
        />
      </React.Fragment>
    )
  }
}
