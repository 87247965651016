import React from "react"

import { GB, US, ES, MG, FR } from "country-flag-icons/react/3x2"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import i18n from "src/i18n"

interface Option {
  value: string;
  label: string;
  icon: JSX.Element
}

const styles = {height: "24px"};

const colourOptions: Array<Option> = [
  { value: "en", label: "EN", icon: <GB title="English" style={styles} /> },
  { value: "en_us", label: "EN US", icon: <US title="English" style={styles} /> },
  { value: "es", label: "ES", icon: <ES title="Spanish" style={styles} /> },
  { value: "mg", label: "MG", icon: <MG title="Malagase" style={styles} /> },
  { value: "fr", label: "FR", icon: <FR title="French" style={styles} /> }]

interface Props {
  value: string;
  onChange: (v: string | undefined) => void
}

const { t } = i18n

export default function LanguageSelect(props: Props) {
  const { value, onChange } = props
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const [selected, setSelected] = React.useState<Option | null>(null)

  React.useEffect(() => {
    if (value) {
      const foundOption = colourOptions.find(el => el.value === value)
      if (foundOption) {
        setSelected(foundOption)
      }
    }
  }, [value])

  return (
    <div style={{ display: "flex" }}>
      <div style={{margin: "3px"}}>
        {selected?.icon}
      </div>
      <Dropdown
        size={"sm"}
        isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)}>
        <DropdownToggle caret>{selected?.label}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem header={true}>{t("header.language.select_language")}</DropdownItem>
          {colourOptions.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                setSelected(option)
                onChange(option.value)
              }}
            >
              {option.icon} {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>

    </div>
  )
}
