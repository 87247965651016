import WithToolTip from "@common/display/ToolTip"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { Button } from "reactstrap"

export default function InviteUser() {
  const history = useHistory()
  return (
    <WithToolTip tooltip="You've been granted ability to create new users within your company/organization (You can invite upto 3 users)">
      <Button color="primray" onClick={() => history.push("/admin/user-admin-limited")} style={{ color: "white", marginRight: "8px" }}>
        Invite User
      </Button>
    </WithToolTip>
  )
}
