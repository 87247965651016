import TimezoneSelect from "@common/input/select/TimezoneSelect"
import { Input } from "reactstrap"
import React from "react"
import moment from "moment"

const UserSchema = {
  getUiSchema() {
    return {
      timezone: {
        "ui:widget": (props) => {
          return (
            <TimezoneSelect
              value={props.value}
              onChange={(value) => {
                props.onChange(value)
              }}
            />
          )
        },
      },
      lastLogin: {
        "ui:widget": (props) => {
          return <Input disabled={true} value={moment(props.value).format("ll")}></Input>
        },
      },
      access: {
        projects: {
          "ui:widget": "checkboxes",
          "ui:options": {
            inline: true,
          },
        },
        portfolios: {
          "ui:widget": "checkboxes",
          "ui:options": {
            inline: true,
          },
        },
      },
    }
  },

  getSchema(userSchema, projects, portfolios, formData) {
    // We set up the list of project codes that can be configured
    const schema = {}
    Object.assign(schema, userSchema)

    if (projects) {
      let codes = projects.map((project) => {
        return project.code
      })
      let names = projects.map((project) => {
        return `${project.name} (${project.code})`
      })
      schema.properties.access.dependencies.restrictProjects.oneOf[1].properties.projects.items.enum = codes
      schema.properties.access.dependencies.restrictProjects.oneOf[1].properties.projects.items.enumNames = names

      if (formData && formData.access && formData.access.projects && formData.access.projects.length) {
        // We need to take into account any projects that may have been configured for the current user from another environment or even that may have been deleted
        formData.access.projects.forEach((projectId) => {
          if (
            schema.properties.access.dependencies.restrictProjects.oneOf[1].properties.projects.items.enum.indexOf(
              projectId,
            ) < 0
          ) {
            schema.properties.access.dependencies.restrictProjects.oneOf[1].properties.projects.items.enum.push(
              projectId,
            )
            schema.properties.access.dependencies.restrictProjects.oneOf[1].properties.projects.items.enumNames.push(
              projectId,
            )
          }
        })
      }
    }

    if (portfolios) {
      let ids = portfolios.map((portfolio) => {
        return portfolio.id
      })
      let names = portfolios.map((portfolio) => {
        return portfolio.name
      })
      schema.properties.access.dependencies.restrictProjects.oneOf[2].properties.portfolios.items.enum = ids
      schema.properties.access.dependencies.restrictProjects.oneOf[2].properties.portfolios.items.enumNames = names

      if (formData && formData.access && formData.access.portfolios && formData.access.portfolios.length) {
        // We need to take into account any portfolios that may have been configured for the current user from another environment or even that may have been deleted
        formData.access.portfolios.forEach((portfolioId) => {
          if (
            schema.properties.access.dependencies.restrictProjects.oneOf[2].properties.portfolios.items.enum.indexOf(
              portfolioId,
            ) < 0
          ) {
            schema.properties.access.dependencies.restrictProjects.oneOf[2].properties.portfolios.items.enum.push(
              portfolioId,
            )
            schema.properties.access.dependencies.restrictProjects.oneOf[2].properties.portfolios.items.enumNames.push(
              portfolioId,
            )
          }
        })
      }
    }

    return schema
  },
}

export default UserSchema
