import React from "react"
import { Alert, Button, Card, CardBody, CardTitle, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"
import ProjectAdminService from "@dashboard/data/project/ProjectAdminService"
import { Note } from "../note/Note"
import NoteService from "./ProjectNoteService"
import i18n from "src/i18n"

const { t } = i18n
export default class Notes extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.loadNotes = this.loadNotes.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadNotes()
    this.projectUpdated = this.projectUpdated.bind(this)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate()

    if (prevProps.project !== this.props.project) {
      this.loadNotes()
    }
  }

  loadNotes() {
    this.setStateIfMounted({ project: undefined })
    const projectId = this.props.project.code
    ProjectAdminService.getProject(projectId).then((project) => {
      if (this.isProjectCurrent(projectId)) {
        this.setStateIfMounted({ project: project })
      } else {
        Logger.debug(`Ignoring out of date response for project: ${projectId}`)
      }
    })
  }

  projectUpdated(latestProject) {
    // We update the project that we are loading the notes from
    this.setStateIfMounted({ project: latestProject })
  }

  renderContent() {
    if (this.state.project) {
      return (
        <div className="notes">
          <ProjectNotes project={this.state.project} loadNotes={this.loadNotes} projectUpdated={this.projectUpdated} />
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

class ProjectNotes extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      note: "",
    }

    this.newNote = this.newNote.bind(this)
    this.editNote = this.editNote.bind(this)
    this.editComment = this.editComment.bind(this)
    this.resolveNote = this.resolveNote.bind(this)
    this.addCommentToNote = this.addCommentToNote.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.save = this.save.bind(this)
  }

  newNote(project) {
    this.setStateIfMounted({ project: project, note: undefined, noteIdx: undefined, commentIdx: undefined }, () => {
      this.toggleModal()
    })
  }

  editNote(project, noteIdx, text) {
    this.setStateIfMounted({ project: project, note: text, noteIdx: noteIdx, commentIdx: undefined }, () => {
      this.toggleModal()
    })
  }

  editComment(project, noteIdx, commentIdx, text) {
    this.setStateIfMounted({ project: project, note: text, noteIdx: noteIdx, commentIdx: commentIdx }, () => {
      this.toggleModal()
    })
  }

  save() {
    this.setStateIfMounted({ loading: true, error: undefined })
    const project = this.props.project
    const projectId = project.code
    const note = this.state.note
    const noteIdx = this.state.noteIdx
    const commentIdx = this.state.commentIdx

    if (isNaN(noteIdx)) {
      //We are creating a new note
      // We get the latest version of the project and add the note to it
      ProjectAdminService.getProject(projectId)
        .then((project) => {
          if (project) {
            NoteService.addNote(project, note).then(() => {
              this.setStateIfMounted({ project: undefined, note: undefined, loading: false, error: undefined })
              this.toggleModal()
              this.props.loadNotes()
            })
          } else {
            this.setStateIfMounted({ error: `${t("process.messages.no_existing_project")}`, loading: false })
          }
        })
        .catch((err) => {
          Logger.error(`Unable to save note: ${note} to project: ${projectId}`)
          Logger.error(err)
          this.setStateIfMounted({ error: `${t("process.messages.unable_tosave_note")}`, loading: false })
        })
    } else {
      if (isNaN(commentIdx)) {
        // We are edditing an exising note
        NoteService.editNote(project, noteIdx, note)
          .then((response) => {
            this.setStateIfMounted({ project: undefined, noteIdx: undefined, note: undefined, loading: false, error: undefined })
            this.toggleModal()
            this.props.projectUpdated(response.project)
          })
          .catch((err) => {
            Logger.error(`Unable to update note: ${note} on project: ${projectId}`)
            Logger.error(err)
            this.setStateIfMounted({ error: `${t("process.messages.unable_toupdate_note")}: ${err.message}`, loading: false })
          })
      } else {
        // We are editing an existing comment
        NoteService.editNoteComment(this.props.project, noteIdx, commentIdx, note)
          .then((response) => {
            this.setStateIfMounted({ project: undefined, noteIdx: undefined, commentIdx: undefined, note: undefined, loading: false, error: undefined })
            this.toggleModal()
            this.props.projectUpdated(response.project)
          })
          .catch((err) => {
            Logger.error(`Unable to update comment: ${note} on project: ${projectId}`)
            Logger.error(err)
            this.setStateIfMounted({ error: `${t("process.messages.unable_toupdate_comment")}: ${err.message}`, loading: false })
          })
      }
    }
  }

  resolveNote(project, noteIdx) {
    this.setStateIfMounted({ error: undefined })

    NoteService.resolveNote(project, noteIdx)
      .then((response) => {
        // The note has been resolved and filtered out
        this.props.projectUpdated(response.project)
      })
      .catch((err) => {
        Logger.error(`Unable to resolve note: ${noteIdx} to project: ${project.code}`)
        Logger.error(err)
        alert(`${t("process.messages.unable_toresolve_note")}: ${err.message}`)
      })
  }

  async addCommentToNote(project, noteIdx, comment) {
    this.setStateIfMounted({ error: undefined })

    return NoteService.addCommentToNote(project, noteIdx, comment)
      .then((response) => {
        this.props.projectUpdated(response.project)
      })
      .catch((err) => {
        Logger.error(`Unable to comment on note: ${noteIdx} of project: ${project.code}`)
        Logger.error(err)
        alert(`${t("process.messages.unable_toadd_comment_to_note")}: ${err.message}`)
      })
  }

  renderContent() {
    return (
      <Card className="note">
        <CardTitle>{t("process.labels.projects_notes")}</CardTitle>
        <CardBody>
          {this.props.project.notes
            ? this.props.project.notes.map((note, idx) => {
                if (note.resolved) {
                  return null // we do this to maintain the indexes
                } else {
                  return (
                    <Note
                      key={idx}
                      noteHolder={this.props.project}
                      noteIdx={idx}
                      note={note}
                      resolveNote={this.resolveNote}
                      addCommentToNote={this.addCommentToNote}
                      editNote={this.editNote}
                      editComment={this.editComment}
                    />
                  )
                }
              })
            : null}
          <Button
            size="sm"
            onClick={() => {
              this.newNote()
            }}
          >
            {t("process.labels.new_project_note")}
          </Button>
          {this.renderModal()}
        </CardBody>
      </Card>
    )
  }

  toggleModal() {
    this.setStateIfMounted({ open: !this.state.open })
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.open} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>
          {isNaN(this.state.noteIdx) ? `${t("labels.add")}` : `${t("buttons.edit")}`} {this.props.project ? `"${this.props.project.name}"` : ""}{" "}
          {isNaN(this.state.commentIdx) ? `${t("labels.note")}` : `${t("labels.comment")}`}
        </ModalHeader>
        {this.state.loading ? (
          <Loading message={`${isNaN(this.state.commentIdx)}` ? `${t("process.messages.saving_note")}` : `${t("process.messages.saving_comment")}`} />
        ) : (
          <React.Fragment>
            <ModalBody>
              <Input
                type="textarea"
                value={this.state.note}
                onChange={(e) => {
                  this.setStateIfMounted({ note: e.target.value })
                }}
              />

              {this.state.error ? <Alert color="danger">{this.state.error}</Alert> : null}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                {isNaN(this.state.noteIdx) ? `${t("labels.add")}` : `${t("buttons.update")}`} {isNaN(this.state.commentIdx) ? `${t("labels.note")}` : `${t("labels.comment")}`}
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleModal}>
                {t("buttons.cancel")}
              </Button>
            </ModalFooter>
          </React.Fragment>
        )}
      </Modal>
    )
  }
}
