import React from "react"
import { Table, Alert } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import CashFlowDates from "./CashFlowDates"
import CashFlowDifferences from "./CashFlowDifferences"
import ReportUtils from "../ReportUtils"
import ReportTotalUtils from "../ReportTotalUtils"

import "../IRRReportContent.css"
import i18n from "src/i18n"

const { t } = i18n
export default class IRRReportContent extends EcosuiteComponent {
  componentDidMount() {
    super.componentDidMount()

    this.aggregateReport()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project || this.props.report !== prevProps.report || this.props.aggregate !== prevProps.aggregate) {
      // We clear out any error state that may heve been recorded for the previous report
      this.setStateIfMounted({ hasError: false, error: undefined })

      this.aggregateReport()
    }
  }

  aggregateReport() {
    if (this.props.report && !this.props.report.predicted) {
      const aggregatedReport = ReportUtils.aggregateReport(this.props.report, this.props.aggregate)
      const reportTotals = ReportTotalUtils.calculateReportTotals(this.props.report)

      this.setStateIfMounted({ aggregatedReport: aggregatedReport, reportTotals: reportTotals })
    } else {
      this.setStateIfMounted({ aggregatedReport: undefined, reportTotals: undefined })
    }
  }

  renderContent() {
    if (this.props.report && this.props.report.predicted) {
      return <Alert color="warning">{t("economics.messages.report_out_of_date")}</Alert>
    } else if (this.props.report && this.state.aggregatedReport) {
      let futureIdx = ReportUtils.getFutureIndex(this.state.aggregatedReport, this.props.aggregate)

      return (
        <div className="irr-report">
          <Table className={this.props.aggregate} hover striped id="irr-report">
            <thead>
              <CashFlowDates report={this.state.aggregatedReport} futureIdx={futureIdx} aggregate={this.props.aggregate} columns={this.props.columns} />
            </thead>

            <tbody>
              <CashFlowDifferences
                report={this.state.aggregatedReport}
                reportTotals={this.state.reportTotals}
                categories={this.props.report.categories}
                futureIdx={futureIdx}
                columns={this.props.columns}
                showAccounts={this.props.showAccounts}
                showSubAccounts={this.props.showSubAccounts}
                section="inline"
                projectDiscountRate={this.props.projectDiscountRate}
              />
            </tbody>
          </Table>
        </div>
      )
    } else if (this.props.report === null) {
      // If the report is null it means that it's been successfuly requested but there isn't a report available for the project.
      return <Alert color="info">{t("economics.messages.report_not_available")}</Alert>
    } else {
      // If the report is undefined it means that we are waiting for it to load
      return (
        <React.Fragment>
          <Loading message={t("loadingMsg.loading_report")} />
        </React.Fragment>
      )
    }
  }
}
