import { ChecklistDataContainer } from "@dashboard/process/views/checklists/ChecklistView"
import { get, getUnuthenticated, post } from "../setup"
import { useMutation, useQuery } from "react-query"

export type ShareChecklistBody = { metaData: ChecklistDataContainer } & {
  emailAddresses: Array<string>
  organization: { name: string; logo?: string }
}

export type GetChecklistResponse = { metaData: ChecklistDataContainer } & {
  emailAddress: string
  createdAt: string
  id: string
  organization: {
    name: string
    logo?: string
  }
}

export const shareChecklistApi = (body: ShareChecklistBody) =>
  post<ShareChecklistBody, Array<string>>("/checklist/public-share", body)

export const getPublicCheckListApi = (uuid: string) =>
  getUnuthenticated<GetChecklistResponse>(`/checklist/public-share/${uuid}`)

export function useGetPublicChecklist(uuid: string) {
  return useQuery({
    queryKey: [`public-checklist/${uuid}`],
    queryFn: async () => {
      const response = await getPublicCheckListApi(uuid)
      return response
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}

export function useShareChecklistToPublic() {
  return useMutation(shareChecklistApi, {
    onError: (e: Error) => {
      console.error(e)
    },
  })
}

export interface AuditRecord {
  version: string
  path: string
  assetType: string
  userId: string
  timestamp: number
  userName: string
  project: string
  eventType: string
  diffs: string
  id: string
}

export interface LastUpdatedUser {
  userName: string
  userId: string
  timestamp: number
}

export const getChecklistAuditApi = (projectId: string) => get<AuditRecord[]>(`/audit/checklist?projectId=${projectId}`)

export const getLastUpdatedUser = async (projectId: string): Promise<LastUpdatedUser | null> => {
  try {
    const records = await getChecklistAuditApi(projectId)
    if (!records || records.length === 0) return null
    
    // Sort by timestamp in descending order and get the most recent record
    const latestRecord = records.sort((a, b) => b.timestamp - a.timestamp)[0]
    
    return {
      userName: latestRecord.userName,
      userId: latestRecord.userId,
      timestamp: latestRecord.timestamp
    }
  } catch (error) {
    return null
  }
}

export function useGetChecklistAudit(projectId: string) {
  return useQuery({
    queryKey: ['audit-checklist', projectId],
    queryFn: async () => {
      const response = await getChecklistAuditApi(projectId)
      return response
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}

export function useGetLastUpdatedChecklistUser(projectId: string) {
  return useQuery({
    queryKey: ['last-updated-user-checklist', projectId],
    queryFn: () => getLastUpdatedUser(projectId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}
