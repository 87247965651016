import React from "react"
import { Modal as BootstrapModal, Button, Container, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { FormError } from "@common/form/EcosuiteForm"
import Logger from "@common/Logger"

import OrganizationService from "./OrganizationAdminService"
import i18n from "src/i18n"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { TextField } from "@aws-amplify/ui-react"

const { t } = i18n

export const RootUserEmailPrompt = NiceModal.create(({ onSubmit }) => {
  const modal = useModal()

  const [email, setEmail] = React.useState("")
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")

  return (
    <BootstrapModal
      centered={true}
      size={"md"}
      isOpen={modal.visible}
      toggle={modal.visible ? () => modal.hide() : undefined}
    >
      <ModalHeader>
        <h2>Enter admin user details</h2>
      </ModalHeader>
      <ModalBody>
        <div>
          <TextField
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="xyz@gmail.com"
            label="Email Address"
          />
          <br />
          <TextField
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            placeholder="John"
            label="First Name"
          />
          <br />
          <TextField
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            placeholder="Doe"
            label="Last Name"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            modal.hide()
            onSubmit({ email, firstName, lastName })
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </BootstrapModal>
  )
})
export default class CreateOrganization extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      formData: null,
      loading: false,
      error: null,
      newOrganization: null,
      schema: null,
    }
    this.createOrganization = this.createOrganization.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    OrganizationService.getOrganizationSchema().then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
  }

  createOrganization(form) {
    this.setState({ error: undefined, formData: form.formData, loading: true })
    NiceModal.show(RootUserEmailPrompt, {
      value: "",
      onSubmit: (v) => {
        OrganizationService.createOrganization({ ...form.formData, ...v })
          .then((response) => {
            Logger.info("OrganizationService.createOrganization response = " + JSON.stringify(response))
            this.props.reloadOrganizations()
          })
          .catch((err) => {
            Logger.error(err)
            this.setStateIfMounted({ error: err, loading: false })
          })
      },
    })
  }

  cancel() {
    this.props.selectOrganization()
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    } else if (this.state.schema) {
      return (
        <Container fluid={true} className="Admin-content">
          <div className="ecogy-form admin-content-area">
            <EcosuiteForm
              schema={this.state.schema}
              uiSchema={OrganizationService.getUiSchema()}
              formData={this.state.formData}
              onSubmit={this.createOrganization}
              onChange={this.formDataChanged}
            >
              {this.renderMessages()}
              <div>
                <Button color="primary" type="submit">
                  {t("buttons.submit")}
                </Button>
                <Button color="danger" onClick={this.cancel}>
                  {t("buttons.cancel")}
                </Button>
              </div>
            </EcosuiteForm>
          </div>
        </Container>
      )
    } else {
      return <Loading />
    }
  }

  renderMessages() {
    return (
      <FormError
        error={this.state.error}
        toggle={() => {
          this.setStateIfMounted({ error: null })
        }}
      />
    )
  }
}
