import { useMutation, useQuery, useQueryClient } from "react-query"
import { del, get, post, put } from "../setup"
import { omit } from "lodash"

export interface Contact {
  id: string
  name: string
  type: string
  email: string
  phone: string
  voip: string
  notes: string
}

export const getContactsApi = () => get<Array<Contact>>("/contacts/all")
export const createContactApi = (body: Omit<Contact, "id">) => post<typeof body, Contact>("/contacts/create", body)
export const deleteContactApi = (id: string) => del<void>(`/contacts/${id}`)
export const getContactByIdApi = (id: string) => get<Contact>(`/contacts/${id}`)
export const updateContactApi = (body: Contact) => put<Omit<Contact, "id">, Contact>(`/contacts/update/${body.id}`, omit(body, "id"))
export const getSchema = () => get<string>("/contacts/schema")

export function useContacts() {
  return useQuery({
    queryKey: ["contacts"],
    queryFn: async () => {
      const response = await getContactsApi()
      return response
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  })
}

export function useContact(id: string | undefined) {
  return useQuery({
    queryKey: ["contact"],
    queryFn: async () => {
      const response = await getContactByIdApi(id as string)
      return response
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: Boolean(id),
  })
}

export function useSchema() {
  return useQuery({
    queryKey: ["contactSchema"],
    queryFn: async () => {
      const response = await getSchema()
      return response
    },
  })
}

export function useCreateContact() {
  const client = useQueryClient()
  return useMutation(createContactApi, {
    onSuccess: () => client.invalidateQueries(["contacts"]),
  })
}

export function useUpdateContact() {
  const client = useQueryClient()
  return useMutation(updateContactApi, {
    onSuccess: () => client.invalidateQueries(["contacts"]),
  })
}

export function useDeleteContact() {
  const client = useQueryClient()
  return useMutation(deleteContactApi, {
    onSuccess: () => client.invalidateQueries(["contacts"]),
  })
}
