import type { Project, ProjectCashFlowTotal } from "@ecogy-types/openapi-types"

/**
 * @returns sorts input projects in place and returns reference to that array
 */
export function sortByAlpha(projects: Project[]): Project[] {
  // projects response is sorted alphabetically by default so we could simply return the original prop
  // return projects

  return projects.sort((a, b) => {
    if (a.name.toLocaleLowerCase() === b.name.toLocaleLowerCase()) {
      return 0
    } else if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
      return 1 // larger alphanumeric values should go to the back of the list
    } else {
      return -1
    }
  })
}

/**
 * @returns sorts input projects in place and returns reference to that array
 */
// type Project doesn't have dcSize so this type conversion is needed
export function sortByDCSize(projects: Array<{ dcSize?: number } & Project>): Project[] {
  return projects.sort((a, b) => {
    if (a.dcSize === b.dcSize) {
      return 0
    } else if ((a.dcSize ?? 0) > (b.dcSize ?? 0)) {
      return -1 // Move `a` to the front of the list if it is greater than `b`
    } else {
      return 1
    }
  })
}

/**
 * @returns sorts input projects in place and returns reference to that array
 */
export function sortByRetainedEarnings(projects: Project[], cashflowTotals: Record<string, ProjectCashFlowTotal | undefined>): Project[] {
  return projects.sort((a, b) => {
    const retainedEarningA = cashflowTotals[a.code]?.retainedEarnings ?? 0
    const retainedEarningB = cashflowTotals[b.code]?.retainedEarnings ?? 0

    if (retainedEarningA === retainedEarningB) {
      return 0
    } else if (retainedEarningA > retainedEarningB) {
      return -1 // Move `a` to the front of the list if it is greater than `b`
    } else {
      return 1
    }
  })
}
