import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { useEffect, useState } from "react"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "2xl"

export function useResponsive() {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>("xs")

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      if (width < 640) setBreakpoint("xs")
      else if (width < 768) setBreakpoint("sm")
      else if (width < 1024) setBreakpoint("md")
      else if (width < 1280) setBreakpoint("lg")
      else if (width < 1536) setBreakpoint("xl")
      else setBreakpoint("2xl")
    }

    handleResize() // Set initial breakpoint
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return breakpoint
}
