import Icon from "@common/display/Icon"
import React from "react"
import { Input } from "reactstrap"
import i18n from "src/i18n"

const { t } = i18n

export default function getProFormaWidgets() {
  return {
    categoriesWidget: categoriesWidget,
    accountsWidget: accountsWidget,
    subAccountsWidget: subAccountsWidget,
    pathSelectWidget: pathSelectWidget,
  }
}

function getCategoryName(props) {
  const idParts = props.id.split("_")
  const proForma = props.formContext.proForma
  if (props.id.endsWith("interestAccount")) {
    // id root_cashFlows_0_category, root_cashFlows_0_payments_0_interestCategory
    return proForma[idParts[1]] &&
      proForma[idParts[1]][idParts[2]] &&
      proForma[idParts[1]][idParts[2]][idParts[3]] &&
      proForma[idParts[1]][idParts[2]][idParts[3]][idParts[4]]
      ? proForma[idParts[1]][idParts[2]][idParts[3]][idParts[4]]["interestCategory"]
      : null
  } else {
    // id root_cashFlows_0_category, root_cashFlows_0_category
    return proForma[idParts[1]] && proForma[idParts[1]][idParts[2]]
      ? proForma[idParts[1]][idParts[2]]["category"]
      : null
  }
}

export function categoriesWidget(props) {
  const categories = props.formContext.categories
  const readonly = props.formContext.readonly

  if (categories && Object.values(categories).length) {
    return (
      <div className="form-group field field-object">
        <Input
          type="select"
          className="form-group field field-string"
          value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
          required={props.required}
          onChange={(event) => {
            props.onChange(event.target.value)
          }}
          disabled={readonly}
        >
          <option value="">{t("labels.select_category")}</option>
          {Object.values(categories).map((category) => {
            return (
              <option key={category.name} value={category.name}>
                {category.name}
              </option>
            )
          })}
        </Input>
      </div>
    )
  } else {
    return (
      <div className="form-group field field-object">
        <Input
          type="text"
          className="form-group field field-string"
          value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
          required={props.required}
          onChange={(event) => {
            props.onChange(event.target.value)
          }}
          disabled={readonly}
        />
      </div>
    )
  }
}

export function accountsWidget(props) {
  const irrCategories = props.formContext.IRRCategories
  const categories = props.formContext.categories
  const readonly = props.formContext.readonly

  const settingsLocation = props.formContext.settings.locationId
  const settingsLocationParentPath =
    settingsLocation &&
    props.formContext.locationsMapById &&
    props.formContext.locationsMapById[settingsLocation].parent_path

  const applyLocationAndSettingsFilter = (categories) => {
    if (!settingsLocationParentPath) return categories
    return categories.filter((c) => {
      const settingsLocationParentPathIds = settingsLocationParentPath.map(
        (_id) => props.formContext.locationsMapByPrivateId[_id].id,
      )

      return settingsLocationParentPathIds.includes(c.location.id)
    })
  }

  if (irrCategories && categories && Object.values(categories).length) {
    const categoryName = getCategoryName(props)
    const category = categoryName ? Object.values(categories).find((category) => category.name === categoryName) : null
    const irrCategory = categoryName
      ? Object.values(irrCategories).find((category) => category.name === categoryName)
      : null
    const sortedCategories = category && Object.values(category.categories).sort((a, b) => a.name.localeCompare(b.name))
    return (
      <div className="form-group field field-object">
        <Input
          type="select"
          className="form-group field field-string"
          value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
          required={props.required}
          onChange={(event) => {
            props.onChange(event.target.value)
          }}
          disabled={readonly}
        >
          <option value="">{t("labels.select_sub_category")}</option>
          {sortedCategories
            ? applyLocationAndSettingsFilter(sortedCategories).map((account) => {
                return (
                  <option key={account.name} value={account.name}>
                    {account.name}
                  </option>
                )
              })
            : null}
        </Input>

        {/* Missing Category Warning */}
        {!irrCategory?.categories[props.value] && (
          <>
            <Icon icon="error" className="date-warning" />
            <span>{t("notes.irr_category_not_selected")}</span>
          </>
        )}
      </div>
    )
  } else {
    return (
      <div className="form-group field field-object">
        <Input
          type="text"
          className="form-group field field-string"
          value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
          required={props.required}
          onChange={(event) => {
            props.onChange(event.target.value)
          }}
          disabled={readonly}
        />
      </div>
    )
  }
}

export function subAccountsWidget(props) {
  const categories = props.formContext.categories
  const proForma = props.formContext.proForma
  const readonly = props.formContext.readonly

  if (categories && Object.values(categories).length) {
    // id root_cashFlows_0_category, root_cashFlows_0_category
    const idParts = props.id.split("_")
    const categoryName =
      proForma[idParts[1]] && proForma[idParts[1]][idParts[2]] ? proForma[idParts[1]][idParts[2]].category : null
    const category = categoryName ? Object.values(categories).find((category) => category.name === categoryName) : null

    if (category && category.categories) {
      const accountName =
        proForma[idParts[1]] && proForma[idParts[1]][idParts[2]] ? proForma[idParts[1]][idParts[2]].account : null
      let account = accountName
        ? Object.values(category.categories).find((account) => account.name === accountName)
        : null

      if (account && account.categories) {
        return (
          <div className="form-group field field-object">
            <Input
              type="select"
              className="form-group field field-string"
              value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
              required={props.required}
              onChange={(event) => {
                props.onChange(event.target.value)
              }}
              disabled={readonly}
            >
              <option value="">{t("labels.select_sub_account")}</option>
              {account
                ? Object.values(account.categories).map((subAccount) => {
                    return (
                      <option key={subAccount.name} value={subAccount.name}>
                        {subAccount.name}
                      </option>
                    )
                  })
                : null}
            </Input>
          </div>
        )
      }
    }
  }
  return <div className="field-message">{t("notes.no_sub_accounts")}</div>
  // EP-1939 disable input on sub accounts where there are no options available
  // return (
  //   <div className="form-group field field-object">
  //     <Input
  //       type="text"
  //       className="form-group field field-string"
  //       value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
  //       required={props.required}
  //       onChange={(event) => {
  //         props.onChange(event.target.value)
  //       }}
  //       disabled={true} // EP-1939 disable input on sub accounts where there are no options available
  //     />
  //   </div>
  // )
}

export const pathSelectWidget = (props) => {
  const { id, required, value, schema } = props

  return (
    <div style={{ display: "flex" }} className="form-group field field-object">
      {schema.enum.length > 3 && (
        <Icon
          icon="priority_high"
          className="multi-system-warning"
          title={t("data.dialogs.project_has_multiple_systems")}
        />
      )}
      <select
        className="form-group field field-string form-select"
        id={id}
        value={value ?? ""}
        required={required}
        onChange={(event) => {
          props.onChange(event.target.value)
        }}
      >
        <option value="">{t("labels.select_path")}</option>
        {schema.enum.map((value, idx) => (
          <option key={value} value={value}>
            {schema.enumNames[idx]}
          </option>
        ))}
      </select>
    </div>
  )
}
