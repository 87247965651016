import React from "react"
import { EcosuiteComponentError, Error, Loading, Message } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"
import EcosuiteView from "@common/module/EcosuiteView"
import FinanceService from "@dashboard/data/project/finance/FinanceService"
import i18n from "src/i18n"

const { t } = i18n

export default class FinancialModelView extends EcosuiteView {
  componentDidMount() {
    super.componentDidMount()

    this.loadProjectSettings()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadProjectSettings()
    }
  }

  loadProjectSettings() {
    this.setStateIfMounted({
      settings: null,
      error: null,
    })

    const projectId = this.props.project.code
    FinanceService.getProjectSettings(projectId)
      .then((settings) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            settings: settings,
          })
        } else {
          Logger.debug(`${t("errors.audit")} ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(t("errors.audit") + projectId)
        Logger.error(err)

        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            settings: new EcosuiteComponentError(err),
          })
        }
      })
  }

  renderContent() {
    if (this.isContentError(this.state.settings)) {
      return <Error error={this.state.settings.getError()} />
    } else if (this.isContentValid(this.state.settings)) {
      if (this.state.settings.financialModelUrl) {
        return (
          <iframe src={`${this.state.settings.financialModelUrl}`} width="100%" height="100%">
            {t("headings.view_google_sheet")} {this.state.settings.financialModelUrl}
          </iframe>
        )
      } else {
        return <Message message={t("messages.no_associated_google_sheet")} />
      }
    } else {
      return <Loading message={t("loadingMsg.googleSheet")} />
    }
  }
}
