// The shared tariff UI schema used in forms.
export const sharedTariffUISchema = {
  "ui:order": [
    "name",
    "description",
    "author",
    "created",
    "lastUpdated",
    "lastUpdatedBy",
    "start",
    "end",
    "flatDemand",
    "fixedChargeUnits",
    "fixedChargeFirstMeter",
    "energyWeekDaySchedule",
    "energyWeekEndSchedule",
    "*",
    "rates",
  ],
}
