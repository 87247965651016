const connectivity = require("./module/connectivity/views.json")
const data = require("./module/data/views.json")
const energy = require("./module/energy/views.json")
const demandresponse = require("./module/demandresponse/views.json")
const event = require("./module/event/views.json")
const finance = require("./module/finance/views.json")
const process = require("./module/process/views.json")

const DashboardModules = {
  getModules() {
    return {
      event: event,
      energy: energy,
      demandresponse: demandresponse,
      connectivity: connectivity,
      finance: finance,
      data: data,
      process: process,
    }
  },
}

export default DashboardModules
