import React, { useEffect, useMemo } from "react"
import Uppy, { Body, Meta, UploadResult, UppyFile } from "@uppy/core"
import Webcam from "@uppy/webcam"
import { Dashboard } from "@uppy/react"
import AwsS3, { AwsS3UploadParameters } from "@uppy/aws-s3"
import JSZip from "jszip"
import "@uppy/core/dist/style.min.css"
import "@uppy/dashboard/dist/style.min.css"
import "@uppy/webcam/dist/style.min.css"
import { useTheme } from "../Components/Header"
import API from "@common/API"
import { MinimalRequiredUppyFile } from "@uppy/utils/lib/UppyFile"
import { useResponsive } from "src/lib/utils"
import { H4 } from "src/components/ui/typography"
import { Auth } from "aws-amplify"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"

const ALLOWED_FILE_TYPES = [
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".doc",
  ".docx",
  ".gif",
  ".bmp",
  ".webp",
  ".zip",
]

const MAX_FILE_SIZE = 250 * 1024 * 1024 // 250MB
const MAX_NUMBER_OF_FILES = 1000

interface PresignedPostData {
  url: string
  fields: Record<string, string>
}

interface UploadParameters {
  documentName: string
  contentType: string
}

const getUploadParameters = async (file: UppyFile<Meta, Body>): Promise<AwsS3UploadParameters> => {
  const filename = `${Date.now()}-${file.name}`
  const presigned = await API.post<PresignedPostData, UploadParameters>("/records/onboarding-document", {
    documentName: filename,
    contentType: file.type,
  })

  return {
    method: "POST",
    url: presigned.url,
    fields: presigned.fields,
  }
}

const extractZipContents = async (
  file: UppyFile<Meta, Body>,
): Promise<MinimalRequiredUppyFile<Meta, Record<string, never>>[]> => {
  const zipContent = await JSZip.loadAsync(file.data)
  const extractedFiles: MinimalRequiredUppyFile<Meta, Record<string, never>>[] = []

  await Promise.all(
    Object.entries(zipContent.files).map(async ([filename, zipEntry]) => {
      if (!zipEntry.dir) {
        const fileExtension = `.${filename.split(".").pop()}`
        if (ALLOWED_FILE_TYPES.includes(fileExtension.toLowerCase())) {
          const blob = await zipEntry.async("blob")
          const folderPath = filename.split("/").slice(0, -1).join("_")
          const baseFilename = filename.split("/").pop() || filename
          const uniqueFilename = folderPath ? `${folderPath}_${baseFilename}` : baseFilename

          extractedFiles.push({
            name: uniqueFilename,
            type: blob.type,
            data: blob,
            meta: { relativePath: filename, name: uniqueFilename },
            source: "Zip Extraction",
            isRemote: false,
          })
        }
      }
    }),
  )

  return extractedFiles
}

const DocumentUploadDashboard: React.FC = () => {
  const uppy = useMemo(() => {
    return new Uppy({
      restrictions: {
        allowedFileTypes: ALLOWED_FILE_TYPES,
        maxFileSize: MAX_FILE_SIZE,
        maxNumberOfFiles: MAX_NUMBER_OF_FILES,
      },
    })
      .use(Webcam)
      .use(AwsS3, {
        getUploadParameters,
        shouldUseMultipart: false,
      })
  }, [])

  useEffect(() => {
    const handleFileAdded = async (file: UppyFile<Meta, Body>) => {
      if (file.extension === "zip") {
        try {
          const extractedFiles = await extractZipContents(file)
          uppy.removeFile(file.id)
          extractedFiles.forEach((extractedFile) => uppy.addFile(extractedFile))
        } catch (error) {
          console.error("Error extracting zip file:", error)
          uppy.removeFile(file.id)
        }
      }
    }

    const handleFilesAdded = (files: UppyFile<Meta, Body>[]) => {
      const invalidFiles = files.filter(
        (file) => !ALLOWED_FILE_TYPES.some((type) => file.name?.toLowerCase().endsWith(type.toLowerCase())),
      )
      invalidFiles.forEach((file) => uppy.removeFile(file.id))
    }

    const handleComplete = (result: UploadResult<Meta, Record<string, never>>) => {
      const { successful, failed } = result

      if (successful!.length > 0 && failed!.length === 0) {
        toast.success("All uploads completed successfully!")
      } else if (failed!.length > 0) {
        toast.warn(`Uploads completed with ${successful!.length} successes and ${failed!.length} failures.`)
      }
    }

    uppy.on("complete", handleComplete)
    uppy.on("file-added", handleFileAdded)
    uppy.on("files-added", handleFilesAdded)

    return () => {
      uppy.off("file-added", handleFileAdded)
      uppy.off("files-added", handleFilesAdded)
      uppy.off("complete", handleComplete)
    }
  }, [uppy])

  React.useEffect(() => {
    async function getCurrentUser() {
      const user = await Auth.currentAuthenticatedUser()
    }
    getCurrentUser()
  }, [])

  const { theme } = useTheme()
  const screen = useResponsive()
  const history = useHistory()
  const dashboardTheme = theme === "light" ? "light" : "dark"

  const getDashboardSize = () => {
    if (screen === "xs") return { width: 280, height: 300 }
    if (screen === "sm") return { width: 450 }
    if (screen === "md") return { width: 600 }
    if (screen === "lg") return { width: 600 }
    return { width: 1200 }
  }

  const { width, height } = getDashboardSize()

  const client = useQueryClient()
  return (
    <div className="">
      <H4>Please upload your org documents. They will be distilled and your org will be set-up automatically! This process will typically take few days!</H4>
      <div className="onboarding tw-flex tw-h-[calc(100vh-64px)] tw-items-center tw-justify-center tw-flex-col">
        <div>
          <Dashboard
            doneButtonHandler={() => {
              client.invalidateQueries("onboarding-documents")
              history.push("/onboarding/view")
            }}
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            theme={dashboardTheme}
            width={width}
            height={height}
          />
        </div>
      </div>
    </div>
  )
}

export default DocumentUploadDashboard
