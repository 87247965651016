import React from "react"
import { create, all } from "mathjs"

import Utils from "@common/utils/Utils"
import EcosuiteComponent, { EcosuiteComponentError, Loading, Error } from "@common/EcosuiteComponent"

import EnergyService from "@dashboard/energy/EnergyService"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"

const { t } = i18n
const math = create(all)

export default class EconodeRangeConsumptionCosts extends EcosuiteComponent {
  componentDidMount() {
    super.componentDidMount()

    this.loadRange(this.props.range)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate()

    if (this.props.project !== prevProps.project) {
      this.loadRange(this.props.range)
    }
  }

  loadRange(range) {
    this.loadConsumptionCost(range)
    this.loadEnergyReadings(range)
  }

  loadConsumptionCost(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      consumptionCost: undefined,
    })

    if (this.props.project) {
      EnergyService.getProjectConsumptionCost(range, null, this.props.project.code)
        .then((response) => {
          this.setStateIfMounted({
            consumptionCost: response,
          })
        })
        .catch((err) => {
          this.setStateIfMounted({
            consumptionCost: new EcosuiteComponentError(err),
          })
        })
    }
  }

  loadEnergyReadings(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      readings: undefined,
    })

    EnergyService.getEnergyReadings(range)
      .then((response) => {
        this.setStateIfMounted({
          readings: response.projects[this.props.project.code],
        })
      })
      .catch((err) => {
        this.setStateIfMounted({
          readings: new EcosuiteComponentError(err),
        })
      })
  }

  renderContent() {
    if (this.isContentError(this.state.readings)) {
      return <Error error={this.state.readings} />
    } else if (this.isContentError(this.state.consumptionCost)) {
      return <Error error={this.state.consumptionCost} />
    } else if (this.isContentValid(this.state.readings) && this.isContentValid(this.state.consumptionCost)) {
      const consumption = this.state.readings.consumption ? this.state.readings.consumption : 0
      const totalCost = this.state.consumptionCost.projectDatums.consumptionCost.totalCost ? this.state.consumptionCost.projectDatums.consumptionCost.totalCost : 0
      const cost = Utils.formatCurrency(totalCost)
      const costRatio = Utils.formatCurrency(math.divide(totalCost, consumption / 1000))

      return (
        <div>
          {/* <div className="sidebar-heading" id="this-month-consumption">
            {EnergyUtils.displayWattHours(this.state.readings.consumption)}
          </div>
          <div className="sidebar-heading demand" id="this-month-demand">
            {EnergyUtils.displayWatts(peakConsumption)}
          </div> */}
          <div className="sidebar-heading cost" id="this-month-cost">
            {cost}
          </div>

          <div className="sidebar-heading cost" id="this-month-cost-ratio">{`${costRatio ? costRatio : 0} / kWh`}</div>

          {/* <UncontrolledTooltip target="this-month-consumption">{`Consumption of ${EnergyUtils.displayWattHours(this.state.readings.consumption)} so far this month`}</UncontrolledTooltip>
          <UncontrolledTooltip target="this-month-demand">{`Peak demand of ${EnergyUtils.displayWatts(peakConsumption)} so far this month`}</UncontrolledTooltip> */}
          <UncontrolledTooltip target="this-month-cost">{t("econode.labels.estimated_charge", { cost: cost })}</UncontrolledTooltip>
          <UncontrolledTooltip target="this-month-cost-ratio">{t("econode.labels.estimated_unit_charge", { costRatio: costRatio })}</UncontrolledTooltip>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
