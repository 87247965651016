import API from "@common/API"

const TerminalService = {
  startSession(nodeId: string) {
    return API.post("/solarnetwork/ssh?nodeId=" + nodeId)
  },

  stopSession(nodeId: string, session: unknown) {
    return API.delete("/solarnetwork/ssh?nodeId=" + nodeId, session)
  },

  setSshCredentials(nodeId: string, credentials: unknown) {
    return API.post("/solarnetwork/credentials/ssh?nodeId=" + nodeId, credentials)
  },

  setSolarNodeCredentials(nodeId: string, proxyUrl: string, credentials: unknown) {
    return API.post("/solarnetwork/credentials/solarnode?nodeId=" + nodeId + "&proxyUrl=" + proxyUrl, credentials)
  },

  getSolarNodeLoginUrl(nodeId: string, proxyUrl: string) {
    return API.get(`/solarnetwork/solarnode/login-url?nodeId=${nodeId}&proxyUrl=${proxyUrl}`)
  },
}

export default TerminalService
