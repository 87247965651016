import React from "react"

import EcosuiteComponent, { EcosuiteComponentError, Loading, Error } from "@common/EcosuiteComponent"

import EnergyService from "@dashboard/energy/EnergyService"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

export default class EconodeRangeConsumption extends EcosuiteComponent {
  componentDidMount() {
    super.componentDidMount()

    this.loadRange(this.props.range)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate()

    if (this.props.project !== prevProps.project) {
      this.loadRange(this.props.range)
    }
  }

  loadRange(range) {
    this.loadConsumptionDatums(range)
    this.loadConsumptionCost(range)
    this.loadEnergyReadings(range)
  }

  loadConsumptionDatums(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        datums: undefined,
        datumsError: undefined,
      },
      () => {
        if (this.props.project) {
          EnergyService.getProjectConsumptionDatums(range, null, this.props.project.code)
            .then((response) => {
              this.setStateIfMounted({
                datums: response,
              })
            })
            .catch((err) => {
              this.setStateIfMounted({
                datums: new EcosuiteComponentError(err),
              })
            })
        }
      },
    )
  }

  loadConsumptionCost(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      consumptionCost: undefined,
    })

    if (this.props.project) {
      EnergyService.getProjectConsumptionCost(range, null, this.props.project.code)
        .then((response) => {
          this.setStateIfMounted({
            consumptionCost: response,
          })
        })
        .catch((err) => {
          this.setStateIfMounted({
            consumptionCost: new EcosuiteComponentError(err),
          })
        })
    }
  }

  loadEnergyReadings(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      readings: undefined,
    })

    EnergyService.getEnergyReadings(range)
      .then((response) => {
        this.setStateIfMounted({
          readings: response.projects[this.props.project.code],
        })
      })
      .catch((err) => {
        this.setStateIfMounted({
          readings: new EcosuiteComponentError(err),
        })
      })
  }

  renderContent() {
    if (this.isContentError(this.state.datums)) {
      return <Error error={this.state.datums} />
    } else if (this.isContentError(this.state.readings)) {
      return <Error error={this.state.readings} />
    } else if (this.isContentError(this.state.consumptionCost)) {
      return <Error error={this.state.consumptionCost} />
    } else if (this.isContentValid(this.state.datums) && this.isContentValid(this.state.readings) && this.isContentValid(this.state.consumptionCost)) {
      const peakConsumption = Object.values(this.state.datums.projectDatums.aggregatedTotals || {}).reduce((peak, aggregate) => {
        if (!peak || aggregate.consumptionMax > peak) {
          return aggregate.consumptionMax
        } else {
          return peak
        }
      }, null)

      // const consumption = this.state.readings.consumption ? this.state.readings.consumption : 0
      // const totalCost = this.state.consumptionCost.projectDatums.consumptionCost.totalCost ? this.state.consumptionCost.projectDatums.consumptionCost.totalCost : 0
      // const cost = Utils.formatCurrency(totalCost)
      // const costRatio = Utils.formatCurrency(math.divide(totalCost, consumption / 1000))

      return (
        <div>
          <div className="sidebar-heading" id="this-month-consumption">
            {EnergyUtils.displayWattHours(this.state.readings.consumption)}
          </div>
          <div className="sidebar-heading demand" id="this-month-demand">
            {EnergyUtils.displayWatts(peakConsumption)}
          </div>
          <div className="sidebar-heading demand" id="this-month-peak-apparant-power">
            {EnergyUtils.formatKva(this.props.peakApparentPower)}
          </div>
          {/* <div className="sidebar-heading cost" id="this-month-cost">
            {cost}
          </div>

          <div className="sidebar-heading cost" id="this-month-cost-ratio">{`${costRatio ? costRatio : 0} / kWh`}</div> */}

          <UncontrolledTooltip target="this-month-consumption">{`Consumption of ${EnergyUtils.displayWattHours(this.state.readings.consumption)} so far this month`}</UncontrolledTooltip>
          <UncontrolledTooltip target="this-month-demand">{`Peak demand of ${EnergyUtils.displayWatts(peakConsumption)} so far this month`}</UncontrolledTooltip>
          <UncontrolledTooltip target="this-month-peak-apparant-power">{`Peak apparent power of ${EnergyUtils.formatKva(this.props.peakApparentPower)} so far this month`}</UncontrolledTooltip>

          {/* <UncontrolledTooltip target="this-month-cost">{`Estimated charge of ${cost} so far this month`}</UncontrolledTooltip>
          <UncontrolledTooltip target="this-month-cost-ratio">{`Estimated unit charge of ${costRatio}/kWh so far this month`}</UncontrolledTooltip> */}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
