import React from "react"
import { ButtonGroup, Button } from "reactstrap"

import EcosuiteModuleHeader from "@common/module/EcosuiteModuleHeader"
import CustomRange from "@common/module/CustomRange"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import i18n from "src/i18n"
import { withRouter } from "react-router-dom"

const { t } = i18n
const PRECANNED_RANGES = {
  allTime: "All Time",
  lastYear: "Last Year",
  last12Months: "Last 12 Months",
  thisYear: "This Year",
  // lastMonth: "Last Month",
  // thisMonth: "This Month",
  last30Days: "Last 30 Days",
  thisWeek: "This Week",
  last7Days: "Last 7 Days",
  yesterday: "Yesterday",
  today: "Today",
}

class EconodeModuleHeader extends EcosuiteModuleHeader {
  renderDateSelector() {
    // EP-710 If there a single project is selected we make sure that the range only covers that project
    let projects = this.props.project ? [this.props.project] : this.props.projects

    return (
      <ButtonGroup className="header-button-group date-range-group">
        <Button
          className={this.props.rangeName === "lastMonth" ? "selected" : null}
          onClick={() => {
            this.selectRange("lastMonth")
          }}
          disabled={!this.isDateRangeEnabled()}
        >
          {t("dates.Last Month")}
        </Button>
        <Button
          className={this.props.rangeName === "thisMonth" ? "selected" : null}
          onClick={() => {
            this.selectRange("thisMonth")
          }}
          disabled={!this.isDateRangeEnabled()}
        >
          {t("dates.This Month")}
        </Button>
        <CustomRange
          isOpen={this.state.dateRangeOpen}
          projects={projects}
          selectRange={this.selectRange}
          updateCustomRange={this.updateCustomRange}
          toggle={this.toggleDateRange}
          range={DateRangeUtils.getRange(this.props.rangeName, this.props.customRange, projects)}
          rangeName={this.props.rangeName}
          customRange={this.state.customRange}
          disabled={!this.isDateRangeEnabled()}
          precannedRanges={PRECANNED_RANGES}
          showTime={this.props.showTime}
          end={true}
        />
      </ButtonGroup>
    )
  }
}

export default withRouter(EconodeModuleHeader)
