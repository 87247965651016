import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const resources = {
  en: {
    translation: {
      ...require("./locales/en/common.json"),
      ...require("./locales/en/app.json"),
      ...require("./locales/en/admin.json"),
      ...require("./locales/en/calendar.json"),
      ...require("./locales/en/economics.json"),
      ...require("./locales/en/energy.json"),
      ...require("./locales/en/data.json"),
      ...require("./locales/en/econode.json"),
      ...require("./locales/en/demand-response.json"),
      ...require("./locales/en/process.json"),
      ...require("./locales/en/event.json"),
      ...require("./locales/en/connectivity.json"),
    },
  },
  en_us: {
    translation: {
      ...require("./locales/en_us/common.json"),
      ...require("./locales/en_us/app.json"),
      ...require("./locales/en_us/admin.json"),
      ...require("./locales/en_us/calendar.json"),
      ...require("./locales/en_us/economics.json"),
      ...require("./locales/en_us/energy.json"),
      ...require("./locales/en_us/data.json"),
      ...require("./locales/en_us/econode.json"),
      ...require("./locales/en_us/demand-response.json"),
      ...require("./locales/en_us/process.json"),
      ...require("./locales/en_us/event.json"),
      ...require("./locales/en_us/connectivity.json"),
    },
  },
  mg: {
    translation: {
      ...require("./locales/mg/common.json"),
      ...require("./locales/mg/app.json"),
      ...require("./locales/mg/calendar.json"),
      ...require("./locales/mg/admin.json"),
      ...require("./locales/mg/economics.json"),
      ...require("./locales/mg/energy.json"),
      ...require("./locales/mg/data.json"),
      ...require("./locales/mg/econode.json"),
      ...require("./locales/mg/demand-response.json"),
      ...require("./locales/mg/process.json"),
      ...require("./locales/mg/connectivity.json"),
    },
  },
  es: {
    translation: {
      ...require("./locales/es/common.json"),
      ...require("./locales/es/app.json"),
      ...require("./locales/es/admin.json"),
      ...require("./locales/es/data.json"),
      ...require("./locales/es/event.json"),
      ...require("./locales/es/process.json"),
      ...require("./locales/es/demand-response.json"),
      ...require("./locales/es/econode.json"),
      ...require("./locales/es/energy.json"),
      ...require("./locales/es/economics.json"),
      ...require("./locales/es/connectivity.json"),
      ...require("./locales/mg/calendar.json"),
    },
  },
  fr: {
    translation: {
      ...require("./locales/fr/common.json"),
      ...require("./locales/fr/app.json"),
      ...require("./locales/fr/calendar.json"),
      ...require("./locales/fr/admin.json"),
      ...require("./locales/fr/economics.json"),
      ...require("./locales/fr/energy.json"),
      ...require("./locales/fr/data.json"),
      ...require("./locales/fr/econode.json"),
      ...require("./locales/fr/demand-response.json"),
      ...require("./locales/fr/process.json"),
      ...require("./locales/fr/connectivity.json"),
    },
  },
}

// Initialize i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    saveLanguageToLocalStorage: true,
    initImmediate: false,
    useSuspense: false,
    react: {
      defaultTransParent: "div",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b", "sup", "sub", "p", "ul", "li"],
      transWrapTextNodes: "",
    },
  })

// Save the user's language preference to localStorage whenever the language changes
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng)
})

// Load the user's language preference from localStorage on app startup
const lng = localStorage.getItem("language")
if (lng) i18n.changeLanguage(lng)

export default i18n
