import React from "react"

import { MapView } from "@common/module/EcosuiteView"
import { ProjectMarker } from "@dashboard/ProjectMap"
import moment from "moment"
import DRService from "../DRService"
import i18n from "src/i18n"

const { t } = i18n
class EventMarker extends ProjectMarker {
  renderProjectInfo() {
    const inProgress = moment(this.props.project.start) < moment(new Date())

    return (
      <React.Fragment>
        <div>
          <p>
            <span style={{ "font-weight": "bold" }}>{this.props.project.name}</span>
            <br />
            <span style={{ "font-style": "italic", "font-size": "10px" }}>{this.props.project.id}</span>
          </p>

          <>
            <p style={{ display: "inline" }}>
              {`${t("labels.goal")}:`}
              <span style={{ "font-weight": "bold" }}>{" " + this.props.project.goal}</span>
            </p>

            {inProgress ? (
              <p>
                {t("demand.labels.in_progress_since")}
                <span style={{ "font-style": "italic" }}>{" " + moment(this.props.project.start).fromNow() + ". "}</span>
                {t("demand.labels.event_ends_in")}
                <span style={{ "font-style": "italic" }}>{" " + moment(this.props.project.end).fromNow() + "."}</span>
              </p>
            ) : (
              <p>
                {t("labels.begins")}
                <span style={{ "font-style": "italic" }}>{" " + moment(this.props.project.start).fromNow() + ". "}</span>
              </p>
            )}
          </>
        </div>
      </React.Fragment>
    )
  }

  getColor() {
    const inProgress = moment(this.props.project.start) < moment(new Date())

    if (inProgress) {
      return "#4CAF50"
    } else {
      return "#0EB6DB"
    }
  }
}

export default class DRMapView extends MapView {
  constructor(props, name) {
    super(props, name)

    DRService.listEvents().then((events) => {
      this.setState({
        events: events,
      })
    })
  }

  renderViewControls() {
    return null
  }

  eventInThePast(event) {
    const now = moment(new Date())
    return now > moment(event.endDate)
  }

  getMapMarkers() {
    return this.state.events == null
      ? []
      : // We don't want to render events in the past
        this.state.events
          .filter((event) => !this.eventInThePast(event))
          .map((event) => {
            const projects = event.participants.reduce((ps, p) => {
              const find = this.props.allProjects.find((project) => project.code === p)
              if (find) {
                ps.push(find)
              }
              return ps
            }, [])

            return projects.map((project) => (
              <React.Fragment key={project.code}>
                <EventMarker
                  project={{
                    id: event.id,
                    lat: project.lat,
                    long: project.long,
                    status: "in-progress",
                    name: event.name,
                    start: event.startDate,
                    end: event.endDate,
                    goal: event.goal,
                  }}
                  projectReading={"Hi"}
                  projectStatus={"Hello"}
                  showGeneration={this.props.showGeneration}
                  showConsumption={this.props.showConsumption}
                />
              </React.Fragment>
            ))
          })
  }
}
