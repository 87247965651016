import * as React from "react"
import { Button } from "reactstrap"
import { importProjectMilestones } from "./CodaService"
import NiceModal from "@ebay/nice-modal-react"
import i18n from "src/i18n"
import ConfirmDialog from "@common/display/ConfirmModal/ConfirmModal"

const { t } = i18n
export default function SyncProjectMilestones() {
  const [loading, setLoading] = React.useState(false)

  const handleSync = async () => {
    setLoading(true)
    const result = await importProjectMilestones()
    setLoading(false)
    NiceModal.show(ConfirmDialog, {
      size: "md",
      title: "Sync Project Milestones Result",
      message: (
        <div>
          <b>{t("headings.failed_project_codes")}:</b> {result.failedProjectCodes.length > 0 ? result.failedProjectCodes.join(", ") : "-"}
          <br />
          <b>{t("headings.updated_project_codes")}:</b> {result.updatedProjectCodes.length > 0 ? result.updatedProjectCodes.join(",") : "-"}
        </div>
      ),
      confirmText: "Close",
    })
  }

  return (
    <Button color="ecogy" onClick={handleSync} disabled={loading} size="sm">
      {loading ? "Syncing..." : "Sync All Project Milestone Dates"}
    </Button>
  )
}
