export const SEPARATOR = "/"

const NODE_IDX = 0
const PROJECT_IDX = 1
const SITE_IDX = 2
const SYSTEM_IDX = 3
const DEVICE_TYPE_IDX = 4
const DEVICE_ID_IDX = 5
const SUB_DEVICE_TYPE_IDX = 6
const SUB_DEVICE_ID_IDX = 7

const DEVICE_PROPERTY_IDX = 6
const SUB_DEVICE_PROPERTY_IDX = 8

export const DEVICE_TYPE_PYRANOMETER = "PYR"

/**
 * Utilities class for dealing with SolarNetwork source IDs. These are in the form:
 * <node>/<project>/<site>/<system>/<device type>/<device id>, optionally in some cases we add an additional /<property> param
 */
var SourceUtils = {
  getNodeAndSource(path) {
    let parts = path.split(SEPARATOR)
    return parts[NODE_IDX] + SEPARATOR + parts[PROJECT_IDX] + SEPARATOR + parts[SITE_IDX] + SEPARATOR + parts[SYSTEM_IDX] + SEPARATOR + parts[DEVICE_TYPE_IDX] + SEPARATOR + parts[DEVICE_ID_IDX]
  },

  getSource(path) {
    let parts = path.split(SEPARATOR)
    return SEPARATOR + parts[PROJECT_IDX] + SEPARATOR + parts[SITE_IDX] + SEPARATOR + parts[SYSTEM_IDX] + SEPARATOR + parts[DEVICE_TYPE_IDX] + SEPARATOR + parts[DEVICE_ID_IDX]
  },

  getNode(path) {
    return this.getPathElement(path, NODE_IDX)
  },

  getProjectCode(path) {
    return this.getPathElement(path, PROJECT_IDX)
  },

  getSiteCode(path) {
    return this.getPathElement(path, SITE_IDX)
  },

  getSystemCode(path) {
    return this.getPathElement(path, SYSTEM_IDX)
  },

  getDeviceType(path) {
    return this.getPathElement(path, DEVICE_TYPE_IDX)
  },

  getDeviceID(path) {
    return this.getPathElement(path, DEVICE_ID_IDX)
  },

  getSubDeviceType(path) {
    return this.getPathElement(path, SUB_DEVICE_TYPE_IDX)
  },

  getSubDeviceID(path) {
    return this.getPathElement(path, SUB_DEVICE_ID_IDX)
  },

  getDevice(path) {
    return this.getDeviceType(path) + SEPARATOR + this.getDeviceID(path)
  },

  getPathElement(path, idx) {
    let parts = path.split(SEPARATOR)
    if (parts.length > idx) {
      return path.split(SEPARATOR)[idx]
    }
    return null
  },

  getProperty(path) {
    if (this.getSubDeviceID(path)) {
      return this.getPathElement(path, SUB_DEVICE_PROPERTY_IDX)
    } else {
      return this.getPathElement(path, DEVICE_PROPERTY_IDX)
    }
  },

  isDevice(path) {
    let parts = path.split(SEPARATOR)
    return parts.length > DEVICE_TYPE_IDX
  },

  isProperty(path) {
    /** @type{Array.<string>} */
    const splits = path.split(SEPARATOR)

    if (this.getSubDeviceID(path)) {
      /** @type boolean */
      return splits.length > SUB_DEVICE_PROPERTY_IDX
    } else {
      /** @type boolean */
      return splits.length > DEVICE_PROPERTY_IDX
    }
  },
}

export default SourceUtils
