import React from "react"

import { Loading } from "@common/EcosuiteComponent"
import EnergyUtils from "../EnergyUtils"
import EnergySourceView, { Source } from "../views/EnergySourceView"
import SourceStatusTooltips from "../views/source/SourceStatusTooltips"

export default class EnergyProjectSourceView extends EnergySourceView {
  renderMainView() {
    if (this.props.projectStatus) {
      return (
        <React.Fragment>
          <table>
            {this.renderTableHeader()}
            <tbody>
              {EnergyUtils.getSites(this.props.projectStatus).map((site) => {
                return EnergyUtils.getSystems(site).map((system) => {
                  if (system.nodesStatus) {
                    return system.nodesStatus.map((nodeStatus) => {
                      if (this.isShowSource(nodeStatus)) {
                        return (
                          <Source
                            key={nodeStatus.nodeId + "-" + nodeStatus.sourceId}
                            nodeStatus={nodeStatus}
                            project={this.props.project}
                            system={system}
                            groups={this.props.groups}
                            showGeneration={this.props.showGeneration}
                            showConsumption={this.props.showConsumption}
                            showStorage={this.props.showStorage}
                          />
                        )
                      } else {
                        return null
                      }
                    })
                  } else {
                    return null
                  }
                })
              })}
            </tbody>
          </table>

          <SourceStatusTooltips />
        </React.Fragment>
      )
    } else {
      return <Loading />
    }
  }

  openNodeTerminal(nodeId, sourceId, projectId) {
    window.open(`/terminal?nodeId=${nodeId}&sourceId=${sourceId}&projectId=${projectId}`, "ssh-" + nodeId)
  }

  openNodeConsole(nodeId) {
    window.open(process.env.REACT_APP_SOLARNETWORK_HOST + "/solarssh/?nodeId=" + nodeId, "solar-ssh-" + nodeId)
  }

  openNodeDashboard(nodeId) {
    window.open(process.env.REACT_APP_SOLARNETWORK_HOST + "/node-dashboard/login?nodeId=" + nodeId, "solar-dashboard-" + nodeId)
  }
}
