import React from "react"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"

export default class ForecastConsumption extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.predictedConsumption)) {
      return <Error error={this.props.predictedConsumption.getError()} />
    }

    if (this.isContentValid(this.props.predictedConsumption) && this.props.predictedConsumption.projectDatums[this.props.forecastKey] !== undefined) {
      return <div className="sidebar-heading">{EnergyUtils.displayWattHours(this.props.predictedConsumption.projectDatums[this.props.forecastKey])}</div>
    } else {
      return <Loading />
    }
  }
}
