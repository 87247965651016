import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { DataType, EditingMode, SortingMode, Table } from "ka-table"
import { LayoutWithSideBar } from "../Components/Layout"
import { H4 } from "src/components/ui/typography"
import { GetChecklistResponse, useGetPublicChecklist } from "src/services/checklist"
import { ThemeProvider } from "../Components/Header"
import FullPageSpinner from "../Components/FullPageSpinner"

type TabType = "ntp" | "pto" | "icr"
type DataKey = "ntpData" | "ptoData" | "icrData"

const DATA_KEY_TO_TAB_MAP: Record<DataKey, TabType> = {
  ntpData: "ntp",
  ptoData: "pto",
  icrData: "icr",
}

interface SidebarProps {
  activeTab: TabType
  onTabChange: (tab: TabType) => void
  tabs: TabType[]
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, onTabChange, tabs }) => (
  <div className="tw-w-[300px] tw-sidebar tw-p-4">
    <H4>Selected Checklist</H4>
    <div className="tw-mt-4 tw-flex tw-flex-col tw-space-y-2">
      {tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => onTabChange(tab)}
          className={`
            tw-px-4 tw-py-2 tw-text-left tw-rounded-md tw-transition-colors
            ${activeTab === tab ? "tw-bg-primary tw-text-primary-foreground" : "tw-text-foreground hover:tw-bg-muted"}
          `}
        >
          {tab.toUpperCase()}
        </button>
      ))}
    </div>
  </div>
)

const CheckList: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const { data, isLoading } = useGetPublicChecklist(uuid)
  const [activeTab, setActiveTab] = useState<TabType>("ntp")

  useEffect(() => {
    if (data?.metaData) {
      const firstAvailableTab = Object.keys(DATA_KEY_TO_TAB_MAP).find((key) => data.metaData[key as DataKey]) as
        | DataKey
        | undefined
      if (firstAvailableTab) {
        setActiveTab(DATA_KEY_TO_TAB_MAP[firstAvailableTab])
      }
    }
  }, [data])

  if (isLoading) {
    return (
      <ThemeProvider>
        <FullPageSpinner />
      </ThemeProvider>
    )
  }

  const tabs = Object.entries(data?.metaData || {})
    .filter(([key]) => key in DATA_KEY_TO_TAB_MAP)
    .map(([key]) => DATA_KEY_TO_TAB_MAP[key as DataKey])

  const renderActiveTable = () => {
    if (!data) return null
    const activeData = data.metaData[`${activeTab}Data` as DataKey]
    return activeData ? <DataTables data={activeData} /> : null
  }

  return (
    <LayoutWithSideBar sidebar={<Sidebar activeTab={activeTab} onTabChange={setActiveTab} tabs={tabs} />}>
      <>{renderActiveTable()}</>
    </LayoutWithSideBar>
  )
}

interface TableItem {
  name: string
  status: boolean
  record: string
  data: string
  notes: string
}

interface NTPTableProps {
  sectionName: string
  sectionData: Record<string, any>
}

const NTPTable: React.FC<NTPTableProps> = ({ sectionName, sectionData }) => {
  const columns = [
    { key: "name", title: "Name", dataType: DataType.String },
    { key: "status", title: "Status", dataType: DataType.Boolean, width: "100px" },
    { key: "record", title: "Record", dataType: DataType.String, width: "280px" },
    { key: "data", title: "Data", dataType: DataType.String },
    { key: "notes", title: "Notes", dataType: DataType.String },
  ]

  const sortedData: TableItem[] = Object.entries(sectionData)
    .filter(([key]) => key !== "ui:order")
    .sort(([aKey], [bKey]) => {
      const order = sectionData["ui:order"] || []
      return order.indexOf(aKey) - order.indexOf(bKey)
    })
    .map(([key, value]: [string, TableItem & { isChecked: boolean }]) => ({
      name: key,
      status: value.isChecked,
      record: value.record || "",
      data: value.data || "",
      notes: value.notes || "",
    }))

  return (
    <div className="tw-mb-8 twx-ka-table">
      <H4 className="tw-text-xl tw-font-bold tw-mb-4">{sectionName}</H4>
      <Table
        columns={columns}
        data={sortedData}
        rowKeyField="name"
        sortingMode={SortingMode.Single}
        editingMode={EditingMode.None}
        childComponents={{
          cell: {
            content: (props: any) => {
              if (props.column.key === "status") {
                return (
                  <div
                    className={`
            tw-w-6 tw-h-6 tw-border-2 tw-rounded-sm tw-flex tw-items-center tw-justify-center
            ${
              props.value
                ? "tw-bg-emerald-500 tw-border-emerald-500 dark:tw-bg-emerald-600 dark:tw-border-emerald-600"
                : "tw-border-gray-300 dark:tw-border-gray-600"
            }
            tw-transition-colors tw-duration-200 tw-ease-in-out
          `}
                  >
                    {props.value && (
                      <svg
                        className="tw-w-4 tw-h-4 tw-text-white dark:tw-text-gray-200"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M5 13l4 4L19 7"></path>
                      </svg>
                    )}
                  </div>
                )
              }
              return <>{props.value}</>
            },
          },
        }}
      />
    </div>
  )
}

interface DataTablesProps {
  data?: Record<string, any>
}

const DataTables: React.FC<DataTablesProps> = ({ data }) => {
  const sortedSections = Object.entries(data || {})
    .sort(([a], [b]) => a.localeCompare(b))
    .filter(([_, sectionData]) => Object.keys(sectionData).some((k) => k !== "ui:order"))

  return (
    <div className="tw-p-4">
      {sortedSections.map(([sectionName, sectionData], index) => (
        <NTPTable key={index} sectionName={sectionName} sectionData={sectionData} />
      ))}
    </div>
  )
}

export default CheckList
