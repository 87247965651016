import React from "react"
import EcosuiteComponent from "@common/EcosuiteComponent"
import { ListGroup, ListGroupItem } from "reactstrap"
import Logger from "@common/Logger"

export default class PropertyFilter extends EcosuiteComponent {
  /* constructor(props) {
    super(props)
    this.state.selectedProperties = new Set()
  } */

  render() {
    var properties = []
    this.props.properties.forEach((property) => {
      properties.push(property)
    })
    Logger.info("PropertyFilter: properties = " + JSON.stringify(properties))
    return (
      <div className="property-selector">
        <ListGroup className={"property-selector-list"}>
          {properties.map((property) => {
            return (
              <ListGroupItem
                key={property}
                tag="button"
                action
                flush={true}
                active={this.props.selectedProperties.has(property)}
                onClick={(e) => {
                  e.stopPropagation()
                  let selectedProperties = new Set().union(this.props.selectedProperties)
                  if (selectedProperties.has(property)) {
                    selectedProperties.delete(property)
                  } else {
                    selectedProperties.add(property)
                  }
                  this.props.toggleProperty(property, selectedProperties)
                }}
                className="property"
                title={property}
              >
                {property}
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    )
  }
}
