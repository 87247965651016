import React, { useState } from "react"

export default function ConfettiWrapper(props) {
  const [confetti, setConfetti] = useState(false)

  const handleButtonClick = () => {
    setConfetti(true)
    setTimeout(() => {
      setConfetti(false)
    }, 4000)
  }

  return (
    <span style={{ position: "relative" }}>
      <span onClick={handleButtonClick}>{props.children}</span>
      {confetti ? generateConfetti() : null}
    </span>
  )
}

export function generateConfetti() {
  let confettiPapers = []
  for (let i = 0; i < 75; i++) {
    confettiPapers.push(<div key={`confetti-${i}`} className={`confetti-${i}`}></div>)
  }

  return <span className={"papers-origin"}>{confettiPapers}</span>
}
