import * as React from "react"
import projectMapStyles from "./projectMapStyles.json"
import { GoogleMap } from "@react-google-maps/api"

interface ProjectMapProps {
  markers: JSX.Element
  defaultZoom?: number
  defaultCenter?: { lat: number; lng: number }
}

type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[]
export const libraries = ["geometry", "drawing", "places"] as Libraries

export function ProjectMapRoot(props: ProjectMapProps) {
  const { markers } = props

  return (
    <GoogleMap mapContainerStyle={{ height: "100%" }} options={{ styles: projectMapStyles }} zoom={props.defaultZoom || 3} center={props.defaultCenter || { lat: 35, lng: -80 }}>
      {markers}
    </GoogleMap>
  )
}
