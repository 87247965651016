import React from "react"
import { ButtonToolbar, ButtonGroup, Button } from "reactstrap"
import { Link } from "react-router-dom"

import EcosuiteComponent from "@common/EcosuiteComponent"

import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class EnergyViewControls extends EcosuiteComponent {
  isShowViewSelector() {
    return false
  }

  render() {
    return (
      <div className="content-view-controls search-bar">
        <ButtonToolbar className="float-end">
          <ButtonGroup className="header-button-group">
            {this.props.groups.indexOf("power-user") >= 0 ? (
              <Link to="/power-dashboard" target="_blank">
                <Button size="sm">
                  {t("econode.labels.Power Dashboard")} <Icon icon="open_in_new" />
                </Button>
              </Link>
            ) : null}
            {this.props.additionalViewActionButtons || null}
          </ButtonGroup>
        </ButtonToolbar>
        {this.props.viewControls}
      </div>
    )
  }
}
