import React from "react"
import { create, all } from "mathjs"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import EcosuiteComponent from "@common/EcosuiteComponent"

import EnergyUtils from "@dashboard/energy/EnergyUtils"

const math = create(all)

export default class EconodeConsumptionReadings extends EcosuiteComponent {
  renderContent() {
    if (this.isContentValid(this.props.datums)) {
      const graphDatums = this.props.graphDatums
      let maxConsumptionReading
      let minConsumptionReading
      let averageConsumptionReading

      let maxConsumption
      let minConsumption
      let averageConsumption

      let maxPowerFactor
      let minPowerFactor
      let averagePowerFactor

      if (graphDatums) {
        Object.values(graphDatums).forEach((datum) => {
          // Calculate the Demand max/average/min
          if (!maxConsumptionReading || datum.consumptionMax > maxConsumptionReading) {
            maxConsumptionReading = datum.consumptionMax
          }
          if (!minConsumptionReading || datum.consumptionMin < minConsumptionReading) {
            minConsumptionReading = datum.consumptionMin
          }

          if (datum.consumptionReading) {
            if (!averageConsumptionReading) {
              averageConsumptionReading = datum.consumptionReading
            } else {
              averageConsumptionReading = math.add(averageConsumptionReading, datum.consumptionReading)
            }
          }

          // Calculate the Conumption max/average/min
          if (!maxConsumption || datum.consumption > maxConsumption) {
            maxConsumption = datum.consumption
          }
          if (!minConsumption || datum.consumption < minConsumption) {
            minConsumption = datum.consumption
          }
          if (datum.consumption) {
            if (!averageConsumption) {
              averageConsumption = datum.consumption
            } else {
              averageConsumption = math.add(averageConsumption, datum.consumption)
            }
          }

          // Calculate the Demand max/average/min
          if (!maxPowerFactor || datum.powerFactorMax > maxPowerFactor) {
            maxPowerFactor = datum.powerFactorMax
          }
          if (!minPowerFactor || datum.powerFactorMin < minPowerFactor) {
            minPowerFactor = datum.powerFactorMin
          }

          if (datum.powerFactor) {
            if (!averagePowerFactor) {
              averagePowerFactor = datum.powerFactor
            }
          }
        })

        let datumCount = Object.values(graphDatums).length
        if (averageConsumptionReading && datumCount > 0) {
          averageConsumptionReading = math.divide(averageConsumptionReading, datumCount)
        }

        if (averageConsumption && datumCount > 0) {
          averageConsumption = math.divide(averageConsumption, datumCount)
        }
      }

      const maxApparantPower = math.divide(Math.max(...Object.values(this.props.graphDatums).map((datum) => datum.apparentPowerMax)), 1000)
      const minApparantPower = math.divide(Math.min(...Object.values(this.props.graphDatums).map((datum) => datum.apparentPowerMin)), 1000)
      const averageApparantPower = math.divide(
        math.divide(
          Object.values(this.props.graphDatums)
            .map((datum) => datum.apparentPower)
            .reduce((acc, cur) => acc + Number(cur), 0),
          Object.values(this.props.graphDatums).length,
        ),
        1000,
      )

      return (
        <table className="econode-readings">
          <thead>
            <tr>
              <th></th>
              <th>Highest</th>
              <th>Average</th>
              <th>Lowest</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="label">Demand (kW):</td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("demandReferenceLine", maxConsumptionReading / 1000, "Highest Demand")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("demandReferenceLine")
                }}
              >
                {EnergyUtils.formatNumberAsKilo(maxConsumptionReading)}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("demandReferenceLine", averageConsumptionReading / 1000, "Average Demand")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("demandReferenceLine")
                }}
              >
                {EnergyUtils.formatNumberAsKilo(averageConsumptionReading)}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("demandReferenceLine", minConsumptionReading / 1000, "Lowest Demand")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("demandReferenceLine")
                }}
              >
                {EnergyUtils.formatNumberAsKilo(minConsumptionReading)}
              </td>
            </tr>
            <tr id="tooltip-consumption">
              <UncontrolledTooltip placement="bottom" target="tooltip-consumption">
                {`Calculated using a ${this.props.datumsAggregation} aggregate`}
              </UncontrolledTooltip>
              <td className="label">Consumption (kWh):</td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("consumptionReferenceLine", maxConsumption / 1000, "Highest Observed Consumption")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("consumptionReferenceLine")
                }}
              >
                {EnergyUtils.formatNumberAsKilo(maxConsumption)}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("consumptionReferenceLine", averageConsumption / 1000, "Average Observed Consumption")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("consumptionReferenceLine")
                }}
              >
                {EnergyUtils.formatNumberAsKilo(averageConsumption)}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("consumptionReferenceLine", minConsumption / 1000, "Lowest Observed Consumption")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("consumptionReferenceLine")
                }}
              >
                {EnergyUtils.formatNumberAsKilo(minConsumption)}
              </td>
            </tr>
            <tr>
              <td className="label">Power Factor:</td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("powerFactorReferenceLine", maxPowerFactor, "Highest Power Factor")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("powerFactorReferenceLine")
                }}
              >
                {maxPowerFactor ? EnergyUtils.formatNumber(maxPowerFactor) : "-"}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("powerFactorReferenceLine", averagePowerFactor, "Average Power Factor")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("powerFactorReferenceLine")
                }}
              >
                {averagePowerFactor ? EnergyUtils.formatNumber(averagePowerFactor) : "-"}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("powerFactorReferenceLine", minPowerFactor, "Lowest Power Factor")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("powerFactorReferenceLine")
                }}
              >
                {minPowerFactor ? EnergyUtils.formatNumber(minPowerFactor) : "-"}
              </td>
            </tr>
            <tr>
              <td className="label">Apparent Power (kVA):</td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("apparantPowerReferenceLine", maxApparantPower, "Highest Apparant Power")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("apparantPowerReferenceLine")
                }}
              >
                {maxApparantPower ? EnergyUtils.formatNumber(maxApparantPower) : "-"}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("apparantPowerReferenceLine", averageApparantPower, "Average Apparant Power")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("apparantPowerReferenceLine")
                }}
              >
                {averageApparantPower ? EnergyUtils.formatNumber(averageApparantPower) : "-"}
              </td>
              <td
                onMouseEnter={() => {
                  this.props.showReferenceLine("apparantPowerReferenceLine", minApparantPower, "Lowest Apparant Power")
                }}
                onMouseLeave={() => {
                  this.props.showReferenceLine("apparantPowerReferenceLine")
                }}
              >
                {minApparantPower ? EnergyUtils.formatNumber(minApparantPower) : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      )
    }
  }
}
