import React, { useState } from "react"
import moment from "moment"
import { Button } from "reactstrap"
import Datetime from "react-datetime"

import type { WidgetProps } from "@rjsf/core"
import type { Project, Site } from "@ecogy-types/main"

import Icon from "@common/display/Icon"
import EventUtils from "@dashboard/event/EventUtils"
import i18n from "src/i18n"

const { t } = i18n
type EventDatePickerWidgetProps = {
  eventProject: Project
  eventSite: Site
  disableEventMove?: boolean
  isReadOnly: () => boolean
} & WidgetProps

function EventDatePickerWidget({ eventProject, eventSite, disableEventMove, isReadOnly, ...props }: EventDatePickerWidgetProps): JSX.Element {
  const [date] = useState(props.value ? moment(props.value) : "")
  const [format, setFormat] = useState("ll")

  return (
    <div>
      <Datetime
        initialValue={date}
        dateFormat={format}
        timeFormat="HH:mm:ss"
        onClose={(selectedDate) => {
          if (typeof selectedDate === "object") {               // @ts-ignore
            props.onChange(selectedDate ? selectedDate.toISOString() : "")
          }
          else{
            setFormat("YYYY:MM:DD") 
            props.onChange(selectedDate ? new Date(selectedDate).toISOString() : "")
          } 
        }}
        inputProps={{
          disabled: disableEventMove || isReadOnly(),
          onChange: (e) => {
            // @ts-ignore
            if (e.target.value === "") {
              props.onChange(undefined) // Set to undefined if input is cleared.
            }
          }
        }}
        closeOnSelect={true}
        renderInput={(inputProps) => {
          return (
            <div className="rdt-input-container">
              <input {...inputProps} />
              {props.id !== "root_startDate" && (
                <Button
                  className="rdt-input-clear-button"
                  onClick={(e) => {
                    e.preventDefault()
                    props.onChange(undefined)
                    inputProps.onChange(undefined)
                  }}
                >
                  <Icon icon="close" />
                </Button>
              )}
            </div>
          )
        }}
      />
      {date && eventProject ? (
        <div>
          {t("event.labels.local_time")}: {moment(date).tz(EventUtils.getTimeZoneForProject(eventProject, eventSite)).format("lll")}
        </div>
      ) : null}
    </div>
  )
}

export default EventDatePickerWidget
