import { Button } from "@aws-amplify/ui-react"
import { inRange } from "lodash"
import React from "react"
import { FileWithPath, useDropzone } from "react-dropzone"
import { toast } from "react-toastify"

interface Props {
  currentLogoUrl?: string
  onBlur: (f: FileWithPath) => void
}

export default function LogoUpload(props: Props) {
  const [acceptedFile, setAcceptedFile] = React.useState<FileWithPath | null>(null)

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop(acceptedFiles, fileRejections, event) {
      acceptedFiles.forEach((file) => {
        const image = new Image()
        image.addEventListener("load", () => {
          const acceptableAspectRatio = {
            min: 3,
            max: 3.2,
          }
          const fileAspectRatio = image.width / image.height
          if (!inRange(fileAspectRatio, acceptableAspectRatio.min, acceptableAspectRatio.max)) {
            toast.error("Incorret Aspect Ratio! Aspect ratio must be between 3 & 3.2")
            return {
              code: "incorrect-aspect-ratio",
              message: "Incorret Aspect Ratio! Aspect ratio must be between 3 & 3.2",
            }
          } else {
            setAcceptedFile(file)
          }
        })
        image.src = URL.createObjectURL(file)
      })
    },
  })

  const { currentLogoUrl, onBlur } = props

  const [showUploadBox, setShowUploadBox] = React.useState(true)

  React.useEffect(() => {
    if (currentLogoUrl) {
      setShowUploadBox(false)
    }
  }, [currentLogoUrl])

  const acceptedFileItems = acceptedFile ? (
    <li>
      Current Selection: {acceptedFile.path} - {acceptedFile.size} bytes
    </li>
  ) : null

  return (
    <div
      style={{ marginBottom: "8px" }}
      onBlur={() => {
        onBlur(acceptedFile!)
      }}
    >
      {showUploadBox ? (
        <section
          style={{
            border: "1px dashed white",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "48px",
            paddingTop: "16px",
          }}
        >
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag and drop the logo here, or click to select file</p>
          </div>
          <aside>
            <ul>{acceptedFileItems}</ul>
          </aside>
        </section>
      ) : (
        <div>
          <img src={currentLogoUrl} alt="org-logo" height="200px" />
          <Button
            variation="menu"
            style={{ backgroundColor: "white", color: "black", border: "1px solid white", marginLeft: "16px" }}
            size="small"
            onClick={() => {
              setShowUploadBox(true)
            }}
          >
            Change
          </Button>
        </div>
      )}
    </div>
  )
}
