import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"

export interface RecordItem {
  assetType: string
  created: string
  id: string
  isContract: string
  name: string
  path: string
  recordSubType: string
  recordType: string
  updated: string
  verified: boolean
}

interface ListRecordsResponse {
  records: RecordItem[]
}

const RecordService = {
  listRecords: async (): Promise<RecordItem[]> => {
    const uri = `/records`
    try {
      const response = await API.get(uri)
      return (response as ListRecordsResponse).records
    } catch (err) {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    }
  },

  getRecordDocuments: async (recordId: string): Promise<unknown> => {
    const uri = `/records/${recordId}/record-documents`
    try {
      const response = await API.get(uri)
      return response
    } catch (err) {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    }
  },
}

export default RecordService
