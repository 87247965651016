import * as React from "react"
import Header, { ThemeProvider } from "../Components/Header"
import { Redirect, Route } from "react-router-dom"
import Logo from "src/img/ecosuite_logo.png"
import Sidebar from "./Sidebar"
import ListMyDocuments from "./ListMyDocuments"
import DocumentUploadDashboard from "./DocumentUpload"
import { H1 } from "src/components/ui/typography"
import { useGetUserOnboardingData, useJoinOrganization } from "src/services/onboarding"
import LoadingButton from "src/components/ui/loading-button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "src/components/ui/dialog"
import { Button } from "src/components/ui/button"
import { toast } from "react-toastify"

const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="public-view tw-flex tw-flex-col tw-min-h-screen tw-bg-background">
      <Header logo={Logo} />
      <div className="tw-flex ">
        <div className="lg:tw-w-[320px]">
          <Sidebar />
        </div>
        <main className="tw-flex-grow tw-p-6">{children}</main>
      </div>
    </div>
  )
}

const Profile = () => {
  return (
    <div>
      <H1>Profile content goes here</H1>
    </div>
  )
}

const Onboarding = () => {
  const { data: metaData } = useGetUserOnboardingData()
  const { mutateAsync: joinOrganization, isLoading } = useJoinOrganization()
  const futureUserType = metaData?.futureUserType

  const [showModal, setShowModal] = React.useState(false)
  const [countdown, setCountdown] = React.useState<number | null>(null)

  React.useEffect(() => {
    if (futureUserType) {
      setShowModal(true)
    }
  }, [futureUserType])

  const handleJoin = async () => {
    try {
      await joinOrganization({ userType: futureUserType! })
      setCountdown(5) // Start the countdown
    } catch (error) {
      toast.error("Something went wrong!")
    }
  }

  React.useEffect(() => {
    if (countdown === null) return

    if (countdown === 0) {
      localStorage.clear()
      window.location.href = "/login"
    }

    const timer = setTimeout(() => {
      setCountdown((prev) => (prev! > 0 ? prev! - 1 : prev))
    }, 1000)

    return () => clearTimeout(timer)
  }, [countdown])

  return (
    <ThemeProvider>
      <Layout>
        <div>
          <Dialog open={showModal} onOpenChange={setShowModal}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Invitation to Join Organization</DialogTitle>
                <DialogDescription>
                  You've been invited to join the organization as a full user.
                </DialogDescription>
              </DialogHeader>

              {countdown !== null ? (
                <div className="tw-text-center tw-mt-4">
                  <p>
                    You will be redirected to the login page in{" "}
                    <strong>{countdown}</strong> second{countdown !== 1 && "s"}...
                  </p>
                </div>
              ) : (
                <DialogFooter className="tw-mt-4">
                  <LoadingButton loading={isLoading} onClick={handleJoin}>
                    Join
                  </LoadingButton>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cancel
                  </Button>
                </DialogFooter>
              )}
            </DialogContent>
          </Dialog>

          <Route exact path="/onboarding" render={() => <Redirect to="/onboarding/upload" />} />
          <Route path="/onboarding/upload" component={DocumentUploadDashboard} />
          <Route path="/onboarding/view" component={ListMyDocuments} />
          <Route path="/onboarding/profile" component={Profile} />
        </div>
      </Layout>
    </ThemeProvider>
  )
}

export default Onboarding
