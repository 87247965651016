import React from "react"

/**
 * The QGIS tab.
 * @constructor
 */
export const QGIS = (): JSX.Element => {
  return (
    <>
      <iframe
        // TODO: Refactor height calculation.
        allowFullScreen={true}
        style={{ height: "calc(100vh - 60px)", width: "100%" }}
        // Don't load any layers by default.
        src="https://qgis.ecosuite.io/?l="
      />
    </>
  )
}

export default QGIS
