import React, { FC } from "react"

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import i18n from "src/i18n"

const { t } = i18n
interface ChangeNoteModalProps {
  isOpen: boolean
  toggle: () => void
  submit: () => void
  canSubmit: boolean
}

const ChangeNoteModal: FC<ChangeNoteModalProps> = ({ isOpen, toggle, submit, canSubmit, children }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t("labels.change_note")}</ModalHeader>
      <ModalBody>
        {children}
        <br />
      </ModalBody>
      {canSubmit && (
        <ModalFooter>
          <Button color="primary" onClick={submit} disabled={!canSubmit}>
            {t("labels.save_note")}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ChangeNoteModal
