import React from "react"

import EcosuiteView from "@common/module/EcosuiteView"
import { ProjectMarker, ProjectRadiusMarker } from "@dashboard/ProjectMap"

import EnergyViewControls from "./EconodeEnergyViewControls"
import i18n from "src/i18n"

const { t } = i18n
export default class EnergyView extends EcosuiteView {
  renderViewControls() {
    return (
      <EnergyViewControls
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        rangeName={this.props.rangeName}
        customRange={this.props.customRange}
        project={this.props.project}
        projects={this.props.projects}
        viewControls={this.getEnergyViewControls()}
        additionalViewActionButtons={this.additionalViewActionButtons()}
      />
    )
  }

  getEnergyViewControls() {
    return null
  }

  additionalViewActionButtons() {
    return null
  }
}

export class EnergyProjectMarker extends ProjectMarker {
  renderProjectInfo() {
    return (
      <React.Fragment>
        <div>{(this.props.type ? this.props.type + ": " : `${t("labels.project")}: `) + this.props.project.name}</div>
        {this.props.showGeneration && this.props.projectReading ? <div>{`${t("labels.generation")}: ${Math.round(this.props.projectReading.generation / 1000)} kWh`}</div> : null}
        {this.props.showConsumption && this.props.projectReading ? <div>{`${t("labels.consumption")}: ${Math.round(this.props.projectReading.consumption / 1000)} kWh`}</div> : null}
      </React.Fragment>
    )
  }

  getColor() {
    if (this.props.projectStatus) {
      switch (this.props.projectStatus.status) {
        case "ok":
          return "#4CAF50"
        case "warning":
          return "orange"
        case "error":
          return "#e22006"
        default:
          return "#0EB6DB"
      }
    }
    return "#0EB6DB"
  }
}

export class EnergyRadiusMarker extends ProjectRadiusMarker {
  calculateRadius() {
    if (this.props.showGeneration) {
      let projectGeneration = this.props.projectReading ? this.props.projectReading.generation : 0
      let totalGeneration = this.props.readings ? this.props.readings.generation : 0
      return (projectGeneration / totalGeneration) * 100 + 12
    } else {
      return 0
    }
  }
}
