import React from "react"

import {
  LinkHorizontalThin,
  LinkHorizontalSolid,
  LinkHorizontalSlash,
  FileLigth,
  FileSolid,
  FileExcelRegular,
  ScrewdriverWrenchLight,
  ScrewdriverWrenchRegular,
  ScrewdriverWrenchSolid,
  ArrowUpRightDots,
  ArrowUpRightLeftBottom,
  ArrowUpSquareTriangle,
  Missing,
  NoQualData
} from "../Icons"

export interface IconProps {
  meaning: string
  tooltip?: string
  iconName: string
  icon: any
  type: string
}

export enum IconMeaning {
  "Easy interconnection" = "LinkHorizontalThin",
  "Hard interconnection" = "LinkHorizontalSolid",
  "Unallowed interconnection" = "LinkHorizontalSlash",
  "Easy permitting" = "FileLigth",
  "Hard permitting" = "FileSolid",
  "Unallowed permitting" = "FileExcelRegular",
  "Easy project" = "ScrewdriverWrenchLight",
  "Moderate / normal project" = "ScrewdriverWrenchRegular",
  "Complex project" = "ScrewdriverWrenchSolid",
  "Project boosts portfolio" = "ArrowUpRightDots",
  "New market entry" = "ArrowUpRightLeftBottom",
  "Upsell potential unlocked" = "ArrowUpSquareTriangle",
}

export const definedIcons: IconProps[] = [
  { icon: <LinkHorizontalThin />, type: "link", iconName: "LinkHorizontalThin", meaning: "Easy interconnection" },
  { icon: <LinkHorizontalSolid />, type: "link", iconName: "LinkHorizontalSolid", meaning: "Hard interconnection" },
  { icon: <LinkHorizontalSlash />, type: "link", iconName: "LinkHorizontalSlash", meaning: "Unallowed interconnection" },
  { icon: <FileLigth />, type: "file", iconName: "FileLigth", meaning: "Easy permitting" },
  { icon: <FileSolid />, type: "file", iconName: "FileSolid", meaning: "Hard permitting" },
  { icon: <FileExcelRegular />, type: "file", iconName: "FileExcelRegular", meaning: "Unallowed permitting" },
  { icon: <ScrewdriverWrenchLight />, type: "screw", iconName: "ScrewdriverWrenchLight", meaning: "Easy project" },
  { icon: <ScrewdriverWrenchRegular />, type: "screw", iconName: "ScrewdriverWrenchRegular", meaning: "Moderate / normal project" },
  { icon: <ScrewdriverWrenchSolid />, type: "screw", iconName: "ScrewdriverWrenchSolid", meaning: "Complex project" },
  {
    icon: <ArrowUpRightDots />,
    type: "arrowup",
    iconName: "ArrowUpRightDots",
    meaning: "Project boosts portfolio",
  },
  {
    icon: <ArrowUpRightLeftBottom />,
    type: "arrowup",
    iconName: "ArrowUpRightLeftBottom",
    meaning: "New market entry",
  },
  {
    icon: <ArrowUpSquareTriangle />,
    type: "arrowup",
    iconName: "ArrowUpSquareTriangle",
    meaning: "Upsell potential unlocked",
  },
]

export const missingIcon: IconProps = { icon: <Missing />, type: "missing", iconName: "Missing", meaning: "Missing" }
export const defaultIcon: IconProps = { icon: <NoQualData />, type: "presentdata", iconName: "NoQualData", meaning: "There is no qualitive data"}
