import { ChecklistTab } from "@dashboard/process/views/checklists/ChecklistView"
import { ChecklistField } from "@dashboard/process/views/checklists/ChecklistField"

/**
 * Generate the UI schema.
 * @param schema - The schema to generate the UI schema for.
 * @param expectedTab - The tab that is expected to be rendered.
 */
export const generateUiSchema = (schema: Schema, expectedTab: ChecklistTab): Record<string, unknown> => {
  let base

  switch (expectedTab) {
    case ChecklistTab.NTP:
      base = generateNtpUiSchema(schema)
      break
    case ChecklistTab.PTO:
      base = generatePtoUiSchema(schema)
      break
    case ChecklistTab.ICR:
      base = generateIcrUiSchema(schema)
  }
  const fields = Object.entries(schema.properties).reduce((acc, [key]) => {
    return {
      ...acc,
      [key]: {
        // Each child should be a checklist field.
        "ui:field": ChecklistField,
      },
    }
  }, {})

  const ret: any = {
    ...base,
    ...fields,
  }

  Object.keys(ret).forEach((k) => {
    ret[k]["ui:order"] = schema.properties[k] && schema.properties[k]["ui:order"]
  })

  return ret
}

/**
 * Generate the UI schema for the NTP tab.
 * @param schema - The schema to generate the UI schema for.
 */
const generateNtpUiSchema = (schema: Schema): Record<string, unknown> => {
  const sortedSchemaKeys = Object.keys(schema.properties).sort()

  return {
    "ui:order": [sortedSchemaKeys, "*"].flat(),
  }
}

/**
 * Generate the UI schema for the PTO tab.
 * @param schema - The schema to generate the UI schema for.
 */
const generatePtoUiSchema = (schema: Schema): Record<string, unknown> => {
  const sortedSchemaKeys = Object.keys(schema.properties).sort()

  return {
    "ui:order": [sortedSchemaKeys, "*"].flat(),
  }
}

/**
 * Generate the UI schema for the ICR tab.
 * @param schema - The schema to generate the UI schema for.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateIcrUiSchema = (schema: Schema): Record<string, unknown> => {
  return {
    "ui:order": ["*"],
  }
}
