import React from "react"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"

export default class ConsumptionSavings extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentError(this.props.predictedConsumption)) {
      return <Error error={this.props.predictedConsumption.getError()} />
    }

    if (this.isContentValid(this.props.readings) && this.isContentValid(this.props.predictedConsumption)) {
      const currentConsumption = this.props.readings ? this.props.readings.consumption : 0
      const historicConsumption = this.props.predictedConsumption ? this.props.predictedConsumption.projectDatums.historicForecast : 0

      const diff = currentConsumption - historicConsumption
      const positive = diff >= 0
      const percent = -(100 - (currentConsumption / historicConsumption) * 100)
      return (
        <React.Fragment>
          <div className={`sidebar-heading ${positive ? "negative" : "positive"}`}>{`${positive ? "+" : ""}${EnergyUtils.formatWattHoursAsKiloWattHours(diff)}`} </div>
          <div className={`sidebar-heading ${positive ? "negative" : "positive"}`}>{`${positive ? "+" : ""}${percent ? Math.round(percent === Infinity ? 0 : percent) : 0} %`} </div>
        </React.Fragment>
      )
    } else {
      return <Loading />
    }
  }
}
