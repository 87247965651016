import React from "react"

import EcosuiteComponent from "@common/EcosuiteComponent"

class AssetStatus extends EcosuiteComponent {
  getStatusMessage() {
    if (this.props.status && this.props.status.causes && this.props.status.causes.length > 0) {
      return (
        <React.Fragment>
          {this.props.status.causes.map((cause) => {
            return (
              <div className={cause.status} key={cause.message}>
                {cause.message}
              </div>
            )
          })}
        </React.Fragment>
      )
    }
  }

  renderContent() {
    return <div className={this.props.className}>{this.getStatusMessage()}</div>
  }
}

export default AssetStatus
