import React, { forwardRef } from "react"
import { cn } from "src/lib"

const H1 = forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>((props, ref) => {
  return (
    <h1
      {...props}
      ref={ref}
      className={cn(
        "twx-font tw-scroll-m-20 tw-text-3xl sm:tw-text-4xl md:tw-text-5xl lg:tw-text-6xl tw-font-extrabold tw-tracking-tight tw-text-foreground",
        props.className,
      )}
    >
      {props.children}
    </h1>
  )
})
H1.displayName = "H1"
export { H1 }

const H2 = forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>((props, ref) => {
  return (
    <h2
      {...props}
      ref={ref}
      className={cn(
        "twx-font tw-scroll-m-20 tw-border-b tw-pb-2 tw-text-2xl sm:tw-text-3xl md:tw-text-4xl tw-font-semibold tw-tracking-tight tw-first:mt-0 tw-text-foreground",
        props.className,
      )}
    >
      {props.children}
    </h2>
  )
})
H2.displayName = "H2"
export { H2 }

const H3 = forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>((props, ref) => {
  return (
    <h3
      {...props}
      ref={ref}
      className={cn(
        "twx-font tw-scroll-m-20 tw-text-xl sm:tw-text-2xl md:tw-text-3xl tw-font-semibold tw-tracking-tight tw-text-foreground",
        props.className,
      )}
    >
      {props.children}
    </h3>
  )
})
H3.displayName = "H3"
export { H3 }

const H4 = forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>((props, ref) => {
  return (
    <h4
      {...props}
      ref={ref}
      className={cn(
        "twx-font tw-scroll-m-20 tw-text-lg sm:tw-text-xl md:tw-text-2xl tw-font-semibold tw-tracking-tight tw-text-foreground",
        props.className,
      )}
    >
      {props.children}
    </h4>
  )
})
H4.displayName = "H4"
export { H4 }

const Lead = forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>((props, ref) => {
  return (
    <p
      {...props}
      ref={ref}
      className={cn("twx-font tw-text-lg sm:tw-text-xl md:tw-text-2xl tw-text-muted-foreground", props.className)}
    >
      {props.children}
    </p>
  )
})
Lead.displayName = "Lead"
export { Lead }

const P = forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>((props, ref) => {
  return (
    <p
      {...props}
      ref={ref}
      className={cn(
        "twx-font tw-leading-7 tw-[&:not(:first-child)]:mt-6 tw-text-sm sm:tw-text-base md:tw-text-lg tw-text-foreground",
        props.className,
      )}
    >
      {props.children}
    </p>
  )
})
P.displayName = "P"
export { P }

const Large = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={cn(
        "twx-font tw-text-base sm:tw-text-lg md:tw-text-xl tw-font-semibold tw-text-foreground",
        props.className,
      )}
    >
      {props.children}
    </div>
  )
})
Large.displayName = "Large"
export { Large }

const Small = forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>((props, ref) => {
  return (
    <p
      {...props}
      ref={ref}
      className={cn(
        "twx-font tw-text-xs sm:tw-text-sm tw-font-medium tw-leading-none tw-text-foreground",
        props.className,
      )}
    >
      {props.children}
    </p>
  )
})
Small.displayName = "Small"
export { Small }

const Muted = forwardRef<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement>>((props, ref) => {
  return (
    <span
      {...props}
      ref={ref}
      className={cn("twx-font tw-text-xs sm:tw-text-sm tw-text-muted-foreground", props.className)}
    >
      {props.children}
    </span>
  )
})
Muted.displayName = "Muted"
export { Muted }

// InlineCode, List, and Quote components remain unchanged as they don't require responsive text sizes
