import React from "react"
import moment from "moment"
import EcosuiteView, { LAYOUT_CONTENT_RIGHT } from "@common/module/EcosuiteView"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import ProjectNotes from "./project/ProjectNotes"
import RecordNotes from "./records/RecordNotes"
import i18n from "src/i18n"

const { t } = i18n
/* displays incomplete, overdue and early tasks for the selected project */
export default class ProcessDashboardProjectView extends EcosuiteView {
  getLayout() {
    if (this.renderNotes()) {
      return LAYOUT_CONTENT_RIGHT
    }
    return null
  }

  renderMainView() {
    return (
      <div className="process-dashboard">
        {this.renderInfo()}
        {this.renderProjectStatus()}
      </div>
    )
  }

  renderSiderBar() {
    return this.renderNotes()
  }

  renderInfo() {
    return (
      <div className="process-dashboard-info">
        <div className="process-dashboard-title">{this.props.project.name}</div>
        <div className="process-dashboard-size">
          <div>{EnergyUtils.formatWatts(this.props.project.dcSize) + " DC"}</div>
          <div>{EnergyUtils.formatWatts(this.props.project.acSize) + " AC"}</div>
        </div>
        <div className="process-dashboard-address">
          <div>{this.props.project.address}</div>
          <div>{this.props.project.state}</div>
        </div>
      </div>
    )
  }

  renderProjectStatus() {
    const statuses = ["New Project", "Interconnection", "Permitting", "Construction", "PTO", "Operational"]
    const activeStatus = {
      "New Project": "active",
      Interconnection: ["Interconnection", "Permitting", "Construction", "PTO", "Operational"].includes(this.props.project.status) ? "active" : "",
      Permitting: ["Permitting", "Construction", "PTO", "Operational"].includes(this.props.project.status) ? "active" : "",
      Construction: ["Construction", "PTO", "Operational"].includes(this.props.project.status) ? "active" : "",
      PTO: ["PTO", "Operational"].includes(this.props.project.status) ? "active" : "",
      Operational: "Operational" === this.props.project.status ? "active" : "",
    }
    return (
      <div className="process-dashboard-status">
        <table>
          <tbody>
            <tr>
              {statuses.map((status) => (
                <td key={status} className={`pointer ${activeStatus[status]}`}>
                  {t(`process.statuses.${status}`)}
                </td>
              ))}
            </tr>
            <tr>
              {statuses.map((status) => (
                <td key={`days-${status}`}>{this.getStatusDays(status)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  getStatusDays(status) {
    if (this.props.project.milestones) {
      switch (status) {
        case "New Project":
          if (this.props.project.milestones.interconnection) {
            return `${moment(this.props.project.milestones.interconnection).diff(moment(this.props.project.milestones.initiated), "days")} Days`
          } else if (this.props.project.milestones.initiated) {
            return `${moment().diff(moment(this.props.project.milestones.initiated), "days")} Days`
          } else {
            return null
          }
        case "Interconnection":
          if (this.props.project.milestones.permitting) {
            return `${moment(this.props.project.milestones.permitting).diff(moment(this.props.project.milestones.interconnection), "days")} Days`
          } else if (this.props.project.milestones.interconnection) {
            return `${moment().diff(moment(this.props.project.milestones.interconnection), "days")} Days`
          } else {
            return null
          }
        case "Permitting":
          if (this.props.project.milestones.construction) {
            return `${moment(this.props.project.milestones.construction).diff(moment(this.props.project.milestones.permitting), "days")} Days`
          } else if (this.props.project.milestones.permitting) {
            return `${moment().diff(moment(this.props.project.milestones.permitting), "days")} Days`
          } else {
            return null
          }
        case "Construction":
          if (this.props.project.milestones.pto) {
            return `${moment(this.props.project.milestones.pto).diff(moment(this.props.project.milestones.construction), "days")} Days`
          } else if (this.props.project.milestones.construction) {
            return `${moment().diff(moment(this.props.project.milestones.construction), "days")} Days`
          } else {
            return null
          }
        case "PTO":
          if (this.props.project.milestones.operational) {
            return `${moment(this.props.project.milestones.operational).diff(moment(this.props.project.milestones.pto), "days")} Days`
          } else if (this.props.project.milestones.pto) {
            return `${moment().diff(moment(this.props.project.milestones.pto), "days")} Days`
          } else {
            return null
          }
        case "Operational":
          if (this.props.project.milestones.operational) {
            return `${moment().diff(moment(this.props.project.milestones.operational), "days")} Days`
          } else {
            return null
          }
      }
    }
    return null
  }

  getGroupTitle(groupName) {
    switch (groupName) {
      case "bizdev":
        return `${t("process.statuses.Business Development")}`
      case "construction":
        return `${t("process.statuses.Construction")}`
      case "engineering":
        return `${t("process.statuses.Engineering")}`
      case "management":
        return `${t("process.statuses.Project Management")}`
      case "sitevisit":
        return `${t("process.statuses.Site Visit")}`
      default:
        return groupName
    }
  }

  renderNotes() {
    if (!this.props.groups.includes("notes")) return null
    return (
      <div className="process-dashboard-notes">
        <ProjectNotes {...this.props} />
        <RecordNotes {...this.props} />
      </div>
    )
  }
}
