import React, { Component } from "react"
import moment from "moment"
import { create, all } from "mathjs"

import { Loading } from "@common/EcosuiteComponent"
import DeviceUtils from "@common/utils/DeviceUtils"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import EnergySourceView from "@dashboard/energy/views/EnergySourceView"
import SourceStatusTooltips from "@dashboard/energy/views/source/SourceStatusTooltips"
import i18n from "src/i18n"

const { t } = i18n
const math = create(all)

export default class EnergyProjectSourceView extends EnergySourceView {
  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th id="col-source-id">{t("headings.sources")}</th>
          <th id="col-source-node">{t("table_headings.node")}</th>
          <th id="col-source-latest-reading">{t("table_headings.latest_reading_date")}</th>
          <th id="col-source-last-hour">{`${t("table_headings.last_hour_reading")} (kWh)`}</th>
          <th id="col-source-last-hour-expected">{`${t("table_headings.last_hour_expected")} (kWh)`}</th>
          <th id="col-source-last-hour-percentage">%</th>
          <th id="col-source-last-24">{`${t("table_headings.last_24_reading")} (kWh)`}</th>
          <th id="col-source-last-24-expected">{`${t("table_headings.last_24_expected")} (kWh)`}</th>
          <th id="col-source-last-24-percentage">%</th>
          {this.props.showGeneration ? <th id="col-source-dc-size">{`${t("table_headings.dc_size")} (kW)`}</th> : null}
          {this.props.showGeneration ? <th id="col-source-ac-size">{`${t("table_headings.ac_size")} (kW)`}</th> : null}
          {this.props.showConsumption ? <th id="col-source-expected-peak">{`${t("table_headings.expected_peak")} (kW)`}</th> : null}
          <th id="col-source-message">{t("table_headings.message")}</th>
        </tr>
      </thead>
    )
  }

  renderMainView() {
    if (this.props.projectStatus) {
      return (
        <React.Fragment>
          <table>
            {this.renderTableHeader()}
            <tbody>
              {EnergyUtils.getSites(this.props.projectStatus).map((site) => {
                return EnergyUtils.getSystems(site).map((system) => {
                  if (system.nodesStatus) {
                    return system.nodesStatus.map((nodeStatus) => {
                      if (this.isShowSource(nodeStatus)) {
                        return (
                          <Source
                            key={nodeStatus.nodeId + "-" + nodeStatus.sourceId}
                            nodeStatus={nodeStatus}
                            project={this.props.project}
                            system={system}
                            groups={this.props.groups}
                            showGeneration={this.props.showGeneration}
                            showConsumption={this.props.showConsumption}
                            showStorage={this.props.showStorage}
                          />
                        )
                      } else {
                        return null
                      }
                    })
                  } else {
                    return null
                  }
                })
              })}
            </tbody>
          </table>

          <SourceStatusTooltips />
        </React.Fragment>
      )
    } else {
      return <Loading />
    }
  }
}
export class Source extends Component {
  formatSourceReading(nodeStatus, reading) {
    if (EnergyUtils.isIrradianceDatum(nodeStatus)) {
      return reading || reading === 0 ? EnergyUtils.formatNumberAsKilo(reading) + "/m2" : undefined
    } else if (reading || reading === 0) {
      return EnergyUtils.formatNumberAsKilo(reading)
    }
  }

  render() {
    let nodeStatus = this.props.nodeStatus
    let size = DeviceUtils.getDeviceDCSize(this.props.system, nodeStatus.sourceId)
    let acSize = DeviceUtils.getDeviceACSize(this.props.system, nodeStatus.sourceId)
    let peakPower = DeviceUtils.getDevicePeakPower(this.props.system, nodeStatus.sourceId)

    return (
      <tr className={nodeStatus.status}>
        <td>{nodeStatus.sourceId}</td>
        <td>{nodeStatus.nodeId}</td>
        <td>{moment(nodeStatus.latestDatumDate).format("lll")}</td>
        <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.hourReading)}</td>
        <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.hourExpected)}</td>
        <td className="numerical">{nodeStatus.hourExpected && nodeStatus.hourReading ? math.round((nodeStatus.hourReading / nodeStatus.hourExpected) * 100) + "%" : null}</td>
        <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.reading)}</td>
        <td className={"numerical"}>{this.formatSourceReading(nodeStatus, nodeStatus.expected)}</td>
        <td className="numerical">{nodeStatus.expected && nodeStatus.reading ? math.round((nodeStatus.reading / nodeStatus.expected) * 100) + "%" : null}</td>
        {this.props.showGeneration ? <td className={"numerical"}>{size ? EnergyUtils.formatNumber(size) : ""}</td> : null}
        {this.props.showGeneration ? <td className={"numerical"}>{acSize ? EnergyUtils.formatNumber(acSize) : ""}</td> : null}
        {this.props.showConsumption ? <td className={"numerical"}>{peakPower ? EnergyUtils.formatNumber(peakPower) : ""}</td> : null}
        <td>
          {nodeStatus.causes
            ? nodeStatus.causes.map((cause) => {
                return <div key={cause.message}>{cause.message}</div>
              })
            : null}
        </td>
      </tr>
    )
  }
}
