import React from "react"
import moment from "moment"
import { ButtonGroup, ButtonToolbar, Button, Form, FormGroup, Label, Input } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

import { Loading, EcosuiteComponentError, Error } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"
import EcosuiteView, { LAYOUT_SPLIT } from "@common/module/EcosuiteView"
import ReportsService from "../FinanceReportService"
import ProFormaService from "@dashboard/data/pro-forma/ProFormaService"
import ReportTotalUtils from "./report/irr/ReportTotalUtils"
import ReportUtils from "./report/irr/ReportUtils"
import ReportDownload from "./report/ReportDownload"
import FinanceService from "../FinanceService"
import ProFormaInputsDetails from "./pro-forma-inputs/ProFormaInputsDetails"
import ProFormaInputsCashFlows from "./pro-forma-inputs/ProFormaInputsCashFlows"
import i18n from "src/i18n"

const { t } = i18n
export default class ProFormaInputsProjectView extends EcosuiteView {
  constructor(props) {
    super(props, "project-proFormaInputs")

    this.selectProFormaVersion = this.selectProFormaVersion.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.load()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project || this.props.proFormaVersion !== prevProps.proFormaVersion) {
      this.load()
    }
  }

  load() {
    this.loadProForma()
    this.loadProFormaVersions()
    this.loadProFormaIRR()
    this.loadIRRReport()
    this.loadCategories()
  }

  async loadProForma() {
    this.setStateIfMounted({ proForma: null })
    const projectId = this.props.project.code
    return ProFormaService.getProjectProForma(projectId)
      .then((proForma) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ proForma: proForma })
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ proForma: new EcosuiteComponentError(err) })
        }
      })
  }

  loadProFormaVersions() {
    this.setStateIfMounted({ proFormaVersions: null })
    if (this.props.project) {
      const projectId = this.props.project.code
      ReportsService.getProFormaVersions(projectId).then((versions) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ proFormaVersions: versions })
        } else {
          Logger.debug(`Ignoring out of date response for project: ${projectId}`)
        }
      })
    }
  }

  loadProFormaIRR() {
    this.setStateIfMounted({ irr: null })

    if (this.props.project) {
      const projectId = this.props.project.code
      const proFormaVersionId = this.props.proFormaVersion ? this.props.proFormaVersion : undefined
      ProFormaService.getProjectProFormaIRR(projectId, proFormaVersionId)
        .then((irr) => {
          const currentProFormaVersionId = this.props.proFormaVersion ? this.props.proFormaVersion : undefined
          if (this.isProjectCurrent(projectId) && proFormaVersionId === currentProFormaVersionId) {
            this.setStateIfMounted({ irr: irr })
          } else {
            Logger.warning(`Ingoring out of date irr response for project: ${projectId}/${this.props.project.code}; pro forma version: ${proFormaVersionId}/${currentProFormaVersionId}`)
          }
        })
        .catch((err) => {
          Logger.error(err)
          if (this.isProjectCurrent(projectId)) {
            this.setStateIfMounted({ irr: new EcosuiteComponentError(err) })
          }
        })
    }
  }

  loadIRRReport() {
    this.setStateIfMounted({ aggregatedReport: undefined, reportTotals: undefined })

    const projectId = this.props.project.code
    const proFormaVersionId = this.props.proFormaVersion ? this.props.proFormaVersion : undefined
    ReportsService.getFinanceReport(projectId, proFormaVersionId)
      .then((response) => {
        const currentProFormaVersionId = this.props.proFormaVersion ? this.props.proFormaVersion : undefined
        if (this.isProjectCurrent(projectId) && proFormaVersionId === currentProFormaVersionId) {
          const report = response.report

          if (report) {
            // If a report exists, add the report details.
            const aggregatedReport = report ? ReportUtils.aggregateReport(report, "total") : null
            const reportTotals = report ? ReportTotalUtils.calculateReportTotals(report) : null

            this.setStateIfMounted({ report: report, aggregatedReport: aggregatedReport, reportTotals: reportTotals })
          } else {
            this.setStateIfMounted({ aggregatedReport: new EcosuiteComponentError("No finance report configured for this project and pro forma version.") })
          }
        } else {
          Logger.warning(`Ignoring out of date report response for project: ${projectId}/${this.props.project.code}; pro forma version: ${proFormaVersionId}/${currentProFormaVersionId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ aggregatedReport: new EcosuiteComponentError(err) })
        }
      })
  }

  loadCategories() {
    this.setStateIfMounted({ categories: null })
    if (this.props.project) {
      const projectId = this.props.project.code
      FinanceService.getIrrCategories(projectId).then((response) => {
        if (this.isProjectCurrent(projectId)) {
          const categories = {}
          Object.values(response.categories).forEach((category) => {
            categories[category.name] = {
              name: category.name,
              categories: Object.values(category.categories).map((account) => {
                return {
                  name: account.name,
                  categories: account.categories
                    ? Object.values(account.categories).map((subAccount) => {
                        return {
                          name: subAccount.name,
                        }
                      })
                    : [],
                }
              }),
            }
          })

          delete categories["Equity"]
          delete categories["Liability"]

          this.setStateIfMounted({ categories: categories })
        } else {
          Logger.debug(`Ignoring out of date response for project: ${projectId}`)
        }
      })
    }
  }

  selectProFormaVersion(version) {
    Logger.debug("Selecting Pro Forma version: " + version)
    this.props.actions.setProFormaVersion(version, () => {
      this.loadProFormaIRR()
      this.loadIRRReport()
    })
  }

  getLayout() {
    return LAYOUT_SPLIT
  }

  getLeftSplitWidth() {
    return 6
  }

  getProForma() {
    if (this.props.proFormaVersion && this.props.proFormaVersion.includes("/") && this.state.proFormaVersions) {
      return this.state.proFormaVersions.find((proFormaVersion) => proFormaVersion.id === this.props.proFormaVersion) ?? this.state.proForma
    } else {
      return this.state.proForma
    }
  }

  getSystemTypes() {
    const types = new Set()
    Object.values(this.props.project.sites).forEach((site) => {
      Object.values(site.systems).forEach((system) => {
        if (system.subType) {
          types.add(system.subType)
        }
      })
    })
    return Array.from(types).sort()
  }

  renderLeftView() {
    if (this.isContentError(this.state.proForma)) {
      return <Error error={this.state.proForma.getError()} />
    } else if (this.isContentError(this.state.irr)) {
      return <Error error={this.state.irr.getError()} />
    } else if (this.isContentError(this.state.aggregatedReport)) {
      return <Error error={this.state.aggregatedReport.getError()} />
    } else if (this.isContentValid(this.state.aggregatedReport) && this.isContentValid(this.state.proForma) && this.isContentValid(this.state.irr)) {
      const proForma = this.getProForma()
      const systemTypes = this.getSystemTypes()

      return <ProFormaInputsDetails project={this.props.project} proForma={proForma} proFormaIRR={this.state.irr} systemTypes={systemTypes} />
    } else {
      return <Loading />
    }
  }

  renderRightView() {
    if (this.isContentError(this.state.proForma)) {
      return <Error error={this.state.proForma.getError()} />
    } else if (this.isContentError(this.state.aggregatedReport)) {
      return <Error error={this.state.aggregatedReport.getError()} />
    } else if (this.isContentValid(this.state.aggregatedReport) && this.isContentValid(this.state.proForma)) {
      const proForma = this.getProForma()
      const fixedAssets = this.state.reportTotals.lifetimeTotals.forecast.categories["Fixed Asset"]

      return <ProFormaInputsCashFlows project={this.props.project} proForma={proForma} fixedAssets={fixedAssets} />
    } else {
      return <Loading />
    }
  }

  renderViewControls() {
    return (
      <div className="content-view-controls">
        {this.state.proFormaVersions && this.state.proFormaVersions.length ? (
          <Form className="irr-report-column-controls" style={{ marginTop: "-6px" }}>
            <FormGroup check inline>
              <span className="control-forecast-label">{t("labels.forecast")}:</span>
              <Label className={"form-check-label-color"} id="pro-forma-toggle">
                <Input
                  type="select"
                  bsSize="sm"
                  value={this.props.proFormaVersion}
                  onChange={(e) => {
                    this.selectProFormaVersion(e.target.value)
                  }}
                >
                  <option value={`proforma-${this.props.project.code}`}>Current {`(${moment(this.state.proForma?.updated).format("lll")})`}</option>
                  {this.state.proFormaVersions
                    .filter((version) => !version.archived)
                    .map((version) => {
                      const versionName = `${version.id.split("/")[1]} (${moment(version.created).format("lll")})`
                      return (
                        <option key={version.id} value={version.id}>
                          {versionName}
                        </option>
                      )
                    })}
                </Input>
              </Label>
            </FormGroup>
            <UncontrolledTooltip placement="top" target="pro-forma-toggle" style={{ textAlign: "left" }}>
              {t("economics.messages.forecast_using_one_of")}:
              <ul>
                <li>
                  <b>{t("labels.current")}</b>: {t("economics.messages.current_explained")}
                </li>
                <li>
                  <b>[{t("labels.version")}]</b>: {t("economics.messages.version_explained")}
                </li>
              </ul>
            </UncontrolledTooltip>
          </Form>
        ) : null}

        {this.isContentValid(this.state.aggregatedReport) && this.isContentValid(this.state.proForma) && this.isContentValid(this.state.irr) ? (
          <ButtonToolbar className="float-end">
            <ButtonGroup>
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  ReportDownload.downloadReport(this.props.project, this.getProForma(), this.state.irr, this.state.report, this.state.reportTotals, this.state.categories, [], ["forecast"], "inputs", {
                    showSubAccounts: false,
                  })
                }}
                disabled={!this.state.categories}
              >
                {t("buttons.download")}
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ) : null}
      </div>
    )
  }
}
