import { Auth } from "aws-amplify"

/**
 * Get the organization ID of the currently authenticated user.
 * @returns {Promise<string>}
 */
export const getUserOrganizationId = async () => {
  const user = await Auth.currentAuthenticatedUser()
  return user.attributes["custom:organizationId"]
}
