import EconodeUtils from "src/econode/EconodeUtils"
import Logger from "@common/Logger"

export const ECONODE_FILTER = "filter-econodes"

export default class SearchUtils {
  static checkProductType(project, value) {
    const products = new Set()
    Object.values(project.sites).forEach((site) => {
      Object.values(site.systems).forEach((system) => {
        products.add(system.type)
      })
    })
    return products.has(value.toLowerCase())
  }

  static checkProductSubType(project, value) {
    const productTypes = new Set()
    Object.values(project.sites).forEach((site) => {
      Object.values(site.systems).forEach((system) => {
        if (system.subType) {
          productTypes.add(system.subType.toLowerCase())
        }
      })
    })
    return productTypes.has(value.replace(" ", "").toLowerCase())
  }

  static checkProjectFlag(project, values) {
    // Check to see if a flag has been selected.
    if (values.includes("Active") || values.includes("Paused") || values.includes("Cancelled") || values.includes("Archived")) {
      // If the paused flag has been selected, check to see if the project is paused.
      if (project.paused && values.includes("Paused")) {
        return true
      }
      // If the cancelled flag has been selected, check to see if the project is cancelled.
      if (project.cancelled && values.includes("Cancelled")) {
        return true
      }
      // If the archived flag has been selected, check to see if the project is archived.
      if (project.archived && values.includes("Archived")) {
        return true
      }
      // If the active flag has been selected, check to see if the project is active.
      if (values.includes("Active")) {
        return !project.paused && !project.cancelled
      }
    } else {
      // Default to all projects which are not paused or cancelled
      return !project.paused && !project.cancelled
    }
  }

  static checkRevenueType(project, value) {
    return project.revenueType && project.revenueType.toLowerCase() === value.toLowerCase()
  }

  static checkProjectRevenueTypes(project, values) {
    if (values && values.length) {
      return values.some((value) => SearchUtils.checkRevenueType(project, value))
    }
    return true
  }

  static checkSize(size, filterValue) {
    switch (filterValue) {
      case "< 200 kW AC":
        return size < 200
      case "< 250 kW DC":
        return size < 250
      case "200 - 499 kW AC":
        return size >= 200 && size < 500
      case "250 - 499 kW DC":
        return size >= 200 && size < 500
      case "500 - 1000 kW AC":
      case "500 - 1000 kW DC":
        return size >= 500 && size <= 1000
      case "> 1000 kW AC":
      case "> 1000 kW DC":
        return size > 1000
      default:
        return true
    }
  }

  A

  /**
   * Check if any system statuses are represented within a project.
   * @param project - The project.
   * @param value - The expected system status value.
   * @returns {boolean}
   */
  static checkSystemStatuses(project, value) {
    return Object.values(project.sites).some((site) => Object.values(site.systems).some((system) => system.status === value))
  }

  static filterProjects(projects, autoCompletes, filter, selectedRefinements) {
    if (!projects) {
      return []
    }

    if (filter === ECONODE_FILTER) {
      projects = EconodeUtils.filterEconodeProjects(projects)
    }

    if (!selectedRefinements["Project Flag"] || !selectedRefinements["Project Flag"].includes("Archived")) {
      projects = projects.filter((project) => !project.archived)
    }

    Object.keys(selectedRefinements).forEach((key) => {
      projects = projects.filter((project) => {
        return SearchUtils.checkRefinement(project, autoCompletes, key, selectedRefinements[key])
      })
    })

    // Default to all projects
    return projects
  }

  static checkRefinement(project, autoCompletes, key, values) {
    if (values && values.length) {
      return values.reduce((match, value) => {
        return match || SearchUtils.checkRefinementValue(project, autoCompletes, key, value, values)
      }, false)
    }
    return true // If no refinement values are specified, non are selected, so we return a match
  }

  static checkRefinementValue(project, autoCompletes, key, value, values) {
    if (key === "Tag") {
      return project.tags && project.tags.indexOf(value) >= 0
    } else if (key === "Product Type") {
      return SearchUtils.checkProductType(project, value)
    } else if (key === "Product Sub Type") {
      return SearchUtils.checkProductSubType(project, value)
    } else if (key === "Revenue Type") {
      return SearchUtils.checkProjectRevenueTypes(project, values)
    } else if (key === "Project Flag") {
      return SearchUtils.checkProjectFlag(project, values)
    } else if (key === "Project Status") {
      return project.status === value
    } else if (key === "System Status") {
      return SearchUtils.checkSystemStatuses(project, value)
    } else if (key === "State") {
      return project.state === value
    } else if (key === "Town") {
      return project.town === value
    } else if (key === "AC Size (kW)") {
      return SearchUtils.checkSize(project.acSize, value)
    } else if (key === "DC Size (kW)") {
      return SearchUtils.checkSize(project.dcSize, value)
    } else if (key === "Utility") {
      return autoCompletes && autoCompletes.utilityName[value].projects.includes(project.code)
    } else if (key === "Incentive") {
      return autoCompletes && autoCompletes.incentiveName[value].projects.includes(project.code)
    } else if (key === "EPC") {
      return autoCompletes && autoCompletes.epcContractorName[value].projects.includes(project.code)
    } else if (key === "Equipment Manufacturer") {
      return autoCompletes && autoCompletes.manufacturer[value].projects.includes(project.code)
    } else if (key === "Roof Warranty") {
      return autoCompletes && autoCompletes.roofWarrantyProviderName[value].projects.includes(project.code)
    } else {
      Logger.warning(`refinement: ${key} not supported`)
    }
  }

  static isRefinementActive(selectedRefinements, name) {
    return selectedRefinements[name] && selectedRefinements[name].length
  }

  static isRefinementSelected(selectedRefinements, name, value) {
    return selectedRefinements[name] && selectedRefinements[name].includes(value)
  }
}
