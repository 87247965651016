import React from "react"
import { Button, Container, Col, Row, FormGroup, Label } from "reactstrap"
import PropTypes from "prop-types"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Line, LineChart } from "recharts"
import Toggle from "react-toggle"

import EnergyView from "./EconodeEnergyView"
import Logger from "@common/Logger"
import Settings from "@common/Settings"
import EcosuiteComponent, { EcosuiteComponentError, Loading, Error } from "@common/EcosuiteComponent"
import ProjectUtils from "@common/utils/ProjectUtils"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import GraphUtils from "@common/utils/GraphUtils"

import EnergyService from "@dashboard/energy/EnergyService"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import DateRangeGraph from "@dashboard/energy/graphs/DateRangeGraph"

import EconodeUtils from "src/econode/EconodeUtils"

import PowerDashboardGraphTools from "./power-dashboard/PowerDashboardGraphTools"
import EnergyViewControls from "./EconodeEnergyViewControls"

import "@common/react-toggle.css"
import i18n from "src/i18n"

const { t } = i18n
const DATUM_TYPES = ["voltage", "current", "watts", "frequency", "powerFactor", "apparentPower", "reactivePower", "wattHours"]
const POWER_DATUM_GRAPH_COLORS = [GRAPH_COLORS[3], GRAPH_COLORS[5], GRAPH_COLORS[8], GRAPH_COLORS[2], GRAPH_COLORS[1], GRAPH_COLORS[0], GRAPH_COLORS[6], GRAPH_COLORS[4]]

export default class EconodePowerDashboardView extends EnergyView {
  constructor(props) {
    super(props)

    this.state = {
      filteredEconodes: [],
      filteredDatumTypes: Settings.getSetting("econodePowerDashboardDatumTypes", DATUM_TYPES.join(",")).split(","),
      showPhased: Settings.getSetting("econodePowerDashboardShowPhased", false) === "true",
      showMaxMin: Settings.getSetting("econodePowerDashboardShowMaxMin", false) === "true",
    }
    this.toggleEconode = this.toggleEconode.bind(this)
    this.toggleDatumType = this.toggleDatumType.bind(this)
    this.handleMaxMinChange = this.handleMaxMinChange.bind(this)
    this.handlePhasedChange = this.handlePhasedChange.bind(this)

    this.loadDatums()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project || !this.props.range.isSame(prevProps.range)) {
      this.loadDatums()
    }
  }

  loadDatums() {
    this.setStateIfMounted({
      datums: null,
    })
    let sourceId = ProjectUtils.getPath(this.props.project) + "/**/CON/**"
    EnergyService.getNodesDatums(this.props.range, null, this.props.project.code, null, null, sourceId)
      .then((response) => {
        this.setStateIfMounted({
          datums: response.data,
          range: response.range,
          aggregation: response.aggregation,
        })
      })
      .catch((err) => {
        Logger.error("Failed to load nodes datums for project: " + this.props.project.code + ": " + err)
        Logger.error(err)
        this.setStateIfMounted({
          datums: new EcosuiteComponentError(err),
        })
      })
  }

  toggleEconode(econodeId) {
    let filteredEconodes = this.state.filteredEconodes
    if (filteredEconodes.indexOf(econodeId) >= 0) {
      filteredEconodes = filteredEconodes.filter((id) => id !== econodeId)
    } else {
      filteredEconodes.push(econodeId)
    }
    this.setStateIfMounted({ filteredEconodes: filteredEconodes })
  }

  toggleDatumType(datumType) {
    let filteredDatumTypes = this.state.filteredDatumTypes
    if (filteredDatumTypes.indexOf(datumType) >= 0) {
      filteredDatumTypes = filteredDatumTypes.filter((id) => id !== datumType)
    } else {
      filteredDatumTypes.push(datumType)
    }

    Settings.setSetting("econodePowerDashboardDatumTypes", filteredDatumTypes.join(","))
    this.setStateIfMounted({ filteredDatumTypes: filteredDatumTypes })
  }

  getSelectedDatumTypes() {
    return this.state.filteredDatumTypes
  }

  handlePhasedChange(event) {
    Settings.setSetting("econodePowerDashboardShowPhased", Boolean(event.target.checked).toString())
    this.setStateIfMounted({ showPhased: event.target.checked })
  }

  handleMaxMinChange(event) {
    Settings.setSetting("econodePowerDashboardShowMaxMin", Boolean(event.target.checked).toString())
    this.setStateIfMounted({ showMaxMin: event.target.checked })
  }

  renderViewControls() {
    return (
      <EnergyViewControls
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        rangeName={this.props.rangeName}
        customRange={this.props.customRange}
        project={this.props.project}
        projects={this.props.projects}
        viewControls={this.renderPowerDashboardViewControls()}
      />
    )
  }

  renderPowerDashboardViewControls() {
    return (
      <div className="float-start">
        <label>
          <Toggle defaultChecked={this.state.showPhased} onChange={this.handlePhasedChange} />
          <span className="react-toggle-text">{t("econode.labels.Show Phased")}</span>
        </label>
        <label>
          <Toggle defaultChecked={this.state.showMaxMin} onChange={this.handleMaxMinChange} />
          <span className="react-toggle-text">{t("econode.labels.Show Max/Min")}</span>
        </label>
      </div>
    )
  }

  renderMainView() {
    return (
      <div className="econode-power-dashboard">
        {this.renderEconodeToggles()}
        {this.renderGraph()}
      </div>
    )
  }

  renderEconodeToggles() {
    return (
      <div className="econode-toggles">
        {EconodeUtils.getEconodesForProject(this.props.project).map((econode) => {
          return (
            <Button key={econode.code} className={this.state.filteredEconodes.indexOf(econode.code) < 0 ? "active" : null} onClick={() => this.toggleEconode(econode.code)} title={econode.code}>
              {econode.name}
            </Button>
          )
        })}
      </div>
    )
  }

  renderGraph() {
    return (
      <Container fluid={true}>
        <Row>
          <Col md="2">
            <div className="econode-power-dashboard-filters">{this.renderFilters()}</div>
          </Col>
          <Col md="10">
            <div className="econode-power-dashboard-graph">{this.renderPowerDashboardGraph()}</div>
          </Col>
        </Row>
      </Container>
    )
  }

  renderFilters() {
    return DATUM_TYPES.map((type) => {
      return (
        <FormGroup key={type}>
          <Label style={{ color: getDatumTypeColor(type) }} check>
            <input
              type="checkbox"
              checked={this.getSelectedDatumTypes().indexOf(type) >= 0}
              onChange={() => {
                this.toggleDatumType(type)
              }}
            />{" "}
            {type}
          </Label>
        </FormGroup>
      )
    })
  }

  renderPowerDashboardGraph() {
    if (this.isContentError(this.state.datums)) {
      return <Error error={this.state.datums.getError()} />
    } else if (this.isContentValid(this.state.datums)) {
      // filter the datums based on selected econodes
      let selectedEconodes = EconodeUtils.getEconodesForProject(this.props.project).filter((econode) => this.state.filteredEconodes.indexOf(econode.code) < 0)
      let datums = this.state.datums.filter((datum) => selectedEconodes.find((econode) => datum.sourceId.startsWith(econode.code)) !== undefined)
      let datumTypes = this.getSelectedDatumTypes()

      if (this.state.showPhased) {
        return (
          <React.Fragment>
            <div className="phased-graph">
              <EconodeSourceGraph datums={datums} phase="a" datumTypes={datumTypes} showMaxMin={this.state.showMaxMin} range={this.state.range} aggregation={this.state.aggregation} />
            </div>
            <div className="phased-graph">
              <EconodeSourceGraph datums={datums} phase="b" datumTypes={datumTypes} showMaxMin={this.state.showMaxMin} range={this.state.range} aggregation={this.state.aggregation} />
            </div>
            <div className="phased-graph">
              <EconodeSourceGraph datums={datums} phase="c" datumTypes={datumTypes} showMaxMin={this.state.showMaxMin} range={this.state.range} aggregation={this.state.aggregation} />
            </div>
          </React.Fragment>
        )
      } else {
        return <EconodeSourceGraph datums={datums} datumTypes={this.getSelectedDatumTypes()} showMaxMin={this.state.showMaxMin} range={this.state.range} aggregation={this.state.aggregation} />
      }
    } else {
      return <Loading />
    }
  }
}

const getDatumTypeColor = (datumType) => {
  return POWER_DATUM_GRAPH_COLORS[DATUM_TYPES.indexOf(datumType.split("_")[0])]
}

class EconodeSourceGraph extends DateRangeGraph {
  renderContent() {
    let sources = PowerDashboardGraphTools.getPowerDashabordDatums(this.props.datums, this.props.datumTypes, this.props.phase, this.props.showMaxMin)
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={sources.data} margin={{ top: 5, right: 30, left: 30, bottom: 10 }}>
          <XAxis
            dataKey="time"
            type="number"
            scale="time"
            domain={this.getDomain(this.props.range, this.props.aggregation)}
            tickFormatter={(time) => {
              return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
            }}
          />
          <YAxis />
          <YAxis yAxisId="wattHours" unit="kWh" />
          <YAxis yAxisId="watts" orientation="right" />
          <YAxis yAxisId="powerFactor" orientation="right" domain={[0, 1]} />
          <Tooltip content={<SourceTooltip range={this.props.range} aggregation={this.props.aggregation} />} />
          {sources.datumIds.map((datumId) => {
            let sourceId = PowerDashboardGraphTools.getDatumIdSourceId(datumId)
            let sourceIdx = sources.sourceIds.indexOf(sourceId)
            let color = this.getDatumTypeColor(datumId) //GRAPH_COLORS[idx % sources.datumIds.length]
            return (
              <Line
                yAxisId={this.getAxis(datumId)}
                key={datumId}
                dataKey={datumId}
                type="monotone"
                stroke={color}
                dot={true}
                strokeDasharray={`${sourceIdx ? sourceIdx : 1} ${sourceIdx ? sourceIdx : 0}`}
                isAnimationActive={false}
              />
            )
          })}
        </LineChart>
      </ResponsiveContainer>
    )
  }

  getAxis(datumId) {
    let type = PowerDashboardGraphTools.getDatumIdType(datumId)
    if (type.startsWith("wattHours")) {
      return "wattHours"
    }
    if (type.startsWith("powerFactor")) {
      return "powerFactor"
    }
    if (type.startsWith("watts") || type.startsWith("apparentPower") || type.startsWith("reactivePower")) {
      return "watts"
    }
  }

  getDatumTypeColor(datumId) {
    let type = PowerDashboardGraphTools.getDatumIdType(datumId)
    return getDatumTypeColor(type)
  }
}

class SourceTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {payload.map((data) => {
              return (
                <React.Fragment key={data.dataKey}>
                  <p className="label" style={{ color: data.stroke }}>
                    {this.formatDatum(data)}
                  </p>
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }
    return null
  }

  formatDatum(data) {
    let sourceId = PowerDashboardGraphTools.getDatumIdSourceId(data.dataKey)
    let type = PowerDashboardGraphTools.getDatumIdType(data.dataKey)
    if (type.startsWith("wattHours")) {
      return `${sourceId} ${type}: ${EnergyUtils.formatKiloWattHours(data.value ? data.value : 0)}`
    } else if (type.startsWith("watts")) {
      return `${sourceId} ${type}: ${EnergyUtils.formatNumber(data.value)} W`
    } else if (type.startsWith("voltage")) {
      return `${sourceId} ${type}: ${EnergyUtils.formatNumber(data.value)} V`
    } else if (type.startsWith("current")) {
      return `${sourceId} ${type}: ${EnergyUtils.formatNumber(data.value)} A`
    } else if (type.startsWith("frequency")) {
      return `${sourceId} ${type}: ${EnergyUtils.formatNumber(data.value)} Hz`
    } else if (type.startsWith("reactivePower")) {
      return `${sourceId} ${type}: ${EnergyUtils.formatNumber(data.value)} VAR`
    } else if (type.startsWith("apparentPower")) {
      return `${sourceId} ${type}: ${EnergyUtils.formatNumber(data.value)} VA`
    } else {
      return `${sourceId} ${type}: ${EnergyUtils.formatNumber(data.value)}`
    }
  }
}
