import * as Moment from "moment-timezone"
import { extendMoment } from "moment-range"

import API from "@common/API"

const moment = extendMoment(Moment)

/**
 * Service for administering Ecosuite Projects settings.
 */
const ProjectAdminService = {
  async getProjects() {
    return API.get<{ projects: unknown }>("/projects/")
      .then((data) => {
        return data.projects
      })
      .catch(() => {
        return Promise.reject({ message: "Unable to retrieve Projects" })
      })
  },

  async getProject(projectId: string) {
    return API.get<{ project: unknown }>(`/projects/${projectId}`)
      .then((data) => {
        return data.project
      })
      .catch(() => {
        return Promise.reject({ message: "Unable to retrieve Project: " + projectId })
      })
  },

  createProject(form: { formData: Record<string, unknown> }): Promise<unknown> {
    return API.post("/projects/" + form.formData.code, form.formData)
  },

  editProject(id: string, form: { formData: unknown }, note: string): Promise<unknown> {
    return API.put("/projects/" + id + `?note=${encodeURIComponent(note)}`, form.formData)
  },

  deleteProject(id: string, note: string): Promise<unknown> {
    return API.delete("/projects/" + id + `?note=${encodeURIComponent(note)}`)
  },

  createSite(projectId: string, form: { formData: Record<string, unknown> }): Promise<unknown> {
    return API.post("/projects/" + projectId + "/sites/" + form.formData.code, form.formData)
  },

  editSite(projectId: string, id: string, form: { formData: unknown }, note: string): Promise<unknown> {
    return API.put("/projects/" + projectId + "/sites/" + id + `?note=${encodeURIComponent(note)}`, form.formData)
  },

  deleteSite(projectId: string, id: string, note: string): Promise<unknown> {
    return API.delete("/projects/" + projectId + "/sites/" + id + `?note=${encodeURIComponent(note)}`)
  },

  createSystem(projectId: string, siteId: string, system: Record<string, unknown>): Promise<unknown> {
    return API.post("/projects/" + projectId + "/sites/" + siteId + "/systems/" + system.code, system)
  },

  editSystem(projectId: string, siteId: string, id: string, system: Record<string, unknown>, note: string): Promise<unknown> {
    return API.put("/projects/" + projectId + "/sites/" + siteId + "/systems/" + id + `?note=${encodeURIComponent(note)}`, system)
  },

  deleteSystem(projectId: string, siteId: string, id: string, note: string): Promise<unknown> {
    return API.delete("/projects/" + projectId + "/sites/" + siteId + "/systems/" + id + `?note=${encodeURIComponent(note)}`)
  },

  getPredictedGeneration(): Promise<unknown> {
    const range = moment().range(moment().startOf("year"), moment().endOf("year"))
    const url = API.addRangeToUrl("/energy/datums/generation/predicted", range)
    return API.get(url as string)
  },
}

export default ProjectAdminService
