import React from "react"

import { EcosuiteComponentError, Loading } from "@common/EcosuiteComponent"
import EcosuiteModule from "@common/module/EcosuiteModule"
import Logger from "@common/Logger"

import EventService from "./EventService"
import UserAlertService from "./UserAlertService"

import EventView from "./views/EventView"
import ServiceRequestView from "./views/ServiceRequestView"
import UserAlerts from "./views/UserAlertsView"

import "./EventModule.css"
import ServiceRequestService from "./ServiceRequestService"
import i18n from "src/i18n"

const { t } = i18n
class EventModule extends EcosuiteModule {
  constructor(props) {
    super(props, "event")

    this.loadEvents = this.loadEvents.bind(this)
    this.eventUpdated = this.eventUpdated.bind(this)
    this.eventDeleted = this.eventDeleted.bind(this)
    this.serviceRequestUpdated = this.serviceRequestUpdated.bind(this)
    this.serviceRequestDeleted = this.serviceRequestDeleted.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.load()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (this.props.loadTime !== prevProps.loadTime) {
      this.load()
    }
  }

  selectRange(rangeName, customRange) {
    let range = super.selectRange(rangeName, customRange)
    this.loadEvents(range)
    this.loadServiceRequests(range)
    this.loadUserAlerts(range)
  }

  load() {
    const range = this.getExclusiveRange()
    this.loadEvents(range)
    this.loadServiceRequests(range)
    this.loadUserAlerts(range)
  }

  loadEvents(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setState({
      events: undefined,
    })
    EventService.listEvents(range).then((data) => {
      if (this.isRangeCurrent(range)) {
        const events = data.events.sort((a, b) => {
          if (a.startDate === b.startDate) {
            return a.cause.localeCompare(b.cause)
          }
          return a.startDate.localeCompare(b.startDate)
        })

        this.setStateIfMounted({
          events: events,
        })
      } else {
        Logger.debug(`Ignoring out of date response for range: ${range}`)
      }
    })
  }

  loadServiceRequests(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setState({
      serviceRequests: undefined,
    })
    ServiceRequestService.listServiceRequests(range).then((data) => {
      if (this.isRangeCurrent(range)) {
        const serviceRequests = data.serviceRequests.sort((a, b) => {
          if (a.created === b.created) {
            return a.taskSummary.localeCompare(b.taskSummary)
          }
          return a.created.localeCompare(b.created)
        })

        this.setStateIfMounted({
          serviceRequests: serviceRequests,
        })
      } else {
        Logger.debug(`Ignoring out of date response for range: ${range}`)
      }
    })
  }

  loadUserAlerts(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted(
      {
        userAlerts: undefined,
      },
      () => {
        UserAlertService.getUserAlerts(this.props.userId, range)
          .then((alerts) => {
            if (this.isRangeCurrent(range)) {
              this.setStateIfMounted({
                userAlerts: alerts,
              })
            } else {
              Logger.debug(`Ignoring out of date response for range: ${range}`)
            }
          })
          .catch((err) => {
            Logger.error(err)
            if (this.isRangeCurrent(range)) {
              this.setStateIfMounted({
                userAlerts: new EcosuiteComponentError(err),
              })
            }
          })
      },
    )
  }

  eventUpdated(event) {
    if (event) {
      const events = this.state.events
      if (events) {
        let found = false
        events.forEach((listEvent, idx) => {
          if (listEvent.id === event.id) {
            events[idx] = event // replace the matching event
            found = true
          }
        })

        if (!found) {
          events.push(event)
        }
      }

      this.setStateIfMounted({
        events: events,
      })
    } else {
      this.loadEvents(this.getExclusiveRange())
    }
  }

  eventDeleted() {
    this.loadEvents(this.getExclusiveRange())
  }

  serviceRequestUpdated(serviceRequest) {
    if (serviceRequest) {
      const serviceRequests = this.state.serviceRequests
      if (serviceRequests) {
        let found = false
        serviceRequests.forEach((listServiceRequest, idx) => {
          if (listServiceRequest.id === serviceRequest.id) {
            serviceRequests[idx] = serviceRequest // replace the matching event
            found = true
          }
        })

        if (!found) {
          serviceRequests.push(serviceRequest)
        }
      }

      this.setStateIfMounted({
        serviceRequests: serviceRequests,
      })
    } else {
      this.loadServiceRequests(this.getExclusiveRange())
    }
  }

  serviceRequestDeleted() {
    this.loadServiceRequests(this.getExclusiveRange())
  }

  /**
   * Filters the current events by the selected projects.
   * @returns {*} - The events filtered by selected projects.
   */
  filterEventsByProject() {
    var events = this.state.events
    if (events) {
      let projectIds = this.props.projects.map((project) => project.code)

      events = events.filter((event) => {
        if (this.props.project) {
          return this.props.project.code === event.location.project
        } else {
          return projectIds.indexOf(event.location.project) >= 0
        }
      })

      return events
    }
  }

  renderModuleView() {
    if (!this.state.events) {
      return (
        // Without these styles the loading spinner isn't centered
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Loading message={t("event.messages.fetching_events")} />
        </div>
      )
    }

    if (this.state.moduleView === "userAlerts") {
      return this.renderAlerts()
    } else if (this.state.moduleView === "serviceRequests") {
      return this.renderServiceRequests()
    } else {
      return this.renderEvents()
    }
  }

  renderProjectView() {
    if (!this.state.events) {
      return <Loading message={t("event.messages.fetching_events")} />
    }

    if (this.state.projectView === "userAlerts") {
      return this.renderAlerts()
    } else if (this.state.projectView === "serviceRequests") {
      return this.renderServiceRequests()
    } else {
      return this.renderEvents()
    }
  }

  renderAlerts() {
    const alerts =
      this.props.project && this.state.userAlerts
        ? this.state.userAlerts.filter((alert) => (alert.sourceId && alert.sourceId.startsWith("/" + this.props.project.code)) || alert.message.startsWith("/" + this.props.project.code))
        : this.state.userAlerts
    return <UserAlerts userAlerts={alerts} />
  }

  renderServiceRequests() {
    return (
      <ServiceRequestView
        {...this.props}
        events={this.filterEventsByProject()}
        serviceRequests={this.state.serviceRequests}
        actions={{ serviceRequestUpdated: this.serviceRequestUpdated, serviceRequestDeleted: this.serviceRequestDeleted }}
      />
    )
  }

  renderEvents() {
    return <EventView {...this.props} events={this.filterEventsByProject()} actions={{ eventUpdated: this.eventUpdated, eventDeleted: this.eventDeleted }} />
  }
}

export default EventModule
