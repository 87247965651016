import React, { useState, useEffect, useMemo, useCallback } from "react"
import { Button } from "src/components/ui/button"
import { Share1Icon } from "@radix-ui/react-icons"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "src/components/ui/dialog"
import { Creatable } from "src/components/ui/rcselect"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/form"
import { Table } from "ka-table"
import { DataType, SortingMode } from "ka-table/enums"
import moment from "moment"
import EmptyView from "src/components/ui/empty-view"
import LoadingButton from "src/components/ui/loading-button"
import { P } from "src/components/ui/typography"
import "src/styles/table-theme.scss"
import { useShareRecordDocumentsToPublic } from "src/services/record/documents"
import { Auth } from "aws-amplify"
import { toast } from "react-toastify"
import { useUserOrganization } from "src/services/users"
import { Document, Record, MetaData, Portfolio, Project } from "../types"

const formSchema = z.object({
  emails: z
    .array(z.object({ label: z.string().email(), value: z.string().email() }))
    .min(1, "At least one email is required"),
})

type FormValues = z.infer<typeof formSchema>

const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

const flattenDocumentsData = (documentsData: Record[]) =>
  documentsData.flatMap((record) =>
    record.documents.map((doc) => ({
      fileName: doc.fileName,
      recordName: record.recordName,
      recordPath: record.recordPath,
      recordSubType: record.recordSubType,
      recordType: record.recordType,
      updated: doc.updated,
    })),
  )

const useAuth = () => {
  const [sub, setSub] = useState<string | undefined>(undefined)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        setSub(user.attributes.sub)
      } catch (error) {
        console.error("Error fetching authenticated user:", error)
      }
    }
    fetchUser()
  }, [])

  return { sub }
}

const DocumentTable: React.FC<{ data: ReturnType<typeof flattenDocumentsData> }> = ({ data }) => (
  <div className="twx-ka-table">
    <Table
      columnResizing={true}
      virtualScrolling={{ enabled: true }}
      columns={[
        { key: "index", title: "Sn", dataType: DataType.Number, width: 64 },
        { key: "fileName", title: "File Name", dataType: DataType.String, width: 1000 },
        { key: "recordName", title: "Record Name", dataType: DataType.String },
        { key: "recordPath", title: "Record Path", dataType: DataType.String },
      ]}
      data={data.map((datum, i) => ({ ...datum, index: i + 1 }))}
      rowKeyField={"id"}
      sortingMode={SortingMode.Single}
      childComponents={{
        cellText: {
          content: ({ column, rowData }) => {
            if (column.key === "updated") {
              return <>{moment(rowData.updated).format("LLL")}</>
            }
          },
        },
        tableWrapper: {
          elementAttributes: () => ({ style: { maxHeight: 600 } }),
        },
      }}
    />
  </div>
)

interface ShareDocumentsProps {
  documentsData: Record[]
  metaData: MetaData
}

export default function ShareDocuments({ documentsData, metaData }: ShareDocumentsProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { sub } = useAuth()
  const { data: org } = useUserOrganization(sub)
  const { mutate, isLoading } = useShareRecordDocumentsToPublic()

  const flattenedData = useMemo(() => flattenDocumentsData(documentsData), [documentsData])

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { emails: [] },
  })

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!org) {
        toast.error("Failed to fetch organization")
        return
      }

      const emailAddresses = values.emails.map((e) => e.value)
      const organization = {
        name: org.organization.name,
        logo: org.organization.settings?.logoUrl,
      }

      mutate(
        { ...metaData, emailAddresses, organization },
        {
          onSuccess: () => {
            toast.success("Documents shared successfully")
            setIsOpen(false)
            form.reset()
          },
          onError: (error) => {
            toast.error("Failed to share documents: " + error.message)
          },
        },
      )
    },
    [org, metaData, mutate, form, setIsOpen],
  )

  return (
    <div className="tw-ml-4">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button onClick={() => setIsOpen(true)}>
            <Share1Icon className="tw-mr-2 tw-h-4 tw-w-4" /> Share Documents
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:tw-max-w-[80%]">
          <DialogHeader className="tw-mb-4 tw-p-2">
            <DialogTitle>Share documents to public</DialogTitle>
            <DialogDescription>Share documents in the current screen to users</DialogDescription>
          </DialogHeader>
          <div className="tw-p-2">
            {flattenedData.length > 0 ? (
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-8">
                  <FormField
                    control={form.control}
                    name="emails"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email Addresses</FormLabel>
                        <FormControl>
                          <Controller
                            name="emails"
                            control={form.control}
                            render={({ field }) => (
                              <Creatable
                                {...field}
                                isMulti={true}
                                noOptionsMessage={() => <div>Please enter a valid email</div>}
                                className="tw-mt-4"
                                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                isValidNewOption={(inputValue) => isValidEmail(inputValue)}
                              />
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <P>List of documents to be shared:</P>
                  <DocumentTable data={flattenedData} />
                  <DialogFooter>
                    <LoadingButton type="submit" loading={isLoading} onClick={form.handleSubmit(onSubmit)}>
                      Share
                    </LoadingButton>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setIsOpen(false)
                        form.reset()
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            ) : (
              <EmptyView text="No Documents Selected" />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
