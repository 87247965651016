import React from "react"

import EcosuiteModule from "@common/module/EcosuiteModule"
import "../energy/EnergyModule.css"
import DRModuleViews from "./DRModuleViews"

import "./DRModule.css"
import DRService from "./DRService"
import UserAdminService from "@admin/users/UserAdminService"
import moment from "moment"

export default class DRModule extends EcosuiteModule {
  constructor(props) {
    super(props, "demandresponse")

    this.loadEvents = this.loadEvents.bind(this)
    this.loadParticipants = this.loadParticipants.bind(this)

    this.loadNotifications(this.getCustomRange())
    this.loadEvents(this.getCustomRange())
    this.loadParticipants()
  }

  loadNotifications(range) {
    this.setStateIfMounted({
      notifications: null,
    })

    Promise.all([DRService.listNotifications(), UserAdminService.getUsers()]).then(([notifications, users]) => {
      // If this is project specific, filter down the notifications to this project
      let filtered = this.props.project ? notifications.filter((notification) => notification.targets.includes(this.props.project.code)) : notifications

      // Filter regardless based on time
      filtered = filtered.filter((notification) => {
        const date = moment(notification.date)
        return date >= range.start && date <= range.end
      })

      this.setStateIfMounted({
        users: users.users,
        notifications: filtered.sort((a, b) => {
          const left = moment(a.date)
          const right = moment(b.date)
          if (left > right) {
            return -1
          }
          if (left < right) {
            return 1
          }
          return 0
        }),
      })
    })
  }

  loadEvents(range) {
    this.setStateIfMounted({
      events: null,
    })

    return DRService.listEvents().then((events) => {
      // Filter by range, then filter by whether the even has a relevant participant
      const relevantCodes = this.props.projects.map((project) => project.code)
      const filtered = events
        .filter((event) => {
          const start = moment(event.startDate)
          const end = moment(event.endDate)
          const eventRange = moment.range(start, end)
          return range.overlaps(eventRange)
        })
        .filter((event) => event.participants.some((code) => relevantCodes.includes(code)))

      this.setStateIfMounted({
        events: filtered,
      })

      return events
    })
  }

  loadParticipants() {
    this.setStateIfMounted({
      participants: null,
    })

    DRService.listParticipants().then((participants) => {
      this.setStateIfMounted({
        participants: participants,
      })
    })
  }

  componentDidMount() {
    super.componentDidMount()
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (this.props.project !== prevProps.project) {
      this.loadNotifications(this.getCustomRange())
    }
    if (!this.areProjectsCurrent(prevProps.projects.map((p) => p.code))) {
      this.loadNotifications(this.getCustomRange())
      this.loadParticipants()
      this.loadEvents(this.getCustomRange())
    }
  }

  selectRange(rangeName, customRange) {
    let range = super.selectRange(rangeName, customRange)
    this.setStateIfMounted({ aggregation: undefined }, () => {
      this.loadNotifications(range)
      this.loadEvents(range)
    })
  }

  renderProjectView() {
    return (
      <div className={"EnergyView"}>
        <DRModuleViews
          view={"notification"}
          projects={this.props.projects}
          project={this.props.project}
          allProjects={this.props.allProjects}
          notifications={this.state.notifications}
          users={this.state.users}
          events={this.state.events}
          participants={this.state.participants}
          fetchEvents={() => this.loadEvents(this.getCustomRange())}
          fetchParticipants={() => this.loadParticipants()}
        />
      </div>
    )
  }

  renderModuleView() {
    return (
      <div className={"EnergyView"}>
        <DRModuleViews
          view={this.state.moduleView}
          projects={this.props.projects}
          allProjects={this.props.allProjects}
          notifications={this.state.notifications}
          users={this.state.users}
          events={this.state.events}
          participants={this.state.participants}
          fetchEvents={() => this.loadEvents(this.getCustomRange())}
          fetchParticipants={() => this.loadParticipants()}
        />
      </div>
    )
  }
}
