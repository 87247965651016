import React from "react"
import { Loading } from "@common/EcosuiteComponent"

import EnergyView from "./EnergyView"

import SiteGraphs from "./site/SiteGraphs"

class EnergyProjectGraphsView extends EnergyView {
  getSiteStatus(siteId) {
    if (this.props.projectStatus) {
      return this.props.projectStatus.sites[siteId]
    }
    return null
  }

  getSiteReading(siteId) {
    if (this.props.readings) {
      return this.props.readings.sites[siteId]
    }
    return null
  }

  renderMainView() {
    if (this.props.project) {
      return (
        <div>
          {Object.values(this.props.project.sites).map((site) => {
            let siteStatus = this.isContentValid(this.props.projectStatus) ? this.props.projectStatus.sites[site.code] : this.props.projectStatus
            let siteDatums = this.isContentValid(this.props.datums) ? this.props.datums.sites[site.code] : this.props.datums
            let siteConsumptionCost = this.isContentValid(this.props.consumptionCost) ? this.props.consumptionCost.sites[site.code] : this.props.consumptionCost
            let sitePredictedConsumption = this.isContentValid(this.props.predictedConsumption) ? this.props.predictedConsumption.sites[site.code] : this.props.predictedConsumption
            let siteExpectedGeneration = this.isContentValid(this.props.expectedGeneration) ? this.props.expectedGeneration.sites[site.code] : this.props.expectedGeneration
            let sitePredictedGeneration = this.isContentValid(this.props.predictedGeneration) ? this.props.predictedGeneration.sites[site.code] : this.props.predictedGeneration
            let siteReadings = this.isContentValid(this.props.readings) ? this.props.readings.sites[site.code] : this.props.readings

            return (
              <SiteGraphs
                key={site.code}
                groups={this.props.groups}
                project={this.props.project}
                site={site}
                datums={siteDatums}
                datumsRange={this.props.datumsRange}
                datumsAggregation={this.props.datumsAggregation}
                consumptionCost={siteConsumptionCost}
                predictedConsumption={sitePredictedConsumption}
                expectedGeneration={siteExpectedGeneration}
                predictedGeneration={sitePredictedGeneration}
                readings={siteReadings}
                siteStatus={siteStatus}
                range={this.props.range}
                selectRange={this.props.selectRange}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                userAlerts={this.props.userAlerts}
              />
            )
          })}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

export default EnergyProjectGraphsView
