import React from "react"

import EcosuiteComponent, { Error, Loading } from "@common/EcosuiteComponent"

import EnergyUtils from "@dashboard/energy/EnergyUtils"
import DatumUtils from "@dashboard/energy/graphs/datums/DatumUtils"
import SourceDatumUtils from "@dashboard/energy/graphs/datums/SourceDatumUtils"

import EventLineGraph from "@dashboard/event/line-graph/EventLineGraph"
import SiteWeatherLine from "../weather/SiteWeatherLine"

import TotalAreaGraph from "@dashboard/energy/graphs/TotalAreaGraph"

import TotalGenerationBarGraph from "@dashboard/energy/graphs/generation/TotalGenerationBarGraph"
import GenerationLineGraph from "@dashboard/energy/graphs/generation/GenerationLineGraph"
import GenerationStackedBarGraph from "@dashboard/energy/graphs/generation/GenerationStackedBarGraph"
import ExpectedGenerationLineGraph from "@dashboard/energy/graphs/generation/ExpectedGenerationLineGraph"
import GenerationPowerLineGraph from "@dashboard/energy/graphs/generation/GenerationPowerLineGraph"

import TotalConsumptionBarGraph from "@dashboard/energy/graphs/consumption/TotalConsumptionBarGraph"
import PredictedConsumptionLineGraph from "@dashboard/energy/graphs/consumption/PredictedConsumptionLineGraph"
import ConsumptionPowerLineGraph from "@dashboard/energy/graphs/consumption/ConsumptionPowerLineGraph"

import TotalPowerLineGraph from "@dashboard/energy/graphs/TotalPowerLineGraph"
import PowerFactorBarGraph from "@dashboard/energy/graphs/PowerFactorBarGraph"
import ForecastGenerationLineGraph from "@dashboard/energy/graphs/generation/ForecastGenerationLineGraph"
import StoreageLineGraph from "@dashboard/energy/graphs/storage/StorageLineGraph"
import i18n from "src/i18n"

const { t } = i18n
class SiteGraphs extends EcosuiteComponent {
  renderContent() {
    let siteStatus = this.props.siteStatus

    return (
      <div className="site-content">
        <h4
          className={
            "content-title " + (this.isContentValid(siteStatus) && siteStatus.status ? siteStatus.status : "no-status")
          }
        >
          {this.props.site.name} ({this.props.site.code})
        </h4>

        {this.getSiteGraph()}
      </div>
    )
  }

  getSiteGraph() {
    if (this.isContentError(this.props.datums)) {
      return <Error error={this.props.datums.getError()} />
    } else if (this.isContentValid(this.props.datums)) {
      let graphDatums = DatumUtils.groupDatums(Object.values(this.props.site.systems), this.props.datums.systems)
      let consumptionCostDatums = this.isContentValid(this.props.consumptionCost)
        ? DatumUtils.groupConsumptionCostDatums(
            Object.values(this.props.site.systems),
            this.props.consumptionCost.systems,
          )
        : null
      let predictedConsumptionDatums = this.isContentValid(this.props.predictedConsumption)
        ? DatumUtils.groupPredictedConsumptionDatums(
            Object.values(this.props.site.systems),
            this.props.predictedConsumption.systems,
          )
        : null
      let expectedGenerationDatums = this.isContentValid(this.props.expectedGeneration)
        ? DatumUtils.groupExpectedGenerationDatums(
            Object.values(this.props.site.systems),
            this.props.expectedGeneration.systems,
          )
        : null
      let predictedGenerationDatums = this.isContentValid(this.props.predictedGeneration)
        ? DatumUtils.groupPredictedGenerationDatums(
            Object.values(this.props.site.systems),
            this.props.predictedGeneration.systems,
          )
        : null

      let sourceGraphDatums = SourceDatumUtils.groupSystemSourceDatums(Object.values(this.props.datums.systems))

      let range = this.props.datumsRange
      let aggregation = this.props.datumsAggregation

      let graphs = []

      graphs.push(
        <EventLineGraph
          key="event-line"
          groups={this.props.groups}
          projects={[this.props.project]}
          range={this.props.range}
          dotAggregate={aggregation + "s"}
          type="energy"
          project={this.props.project.code}
          site={this.props.site.code}
          style={{
            marginLeft: "60px",
            width: "calc(100% - 90px)",
          }}
        />,
      )

      graphs.push(
        <SiteWeatherLine
          key="weather-line"
          range={this.props.range}
          project={this.props.project}
          site={this.props.site}
          style={{
            marginLeft: "60px",
            width: "calc(100% - 90px)",
          }}
        />,
      )

      if (graphDatums && Object.keys(graphDatums).length > 0) {
        graphs.push(
          <React.Fragment key="total-graph">
            <h5>{`${t("energy.graphNames.site_generation_and_consumption")} (kWh)`}</h5>

            <TotalAreaGraph
              assets={EnergyUtils.getSystems(this.props.site)}
              graphDatums={graphDatums}
              datums={this.props.datums}
              consumptionCostDatums={consumptionCostDatums}
              predictedConsumptionDatums={predictedConsumptionDatums}
              expectedGenerationDatums={expectedGenerationDatums}
              predictedGenerationDatums={predictedGenerationDatums}
              range={range}
              aggregation={aggregation}
              showGeneration={this.props.showGeneration}
              showConsumption={this.props.showConsumption}
              showStorage={this.props.showStorage}
              selectRange={this.props.selectRange}
            />
          </React.Fragment>,
        )

        if (this.props.showGeneration) {
          graphs.push(
            <React.Fragment key="site-gen-graph">
              <h5>{`${t("energy.graphNames.site_generation")} (kWh)`}</h5>
              <TotalGenerationBarGraph
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                datums={this.props.datums}
                consumptionCostDatums={consumptionCostDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                expectedGenerationDatums={expectedGenerationDatums}
                predictedGenerationDatums={predictedGenerationDatums}
                range={range}
                aggregation={aggregation}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                showStorage={this.props.showStorage}
                selectRange={this.props.selectRange}
                project={this.props.project}
                projects={this.props.projects}
              />
            </React.Fragment>,
          )
          graphs.push(
            <React.Fragment key="system-gen-graph">
              <h5>{`${t("energy.graphNames.system_generation")} (kWh)`}</h5>
              <GenerationLineGraph
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
              />
            </React.Fragment>,
          )
          graphs.push(
            <React.Fragment key="system-gen-stacked-graph">
              <h5>{`${t("energy.graphNames.system_generation_stacked")} (kWh)`}</h5>
              <GenerationStackedBarGraph
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
              />
            </React.Fragment>,
          )
          graphs.push(
            <React.Fragment key="system-gen-norm-graph">
              <h5>{t("energy.graphNames.system_generation_normalized")}</h5>
              <GenerationLineGraph
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
                normalise={true}
              />
            </React.Fragment>,
          )
          graphs.push(
            <React.Fragment key="expected-site-gen-graph">
              <h5>{`${t("energy.graphNames.expected_site_generation")} (kWh)`}</h5>
              <ExpectedGenerationLineGraph
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                expectedGenerationDatums={expectedGenerationDatums}
                predictedGenerationDatums={predictedGenerationDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>,
          )
          graphs.push(
            <React.Fragment key="forecast-site-gen-graph">
              <h5>{`${t("energy.graphNames.forecast_site_generation")} (kWh)`}</h5>
              <ForecastGenerationLineGraph
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                expectedGenerationDatums={expectedGenerationDatums}
                predictedGenerationDatums={predictedGenerationDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>,
          )
        }

        if (this.props.showConsumption) {
          graphs.push(
            <React.Fragment key="site-con-graph">
              <h5>{`${t("energy.graphNames.site_consumption")} (kWh)`}</h5>

              <TotalConsumptionBarGraph
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                datums={this.props.datums}
                consumptionCostDatums={consumptionCostDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                expectedGenerationDatums={expectedGenerationDatums}
                predictedGenerationDatums={predictedGenerationDatums}
                range={range}
                aggregation={aggregation}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                showStorage={this.props.showStorage}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>,
          )

          graphs.push(
            <React.Fragment key="expected-site-con-graph">
              <h5>{`${t("energy.graphNames.expected_site_consumption")} (kWh)`}</h5>
              <PredictedConsumptionLineGraph
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                predictedConsumptionDatums={predictedConsumptionDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>,
          )
        }

        let readingsGraphName = `${t("energy.graphNames.site_generation_and_demand")} (kW)`
        if (!this.props.showConsumption) {
          readingsGraphName = `${t("energy.graphNames.site_generation")} (kW)`
        } else if (!this.props.showGeneration) {
          readingsGraphName = `${t("energy.graphNames.site_demand")} (kW)`
        }

        graphs.push(
          <React.Fragment key="readings-graph">
            <h5>{readingsGraphName}</h5>
            <TotalPowerLineGraph
              assets={EnergyUtils.getSystems(this.props.site)}
              graphDatums={graphDatums}
              datums={this.props.datums}
              range={range}
              aggregation={aggregation}
              showGeneration={this.props.showGeneration}
              showConsumption={this.props.showConsumption}
              showStorage={this.props.showStorage}
              selectRange={this.props.selectRange}
            />
          </React.Fragment>,
        )
      }

      if (this.props.showGeneration) {
        graphs.push(
          <React.Fragment key="system-gen-power-graph">
            <h5>{`${t("energy.graphNames.system_generation")} (kW)`}</h5>
            <GenerationPowerLineGraph
              assets={EnergyUtils.getSystems(this.props.site)}
              graphDatums={graphDatums}
              datums={this.props.datums}
              range={range}
              aggregation={aggregation}
              selectRange={this.props.selectRange}
            />
          </React.Fragment>,
        )
      }

      if (this.props.showConsumption) {
        graphs.push(
          <React.Fragment key="system-con-power-graph">
            <h5>{`${t("energy.graphNames.system_demand")} (kW)`}</h5>
            <ConsumptionPowerLineGraph
              assets={EnergyUtils.getSystems(this.props.site)}
              graphDatums={graphDatums}
              datums={this.props.datums}
              range={range}
              aggregation={aggregation}
              selectRange={this.props.selectRange}
            />
          </React.Fragment>,
        )
      }

      if (this.props.showStorage) {
        graphs.systemStorage = {
          graph: (
            <React.Fragment>
              <StoreageLineGraph
                project={this.props.project}
                site={this.props.site}
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: `${t("energy.graphNames.system_storage")} (kWh)`,
        }
      }

      graphs.push(
        <React.Fragment key="power-graph">
          <h5>{t("energy.graphNames.power_factor")}</h5>
          <PowerFactorBarGraph
            assets={EnergyUtils.getSystems(this.props.site)}
            graphDatums={sourceGraphDatums}
            datums={this.props.datums}
            range={range}
            aggregation={aggregation}
            selectRange={this.props.selectRange}
            userAlerts={this.props.userAlerts}
          />
        </React.Fragment>,
      )

      return <div>{graphs}</div>
    } else {
      return <Loading />
    }
  }
}

export default SiteGraphs
