import React, { Component } from "react"
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu-v2"
import moment from "moment"
import { create, all } from "mathjs"

import prettyBytes from "src/pretty-bytes"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import i18n from "src/i18n"

const math = create(all)
const { t } = i18n
class ProjectsTable extends EcosuiteComponent {
  renderContent() {
    if (this.props.connectivity) {
      let days = this.props.connectivity ? moment(this.props.connectivity.range.localEndDate).diff(this.props.connectivity.range.localStartDate, "days", true) : 1

      return (
        <div>
          <table>
            <thead>
              <tr>
                <th>{t("table_headings.code")}</th>
                <th>{t("table_headings.name")}</th>
                <th>{t("labels.devices")}</th>
                <th className="ranged">{t("connectivity.labels.total_data")}</th>
                <th className="ranged">{t("connectivity.labels.average_daily_data")}</th>
                <th className="ranged">{t("connectivity.labels.expected_daily_data")}</th>
                <th className="ranged">{t("connectivity.labels.percent_of_daily_data")}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(this.props.connectivity.projects).map((projectId) => {
                const project = this.props.projects.find((project) => project.code === projectId)
                let projectExpectedDailyData = Object.values(project.sites).reduce((total, site) => {
                  return (total += site.expectedDailyData ? site.expectedDailyData : 0)
                }, 0)

                return (
                  <ContextMenuTrigger renderTag="tr" id={"project-row-" + projectId} key={projectId}>
                    <td>{projectId}</td>
                    <td>{project.name}</td>
                    <td className="numerical">{this.props.devices ? this.props.devices.projects[projectId].devices.length : ""}</td>
                    <td className="numerical ranged">{this.props.connectivity ? prettyBytes(this.props.connectivity.projects[projectId].total) : ""}</td>
                    <td className="numerical ranged">{this.props.connectivity ? prettyBytes(this.props.connectivity.projects[projectId].total / days) : ""}</td>
                    <td className="numerical ranged">{this.props.connectivity ? prettyBytes(projectExpectedDailyData) : ""}</td>
                    <td className="numerical ranged">
                      {this.props.connectivity && projectExpectedDailyData
                        ? math.round(math.multiply(math.divide(this.props.connectivity.projects[projectId].total / days, projectExpectedDailyData), 100), 0) + "%"
                        : ""}
                    </td>
                  </ContextMenuTrigger>
                )
              })}
            </tbody>
          </table>

          {Object.keys(this.props.connectivity.projects).map((projectId) => {
            return <ProjectMenu projectId={projectId} devices={this.props.devices ? this.props.devices.projects[projectId].devices : []} key={projectId} />
          })}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

class ProjectMenu extends Component {
  render() {
    let projectId = this.props.projectId
    let devices = this.props.devices

    return (
      <ContextMenu id={"project-row-" + projectId} key={projectId}>
        {devices && devices.length > 0 ? (
          devices.map((device) => {
            return (
              <MenuItem data={device} onClick={this.openDevice} key={device.id}>
                {device.name}
              </MenuItem>
            )
          })
        ) : (
          <MenuItem>
            <i>{t("connectivity.messages.no_devices_available")}</i>
          </MenuItem>
        )}
      </ContextMenu>
    )
  }

  openDevice(e, data) {
    window.open(data.url)
  }
}

export default ProjectsTable
