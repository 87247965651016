import React from "react"
import { Label, Col } from "reactstrap"
import moment from "moment"
import Icon from "@common/display/Icon"
import { startCase } from "lodash"
import CopyToClipboard from "@common/display/CopyToClipboard"
import i18n from "src/i18n"
import ConfirmDialog from "@common/display/ConfirmModal/ConfirmModal"
import NiceModal from "@ebay/nice-modal-react"
import WithToolTip from "@common/display/ToolTip"

const { t } = i18n
const PROJECT_EXPECTANCY = 36 //years

function RegisterFieldCopy({ value, schema }) {
  if (!value) return null
  if (typeof value !== "number" && typeof value !== "string") return null
  if (schema.format === "date") return <CopyToClipboard text={value} color="white" />

  if (!schema.enum) return null

  const indexOfValueInSchemaEnum = schema.enum.findIndex((el) => el === value)
  const actualValue = schema.enumNames ? schema.enumNames[indexOfValueInSchemaEnum] : value

  return <CopyToClipboard text={actualValue} color="white" />
}

function ColFlexWithCopyField({ children, copyField }) {
  return (
    <div className="copy-field-col-flex">
      <div className="copy-field-col-fullwidth">{children}</div>
      {copyField}
    </div>
  )
}

export default function EcosuiteFieldTemplate(props) {
  let { id, classNames, label, required, errors, children, help, description } = props

  if (props.uiSchema["ui:custom"]) {
    children = props.uiSchema["ui:custom"].call(props, props)
  }

  let link = props.uiSchema["ui:link"]
  if (link) {
    description = (
      <React.Fragment>
        {description.props.description}
        <a href={link} target="_blank" rel="noreferrer noopener">
          {link}
        </a>
      </React.Fragment>
    )
  }

  const renderDescription = ({ type, props }) => {
    const { children, ...attributes } = props
    return React.createElement(type, attributes, children)
  }

  // By default the form has an ID of root, where multiple forms are present we prefix them with ecogy_<asset id>
  if (id && (id === "root" || (id.startsWith("ecogy_") && id.lastIndexOf("_") === 5))) {
    return (
      <div className="ecogy-form-content">
        <div className={classNames}>
          <Col>
            <ColFlexWithCopyField copyField={<RegisterFieldCopy value={props.formData} schema={props.schema} />}>
              {children}
            </ColFlexWithCopyField>
          </Col>
          {errors}
        </div>
      </div>
    )
  } else {
    const booleanUiOptions = props.schema.type == "boolean" ? { label: false, labelPlaceholder: true } : null
    const uiOptions = { ...props.uiSchema["ui:options"], ...booleanUiOptions }

    if (children && children.props && children.props.schema && children.props.schema.type === "object") {
      // Note: react-jsonschema-form doesn't currently support Bootstrap 4 which we are using, as such components such as Arrays won't render properly (no glyphicons in Bootstrap 4)
      if (uiOptions && uiOptions.inline) {
        // Display the form inline
        return (
          <div className={"field-object-inline " + classNames}>
            <ColFlexWithCopyField copyField={<RegisterFieldCopy value={props.formData} schema={props.schema} />}>
              {children}
            </ColFlexWithCopyField>
            {renderAddons(props)}
            {renderWarnings(props)}
            {help}
            {description}
            {errors}
          </div>
        )
      } else {
        return (
          <div className={"row " + classNames}>
            <Col>
              <ColFlexWithCopyField copyField={<RegisterFieldCopy value={props.formData} schema={props.schema} />}>
                {children}
              </ColFlexWithCopyField>
              {renderAddons(props)}
              {renderWarnings(props)}
              {help}
              {description}
              {errors}
            </Col>
          </div>
        )
      }
    } else {
      if (uiOptions && uiOptions.inline) {
        // Display the form inline
        return (
          <div className={"field-inline " + classNames + (uiOptions.inlineProperties ? " inline-properties" : "")}>
            {uiOptions.label !== false ? (
              <label>
                {label ? t(`formLabels.${label}`) : ""}
                {required ? "*: " : ": "}
                {renderSeflLink(props)}
              </label>
            ) : null}
            <ColFlexWithCopyField copyField={<RegisterFieldCopy value={props.formData} schema={props.schema} />}>
              {children}
            </ColFlexWithCopyField>
            {renderAddons(props)}
            {renderWarnings(props)}
            {help}
            {description}
            {errors}
          </div>
        )
      } else if (uiOptions && "label" in uiOptions && !uiOptions.label && !uiOptions.labelPlaceholder) {
        // Exclude the label entirely, only render the content
        return (
          <div className={classNames + (uiOptions.inlineProperties ? " inline-properties" : "")}>
            <WithToolTip tooltip={uiOptions.toolTip ? t(uiOptions.toolTip) : undefined}>
              <ColFlexWithCopyField copyField={<RegisterFieldCopy value={props.formData} schema={props.schema} />}>
                {children}
              </ColFlexWithCopyField>
            </WithToolTip>

            {renderAddons(props)}
            {renderWarnings(props)}
            {help}
            {uiOptions.description !== false ? description : null}
            {errors}
          </div>
        )
      } else if (uiOptions && "label" in uiOptions && !uiOptions.label && uiOptions.labelPlaceholder) {
        // Space out where the label would be but don't render the label itself
        let labelColumns = uiOptions && uiOptions.labelColumns ? uiOptions.labelColumns : 2
        let contentColumns = uiOptions && uiOptions.contentColumns ? uiOptions.contentColumns : 10
        return (
          <div className={"row " + classNames + (uiOptions.inlineProperties ? " inline-properties" : "")}>
            <Col sm={labelColumns} />
            <Col sm={contentColumns}>
              <ColFlexWithCopyField copyField={<RegisterFieldCopy value={props.formData} schema={props.schema} />}>
                {children}
              </ColFlexWithCopyField>

              {renderAddons(props)}
              {renderWarnings(props)}
              {help}
              {uiOptions.description !== false ? description : null}
              {errors}
            </Col>
          </div>
        )
      } else {
        // Render the label
        let labelColumns = uiOptions && uiOptions.labelColumns ? uiOptions.labelColumns : 2
        let contentColumns = uiOptions && uiOptions.contentColumns ? uiOptions.contentColumns : 10
        const descriptionContent =
          description.props.description && typeof description.props.description === "object"
            ? renderDescription({ ...description?.props?.description })
            : description.props.description && typeof description.props.description !== "object"
              ? t(`formDesc.${description?.props?.description}`)
              : ""

        return (
          <div className={"row " + classNames + (uiOptions && uiOptions.inlineProperties ? " inline-properties" : "")}>
            <Label sm={labelColumns} htmlFor={id} className="control-label">
              {label ? t(`formLabels.${label}`) : ""}
              {required ? "*" : null}
              {renderSeflLink(props)}
            </Label>
            <Col sm={contentColumns}>
              <ColFlexWithCopyField copyField={<RegisterFieldCopy value={props.formData} schema={props.schema} />}>
                <WithToolTip tooltip={uiOptions.toolTip ? t(uiOptions.toolTip) : undefined}>{children}</WithToolTip>
              </ColFlexWithCopyField>
              {renderAddons(props)}
              <div>
                {renderWarnings(props) ? (
                  <WithToolTip tooltip={uiOptions.warningToolTip ? t(uiOptions.warningToolTip) : undefined}>
                    {renderWarnings(props)}
                  </WithToolTip>
                ) : null}
              </div>
              {help}
              <div>{descriptionContent}</div>
              {errors}
            </Col>
          </div>
        )
      }
    }
  }
}

const renderSeflLink = (props) => {
  let linkself = props.uiSchema["ui:linkself"]
  if (linkself && props.children) {
    try {
      const url = props.children.props.children[0].props.formData
      new URL(url) // check that we have a valid URL
      return (
        <a href={url} target="_blank" rel="noreferrer noopener" className="float-end">
          <Icon icon="open_in_new" title={t("labels.open_in_new_window")} />
        </a>
      )
    } catch (err) {
      // Ignore invalid URL
      return <Icon icon="open_in_new" className="float-end" title={t("labels.open_in_new_window")} disabled />
    }
  }
}

const renderAddons = (props) => {
  const addon = props.uiSchema["ui:addon"]
  if (!addon || !addon.component) return null
  return addon.component(props.formData, props.onChange)
}

/**
 * dateWarning and sizeWarning are combined into this one function to remove clutter
 * @param {Object} props - the props that are passed into the Ecosuite Form
 * @returns {Component} - the warning component that needs to be rendered
 */
const renderWarnings = (props) => {
  const { id, schema, required, formContext } = props
  const systemInfo = formContext.systemInfo

  if (props.uiSchema["warning:custom"]) {
    return renderCustomWarning(props.uiSchema["warning:custom"], props.formData, formContext, props.id)
  }

  if (props.uiSchema["warning:match"]) {
    const warning = renderMatchWarning(props.uiSchema["warning:match"], props.formData, formContext, props.onChange)
    if (warning) {
      return warning
    }
  }

  if (schema.format == "date") {
    return renderDateWarning(props.formData, formContext)
  } else if (systemInfo && required && id.includes(`${systemInfo.systemCode}_dcSize`)) {
    return renderSizeWarning(systemInfo.dcSizeWarning, "DC")
  } else if (systemInfo && required && id.includes(`${systemInfo.systemCode}_acSize`)) {
    return renderSizeWarning(systemInfo.acSizeWarning, "AC")
  } else {
    return undefined
  }
}

const renderCustomWarning = (config, formData, formContext, path) => {
  const rootState = formContext[config.contextKey]

  const parsedPath = path
    .split("_")
    .map((segment) => {
      if (!isNaN(segment)) {
        return parseInt(segment)
      }
      return segment
    })
    .filter((p) => p !== "root")

  const getPathData = (root, pathArray) => {
    return pathArray.reduce((data, pathPart) => data && data[pathPart], root)
  }

  const getParentFunctionGenerator = (path = parsedPath) => {
    if (!path || !path.length) return null
    const parentParsedPath = [...path]
    parentParsedPath.pop()
    return Object.assign(getPathData(rootState, parentParsedPath) || {}, {
      getParent: () => getParentFunctionGenerator(parentParsedPath),
    })
  }

  Object.assign(formContext, {
    getParent: getParentFunctionGenerator,
  })

  if (config.textElementRenderer) {
    return config.textElementRenderer(formData, formContext)
  }

  return null
}

/**
 * We add a custom "warning:match" option to the UI Schema, allowing properties to be checked against other object provided in the context.
 * The "warning:match" consists of:
 * - contextKey: The key in the context that identifies the object to check
 * - property: The property on the context object to check against
 * @param {*} match The "warning:match" configuration
 * @param {*} formData The data in the form to check
 * @param {*} formContext The form context to retrieve the value to check against
 * @returns
 */
const renderMatchWarning = (match, formData, formContext, onChange) => {
  let formContextValue = formContext[match.contextKey]

  if (match.contextKey === "__self__") {
    formContextValue = formContext
  }

  const expected = formContextValue[match.property]
  const formattedExpected = match.valueFormatter ? match.valueFormatter(expected) : expected

  if (formData !== formattedExpected) {
    return (
      <>
        <Icon
          icon="error"
          className="date-warning"
          onClick={() => {
            if (match.setValueToComparedValue) {
              NiceModal.show(ConfirmDialog, {
                size: "md",
                title: `Do you want to update current value with ${startCase(match.property)}`,
                message: <div>Are you sure you want to update current value with {startCase(match.property)}?</div>,
                confirmText: "Confirm",
                cancelText: "Cancel",
                onConfirm: () => onChange(formContext[match.property]),
              })
            }
          }}
        />
        <span>
          {t("messages.unmatched_key", {
            key: match.contextKey === "__self__" ? startCase(match.property) : match.contextKey,
            formattedExpected: formattedExpected,
          })}
        </span>
      </>
    )
  }
}

const renderDateWarning = (formData, formContext) => {
  const inputDate = formData ? +moment(formData).format("YYYY") : undefined
  const startDate = formContext.startDate ? +moment(formContext.startDate).format("YYYY") : undefined
  if (inputDate && startDate && (inputDate < startDate || inputDate > startDate + PROJECT_EXPECTANCY)) {
    return (
      <>
        <Icon icon="error" className="date-warning" />
        <span>
          {inputDate < startDate
            ? `${
                formContext.proForma
                  ? `${t("messages.start_date_before_cashflow")}`
                  : `${t("messages.start_date_before_project")}`
              }`
            : `${t("messages.end_date_explained")}`}
        </span>
      </>
    )
  }
}

const renderSizeWarning = (sizeWarning, key) => {
  if (sizeWarning) {
    return (
      <>
        <Icon icon="error" className="size-warning" />
        <span>{t("notes.size_combined", { key: key })}</span>
      </>
    )
  }
}
