import { JSONSchema7 } from "json-schema"
import { cloneDeep } from "lodash"

/**
 * Returns a new JSONSchema7 object with specified fields removed from its properties.
 *
 * @param {Array<string>} fields - An array of field names to be removed from the schema's properties.
 * @param {JSONSchema7} schema - The JSON schema to modify.
 * @returns {JSONSchema7} A new JSON schema with specified fields removed from its properties, or the original schema if no changes were made.
 */
export default function hideSchemaFields(fields: Array<string>, schema: JSONSchema7): JSONSchema7 {
  if (!fields || !schema || !schema.properties) return schema
  const _schema = cloneDeep(schema)
  fields.forEach((field) => {
    if (_schema.required?.includes(field)) {
      // eslint-disable-next-line no-console
      console.error(`Cannot hide field ${field} from ${_schema.$id} because it is a required property`)
    } else {
      if (_schema.properties) {
        delete _schema.properties[field]
      }
    }
  })
  return _schema
}
