import React from "react"
import PropTypes from "prop-types"
import i18n from "src/i18n"
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap"
import Datetime from "react-datetime"
import Moment from "moment"
import { extendMoment } from "moment-range"

import EcosuiteComponent from "@common/EcosuiteComponent"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import Aggregations from "@common/Aggregations"
import Icon from "@common/display/Icon"

const { t } = i18n
const moment = extendMoment(Moment)
const PRECANNED_RANGES = {
  allTime: "All Time",
  lastYear: "Last Year",
  last12Months: "Last 12 Months",
  thisYear: "This Year",
  lastMonth: "Last Month",
  thisMonth: "This Month",
  thisWeek: "This Week",
  last7Days: "Last 7 Days",
  yesterday: "Yesterday",
  today: "Today",
}

const CUSTOM_RANGE_NAME = "custom"

class CustomRange extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.handleChangeStart = this.handleChangeStart.bind(this)
    this.handleChangeEnd = this.handleChangeEnd.bind(this)
    this.selectCustomDateRange = this.selectCustomDateRange.bind(this)
    this.isValidDate = this.isValidDate.bind(this)
  }

  isValidDate(currentDate) {
    if (this.props.restrictions) {
      if (this.props.restrictions.maximumDate) {
        return currentDate.isSameOrBefore(moment(this.props.restrictions.maximumDate))
      }

      if (this.props.restrictions.minimumDate) {
        return currentDate.isSameOrAfter(moment(this.props.restrictions.minimumDate))
      }
    }
    return true
  }

  handleChangeStart(startDate) {
    if (typeof startDate === "object") {
      // startDate will be a string if the date isn't valid
      let range = this.props.customRange ? this.props.customRange : moment.range(startDate, startDate)
      range.start = startDate
      if (this.isRangeValid(range)) {
        this.props.updateCustomRange(range)
      }
    }
  }

  handleChangeEnd(endDate) {
    if (typeof endDate === "object") {
      // endDate will be a string if the date isn't valid
      let range = this.props.customRange ? this.props.customRange : moment.range(endDate, endDate)
      range.end = endDate
      if (this.isRangeValid(range)) {
        this.props.updateCustomRange(range)
      }
    }
  }

  isRangeValid(range) {
    this.setState({ rangeWarning: null })
    if (range && range.start && range.end && moment.isRange(range) && range.end.isBefore(range.start)) {
      this.setState({ rangeWarning: `${t("warnings.range_warning")}` })
      return false
    }
    return true
  }

  getDateFormat(showTimeSelect) {
    return showTimeSelect ? "lll" : "ll"
  }

  isShowTimeForRange(range) {
    return this.props.showTime && DateRangeUtils.isShowTimeForRange(range)
  }

  getDateFormatForRange(range) {
    const showTimeSelect = this.isShowTimeForRange(range)
    return this.getDateFormat(showTimeSelect)
  }

  getTimeFormatForRange(range) {
    const showTimeSelect = this.isShowTimeForRange(range)
    if (showTimeSelect) {
      let aggregation = DateRangeUtils.getAggregateForRange(range)
      if (aggregation === Aggregations.None) {
        return "hh:mm:ss a"
      } else if (aggregation === Aggregations.FiveMinute) {
        return "hh:mm a"
      } else if (aggregation === Aggregations.Hour) {
        return "hh a"
      } else {
        throw Error(`${t("errors.unsupported_time_format")}: ${aggregation}`)
      }
    }

    return false
  }

  getCustomRangeName(precannedRanges) {
    if (precannedRanges[this.props.rangeName]) {
      return precannedRanges[this.props.rangeName]
    } else if (this.props.rangeName === CUSTOM_RANGE_NAME && this.props.range) {
      return this.renderRange(this.props.range)
    } else {
      const range = DateRangeUtils.getPrecannedRange(this.props.rangeName)
      if (range) {
        return this.renderRange(range)
      } else {
        return this.renderRange(this.props.customRange)
      }
    }
  }

  selectCustomDateRange() {
    let range = this.props.customRange ? this.props.customRange : this.props.range
    this.props.selectRange(CUSTOM_RANGE_NAME, range)
    this.props.toggle()
  }

  renderRange(range) {
    let dateFormat = this.getDateFormatForRange(range)
    return (
      <span className="custom-range-name">
        <Icon icon="calendar_today" />
        <span className="range">
          <div className="range-start">{range.start.format(dateFormat)}</div>
          <div className="range-divider">{" to "}</div>
          <div className="range-end">{range.end.format(dateFormat)}</div>
        </span>
      </span>
    )
  }

  render() {
    let range = this.props.customRange ? this.props.customRange : this.props.range
    const timeFormat = this.getTimeFormatForRange(range)
    const precannedRanges = this.props.precannedRanges ? this.props.precannedRanges : PRECANNED_RANGES

    return (
      <ButtonDropdown isOpen={this.props.isOpen} toggle={this.props.toggle} className="">
        <DropdownToggle
          caret
          disabled={this.props.disabled}
          className={"custom-range-button " + (Object.keys(precannedRanges).includes(this.props.rangeName) || this.props.rangeName === CUSTOM_RANGE_NAME ? "selected" : null)}
        >
          {this.getCustomRangeName(precannedRanges)}
        </DropdownToggle>
        <DropdownMenu className="date-range-menu" end={this.props.end}>
          <div className="custom-date-range">
            {/* EP-1680 Disabling the date input for now */}
            <div className="custom-date">
              <Datetime
                value={range.start}
                dateFormat="ll"
                timeFormat={timeFormat}
                onChange={this.handleChangeStart}
                open={true}
                isValidDate={this.isValidDate}
                renderInput={(props) => {
                  return <Input {...props} readOnly={true} disabled={true} />
                }}
              />
            </div>
            <div className="custom-date">
              <Datetime
                value={range.end}
                dateFormat="ll"
                timeFormat={timeFormat}
                onChange={this.handleChangeEnd}
                open={true}
                isValidDate={this.isValidDate}
                renderInput={(props) => {
                  return <Input {...props} readOnly={true} disabled={true} />
                }}
              />
            </div>

            <div className="date-range-buttons">
              <Button onClick={this.selectCustomDateRange} color="primary" className="date-range-button">
                {t("buttons.select_range")}
              </Button>
            </div>
          </div>

          <div className="precanned-date-ranges">
            {Object.keys(precannedRanges).map((rangeName) => {
              return (
                <DropdownItem
                  key={rangeName}
                  className={this.props.rangeName === rangeName ? "selected" : null}
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.selectRange(rangeName)
                  }}
                >
                  {t(`dates.${precannedRanges[rangeName]}`)}
                </DropdownItem>
              )
            })}
          </div>

          {this.state.rangeWarning ? (
            <div className="date-range-warning">
              <Icon icon="warning" /> {this.state.rangeWarning}
            </div>
          ) : null}
        </DropdownMenu>
      </ButtonDropdown>
    )
  }
}

CustomRange.propTypes = {
  rangeName: PropTypes.string,
}

export default CustomRange
