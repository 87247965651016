import React from "react"
import Tree, { TreeNode } from "rc-tree"

import Logger from "@common/Logger"

import EnergyService from "@dashboard/energy/EnergyService"

import SourceUtils, { DEVICE_TYPE_PYRANOMETER, SEPARATOR } from "./SourceUtils"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import "rc-tree/assets/index.css"

const DEFAULT_PROPERTIES = [
  "voltage",
  "current",
  "watts",
  "frequency",
  "powerFactor",
  "apparentPower",
  "reactivePower",
  "wattHours",
]
const DEFAULT_IRRADIANCE_PROPERTIES = ["irradiance", "irradianceHours"]

class PowerUserSourcesTree extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = { acceptedKeys: [], sourceIds: [] }

    this.onLoadData = this.onLoadData.bind(this)
    this.onTreeNodeChecked = this.onTreeNodeChecked.bind(this)
    this.getSelectedProjectNodes = this.getSelectedProjectNodes.bind(this)
    this.getTreeNodes = this.getTreeNodes.bind(this)
    this.getSourcePropertiesAsTreeNodes = this.getSourcePropertiesAsTreeNodes.bind(this)
  }

  componentDidMount() {
    this.selectProjectNodes()
  }

  componentDidUpdate(prevProps) {
    if (this.props.projects !== prevProps.projects || this.props.propertiesFilter !== prevProps.propertiesFilter) {
      Logger.info("componentDidUpdate: acceptedKeys = " + JSON.stringify(this.state.acceptedKeys))
      const acceptedKeys = this.state.acceptedKeys.filter((key) => {
        const parts = key.split("/")
        return this.props.propertiesFilter.has(parts[parts.length - 1])
      })
      if (this.props.projects !== prevProps.projects || acceptedKeys.length !== this.state.acceptedKeys.length) {
        this.setState(
          {
            selectedProjectNodes: undefined,
            allSourceIds: undefined,
            acceptedKeys: acceptedKeys,
          },
          () => {
            this.selectProjectNodes()
          },
        )
      }
    } else if (!this.state.selectedProjectNodes) {
      this.selectProjectNodes()
    }
  }

  selectProjectNodes() {
    if (this.props.projects) {
      /** @type {Object.<string, object>} */
      const projectsMap = {}
      this.props.projects.forEach((project) => {
        projectsMap[project.code] = project
      })

      EnergyService.getDevices().then((response) => {
        this.setState(
          {
            projects: projectsMap,
            selectedProjectNodes: this.getSelectedProjectNodes(projectsMap, response),
            allSourceIds: response,
          },
          () => {
            this.props.selectProperties(this.state.sourceIds, this.state.acceptedKeys)
          },
        )
      })
    }
  }

  /**
   * For a source gets the property nodes that should be displayed.
   *
   * @param {string} sourceKey The source key in the form /<project>/<site>/<system>/<device type>/<device id>
   * @returns Array.<TreeNode> an array of TreeNodes, one for each property of the specified source
   */
  getSourcePropertiesAsTreeNodes(sourceKey) {
    /** @type Set.<String> */
    const propertiesSet = this.props.availableProperties[sourceKey]
    /** @type Array<String> */

    let properties = []
    if (this.props.filterByProperties) {
      properties = propertiesSet
        ? Array.from(propertiesSet).filter((property) => this.props.propertiesFilter.has(property))
        : []
    } else {
      properties = propertiesSet ? Array.from(propertiesSet) : this.getDefaultProperties(sourceKey)
    }

    properties.sort()

    return properties.map((property) => (
      <TreeNode
        title={property}
        key={sourceKey + "/" + property}
        isLeaf={true}
        onSelect={null}
        icon={null}
        style={null}
        switcherIcon={null}
      />
    ))
  }

  getDefaultProperties(sourceKey) {
    let deviceType = SourceUtils.getDeviceType(sourceKey)
    if (deviceType === DEVICE_TYPE_PYRANOMETER) {
      return DEFAULT_IRRADIANCE_PROPERTIES
    } else {
      return DEFAULT_PROPERTIES
    }
  }

  /**
   * Takes an array of selected projects and returns a corresponding map of project codes to projects
   * @param {Object.<String,{code: String}}>} projects
   * @param {Object.<String,{sites: Object<String, {systems: Object<String, {nodes: Object<String, {id: String, devices: Array<String>}>}>}>}>} allSourceIds
   * @returns Object.<String,{sites: Object<String, {systems: Object<String, {nodes: Object<String, {id: String, devices: Array<String>}>}>}>}>
   *  side effects: none
   */
  getSelectedProjectNodes(projects, allSourceIds) {
    if (!allSourceIds) {
      allSourceIds = this.state.allSourceIds
      if (!allSourceIds) {
        return {}
      }
    }
    /** @type Object.<String,{sites: Object<String, {systems: Object<String, {nodes: Object<String, {id: String, devices: Array<String>}>}>}>}> */
    var selectedProjectNodes = {}
    Object.values(projects).forEach((project) => {
      /** @type {{sites: Object<String, {systems: Object<String, {nodes: Object<String, {id: String, devices: Array<String>}>}>}>}} */
      const projectWithNodes = allSourceIds[project.code]
      if (projectWithNodes) {
        selectedProjectNodes[project.code] = projectWithNodes
      }
    })
    return selectedProjectNodes
  }

  replacer(key, value) {
    if (Object.getPrototypeOf(value) === Set.prototype) {
      return Array.from(value)
    }
    return value
  }

  /* returns the projects, sites, systems, nodes and sources rendered as a tree */
  getTreeNodes() {
    if (!this.state.selectedProjectNodes || !this.state.projects || !Object.keys(this.state.projects).length) {
      return []
    }
    Logger.info("getTreeNodes(): availableProperties=" + JSON.stringify(this.props.availableProperties, this.replacer))
    return Object.keys(this.state.selectedProjectNodes).map(
      /** @type {String} */ (projectId) => {
        /** @type {{sites: Object<String, {systems: Object<String, {nodes: Object<String, {id: String, devices: Array<String>}>}>}>}} */
        const projectWithNodes = this.state.selectedProjectNodes[projectId]
        /** @type {{name: String, sites: Object<String,{name: String, systems: Object<String, {name: String}>}>}} */
        const projectWithNames = this.state.projects[projectId]
        if (!projectWithNames) {
          Logger.warning("project '" + projectId + "' not found in projects")
          return ""
        }
        return (
          <TreeNode
            title={projectWithNames.name}
            key={projectId}
            onSelect={null}
            icon={null}
            style={null}
            switcherIcon={null}
          >
            {Object.keys(projectWithNodes.sites).map((siteId) => {
              /** @type {{name: String, systems: Object<String, {name: String}>}} */
              const siteWithNames = projectWithNames.sites[siteId]
              if (siteWithNames == null) {
                Logger.warning(
                  "site '" +
                    siteId +
                    "' not found for project " +
                    projectId +
                    ". This indicates sources have been recorded for sites/systems not configured in AMS.",
                )
                return ""
              }
              /** @type {{systems: Object<String, {nodes: Object<string, {id: String, devices: Array<String>}>}>}} */
              const siteWithNodes = projectWithNodes.sites[siteId]
              /** @type string */
              const sitePath = projectId + "/" + siteId
              return (
                <TreeNode
                  title={siteWithNames.name}
                  key={sitePath}
                  onSelect={null}
                  icon={null}
                  style={null}
                  switcherIcon={null}
                >
                  {Object.keys(siteWithNodes.systems).map((systemId) => {
                    /** @type {{nodes: Object<String, {id: String, devices: Array<String>}>}} */
                    const system = siteWithNodes.systems[systemId]
                    /** @type {{name: String}} */
                    const systemWithName = siteWithNames.systems[systemId]
                    if (!systemWithName) {
                      return ""
                    }
                    /** @type string */
                    const systemPath = sitePath + "/" + systemId
                    return (
                      <TreeNode
                        title={systemWithName.name}
                        key={systemPath}
                        onSelect={null}
                        icon={null}
                        style={null}
                        switcherIcon={null}
                      >
                        {Object.values(system.nodes).map((node) => {
                          /** @type string */
                          const nodePath = node.id + "/" + systemPath
                          return (
                            <TreeNode
                              title={"node " + node.id}
                              key={nodePath}
                              onSelect={null}
                              icon={null}
                              style={null}
                              switcherIcon={null}
                            >
                              {node.devices.map((device) => {
                                /** @type string */
                                const key = nodePath + "/" + device
                                return (
                                  <TreeNode
                                    title={device}
                                    key={key}
                                    onSelect={null}
                                    icon={null}
                                    style={null}
                                    switcherIcon={null}
                                  >
                                    {this.getSourcePropertiesAsTreeNodes(key)}
                                  </TreeNode>
                                )
                              })}
                            </TreeNode>
                          )
                        })}
                      </TreeNode>
                    )
                  })}
                </TreeNode>
              )
            })}
          </TreeNode>
        )
      },
    )
  }
  /** @param {Array.<String>} checkedKeys }
   * @return {void}
   */
  onTreeNodeChecked(checkedKeys) {
    /** @type{Array.<string>} */
    var acceptedKeys = []
    /** @type{Array.<string>} */
    var newSourceIds = []
    /** @type{Array.<string>} */
    var sourceIds = []

    checkedKeys.forEach((checkedKey) => {
      /** @type number */
      const firstSlashPos = checkedKey.indexOf(SEPARATOR)
      if (firstSlashPos > 0) {
        /** @type boolean */
        const isProperty = SourceUtils.isProperty(checkedKey)
        /** @type number */
        const endPos = isProperty ? checkedKey.lastIndexOf(SEPARATOR) : checkedKey.length
        /** @type string */
        const sourceId = checkedKey.substring(firstSlashPos, endPos)

        // Keep track of the sources that have been selected to determine if the DEFAULT selection should be made (rather than the default ALL)
        if (SourceUtils.isDevice(sourceId) && !sourceIds.includes(sourceId)) {
          sourceIds.push(sourceId)
          if (!this.state.sourceIds || !this.state.sourceIds.includes(sourceId)) {
            newSourceIds.push(sourceId)
          }
        }
        acceptedKeys.push(checkedKey)
      }
    })

    // We notify the parent component about the sources and keys that have been selected
    this.props.selectProperties(sourceIds, acceptedKeys)
    // We also keep track of the selections for comparison later
    this.setState({
      acceptedKeys: acceptedKeys,
      sourceIds: sourceIds,
      selectedProjectNodes: this.getSelectedProjectNodes(this.state.projects, undefined),
    })
  }

  isDefaultPropertyKey() {
    return
  }

  onLoadData() {
    return new Promise((resolve) => {
      resolve()
    })
  }

  renderContent() {
    if (!this.state.allSourceIds) {
      return <Loading />
    }
    return (
      <Tree
        showIcon={false}
        className="nodes-tree"
        selectable={false}
        checkable
        onCheck={this.onTreeNodeChecked}
        onExpand={this.props.onExpand}
        checkedKeys={this.props.checkedKeys}
        loadData={this.onLoadData}
      >
        {this.getTreeNodes()}
      </Tree>
    )
  }
}

export default PowerUserSourcesTree
