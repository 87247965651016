import React, { Component } from "react"

import { NavLink } from "react-router-dom"
import { Badge } from "reactstrap"

import ProjectMenu from "@common/project/ProjectMenu"
import EcosuiteComponent from "@common/EcosuiteComponent"
import * as Tracker from "@common/utils/TrackingUtils"
import Settings from "@common/Settings"
import i18n from "src/i18n"
import logo from "src/img/ecosuite_logo.png"

const { t } = i18n
const WIKI_URL = "https://docs.ecosuite.io/release-notes"

export default class SideBar extends Component {
  render() {
    return (
      <div className="App-sidebar">
        <ProjectMenu {...this.props} showFilters={true} />
        <ModuleSelector groups={this.props.groups} version={this.props.version} moduleViews={this.props.moduleViews} />
      </div>
    )
  }
}

export class ModuleSelector extends EcosuiteComponent {
  constructor(props) {
    super(props)

    const [releaseNumber, versionNumber /*, patchNumber */] = this.props.version.split(".")
    this.state.versionUrl = `${releaseNumber}.${versionNumber}`
    this.state.lastCheckVersion = Settings.getSetting("last-checked-version", this.props.version)
  }

  checkAccess(moduleId) {
    const moduleViews = this.props.moduleViews[moduleId]
    if (!moduleViews) {
      return false
    }

    return moduleViews.portfolio.views.length || moduleViews.project.views.length
  }

  render() {
    return (
      <div className="nav">
        {this.checkAccess("event") ? (
          <NavLink
            className="content-selector"
            to="/events"
            onClick={() => {
              Tracker.emit({ name: "Module: events" })
            }}
          >
            {t("views.Events")}
          </NavLink>
        ) : null}
        {this.checkAccess("energy") ? (
          <NavLink
            className="content-selector"
            to="/energy"
            onClick={() => {
              Tracker.emit({ name: "Module: energy" })
            }}
          >
            {t("views.Energy")}
          </NavLink>
        ) : null}
        {this.checkAccess("demandresponse") ? (
          <NavLink
            className="content-selector"
            to="/demandresponse"
            onClick={() => {
              Tracker.emit({ name: "Module: demand response" })
            }}
          >
            {t("views.Demand Response")}
          </NavLink>
        ) : null}
        {this.checkAccess("connectivity") ? (
          <NavLink
            className="content-selector"
            to="/connectivity"
            onClick={() => {
              Tracker.emit({ name: "Module: connectivity" })
            }}
          >
            {t("views.Connectivity")}
          </NavLink>
        ) : null}
        {this.checkAccess("finance") ? (
          <NavLink
            className="content-selector"
            to="/economics"
            onClick={() => {
              Tracker.emit({ name: "Module: finance" })
            }}
          >
            {t("views.Economics")}
          </NavLink>
        ) : null}
        {this.checkAccess("data") ? (
          <NavLink
            className="content-selector"
            to="/data"
            onClick={() => {
              Tracker.emit({ name: "Module: assets" })
            }}
          >
            {t("views.Data")}
          </NavLink>
        ) : null}
        {this.checkAccess("process") ? (
          <NavLink
            className="content-selector"
            to="/forge"
            onClick={() => {
              Tracker.emit({ name: "Module: forge" })
            }}
          >
            {t("views.Forge")}
          </NavLink>
        ) : null}

        <img src={logo} alt="logo" style={{ marginRight: 20, marginBottom: "-16px" }} height="40px" />
        <a
          href={`${WIKI_URL}`}
          target="_blank"
          rel="noreferrer"
          className="build"
          onClick={() => {
            this.setStateIfMounted({ lastCheckVersion: this.props.version })
            Settings.setSetting("last-checked-version", this.props.version)
          }}
        >
          {"v" + this.props.version}
          {this.state.lastCheckVersion !== this.props.version && (
            <Badge className="build__new-version" color="primary" pill>
              {t("labels.NEW")}
            </Badge>
          )}
        </a>
      </div>
    )
  }
}
