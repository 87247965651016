import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { CognitoUser } from "@aws-amplify/auth"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "src/components/ui/card"
import { Input } from "src/components/ui/input"
import { Button } from "src/components/ui/button"
import { Label } from "src/components/ui/label"
import { Alert, AlertDescription } from "src/components/ui/alert"
import Header, { ThemeProvider } from "../Components/Header"
import LoadingButton from "src/components/ui/loading-button"
import Logo from "src/img/ecosuite_logo.png"
import "./onboarding.scss"
import { useHistory } from "react-router-dom"
import { PasswordInput } from "src/components/ui/password-input"

enum LoginErrorCode {
  UserNotConfirmed = "UserNotConfirmedException",
  NotAuthorized = "NotAuthorizedException",
  UserNotFound = "UserNotFoundException",
}

enum ChallengeType {
  SMS_MFA = "SMS_MFA",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
}

type LoginError = {
  code: LoginErrorCode | string
  message: string
}

const handleLoginError = (error: unknown): string => {
  const loginError = error as LoginError
  switch (loginError.code) {
    case LoginErrorCode.UserNotConfirmed:
      return "Please confirm your account before logging in."
    case LoginErrorCode.NotAuthorized:
      return "Incorrect username or password."
    case LoginErrorCode.UserNotFound:
      return "User not found. Please check your username."
    default:
      return loginError.message || "An error occurred during login."
  }
}

const isMFAUser = (user: CognitoUser): boolean =>
  user.challengeName === ChallengeType.SMS_MFA || user.challengeName === ChallengeType.SOFTWARE_TOKEN_MFA

interface LoginFormData {
  username: string
  password: string
}

interface NewPasswordFormData {
  newPassword: string
  confirmPassword: string
}

const useLoginForm = (initialState: LoginFormData) => {
  const [formData, setFormData] = useState<LoginFormData>(initialState)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
  }
  return { formData, handleChange }
}

const useNewPasswordForm = (initialState: NewPasswordFormData) => {
  const [formData, setFormData] = useState<NewPasswordFormData>(initialState)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
  }
  return { formData, handleChange }
}

interface LoginFormProps {
  onSubmit: (username: string, password: string) => Promise<void>
  isLoading: boolean
  error: string
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, isLoading, error }) => {
  const { formData, handleChange } = useLoginForm({ username: "", password: "" })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(formData.username, formData.password)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="tw-grid tw-w-full tw-items-center tw-gap-4">
        <div className="tw-flex tw-flex-col tw-space-y-1.5">
          <Label htmlFor="username">Username</Label>
          <Input
            id="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Enter your username"
            required
          />
        </div>
        <div className="tw-flex tw-flex-col tw-space-y-1.5">
          <Label htmlFor="password">Password</Label>
          <PasswordInput
            id="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            required
          />
        </div>
      </div>
      {error && (
        <Alert variant="destructive" className="tw-mt-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <LoadingButton className="tw-w-full tw-mt-4" type="submit" loading={isLoading}>
        Sign In
      </LoadingButton>
    </form>
  )
}

interface NewPasswordFormProps {
  onSubmit: (newPassword: string, confirmPassword: string) => Promise<void>
  isLoading: boolean
  error: string
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit, isLoading, error }) => {
  const { formData, handleChange } = useNewPasswordForm({ newPassword: "", confirmPassword: "" })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(formData.newPassword, formData.confirmPassword)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="tw-grid tw-w-full tw-items-center tw-gap-4">
        <div className="tw-flex tw-flex-col tw-space-y-1.5">
          <Label htmlFor="newPassword">New Password</Label>
          <PasswordInput
            id="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            placeholder="Enter your new password"
            required
          />
        </div>
        <div className="tw-flex tw-flex-col tw-space-y-1.5">
          <Label htmlFor="confirmPassword">Confirm New Password</Label>
          <PasswordInput
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm your new password"
            required
          />
        </div>
      </div>
      {error && (
        <Alert variant="destructive" className="tw-mt-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <LoadingButton className="tw-w-full tw-mt-4" type="submit" loading={isLoading}>
        Set New Password
      </LoadingButton>
    </form>
  )
}

const LoginPage: React.FC = () => {
  const [error, setError] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState<boolean>(false)
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)

  const history = useHistory()

  const handleLogin = async (username: string, password: string) => {
    setError("")
    setIsLoading(true)

    try {
      const user: CognitoUser = await Auth.signIn(username, password)
      if (isMFAUser(user)) {
        setError("Not an onboarding user. MFA is required for this account.")
      } else if (user.challengeName === ChallengeType.NEW_PASSWORD_REQUIRED) {
        setIsNewPasswordRequired(true)
        setCognitoUser(user)
      } else {
        history.push("/onboarding")
      }
    } catch (error) {
      setError(handleLoginError(error))
    } finally {
      setIsLoading(false)
    }
  }

  const handleNewPassword = async (newPassword: string, confirmPassword: string) => {
    setError("")

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match. Please try again.")
      return
    }

    setIsLoading(true)

    try {
      if (cognitoUser) {
        await Auth.completeNewPassword(cognitoUser, newPassword)
        history.push("/onboarding")
      }
    } catch (error) {
      setError("Failed to set new password. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleForgotPassword = () => {
    history.push("/onboarding/reset-password")
  }

  return (
    <ThemeProvider>
      <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-background onboarding">
        <Header logo={Logo} />
        <div className="tw-flex tw-flex-grow tw-items-center tw-justify-center">
          <Card className="tw-w-[460px]">
            <CardHeader>
              <CardTitle className="tw-text-xl tw-font-bold">
                {isNewPasswordRequired ? "Set New Password" : "Login"}
              </CardTitle>
              <CardDescription>
                {isNewPasswordRequired
                  ? "Please set a new password for your account"
                  : "Enter your credentials to access your onboarding account"}
              </CardDescription>
            </CardHeader>
            <CardContent>
              {isNewPasswordRequired ? (
                <NewPasswordForm onSubmit={handleNewPassword} isLoading={isLoading} error={error} />
              ) : (
                <LoginForm onSubmit={handleLogin} isLoading={isLoading} error={error} />
              )}
            </CardContent>
            {!isNewPasswordRequired && (
              <CardFooter className="tw-flex tw-justify-center tw-w-[100%]">
                <Button variant="link" onClick={handleForgotPassword}>
                  Forgot password?
                </Button>
              </CardFooter>
            )}
          </Card>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default LoginPage
