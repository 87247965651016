import React from "react"
import { Alert, Button, Container } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm from "@common/form/EcosuiteForm"
import Logger from "@common/Logger"

import UserAdminService from "../UserAdminService"
import UserTypeSchema from "./UserTypeSchema"
import { UserTypeSelector, UserGroupSelector } from "../widgets"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
const LAST_PAGE_OF_FORM = 1

export default class CreateUserType extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentFormPage: 0,
      currentPageSchema: null,
      formData: {},
    }
    this.createUserType = this.createUserType.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    // Get schema for User Type page and User Group page
    UserTypeSchema.getUserTypeSchema().then(([userTypeSchema, userGroupSchema]) => {
      this.setStateIfMounted({
        userTypeSchema: userTypeSchema,
        userGroupSchema: userGroupSchema,
      })
    })
  }

  // Schema display switch
  getCurrentPageSchema() {
    switch (this.state.currentFormPage) {
      case 0:
        return this.state.userTypeSchema
      case 1:
        return this.state.userGroupSchema
      default:
        return null
    }
  }

  createUserType(formData) {
    this.setState({ error: undefined, loading: true })
    UserAdminService.createUserType(formData)
      .then((response) => {
        this.props.userTypeAdded(response.userType)
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, loading: false })
      })
  }

  cancel() {
    this.props.selectUserType()
  }

  renderFormButtons() {
    switch (this.state.currentFormPage) {
      // Display these buttons when on first page of the form
      case 0:
        return (
          <Button color="primary" type="submit">
            {`${t("labels.next")}\t`}
            <Icon icon="arrow_forward" />
          </Button>
        )
      // Display these buttons when on last page of the form
      case LAST_PAGE_OF_FORM:
        return (
          <>
            <Button color="primary" type="submit" onClick={() => this.setState({ currentFormPage: this.state.currentFormPage - 1 })}>
              <Icon icon="arrow_back" />
              {`\t${t("labels.back")}`}
            </Button>
            <Button color="primary" type="submit">
              {t("buttons.submit")}
            </Button>
          </>
        )
      // Display these buttons when in the middle of the form
      default:
        return (
          <>
            <Button color="primary" type="submit" onClick={() => this.setState({ currentFormPage: this.state.currentFormPage - 1 })}>
              <Icon icon="arrow_back" />
              {`\t${t("labels.back")}`}
            </Button>
            <Button color="primary" type="submit">
              {`${t("labels.next")}\t`}
              <Icon icon="arrow_forward" />
            </Button>
          </>
        )
    }
  }

  renderContent() {
    if (this.state.loading) {
      return <Loading />
    } else if (this.state.userTypeSchema) {
      let error = this.getError()
      return (
        <Container fluid={true} className="Admin-content">
          <div className="ecogy-form admin-content-area">
            <EcosuiteForm
              schema={this.getCurrentPageSchema()}
              uiSchema={UserTypeSchema.getUserTypeUISchema()}
              formData={this.state.formData}
              onChange={this.formDataChanged}
              onSubmit={({ formData }) => {
                if (this.state.currentFormPage === LAST_PAGE_OF_FORM) {
                  this.createUserType({ ...this.state.formData, ...formData })
                } else {
                  this.setState({
                    currentFormPage: this.state.currentFormPage + 1,
                    formData: { ...this.state.formData, ...formData },
                  })
                }
              }}
              widgets={{
                userTypeSelector: UserTypeSelector,
                userGroupSelector: UserGroupSelector,
              }}
            >
              <div className="admin-footer__form-buttons">
                <Button color="danger" onClick={this.cancel}>
                  {t("buttons.cancel")}
                </Button>
                {this.renderFormButtons()}
              </div>
            </EcosuiteForm>
            {error ? <Alert color="danger">{error}</Alert> : null}
          </div>
        </Container>
      )
    } else {
      return <Loading />
    }
  }

  getError() {
    if (this.state && this.state.error) {
      return this.state.error.message ? this.state.error.message : this.state.error
    }
    return null
  }
}
