import React from "react"

import EcosuiteComponent from "@common/EcosuiteComponent"

import SolarFluxGraph from "@dashboard/energy/graphs/SolarFluxGraph"

class LiveSite extends EcosuiteComponent {
  renderContent() {
    let siteStatus = this.props.siteStatus

    return (
      <div className="site-content">
        <h4 className={"content-title " + (this.isContentValid(siteStatus) && siteStatus.status ? siteStatus.status : "no-status")}>
          {this.props.site.name} ({this.props.site.code})
        </h4>

        <SolarFluxGraph project={this.props.project} site={this.props.site} showGeneration={this.props.showGeneration} showConsumption={this.props.showConsumption} />
      </div>
    )
  }
}

export default LiveSite
