import React, { Component } from "react"
import { Form, FormGroup, Input, Label, Button, Alert } from "reactstrap"

import { Terminal } from "xterm"
import { AttachAddon } from "xterm-addon-attach"

import Logger from "@common/Logger"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"

import TerminalService from "./TerminalService"

import "xterm/css/xterm.css"
import "./ShellTerminal.css"
import i18n from "src/i18n"

const { t } = i18n
var ansiEscapes = {
  color: {
    bright: {
      gray: "\x1B[30;1m",
      green: "\x1B[32;1m",
      red: "\x1B[31;1m",
      yellow: "\x1B[33;1m",
      white: "\x1B[37;1m",
    },
  },
  reset: "\x1B[0m",
}

/**
 * Creates an SSH connection to a specififed node, providing a terminal for user entry.
 */
export default class ShellTerminal extends Component {
  render() {
    let params = new URLSearchParams(window.location.search)

    return (
      <div className="EcogyTerminal">
        <Header params={params} />
        <NodeTerminal nodeId={params.get("nodeId")} />
      </div>
    )
  }
}

class Header extends Component {
  render() {
    return (
      <div className="terminal-header">
        <label>{t("table_headings.node")}: </label>
        {this.props.params.get("nodeId")}
        {this.props.params.has("projectId") ? (
          <React.Fragment>
            <label>{t("labels.project")}: </label>
            <a href={"/energy?projectId=" + this.props.params.get("projectId")}>{this.props.params.get("projectId")}</a>
          </React.Fragment>
        ) : null}
        {this.props.params.has("sourceId") ? (
          <React.Fragment>
            <label>{t("labels.source")}: </label> {this.props.params.get("sourceId")}
          </React.Fragment>
        ) : null}
      </div>
    )
  }
}

class NodeTerminal extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.termElm = React.createRef() // used by the Terminal

    this.state.username = "" // if undefined will be trated as uncontrolled input
    this.state.password = "" // if undefined will be trated as uncontrolled input

    this.state.solarNodeUsername = "" // if undefined will be trated as uncontrolled input
    this.state.solarNodePassword = "" // if undefined will be trated as uncontrolled input

    this.startSession = this.startSession.bind(this)
    this.endSession = this.endSession.bind(this)
    this.openProxy = this.openProxy.bind(this)
    this.connect = this.connect.bind(this)
    this.solarNodeLogin = this.solarNodeLogin.bind(this)
    this.cancelSolarNodeLogin = this.cancelSolarNodeLogin.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.startSession()
  }
  componentWillUnmount() {
    this.endSession()
  }

  startSession() {
    if (this.props.nodeId) {
      this.setStateIfMounted({ error: null, loading: true, closed: false })
      TerminalService.startSession(this.props.nodeId)
        .then((json) => {
          this.setStateIfMounted({
            session: json.session,
            ssh: json.data,
            webSocketUrl: json.webSocketUrl,
            solarNodeProxyUrl: json.solarNodeProxyUrl,
            solarNodeLoginUrl: json.solarNodeLoginUrl,
            loading: false,
          })
          if (!json.started) {
            this.setStateIfMounted({
              session: null,
              ssh: null,
              webSocketUrl: null,
              solarNodeProxyUrl: null,
              solarNodeLoginUrl: null,
              error: {
                message: "Unable to establish remote SSH connection, the node may be unavailable or not support SSH.",
              },
              loading: false,
            })
          }

          if (json.data.username && json.data.password && json.started) {
            this.connect() // If we have credentials try to sign into the session automatically
          }
        })
        .catch((err) => {
          this.setStateIfMounted({ session: null, ssh: null, webSocketUrl: null, solarNodeProxyUrl: null, solarNodeLoginUrl: null, error: err, loading: false })
        })
    }
  }

  endSession() {
    if (this.state && this.state.session && this.props.nodeId) {
      TerminalService.stopSession(this.props.nodeId, this.state.session)
        .then(() => {
          this.setStateIfMounted({
            session: null,
            ssh: null,
            webSocketUrl: null,
            solarNodeProxyUrl: null,
            solarNodeLoginUrl: null,
            showSolarNodeSetupLogin: false,
            loadingSolarNodeSetup: false,
            error: null,
            loading: false,
          })
        })
        .catch((err) => {
          this.setStateIfMounted({
            session: null,
            ssh: null,
            webSocketUrl: null,
            solarNodeProxyUrl: null,
            solarNodeLoginUrl: null,
            showSolarNodeSetupLogin: false,
            loadingSolarNodeSetup: false,
            error: err,
            loading: false,
          })
        })
    }
  }

  openProxy() {
    // Load the SolarNode login URL as a race condition it may not have been successfully generated
    if (this.state.solarNodeLoginUrl) {
      Logger.debug("Using SolarNode login URL: " + this.state.solarNodeLoginUrl)
      window.open(this.state.solarNodeLoginUrl, "_blank")
    } else {
      Logger.debug("Requesting SolarNode login URL")
      this.setStateIfMounted({ loadingSolarNodeSetup: true })
      TerminalService.getSolarNodeLoginUrl(this.props.nodeId, this.state.solarNodeProxyUrl).then((json) => {
        Logger.debug("Retrieved SolarNode login URL: " + json.solarNodeLoginUrl)

        if (json.solarNodeLoginUrl) {
          this.setStateIfMounted(
            {
              solarNodeLoginUrl: json.solarNodeLoginUrl,
              loadingSolarNodeSetup: false,
            },
            () => {
              window.open(this.state.solarNodeLoginUrl, "_blank")
            },
          )
        } else {
          this.setStateIfMounted({
            showSolarNodeSetupLogin: true,
            loadingSolarNodeSetup: false,
          })
        }
      })
    }
  }

  cancelSolarNodeLogin() {
    this.setStateIfMounted({
      showSolarNodeSetupLogin: false,
    })
  }

  solarNodeLogin(e) {
    if (e) {
      e.preventDefault()
    }

    this.setStateIfMounted({ error: null, loadingSolarNodeSetup: true })

    TerminalService.setSolarNodeCredentials(this.props.nodeId, this.state.solarNodeProxyUrl, { username: this.state.solarNodeUsername, password: this.state.solarNodePassword }).then((response) => {
      if (response.solarNodeLoginUrl) {
        this.setStateIfMounted({ solarNodeLoginUrl: response.solarNodeLoginUrl, loadingSolarNodeSetup: false, showSolarNodeSetupLogin: false, error: null }, this.openProxy)
      } else {
        this.setStateIfMounted({ loadingSolarNodeSetup: false, error: { message: "Login failed" } })
      }
    })
  }

  async connect(e) {
    if (e) {
      e.preventDefault()
    }

    if (!this.term) {
      const term = new Terminal({ rows: 30 })
      term.open(this.termElm.current)
      this.term = term
    }
    const term = this.term

    const socket = new WebSocket(this.state.webSocketUrl)

    const attachAddon = new AttachAddon(socket)
    this.term.loadAddon(attachAddon)

    socket.onopen = () => {
      term.clear()
      term.writeln(ansiEscapes.color.bright.white + "Signing in ...")
      this.setStateIfMounted({ signingIn: true })

      let data = this.state.ssh
      data.term = "xterm"
      data.cols = term.cols
      data.lines = term.rows

      if (this.state.username) {
        data.username = this.state.username
      }
      if (this.state.password) {
        data.password = this.state.password
      }

      var msg = {
        cmd: "attach-ssh",
        data: data,
      }

      socket.send(JSON.stringify(msg)) // clear out the credentials
    }

    socket.onclose = (event) => {
      this.setStateIfMounted({ signingIn: false })
      if (event && event.reason === "Connection closed") {
        this.setStateIfMounted({ closed: true })

        term.dispose()
        this.endSession()
        this.term = null
      } else if (event && event.reason === "Bad credentials") {
        this.setStateIfMounted({ badCredentials: true })
        term.writeln(ansiEscapes.color.bright.red + "Invalid credentials, please try again")
        // Keep the terminal open so we can try other credentials
      } else {
        this.setStateIfMounted({ error: { message: "Connection Closed" + (event && event.reason ? ": " + event.reason : "") } })

        term.dispose()
        this.endSession()
        this.term = null
      }
    }

    socket.onmessage = (event) => {
      try {
        let data = JSON.parse(event.data)
        if (data.message === "Ready to attach") {
          term.attach(socket)
          term._initialized = true

          // Persist the credentials for future use if they've been supplied
          if (this.state.username && this.state.password) {
            this.saveCredentials(this.state.username, this.state.password)
          }

          term.writeln(ansiEscapes.color.bright.green + "Sign in successful")
          term.writeln(ansiEscapes.color.bright.white)

          // Now that we're connected we clear out the credentials
          this.setStateIfMounted({ ssh: null, username: "", password: "", badCredentials: false, signingIn: false })
        }
      } catch (e) {
        // Ignore other messages
      }
    }
  }

  saveCredentials(username, password) {
    if (username && password) {
      TerminalService.setSshCredentials(this.props.nodeId, {
        username: username,
        password: password,
      })
    }
  }

  render() {
    if (!this.props.nodeId) {
      return <Error error={{ message: `${t("errors.no_nodeId_specified")}` }} />
    }

    if (this.state.loadingSolarNodeSetup) {
      return (
        <div className="solarnode-login">
          <div className="terminal-loading">
            <Loading />
            {t("loadingMsg.connecting_to_solarNode")}: {this.props.nodeId}
          </div>
        </div>
      )
    } else if (this.state.showSolarNodeSetupLogin) {
      return this.renderSolarNodeLogin()
    } else if (this.state.loading) {
      return (
        <div className="terminal-loading">
          <Loading />
          {t("loadingMsg.connecting_toNode")}: {this.props.nodeId}
        </div>
      )
    } else if (this.state.closed) {
      return (
        <div className="ecogy-terminal">
          <div>{t("notes.connection_toNode", { nodeId: this.props.nodeId })}</div>
          {this.state.session ? null : this.renderFooter()}
        </div>
      )
    } else if (this.state.error) {
      return (
        <div className="ecogy-terminal">
          <Error error={this.state.error} />
          <Alert color="warning">
            {"If you're having trouble connecting you may need to install the SolarNetwork issued certificate in your browser: "}
            <a href="https://github.com/SolarNetworkFoundation/solarnetwork-ops/wiki/SolarNetwork-certificates#root-certificate" target="_blank" rel="noopener noreferrer">
              SolarNetwork Certificate
            </a>
            {"; or try accessing "}
            <a href="https://ssh.solarnetwork.net:8443/ssh" target="_blank" rel="noopener noreferrer">
              ssh.solarnetwork.net
            </a>
            {" and trust the SolarNetwork issued certificate."}
          </Alert>
          {this.renderFooter()}
        </div>
      )
    } else {
      return (
        <div className="ecogy-terminal">
          <div ref={this.termElm}></div>
          {this.renderFooter()}
        </div>
      )
    }
  }

  renderSolarNodeLogin() {
    return (
      <div className="solarnode-login">
        <h2>{t("headings.solarNode_setup_login")}</h2>
        <Form onSubmit={this.solarNodeLogin}>
          <FormGroup>
            <Label for="user">{t("table_headings.user")}: </Label>
            <Input
              id="user"
              type="text"
              name="user"
              required={true}
              value={this.state.solarNodeUsername}
              onChange={(e) => {
                this.setStateIfMounted({ solarNodeUsername: e.target.value })
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">{t("labels.password")}: </Label>
            <Input
              id="password"
              type="password"
              name="password"
              required={true}
              value={this.state.solarNodePassword}
              onChange={(e) => {
                this.setStateIfMounted({ solarNodePassword: e.target.value })
              }}
            />
          </FormGroup>
          <Button color="secondary" onClick={this.cancelSolarNodeLogin}>
            {t("buttons.cancel")}
          </Button>
          <Button color="primary" className="float-end">
            {t("header.userMenu.login")}
          </Button>
        </Form>
        <p>
          <a href={this.state.solarNodeProxyUrl} target="_blank" rel="noopener noreferrer">
            {t("notes.go_to_solarNode")}
          </a>
        </p>
        {this.state.error ? <Error error={this.state.error} /> : null}
      </div>
    )
  }

  renderFooter() {
    return (
      <div className="footer">
        {this.state.session ? (
          <React.Fragment>
            <Button color="primary" onClick={this.openProxy} disabled={!this.state.solarNodeProxyUrl}>
              {t("labels.solarNode_setup")}
            </Button>
            <Button color="danger" onClick={this.endSession}>
              {t("labels.end_session")}
            </Button>
          </React.Fragment>
        ) : (
          <Button color="primary" onClick={this.startSession}>
            {t("labels.start_session")}
          </Button>
        )}
        {!this.state.signingIn && this.state.ssh && (!this.state.ssh.username || this.state.badCredentials) ? (
          <Form className="float-end centre-form" onSubmit={this.connect}>
            <div className="centre-form input-wrapper">
              <Label for="user">{t("table_headings.user")}: </Label>
              <Input
                id="user"
                type="text"
                name="user"
                placeholder="solar"
                value={this.state.username}
                onChange={(e) => {
                  this.setStateIfMounted({ username: e.target.value })
                }}
              />
            </div>
            <div className="centre-form input-wrapper">
              <Label for="password">{t("labels.password")}: </Label>
              <Input
                id="password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={(e) => {
                  this.setStateIfMounted({ password: e.target.value })
                }}
              />
            </div>
            <Button>{t("header.userMenu.login")}</Button>
          </Form>
        ) : null}
      </div>
    )
  }
}
