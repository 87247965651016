import React from "react"

import { MapView } from "@common/module/EcosuiteView"

import { EnergyProjectMarker, EnergyRadiusMarker } from "./EnergyView"
import EnergyViewControls from "./EnergyViewControls"

/**
 * Expects the following props:
 *
 * projects - list of the projects that should be displayed
 * projectsStatus - list of the status of projects

 * readings - map of project readings
 */
export default class EnergyMapView extends MapView {
  renderViewControls() {
    return (
      <EnergyViewControls
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        rangeName={this.props.rangeName}
        customRange={this.props.customRange}
        project={this.props.project}
        projects={this.props.projects}
        viewControls={this.getEnergyViewControls()}
      />
    )
  }

  getEnergyViewControls() {
    return null
  }

  getMapMarkers() {
    return this.props.projects == null
      ? []
      : this.props.projects.map((project) => {
          let projectStatus = this.getProjectStatus(project.code)
          let projectReading = this.getProjectReading(project.code)

          return project.lat && project.long ? (
            <React.Fragment key={project.code}>
              <EnergyProjectMarker
                project={project}
                projectReading={projectReading}
                projectStatus={projectStatus}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                showStorage={this.props.showStorage}
              />
              <EnergyRadiusMarker
                project={project}
                projectReading={projectReading}
                readings={this.props.readings}
                projectStatus={projectStatus}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                showStorage={this.props.showStorage}
              />
            </React.Fragment>
          ) : null
        })
  }

  getProjectStatus(projectId) {
    if (this.isContentValid(this.props.projectsStatus)) {
      return this.props.projectsStatus.projects.find((projectStatus) => {
        return projectStatus.code === projectId
      })
    }
    return null
  }

  getProjectReading(projectId) {
    if (this.isContentValid(this.props.readings)) {
      return this.props.readings.projects[projectId]
    }
    return null
  }
}
