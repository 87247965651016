import React from "react"
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import ConsumptionSavings from "./ConsumptionSavings"
import { t } from "i18next"

export default class ConsumptionSavingsGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentError(this.props.predictedConsumption)) {
      return <Error error={this.props.predictedConsumption.getError()} />
    }

    if (this.isContentValid(this.props.readings) && this.isContentValid(this.props.predictedConsumption)) {
      const currentConsumption = this.props.readings ? this.props.readings.consumption / 1000 : 0
      const historicConsumption = this.props.predictedConsumption ? this.props.predictedConsumption.projectDatums.historicForecast / 1000 : 0
      const targetConsumption = this.props.predictedConsumption ? this.props.predictedConsumption.projectDatums.targetForecast / 1000 : 0

      const data = [
        { name: "Actual", value: currentConsumption, fill: GRAPH_COLORS[0] },
        { name: "Target", value: targetConsumption, fill: GRAPH_COLORS[1] },
        { name: "Historic", value: historicConsumption, fill: GRAPH_COLORS[2] },
      ]

      return (
        <div className="sidebar-graph">
          <BarChart width={300} height={220} data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Bar type="linear" dataKey="value" />

            <Tooltip
              wrapperStyle={{ color: "#000", pointerEvents: "auto" }}
              // eslint-disable-next-line no-unused-vars
              formatter={(value, name, props) => {
                return EnergyUtils.formatKiloWattHours(value)
              }}
            />
          </BarChart>
          <div className="sidebar-title">{t("energy.labels.trend_this_month")}</div>
          <ConsumptionSavings readings={this.props.readings} predictedConsumption={this.props.predictedConsumption} />
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
