import { create, all } from "mathjs"

const math = create(all)
const add = function (a, b) {
  return math.add(a ? a : 0, b ? b : 0)
}
const concat = function (a, b) {
  return (a ? a : []).concat(b ? b : [])
}

const Utils = {
  add: add,

  groupCategory(category) {
    let groupCategory = {
      name: category.name,
      categories: {},
      totals: [],
      transactions: [],
    }
    Object.keys(category.categories).forEach((subCategoryId) => {
      let subCategory = category.categories[subCategoryId]
      groupCategory.categories[subCategoryId] = {
        name: subCategory.name,
        categories: {},
        totals: [],
        transactions: [],
      }

      if (subCategory.categories) {
        Object.keys(subCategory.categories).forEach((accountId) => {
          groupCategory.categories[subCategoryId].categories[accountId] = {
            name: subCategory.categories[accountId].name,
            categories: {},
            totals: [],
            transactions: [],
          }
        })
      }
    })

    return groupCategory
  },

  addGroupTotals(mode, report, reportIdx, aggregatedReport, groupIdx, futureIdx) {
    const reportSection = report[mode]
    const aggregatedReportSection = aggregatedReport[mode]

    // Increment the existing entry
    aggregatedReportSection.netIncomes[groupIdx] = add(aggregatedReportSection.netIncomes[groupIdx], reportSection.netIncomes[reportIdx])
    aggregatedReportSection.unleveredTotals[groupIdx] = add(aggregatedReportSection.unleveredTotals[groupIdx], reportSection.unleveredTotals[reportIdx])
    aggregatedReportSection.leveredTotals[groupIdx] = add(aggregatedReportSection.leveredTotals[groupIdx], reportSection.leveredTotals[reportIdx])

    if (mode !== "actual" || (mode === "actual" && reportIdx < futureIdx)) {
      aggregatedReportSection.unleveredIrr[groupIdx] = reportSection.unleveredIrr[reportIdx]
      aggregatedReportSection.leveredIrr[groupIdx] = reportSection.leveredIrr[reportIdx]
      aggregatedReportSection.retainedEarnings[groupIdx] = reportSection.retainedEarnings[reportIdx]
    }

    Object.keys(reportSection.categories).forEach((categoryId) => {
      const category = reportSection.categories[categoryId]
      aggregatedReportSection.categories[categoryId].totals[groupIdx] = add(aggregatedReportSection.categories[categoryId].totals[groupIdx], category.totals[reportIdx])
      if (category.transactions) {
        aggregatedReportSection.categories[categoryId].transactions[groupIdx] = concat(aggregatedReportSection.categories[categoryId].transactions[groupIdx], category.transactions[reportIdx])
      }
      Object.keys(category.categories).forEach((subCategoryId) => {
        const subCategory = category.categories[subCategoryId]
        aggregatedReportSection.categories[categoryId].categories[subCategoryId].totals[groupIdx] = add(
          aggregatedReportSection.categories[categoryId].categories[subCategoryId].totals[groupIdx],
          subCategory.totals[reportIdx],
        )
        if (subCategory.transactions) {
          aggregatedReportSection.categories[categoryId].categories[subCategoryId].transactions[groupIdx] = concat(
            aggregatedReportSection.categories[categoryId].categories[subCategoryId].transactions[groupIdx],
            subCategory.transactions[reportIdx],
          )
        }
        if (subCategory.categories) {
          Object.keys(subCategory.categories).forEach((accountId) => {
            const account = subCategory.categories[accountId]
            aggregatedReportSection.categories[categoryId].categories[subCategoryId].categories[accountId].totals[groupIdx] = add(
              aggregatedReportSection.categories[categoryId].categories[subCategoryId].categories[accountId].totals[groupIdx],
              account.totals[reportIdx],
            )
            if (account.transactions) {
              aggregatedReportSection.categories[categoryId].categories[subCategoryId].categories[accountId].transactions[groupIdx] = concat(
                aggregatedReportSection.categories[categoryId].categories[subCategoryId].categories[accountId].transactions[groupIdx],
                account.transactions[reportIdx],
              )
            }
          })
        }
      })
    })
  },

  initGroupTotals(reportSection, reportIdx, aggregatedReportSection, date) {
    aggregatedReportSection.dates.push(date)
    aggregatedReportSection.netIncomes.push(reportSection.netIncomes[reportIdx])
    aggregatedReportSection.unleveredTotals.push(reportSection.unleveredTotals[reportIdx])
    aggregatedReportSection.unleveredIrr.push(reportSection.unleveredIrr[reportIdx])
    aggregatedReportSection.leveredTotals.push(reportSection.leveredTotals[reportIdx])
    aggregatedReportSection.leveredIrr.push(reportSection.leveredIrr[reportIdx])
    aggregatedReportSection.retainedEarnings.push(reportSection.retainedEarnings[reportIdx])

    Object.keys(reportSection.categories).forEach((categoryId) => {
      const category = reportSection.categories[categoryId]
      aggregatedReportSection.categories[categoryId].totals.push(category.totals[reportIdx])
      aggregatedReportSection.categories[categoryId].transactions.push(category.transactions ? category.transactions[reportIdx] : [])
      Object.keys(category.categories).forEach((subCategoryId) => {
        const subCategory = category.categories[subCategoryId]
        aggregatedReportSection.categories[categoryId].categories[subCategoryId].totals.push(subCategory.totals[reportIdx])
        aggregatedReportSection.categories[categoryId].categories[subCategoryId].transactions.push(subCategory.transactions ? subCategory.transactions[reportIdx] : [])
        if (subCategory.categories) {
          Object.keys(subCategory.categories).forEach((accountId) => {
            const account = subCategory.categories[accountId]
            aggregatedReportSection.categories[categoryId].categories[subCategoryId].categories[accountId].totals.push(account.totals[reportIdx])
            aggregatedReportSection.categories[categoryId].categories[subCategoryId].categories[accountId].transactions.push(account.transactions ? account.transactions[reportIdx] : [])
          })
        }
      })
    })
  },
}

export default Utils
