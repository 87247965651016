import React from "react"
import {
  Form,
  InputGroup,
  Input,
  Label,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupText,
} from "reactstrap"
import { ButtonToolbar, ButtonGroup, Button } from "reactstrap"

import EcosuiteComponent from "@common/EcosuiteComponent"
import TypeFilter from "@dashboard/data/TypeFilter"

import { PriorityFilter } from "@dashboard/event/EventFilters"
import Confirmation from "@common/input/button/Confirmation"
import moment from "moment"
import BackdropDialog from "@common/input/button/BackdropDialog"
import EventListView from "./EventListView"
import Utils from "@common/utils/Utils"
import { uniqBy } from "lodash"
import i18n from "src/i18n"

const { t } = i18n
export default class EventViewControls extends EcosuiteComponent {
  constructor() {
    super()
    this.state = {
      eventsTypes: [],
    }
  }
  /**
   * Returns corresponding project, system, site codes if they exist
   * @returns - { project: project.code, system: system.code, site: site.code }
   */

  componentDidMount() {
    const eventTypesWithSubtypes = this.props.events.map((evt) => {
      return {
        type: evt.type,
        subTypes: [
          ...new Set(
            this.props.events.filter((event) => event.type === evt.type).map((event) => event.subType ?? "None"),
          ),
        ],
      }
    })

    const typesWithSubtypes = uniqBy(eventTypesWithSubtypes, "type")
    this.state.eventsTypes = typesWithSubtypes
    this.setStateIfMounted({ eventsTypes: typesWithSubtypes })
  }

  getAutoFillPayload() {
    let payload = {}
    if (this.props.project) {
      payload.project = this.props.project.code
      const sites = Object.values(this.props.project.sites)
      if (sites.length === 1) {
        payload.site = sites[0].code
        const systems = Object.values(sites[0].systems)
        if (systems.length === 1) {
          payload.system = systems[0].code
        }
      }
    }
    return payload
  }

  renderEventButtons() {
    if (this.props.readonly) {
      return null
    }

    return (
      <>
        <BackdropDialog
          isOpen={this.state.showExportDialog}
          type={"option"}
          optionProps={{
            options: {
              csv: "csv",
              json: "json",
            },
            defaultOptionKey: "csv",
            optionInfo: {
              csv: {
                color: "info",
                text: `${t("event.messages.export_event_data_to_csv")}`,
                performText: `${t("event.messages.exporting_event_data")}`,
              },
              json: {
                color: "info",
                text: `${t("event.messages.export_event_data_to_json")}`,
                performText: `${t("event.messages.exporting_event_data")}`,
              },
            },
          }}
          title={t("event.messages.export_events")}
          onCancel={() =>
            this.setStateIfMounted({
              showExportDialog: false,
            })
          }
          onConfirm={async (selection) => {
            let content = ""

            if (selection === "csv") {
              content = "Priority,Type,SubType,Cause,Asset,Start Date,Due Date,End Date,Added By\n"
              for (const [, value] of Object.entries(this.props.eventTableSelected)) {
                content += `${EventListView.getPriority(value)},${value.type},${Utils.capitalizeFirstLetter(
                  Utils.splitCamelCase(value.subType ?? "None"),
                )},${value.cause},${value.path},"${moment(value.startDate).format("lll")}","${moment(
                  value.dueDate,
                ).format("lll")}","${moment(value.endDate).format("lll")}",${value.userName}\n`
              }
            } else if (selection === "json") {
              const objects = []
              for (const [, value] of Object.entries(this.props.eventTableSelected)) {
                objects.push({
                  Priority: EventListView.getPriority(value),
                  Type: value.type,
                  SubType: Utils.capitalizeFirstLetter(Utils.splitCamelCase(value.subType ?? "None")),
                  Cause: value.cause,
                  Asset: value.path,
                  "Start Date": moment(value.startDate).format("lll"),
                  "Due Date": moment(value.dueDate).format("lll"),
                  "End Date": moment(value.endDate).format("lll"),
                  "Added By": value.userName,
                })
                content = JSON.stringify(objects, " ", 4)
              }
            }

            const extensionData = {
              csv: {
                contentType: "text/csv",
                extension: "csv",
              },
              json: {
                contentType: "application/json",
                extension: "json",
              },
            }

            const blob = new Blob([content], { type: extensionData[selection].contentType })
            const url = window.URL.createObjectURL(blob)
            const anchor = document.createElement("a")
            anchor.href = url
            anchor.download = this.props.project
              ? `${this.props.project.code}-events.${extensionData[selection].extension}`
              : `events.${extensionData[selection].extension}`
            anchor.click()
            window.URL.revokeObjectURL(url)
          }}
        />
        <ButtonToolbar className="secondary-header-toolbar float-end">
          <ButtonGroup className="header-button-group">
            <Button
              onClick={() => {
                this.setStateIfMounted({
                  showExportDialog: true,
                })
              }}
              size="sm"
              color="dark"
            >
              {t("event.labels.download_slected")}
            </Button>
            <Confirmation
              size={"sm"}
              idSuffix={"delete-events"}
              text={t("labels.delete_selected")}
              color={"danger"}
              header={t("event.labels.delete_events")}
              onConfirm={() => {
                // Get all the events to delete.
                let eventsToDelete = []
                for (const [, value] of Object.entries(this.props.eventTableSelected)) {
                  eventsToDelete.push(value)
                }

                // Remove the selected events.
                this.props.actions.deleteEvents.call(this, eventsToDelete).then(() => {
                  this.props.clearSelectedEvents.call(this)
                })
              }}
              body={t("event.messages.confirm_delete_selected_event")}
              toolTip={t("event.labels.delete_selected_events")}
              irreversible={true}
            />
            <Button
              className={this.props.event && !this.props.event.id ? "selected" : ""}
              onClick={() => {
                this.props.actions.selectEvent({ location: this.getAutoFillPayload() })
              }}
              size="sm"
              color="ecogy"
            >
              {t("event.labels.new_event")}
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </>
    )
  }

  renderContent() {
    return (
      <div className="content-view-controls search-bar">
        {this.renderEventButtons()}

        <Form
          inline
          className="float-end"
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <InputGroup size="sm">
            {this.props.hashtags && this.props.hashtags.length ? (
              <UncontrolledDropdown size="sm">
                <DropdownToggle caret>#</DropdownToggle>
                <DropdownMenu>
                  {this.props.hashtags.map((hashtag) => {
                    return (
                      <DropdownItem
                        key={hashtag}
                        onClick={() => {
                          this.props.actions.search(hashtag)
                        }}
                      >
                        {hashtag}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null}
            <InputGroupText>{t("labels.search")}:</InputGroupText>
            <Input
              type="text"
              name="search"
              placeholder="e.g. 'outage' or /PR/ST/SYS"
              value={this.props.searchTerm}
              onChange={(e) => {
                this.props.actions.search(e.target.value)
              }}
            />
            <InputGroupText
              onClick={() => {
                this.props.actions.search("")
              }}
            >
              X
            </InputGroupText>
          </InputGroup>
        </Form>

        <Form inline className="float-end">
          <Label>{t("event.labels.show_only")}:</Label>

          <FormGroup inline check>
            <Label check className={"form-check-label-color event-user-generated"}>
              <Input
                type="checkbox"
                checked={this.props.filters.showSystemGeneratedOnly}
                onChange={() => {
                  this.props.actions.toggleSystemGenerated()
                }}
              />{" "}
              {t("event.labels.system_generated")}
            </Label>
          </FormGroup>

          <FormGroup inline check>
            <Label check className={"form-check-label-color event-due"}>
              <Input
                type="checkbox"
                checked={this.props.filters.showDueOnly}
                onChange={() => {
                  this.props.actions.toggleDue()
                }}
              />{" "}
              {t("labels.overdue")}
            </Label>
          </FormGroup>

          <FormGroup inline check>
            <Label check className={"form-check-label-color event-unresolved"}>
              <Input
                type="checkbox"
                checked={this.props.filters.showUnresolvedOnly}
                onChange={() => {
                  this.props.actions.toggleUnresolved()
                }}
              />{" "}
              {t("labels.unresolved")}
            </Label>
          </FormGroup>
        </Form>

        <Form inline className="float-start">
          {this.props.priorities ? (
            <React.Fragment>
              <Label>{t("table_headings.priority")}:</Label>
              {this.props.priorities.map((priority) => {
                return (
                  <PriorityFilter
                    key={priority.id}
                    priority={priority}
                    selectedPriorities={this.props.selectedPriorities}
                    togglePriority={this.props.actions.togglePriority}
                  />
                )
              })}
            </React.Fragment>
          ) : null}
        </Form>
        {this.props.types && this.props.types.length > 1 ? (
          <ButtonToolbar className="float-start capitalize-buttons">
            {this.renderFilters("type", this.props.types, this.props.selectedTypes)}
          </ButtonToolbar>
        ) : null}
      </div>
    )
  }

  renderFilters(id, base, filters) {
    const selectedTypes = { [id]: {} }

    if (base) {
      base.forEach((type) => {
        selectedTypes[id][type] = filters?.includes(type) ?? true
      })
    }

    return base ? (
      <TypeFilter
        typeHierarchy={[
          {
            id: id,
            name: id,
            subTypes: base.map((type) => {
              const subTypes = this.state.eventsTypes.find((typ) => typ.type === type)?.subTypes
              return {
                id: type,
                name: Utils.splitCamelCase(type ?? "None"),
                subTypes: Number(subTypes?.length) === 1 && subTypes[0] === "None" ? undefined : subTypes,
              }
            }),
          },
        ]}
        selectedTypes={selectedTypes}
        toggleType={(e, type) => {
          e.preventDefault()
          e.stopPropagation()

          if (type === "type") {
            this.props.actions.toggleTypes()
          } else if (type === "subType") {
            this.props.actions.toggleSubTypes(filters?.length ? [] : base)
          }
        }}
        toggleSubType={(e, type, subType) => {
          e.preventDefault()
          e.stopPropagation()

          if (type === "type") {
            this.props.actions.toggleType(subType)
          } else if (type === "subType") {
            this.props.actions.toggleSubTypes([subType])
          }
        }}
      />
    ) : null
  }
}
