import React from "react"

import EconodeModuleHeader from "./EconodeModuleHeader"
import EcosuiteModule from "@common/module/EcosuiteModule"

export default class EconodeModule extends EcosuiteModule {
  renderHeader() {
    return (
      <EconodeModuleHeader
        restrictions={this.props.restrictions}
        groups={this.props.groups}
        // Make the products available for selection
        products={this.state.products}
        selectedProductIds={this.state.selectedProductIds}
        // Make the module views available for selection
        moduleViews={this.getModuleViews()}
        moduleView={this.getSelectedModuleView()}
        projectViews={this.getProjectViews()}
        projectView={this.getSelectedProjectView()}
        // Make the date range available for selection
        rangeName={this.state.rangeName}
        customRange={this.getCustomRange()}
        showTime={this.state.dateRangeIncludesTime}
        // Make the selected project and projects available
        project={this.props.project}
        projects={this.props.projects}
        // Make the actions available
        actions={{
          selectModuleView: this.selectModuleView,
          selectProjectView: this.selectProjectView,
          selectRange: this.selectRange,
          selectProject: this.props.actions.selectProject,
          toggleProduct: this.toggleProduct,
        }}
      />
    )
  }
}
