import React from "react"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"
import EnergyUtils from "@dashboard/energy/EnergyUtils"

export default class PreviousPeriodConsumption extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.previousReadings)) {
      return <Error error={this.props.previousReadings.getError()} />
    }
    if (this.isContentValid(this.props.previousReadings) && this.props.previousReadings.consumption !== undefined) {
      return (
        <div className="sidebar-heading" id={this.props.id}>
          {EnergyUtils.displayWattHours(this.props.previousReadings.consumption)}
        </div>
      )
    } else if (this.props.previousReadings !== null) {
      return <Loading />
    } else {
      return null
    }
  }
}
