import React, { useState } from "react"
import { Table as StrapTable, Input, Button as OldButton } from "reactstrap"
import CreatableSelect from "react-select/creatable"
import API from "@common/API"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Info, FileText, CheckCircle } from 'lucide-react'
import RecordService from "./RecordService"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogClose } from "src/components/ui/dialog"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "src/components/ui/card"
import { Table, DataType, SortingMode } from 'ka-table'
import 'ka-table/style.css'
import { EyeOpenIcon, DownloadIcon } from "@radix-ui/react-icons"
import { useFilePreview, PreviewDialog, downloadFile, viewFile } from "src/PublicView/Components/Preview"

export const ChecklistField = ({ id, schema, formData, ...props }) => {
  if (Object.keys(schema.properties).length === 0) {
    return null
  }

  const [showRecordInput, setShowRecordInput] = useState(false)
  const [selectedRecordInput, setSelectedRecordInput] = useState("")
  const [showDataInput, setShowDataInput] = useState(false)
  const [selectedDataInput, setSelectedDataInput] = useState("")
  const [showNotesInput, setShowNotesInput] = useState(false)
  const [selectedNotesInput, setSelectedNotesInput] = useState("")
  const [isSuggesting, setIsSuggesting] = useState(false)

  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [infoRecordName, setInfoRecordName] = useState(null)
  const [infoRecordId, setInfoRecordId] = useState(null)
  const [infoRecordDocuments, setInfoRecordDocuments] = useState([])
  const [loadingInfo, setLoadingInfo] = useState(false)
  const [infoRecord, setInfoRecord] = useState(null) // to store the actual record details

  const toggleInfoModal = () => setInfoModalOpen(!infoModalOpen)

  const sortedEntries = schema["ui:order"] ? sortEntries(schema) : Object.entries(schema.properties)
  function sortEntries(schema) {
    return [...Object.entries(schema.properties)].sort((a, b) => {
      return schema["ui:order"].indexOf(a[0]) > schema["ui:order"].indexOf(b[0]) ? 1 : -1
    })
  }

  let toCheck = []
  Object.keys(schema.properties).forEach((key) => {
    if (formData[key] !== undefined) {
      toCheck.push(formData[key])
    }
  })

  const isNtpOrPto = props.formContext?.isNtpOrPto

  async function suggestRecord(records, itemTitle, itemData, itemNotes) {
    setIsSuggesting(true)
    try {
      const data = await API.post(`/records/suggestChecklistRecord`, {
        records,
        itemTitle,
        itemData,
        itemNotes,
      })
      setIsSuggesting(false)
      toast.success(`Suggestion applied!`, {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      })
      return data.id
    } catch (error) {
      setIsSuggesting(false)
      toast.error(`Suggestion failed. Please try again.`, {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      })
      throw error
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#e6f7ff" : "#fff",
      color: "#000",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      zIndex: 1001
    }),
  }

  async function handleInfoClick(currentRecord, recordId) {
    // Show the dialog immediately
    toggleInfoModal()
    setLoadingInfo(true)

    // Set initial record details known
    setInfoRecordName(currentRecord?.name)
    setInfoRecordId(recordId)
    setInfoRecord(currentRecord)

    // Fetch documents
    const documents = await RecordService.getRecordDocuments(recordId)
    setInfoRecordDocuments(documents?.data?.documents || [])

    setLoadingInfo(false)
  }

  return (
    <div className={"border border-1 p-2"}>
      <StrapTable id={id} dark striped>
        <thead>
          <tr>
            <th className="table__header">{schema.title}</th>
            <th className="table__header-placeholder table__check-container" style={{ width: "5%" }}>
              <div className="table__check">
                <Input type="checkbox" checked={toCheck.every((child) => child.isChecked)} disabled />
              </div>
            </th>
            <th className={"table__header-placeholder"}>Record</th>
            <th className={"table__header-placeholder"}>Data</th>
            <th className={"table__header-placeholder"}>Notes</th>
          </tr>
        </thead>
        <tbody>
          {sortedEntries.map(([key, child]) => {
            const itemData = formData[key]?.data || ""
            const itemNotes = formData[key]?.notes || ""
            const itemTitle = child.title

            const recordOptions = [
              { label: "Suggest", value: "suggest" },
              ...props.formContext.records.map((r) => ({
                value: r.id,
                label: r.name,
              })),
            ]

            const currentRecordId = formData[key]?.record
            const currentRecord = props.formContext.records.find((r) => r.id === currentRecordId)

            const showInfoIcon = currentRecordId && currentRecord

            return (
              <tr key={child.title}>
                <td>
                  <span>{child.title}</span>
                </td>

                <td className="table__check-container">
                  <div className="table__check">
                    <Input
                      type="checkbox"
                      checked={formData[key]?.isChecked}
                      onChange={(e) => {
                        props.onChange({
                          ...formData,
                          [key]: { ...formData[key], isChecked: e.target.checked },
                        })
                      }}
                    />
                  </div>
                </td>

                <td
                  className={`table__text-container  ${selectedRecordInput === key ? "no-padding" : ""}`}
                  onClick={() => {
                    setSelectedRecordInput(key)
                    setShowRecordInput(true)
                  }}
                >
                  <div className="table__text" style={{ display: "flex", alignItems: "center" }}>
                    {showRecordInput && selectedRecordInput === key ? (
                      isSuggesting ? (
                        <span>Loading suggestion...</span>
                      ) : isNtpOrPto ? (
                        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                          <div style={{ flexGrow: 1 }}>
                            <CreatableSelect
                              styles={customStyles}
                              autoFocus
                              isClearable
                              value={
                                currentRecord
                                  ? { value: currentRecordId, label: currentRecord.name }
                                  : (currentRecordId ? { value: currentRecordId, label: currentRecordId } : null)
                              }
                              options={recordOptions}
                              placeholder="Select or type a Record..."
                              onCreateOption={(inputValue) => {
                                props.onChange({
                                  ...formData,
                                  [key]: { ...formData[key], record: inputValue },
                                })
                                setSelectedRecordInput("")
                                setShowRecordInput(false)
                              }}
                              onChange={async (selectedOption) => {
                                if (!selectedOption) {
                                  // Cleared selection
                                  props.onChange({
                                    ...formData,
                                    [key]: { ...formData[key], record: "" },
                                  })
                                  return
                                }

                                if (selectedOption.value === "suggest") {
                                  try {
                                    const suggestedId = await suggestRecord(
                                      props.formContext.records,
                                      itemTitle,
                                      itemData,
                                      itemNotes
                                    )

                                    props.onChange({
                                      ...formData,
                                      [key]: { ...formData[key], record: suggestedId },
                                    })
                                  } catch (error) {
                                    console.error("Suggestion failed:", error)
                                  }
                                } else {
                                  // Existing record from the list
                                  props.onChange({
                                    ...formData,
                                    [key]: { ...formData[key], record: selectedOption.value },
                                  })
                                }
                              }}
                              onBlur={() => {
                                setSelectedRecordInput("")
                                setShowRecordInput(false)
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") e.target.blur()
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        // Non NTP/PTO: Just a text input
                        <Input
                          autoFocus
                          type="text"
                          value={formData[key]?.record ?? ""}
                          onChange={(e) => {
                            props.onChange({
                              ...formData,
                              [key]: { ...formData[key], record: e.target.value },
                            })
                          }}
                          onBlur={() => {
                            setSelectedRecordInput("")
                            setShowRecordInput(false)
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") e.target.blur()
                          }}
                        />
                      )
                    ) : (
                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ marginRight: "8px" }}>
                          {isNtpOrPto
                            ? (currentRecord?.name || (currentRecordId && !currentRecord ? currentRecordId : formData[key]?.record))
                            : formData[key]?.record}
                        </div>
                        {showInfoIcon && (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={async (e) => {
                              e.stopPropagation()
                              await handleInfoClick(currentRecord, formData[key]?.record)
                            }}
                          >
                            <Info size={20} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  className={`table__text-container  ${selectedDataInput === key ? "no-padding" : ""}`}
                  onClick={() => {
                    setSelectedDataInput(key)
                    setShowDataInput(true)
                  }}
                >
                  <div className="table__text">
                    {showDataInput && selectedDataInput === key ? (
                      <Input
                        autoFocus
                        type="text"
                        value={formData[key]?.data ?? ""}
                        onChange={(e) => {
                          props.onChange({
                            ...formData,
                            [key]: { ...formData[key], data: e.target.value },
                          })
                        }}
                        onBlur={() => {
                          setSelectedDataInput("")
                          setShowDataInput(false)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.target.blur()
                        }}
                      />
                    ) : (
                      <span>{formData[key]?.data}</span>
                    )}
                  </div>
                </td>

                <td
                  className={`table__text-container  ${selectedNotesInput === key ? "no-padding" : ""}`}
                  onClick={() => {
                    setSelectedNotesInput(key)
                    setShowNotesInput(true)
                  }}
                >
                  <div className="table__text">
                    {showNotesInput && selectedNotesInput === key ? (
                      <Input
                        autoFocus
                        type="text"
                        value={formData[key]?.notes ?? ""}
                        onChange={(e) => {
                          props.onChange({
                            ...formData,
                            [key]: { ...formData[key], notes: e.target.value },
                          })
                        }}
                        onBlur={() => {
                          setSelectedNotesInput("")
                          setShowNotesInput(false)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.target.blur()
                        }}
                      />
                    ) : (
                      <span>{formData[key]?.notes}</span>
                    )}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </StrapTable>

      <Dialog open={infoModalOpen} onOpenChange={toggleInfoModal}>
        <DialogContent style={{ maxWidth: "800px", maxHeight: "600px", overflowY: "auto" }}>
          <DialogHeader>
            <DialogTitle className="tw-flex tw-items-center tw-gap-2">
              <FileText className="tw-w-5 tw-h-5"/> Record Info
            </DialogTitle>
          </DialogHeader>
          {loadingInfo ? (
            <div className="tw-flex tw-justify-center tw-items-center tw-h-48">
              <div className="tw-animate-spin tw-rounded-full tw-h-10 tw-w-10 tw-border-t-2 tw-border-b-2 tw-border-gray-500"></div>
            </div>
          ) : (
            <div className="tw-space-y-4">
              {infoRecord && (
                <Card>
                  <CardHeader>
                    <CardTitle className="tw-flex tw-items-center tw-gap-2">
                      {infoRecordName || "Unknown Record"}
                      {infoRecord.verified && <CheckCircle className="tw-w-4 tw-h-4 tw-text-green-500" />}
                    </CardTitle>
                    <CardDescription>{infoRecord.path ? `Path: ${infoRecord.path}` : ""}</CardDescription>
                  </CardHeader>
                  <CardContent className="tw-grid tw-gap-2 tw-grid-cols-2">
                    <div>
                      <strong>Record ID:</strong> <div>{infoRecordId}</div>
                    </div>
                    <div>
                      <strong>Asset Type:</strong> <div>{infoRecord.assetType}</div>
                    </div>
                    <div>
                      <strong>Record Type:</strong> <div>{infoRecord.recordType}</div>
                    </div>
                    <div>
                      <strong>Record Sub-Type:</strong> <div>{infoRecord.recordSubType}</div>
                    </div>
                    <div>
                      <strong>Is Contract:</strong> <div>{infoRecord.isContract}</div>
                    </div>
                    <div>
                      <strong>Created:</strong> <div>{new Date(infoRecord.created).toLocaleString()}</div>
                    </div>
                    <div>
                      <strong>Updated:</strong> <div>{new Date(infoRecord.updated).toLocaleString()}</div>
                    </div>
                    <div>
                      <strong>Verified:</strong> <div>{infoRecord.verified ? "Yes" : "No"}</div>
                    </div>
                  </CardContent>
                </Card>
              )}

              {infoRecordDocuments && infoRecordDocuments.length > 0 ? (
                <>
                  <h5 className="font-semibold">Documents</h5>
                  <DocumentsTable documents={infoRecordDocuments} />
                </>
              ) : (
                <p className="tw-text-gray-500">No documents found.</p>
              )}
            </div>
          )}

          <DialogFooter>
            <DialogClose asChild>
              <OldButton color="secondary" className="tw-mt-4">Close</OldButton>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}



const ActionButtons = ({ rowData }) => {
  const [loadingDownload, setLoadingDownload] = React.useState(false)

  const { previewFile, setPreviewFile, pdfLoadError, handlePreview, isLoading: loadingPreview } = useFilePreview()

  const handleDownload = async (file) => {
    try {
      setLoadingDownload(true)
      const url = await viewFile(file.fileKey)
      await downloadFile(url, file.fileName)
      toast.success("Successfully downloaded file! Check your downloads folder.")
    } catch (error) {
      toast.error("Download failed!")
    } finally {
      setLoadingDownload(false)
    }
  }

  return (
    <>
      <PreviewDialog
        previewFile={previewFile}
        setPreviewFile={setPreviewFile}
        pdfLoadError={pdfLoadError}
        isLoading={loadingPreview}
      />
      <div className="flex">
        <OldButton
          size="sm"
          color="secondary"
          className="p-2"
          disabled={loadingPreview}
          onClick={() => handlePreview({ fileName: rowData.name, fileKey: rowData.fileKey })}
        >
          {loadingPreview ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            <EyeOpenIcon className="h-4 w-4" />
          )}
        </OldButton>
        <OldButton
          size="sm"
          color="secondary"
          className="p-2 ml-2"
          disabled={loadingDownload}
          onClick={() => handleDownload({ fileName: rowData.name, fileKey: rowData.fileKey })}
        >
          {loadingDownload ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            <DownloadIcon className="h-4 w-4" />
          )}
        </OldButton>
      </div>
    </>
  )
}

export const DocumentsTable = ({ documents }) => {
  // Transform document data to match our table schema
  const docsData = documents.map((doc) => ({
    fileKey: doc.fileKey,
    name: doc.title,
    size: doc.size,
    updated: new Date(doc.updated).toLocaleString(),
  }))

  const columns = [
    { key: 'action', title: 'Actions', dataType: DataType.String, width: 160 },
    { key: 'name', title: 'Document Name', dataType: DataType.String },
    { key: 'size', title: 'Size (bytes)', dataType: DataType.Number, width: 160 },
    { key: 'updated', title: 'Updated', dataType: DataType.String, width: 200 },
  ]

  return (
    <div className="twx-ka-table pt-4">
      <Table
        columns={columns}
        data={docsData}
        rowKeyField="fileKey"
        noData={{ text: "No documents found." }}
        sortingMode={SortingMode.Single}
        childComponents={{
          cellText: {
            content: (props) => {
              if (props.column.key === 'action') {
                return <ActionButtons rowData={props.rowData} />
              }
              return props.value
            },
          },
          tableWrapper: {
            elementAttributes: () => ({ style: { maxHeight: "calc(100vh - 400px)" } }),
          },
        }}
      />
    </div>
  )
}
