import React, { useEffect, useState } from "react"
import { DateTime } from "luxon"
import { Button } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import "./MediaTableEntry.scss"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

/**
 * A media table entry.
 *
 * This represents a single media item.
 * @param media: The media.
 * @param deleteAndRemoveMedia: The function to delete and remove the media.
 * @constructor
 */
const { t } = i18n
export const MediaTableEntry = ({ media, deleteAndRemoveMedia }: { media: MediaResponse; deleteAndRemoveMedia: (key: string) => void }): JSX.Element => {
  const [actioning, setActioning] = useState<boolean>(false)

  useEffect(() => {
    setActioning(false)
  }, [media.key])

  return (
    <>
      <tr className={actioning ? "media-table-entry-actioning" : "media-table-entry"}>
        <td>{media.contentType}</td>
        <td>{(media.contentLength / 1000).toFixed()}</td>
        <td>{media.name}</td>
        <td>{DateTime.fromISO(media.createdDateTime).toLocaleString()}</td>
        <td>{media.description ?? ""}</td>
        <td>{media.tags.join(", ")}</td>
        <td>
          <UncontrolledTooltip target={"media-table-entry-preview"}>{t("labels.preview")}</UncontrolledTooltip>
          <Button
            id={"media-table-entry-preview"}
            disabled={actioning}
            size={"sm"}
            onClick={() => {
              window.open(media.data, "_blank", "noopener,noreferrer")
            }}
          >
            <Icon icon={"preview"} />
          </Button>
          <UncontrolledTooltip target={"media-table-entry-delete"}>{t("buttons.delete")}</UncontrolledTooltip>
          <Button
            id={"media-table-entry-delete"}
            disabled={actioning}
            size={"sm"}
            color={"danger"}
            onClick={async () => {
              setActioning(true)

              await deleteAndRemoveMedia.call(this, media.key)
            }}
          >
            <Icon icon={"delete"} />
          </Button>
        </td>
      </tr>
    </>
  )
}

export default MediaTableEntry
