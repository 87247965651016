import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import Logger from "@common/Logger"
import Icon from "@common/display/Icon"
import DocumentUpload from "@common/input/files/DocumentUpload"
import FinanceService from "@dashboard/finance/FinanceService"
import i18n from "src/i18n"
import { getUserOrganizationId } from "@common/OrganizationUtils"
import { Loading } from "@common/EcosuiteComponent"

const { t } = i18n

export function FixedArrayFieldTemplate(props) {
  return (
    <Row className={props.className}>
      {props.items.length ? (
        props.items.map((element, idx) => {
          let layout = props.uiSchema["ui:layout"]
          return (
            <Col sm={layout && layout.width ? layout.width : 12} key={idx}>
              {layout && layout.labelPrefix ? layout.labelPrefix + " " + (idx + 1) : null}
              {props.required ? "*" : null}
              {element.children}
            </Col>
          )
        })
      ) : (
        <p>{t("errors.no_entry_available")}</p>
      )}
    </Row>
  )
}

const renderFilters = (props) => {
  if (props.uiSchema["ui:array"]) {
    const renderFiltersFunction = props.formContext[props.uiSchema["ui:array"]]
    if (renderFiltersFunction) {
      return renderFiltersFunction()
    } else {
      Logger.warning(
        `The configured "ui:array" function: "${props.uiSchema["ui:array"]}" is not available in the formContext`,
      )
    }
  }
  return null
}

const renderCollapse = (props, idx) => {
  const collapse = props.uiSchema["ui:collapse"]
  if (collapse) {
    const label = props.uiSchema["ui:item-label"]
    const title = isNewItem(props, idx) ? `New ${label}` : collapse.renderTitle(props, idx)
    return (
      <div className="field-array-entry-collapse">
        <div
          className="dropdown-icon collapse-show"
          onClick={() => {
            document.getElementById(`${props.idSchema["$id"]}_${idx}-entry`).classList.remove("collapsed")
            document
              .getElementById(`${props.idSchema["$id"]}_${idx}-content`)
              .getElementsByClassName("form-control")[0]
              .focus({ focusVisible: true })
          }}
        >
          <a style={{ color: "white" }}>
            <Icon icon="expand_more"></Icon>
          </a>
          {title}
        </div>
        <div
          className="dropdown-icon collapse-hide"
          onClick={() => {
            document.getElementById(`${props.idSchema["$id"]}_${idx}-entry`).classList.add("collapsed")
          }}
        >
          <a style={{ color: "white" }}>
            <Icon icon="expand_less"></Icon>
          </a>
          {title}
        </div>
      </div>
    )
  }
  return null
}

const isNewItem = (props, idx) => {
  const collapse = props?.uiSchema["ui:collapse"]
  if (collapse) {
    if (collapse.isNewItem) {
      return collapse.isNewItem(props, idx)
    } else {
      if (!collapse.renderTitle(props, idx)) {
        return true // By default, if there's no title, we assume it's a new item
      }
    }
  }
  return false
}

export function EcosuiteArrayFieldTemplate(props) {
  let layout = props.uiSchema["ui:layout"]
  if (layout && layout.fixed) {
    return FixedArrayFieldTemplate(props)
  }

  const collapse = props.uiSchema["ui:collapse"]

  const tooltips = props.uiSchema["ui:tooltips"]
  const tooltipAddId = "btn-add-item-" + props.idSchema["$id"]
  const tooltipRemoveId = "btn-remove-item-" + props.idSchema["$id"]

  const label = props.uiSchema["ui:item-label"]
  if (location.hash) {
    var element = document.getElementById(location.hash.substring(1))
    if (element) {
      element.classList.remove("collapsed")
      element.scrollIntoView()
    }
  }

  return (
    <div className={props.className} id={`${props.idSchema["$id"]}`}>
      {renderFilters(props)}
      {props.items &&
        props.items.map((element, idx) => {
          const isNew = isNewItem(props, idx)

          // TODO Move this into its own custom field component to get direct access to the `errors` prop.
          // As per the docs https://react-jsonschema-form.readthedocs.io/en/v4.2.2/advanced-customization/custom-templates/#custom-templates
          // FieldTemplates are not meant to be responsible for behaviour (reactivity), only for layout (ui) and as such
          // don't have access to props like `errors` so getting them this way by drilling to the children is a bit of a hack
          const hasErrors = Object.keys(element.children?.props?.errorSchema ?? {}).length !== 0
          if (hasErrors) {
            // Remove `collapsed` classname from templates that have validation errors
            document.getElementById(`${props.idSchema["$id"]}_${idx}-entry`).classList.remove("collapsed")
          }

          return (
            <div
              id={`${props.idSchema["$id"]}_${idx}-entry`}
              key={element.index}
              className={`
                field-array-entry${layout && layout.inline ? " field-array-inline" : ""}
                ${collapse && collapse.collapsed && !isNew && !props.formContext.expandAll ? " collapsed" : ""}
                ${collapse && collapse.className ? ` ${collapse.className}` : ""}
                ${isNew ? " new-entry" : ""}
              `}
            >
              {renderCollapse(props, idx)}
              <div className="field-array-entry-content" id={`${props.idSchema["$id"]}_${idx}-content`}>
                {layout && layout.labelPrefix ? <legend>{layout.labelPrefix + " " + (idx + 1)}</legend> : null}
                {element.children}
              </div>
              <div className={layout && layout.buttonsClassName ? layout.buttonsClassName : "field-array-buttons"}>
                {tooltips && tooltips.remove && (
                  <UncontrolledTooltip placement="right" target={tooltipRemoveId}>
                    {tooltips.remove}
                  </UncontrolledTooltip>
                )}
                <Button
                  onClick={element.onDropIndexClick(element.index)}
                  className="icon-button"
                  outline
                  color="danger"
                  disabled={props.disabled}
                  id={tooltipRemoveId}
                >
                  <Icon icon="remove_circle" /> {label}
                </Button>

                {element.hasMoveDown && (
                  <Button
                    outline
                    onClick={(e) => {
                      element.onReorderClick(element.index, element.index + 1)(e)
                      document.getElementById(`${props.idSchema["$id"]}_${idx + 1}-entry`).classList.remove("collapsed")
                      document.getElementById(`${props.idSchema["$id"]}_${idx}-entry`).classList.add("collapsed")
                    }}
                    className="icon-button"
                    disabled={props.disabled}
                  >
                    <Icon icon="arrow_downward" />
                  </Button>
                )}
                {element.hasMoveUp && (
                  <Button
                    outline
                    onClick={(e) => {
                      element.onReorderClick(element.index, element.index - 1)(e)
                      document.getElementById(`${props.idSchema["$id"]}_${idx - 1}-entry`).classList.remove("collapsed")
                      document.getElementById(`${props.idSchema["$id"]}_${idx}-entry`).classList.add("collapsed")
                    }}
                    className="icon-button"
                    disabled={props.disabled}
                  >
                    <Icon icon="arrow_upward" />
                  </Button>
                )}

                {props.extraButtonNames && props.extraButtonNames.length > 0 && (
                  <div>
                    {
                      // Check to see if an element should be ignored.
                      // This is useful if some elements are judged to be incomplete.

                      !props.extraButtonIgnore.call(this, element) &&
                        props.extraButtonNames.map((name) => (
                          <Button
                            key={element.key}
                            onClick={
                              // Evaluate the element function.
                              props.extraButtonEvaluator
                                ? (event) => props.extraButtonEvaluator.call(this, event, element)
                                : (event) => {
                                    event.preventDefault()
                                  }
                            }
                          >
                            {name}
                          </Button>
                        ))
                    }
                  </div>
                )}
              </div>
            </div>
          )
        })}

      {props.canAdd && (
        <div className="field-array-add">
          {tooltips && tooltips.add && (
            <UncontrolledTooltip placement="left" target={tooltipAddId}>
              {tooltips.add}
            </UncontrolledTooltip>
          )}
          <Button
            onClick={props.onAddClick}
            className="icon-button"
            disabled={props.disabled}
            id={tooltipAddId}
            color="ecogy"
          >
            <Icon icon="add_circle" /> {label}
          </Button>
        </div>
      )}
    </div>
  )
}

export function TitledArrayFieldTemplate(props) {
  return (
    <div className={props.className}>
      <fieldset>
        <legend id={props.idSchema.$id}>
          {t(`titles.${props.title}`)}
          {props.required ? <span className="required">*</span> : null}
        </legend>

        <EcosuiteArrayFieldTemplate {...props} />
      </fieldset>
    </div>
  )
}

export function NotesArrayFieldTemplate(props) {
  return (
    <div className={props.className}>
      <fieldset>
        <legend id={props.idSchema.$id}>
          {t(`titles.${props.title}`)}
          {props.required ? <span className="required">*</span> : null}
        </legend>

        {props.items &&
          props.items.map((element) => {
            return (
              <div key={element.index}>
                {element.children}
                {element.children.props.formData.userName ? (
                  <div style={{ float: "right" }}>
                    <label>{t("labels.added_by")}:</label>
                    {element.children.props.formData.userName}
                  </div>
                ) : null}
                <Button
                  onClick={element.onDropIndexClick(element.index)}
                  className="icon-button remove-button"
                  color="danger"
                  disabled={props.disabled}
                >
                  <Icon icon="remove_circle" /> {t("buttons.delete")}
                </Button>
              </div>
            )
          })}

        {props.canAdd && (
          <Button onClick={props.onAddClick} className="icon-button add-button" disabled={props.disabled}>
            <Icon icon="add_circle" /> {t("labels.add")}
          </Button>
        )}
      </fieldset>
    </div>
  )
}

/**
 * An array template that adds a document upload button to each item.
 * @param props - The properties.
 * @returns {JSX.Element}
 * @constructor
 */
export const DocumentUploadArrayFieldTemplate = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedElement, setSelectedElement] = useState()

  return (
    <>
      <EcosuiteArrayFieldTemplate
        {...props}
        extraButtonNames={["Documents"]}
        extraButtonIgnore={(element) => !element.children.props.formData.id}
        extraButtonEvaluator={(event, element) => {
          event.preventDefault()

          // Extract the associated data.
          setSelectedElement(element.children.props.formData)

          // Indicate that we must show the document modal.
          setShowModal(true)
        }}
      />
      <DocumentUpload
        title={props.modalTitle ?? `${t("labels.documents")}`}
        bucketPath={`${props.bucketPrefix ?? ""}/${selectedElement ? `${selectedElement["id"]}/` : ""}`}
        showModal={showModal}
        onClose={() => {
          setSelectedElement(undefined)
          setShowModal(false)
        }}
        uploadFunc={props.uploadFunc}
        uploadArgs={props.uploadArgs}
      />
    </>
  )
}

/*
 * An array template that adds a document upload button to each item (for payment logs).
 * @param props - The properties.
 * @returns {JSX.Element}
 * @constructor
 */
export const PaymentDocumentUploadArrayFieldTemplate = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedElement, setSelectedElement] = useState()
  const [organizationId, setOrganizationId] = useState()

  useEffect(() => {
    getUserOrganizationId().then((orgId) => {
      setOrganizationId(orgId)
    })
  }, [])

  if (!organizationId) {
    return <Loading />
  }

  return (
    <>
      <EcosuiteArrayFieldTemplate
        {...props}
        extraButtonNames={[t("labels.payment_documents")]}
        extraButtonIgnore={(element) => !element.children.props.formData.id}
        extraButtonEvaluator={(event, element) => {
          event.preventDefault()

          // Extract the associated data.
          setSelectedElement(element.children.props.formData)

          // Indicate that we must show the document modal.
          setShowModal(true)
        }}
      />
      <DocumentUpload
        title={t("labels.payment_documents")}
        bucketPath={`${organizationId}/payment-logs/${selectedElement ? `${selectedElement["id"]}/` : ""}`}
        showModal={showModal}
        onClose={() => {
          setSelectedElement(undefined)
          setShowModal(false)
        }}
        uploadFunc={FinanceService.uploadDocument}
        uploadArgs={[props.projectId, props.cashFlowId, selectedElement ? selectedElement["id"] : ""]}
      />
    </>
  )
}
