import API from "@common/API"
import Logger from "@common/Logger"

const NotificationService = {
  getUserNotifications(userId: string) {
    return API.get<{ notifications: unknown }>(`/users/${userId}/notifications`).then((response) => {
      return response.notifications
    })
  },

  saveUserNotification(userId: string, notification: { id: string }) {
    if (notification.id) {
      return this.updateUserNotification(userId, notification.id, notification)
    } else {
      return this.createUserNotification(userId, notification)
    }
  },

  createUserNotification(userId: string, notification: unknown) {
    Logger.debug(`Creating notification for user: ${userId}`)
    return API.post<{ notification: unknown }, unknown>(`/users/${userId}/notifications`, notification).then((response) => response.notification)
  },

  updateUserNotification(userId: string, notificationId: string, notification: unknown) {
    Logger.debug(`Updating notification: ${notificationId} for user: ${userId}`)
    return API.put<{ notification: unknown }, unknown>(`/users/${userId}/notifications/${notificationId}`, notification).then((response) => response.notification)
  },

  deleteUserNotification(userId: string, notificationId: string) {
    Logger.debug(`Deleting notification: ${notificationId} for user: ${userId}`)
    return API.delete(`/users/${userId}/notifications/${notificationId}`)
  },

  getDevices() {
    // @ts-ignore
    return API.getFromCache("/solarnetwork/devices", API.getDefaultCacheTimeout()).then((response: { projects: any }) => {
      return response.projects
    })
  },
}

export default NotificationService
