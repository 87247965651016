import { t } from "i18next"
import * as React from "react"
import { TabContent, TabPane, NavLink, Button, Col, Container, Row, Nav, NavItem, Input, Label } from "reactstrap"
import { createUserForLimitedAdminApi, useUsersForLimitedAdmin, usecreateUserForLimitedAdmin } from "src/services/users"
import UserList from "./widgets/UserList"
import Icon from "@common/display/Icon"
import moment from "moment"
import TimezoneSelect from "@common/input/select/TimezoneSelect"
import UserAdminService from "./UserAdminService"
import { useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

const userTablePadding = "20px"

function isRegistrationExpired(user: any) {
  return moment().isAfter(moment(user.updated).add(7, "days")) && user.status === "FORCE_CHANGE_PASSWORD"
}

function UsersList({ selectedUser, data, setSelectedUser }: any) {
  return (
    <div className="userlist-content">
      <table className="user-table">
        <thead>
          <tr>
            <th className="user-name">{t("table_headings.user")}</th>
            {!selectedUser ? (
              <React.Fragment>
                <th>{t("table_headings.first_name")}</th>
                <th>{t("table_headings.last_name")}</th>
                <th>{t("table_headings.type")}</th>
                <th>{t("table_headings.status")}</th>
                <th>{t("table_headings.created")}</th>
                <th>{t("table_headings.updated")}</th>
                <th>{t("table_headings.timezone")}</th>
                <th>{t("table_headings.restricted")}</th>
              </React.Fragment>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {(data || []).map((user: any) => {
            const expired = isRegistrationExpired(user)
            return (
              <tr key={user.id}>
                <td
                  title={`User: ${user.id}`}
                  style={{
                    paddingRight: selectedUser ? "0px" : userTablePadding,
                  }}
                  onClick={() => setSelectedUser(user)}
                >
                  <Button className="user-button">{user.displayName}</Button>
                </td>

                {!selectedUser ? (
                  <React.Fragment>
                    <td>{user.attributes.firstName}</td>
                    <td>{user.attributes.lastName}</td>
                    <td>
                      {user.attributes.userType
                        ? user.attributes.userType || (
                            // Render this warning if the user has an assigned userTypeId
                            // but the assigned id does not have an associated name
                            <>
                              <Icon icon="warning" />
                              <span> {t("errors.no_longer_exists")} </span>
                            </>
                          )
                        : "-"}
                    </td>
                    <td className={expired ? "user-registration-expired" : ""}>{expired ? `${t("labels.expired")}` : user.status}</td>
                    <td>{moment(user.created).format("lll")}</td>
                    <td>{moment(user.updated).format("lll")}</td>
                    <td>{user.attributes.timezone}</td>
                    <td>{user.attributes.access.restrictProjects}</td>
                  </React.Fragment>
                ) : null}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function Details({ username, userDetails, setToListMode }: { username: string; userDetails?: Record<string, any>; setToListMode: () => void }) {
  return (
    <div className="admin-content admin-tabs">
      <Nav tabs>
        <NavItem>
          <NavLink className={"active"}>{t("header.userMenu.profile")}</NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab="profile">
        <TabPane tabId="profile">
          <CreateEditForm username={username} formData={userDetails} setToListMode={setToListMode} />
        </TabPane>
      </TabContent>
    </div>
  )
}

const getDomainDetails = (email: string) => {
  const [user, userdomain] = email.split("@")
  return { user, userdomain }
}

const validateEmail = (email: string, domain: string) => {
  
  const [, userdomain] = email.split("@")
  if(userdomain){
    window.alert("Please only enter the local part of the Email you would like to invite from your domain: " + domain)
  }
  return email
}

function CreateEditForm({ username, formData, setToListMode }: { username: string; formData?: Record<string, any>; setToListMode: () => void }) {
  const [, userdomain] = username.split("@")
  const [submitting, setSubmitting] = React.useState(false)
  const [form, setForm] = React.useState(
    formData?.attributes || {
      email: "",
      firstName: "",
      lastName: "",
      userType: "",
      timezone: "",
      access: {
        preventDownload: "no",
        restrictProjects: "no",
      },
    },
  ) 
  const queryClient = useQueryClient()
  const history = useHistory()

  React.useEffect(() => {
    if (formData) {
      setForm({ ...formData.attributes, email: getDomainDetails(formData.attributes.email).user })
    }
  }, [formData])

  return (
    <div>
      <div style={{ height: "calc(100vh - 200px)" }}>
        <div>
          <Label>Email*</Label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input style={{ width: "400px" }} value={form.email} onChange={(e) => setForm({ ...form, email: e.target.value })} />
            <div style={{ width: "400px", marginLeft: "12px", fontWeight: 600, fontSize: 18 }}>@{userdomain}</div>
          </div>
        </div>

        <div style={{ marginTop: "8px" }}>
          <Label>First Name*</Label>
          <Input value={form.firstName} onChange={(e) => setForm({ ...form, firstName: e.target.value })} />
        </div>

        <div style={{ marginTop: "8px" }}>
          <Label>Last Name*</Label>
          <Input value={form.lastName} onChange={(e) => setForm({ ...form, lastName: e.target.value })} />
        </div>

        <div style={{ marginTop: "8px" }}>
          <Label>Timezone</Label>
          <TimezoneSelect
            value={form.timezone}
            onChange={(value) => {
              setForm({ ...form, timezone: value })
            }}
          />
        </div>

        <div style={{ marginTop: "8px" }}>
          <Label>Start Date</Label>
          <Input
            type="date"
            value={form.access?.startDate}
            onChange={(e) => {
              setForm({ ...form, access: { ...form.access, startDate: e.target.value } })
            }}
          />
        </div>

        <div style={{ marginTop: "8px" }}>
          <Label>End Date</Label>
          <Input
            type="date"
            value={form.access?.endDate}
            onChange={(e) => {
              setForm({ ...form, access: { ...form.access, endDate: e.target.value } })
            }}
          />
        </div>
      </div>
      <Button
        color="primary"
        disabled={!form.email || !form.firstName || !form.lastName || submitting}
        onClick={async () => {
          if (!formData) {
            // create
            setSubmitting(true)
            let response : any
            try {
              response = await createUserForLimitedAdminApi({ ...form, email: `${validateEmail(form.email, userdomain)}@${userdomain}`})
            } catch(e) {
              response = e
            }

            setSubmitting(false)
            if (response.message) {
              window.alert(response.message)
            } else {
            // setToListMode()
              history.push("/")
              window.alert("User successfully created! Created user will receive an email soon with instructions on how to proceed.")
            }
          } else {
            setSubmitting(true)
            await UserAdminService.editUserLimitedAdmin(formData.id, { ...form, email: `${(form.email)}@${userdomain}`})
            setSubmitting(false)
            queryClient.invalidateQueries("usersLimitedAdmin")
            // setToListMode()
            history.push("/")
          }
        }}
      >
        {submitting ? "Submitting" : "Submit"}
      </Button>
      <Button
        onClick={() => {
          history.push("/")
        }}
        color="danger"
        style={{ marginLeft: "16px" }}
      >
        Cancel
      </Button>
    </div>
  )
}

export default function LimitedAdmin({ username }: { username: string }) {
  const [mode, setMode] = React.useState<"list" | "create" | "update">("create")
  const [selectedUser, setSelectedUser] = React.useState<any | null>(null)

  const { data } = useUsersForLimitedAdmin()
  const history = useHistory()

  return (
    <div className="Admin">
      <ul className="nav nav-tabs app-mode">
        <li className={"nav-item selected"}>
          <NavLink className="active nav-link">{t("settings.labels.users")}</NavLink>
        </li>
      </ul>
      <TabContent activeTab={"users"}>
        <TabPane tabId="users">
          {selectedUser && mode === "list" && (
            <Container fluid={true} className="Admin-content">
              <Row>
                <Col md="3">
                  <UsersList selectedUser={selectedUser} setSelectedUser={setSelectedUser} data={data} />
                </Col>
                <Col md="9">
                  <Details
                    username={username}
                    userDetails={selectedUser}
                    setToListMode={() => {
                      // setMode("list")
                      // setSelectedUser(null)
                      history.push("/")
                    }}
                  />
                </Col>
              </Row>
            </Container>
          )}
          {!selectedUser && mode === "list" && (
            <Container fluid={true} className="Admin-content">
              <Row>
                <UsersList selectedUser={selectedUser} setSelectedUser={setSelectedUser} data={data} />
              </Row>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    setMode("create")
                  }}
                >
                  New User
                </Button>
              </div>
            </Container>
          )}
          {mode === "create" && (
            <Container fluid={true} className="Admin-content">
              <Details
                username={username}
                userDetails={selectedUser}
                setToListMode={() => {
                  // setMode("list")
                  // setSelectedUser(null)
                  history.push("/")
                }}
              />
            </Container>
          )}
        </TabPane>
      </TabContent>
    </div>
  )
}
