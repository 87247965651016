import React from "react"
import { ButtonToolbar, ButtonGroup, Button, Label, Form, FormGroup, Input, DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import Toggle from "react-toggle"
import moment from "moment"

import EcosuiteComponent from "@common/EcosuiteComponent"
import { COLUMNS, COLUMN_NAMES } from "../ReportView"
import i18n from "src/i18n"

const { t } = i18n

class ReportViewControls extends EcosuiteComponent {
  renderContent() {
    return (
      <div className="content-view-controls">
        {this.props.toolbar}

        <ButtonToolbar className="float-start">
          <ButtonGroup className="header-button-group">
            <Button
              className={this.props.aggregate === "month" ? "selected" : null}
              onClick={() => {
                this.props.actions.selectAggregate("month")
              }}
              title="Month"
              size="sm"
            >
              {t("labels.month")}
            </Button>
            <Button
              className={this.props.aggregate === "quarter" ? "selected" : null}
              onClick={() => {
                this.props.actions.selectAggregate("quarter")
              }}
              title="Quarter"
              size="sm"
            >
              {t("labels.quarter")}
            </Button>
            <Button
              className={this.props.aggregate === "year" ? "selected" : null}
              onClick={() => {
                this.props.actions.selectAggregate("year")
              }}
              title="Year"
              size="sm"
            >
              {t("labels.year")}
            </Button>
            <Button
              className={this.props.aggregate === "total" ? "selected" : null}
              onClick={() => {
                this.props.actions.selectAggregate("total")
              }}
              title="Total"
              size="sm"
            >
              {t("labels.total")}
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
        {this.props.columnToggles?.length && (
          <>
            <Form
              className="irr-report-column-controls"
              style={this.props.columnToggles?.includes("forecast") && this.props.proFormaVersions && this.props.proFormaVersions.length ? { marginTop: "-6px" } : null}
            >
              {/*   Generate Toggles Dynamically   */}
              {this.props.columnToggles
                // These need to be sorted because array order is not enforced when saving this data onto the userType
                ?.sort((a, b) => (COLUMNS.indexOf(a) > COLUMNS.indexOf(b) ? 1 : -1))
                .map((column) => {
                  const key = `${column}-toggle`

                  // This is a copy of the previous forecast conditional. It can probably be reformatted
                  if (column === "forecast" && this.props.proFormaVersions?.length) {
                    return (
                      <FormGroup key={key} check inline className="control-forecast-group">
                        <Label check className={"form-check-label-color control-forecast"} id="forecast-toggle">
                          <Input
                            className="control-forecast__proforma-check"
                            type="checkbox"
                            checked={this.props.columns.indexOf("forecast") >= 0}
                            onChange={() => {
                              this.props.actions.toggleColumn("forecast")
                            }}
                          />
                          <span className="control-forecast-label">Forecast:</span>
                          <Input
                            className="control-forecast__proforma-select"
                            type="select"
                            bsSize="sm"
                            value={this.props.proFormaVersion}
                            style={{ maxWidth: "110px" }}
                            onChange={(e) => {
                              this.props.actions.selectProFormaVersion(e.target.value)
                            }}
                          >
                            <option value="">Current {`(${moment(this.props.proForma?.updated).format("lll")})`}</option>
                            {this.props.proFormaVersions
                              .filter((version) => !version.archived)
                              .map((version) => {
                                const versionName = `${version.id.split("/")[1]} (${moment(version.created).format("lll")})`
                                return (
                                  <option key={version.id} value={version.id}>
                                    {versionName}
                                  </option>
                                )
                              })}
                          </Input>
                        </Label>
                      </FormGroup>
                    )
                  }

                  return (
                    <FormGroup key={key} check inline>
                      <Label check className={`form-check-label-color control-${column}`} id={`${column}-toggle`}>
                        <Input
                          type="checkbox"
                          checked={this.props.columns.indexOf(column) >= 0}
                          onChange={() => {
                            this.props.actions.toggleColumn(column)
                          }}
                        />
                        {COLUMN_NAMES[COLUMNS.indexOf(column)]}
                      </Label>
                    </FormGroup>
                  )
                })}
            </Form>

            {/*   Generate Tooltips dynamically   */}
            {this.props.columnToggles.map((column) => {
              const key = `${column}-toggle`

              switch (column) {
                case "forecast":
                  return (
                    <UncontrolledTooltip placement="top" key={`${key}-tooltip`} target={key}>
                      {t("economics.tooltips.forecasted_cashflow")}
                    </UncontrolledTooltip>
                  )
                case "actual":
                  return (
                    <UncontrolledTooltip placement="top" key={`${key}-tooltip`} target={key}>
                      {t("economics.tooltips.actual_transactions")}
                    </UncontrolledTooltip>
                  )
                case "expected":
                  return (
                    <UncontrolledTooltip placement="top" key={`${key}-tooltip`} target={key}>
                      {t("economics.tooltips.expected_cashflow")}
                    </UncontrolledTooltip>
                  )
                case "latestBestEstimate":
                  return (
                    <UncontrolledTooltip placement="top" key={`${key}-tooltip`} target={key}>
                      {t("economics.tooltips.lbt_cashflow")}
                    </UncontrolledTooltip>
                  )
                case "difference":
                  return (
                    <UncontrolledTooltip placement="top" key={`${key}-tooltip`} target={key}>
                      {t("economics.tooltips.actual_minus_forecast")}
                    </UncontrolledTooltip>
                  )
              }
            })}
          </>
        )}

        {this.props.flags && (
          <span className="report-flag-controls">
            <UncontrolledDropdown direction="up" className="float-start" size="sm">
              <DropdownToggle caret id="cash-flow-flags">
                {t("labels.show")}
              </DropdownToggle>
              <DropdownMenu>
                <Form>
                  {Object.keys(this.props.flags).map((flag) => {
                    return (
                      <FormGroup key={flag} check>
                        <Label check>
                          {this.props.flags[flag]}
                          <Input type="checkbox" value={flag} onChange={this.props.actions.toggleFlag} checked={this.props.selectedFlags?.includes(flag)} />
                        </Label>
                      </FormGroup>
                    )
                  })}
                </Form>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledTooltip placement="left" target="cash-flow-flags">
              {t("economics.tooltips.select_the_flagged")}
            </UncontrolledTooltip>
          </span>
        )}

        {this.props.actions.toggleDateMode ? (
          <label className="irr-report-account-controls" title="Choose between normalising project start dates to the same time or using actual start dates">
            <Toggle defaultChecked={this.props.normaliseDates} onChange={this.props.actions.toggleDateMode} />
            <span className="react-toggle-text">{t("labels.normalize")}</span>
          </label>
        ) : null}

        {this.props.actions.toggleAccounts ? (
          <label className="irr-report-account-controls">
            <Toggle defaultChecked={this.props.showAccounts} onChange={this.props.actions.toggleAccounts} />
            <span className="react-toggle-text">{t("labels.accounts")}</span>
          </label>
        ) : null}

        {this.props.showAccounts && this.props.actions.toggleSubAccounts ? (
          <label className="irr-report-account-controls">
            <Toggle defaultChecked={this.props.showSubAccounts} onChange={this.props.actions.toggleSubAccounts} />
            <span className="react-toggle-text">{t("labels.sub_accounts")}</span>
          </label>
        ) : null}

        {this.props.timestamp ? <label className="float-end">{moment(this.props.timestamp).format("MMM DD h:mmA")}</label> : null}
      </div>
    )
  }
}

export default ReportViewControls
