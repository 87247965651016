import React from "react"
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap"
import EcosuiteForm, { EcosuiteArrayFieldTemplate } from "@common/form/EcosuiteForm"
import { sharedTariffUISchema } from "@dashboard/data/tariffs/tariffUtils"
import i18n from "src/i18n"

interface TariffCreatorProps {
  schema: Schema
  value: Schema
  onChange: (value: Tariff) => void
  onSubmit: (value: Tariff) => void
}

const { t } = i18n

/**
 * The tariff creator.
 * @param props - The props.
 * @constructor
 */
export const TariffCreator = (props: TariffCreatorProps) => {
  const { schema, value, onSubmit } = props

  const newTariffUISchema = {
    ...sharedTariffUISchema,
    id: {
      "ui:widget": "hidden",
    },
    assetType: {
      "ui:widget": "hidden",
    },
    path: {
      "ui:widget": "hidden",
    },
    author: {
      "ui:widget": "hidden",
    },
    lastUpdated: {
      "ui:widget": "hidden",
    },
    lastUpdatedBy: {
      "ui:widget": "hidden",
    },
    created: {
      "ui:widget": "hidden",
    },
  }

  return (
    <Card>
      <CardHeader tag={"h5"}>
        <Row>
          <Col>
            <CardTitle>{t("data.dialogs.create_new_tariff")}</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Col className={"ecogy-form"}>
          <EcosuiteForm schema={schema} uiSchema={newTariffUISchema} formData={value} ArrayFieldTemplate={EcosuiteArrayFieldTemplate} onSubmit={onSubmit} />
        </Col>
      </CardBody>
    </Card>
  )
}
