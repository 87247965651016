import React from "react"
import { Button, Alert } from "reactstrap"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm from "@common/form/EcosuiteForm"
import Logger from "@common/Logger"

import ProfileService from "./ProfileService"
import TimezoneSelect from "@common/input/select/TimezoneSelect"
import Icon from "@common/display/Icon"
import TimedMessage from "@common/display/TimedMessage"
import i18n from "src/i18n"

const { t } = i18n
class ProfileForm extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.editProfile = this.editProfile.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    ProfileService.getProfileSchema().then((schema) => {
      this.setStateIfMounted({ schema: schema })
    })
    ProfileService.getProfile().then((profile) => {
      this.setStateIfMounted({ formData: profile })
    })
  }

  editProfile(form) {
    this.setState({ error: undefined, success: false, formData: undefined, saving: true })
    ProfileService.editProfile(form)
      .then((profile) => {
        this.setStateIfMounted({ success: true, formData: profile, saving: false })
      })
      .catch((err) => {
        Logger.error(err)
        this.setState({ error: err, success: false, formData: form.formData, saving: false })
      })
  }

  getUiSchema() {
    return {
      timezone: {
        "ui:widget": (props) => {
          return (
            <TimezoneSelect
              className="timezone-picker"
              onChange={(value) => {
                props.onChange(value)
              }}
              value={props.value}
            />
          )
        },
      },
    }
  }

  renderContent() {
    if (this.state.saving) {
      return <Loading message={`${t("loadingMsg.updating_profile")}`} />
    }
    if (!this.state.formData) {
      return <Loading message={`${t("loadingMsg.loading_profile")}`} />
    }

    if (this.state.schema) {
      let error = this.getError()
      return (
        <div className="ecogy-form admin-tab-content-area">
          <Alert color="info" className="info">
            <Icon icon="info" />
            {t("notes.profile_changes")}
          </Alert>
          <EcosuiteForm schema={this.state.schema} uiSchema={this.getUiSchema()} formData={this.state.formData} onSubmit={this.editProfile}>
            <div>
              <Button color="primary" type="submit">
                {t("buttons.submit")}
              </Button>
            </div>
          </EcosuiteForm>
          {this.state.success ? (
            <TimedMessage delay={8000}>
              <Alert color="info">{t("messages.profile_updated")}</Alert>
            </TimedMessage>
          ) : null}
          {error ? <Alert color="danger">{error}</Alert> : null}
        </div>
      )
    } else {
      return <Loading />
    }
  }

  getError() {
    if (this.state && this.state.error) {
      return this.state.error.message ? this.state.error.message : this.state.error
    }
    return null
  }
}

export default ProfileForm
