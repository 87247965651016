const FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const FORMATTER_CENTS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

module.exports = {
  formatCurrency(amount) {
    if (amount || amount === 0) {
      return FORMATTER.format(amount)
    }
  },

  formatCurrencyWithCents(amount) {
    if (amount || amount === 0) {
      return FORMATTER_CENTS.format(amount)
    }
  },

  formatIRR(irr) {
    if (irr) {
      return irr + "%"
    } else {
      return "-"
    }
  },

  getCashFlowCategoriess(cashFlowReport) {
    let categories = []
    if (cashFlowReport) {
      Object.values(cashFlowReport.projectsCashFlow).forEach((projectCashFlow) => {
        Object.keys(projectCashFlow.categories).forEach((categoryName) => {
          let categoryId = "category." + categoryName
          if (!categories.find((category) => categoryId === category.id)) {
            categories.push({ id: categoryId, name: categoryName, type: "category" })
          }

          Object.keys(projectCashFlow.categories[categoryName]).forEach((subCategoryName) => {
            let subCategoryId = categoryId + "." + subCategoryName
            if (!categories.find((category) => subCategoryId === category.id)) {
              categories.push({ id: subCategoryId, name: subCategoryName, type: "subCategory" })
            }
          })
        })
      })
    }
    categories.sort((a, b) => a.id.localeCompare(b.id))

    return categories
  },

  /**
   * Checks if the payment is a revenue payment
   * @param {*} payment The payment to check
   * @return true if the payment should be treated as revenue
   */
  isOperatingRevenuePayment(cashFlow, payment) {
    return cashFlow.category === "Income" && (payment.paymentType === "generation" || payment.paymentType === "size")
  },
}
