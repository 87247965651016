import React from "react"
import { Button, ButtonDropdown, DropdownMenu, DropdownToggle, Input } from "reactstrap"
import Datetime from "react-datetime"
import moment from "moment"

import EcosuiteComponent, { Error } from "@common/EcosuiteComponent"

import ReportService from "./ReportService"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class ReportTable extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.loadReports = this.loadReports.bind(this)
    this.generateReport = this.generateReport.bind(this)

    this.state.dropdownOpen = false
    this.state.latest = moment().subtract(1, "month").format("YYYY-MM")
    this.state.targetMonth = moment().subtract(1, "month").format("YYYY-MM")
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadReports()
  }

  componentDidUpdate(prevProps) {
    if (this.props.portfolio !== prevProps.portfolio) {
      this.loadReports()
    }
  }

  loadReports() {
    ReportService.listOperatingReports(this.props.portfolio).then((response) => {
      this.setStateIfMounted({ reports: response.Contents })
    })
  }

  /**
   * @param {{
   *  sendEmail: Boolean,
   *  targetMonth: dateString,
   * }} args
   */
  generateReport(args) {
    this.setStateIfMounted({ error: undefined })
    const confirmationMsg = args.sendEmail
      ? `${t("settings.messages.operating_report_generation_configured", { targetMonth: args.targetMonth })}`
      : `${t("settings.messages.operating_report_generation", { targetMonth: args.targetMonth })}`

    if (window.confirm(confirmationMsg)) {
      this.setStateIfMounted({ reports: undefined, error: undefined }, () => {
        ReportService.generateReport(this.props.portfolio, args)
          .then(() => {
            this.loadReports()
          })
          .catch((err) => {
            this.setStateIfMounted({ error: err })
          })
      })
    }
  }

  renderDateRange() {
    return (
      <ButtonDropdown
        isOpen={this.state.dropdownOpen}
        toggle={() => {
          this.setState({ dropdownOpen: !this.state.dropdownOpen })
        }}
        className=""
      >
        <DropdownToggle caret disabled={this.props.disabled} style={{ borderRadius: "0.25rem" }}>
          {t("buttons.generate_custom_report")}
        </DropdownToggle>
        <DropdownMenu className="date-range-menu" end>
          <div className="custom-date-range">
            {/* Datetime component for the target month */}
            <div className="custom-date">
              <Datetime
                value={this.state.targetMonth}
                timeFormat={false}
                dateFormat="YYYY-MM"
                open={true}
                onChange={(date) => {
                  this.setState({
                    targetMonth: date.format("YYYY-MM"),
                  })
                }}
                isValidDate={(date) => date.isSameOrBefore(this.state.latest, "month")}
                renderInput={(props) => <Input {...props} readOnly={true} disabled={true} />}
              />
            </div>

            {/* Generate report buttons inside of date dropdown */}
            <div className="date-range-buttons">
              <Button
                color="primary"
                className="date-range-button"
                onClick={() => {
                  this.generateReport({ sendEmail: false, targetMonth: this.state.targetMonth })
                }}
              >
                {t("buttons.generate_report")}
              </Button>
              <Button
                color="primary"
                className="date-range-button"
                onClick={() => {
                  this.generateReport({ sendEmail: true, targetMonth: this.state.targetMonth })
                }}
              >
                {t("buttons.generate_and_email_report")}
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </ButtonDropdown>
    )
  }

  renderContent() {
    return (
      <div className="side-panel-content">
        <h4>{t("headings.operating_reports")}</h4>
        <div className="project-reports">
          {this.state.reports
            ? this.state.reports.length
              ? this.state.reports.map((report) => {
                  const fileName = report.Key.substring(report.Key.lastIndexOf("/") + 1)
                  return (
                    <div key={report.Key} className="report">
                      <div className="document">
                        <Button
                          title={`${t("labels.download_file", { fileName: fileName })}`}
                          className="download float-end"
                          onClick={() => {
                            ReportService.downloadFile(report.Key)
                          }}
                        >
                          <Icon icon="cloud_download" />
                        </Button>
                        {fileName}
                      </div>
                    </div>
                  )
                })
              : `${t("warnings.no_reports")}`
            : t("labels.loading")}
          <div>
            {this.props.portfolio.reports && this.props.portfolio.reports.operating ? (
              <>
                <Button
                  onClick={() => {
                    this.generateReport({ sendEmail: false, targetMonth: this.state.latest })
                  }}
                >
                  {t("buttons.generate_latest_report")}
                </Button>
                <br />
                <Button
                  onClick={() => {
                    this.generateReport({ sendEmail: true, targetMonth: this.state.latest })
                  }}
                >
                  {t("buttons.generate_and_email_latest_report")}
                </Button>
                <br />
                {this.renderDateRange()}
              </>
            ) : null}
            {this.state.error ? <Error error={this.state.error} /> : null}
          </div>
        </div>
      </div>
    )
  }
}
