import React from "react";
import logo from "src/img/ecosuite_logo.png";
import Utils from "@common/utils/Utils";

interface BatteryIndicatorProps {
  soc: number;
}

export default function BatteryIndicator(props: BatteryIndicatorProps) {
  const getStatusClass = (chargeLevel: number): string => {
    if (chargeLevel <= 0)return ' empty';
    if (chargeLevel <= 20) return ' low';
    return ' high'
  }

  const statusClass = getStatusClass(props.soc)

  const height = props.soc + "%";
  return (
    <div className={"battery-cell"}>
      <div className={"battery-cell-bottom"} />
      <div className={"battery-cell-top"} />
      <div className={"battery-cell-top2"} />
      <div className={"charge-bar-wrapper"}>
        <div className={"charge-bar" + statusClass} style={{ height: height }}>
          <div className={"charge-bar-bottom" + statusClass} />
          <div className={"charge-bar-top" + statusClass} />
        </div>
      </div>
      <img className={"logo"} src={logo} alt="Logo" />
    </div>
  );
}

interface BatteryInfoProps extends BatteryIndicatorProps {
  watts: number;
  revenue: number;
}

export function BatteryInfo(props: BatteryInfoProps) {
  return (
    <div className={"battery-box"}>
      <BatteryIndicator {...props} />
      <p style={{ marginTop: "10px", marginLeft: "18px" }}>
        {chargeString(props.watts)}
      </p>
      <p style={{ marginTop: "10px", marginLeft: "15px" }}>Revenue: $ {props.revenue}</p>
    </div>
  );
}

function chargeString(number: number){
  if (number >= 0){
    return "Charge: " + formatkWs(number)
  } else {
    return "Discharge: " + formatkWs(Math.abs(number))
  }
}

function formatkWs(number: number){
  if (number > 0){
    return Utils.formatNumber(number / 1000, null) + " kW"
  } else {
    return "0 kW"
  }
}

interface LargeBatteryInfoProps extends BatteryInfoProps {
  style?: React.CSSProperties;
  availWattHours: number;
  capacityWattHours: number;
  soh: number;
}

export function LargeBatteryInfo(props: LargeBatteryInfoProps) {
  return (
    <div className={"battery-container"} style={props.style}>
      <div>
        <svg
          style={{ overflow: "visible", top: "-10px", left: "10px", position: "relative", zIndex: "100" }}
          height={10}
          width={10}
        >
          <defs>
            <marker id="head" orient="auto" markerWidth="3" markerHeight="4" refX="0.1" refY="2">
              <path d="M0,0 V4 L2,2 Z" fill="#41874e" />
            </marker>
          </defs>

          {props.watts < 0 ? (
            <path
              id="arrow-line"
              markerEnd="url(#head)"
              strokeWidth="6"
              fill="none"
              stroke="#41874e"
              d="M -5 65 C -5 65, -5 15, 65 15"
            />
          ) : null}

          {props.watts > 0 ? (
            <path
              id="arrow-line"
              markerEnd="url(#head)"
              strokeWidth="6"
              fill="none"
              stroke="#41874e"
              d="M 70 15, C 70 15, -5 15, -5 50"
            />
          ) : null}

          <path id="soc-line" strokeWidth="2" fill="none" stroke="grey" d="M 105 128, L 105 128, -3 160" />
        </svg>
      </div>
      <p>
      {chargeString(props.watts)}
      </p>

      <div>
        <div className={"graph-tail-end"}></div>
        <BatteryIndicator {...props} />
      </div>
      <div>
        <p>SOC: {Utils.formatNumber(props.soc, null)}%</p>
        <p style={{ fontSize: "small" }}>({Utils.formatNumber(props.availWattHours / 1000, null)} kWh)</p>
      </div>

      <div>
        <p>{Utils.formatNumber(props.capacityWattHours / 1000, null)} kWh</p>
        <p className="state-of-health">SOH: {Utils.formatNumber(props.soh, null)}%</p>
      </div>
      <p>Revenue: ${props.revenue}</p>
    </div>
  );
}

