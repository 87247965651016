import React from "react"

import EcosuiteView from "@common/module/EcosuiteView"

import ProjectsTable from "../ProjectsTable"

export default class ConnectivityListView extends EcosuiteView {
  renderMainView() {
    return <ProjectsTable projects={this.props.projects} connectivity={this.props.connectivity} devices={this.props.devices} selectProject={this.props.selectProject} />
  }
}
