import React, { Component } from "react"
import { Input } from "reactstrap"
/**
 * The projects selector is used at the top level to allow the projects that are being viewed to be grouped by selecting and deselecting the ones to show.
 */
export default class PortfolioSelector extends Component {
  render() {
    return (
      <Input
        type="select"
        value={this.props.portfolio ? this.props.portfolio.id : ""}
        onChange={(e) => {
          this.props.actions.selectPortfolio(e.target.value)
        }}
        disabled={this.props.restrictions.restrictProjects === "yes" || (this.props.portfolios && this.props.portfolios.length === 1 && this.props.restrictions.restrictProjects === "portfolios")}
      >
        {!this.props.portfolios || this.props.portfolios.length !== 1 || this.props.restrictions.restrictProjects === "no" ? <option value="">All Projects</option> : null}
        {this.props.portfolios
          ? [...this.props.portfolios]
              .sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
              })
              .map((portfolio) => {
                return (
                  <option key={portfolio.id} value={portfolio.id}>
                    {portfolio.name}
                  </option>
                )
              })
          : null}
      </Input>
    )
  }
}
