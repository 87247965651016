import React from "react"
import { FormGroup, Input } from "reactstrap"
import i18n from "src/i18n"

import ReportSection from "../ReportSection"
import moment from "moment"

const { t } = i18n
export default class PortfolioCashFlowDates extends ReportSection {
  render() {
    return renderDates(this.props.dates, this.props.aggregate)
  }
}
const formatDate = (date, aggregate) => {
  switch (aggregate) {
    case "quarter":
      return "Q" + moment(date).format("Q YYYY")
    case "year":
      return moment(date).format("YYYY")
    case "total":
      return "Total"
    default:
      return moment(date).format("MMM YYYY")
  }
}

const renderDateSelector = (dates, aggregate) => {
  if (dates) {
    const currentDate = formatDate(new Date(), aggregate)
    const currentIdx = dates.indexOf(currentDate)
    return (
      <FormGroup>
        <Input type="select" onChange={jumpToDate}>
          <option value="">{t("labels.jump_to")}</option>
          {currentIdx >= 0 ? <option value={`year-${currentIdx}`}>Today</option> : null}
          {dates.map((year, idx) => {
            return (
              <option key={year} value={`year-${idx}`}>
                {year}
              </option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }
}

const jumpToDate = (e) => {
  if (e.target.value) {
    let target = document.getElementById(e.target.value)
    target.scrollIntoView({ inline: "start" })
  }
}

const renderDates = (dates, aggregate) => {
  return (
    <React.Fragment>
      <div className="line years">
        <div className="title">{renderDateSelector(dates, aggregate)}</div>
        <div className="subtitle"> </div>
        <div className="line-body cash-flow-header">
          {dates.map((date, idx) => {
            return (
              <div className="year" key={date} id={`year-${idx}`}>
                {date}
              </div>
            )
          })}
        </div>
      </div>

      <div className="line cashflow-types">
        <div className="title"> </div>
        <div className="subtitle"> </div>
        <div className="line-body cash-flow">
          {dates.map((date) => {
            return (
              <React.Fragment key={date}>
                <div className="cash-flow-forecast">{t("labels.forecast")}</div>
                <div className="cash-flow-expected">{t("labels.anticipated")}</div>
                <div className="cash-flow-actual">{t("labels.actual")}</div>
                <div className="cash-flow-latestBestEstimate">{t("labels.LBE")}</div>
                <div className="cash-flow-difference">{t("labels.difference")}</div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}
