import React from "react"
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import Logger from "@common/Logger"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import CustomRange from "@common/module/CustomRange"

import EnergyService from "./EnergyService"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class DownloadModal extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      format: "csv",
      dropdownOpen: false,
      customRange: DateRangeUtils.getRange(this.props.rangeName, this.props.customRange, this.props.projects),
      rangeName: props.rangeName,
      aggregate: props.aggregate ? props.aggregate : "day",
    }

    this.toggle = this.toggle.bind(this)
    this.toggleRangeDropDown = this.toggleRangeDropDown.bind(this)
    this.export = this.export.bind(this)
    this.selectRange = this.selectRange.bind(this)
    this.updateCustomRange = this.updateCustomRange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.aggregate !== prevProps.aggregate) {
      this.setState({ aggregate: this.props.aggregate })
    }
    if (this.props.rangeName !== prevProps.rangeName) {
      this.selectRange(
        this.props.rangeName,
        DateRangeUtils.getRange(this.props.rangeName, this.props.customRange, this.props.projects),
      )
    }
  }

  selectRange(rangeName, customRange) {
    this.setState({ rangeName: rangeName })
    if (rangeName === "custom") {
      this.setState({ customRange: customRange })
    }
  }

  updateCustomRange(customRange) {
    this.setState({ customRange: customRange })
  }

  toggleRangeDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      loading: false,
      error: undefined,
    })
  }

  setState(newState) {
    super.setState(newState)
  }

  export() {
    this.setState({ loading: true, error: undefined })

    let projectIds = this.props.project ? [this.props.project.code] : this.props.projects.map((project) => project.code)
    const range = this.props.hideRange
      ? this.props.customRange
      : DateRangeUtils.getExclusiveRange(
          DateRangeUtils.getRange(this.state.rangeName, this.state.customRange, this.props.projects),
          this.props.showTime,
        )

    EnergyService.export(projectIds.toString(), range, this.state.aggregate, this.state.format, this.props.sourceIds)
      .then(() => {
        // window.open(URL.createObjectURL(new Blob([response.blob()], { type: response.headers.get("Content-Type") })))

        this.setState({ modal: false, loading: false })
      })
      .catch((err) => {
        Logger.error("Error exporting data")
        Logger.error(err)
        this.setState({ error: err.message, loading: false })
      })
  }

  renderContent() {
    return (
      <React.Fragment>
        <Button onClick={this.toggle} title={t("energy.messages.download_range_energy_data")} size={this.props.size}>
          {this.props.label ? this.props.label : null} <Icon icon="cloud_download" />
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{t("labels.export_data")}</ModalHeader>
          <ModalBody>
            {this.state.loading ? (
              <div>
                {t("loadingMsg.request_being_sent")}
                <Loading />
              </div>
            ) : (
              <Form>
                <p>
                  {this.props.project
                    ? [
                        `${t("energy.messages.export_generated")}: `,
                        <b key="project">{this.props.project.code}</b>,
                        ` ${t("energy.messages.link-willbe_sent")}`,
                      ]
                    : [
                        `${t("energy.messages.exports_willbe_generated")} `,
                        <b key="project">{this.props.projects.length}</b>,
                        ` $${t("energy.messages.projects_and_links_willbe_sent")}`,
                      ]}
                </p>
                <p>
                  {`${t("energy.messages.please_read")} `}
                  <a
                    href="https://docs.ecosuite.io/user-guide/modules/energy/download-datums"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("energy.messages.download_datums_doc")}
                  </a>
                  {` ${t("energy.messages.toget_better_understanding")}`}
                </p>
                <Alert color="info">{t("energy.messages.starting_duration")}</Alert>
                {this.props.hideRange ? null : (
                  <FormGroup className="customRange">
                    <Label for="customRange">{t("labels.date_range")}</Label>
                    <CustomRange
                      id="customRange"
                      rangeName={this.state.rangeName}
                      isOpen={this.state.dropdownOpen}
                      selectRange={this.selectRange}
                      updateCustomRange={this.selectRange}
                      toggle={this.toggleRangeDropDown}
                      customRange={this.state.customRange}
                      showTime={this.props.showTime}
                      projects={this.props.projects}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  {this.state.error ? (
                    <div className="error">{`${t("errors.error_exporting_data")}: ` + this.state.error}</div>
                  ) : null}
                  <Label for="format">{t("labels.format")}</Label>
                  <Input
                    type="select"
                    name="format"
                    id="format"
                    value={this.state.format}
                    onChange={(e) => {
                      this.setStateIfMounted({ format: e.target.value })
                    }}
                  >
                    <option value="csv">CSV</option>
                    <option value="json">JSON</option>
                    {/* <option value="green-button">Green Button</option> */}
                  </Input>
                </FormGroup>
                {this.props.hideAggregate ? null : (
                  <FormGroup>
                    <Label for="aggregate">{t("labels.granularity")}</Label>
                    <Input
                      type="select"
                      name="aggregate"
                      id="aggregate"
                      value={this.state.aggregate}
                      onChange={(e) => {
                        this.setStateIfMounted({ aggregate: e.target.value })
                      }}
                    >
                      <option value="none">{t("aggregates.Everything")}</option>
                      <option value="5minute">{t("aggregates.Every 5 Minutes")}</option>
                      <option value="15minute">{t("aggregates.Every 15 minutes")}</option>
                      <option value="hour">{t("aggregates.Hourly")}</option>
                      <option value="day">{t("aggregates.Daily")}</option>
                      <option value="week">{t("aggregates.Weekly")}</option>
                      <option value="month">{t("aggregates.Monthly")}</option>
                      <option value="year">{t("aggregates.Yearly")}</option>
                    </Input>
                  </FormGroup>
                )}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.export}>
              {t("buttons.export")}
            </Button>{" "}
            <Button color="danger" onClick={this.toggle}>
              {t("buttons.cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}
