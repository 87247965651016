import React from "react"
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts"

import EcosuiteComponent, { Warning, Loading, Error } from "@common/EcosuiteComponent"
import ProjectUtils from "@common/utils/ProjectUtils"
import EcosuiteView, { GRAPH_COLORS, SideBarSection } from "@common/module/EcosuiteView"
import { LAYOUT_CONTENT_RIGHT } from "@common/module/EcosuiteView"
import Utils from "@common/utils/Utils"

import EconodeUtils from "src/econode/EconodeUtils"
import EconodeEconomicsUtils from "../EconodeEconomicsUtils"

import ConsumptionCostComparison from "./widgets/ConsumptionCostComparison"
import ConsumptionCost from "./widgets/ConsumptionCost"
import i18n from "src/i18n"

const { t } = i18n
export default class EconodeEconomicsDashboard extends EcosuiteView {
  getLayout() {
    return LAYOUT_CONTENT_RIGHT
  }

  renderMainView() {
    if (!this.props.projects) {
      return <Loading />
    } else if (this.props.projects.length < 1) {
      return <Warning message={`${t("alertsInfo.no_projects_available")}`} />
    }

    let sites = ProjectUtils.getSitesForProjects(this.props.projects)
    let systems = ProjectUtils.getSystemsForProjects(this.props.projects)
    let econodes = EconodeUtils.filterEconodeSystems(systems)

    return (
      <div className="EconomicsView">
        <table className="econode-info">
          <tbody>
            <tr>
              <td>
                <p className="highlight">{this.props.projects.length}</p>
                <p>{t("labels.projects")}</p>
              </td>
              <td>
                <p className="highlight">{sites.length}</p>
                <p>{t("labels.sites")}</p>
              </td>
              <td>
                <p className="highlight">{econodes.length}</p>
                <p>{t("econode.labels.econodes")}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="highlight">{EconodeEconomicsUtils.getFormattedTotalCost(this.props.consumptionCost)}</p>
                <p>{t("aggregates.This Period")}</p>
              </td>
              <td>
                <p className="highlight">{EconodeEconomicsUtils.getFormattedTotalCost(this.props.previousConsumptionCost)}</p>
                <p>{t("aggregates.Last Period")}</p>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderSiderBar() {
    return (
      <React.Fragment>
        {this.props.showConsumption ? (
          <SideBarSection title={t("econode.dataTitles.Cost This Period")} range={this.props.actualRange} content={<ConsumptionCost consumptionCost={this.props.consumptionCost} />} />
        ) : null}
        {this.props.showConsumption ? (
          <SideBarSection title={t("econode.dataTitles.Cost Last Period")} range={this.props.previousRange} content={<ConsumptionCost consumptionCost={this.props.previousConsumptionCost} />} />
        ) : null}
        {this.props.showConsumption ? (
          <SideBarSection
            title={t("econode.dataTitles.Compared to Last Period")}
            content={<ConsumptionCostComparison consumptionCost={this.props.consumptionCost} previousConsumptionCost={this.props.previousConsumptionCost} />}
          />
        ) : null}

        {this.props.showConsumption ? (
          <SideBarSection title={t("econode.dataTitles.Project Cost")} content={<ConsumptionCostPieGraph consumptionCost={this.props.consumptionCost} projects={this.props.projects} />} />
        ) : null}
      </React.Fragment>
    )
  }
}

class ConsumptionCostPieGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.consumptionCost)) {
      return <Error error={this.props.consumptionCost.getError()} />
    }
    if (this.isContentValid(this.props.consumptionCost)) {
      let data = this.props.projects.map((project) => {
        let projectReadings = this.props.consumptionCost.projects[project.code]
        return { name: project.code, value: projectReadings && projectReadings.consumptionCost ? projectReadings.consumptionCost.totalCost : null }
      })

      return (
        <div className="sidebar-graph">
          <PieChart width={300} height={220} onMouseEnter={this.onPieEnter}>
            <Pie data={data} dataKey="value" nameKey="name" labelLine={false} outerRadius={80} fill="#8884d8">
              {data.map((entry, index) => (
                <Cell fill={GRAPH_COLORS[index % GRAPH_COLORS.length]} key={index} />
              ))}
            </Pie>
            <Tooltip
              // eslint-disable-next-line no-unused-vars
              formatter={(value, name, props) => {
                return Utils.formatCurrency(value)
              }}
            />
            <Legend />
          </PieChart>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
