import API from "@common/API"
import Logger from "@common/Logger"

const ToolsService = {

  async getTimezoneForLatLong(lat: number, long: number): Promise<unknown> {
    return API.get<any>("/getTimezoneForLatLong" + "?lat=" + lat +"&long=" + long)
      .then((data) => data)
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: "failed to get timezone for lat: " + lat + "," + long})
      })
  }
}

export default ToolsService
