import React from "react"
import { Input } from "reactstrap"
import { Loading, Error } from "@common/EcosuiteComponent"
import MediaService from "./MediaService"
import i18n from "src/i18n"
import { getUserOrganizationId } from "@common/OrganizationUtils"

// class MediaInput
const { t } = i18n
export class MediaField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { formData: props.formData ? props.formData : "" }

    this.onChange = this.onChange.bind(this)
    this.upload = this.upload.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.formData !== prevProps.formData) {
      this.setState({ formData: this.props.formData ? this.props.formData : "" })
    }
  }

  onChange(event) {
    const value = event.target.value
    this.setState({ formData: value }, () => {
      this.props.onChange(value ? value : undefined)
    })
  }

  async upload(e) {
    const organizationId = await getUserOrganizationId()

    if (e.target.files.length) {
      this.setState({ loading: true })
      const file = e.target.files[0]
      MediaService.storeFile(`${organizationId}/project/${this.props.idSchema.$id}/${file.name}`, file)
        .then((url) => {
          this.setState({ formData: url, loading: false }, () => {
            this.props.onChange(url)
          })
        })
        .catch((err) => {
          this.setState({ error: err, loading: false })
        })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading message={t("loadingMsg.uploading_img")} />
    }
    return (
      <div>
        <Input type="text" value={this.state.formData} onChange={this.onChange} />
        {this.state.formData ? <img src={this.state.formData} alt="Project Banner" height="100px" /> : null}
        <Input type="file" onChange={this.upload} />
        {this.state.error ? <Error error={this.state.error} /> : null}
      </div>
    )
  }
}
