import aws, { S3 } from "aws-sdk"
import { Auth } from "aws-amplify"
import { ObjectList } from "aws-sdk/clients/s3"

/**
 * A Shared S3 Document service instance.
 */
export class S3DocumentService {
  bucket: string
  s3!: S3
  ready = false

  /**
   * Construct a new
   * @param bucket
   */
  constructor(bucket: string = process.env.REACT_APP_DOCUMENTS_BUCKET ?? "") {
    this.bucket = bucket

    this.prepare()
  }

  /**
   * Prepare the client authorization.
   */
  prepare = async () => {
    if (!this.s3 || !this.s3.config.token || !this.ready) {
      this.s3 = await this.initialize()
      this.ready = true
    } else {
      if (this.s3.config.token.needsRefresh()) {
        await this.s3.config.token.get((err) => {
          if (err) throw err.message
        })
      }
    }
  }

  /**
   * Initialize the S3 object.
   */
  initialize = async (): Promise<S3> => {
    const currentCredentials = await Auth.currentCredentials()
    return new aws.S3({
      apiVersion: "2013-04-01",
      credentials: Auth.essentialCredentials(currentCredentials),
    })
  }

  /**
   * List files given a prefix.
   * @param prefix - The files prefix.
   */
  listFiles = async (prefix: string): Promise<ObjectList | undefined> => {
    await this.prepare()

    const params: S3.Types.ListObjectsRequest = {
      Bucket: this.bucket,
      Prefix: prefix,
    }

    const response = await this.s3.listObjects(params).promise()
    return response.Contents
  }

  /**
   * Get a file.
   * @param key - The file key.
   */
  getFile = async (key: string): Promise<S3.Types.GetObjectOutput> => {
    await this.prepare()

    const params: S3.Types.GetObjectRequest = {
      Bucket: this.bucket,
      Key: key,
    }

    return await this.s3.getObject(params).promise().then()
  }

  /**
   * Download a file.
   * @param key - The file key.
   */
  downloadFile = async (key: string) => {
    await this.prepare()

    const params = {
      Bucket: this.bucket,
      Key: key,
    }

    this.s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) throw err.message

      window.open(url)
    })
  }

  /**
   * Delete a file.
   * @param key - The file key.
   */
  deleteFile = async (key: string) => {
    await this.prepare()

    const params = {
      Bucket: this.bucket,
      Key: key,
    }

    await this.s3.deleteObject(params, (err) => {
      if (err) throw err.message
    })
  }
}

const S3DocumentServiceInstance = new S3DocumentService()
export default S3DocumentServiceInstance
