import Aggregations from "@common/Aggregations"
// import Logger from "@common/Logger"

export const MAX_AGGREGATION = "month"

/** @type Object<String,String> */
const PowerUserAggregations = {
  None: "none",
  FiveMinute: "5minute",
  FifteenMinute: "15minute",
  Hour: "hour",
  Day: "day",
  Month: "month",
  Year: "year",
}

/**
 * Given a requested aggregation, returns the aggregation that should actually be used to reduce the amount of data returned.
 * The screen can only render so much data so we actively reduce where appropriate.
 * @param {DateRange} range The range that is being viewed
 * @param {String} aggregation The aggregate that has been requested
 * @param {int} limit The max size of content the x axis can display.
 */
export function getAggregationForRange(range, aggregation, limit) {
  // limit = window.screen.width ? window.screen.width : 2000

  const diff = range.diff(Aggregations.getUnits(aggregation))

  // Logger.debug(`getAggregationForRange: ${range}, aggregation: ${aggregation}, limit: ${limit}, diff ${diff}`)

  if (diff > limit && aggregation !== MAX_AGGREGATION) {
    // Look for larger aggregate to fit the data
    const nextAggregation = getLargerAggregation(aggregation)
    // Logger.debug(`Trying next aggregation: ${nextAggregation}`)
    const nextDiff =
      Math.round(range.diff(Aggregations.getUnits(nextAggregation)) / Aggregations.getSize(aggregation)) + 1
    if (nextDiff > limit) {
      // The next aggregation fits so recursively use it
      // Logger.debug(`Moving to next aggregation: ${nextAggregation}`)
      return getAggregationForRange(range, nextAggregation, limit)
    } else {
      // We lose fit using the next aggregation level so stick with the one supplied
      // Logger.debug(`Sticking with aggregation: ${aggregation}`)
      return aggregation
    }
  } else {
    // The supplied aggregation fits so we use that
    // Logger.debug(`Using aggregation: ${aggregation}`)
    return aggregation
  }
}

export function getLargerAggregation(aggregation) {
  switch (aggregation) {
    case PowerUserAggregations.None:
      return PowerUserAggregations.FiveMinute
    case PowerUserAggregations.FiveMinute:
      return PowerUserAggregations.FifteenMinute
    case PowerUserAggregations.FifteenMinute:
      return PowerUserAggregations.Hour
    case PowerUserAggregations.Hour:
      return PowerUserAggregations.Day
    default:
      return PowerUserAggregations.Month
  }
}

export default PowerUserAggregations
