import React from "react"
import { Button, Row } from "reactstrap"
import { sortBy } from "lodash"
import EcosuiteComponent, { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import PortfolioDetails from "./PortfolioDetails"
import PortfolioService from "./PortfolioService"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
export default class PortfolioList extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.selectPortfolio = this.selectPortfolio.bind(this)
    this.loadPortfolios = this.loadPortfolios.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadPortfolios()

    let params = new URLSearchParams(window.location.search)
    if (params.has("new-portfolio")) {
      const projectIds = params.get("projectIds").split(",")
      this.selectPortfolio({ projects: projectIds })
    }
  }

  loadPortfolios() {
    this.setStateIfMounted({ portfolios: null, portfolio: null })

    PortfolioService.getPortfolios()
      .then((portfolios) => {
        this.setStateIfMounted({ portfolios: portfolios })
      })
      .catch((err) => {
        this.setStateIfMounted(new EcosuiteComponentError(err))
      })
  }

  selectPortfolio(portfolio, readonly) {
    this.setStateIfMounted({ portfolio: portfolio, readonly: readonly })
  }

  renderContent() {
    if (this.isContentError(this.state.portfolios)) {
      return <Error error={this.state.portfolios.getError()} />
    } else if (this.state.portfolio) {
      return (
        <PortfolioDetails
          portfolio={this.state.portfolio}
          projects={this.props.allProjects}
          readonly={this.state.readonly}
          actions={{
            selectPortfolio: this.selectPortfolio,
            portfolioUpdated: this.loadPortfolios,
            portfolioDeleted: this.loadPortfolios,
          }}
        />
      )
    } else if (this.isContentValid(this.state.portfolios)) {
      var portfolios = this.state.portfolios
      var sortedPortfolios = sortBy(portfolios, "name")
      if (sortedPortfolios.length > 0) {
        return (
          <div className="ecogy-form admin-tab-content-area">
            <table className="project-table">
              <thead>
                <tr>
                  <th className="edit"></th>
                  {this.props.groups.includes("data-write") ? <th className="edit"></th> : null}
                  <th>{t("table_headings.name")}</th>
                  <th>{t("labels.project")}</th>
                </tr>
              </thead>
              <tbody>
                {sortedPortfolios.map((portfolio) => {
                  return (
                    <tr key={portfolio.id}>
                      <td>
                        <Button
                          color="link"
                          size="sm"
                          title={`${t("buttons.view_record")}`}
                          onClick={() => {
                            this.selectPortfolio(portfolio, true)
                          }}
                        >
                          <Icon icon="link" />
                        </Button>
                      </td>
                      {this.props.groups.includes("data-write") ? (
                        <td>
                          <Button
                            color="link"
                            size="sm"
                            title={`${t("buttons.edit_record")}`}
                            onClick={() => {
                              this.selectPortfolio(portfolio, false)
                            }}
                          >
                            <Icon icon="edit" />
                          </Button>
                        </td>
                      ) : null}
                      <td>{portfolio.name}</td>
                      <td>{portfolio.projects.join(", ")}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Row>{this.renderViewControls()}</Row>
          </div>
        )
      } else {
        return (
          <>
            <div className="no-documents">{t("warnings.no_portfolios")}</div>
            <Row>{this.renderViewControls()}</Row>
          </>
        )
      }
    } else {
      return <Loading />
    }
  }

  renderViewControls() {
    if (this.props.groups.includes("data-write")) {
      return (
        <div className="admin-footer">
          <Button
            className="float-end"
            color="ecogy"
            onClick={() => {
              this.selectPortfolio({})
            }}
            size="sm"
          >
            {t("buttons.new_portfolio")}
          </Button>
        </div>
      )
    } else {
      return null
    }
  }
}
