import React from "react"
import { Button, Row, Col, Container } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"

import UserAdminService from "../UserAdminService"

import CreateUserType from "./CreateUserType"
import EditUserType from "./EditUserType"
import i18n from "src/i18n"

const { t } = i18n
class UserTypes extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.refreshUserType = this.refreshUserType.bind(this)
    this.loadUserTypes = this.loadUserTypes.bind(this)
    this.selectUserType = this.selectUserType.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadUserTypes()
  }

  loadUserTypes() {
    this.setState({ userTypes: [] })

    UserAdminService.getUserTypes()
      .then((response) => {
        let selectedUserTypeId = this.props.selectedUserTypeId
        let userTypes = response.userTypes.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })

        let userType = userTypes.find((userType) => {
          return userType.id === selectedUserTypeId
        })

        this.setStateIfMounted({
          userTypes: userTypes,
          selectedUserType: userType,
        })
      })
      .catch((error) => {
        Logger.error(error)
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  refreshUserType(userType) {
    for (var i = 0; i < this.state.userTypes.length; i++) {
      if (this.state.userTypes[i].id === userType.id) {
        this.state.userTypes[i] = userType
        break
      }
    }
    this.setState({ userTypes: this.state.userTypes, selectedUserType: null })
  }

  componentDidUpdate(prevProps) {
    if (this.state.userTypes.length > 0 && this.props.selectedUserTypeId !== prevProps.selectedUserTypeId) {
      let userType = this.state.userTypes.find((userType) => {
        return userType.id === this.props.selectedUserTypeId
      })
      this.setStateIfMounted({
        selectedUserType: userType,
      })
    }
  }

  selectUserType(userType) {
    if (this.state.selectedUserType && userType && this.state.selectedUserType.id === userType.id) {
      userType = null
    }
    this.setStateIfMounted({
      selectedUserType: userType,
    })
  }

  renderContent() {
    if (this.state.selectedUserType && !this.state.selectedUserType.id) {
      return <CreateUserType userType={this.state.selectedUserType} selectUserType={this.selectUserType} userTypeAdded={this.loadUserTypes} />
    }

    return this.state.selectedUserType ? (
      <Row>
        <Container fluid={true} className="Admin-content">
          <Row>
            <Col md="3">{this.renderUserTypeList()}</Col>
            <Col md="9">{this.renderUserTypeDetails()}</Col>
          </Row>
        </Container>
      </Row>
    ) : (
      <Row>
        <Container fluid={true} className="Admin-content">
          {this.renderUserTypeList()}
        </Container>
        <Container fluid={true} className="Admin-footer">
          <div className="admin-footer">
            <Button
              className="header-button float-end"
              color="ecogy"
              onClick={() => {
                this.selectUserType({})
              }}
            >
              {t("settings.labels.new_userType")}
            </Button>
          </div>
        </Container>
      </Row>
    )
  }

  renderUserTypeList() {
    if (this.state.userTypes && this.state.userTypes.length > 0) {
      return (
        <div className="admin-content admin-content-area">
          <table className="user-table">
            <thead>
              <tr>
                <th className="user-name">User Type</th>
                {!this.state.selectedUserType ? (
                  <React.Fragment>
                    <th>{t("table_headings.application")}</th>
                  </React.Fragment>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {this.state.userTypes.map((userType) => {
                const isActive = this.state.selectedUserType && this.state.selectedUserType.id === userType.id
                return (
                  <tr key={userType.id}>
                    <td
                      title={`User Type: ${userType.id}`}
                      className={`${isActive ? " active" : ""}`}
                      style={{ paddingRight: this.state.selectedUserType ? "0px" : "20px" }}
                      onClick={() => {
                        this.selectUserType(userType)
                      }}
                    >
                      <Button className="user-button">{userType.name}</Button>{" "}
                    </td>
                    {!this.state.selectedUserType ? (
                      <React.Fragment>
                        <td>{userType.application}</td>
                      </React.Fragment>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderUserTypeDetails() {
    return (
      <div className="admin-content-area">
        <EditUserType userType={this.state.selectedUserType} userTypeChanged={this.refreshUserType} selectUserType={this.selectUserType} userTypeDeleted={this.loadUserTypes} />
      </div>
    )
  }
}

export default UserTypes
