import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"

/**
 * List all tariffs.
 */
export const listTariffs = (): Promise<Tariff[]> => {
  const uri = "/tariff"
  return API.get(uri)
    .then((response) => {
      const data = response as { tariffs: Tariff[] }
      return data.tariffs
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Get the tariff schema.
 */
export const tariffSchema = (): Promise<Schema> => {
  const uri = "/schemas/tariff"
  return API.get(uri)
    .then((response) => {
      const data = response as { schema: Schema }
      return data.schema
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Create a new tariff.
 */
export const newTariff = (newTariff: Tariff): Promise<Tariff> => {
  const uri = "/tariff"
  return API.post(uri, newTariff)
    .then((response) => {
      const data = response as { tariff: Tariff }
      return data.tariff
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to post '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Update a tariff.
 */
export const updateTariff = (tariff: Tariff): Promise<Tariff> => {
  const uri = "/tariff"
  return API.put(uri, tariff)
    .then((response) => {
      const data = response as { tariff: Tariff }
      return data.tariff
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to put '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Update a tariff.
 * @param id - The tariff ID.
 */
export const deleteTariff = (id: string): Promise<string> => {
  const uri = `/tariff/${id}`
  return API.delete(uri)
    .then((response) => {
      const data = response as { deleted: string }
      return data.deleted
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to delete '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Get a tariff.
 * @param id - The tariff ID.
 */
export const getTariff = (id: string): Promise<Tariff> => {
  const uri = `/tariff/${id}`
  return API.get(uri)
    .then((response) => {
      const data = response as { tariff: Tariff }
      return data.tariff
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    })
}
