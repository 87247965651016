import * as React from "react"
import { DataType, SortingMode } from "ka-table/enums"
import { Table } from "ka-table"
import Icon from "@common/display/Icon"
import EditExternalUser from "./EditExternalUser"
import { removeExternalUserApi, useExternalUsers } from "src/services/users"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import NiceModal from "@ebay/nice-modal-react"
import ConfirmDialog from "@common/display/ConfirmModal/ConfirmModal"
import SlidingPane from "react-sliding-pane"
import "react-sliding-pane/dist/react-sliding-pane.css"

export default function ExternalUsersList() {
  const { data, isLoading, isError, isFetching } = useExternalUsers()
  const [editingUser, setEditingUser] = React.useState(null)

  const queryClient = useQueryClient()

  const mutation = useMutation(removeExternalUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(["externalUsers"])
      toast.success("Successfully removed external user!")
    },
    onError: (error) => {
      toast.error("Something went wrong while removing the user!")
    },
  })

  const handleEdit = (user: any) => {
    setEditingUser(user)
  }

  const handleDelete = (userId: string) => {
    NiceModal.show(ConfirmDialog, {
      size: "md",
      title: "Delete confirmation",
      message: <div>Are you sure you want to remove this user?</div>,
      confirmText: "Confirm",
      onConfirm: () => mutation.mutateAsync(userId),
    })
  }

  if (isError) return <div>Error fetching external users.</div>
  if (isLoading) return <div>Loading...</div>

  return (
    <div
      style={{ height: "calc(100vh - 100px)", display: "flex", flexDirection: "column" }}
      className="dark tw-bg-background tw-text-foreground"
    >
      <h2 style={{ textAlign: "center", marginTop: "8px" }}>External Users</h2>

      <div className="custom-theme-demo" style={{ height: "100%", overflow: "auto", marginTop: "16px" }}>
        <Table
          loading={{
            enabled: isFetching,
            text: "Refetching data...",
          }}
          columns={[
            { key: "userId", title: "User ID", dataType: DataType.String },
            { key: "email", title: "Email", dataType: DataType.String },
            { key: "firstName", title: "First Name", dataType: DataType.String },
            { key: "lastName", title: "Last Name", dataType: DataType.String },
            { key: "userType", title: "User Type", dataType: DataType.String },
            { key: "organization", title: "Organization", dataType: DataType.String },
            { key: "actions", title: "Actions", width: 100 },
          ]}
          data={data}
          rowKeyField={"userId"}
          noData={{
            text: "No external users found",
          }}
          sortingMode={SortingMode.Single}
          childComponents={{
            cellText: {
              content: ({ column, rowData }) => {
                switch (column.key) {
                  case "userType":
                    return rowData.userType.name
                  case "organization":
                    return rowData.organization.name
                  case "actions":
                    return (
                      <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <Icon icon="edit" style={{ cursor: "pointer" }} onClick={() => handleEdit(rowData)} />
                        <Icon
                          icon="delete"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(rowData.userId)}
                        />
                      </div>
                    )
                  default:
                    return undefined
                }
              },
            },
          }}
        />
      </div>

      <SlidingPane
        isOpen={editingUser !== null}
        from="right"
        width="600px"
        onRequestClose={() => setEditingUser(null)}
        hideHeader
      >
        {editingUser && <EditExternalUser user={editingUser} onClose={() => setEditingUser(null)} />}
      </SlidingPane>
    </div>
  )
}
