import React from "react"
import { Popover, PopoverHeader, PopoverBody, Container, Row, Col, UncontrolledPopover } from "reactstrap"
import moment from "moment"

import EcosuiteComponent from "@common/EcosuiteComponent"
import i18n from "src/i18n"

const { t } = i18n
export default class CalendarPopover extends EcosuiteComponent {
  renderContent() {
    if (this.props.event) {
      const task = this.props.event.extendedProps.task
      const process = this.props.event.extendedProps.process
      const record = this.props.event.extendedProps.record

      if (task) {
        return this.renderTaskTooltip(process, task)
      } else if (process) {
        return this.renderProcessTooltip(process)
      } else if (record) {
        return this.renderRecordTooltip(record)
      }
    } else {
      return null
    }
  }

  renderRecordTooltip(record) {
    if (this.props.event) {
      return (
        <Popover placement="right" target={this.props.event.id} isOpen={this.props.event !== null} className="calendar-dot-popover" fade={false}>
          <PopoverHeader>{this.props.event.title}</PopoverHeader>
          <PopoverBody>
            <Container>
              <Row>
                <Col md={5}>
                  <label>{t("labels.asset")}: </label>
                </Col>
                <Col>{record.path}</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <label>{t("table_headings.record")}: </label>
                </Col>
                <Col>{record.name}</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <label>{t("table_headings.date")}: </label>
                </Col>
                <Col>{moment(record.rawDate).format("lll")}</Col>
              </Row>
            </Container>
          </PopoverBody>
        </Popover>
      )
    } else {
      return null
    }
  }

  renderProcessTooltip(process) {
    if (this.props.event) {
      return (
        <UncontrolledPopover placement="right" target={this.props.event.id} isOpen={this.props.event !== null} className="calendar-dot-popover" fade={false}>
          <PopoverHeader>{this.props.event.title}</PopoverHeader>
          <PopoverBody>
            <Container>
              <Row>
                <Col md={5}>
                  <label>{t("labels.project")}: </label>
                </Col>
                <Col>{process.projectName}</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <label>{t("labels.started")}: </label>
                </Col>
                <Col>{process.startDate ? moment(process.startDate).format("lll") : "-"}</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <label>{t("process.labels.days_elapsed")}: </label>
                </Col>
                <Col>{process.daysElapsed.toLocaleString(undefined, { maximumFractionDigits: 1 })}</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <label>{t("process.labels.target_days")}: </label>
                </Col>
                <Col>{process.targetDays.toLocaleString(undefined, { maximumFractionDigits: 1 })}</Col>
              </Row>
            </Container>
          </PopoverBody>
        </UncontrolledPopover>
      )
    } else {
      return null
    }
  }

  renderTaskTooltip(process, task) {
    const isComplete = task.endTime !== null && task.endTime !== undefined
    const isDue = !task.endTime && task.due
    const isOverdue = isDue && moment(task.due).isSameOrBefore(moment())

    var statusClassName = "open"
    if (isOverdue) {
      statusClassName = "overdue"
    } else if (isDue) {
      statusClassName = "due"
    } else if (isComplete) {
      statusClassName = "complete"
    }

    if (this.props.event) {
      return (
        <Popover placement="right" target={this.props.event.id} isOpen={this.props.event !== null} className="calendar-dot-popover" fade={false}>
          <PopoverHeader className={statusClassName}>{this.props.event.title}</PopoverHeader>
          <PopoverBody>
            <Container>
              <Row>
                <Col md={5}>
                  <label>{t("labels.project")}: </label>
                </Col>
                <Col>{process.projectName}</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <label>{t("labels.started")}: </label>
                </Col>
                <Col>{task.startTime ? moment(task.startTime).format("lll") : task.created ? moment(task.created).format("lll") : "-"}</Col>
              </Row>
              <Row className={statusClassName}>
                <Col md={5}>
                  <label>{t("labels.due")}: </label>
                </Col>
                <Col>{task.due ? moment(task.due).format("lll") : "-"}</Col>
              </Row>
              <Row>
                <Col md={5}>
                  <label>{t("labels.completed")}: </label>
                </Col>
                <Col>{task.endTime ? moment(task.endTime).format("lll") : "-"}</Col>
              </Row>
            </Container>
          </PopoverBody>
        </Popover>
      )
    } else {
      return null
    }
  }
}
