import Icon from "@common/display/Icon"
import React, { useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

const UserAuditViewControls = ({
  // Props
  auditFilters,
  toggleAuditFilters,
}: {
  auditFilters: {
    filters: Record<string, boolean>
    key: string
    name: string
  }[]
  toggleAuditFilters: (key: string, toggledAuditFilters: Record<string, boolean>) => void
}): JSX.Element => {
  const [isDropDownOpen, setDropDownOpen] = useState<Record<string, boolean>>({})

  return (
    <div className="admin-footer">
      <div className="btn-toolbar">
      {auditFilters.map(({ filters, key, name }) => {
        return (
          <ButtonDropdown
            key={`${key}-filter`}
            direction="up"
            size="sm"
            isOpen={isDropDownOpen[key]}
            toggle={(e: React.MouseEvent<HTMLElement>) => {
              if (e.currentTarget?.id?.includes(`${key}-toggle`)) {
                setDropDownOpen({ [key]: !isDropDownOpen[key] })
              } else {
                setDropDownOpen({ [key]: e.currentTarget?.id?.includes("user-audit-type") })
              }
            }}
          >
            {/*   Dropdown Toggle Button   */}
            <DropdownToggle caret id={`user-audit-view-controls__${key}-toggle`} className="user-audit-view-controls__toggle">
              <span>{name}</span>
            </DropdownToggle>
            <DropdownMenu>
              {/* Dropdown Item for each diff type   */}
              {Object.entries(filters).map(([filter, bool]) => {
                return (
                  <DropdownItem
                    id={`user-audit-type-${filter}`}
                    key={`user-audit-type-${filter}`}
                    className="user-audit-view-controls__dropdown-item"
                    onClick={() => {
                      toggleAuditFilters(key, { ...filters, [filter]: !bool })
                    }}
                  >
                    <span>{filter}</span>
                    <Icon icon={bool ? "check_box" : "check_box_outline_blank"} />
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </ButtonDropdown>
        )
      })}
      </div>
    </div>
  )
}

export default UserAuditViewControls
