import API from "@common/API"

const ReportsService = {
  getFinanceReports(projectIds: Array<string>, aggregate: string, mode: string, flags: [string]) {
    let url = `/finance/report?projectIds=${projectIds.join(",")}`
    if (aggregate) {
      url += `&aggregate=${aggregate}`
    }
    if (mode) {
      url += `&mode=${mode}`
    }
    if (flags && flags.length) {
      url = API.addParamToUrl(url, "flags", flags.join(","))
    }
    return API.get(url)
  },

  /**
   * Gets the IRR Report for a project.
   *
   * @param {*} projectId The ID of the project to get the report for
   * @param {*} proFormaVersionId (optional) The ID of the Pro Forma version to apply to the report; if not specified the current pro forma is used
   * @returns the IRR report for the project
   */
  getFinanceReport(projectId: string, proFormaVersionId: number, flags: [string]) {
    let url = "/finance/projects/" + projectId + "/report"
    if (proFormaVersionId) {
      url = API.addParamToUrl(url, "proFormaVersionId", encodeURIComponent(proFormaVersionId))
    }
    if (flags && flags.length) {
      url = API.addParamToUrl(url, "flags", flags.join(","))
    }
    return API.get(url)
  },

  getSourcesUses(projectIds: Array<string>, mode: string, flags: [string]) {
    let url = `/finance/sources-uses?projectIds=${projectIds.join(",")}&mode=${mode}`
    if (flags && flags.length) {
      url = API.addParamToUrl(url, "flags", flags.join(","))
    }
    return API.get(url)
  },

  getProjectSourcesUses(projectId: string, mode: string, flags: [string], proFormaVersionId: string) {
    let url = `/finance/projects/${projectId}/sources-uses?mode=${mode}`
    if (flags && flags.length) {
      url = API.addParamToUrl(url, "flags", flags.join(","))
    }
    if (proFormaVersionId) {
      url += `&proFormaVersionId=${encodeURIComponent(proFormaVersionId)}`
    }
    return API.get(url)
  },

  async getProFormaVersions(projectId: string) {
    return API.get<{ versions: Array<{ created: string }> }>(`/projects/${projectId}/pro-forma/versions`).then((response) =>
      response.versions.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()),
    )
  },

  async updateFinanceReport(projectId: string) {
    const url = "/finance/projects/" + projectId + "/report"
    return API.put(url)
  },

  async exportReport(projectIds: Array<string>, aggregate: string, columns: Array<string>, includeProjectBreakdown: boolean, mode: string, includeSubAccounts: boolean, flags: [string]) {
    let url = `/finance/export/report?projectIds=${projectIds.join(",")}`
    if (aggregate) {
      url += `&aggregate=${aggregate}`
    }
    if (columns) {
      url += `&columns=${columns.join(",")}`
    }
    if (includeProjectBreakdown) {
      url += `&includeProjectBreakdown=${includeProjectBreakdown}`
    }
    if (includeSubAccounts) {
      url += `&includeSubAccounts=${includeSubAccounts}`
    }
    if (mode) {
      url += `&mode=${mode}`
    }
    if (flags && flags.length) {
      url = API.addParamToUrl(url, "flags", flags.join(","))
    }
    return API.get(url)
  },
}

export default ReportsService
