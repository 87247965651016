import API from "@common/API"
import Logger from "@common/Logger"

const PortfolioService = {
  async getPortfolios() {
    return API.get<{ portfolios: unknown }>(`/portfolios`).then((data) => {
      return data.portfolios
    })
  },

  createPortfolio(portfolio: unknown): Promise<unknown> {
    return API.post("/portfolios", portfolio)
  },

  updatePortfolio(id: string, portfolio: unknown, note: string): Promise<unknown> {
    Logger.debug("Updating portfolio: " + id)
    return API.put(`/portfolios/${id}?note=${encodeURIComponent(note)}`, portfolio)
  },

  deletePortfolio(id: string, note: string): Promise<unknown> {
    return API.delete(`/portfolios/${id}?note=${encodeURIComponent(note)}`)
  },
}

export default PortfolioService
