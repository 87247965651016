import React from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"

import EcosuiteView from "@common/module/EcosuiteView"
import { Loading, Error, Message } from "@common/EcosuiteComponent"
import Schemas from "@common/Schemas"
import Logger from "@common/Logger"

import CashFlowPaymentLogs from "./payment-logs/CashFlowPaymentLogs"
import PaymentLogControls from "./payment-logs/PaymentLogControls"

import ProFormaService from "@dashboard/data/pro-forma/ProFormaService"
import FinanceService from "../FinanceService"
import "./records/RecordPayments.css"
import CashFlowPaymentLogsGraph from "./payment-logs/CashFlowPaymentLogsGraph"
import FinanceUtils from "../FinanceUtils"
import i18n from "src/i18n"
import { ExportCashFlowPaymentLogsFull } from "./payment-logs/ExportCashFlowPaymentLogs"

const { t } = i18n
export default class CashFlowPaymentLogsView extends EcosuiteView {
  constructor(props) {
    super(props, "finance-contracts")

    this.state = {
      selectedYear: "most recent",
    }

    this.toggle = this.toggle.bind(this)
    this.updatePaymentLogs = this.updatePaymentLogs.bind(this)
    this.setYear = this.setYear.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadSchema()
    this.loadPaymentLogs()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadPaymentLogs()
    }
  }

  async loadSchema() {
    await Schemas.getPaymentLogsSchema()
      .then((paymentLogsSchema) => {
        this.setStateIfMounted({ paymentLogsSchema: paymentLogsSchema })
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err })
      })
  }

  async loadPaymentLogs(activeTab) {
    const projectId = this.props.project.code
    ProFormaService.getProjectProForma(projectId)
      .then(async (proForma) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ paymentLogs: null, error: null })

          const cashFlows = proForma.cashFlows
            ? proForma.cashFlows.filter((cashFlow) => {
                return (
                  cashFlow.payments &&
                  cashFlow.payments.find((payment) => FinanceUtils.isOperatingRevenuePayment(cashFlow, payment))
                ) // || payment.paymentType === "tariff")
              })
            : []

          const paymentLogs = await Promise.all(
            cashFlows.map((cashFlow) => {
              return FinanceService.getCashFlowPaymentLogs(projectId, cashFlow.id)
            }),
          )

          this.setStateIfMounted({
            paymentLogs: paymentLogs,
            activeTab: activeTab ? activeTab : paymentLogs.length ? 0 : null,
          })
        } else {
          Logger.debug(`Ignoring out of date response for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ error: err })
        }
      })
  }

  async updatePaymentLogs() {
    // TODO make this just load the updated payment

    this.loadPaymentLogs(this.state.activeTab)
  }

  setYear(year) {
    this.setState({ selectedYear: year })
  }

  renderViewControls() {
    if (!this.state.paymentLogs || !this.state.paymentLogs.length) {
      return
    }

    return (
      <PaymentLogControls
        cashFlowPaymentLogs={this.state.paymentLogs[this.state.activeTab]}
        selectedYear={this.state.selectedYear}
        actions={{
          setYear: this.setYear,
        }}
      />
    )
  }

  renderMainView() {
    if (this.state.error) {
      return <Error error={this.state.error} />
    }
    if (this.state.paymentLogs) {
      if (!this.state.paymentLogs) {
        return <Message message={t("economics.messages.no_cashflow_payments")} />
      }
      return (
        <div className="record-payments">
          <ExportCashFlowPaymentLogsFull
            data={this.state.paymentLogs.reduce((acc, cur) => {
              acc[cur.cashFlow.name] = cur.paymentLogs.payments
              return acc
            }, {})}
            name={`${this.props.project.code}-cashflow`}
          />
          <Nav tabs className="record-payment-tabs">
            {this.state.paymentLogs.map((cashFlowPaymentLogs, idx) => {
              return (
                <NavItem key={idx}>
                  <NavLink
                    className={this.state.activeTab === idx ? "active" : null}
                    onClick={() => {
                      this.toggle(idx)
                    }}
                  >
                    {cashFlowPaymentLogs.cashFlow.name}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            {this.state.paymentLogs.map((cashFlowPaymentLogs, idx) => {
              return (
                <TabPane tabId={idx} key={idx}>
                  <CashFlowPaymentLogsGraph cashFlowPaymentLogs={cashFlowPaymentLogs} />
                  <CashFlowPaymentLogs
                    selectedYear={this.state.selectedYear}
                    project={this.props.project}
                    paymentLogsSchema={this.state.paymentLogsSchema}
                    cashFlowPaymentLogs={cashFlowPaymentLogs}
                    readonly={this.props.readonly}
                    actions={{ updatePaymentLogs: this.updatePaymentLogs }}
                  />
                </TabPane>
              )
            })}
          </TabContent>
        </div>
      )
    } else {
      return <Loading message={t("economics.messages.loading_cashflow_payment")} />
    }
  }
}
