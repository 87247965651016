import ConfirmDialog from "@common/display/ConfirmModal/ConfirmModal"
import Icon from "@common/display/Icon"
import Table from "@common/display/Table"
import WithToolTip from "@common/display/ToolTip"
import NiceModal from "@ebay/nice-modal-react"
import Fuse from "fuse.js"
import { t } from "i18next"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { Button, Input } from "reactstrap"
import { useContacts, useDeleteContact } from "src/services/contacts"

export default function Contacts() {
  const { data, isLoading, isError } = useContacts()
  const { mutateAsync } = useDeleteContact()
  const [search, setSearch] = React.useState("")

  const history = useHistory()

  React.useEffect(() => {
    document.title = "Contacts - Ecosuite"
  })

  const handleDelete = (id: string, name: string) => {
    NiceModal.show(ConfirmDialog, {
      size: "md",
      title: "Delete confirmation",
      message: <div>Are you sure you want to delete the contact of: {name}?</div>,
      confirmText: "Confirm",
      onConfirm: () => mutateAsync(id),
    })
  }

  const fuseOptions = {
    keys: ["name", "type", "email", "phone", "voip", "notes"],
    threshold: 0.2,
  }
  const fuse = new Fuse(data || [], fuseOptions)
  const result = search.length ? fuse.search(search).map((r) => r.item) : data || []

  if (isLoading) return <div>Fetching data...</div>
  if (isError) return <div>Sorry, something went wrong.</div>

  return (
    <div className="Module">
      <div className="module-content" style={{ width: "100%" }}>
        <div className="content" style={{ width: "100%" }}>
          <div className="finance-table" style={{ width: "100%", height: "calc(100vh - 120px)" }}>
            <div
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "16px" }}
            >
              <h2 style={{ padding: "16px", textAlign: "center" }}>Global Contacts Repository</h2>
              <div>
                <Button color="primary" onClick={() => history.push("/admin/contacts/create")}>
                  Create new contact
                </Button>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginRight: "16px" }}>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ width: "300px" }}
                placeholder="Search..."
                autoFocus
              />
            </div>
            <Table
              defaultColumn={{ accessorKey: "id" }}
              data={result.map((d, i) => ({ ...d, sn: i + 1 }))}
              initialState={{
                sorting: [{ id: "name", desc: false }],
              }}
              columns={[
                {
                  id: "name",
                  accessorKey: "name",
                  header: () => <span>{t("table_headings.name")}</span>,
                  cell: ({ row, getValue }) => <Table.DefaultRowExpander row={row} getValue={getValue} />,
                  size: 150,
                },
                {
                  accessorFn: (row) => row.type,
                  id: "type",
                  header: "type",
                  size: 80,
                },
                {
                  id: "email",
                  accessorKey: "email",
                  header: "email",
                  size: 150,
                },
                { id: "phone", accessorKey: "phone", header: "phone", size: 150 },
                {
                  id: "voip",
                  accessorKey: "voip",
                  header: "voip",
                  size: 150,
                },
                {
                  id: "notes",
                  accessorKey: "notes",
                  header: "notes",
                  size: 300,
                },
                {
                  id: "actions",
                  header: "Actions",
                  size: 50,
                  cell: ({ row, getValue }) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div style={{ cursor: "pointer" }}>
                        <WithToolTip tooltip="Edit">
                          <Icon
                            icon="edit"
                            className="date-warning"
                            onClick={() => history.push(`/admin/contacts/update/${getValue()}`)}
                          />
                        </WithToolTip>
                      </div>
                      <div style={{ cursor: "pointer", marginLeft: "16px" }}>
                        <WithToolTip tooltip="Delete">
                          <Icon
                            icon="delete"
                            className="date-warning"
                            onClick={() => handleDelete(getValue() as string, row.original.name)}
                          />
                        </WithToolTip>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
