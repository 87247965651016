import React, { useState } from "react"
import { Typeahead } from "react-bootstrap-typeahead"

export function addAutoComplete(uiSchema, autoCompletes, readonly, propertyName) {
  if (!uiSchema[propertyName]) {
    uiSchema[propertyName] = {}
  }
  uiSchema[propertyName]["ui:widget"] = (props) => {
    const [localValue, setLocalValue] = useState(props.value)
    //Using a seperate value so that users can enter values manually and have them be saved automatically
    return (
      <Typeahead
        id={`${propertyName}AutoComplete`}
        labelKey={propertyName}
        onBlur={() => {
          if (localValue !== props.value) {
            props.onChange(localValue)
          }
        }}
        onInputChange={(change) => {
          setLocalValue(change)
        }}
        onChange={(selected) => {
          if (!(selected && selected.length)) {
            return
          }
          setLocalValue(selected[0].customOption ? selected[0][propertyName] : selected[0])
          props.onChange(selected[0].customOption ? selected[0][propertyName] : selected[0])
        }}
        options={autoCompletes ? Object.keys(autoCompletes[propertyName]).sort() : []}
        selected={localValue ? [localValue] : []}
        allowNew={true}
        disabled={readonly}
      />
    )
  }
}
