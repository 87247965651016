import React from "react"
import PropTypes from "prop-types"

import { PieChart, Pie, Cell, XAxis, YAxis, Tooltip, Legend, BarChart, Bar } from "recharts"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"

import EnergyUtils from "../EnergyUtils"
import i18n from "src/i18n"

const { t } = i18n
class InstantaneousGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.instantaneous)) {
      return <Error error={this.props.instantaneous.getError()} />
    }
    if (this.isContentValid(this.props.instantaneous)) {
      let graph = null
      let totalGeneration = this.props.instantaneous.generation

      let data = Object.values(this.props.projects).map((project, idx) => {
        let watts = this.props.instantaneous.projects[project.code] ? this.props.instantaneous.projects[project.code].generation : 0

        return {
          name: project.code,
          value: EnergyUtils.convertToKilo(watts),
          label: EnergyUtils.formatWattsAsKiloWatts(watts),
          project: project,
          datums: this.isContentValid(this.props.datums) ? this.props.datums.projects[project.code] : null,
          fill: GRAPH_COLORS[idx % GRAPH_COLORS.length],
        }
      })

      if (data && data.length > 0) {
        graph = <InstantaneousBarGraph data={data} projects={this.props.projects} />
      }

      return (
        <div>
          <div className="sidebar-heading">{EnergyUtils.formatWattsAsKiloWatts(totalGeneration)}</div>
          {graph}
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

class InstantaneousBarGraph extends EcosuiteComponent {
  renderContent() {
    return (
      <div className="sidebar-graph">
        <BarChart
          width={300}
          height={200}
          data={this.props.data}
          label={false}
          onMouseEnter={this.onPieEnter}
          margin={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis
            domain={[
              0,
              (dataMax) => {
                return dataMax > 100 ? dataMax : 100
              },
            ]}
          />

          <Tooltip content={<InstantaneousGraphTooltip />} />

          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </div>
    )
  }
}

class InstantaneousGraphTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
  }

  renderContent() {
    const { active } = this.props

    if (active) {
      const { payload } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            {payload.map((data) => {
              let entry = data.payload
              return (
                <div key={entry.project.code}>
                  <p className="label">
                    <b>{entry.project.code + ": " + entry.project.name}</b>
                  </p>
                  <p className="label">{`${t("energy.graphNames.instantaneous")}: ${EnergyUtils.formatKiloWatts(entry.value ? entry.value : 0)}`}</p>
                  <p className="label">{`${t("energy.graphNames.todays_peak")}: ${
                    entry.datums && entry.datums.peakGeneration ? EnergyUtils.formatWattsAsKiloWatts(entry.datums.peakGeneration) : "-"
                  }`}</p>
                  <p className="label">{`${t("energy.graphNames.project_peak")}: ${entry.project && entry.project.dcSize ? EnergyUtils.formatKiloWatts(entry.project.dcSize) : "-"}`}</p>
                </div>
              )
            })}
          </div>
        )
      }
    }

    return null
  }
}

class ConsumptionGenerationPieGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentValid(this.props.readings)) {
      let gen = this.props.readings.generation
      let con = this.props.readings.consumption
      let data = [
        { name: `${t("labels.generation")}`, value: gen },
        { name: `${t("labels.consumption")}`, value: con },
      ]

      return <EnergyPieGraph data={data} />
    } else {
      return <Loading />
    }
  }
}

class SelfConsumptionPieGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentValid(this.props.readings)) {
      let exp = this.props.readings.export
      let con = this.props.readings.consumption
      let data = [
        { name: `${t("buttons.export")}`, value: exp },
        { name: `${t("labels.consumption")}`, value: con },
      ]

      return <EnergyPieGraph data={data} />
    } else {
      return <Loading />
    }
  }
}

class EnergyConsumedPieGraph extends EcosuiteComponent {
  renderContent() {
    if (this.isContentError(this.props.readings)) {
      return <Error error={this.props.readings.getError()} />
    }
    if (this.isContentValid(this.props.readings)) {
      let sto = this.props.readings.storage
      let gen = this.props.readings.generation
      let con = this.props.readings.consumption
      let exp = this.props.readings.export
      let data = [
        { name: `${t("labels.grid")}`, value: con },
        { name: `${t("labels.battery")}`, value: sto },
        { name: `${t("labels.solar")}`, value: gen - exp },
      ]

      return <EnergyPieGraph data={data} />
    } else {
      return <Loading />
    }
  }
}

class EnergyPieGraph extends EcosuiteComponent {
  renderContent() {
    return (
      <div className="sidebar-graph">
        <PieChart width={300} height={220} onMouseEnter={this.onPieEnter}>
          <Pie data={this.props.data} dataKey="value" nameKey="name" labelLine={false} outerRadius={80} fill="#8884d8">
            {this.props.data.map((entry, index) => (
              <Cell fill={GRAPH_COLORS[index % GRAPH_COLORS.length]} key={index} />
            ))}
          </Pie>
          <Tooltip
            // eslint-disable-next-line no-unused-vars
            formatter={(value, name, props) => {
              return EnergyUtils.displayWattHours(value)
            }}
            isAnimationActive={this.props.fixedTooltip !== true}
          />
          <Legend />
        </PieChart>
      </div>
    )
  }
}

export { InstantaneousGraph, ConsumptionGenerationPieGraph, SelfConsumptionPieGraph, EnergyConsumedPieGraph, EnergyPieGraph }
