import React, { Component } from "react"

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import { Alert } from "reactstrap"

import { Loading } from "@common/EcosuiteComponent"

import SiteSystems from "./SiteSystems"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
class ProjectSites extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.systemChanged = this.systemChanged.bind(this)
    this.state = {}
  }

  componentDidMount() {
    if (this.props.project) {
      this.selectDefaultSite()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.project.code !== prevProps.project.code) {
      this.selectDefaultSite()
    }
  }

  selectDefaultSite() {
    if (Object.keys(this.props.project.sites).length > 0) {
      this.setState({
        activeTab: Object.keys(this.props.project.sites)[0],
      })
    } else {
      this.setState({
        activeTab: "new-site",
      })
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  systemChanged() {
    this.props.projectChanged(this.props.project) // Just notify that the project needs to be updated
  }

  render() {
    if (this.props.project) {
      return (
        <div>
          <Nav tabs>
            {this.props.project.sites && Object.values(this.props.project.sites).length > 0
              ? Object.values(this.props.project.sites).map((site, idx) => {
                  return (
                    <NavItem key={"tab-site-" + idx}>
                      <NavLink
                        className={this.state.activeTab === site.code ? "active" : null}
                        onClick={() => {
                          this.toggle(site.code)
                        }}
                        id={site.code}
                      >
                        {site.name}
                        {site.verified && <Icon icon="verified" className="verified" />}
                      </NavLink>
                      <UncontrolledTooltip target={site.code}>{this.props.verifiedTooltip}</UncontrolledTooltip>
                    </NavItem>
                  )
                })
              : null}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            {this.props.project.sites && Object.values(this.props.project.sites).length > 0 ? (
              Object.values(this.props.project.sites).map((site) => {
                return (
                  <TabPane tabId={site.code} key={"tab-content-" + site.code}>
                    <Row>
                      <Col sm="12">
                        <SiteSystems
                          project={this.props.project}
                          site={site}
                          predictedGeneration={this.props.predictedGeneration ? this.props.predictedGeneration.sites[site.code] : null}
                          systemAdded={this.systemChanged}
                          systemChanged={this.systemChanged}
                          systemDeleted={this.systemChanged}
                          autoCompletes={this.props.autoCompletes}
                          readonly={this.props.readonly}
                          verifiedTooltip={this.props.verifiedTooltip}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )
              })
            ) : (
              <div className="project-sites">
                <Alert color="warning">{t("alertsInfo.project_has_no_site")}</Alert>
              </div>
            )}
          </TabContent>
        </div>
      )
    }

    return <Loading />
  }
}

export default ProjectSites
