import React from "react"
import moment from "moment-timezone"
import { Button, Popover, PopoverHeader, PopoverBody, Tooltip } from "reactstrap"
import { Link } from "react-router-dom"
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu-v2"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Aggregations from "@common//Aggregations"

import EventService from "../EventService"
import EventUtils from "../EventUtils"

import "./EventLineGraph.css"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
class EventLineGraph extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state.eventDots = []

    this.loadEvents = this.loadEvents.bind(this)
    this.resolveEvent = this.resolveEvent.bind(this)
    this.toggleDot = this.toggleDot.bind(this)
    this.escFunction = this.escFunction.bind(this)
    this.highlightEvent = this.highlightEvent.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    document.addEventListener("keydown", this.escFunction, false)
    this.loadEvents()
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.range && (this.props.range.startDate !== prevProps.range.startDate || this.props.range.endDate !== prevProps.range.endDate)) ||
      this.props.dotAggregate !== prevProps.dotAggregate
    ) {
      this.setStateIfMounted({ dot: null })
      this.loadEvents()
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      // Esc key
      this.toggleDot(null) // close the dot popover
    }
  }

  isEnabled() {
    return this.props.groups && this.props.groups.indexOf("event") >= 0
  }

  isReadOnly() {
    return this.props.readonly || (this.props.groups && !this.props.groups.includes("event-write"))
  }

  loadEvents() {
    if (this.isEnabled()) {
      let range = this.props.range
      let type = this.props.type
      let projectId = this.props.project ? this.props.project : undefined
      let siteId = this.props.site ? this.props.site : undefined

      this.setStateIfMounted({ loading: true })

      EventService.listEvents(range, type, projectId, siteId).then((data) => {
        // EP-529 When the request returns, only load if the range still matches the latest provided, ignore response if it doesn't
        if (range.isSame(this.props.range)) {
          let events = data.events.sort((a, b) => a.startDate > b.startDate)
          let range = {
            startDate: moment(data.range.localStartDate),
            endDate: moment(data.range.localEndDate),
          }
          let eventDots = this.getEventDots(this.props.dotAggregate, events, range)

          let dot = this.state.dot
            ? eventDots.find((dot) => {
                return dot.id === this.state.dot.id
              })
            : null

          this.setStateIfMounted({
            eventDots: eventDots,
            range: range,
            loading: false,
            dot: dot, // Make sure that the selected dot contents is updated
          })
        }
      })
    }
  }

  getEventDots(aggregate, events, range) {
    let dots = []
    let dotAggregate = aggregate ? aggregate : "days"
    if (dotAggregate === "undefined") dotAggregate = "days"
    let dotAggregateUnit = dotAggregate.substring(0, dotAggregate.length - 1)
    // let dotAggregate = 'days'

    if (events) {
      let end = range.endDate
      let dotStart = moment(range.startDate).startOf(aggregate)
      let idx = 0

      while (dotStart.isBefore(end)) {
        if (idx > 9999) return []
        let dotEnd = moment(dotStart).add(Aggregations.getSize(dotAggregateUnit), Aggregations.getUnits(dotAggregateUnit))
        let dotEvents = this.getEventsForRange(dotStart, dotEnd, events)
        let unresolved = dotEvents.filter((event) => event.endDate === undefined).length > 0

        dots.push({
          id: this.getDotId(idx),
          events: dotEvents,
          startDate: dotStart.toDate(),
          endDate: dotEnd.toDate(),
          unresolved: unresolved,
        })

        dotStart = dotEnd // prepare for next loop
        idx++
      }
    }

    return dots
  }

  getEventsForRange(dotStart, dotEnd, events) {
    let dotEvents = []
    events.forEach((event) => {
      if (moment(event.startDate).isBefore(dotEnd) && (event.endDate === undefined || moment(event.endDate).isSameOrAfter(dotStart))) {
        dotEvents.push(event) // we have an event that matchs the dot
      }
    })
    return dotEvents
  }

  getDotId(idx) {
    let parts = ["dot", this.props.project, this.props.site, this.props.system, this.props.node, this.props.device, idx]
    return parts.join("-")
  }

  toggleDot(dot) {
    if (this.state.dot && this.state.dot !== dot) {
      // Special case to make sure the popover is redrawn
      this.setStateIfMounted({ dot: null })
      this.forceUpdate(() => {
        this.setStateIfMounted({ dot: dot })
      })
    } else {
      this.setStateIfMounted({
        dot: this.state.dot === dot ? null : dot,
      })
    }
  }

  setTooltipDot(dot) {
    this.setStateIfMounted({
      tooltipDot: dot,
    })
  }

  resolveEvent(eventId) {
    this.setStateIfMounted({ loading: true })
    EventService.resolveEvent(eventId).then(() => {
      // Currently just reloading all events with the latest data rather than trying to update only the updated event
      this.loadEvents()
    })
  }

  highlightEvent(event) {
    this.setStateIfMounted({ hightlightEvent: event })
  }

  formatEventDate(event, date) {
    return EventUtils.formatEventDate(this.props.projects, event, date, "lll")
  }

  render() {
    if (!this.isEnabled() || !this.props.projects) {
      return null
    }

    let enabledProjectCodes = this.props.projects.map((project) => project.code)
    let eventDots = this.state.eventDots

    let style = {}
    let centeredPadding = 0
    if (this.state.eventDots && this.props.centered) {
      centeredPadding = (1 / this.state.eventDots.length / 2) * 100 + "%"
      style.marginLeft = centeredPadding
      style.marginRight = centeredPadding
    }

    return (
      <div className="event-line" style={this.props.style}>
        <div style={style}>
          {eventDots.map((dot, idx) => {
            let left = (idx / (eventDots.length - 1)) * 100 + "%"
            let dotElement
            let events = dot.events.filter((event) => {
              // Only include events for projects that are currently selected
              return enabledProjectCodes.indexOf(event.location.project) >= 0
            })

            if (events.length > 0) {
              dotElement = (
                <React.Fragment>
                  <ContextMenuTrigger id={"menu-" + dot.id}>
                    <div
                      id={dot.id}
                      className={"event-dot" + (dot.unresolved ? " unresolved" : "") + (this.state.dot && this.state.dot.id === dot.id ? " selected" : "")}
                      style={{ left: left }}
                      onClick={() => this.toggleDot(dot)}
                      onMouseOver={() => this.setTooltipDot(dot)}
                      onMouseOut={() => this.setTooltipDot()}
                    >
                      {events.length > 9 ? "+" : events.length}
                    </div>
                  </ContextMenuTrigger>
                </React.Fragment>
              )
            } else {
              dotElement = (
                <ContextMenuTrigger id={"menu-" + dot.id}>
                  <div id={dot.id} className="event-dot event-dot-empty" style={{ left: left }}>
                    <div />
                  </div>
                </ContextMenuTrigger>
              )
            }

            return (
              <React.Fragment key={idx}>
                {dotElement}

                <ContextMenu
                  id={"menu-" + dot.id}
                  onShow={() => {
                    this.toggleDot(null)
                    this.setTooltipDot()
                  }}
                >
                  <div className="event-dot-menu">
                    <MenuItem>{moment(dot.startDate).tz("America/New_York").format("lll")}</MenuItem>
                    {this.isReadOnly() ? null : (
                      <MenuItem>
                        <Link to={this.newEventUrl(dot.startDate)}>
                          <Button color="primary">{t("event.labels.new_event")}</Button>
                        </Link>
                      </MenuItem>
                    )}
                  </div>
                </ContextMenu>
              </React.Fragment>
            )
          })}

          {this.renderHighlightEvent()}

          {this.renderTooltip()}
          {this.renderPopover()}
        </div>
      </div>
    )
  }

  renderHighlightEvent() {
    if (this.state.hightlightEvent) {
      let range = this.state.range,
        event = this.state.hightlightEvent
      let timezone = EventUtils.getTimeZoneForProject(this.props.project, event.path)

      let startDate = moment(event.startDate),
        endDate = event.endDate ? moment(event.endDate) : null
      let start = startDate.isBefore(range.startDate) ? range.startDate : startDate
      let end = endDate && endDate.isBefore(range.endDate) ? endDate : range.endDate

      let rangeLength = moment(range.endDate).subtract(1, this.props.dotAggregate).diff(range.startDate) // Note subtract 1 to get proper range, e.g. for 7 days only 6 days would be shown
      // let startIdx = start.diff(range.startDate)/rangeLength*100
      // let width = end.diff(start)/rangeLength*100
      let startIdx = (moment(start).tz(timezone).startOf(this.props.dotAggregate).diff(range.startDate.tz(timezone)) / rangeLength) * 100
      let width = (moment(end).tz(timezone).startOf(this.props.dotAggregate).diff(start.tz(timezone).startOf(this.props.dotAggregate)) / rangeLength) * 100

      return (
        <div
          className="event-line-highlight"
          style={{
            left: startIdx + "%",
            width: width + "%",
          }}
        >
          <div className="event-start" />
          <div className="event-end" />
        </div>
      )
    }
  }

  renderTooltip() {
    let tooltipDot = this.state.tooltipDot
    let popoverDot = this.state.dot

    if (tooltipDot && !popoverDot) {
      let enabledProjectCodes = this.props.projects.map((project) => project.code)
      let events = tooltipDot.events.filter((event) => {
        // Only include events for projects that are currently selected
        return enabledProjectCodes.indexOf(event.location.project) >= 0
      })
      return (
        <Tooltip
          placement="auto-start"
          // flip={false} // flip must be enabled for placement auto
          modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
          isOpen={tooltipDot !== undefined}
          target={tooltipDot.id}
          className="event-dot-tooltip"
        >
          {events.map((event, idx) => {
            let timezone = EventUtils.getTimeZoneForProject(this.props.project, event.path)
            return (
              <div key={idx}>
                <p className="title">
                  <span className="path">{event.path}</span>
                  <span className="date">
                    {event.endDate ? (
                      <em>{moment(event.startDate).tz(timezone).format("lll") + " - " + moment(event.endDate).tz(timezone).format("lll")}</em>
                    ) : (
                      <React.Fragment>
                        <em>{moment(event.startDate).tz(timezone).format("lll")}</em> - <strong>{t("labels.UNRESOLVED")}</strong>
                      </React.Fragment>
                    )}
                  </span>
                </p>
                <p className="cause">{event.cause}</p>
              </div>
            )
          })}
        </Tooltip>
      )
    }
  }

  renderPopover() {
    let popoverDot = this.state.dot
    if (popoverDot) {
      let enabledProjectCodes = this.props.projects.map((project) => project.code)
      let events = popoverDot.events.filter((event) => {
        // Only include events for projects that are currently selected
        return enabledProjectCodes.indexOf(event.location.project) >= 0
      })

      return (
        <Popover
          placement="top"
          flip={false}
          modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
          isOpen={popoverDot !== undefined}
          target={popoverDot.id}
          className="event-dot-popover"
        >
          <PopoverHeader>
            {moment(popoverDot.startDate).tz("America/New_York").format("lll")} - {moment(popoverDot.endDate).tz("America/New_York").format("lll")}
            <span className="event-actions">
              {this.isReadOnly() ? null : (
                <Link to={this.newEventUrl(popoverDot.startDate)}>
                  <Button color="primary">{t("event.labels.new_event")}</Button>
                </Link>
              )}
              <span onClick={() => this.toggleDot(null)}>
                <Icon icon="close" className="close-button" />
              </span>
            </span>
          </PopoverHeader>
          <PopoverBody>
            {this.state.loading ? (
              <Loading />
            ) : (
              events.map((event, idx) => {
                let timezone = EventUtils.getTimeZoneForProject(this.props.project, event.path)
                return (
                  <div key={idx}>
                    <div className={"event " + (event.endDate ? "resolved" : "unresolved")} onMouseOver={() => this.highlightEvent(event)} onMouseOut={() => this.highlightEvent()}>
                      <p className="title">
                        <span className="path">{event.path}</span>
                        {": "}
                        <span className="cause">{event.cause}</span>
                        <span className="event-actions">
                          {event.endDate || this.isReadOnly() ? null : (
                            <Button
                              color="primary"
                              onClick={() => {
                                this.resolveEvent(event.id)
                              }}
                            >
                              {t("buttons.resolve")}
                            </Button>
                          )}
                          <Link to={"/events?eventId=" + event.id}>
                            <Button color="info">{t("labels.view")}</Button>
                          </Link>
                        </span>
                      </p>

                      {event.endDate ? (
                        <p>
                          <em>{moment(event.startDate).tz(timezone).format("lll") + " - " + moment(event.endDate).tz(timezone).format("lll")}</em>
                        </p>
                      ) : (
                        <p>
                          <em>{moment(event.startDate).tz(timezone).format("lll")}</em>
                          {" - "}
                          <strong>{t("labels.UNRESOLVED")}</strong>
                        </p>
                      )}

                      <p className="description">{event.description}</p>
                    </div>
                  </div>
                )
              })
            )}
          </PopoverBody>
        </Popover>
      )
    }
  }

  newEventUrl(startDate) {
    let params = new URLSearchParams()
    params.set("type", this.props.type)
    params.set("startDate", startDate.toISOString())
    if (this.props.project) {
      params.set("project", this.props.project)
    }
    if (this.props.site) {
      params.set("site", this.props.site)
    }
    if (this.props.system) {
      params.set("site", this.props.system)
    }
    if (this.props.node) {
      params.set("site", this.props.node)
    }
    if (this.props.device) {
      params.set("site", this.props.device)
    }
    return "/events?new&" + params.toString()
  }
}

export default EventLineGraph
