import * as React from "react"
import { Spinner } from "src/components/ui/spinner"

export default function FullPageSpinner() {
  return (
    <div className="public-view tw-bg-background tw-text-foreground tw-h-screen tw-flex tw-items-center tw-justify-center">
      <Spinner size="large">Loading...</Spinner>
    </div>
  )
}
