import React from "react"

import EnergyView from "@common/module/EcosuiteView"
import EcosuiteComponent, { Loading, Error } from "@common/EcosuiteComponent"

import ProjectService from "./ProjectService"
import Logger from "@common/Logger"
import i18n from "src/i18n"

const { t } = i18n

export default class ProjectContactsView extends EnergyView {
  constructor(props, name) {
    super(props, name)

    this.loadContacts = this.loadContacts.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadContacts()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project.code !== prevProps.project.code) {
      this.loadContacts()
    }
  }

  loadContacts() {
    this.setStateIfMounted({ contacts: undefined, error: undefined })
    const projectId = this.props.project.code
    ProjectService.getProjectContacts(projectId)
      .then((contacts) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ contacts: contacts })
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({ error: err })
        }
      })
  }

  renderMainView() {
    if (this.state.contacts) {
      return (
        <div className="project-contacts">
          <h2>{this.isAssetManager() ? `${this.props.project.name} (/${this.props.project.code})` : this.props.project.name}</h2>
          <h5>{t("headings.project_record_contacts")}</h5>
          <RecordContacts contacts={this.state.contacts.recordContacts} />

          {Object.values(this.props.project.sites).map((site) => {
            return (
              <div className="site-contacts" key={site.code}>
                <h3>{this.isAssetManager() ? `${site.name} (/${this.props.project.code}/${site.code})` : site.name}</h3>
                <h5>{t("headings.site_contacts")}</h5>
                <Contacts contacts={site.contacts} />
                <h5>{t("headings.site_record_contacts")}</h5>
                <RecordContacts contacts={this.state.contacts.sites[site.code] ? this.state.contacts.sites[site.code].recordContacts : []} />

                {Object.values(site.systems).map((system) => {
                  return (
                    <div className="system-contacts" key={system.code}>
                      <h4>{this.isAssetManager() ? `${system.name} (/${this.props.project.code}/${site.code}/${system.code})` : system.name}</h4>
                      <h5>{t("headings.system_record_contacts")}</h5>
                      <RecordContacts
                        contacts={
                          this.state.contacts.sites[site.code] && this.state.contacts.sites[site.code].systems[system.code]
                            ? this.state.contacts.sites[site.code].systems[system.code].recordContacts
                            : []
                        }
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )
    } else if (this.state.error) {
      return <Error error={this.state.error} />
    } else {
      return <Loading />
    }
  }
}

class Contacts extends EcosuiteComponent {
  renderContent() {
    if (this.props.contacts && this.props.contacts.length > 0) {
      return (
        <table className="contacts">
          <thead>
            <tr>
              <th width="20%">{t("table_headings.contact")}</th>
              <th width="20%">{t("table_headings.type")}</th>
              <th width="20%">{t("table_headings.phone")}</th>
              <th>{t("table_headings.voip")}</th>
              <th>{t("table_headings.email")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.contacts.map((contact, idx) => {
              return (
                <tr key={idx}>
                  <td>{contact.name}</td>
                  <td>{contact.type}</td>
                  <td>{contact.phone ? <a href={"tel:" + contact.phone}>{contact.phone}</a> : null}</td>
                  <td>
                    {contact.voip ? (
                      <a href={contact.voip} target="_blank" rel="noreferrer noopener">
                        {contact.voip}
                      </a>
                    ) : null}
                  </td>
                  <td>{contact.email}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    } else {
      return <div className="no-contacts">{t("alertsInfo.no_contacts")}</div>
    }
  }
}

class RecordContacts extends EcosuiteComponent {
  renderContent() {
    if (this.props.contacts && this.props.contacts.length > 0) {
      return (
        <table className="contacts">
          <thead>
            <tr>
              <th width="20%">{t("table_headings.contact")}</th>
              <th width="20%">{t("table_headings.type")}</th>
              <th width="20%">{t("table_headings.phone")}</th>
              <th>{t("table_headings.voip")}</th>
              <th>{t("table_headings.email")}</th>
              <th>{t("table_headings.record")}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.contacts.map((contact, idx) => {
              return (
                <tr key={idx}>
                  <td>{contact.name}</td>
                  <td>{contact.type}</td>
                  <td>{contact.phone ? <a href={"tel:" + contact.phone}>{contact.phone}</a> : null}</td>
                  <td>
                    {contact.voip ? (
                      <a href={contact.voip} target="_blank" rel="noreferrer noopener">
                        {contact.voip}
                      </a>
                    ) : null}
                  </td>
                  <td>{contact.email}</td>
                  <td>{contact.recordName}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    } else {
      return <div className="no-contacts">{t("alertsInfo.no_contacts")}</div>
    }
  }
}
