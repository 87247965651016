import React, { Component } from "react"

import { Route } from "react-router-dom"

import Notifications from "./notifications/Notifications"
import ProfileTabs from "./ProfileTabs"

import "./Profile.css"

export default class Profile extends Component {
  constructor(props) {
    super(props)

    this.renderNotifications = this.renderNotifications.bind(this)
    this.renderProfile = this.renderProfile.bind(this)
  }

  render() {
    return (
      <div className="user-profile">
        <Route exact path="/profile/notifications" render={this.renderNotifications} />
        <Route exact path="/profile" render={this.renderProfile} />
      </div>
    )
  }

  renderNotifications() {
    return <Notifications renderTitle={true} admin={false} userId={this.props.userId} groups={this.props.groups} />
  }

  renderProfile() {
    return <ProfileTabs />
  }
}
