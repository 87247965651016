import API from "@common/API"
import Logger from "@common/Logger"

/**
 * Service for administering Ecosuite Projects Finance settings.
 */
const FinanceService = {
  getIRRCategories(projectId: string) {
    return API.get<{ categories: unknown }>(`/finance/projects/${projectId}/irr-categories`)
      .then((data) => {
        return data.categories
      })
      .catch((e: unknown) => {
        Logger.error(e)
        return Promise.reject({ message: `Unable to retrieve categories for project: ${projectId}` })
      })
  },

  getProjectSettings(projectId: string) {
    return API.get<{ settings: unknown }>(`/finance/projects/${projectId}/settings`)
      .then((data) => {
        return data.settings
      })
      .catch((e: unknown) => {
        Logger.error(e)
        return Promise.reject({ message: `Unable to retrieve settings for project: ${projectId}` })
      })
  },

  updateProjectSettings(projectId: string, settings: unknown): Promise<unknown> {
    if (settings) {
      return API.put(`/finance/projects/${projectId}/settings`, settings)
    } else {
      // Nothing to save
      Logger.info("Skipping save as no settings provided")
      return Promise.resolve()
    }
  },
}

export default FinanceService
