import React from "react"
import PropTypes from "prop-types"
import BaseObjectField from "@rjsf/core/lib/components/fields/ObjectField"

const filterOrder = (properties, order) => order.filter((prop) => prop === "*" || properties.includes(prop))

/**
 * This removes the properties from ui:order that aren't available on the object.
 * This is to prevent warnings being logged for properties that aren't there because of they aren't part of the current dependency but we want to order them.
 */
const EcosuiteObjectField = ({ uiSchema, ...props }) => {
  const properties = Object.keys(props.schema.properties)
  const order = uiSchema["ui:order"]
  const newUISchema = order ? { ...uiSchema, "ui:order": filterOrder(properties, order) } : uiSchema

  return <BaseObjectField {...props} uiSchema={newUISchema} />
}

EcosuiteObjectField.propTypes = {
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
}

export default EcosuiteObjectField
