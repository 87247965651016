import React from "react"
import { Alert, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Typeahead } from "react-bootstrap-typeahead"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import RecordService from "../../RecordService"
import sanitize from "sanitize-filename"
import i18n from "src/i18n"

const documentFolderHierarchy = require("@dashboard/data/record/record-document-folders.json")
const { t } = i18n

export class RecordFolderEdit extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state.folderNames = this.getFolderNames()

    this.toggleModal = this.toggleModal.bind(this)
    this.editFolder = this.editFolder.bind(this)
  }

  toggleModal() {
    this.setStateIfMounted({ error: null, message: "", files: [] })
    this.props.selectFolder() // Clear the selected document
  }

  getFolderNames() {
    if (this.props.record && this.props.record.recordType && this.props.record.recordSubType) {
      // Prepopulate folderNames with default folder names
      const folderNames = new Set(documentFolderHierarchy[this.props.record.recordType][this.props.record.recordSubType])

      const recordTypeDocuments = this.props.typeHierarchy.find((typeObject) => typeObject.id === this.props.record.recordType)
      const recordSubTypeDocuments = recordTypeDocuments.subTypes.find((subTypeObject) => subTypeObject.id === this.props.record.recordSubType)

      if (recordSubTypeDocuments) {
        if (recordSubTypeDocuments.folders && recordSubTypeDocuments.folders.length > 0) {
          recordSubTypeDocuments.folders.forEach((folder) => folderNames.add(folder))
        }
      }

      // Don't render the Archive folder as an option
      folderNames.delete("Archive")

      return Array.from(folderNames)
    }
  }

  async editFolder() {
    if (!this.state.folderName) {
      this.setStateIfMounted({ error: t("data.errorMsg.enter_folder_name"), loading: false })
    } else if (this.state.folderName !== sanitize(this.state.folderName)) {
      this.setStateIfMounted({ error: t("data.errorMsg.folder_name"), loading: false })
    } else {
      const documents = this.props.documents.filter((d) => d.folderName === this.props.folder)

      this.setStateIfMounted({ loading: true, progress: 0, total: documents.length, error: null, message: null })

      for (let i = 0; i < documents.length; i++) {
        const doc = documents[i]

        await RecordService.moveFile(doc.fileKey, `records/${this.props.record.id}/${this.state.folderName}${doc.subFolderName ? `/${doc.subFolderName}` : ""}/${doc.fileName}`, this.props.groups)
          .then(() => {
            this.setStateIfMounted({ progress: i + 1 })
          })
          .catch((err) => {
            this.setStateIfMounted({ error: `${t("errors.saving_document")}: ${err.message}`, loading: false })
          })
      }

      this.setStateIfMounted({ message: t("messages.document_moved"), loading: false, folderNames: this.getFolderNames() })
      this.props.actions.loadDocuments(this.props.record) // Reload the documents to pick up the newly uploaded ones
      this.toggleModal()
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.folder !== undefined} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>{t("buttons.edit_folder")}</ModalHeader>
          <ModalBody>
            <Label>{t("labels.folder_name")}:</Label>
            <Typeahead
              id="folderAutoComplete"
              onChange={(selected) => {
                this.setStateIfMounted({ folderName: selected && selected.length ? (typeof selected[0] === "object" ? selected[0].label : selected[0]) : null })
              }}
              options={this.state.folderNames ?? []}
              selected={this.state.folderName ? [this.state.folderName] : []}
              allowNew={true}
              disabled={this.props.readonly}
            />

            {this.state.loading ? <Loading message={`${t("loadingMsg.moving_documents")} ${this.state.progress}/${this.state.total} ...`} /> : null}
            {this.state.message ? <Alert color="info">{this.state.message}</Alert> : null}
            {this.state.error ? <Alert color="danger">{this.state.error}</Alert> : null}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editFolder}>
              {t("buttons.save")}
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModal}>
              {t("buttons.cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}
