import * as React from "react"
import { UncontrolledTooltip as ReactStrapUncontrolledTooltip } from "reactstrap"

class ToolTipErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error("Tooltip target mismatch error - Reactstrap", error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

export default function UncontrolledTooltip(props) {
  return (
    <ToolTipErrorBoundary>
      <ReactStrapUncontrolledTooltip {...props} />
    </ToolTipErrorBoundary>
  )
}
