import React, { Component } from "react"

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap"
import { Alert } from "reactstrap"

import { Loading } from "@common/EcosuiteComponent"

import SiteDetails from "./SiteDetails"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

const { t } = i18n
class ProjectSites extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.siteAdded = this.siteAdded.bind(this)
    this.siteChanged = this.siteChanged.bind(this)
    this.siteDeleted = this.siteDeleted.bind(this)
    this.state = {}
  }

  componentDidMount() {
    if (this.props.project) {
      this.selectDefaultSite()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.project.code !== prevProps.project.code) {
      this.selectDefaultSite()
    }
  }

  selectDefaultSite() {
    if (Object.keys(this.props.project.sites).length > 0) {
      this.setState({
        activeTab: this.getSites()[0].code,
      })
    } else {
      this.setState({
        activeTab: "new-site",
      })
    }
  }

  getSites() {
    return Object.values(this.props.project.sites).sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  siteAdded(site) {
    this.props.projectChanged(this.props.project)
    this.setState({
      activeTab: site.code,
    })
  }

  siteChanged(site) {
    this.props.projectChanged(this.props.project)
    this.setState({
      activeTab: site.code,
    })
  }

  siteDeleted() {
    this.props.projectChanged(this.props.project)
    this.selectDefaultSite()
  }

  render() {
    if (this.props.project) {
      let sites = this.getSites()
      return (
        <div className="project-site-tabs">
          <Nav tabs>
            {sites.map((site, idx) => {
              return (
                <NavItem key={"tab-site-" + idx}>
                  <NavLink
                    className={this.state.activeTab === site.code ? "active" : null}
                    onClick={() => {
                      this.toggle(site.code)
                    }}
                  >
                    {site.name}
                    {site.verified && <Icon icon="verified" className="verified" />}
                  </NavLink>
                </NavItem>
              )
            })}
            {this.props.readonly ? null : (
              <>
                <NavItem key={"tab-new-site"} id="tab-new-site" className="ms-auto new">
                  <NavLink
                    className={this.state.activeTab === "new-site" ? "active" : null}
                    onClick={() => {
                      this.toggle("new-site")
                    }}
                  >
                    {t("links.new_site")}
                  </NavLink>
                </NavItem>
                <UncontrolledTooltip target="tab-new-site">{t("data.dialogs.create_new_site")}</UncontrolledTooltip>
              </>
            )}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            {sites.length > 0 ? (
              sites.map((site) => {
                return (
                  <TabPane tabId={site.code} key={"tab-content-" + site.code}>
                    <Row>
                      <Col sm="12">
                        <SiteDetails
                          groups={this.props.groups}
                          key={site.code}
                          project={this.props.project}
                          site={site}
                          predictedGeneration={this.props.predictedGeneration ? this.props.predictedGeneration.sites[site.code] : null}
                          siteChanged={this.siteChanged}
                          siteDeleted={this.siteDeleted}
                          readonly={this.props.readonly}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )
              })
            ) : (
              <div className="project-sites">
                <Alert color="warning">{t("alertsInfo.project_has_no_site")}</Alert>
              </div>
            )}
            <TabPane tabId="new-site" key={"tab-content-new"}>
              <Row>
                <Col sm="12">
                  <SiteDetails project={this.props.project} key="new-site" siteAdded={this.siteAdded} readonly={this.props.readonly} groups={this.props.groups} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      )
    }

    return <Loading />
  }
}

export default ProjectSites
