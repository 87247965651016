import React, { Component } from "react"
import { Input, InputGroup, InputGroupText } from "reactstrap"
import i18n from "src/i18n"

const { t } = i18n
export class ProjectSearch extends Component {
  constructor(props) {
    super(props)

    this.search = this.search.bind(this)
  }

  search(e) {
    this.props.actions.search(e.target.value)
  }

  render() {
    return (
      <InputGroup className="project-search-bar" size="sm">
        <Input placeholder={`${t("labels.search")}`} onChange={this.search} value={this.props.searchTerm ? this.props.searchTerm : ""} />

        <InputGroupText
          onClick={(e) => {
            e.preventDefault()
            this.props.actions.search()
          }}
        >
          x
        </InputGroupText>
      </InputGroup>
    )
  }
}
