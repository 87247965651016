import React from "react"
import moment from "moment"
import { FormGroup, Input } from "reactstrap"

import ReportSection from "../ReportSection"
import i18n from "src/i18n"

const { t } = i18n

class CashFlowDates extends ReportSection {
  renderDateSelector() {
    if (this.props.report) {
      let date = moment(this.props.report.dates[0])
      let end = moment(this.props.report.dates[this.props.report.dates.length - 1])

      let years = []
      while (date.isSameOrBefore(end, "year")) {
        years.push(date.format("YYYY"))
        date.add(1, "years")
      }

      return (
        <FormGroup>
          <Input type="select" onChange={this.jumpToDate}>
            <option value="">{t("economics.labels.Jump to Year")}</option>
            <option value={moment().format("YYYY")}>{t("dates.Today")}</option>
            {years.map((year, idx) => {
              return (
                <option key={idx} value={year}>
                  {year}
                </option>
              )
            })}
            <option value="to-date">{t("economics.labels.To Date")}</option>
            <option value="lifetime">{t("economics.labels.Lifetime")}</option>
          </Input>
        </FormGroup>
      )
    }
  }

  jumpToDate(e) {
    if (e.target.value) {
      let target = document.getElementById("year-" + e.target.value)
      target.scrollIntoView({ inline: "start" }) //block: "start",
      // document.getElementById("finance-report").scrollLeft -= 250 // cater for the sticky columns
      // document.getElementsByClassName("content")[0].scrollLeft -= 250 // cater for the sticky columns

      e.target.value = ""
    }
  }

  render() {
    document.getElementsByClassName("content")[0].scrollLeft = 0

    const colSpanAll = this.props.columns && this.props.columns.length ? this.props.columns.length : 1
    const futureColumns = this.props.columns ? this.props.columns.filter((column) => column === "forecast" || column === "latestBestEstimate") : null
    const colSpanFuture = futureColumns && futureColumns.length ? futureColumns.length : 1

    return (
      <React.Fragment>
        <tr className="years">
          <th>{this.renderDateSelector()}</th>
          {this.props.report.dates.map((date, idx) => {
            let showAllColumns = idx < this.props.futureIdx // after the current date we don't show all the columns
            const colSpan = showAllColumns ? colSpanAll : colSpanFuture
            return (
              <th key={idx} className={this.getCellClass(idx)} style={{ minWidth: `${colSpan}00px`, width: `${colSpan}00px` }} id={"year-" + moment(date).format("YYYY")} colSpan={colSpan}>
                {this.formatDate(date)}
              </th>
            )
          })}
          <th style={{ minWidth: `${colSpanAll}00px`, width: `${colSpanAll}00px` }} colSpan={colSpanAll} id="year-to-date">
            {t("economics.labels.To Date")}
          </th>
          <th style={{ minWidth: `${colSpanFuture}00px`, width: `${colSpanFuture}00px` }} colSpan={colSpanFuture} id="year-lifetime">
            {t("economics.labels.Lifetime")}
          </th>
          {/* <th className="spacer">End of Report</th> */}
        </tr>

        <tr className="cashflow-types">
          <th></th>
          {this.props.report.dates.map((date, idx) => {
            let showAllColumns = idx < this.props.futureIdx // after the current date we don't show all the columns
            return (
              <React.Fragment key={idx}>
                {showAllColumns ? (
                  <React.Fragment>
                    <th className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>{t("labels.forecast")}</th>
                    <th className={`${this.getCellClass(idx)} cash-flow-expected ${this.getColumnClass("expected")}`}>{t("labels.anticipated")}</th>
                    <th className={`${this.getCellClass(idx)} cash-flow-actual ${this.getColumnClass("actual")}`}>{t("labels.actual")}</th>
                    <th className={`${this.getCellClass(idx)} cash-flow-latestBestEstimate ${this.getColumnClass("latestBestEstimate")}`}>{t("labels.LBE")}</th>
                    <th className={`${this.getCellClass(idx)} cash-flow-difference ${this.getColumnClass("difference")}`}>{t("labels.difference")}</th>
                    {this.renderSpacer()}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <th className={`${this.getCellClass(idx)} cash-flow-forecast ${this.getColumnClass("forecast")}`}>{t("labels.forecast")}</th>
                    <th className={`${this.getCellClass(idx)} cash-flow-pro-latestBestEstimate ${this.getColumnClass("latestBestEstimate")}`}>{t("labels.LBE")}</th>
                    {this.renderFutureSpacer()}
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          })}

          <th className={`cash-flow-forecast ${this.getColumnClass("forecast")}`}>{t("labels.forecast")}</th>
          <th className={`cash-flow-expected ${this.getColumnClass("expected")}`}>{t("labels.anticipated")}</th>
          <th className={`cash-flow-actual ${this.getColumnClass("actual")}`}>{t("labels.actual")}</th>
          <th className={`cash-flow-latestBestEstimate ${this.getColumnClass("latestBestEstimate")}`}>{t("labels.LBE")}</th>
          <th className={`cash-flow-difference ${this.getColumnClass("difference")}`}>{t("labels.difference")}</th>

          <th className={`cash-flow-forecast ${this.getColumnClass("forecast")}`}>{t("labels.forecast")}</th>
          <th className={`cash-flow-latestBestEstimate ${this.getColumnClass("latestBestEstimate")}`}>{t("labels.LBE")}</th>

          {/* {this.props.columns.length ? <th className="spacer">End of Report</th> : null} */}
        </tr>
      </React.Fragment>
    )
  }

  formatDate(date) {
    switch (this.props.aggregate) {
      case "quarter":
        return "Q" + moment(date).format("Q YYYY")
      case "year":
        return moment(date).format("YYYY")
      case "total":
        return "Total"
      default:
        return moment(date).format("MMM YYYY")
    }
  }
}

export default CashFlowDates
