import React from "react"

/**
 * All valid JSONText properties.
 */
export interface JSONTextProps {
  object: Record<string, unknown>
}

/**
 * A simple JSON text display.
 * @param props - The JSONText properties.
 * @constructor
 */
export const JSONText = (props: JSONTextProps): JSX.Element => {
  return (
    <>
      <pre>{JSON.stringify(props.object, null, 4)}</pre>
    </>
  )
}
