import React from "react"

import EcosuiteComponent from "@common/EcosuiteComponent"
import Utils from "@common/utils/Utils"
import i18n from "src/i18n"

const { t } = i18n
export default class ProFormaInputsCashFlows extends EcosuiteComponent {
  renderContent() {
    const { project, fixedAssets, proForma } = this.props
    const dcSizeWatts = proForma.systemSize * 1000

    return (
      <table className="project-table pro-forma-inputs">
        <thead>
          <tr>
            <th colSpan={3}>{t("economics.headings.forecast_project_chart", { projectName: project.name })}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{t("table_headings.account")}</th>
            <th>{`${t("table_headings.account")} $`}</th>
            <th>{t("economics.labels.$/W DC")}</th>
          </tr>
          {fixedAssets?.categories
            ? Object.values(fixedAssets.categories).map((account) => {
                return (
                  <React.Fragment key={account.name}>
                    <tr className="account">
                      <td>{account.name}</td>
                      <td>{Utils.formatNumber(Math.abs(account.totals))}</td>
                      <td>{Utils.formatNumber(Math.abs(account.totals) / dcSizeWatts)}</td>
                    </tr>
                    {Object.values(account.categories).map((subAccount) => {
                      return (
                        <tr className="sub-account" key={subAccount.name}>
                          <td>{subAccount.name}</td>
                          <td>{Utils.formatNumber(Math.abs(subAccount.totals))}</td>
                          <td>{Utils.formatNumber(Math.abs(subAccount.totals) / dcSizeWatts)}</td>
                        </tr>
                      )
                    })}
                  </React.Fragment>
                )
              })
            : null}
        </tbody>
        <tfoot>
          <tr className="total">
            <td>{t("table_headings.total")}</td>
            <td>{Utils.formatNumber(Math.abs(fixedAssets?.totals || 0))}</td>
            <td>{Utils.formatNumber(Math.abs(fixedAssets?.totals || 0) / dcSizeWatts)}</td>
          </tr>
        </tfoot>
      </table>
    )
  }
}
