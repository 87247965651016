export const timezones = {
  "(GMT-11:00) Niue": "Pacific/Niue",
  "(GMT-11:00) Pago Pago": "Pacific/Pago_Pago",
  "(GMT-10:00) Hawaii Time": "Pacific/Honolulu",
  "(GMT-10:00) Rarotonga": "Pacific/Rarotonga",
  "(GMT-10:00) Tahiti": "Pacific/Tahiti",
  "(GMT-09:30) Marquesas": "Pacific/Marquesas",
  "(GMT-09:00) Alaska Time": "America/Anchorage",
  "(GMT-09:00) Gambier": "Pacific/Gambier",
  "(GMT-08:00) Pacific Time": "America/Los_Angeles",
  "(GMT-08:00) Pacific Time - Tijuana": "America/Tijuana",
  "(GMT-08:00) Pacific Time - Vancouver": "America/Vancouver",
  "(GMT-08:00) Pacific Time - Whitehorse": "America/Whitehorse",
  "(GMT-08:00) Pitcairn": "Pacific/Pitcairn",
  "(GMT-07:00) Mountain Time - Dawson Creek": "America/Dawson_Creek",
  "(GMT-07:00) Mountain Time": "America/Denver",
  "(GMT-07:00) Mountain Time - Edmonton": "America/Edmonton",
  "(GMT-07:00) Mountain Time - Hermosillo": "America/Hermosillo",
  "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan": "America/Mazatlan",
  "(GMT-07:00) Mountain Time - Arizona": "America/Phoenix",
  "(GMT-07:00) Mountain Time - Yellowknife": "America/Yellowknife",
  "(GMT-06:00) Belize": "America/Belize",
  "(GMT-06:00) Central Time": "America/Chicago",
  "(GMT-06:00) Costa Rica": "America/Costa_Rica",
  "(GMT-06:00) El Salvador": "America/El_Salvador",
  "(GMT-06:00) Guatemala": "America/Guatemala",
  "(GMT-06:00) Managua": "America/Managua",
  "(GMT-06:00) Central Time - Mexico City": "America/Mexico_City",
  "(GMT-06:00) Central Time - Regina": "America/Regina",
  "(GMT-06:00) Central Time - Tegucigalpa": "America/Tegucigalpa",
  "(GMT-06:00) Central Time - Winnipeg": "America/Winnipeg",
  "(GMT-06:00) Galapagos": "Pacific/Galapagos",
  "(GMT-05:00) Bogota": "America/Bogota",
  "(GMT-05:00) America Cancun": "America/Cancun",
  "(GMT-05:00) Cayman": "America/Cayman",
  "(GMT-05:00) Guayaquil": "America/Guayaquil",
  "(GMT-05:00) Havana": "America/Havana",
  "(GMT-05:00) Eastern Time - Iqaluit": "America/Iqaluit",
  "(GMT-05:00) Jamaica": "America/Jamaica",
  "(GMT-05:00) Lima": "America/Lima",
  "(GMT-05:00) Nassau": "America/Nassau",
  "(GMT-05:00) Eastern Time": "America/New_York",
  "(GMT-05:00) Panama": "America/Panama",
  "(GMT-05:00) Port-au-Prince": "America/Port-au-Prince",
  "(GMT-05:00) Rio Branco": "America/Rio_Branco",
  "(GMT-05:00) Eastern Time - Toronto": "America/Toronto",
  "(GMT-05:00) Easter Island": "Pacific/Easter",
  "(GMT-04:30) Caracas": "America/Caracas",
  "(GMT-03:00) Asuncion": "America/Asuncion",
  "(GMT-04:00) Barbados": "America/Barbados",
  "(GMT-04:00) Boa Vista": "America/Boa_Vista",
  "(GMT-03:00) Campo Grande": "America/Campo_Grande",
  "(GMT-03:00) Cuiaba": "America/Cuiaba",
  "(GMT-04:00) Curacao": "America/Curacao",
  "(GMT-04:00) Grand Turk": "America/Grand_Turk",
  "(GMT-04:00) Guyana": "America/Guyana",
  "(GMT-04:00) Atlantic Time - Halifax": "America/Halifax",
  "(GMT-04:00) La Paz": "America/La_Paz",
  "(GMT-04:00) Manaus": "America/Manaus",
  "(GMT-04:00) Martinique": "America/Martinique",
  "(GMT-04:00) Port of Spain": "America/Port_of_Spain",
  "(GMT-04:00) Porto Velho": "America/Porto_Velho",
  "(GMT-04:00) Puerto Rico": "America/Puerto_Rico",
  "(GMT-04:00) Santo Domingo": "America/Santo_Domingo",
  "(GMT-04:00) Thule": "America/Thule",
  "(GMT-04:00) Bermuda": "Atlantic/Bermuda",
  "(GMT-03:30) Newfoundland Time - St. Johns": "America/St_Johns",
  "(GMT-03:00) Araguaina": "America/Araguaina",
  "(GMT-03:00) Buenos Aires": "America/Argentina/Buenos_Aires",
  "(GMT-03:00) Salvador": "America/Bahia",
  "(GMT-03:00) Belem": "America/Belem",
  "(GMT-03:00) Cayenne": "America/Cayenne",
  "(GMT-03:00) Fortaleza": "America/Fortaleza",
  "(GMT-03:00) Godthab": "America/Godthab",
  "(GMT-03:00) Maceio": "America/Maceio",
  "(GMT-03:00) Miquelon": "America/Miquelon",
  "(GMT-03:00) Montevideo": "America/Montevideo",
  "(GMT-03:00) Paramaribo": "America/Paramaribo",
  "(GMT-03:00) Recife": "America/Recife",
  "(GMT-03:00) Santiago": "America/Santiago",
  "(GMT-02:00) Sao Paulo": "America/Sao_Paulo",
  "(GMT-03:00) Palmer": "Antarctica/Palmer",
  "(GMT-03:00) Rothera": "Antarctica/Rothera",
  "(GMT-03:00) Stanley": "Atlantic/Stanley",
  "(GMT-02:00) Noronha": "America/Noronha",
  "(GMT-02:00) South Georgia": "Atlantic/South_Georgia",
  "(GMT-01:00) Scoresbysund": "America/Scoresbysund",
  "(GMT-01:00) Azores": "Atlantic/Azores",
  "(GMT-01:00) Cape Verde": "Atlantic/Cape_Verde",
  "(GMT+00:00) Abidjan": "Africa/Abidjan",
  "(GMT+00:00) Accra": "Africa/Accra",
  "(GMT+00:00) Bissau": "Africa/Bissau",
  "(GMT+00:00) Casablanca": "Africa/Casablanca",
  "(GMT+00:00) El Aaiun": "Africa/El_Aaiun",
  "(GMT+00:00) Monrovia": "Africa/Monrovia",
  "(GMT+00:00) Danmarkshavn": "America/Danmarkshavn",
  "(GMT+00:00) Canary Islands": "Atlantic/Canary",
  "(GMT+00:00) Faeroe": "Atlantic/Faroe",
  "(GMT+00:00) Reykjavik": "Atlantic/Reykjavik",
  "(GMT+00:00) GMT (no daylight saving)": "Etc/GMT",
  "(GMT+00:00) Dublin": "Europe/Dublin",
  "(GMT+00:00) Lisbon": "Europe/Lisbon",
  "(GMT+00:00) London": "Europe/London",
  "(GMT+01:00) Algiers": "Africa/Algiers",
  "(GMT+01:00) Ceuta": "Africa/Ceuta",
  "(GMT+01:00) Lagos": "Africa/Lagos",
  "(GMT+01:00) Ndjamena": "Africa/Ndjamena",
  "(GMT+01:00) Tunis": "Africa/Tunis",
  "(GMT+02:00) Windhoek": "Africa/Windhoek",
  "(GMT+01:00) Amsterdam": "Europe/Amsterdam",
  "(GMT+01:00) Andorra": "Europe/Andorra",
  "(GMT+01:00) Central European Time - Belgrade": "Europe/Belgrade",
  "(GMT+01:00) Berlin": "Europe/Berlin",
  "(GMT+01:00) Brussels": "Europe/Brussels",
  "(GMT+01:00) Budapest": "Europe/Budapest",
  "(GMT+01:00) Copenhagen": "Europe/Copenhagen",
  "(GMT+01:00) Gibraltar": "Europe/Gibraltar",
  "(GMT+01:00) Luxembourg": "Europe/Luxembourg",
  "(GMT+01:00) Madrid": "Europe/Madrid",
  "(GMT+01:00) Malta": "Europe/Malta",
  "(GMT+01:00) Monaco": "Europe/Monaco",
  "(GMT+01:00) Oslo": "Europe/Oslo",
  "(GMT+01:00) Paris": "Europe/Paris",
  "(GMT+01:00) Central European Time - Prague": "Europe/Prague",
  "(GMT+01:00) Rome": "Europe/Rome",
  "(GMT+01:00) Stockholm": "Europe/Stockholm",
  "(GMT+01:00) Tirane": "Europe/Tirane",
  "(GMT+01:00) Vienna": "Europe/Vienna",
  "(GMT+01:00) Warsaw": "Europe/Warsaw",
  "(GMT+01:00) Zurich": "Europe/Zurich",
  "(GMT+02:00) Cairo": "Africa/Cairo",
  "(GMT+02:00) Johannesburg": "Africa/Johannesburg",
  "(GMT+02:00) Maputo": "Africa/Maputo",
  "(GMT+02:00) Tripoli": "Africa/Tripoli",
  "(GMT+02:00) Amman": "Asia/Amman",
  "(GMT+02:00) Beirut": "Asia/Beirut",
  "(GMT+02:00) Damascus": "Asia/Damascus",
  "(GMT+02:00) Gaza": "Asia/Gaza",
  "(GMT+02:00) Jerusalem": "Asia/Jerusalem",
  "(GMT+02:00) Nicosia": "Asia/Nicosia",
  "(GMT+02:00) Athens": "Europe/Athens",
  "(GMT+02:00) Bucharest": "Europe/Bucharest",
  "(GMT+02:00) Chisinau": "Europe/Chisinau",
  "(GMT+02:00) Helsinki": "Europe/Helsinki",
  "(GMT+02:00) Istanbul": "Europe/Istanbul",
  "(GMT+02:00) Moscow-01 - Kaliningrad": "Europe/Kaliningrad",
  "(GMT+02:00) Kiev": "Europe/Kiev",
  "(GMT+02:00) Riga": "Europe/Riga",
  "(GMT+02:00) Sofia": "Europe/Sofia",
  "(GMT+02:00) Tallinn": "Europe/Tallinn",
  "(GMT+02:00) Vilnius": "Europe/Vilnius",
  "(GMT+03:00) Khartoum": "Africa/Khartoum",
  "(GMT+03:00) Nairobi": "Africa/Nairobi",
  "(GMT+03:00) Syowa": "Antarctica/Syowa",
  "(GMT+03:00) Baghdad": "Asia/Baghdad",
  "(GMT+03:00) Qatar": "Asia/Qatar",
  "(GMT+03:00) Riyadh": "Asia/Riyadh",
  "(GMT+03:00) Minsk": "Europe/Minsk",
  "(GMT+03:00) Moscow+00 - Moscow": "Europe/Moscow",
  "(GMT+03:30) Tehran": "Asia/Tehran",
  "(GMT+04:00) Baku": "Asia/Baku",
  "(GMT+04:00) Dubai": "Asia/Dubai",
  "(GMT+04:00) Tbilisi": "Asia/Tbilisi",
  "(GMT+04:00) Yerevan": "Asia/Yerevan",
  "(GMT+04:00) Moscow+01 - Samara": "Europe/Samara",
  "(GMT+04:00) Mahe": "Indian/Mahe",
  "(GMT+04:00) Mauritius": "Indian/Mauritius",
  "(GMT+04:00) Reunion": "Indian/Reunion",
  "(GMT+04:30) Kabul": "Asia/Kabul",
  "(GMT+05:00) Mawson": "Antarctica/Mawson",
  "(GMT+05:00) Aqtau": "Asia/Aqtau",
  "(GMT+05:00) Aqtobe": "Asia/Aqtobe",
  "(GMT+05:00) Ashgabat": "Asia/Ashgabat",
  "(GMT+05:00) Dushanbe": "Asia/Dushanbe",
  "(GMT+05:00) Karachi": "Asia/Karachi",
  "(GMT+05:00) Tashkent": "Asia/Tashkent",
  "(GMT+05:00) Moscow+02 - Yekaterinburg": "Asia/Yekaterinburg",
  "(GMT+05:00) Kerguelen": "Indian/Kerguelen",
  "(GMT+05:00) Maldives": "Indian/Maldives",
  "(GMT+05:30) India Standard Time": "Asia/Calcutta",
  "(GMT+05:30) Colombo": "Asia/Colombo",
  "(GMT+05:45) Katmandu": "Asia/Katmandu",
  "(GMT+06:00) Vostok": "Antarctica/Vostok",
  "(GMT+06:00) Almaty": "Asia/Almaty",
  "(GMT+06:00) Bishkek": "Asia/Bishkek",
  "(GMT+06:00) Dhaka": "Asia/Dhaka",
  "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk": "Asia/Omsk",
  "(GMT+06:00) Thimphu": "Asia/Thimphu",
  "(GMT+06:00) Chagos": "Indian/Chagos",
  "(GMT+06:30) Rangoon": "Asia/Rangoon",
  "(GMT+06:30) Cocos": "Indian/Cocos",
  "(GMT+07:00) Davis": "Antarctica/Davis",
  "(GMT+07:00) Bangkok": "Asia/Bangkok",
  "(GMT+07:00) Hovd": "Asia/Hovd",
  "(GMT+07:00) Jakarta": "Asia/Jakarta",
  "(GMT+07:00) Moscow+04 - Krasnoyarsk": "Asia/Krasnoyarsk",
  "(GMT+07:00) Hanoi": "Asia/Saigon",
  "(GMT+07:00) Christmas": "Indian/Christmas",
  "(GMT+08:00) Casey": "Antarctica/Casey",
  "(GMT+08:00) Brunei": "Asia/Brunei",
  "(GMT+08:00) Choibalsan": "Asia/Choibalsan",
  "(GMT+08:00) Hong Kong": "Asia/Hong_Kong",
  "(GMT+08:00) Moscow+05 - Irkutsk": "Asia/Irkutsk",
  "(GMT+08:00) Kuala Lumpur": "Asia/Kuala_Lumpur",
  "(GMT+08:00) Macau": "Asia/Macau",
  "(GMT+08:00) Makassar": "Asia/Makassar",
  "(GMT+08:00) Manila": "Asia/Manila",
  "(GMT+08:00) China Time - Beijing": "Asia/Shanghai",
  "(GMT+08:00) Singapore": "Asia/Singapore",
  "(GMT+08:00) Taipei": "Asia/Taipei",
  "(GMT+08:00) Ulaanbaatar": "Asia/Ulaanbaatar",
  "(GMT+08:00) Western Time - Perth": "Australia/Perth",
  "(GMT+08:30) Pyongyang": "Asia/Pyongyang",
  "(GMT+09:00) Dili": "Asia/Dili",
  "(GMT+09:00) Jayapura": "Asia/Jayapura",
  "(GMT+09:00) Seoul": "Asia/Seoul",
  "(GMT+09:00) Tokyo": "Asia/Tokyo",
  "(GMT+09:00) Moscow+06 - Yakutsk": "Asia/Yakutsk",
  "(GMT+09:00) Palau": "Pacific/Palau",
  "(GMT+10:30) Central Time - Adelaide": "Australia/Adelaide",
  "(GMT+09:30) Central Time - Darwin": "Australia/Darwin",
  "(GMT+10:00) Dumont D'Urville": "Antarctica/DumontDUrville",
  "(GMT+10:00) Moscow+07 - Magadan": "Asia/Magadan",
  "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk": "Asia/Vladivostok",
  "(GMT+10:00) Eastern Time - Brisbane": "Australia/Brisbane",
  "(GMT+11:00) Eastern Time - Hobart": "Australia/Hobart",
  "(GMT+11:00) Eastern Time - Melbourne, Sydney": "Australia/Sydney",
  "(GMT+10:00) Truk": "Pacific/Chuuk",
  "(GMT+10:00) Guam": "Pacific/Guam",
  "(GMT+10:00) Port Moresby": "Pacific/Port_Moresby",
  "(GMT+11:00) Efate": "Pacific/Efate",
  "(GMT+11:00) Guadalcanal": "Pacific/Guadalcanal",
  "(GMT+11:00) Kosrae": "Pacific/Kosrae",
  "(GMT+11:00) Norfolk": "Pacific/Norfolk",
  "(GMT+11:00) Noumea": "Pacific/Noumea",
  "(GMT+11:00) Ponape": "Pacific/Pohnpei",
  "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy": "Asia/Kamchatka",
  "(GMT+13:00) Auckland": "Pacific/Auckland",
  "(GMT+13:00) Fiji": "Pacific/Fiji",
  "(GMT+12:00) Funafuti": "Pacific/Funafuti",
  "(GMT+12:00) Kwajalein": "Pacific/Kwajalein",
  "(GMT+12:00) Majuro": "Pacific/Majuro",
  "(GMT+12:00) Nauru": "Pacific/Nauru",
  "(GMT+12:00) Tarawa": "Pacific/Tarawa",
  "(GMT+12:00) Wake": "Pacific/Wake",
  "(GMT+12:00) Wallis": "Pacific/Wallis",
  "(GMT+14:00) Apia": "Pacific/Apia",
  "(GMT+13:00) Enderbury": "Pacific/Enderbury",
  "(GMT+13:00) Fakaofo": "Pacific/Fakaofo",
  "(GMT+13:00) Tongatapu": "Pacific/Tongatapu",
  "(GMT+14:00) Kiritimati": "Pacific/Kiritimati",
}
