import React from "react"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import AssetStatus from "../info/AssetStatus"

import LiveSourceTable from "./LiveSourceTable"
import i18n from "src/i18n"

const { t } = i18n
class System extends EcosuiteComponent {
  render() {
    let system = this.props.system
    let systemStatus = this.props.systemStatus

    return this.props.system ? (
      <div className="system-content">
        <h6 className={"content-subtitle " + (systemStatus && systemStatus.status ? systemStatus.status : "no-status")}>
          {system.name} ({system.code})
        </h6>

        <AssetStatus className="details-info" asset={this.props.system} status={systemStatus} showGeneration={this.props.showGeneration} showConsumption={this.props.showConsumption} />

        {systemStatus && systemStatus.nodesStatus.length > 0 ? (
          <LiveSourceTable
            project={this.props.project}
            site={this.props.site}
            system={system}
            nodesStatus={systemStatus.nodesStatus}
            showGeneration={this.props.showGeneration}
            showConsumption={this.props.showConsumption}
          />
        ) : systemStatus ? (
          <div className="no-match">{t("energy.errorMsgs.no_solar_network_matched")}</div>
        ) : (
          <Loading />
        )}
      </div>
    ) : (
      <Loading />
    )
  }
}

export default System
