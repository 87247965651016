import API from "@common/API"
import Logger from "@common/Logger"

const ProjectService = {
  async getI2XData(projectId: string, startDate: string, endDate: string): Promise<Record<string, unknown>> {
    return API.get <{
      i2x: Record<string, unknown>
    }>(`/projects/${projectId}/i2x/?startDate=${startDate}?endDate=${endDate}`)
      .then((response) => response.i2x)
      .catch((err: unknown) => {
        Logger.error(err)
        return Promise.reject({ message: "Unable to retrieve i2X for project: " + projectId })
      })
  }
}

export default ProjectService
