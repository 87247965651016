import React, { CSSProperties } from "react"
import { ColumnDef, ExpandedState, flexRender, getCoreRowModel, getExpandedRowModel, getSortedRowModel, InitialTableState, Row, useReactTable, Getter } from "@tanstack/react-table"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

interface TableProps<T> {
  data: Array<T>
  columns: Array<ColumnDef<T>>
  defaultColumn: Partial<ColumnDef<T>>
  initialState?: InitialTableState
  rowCount?: number
  tdStyle?: CSSProperties
  handleCellContextMenu?: (event: any, rowId: any) => void
}
export default function Table<T extends object>(props: TableProps<T>) {
  const { data, columns: _columns, defaultColumn, initialState = {}, rowCount, tdStyle = {} } = props
  const handleCellContextMenu = props.handleCellContextMenu

  const columns = React.useMemo<ColumnDef<T>[]>(() => _columns, [_columns])

  const [expanded, setExpanded] = React.useState<ExpandedState>(data.map((_, i) => i).reduce((acc, i) => ({ ...acc, [i]: true }), {} as Record<string, boolean>))

  const table = useReactTable({
    data,
    defaultColumn,
    state: {
      expanded,
    },
    initialState,
    getSubRows: (row) => ("subRows" in row ? (row.subRows as T[]) : undefined),
    onExpandedChange: setExpanded,
    columns: columns.map((c) => ({ ...c, sortUndefined: 1 })),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  return (
    <div className="p-2">
      <div className="h-2" />
      <div>
        <table className="ecogy__table_root">
          <thead className="ecogy__table_head_container">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="ecogy__table_head_row">
                {headerGroup.headers.map((header) => {
                  const alignment = ((header) => {
                    let align = "center"
                    if (header.column.columnDef.meta?.align === "left") align = "left"
                    if (header.column.columnDef.meta?.align === "right") align = "right"
                    return align as CSSProperties["textAlign"]
                  })(header)

                  const thWidthStyle = header.column.columnDef.size ? { width: header.getSize() } : {}

                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{ cursor: "pointer", position: "sticky", top: 0, ...thWidthStyle }} className="ecogy__table_head_cell">
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                          style={{ textAlign: alignment }}
                        >
                          <span id={header.column.columnDef.id}>{flexRender(header.column.columnDef.header, header.getContext())}</span>
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                          {header.column.columnDef.meta?.tooltip && (
                            <UncontrolledTooltip placement={header.column.columnDef.meta.tooltip.placement} target={header.column.columnDef.id || ""}>
                              {header.column.columnDef.meta.tooltip.text}
                            </UncontrolledTooltip>
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const alignment = ((cell) => {
                      let align = "left"
                      if (cell.column.columnDef.meta?.align === "center") align = "center"
                      if (cell.column.columnDef.meta?.align === "right") align = "right"
                      return align as CSSProperties["textAlign"]
                    })(cell)
                    return (
                      <td
                        key={`${cell.column.columnDef.id}-cell.id`}
                        // @ts-ignore
                        className={`px-2 ${cell.column.columnDef.id === "icons" && row.original.icons.length === 0 ? "cell-icon" : ""}`}
                        style={{ textAlign: alignment, ...tdStyle }}
                        onClick={cell.column.columnDef.id === "icons" && handleCellContextMenu ? (event) => handleCellContextMenu(event, parseInt(cell.id)) : undefined}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((group, i) => (
              <tr key={i}>
                {group.headers.map((col, j) => {
                  const colValue = col.column.columnDef.footer
                  if (!colValue) return <td key={j}></td>
                  return (
                    <td key={j} style={{ ...tdStyle }}>
                      {typeof colValue === "string" ? colValue : colValue(col.getContext())}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
      <div>{rowCount || table.getRowModel().rows.length} Total Rows</div>
    </div>
  )
}

function DefaultRowExpander<T>({ row, getValue }: { row: Row<T>; getValue: Getter<unknown> }) {
  const expanderStyle = {
    paddingLeft: `${row.depth * 2}rem`,
  }
  return (
    <div style={expanderStyle}>
      <>
        {row.getCanExpand() && (
          <button
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: "pointer" },
            }}
          >
            {row.getIsExpanded() ? "▼" : "▶️"}
          </button>
        )}
        {getValue()}
      </>
    </div>
  )
}

Table.DefaultRowExpander = DefaultRowExpander
