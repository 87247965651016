import React from "react"
import { Container, Row, Col } from "reactstrap"

import EcosuiteComponent, { Error } from "../../../../../common/EcosuiteComponent"
import IRRReportContent from "./irr/report/IRRReportContent"

import "./IRRReport.css"

export default class IRRReport extends EcosuiteComponent {
  componentDidUpdate(prevProps) {
    if (this.props.report !== prevProps.report || this.props.report !== prevProps.report || this.props.aggregate !== prevProps.aggregate) {
      // We clear out any error state that may heve been recorded for the previous report
      this.setStateIfMounted({ hasError: false, error: undefined })
    }
  }

  renderContent() {
    if (this.isContentError(this.props.report)) {
      return <Error error={this.props.report.getError()} />
    }

    return (
      <div className="IRRReport">
        <Container fluid={true} className="IRRReport-content">
          <Row>
            <Col>
              <div className="IRRReport-content" id="finance-report">
                <div className="scroller">
                  <IRRReportContent
                    project={this.props.project}
                    report={this.props.report}
                    aggregate={this.props.aggregate}
                    columns={this.props.columns}
                    showAccounts={this.props.showAccounts}
                    showSubAccounts={this.props.showSubAccounts}
                    projectDiscountRate={this.props.projectDiscountRate}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
