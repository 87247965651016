import * as React from "react"
import * as Tracker from "./common/utils/TrackingUtils"
import { Link } from "react-router-dom"
import logo from "./img/ecosuite_logo.png"
import { useUserOrganization } from "./services/users"
import AccountsCentre from "./AccountsCentre"

interface Props {
  userId: string
}

export default function HeaderLogo(props: Props) {
  const { userId } = props
  const { data } = useUserOrganization(userId)
  const logoToDisplay = data?.organization.settings?.logoUrl || logo

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Link
        to="/"
        onClick={() => {
          Tracker.emit({ name: "App: AMS" })
        }}
      >
        <img src={logoToDisplay} className="logo" alt="logo" style={{ marginRight: 20 }} />
      </Link>

      <div style={{ marginLeft: "-16px", zIndex: 999 }}>
        <AccountsCentre userId={userId} />
      </div>
    </div>
  )
}
