import React, { Component } from "react"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"

const { t } = i18n
const tooltipData = [
  { placement: "bottom", target: "col-source-latest-reading", content: t("energy.tooltips.latest_reading_recorded") },
  { placement: "bottom", target: "col-source-last-hour", content: t("energy.tooltips.the_reading_from_last_hour") },
  { placement: "bottom", target: "col-source-last-hour-expected", content: t("energy.tooltips.expected_reading_from_last_hour") },
  { placement: "bottom", target: "col-source-last-24", content: t("energy.tooltips.the_reading_from_last_24") },
  { placement: "bottom", target: "col-source-last-24-expected", content: t("energy.tooltips.expected_reading_from_the_last_24") },
  { placement: "bottom", target: "col-source-last-hour-percentage", content: t("energy.tooltips.actual_percentage") },
  { placement: "bottom", target: "col-source-expected-peak", content: t("energy.tooltips.expected_peak_power") },
  { placement: "bottom", target: "col-source-message", content: t("energy.tooltips.text_explanation") },
  { placement: "bottom", target: "col-source-last-24-percentage", content: t("energy.tooltips.actual_as_percentage_of_expected") },
]

export default class SourceStatusTooltips extends Component {
  render() {
    return (
      <React.Fragment>
        <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-source-node">
          {t("energy.tooltips.solar_network_id")}
          <p className="tooltip-detail">
            <span className="text-ok">{t("energy.energyStatus.green")}</span>: {t("energy.messages.no_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-warning">{t("energy.energyStatus.orange")}</span>: {t("energy.messages.connectivity_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-error">{t("energy.energyStatus.red")}</span>: {t("energy.messages.issues_need_tobe_resolved")}
          </p>
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-source-id">
          {t("energy.tooltips.solar_network_id_source")}
          <p className="tooltip-detail">
            <span className="text-ok">{t("energy.energyStatus.green")}</span>: {t("energy.messages.no_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-warning">{t("energy.energyStatus.orange")}</span>: {t("energy.messages.connectivity_issues")}
          </p>
          <p className="tooltip-detail">
            <span className="text-error">{t("energy.energyStatus.red")}</span>: {t("energy.messages.issues_need_tobe_resolved")}
          </p>
        </UncontrolledTooltip>
        {tooltipData.map((tooltip) => (
          <UncontrolledTooltip key={tooltip.target} placement={tooltip.placement} modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target={tooltip.target}>
            {tooltip.content}
          </UncontrolledTooltip>
        ))}
        {this.props.showGeneration ? (
          <React.Fragment>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-source-dc-size">
              {t("energy.tooltips.dc_generation")}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-source-ac-size">
              {t("energy.tooltips.ac_generation")}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : null}
        {this.props.showConsumption ? (
          <UncontrolledTooltip placement="bottom" modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]} target="col-source-expected-peak">
            {t("energy.tooltips.consumption")}
          </UncontrolledTooltip>
        ) : null}
      </React.Fragment>
    )
  }
}
