import React from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import { Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import ProFormaService from "../../data/pro-forma/ProFormaService"
import Schemas from "@common/Schemas"
import Settings from "@common/Settings"
import { Tooltip } from "bootstrap"
import CalendarPopover from "./calendar/CalendarPopover"
import "@common/react-toggle.css"
import "./calendar/calendar.scss"
import { Button, ButtonToolbar } from "reactstrap"
import i18n from "src/i18n"
import "./calendar/calendar.css"
import { isEmpty } from "lodash"

const { t } = i18n

const PRODUCT_MILESTONES = "milestones",
  PRODUCT_PAYMENTS = "payments"

const additionalProducts = [PRODUCT_MILESTONES, PRODUCT_PAYMENTS]

export default class CalendarView extends EcosuiteView {
  constructor(props) {
    super(props)
    this.eventMouseEnter = this.eventMouseEnter.bind(this)
    this.eventMouseLeave = this.eventMouseLeave.bind(this)
    this.worker = new Worker(new URL("CalendarEvents.js", import.meta.url))
  }

  componentDidMount() {
    super.componentDidMount()
    this.setStateIfMounted(
      { additionalSelectedProductIds: Settings.getSetting("calendarAdditionalProducts", []) },
      () => {
        Schemas.getRecordSchema().then((schema) => {
          const datePropertyTitles = {}
          populateDatePropertyTitles(datePropertyTitles, "record", schema, schema)
          this.setStateIfMounted({ recordSchema: schema, datePropertyTitles: datePropertyTitles })
        })
        Schemas.getProjectSchema().then((projectSchema) => {
          Schemas.getProFormaSchema().then((proFormaSchema) => {
            this.setStateIfMounted({ projectSchema: projectSchema, proFormaSchema: proFormaSchema }, () => {
              this.loadEvents()
            })
          })
        })
      },
    )
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.records !== prevProps.records ||
      this.props.selectedProductIds !== prevProps.selectedProductIds ||
      this.props.project !== prevProps.project ||
      JSON.stringify(this.props.projects.map((p) => p.code)) !== JSON.stringify(prevProps.projects.map((p) => p.code))
    ) {
      this.loadEvents()
    }
  }

  loadEvents() {
    this.state.proFormas = null
    if (this.props.project) {
      // If a project is selected, load the pro forma and events for the selected project
      const projectId = this.props.project.code
      ProFormaService.getProjectProForma(projectId).then((proForma) => {
        if (this.isProjectCurrent(projectId) && !isEmpty(proForma)) {
          this.setStateIfMounted({ proFormas: [proForma] }, () => {
            this.getEvents()
          })
        }
      })
    } else {
      const projectsCodes = this.props.projects.map((project) => project.code)
      ProFormaService.getProFormas().then((proFormas) => {
        if (this.areProjectsCurrent(projectsCodes)) {
          const filteredProFormas = proFormas?.filter((proforma) =>
            projectsCodes.includes(proforma.id.replace("proforma-", "")),
          )
          this.setStateIfMounted({ proFormas: filteredProFormas }, () => {
            this.getEvents()
          })
        }
      })

      this.setStateIfMounted({ proForma: null }, () => {
        this.getEvents() // TODO do we really need to call getEvents() twice ?
      })
    }
  }

  getEvents() {
    this.worker.onmessage = (events) => {
      this.setStateIfMounted({ events: events.data })
    }
    this.worker.postMessage(
      JSON.parse(
        JSON.stringify({
          props: this.props,
          state: this.state,
          messages: {
            payment_due: t("calendar.messages.payment_due"),
            currency_symbol: t("calendar.messages.currency_symbol"),
          },
        }),
      ),
    )
  }

  renderMainView() {
    if (!this.props.records) {
      return (
        <div className="processes-calendar">
          <Loading />
        </div>
      )
    } else {
      return (
        <div className="processes-calendar content-with-controls">
          <FullCalendar
            height="parent"
            initialView="dayGridMonth"
            plugins={[dayGridPlugin]}
            weekends={true}
            events={this.state.events}
            eventClick={function (info) {
              info.jsEvent.preventDefault() // don't let the browser navigate

              if (info.event.url) {
                window.open(info.event.url)
              }
            }}
            eventMouseEnter={this.eventMouseEnter}
            eventMouseLeave={this.eventMouseLeave}
            eventContent={(info) => {
              return <div id={info.event.id}>{info.event.title}</div>
            }}
            eventDidMount={(info) => {
              new Tooltip(info.el, {
                title: info.event.extendedProps.description ? info.event.extendedProps.description : "",
                placement: "top",
                trigger: "hover",
                container: "body",
              })
            }}
            dayMaxEventRows={false}
          />
          <CalendarPopover event={this.state.event} />
          {this.renderAdditionalProductSelector()}
        </div>
      )
    }
  }

  renderAdditionalProductSelector() {
    return (
      <ButtonToolbar className="content-view-controls">
        {additionalProducts.map((product) => (
          <Button
            size="sm"
            id={product}
            key={product}
            onClick={() => this.toggleAdditionalProduct(product)}
            className={
              this.state.additionalSelectedProductIds && this.state.additionalSelectedProductIds.indexOf(product) >= 0
                ? "selected"
                : null
            }
          >
            {t("calendar.labels." + product)}
          </Button>
        ))}
      </ButtonToolbar>
    )
  }

  toggleAdditionalProduct(productId) {
    var additionalSelectedProductIds = [...this.state.additionalSelectedProductIds]
    if (additionalSelectedProductIds.indexOf(productId) >= 0) {
      // Remove the product
      additionalSelectedProductIds = additionalSelectedProductIds.filter(
        (selectedProductId) => selectedProductId !== productId,
      )
    } else {
      // Add the product
      additionalSelectedProductIds = additionalSelectedProductIds.concat([productId])
    }
    Settings.setSetting("calendarAdditionalProducts", additionalSelectedProductIds)
    this.setStateIfMounted({ additionalSelectedProductIds: additionalSelectedProductIds }, () => {
      this.setStateIfMounted({ events: this.getEvents() })
    })
  }

  eventMouseEnter(info) {
    if (info.event.id && (!this.state.event || this.state.event.id !== info.event.id)) {
      this.setStateIfMounted({
        event: info.event,
      })
    }
  }

  eventMouseLeave() {
    this.setStateIfMounted({ event: null })
  }
}

const populateDatePropertyTitles = function (datePropertyTitles, path, schema, object, dependencyKey) {
  Object.keys(object.properties).forEach((propertyName) => {
    if (propertyName !== dependencyKey) {
      let property = object.properties[propertyName]

      if (property.$ref) {
        let definitionPath = property.$ref.split("#/definitions/")[1]
        let definition = schema.definitions[definitionPath]
        object.properties[propertyName] = definition
      } else if (property.type === "array" && property.items && property.items.$ref) {
        let definitionPath = property.items.$ref.split("#/definitions/")[1]
        let definition = schema.definitions[definitionPath]
        object.properties[propertyName] = definition
      }

      if (property.format === "date") {
        datePropertyTitles[path + "-" + propertyName] = property.title
      }
    }
  })

  Object.keys(object.properties).forEach((propertyName) => {
    let property = object.properties[propertyName]
    if (property.type === "object") {
      populateDatePropertyTitles(datePropertyTitles, `${path}.${propertyName}`, schema, property, dependencyKey)
    } else if (property.type === "array" && property.items && property.items.type === "object") {
      populateDatePropertyTitles(datePropertyTitles, `${path}.${propertyName}`, schema, property.items, dependencyKey)
    }
  })

  if (object.dependencies && Object.keys(object.dependencies).length > 0) {
    Object.keys(object.dependencies).forEach((dependencyKey) => {
      // let dependencyValue = object.dependencies[dependencyKey].properties[dependencyKey].enum[0]
      object.dependencies[dependencyKey].oneOf.forEach((dependency) => {
        populateDatePropertyTitles(datePropertyTitles, path, schema, dependency, dependencyKey)
      })
    })
  }
}
