import React, { Component } from "react"
import { Input, FormGroup, Label } from "reactstrap"
export class PriorityFilter extends Component {
  render() {
    return (
      <FormGroup inline check>
        <Label check className={"form-check-label-color event-priority-" + this.props.priority.id}>
          <Input
            type="checkbox"
            value={this.props.priority.id}
            checked={this.props.selectedPriorities.indexOf(this.props.priority.id) >= 0}
            onChange={() => {
              this.props.togglePriority(this.props.priority.id)
            }}
          />{" "}
          {this.props.priority.name}
        </Label>
      </FormGroup>
    )
  }
}
