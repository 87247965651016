import React, { useState } from "react"
import { listCodeFiles } from "@dashboard/process/views/media/MediaService"
import { Typeahead } from "react-bootstrap-typeahead"
import { useCurrentEffect } from "@common/hooks/useCurrentEffect"
import i18n from "src/i18n"

const { t } = i18n
/**
 * A simple media auto complete.
 * @param code - The code.
 * @param allMedia - All media. If not provided, then all media is fetched.
 * @param disableFetch - Whether to disable the fetching behavior.
 * @param ignoreMediaKeys - Media keys to ignore. This is useful for sending in forms.
 * @param selected - The currently selected key.
 * @param selectCallback - The callback for selecting a key.
 * @constructor
 */
export const MediaAutoComplete = ({
  code,
  allMedia,
  disableFetch,
  ignoreMediaKeys,
  selected,
  selectCallback,
}: {
  code: string
  allMedia: MediaResponse[] | undefined
  disableFetch: boolean
  ignoreMediaKeys: string[]
  selected: string | undefined
  selectCallback: (key: string) => void
}): JSX.Element => {
  const [fetchedMedia, setFetchedMedia] = useState<MediaResponse[] | undefined>(undefined)

  useCurrentEffect(
    (isCurrent) => {
      if (!allMedia && !disableFetch) {
        initializeData(isCurrent)
      }
    },
    [code],
  )

  /**
   * Initialize the data.
   * @param isCurrent - Whether the render is current.
   */
  const initializeData = async (isCurrent: () => boolean) => {
    const media = await listCodeFiles(code)

    if (isCurrent()) {
      setFetchedMedia(media)
    }
  }

  /**
   * Build the media options.
   * @param mediaArray - The media array.
   */
  const buildOptions = (mediaArray: MediaResponse[] | undefined) => {
    return mediaArray ? mediaArray.filter((media) => !ignoreMediaKeys.includes(media.key)).map((media) => media.key) : []
  }

  return (
    <>
      <Typeahead
        isLoading={!allMedia && !fetchedMedia}
        id={"media-auto-complete"}
        inputProps={{ required: true }}
        placeholder={`${t("labels.enter_media_key")}`}
        onChange={(media) => selectCallback.call(this, media[0])}
        selected={selected ? [selected] : []}
        options={buildOptions(allMedia ?? fetchedMedia)}
      />
    </>
  )
}

export default MediaAutoComplete
