import API from "@common/API"

const DRService = {
  getParticipantSchema() {
    return API.getFromCache("/schemas/participant")
  },

  getEventSchema() {
    return API.getFromCache("/schemas/dr-event")
  },

  listParticipants() {
    return API.get("/dr/participants")
  },

  deleteParticipant(id: string) {
    return API.delete("/dr/participants/" + id)
  },

  createParticipant(contactUser: unknown, phoneContact: unknown, projects: unknown) {
    return API.post("/dr/participants", {
      contactUser: contactUser,
      phoneContact: phoneContact,
      projects: projects,
    })
  },

  listEvents() {
    return API.get("/dr/dr-events")
  },

  createEvent(event: unknown) {
    return API.post("/dr/dr-events", event)
  },

  putEvent(id: string, event: unknown) {
    return API.put("/dr/dr-events/" + id, event)
  },

  deleteEvent(id: string) {
    return API.delete("/dr/dr-events/" + id)
  },

  listNotifications() {
    return API.get("/dr/dr-notifications")
  },
}

export default DRService
