import React from "react"
import { Form, FormGroup, Input, Label, Button, Alert } from "reactstrap"
import { Card, CardTitle, CardSubtitle, CardBody, CardText } from "reactstrap"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"

import FinanceService from "./FinanceService"
import i18n from "src/i18n"
import SoftLedgerAdminService from "./SoftLedgerAdminService"
import { keyBy } from "lodash"

const { t } = i18n

function shouldRenderIrrCategoryBasedOnLocation(selectedLocationId, locations = [], subCategory) {
  const locationHashByPrivateId = keyBy(locations, "_id")
  const location = locations.find((l) => l.id === selectedLocationId)
  if (!location) return false
  const locationIdPath = location.parent_path.map((_id) => locationHashByPrivateId[_id].id)
  if (locationIdPath.includes(subCategory.location.id)) return true
  return false
}

class IRRCategories extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {}

    this.saveSettings = this.saveSettings.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadProjectCategories()
    SoftLedgerAdminService.getLocations().then((locations) => {
      this.setState({ locations })
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.project.code !== prevProps.project.code) {
      this.loadProjectCategories()
    }
  }

  loadProjectCategories() {
    this.setStateIfMounted({
      categories: null,
      error: null,
    })

    const projectId = this.props.project.code
    FinanceService.getIRRCategories(projectId)
      .then((categories) => {
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            categories: categories,
          })
        } else {
          Logger.debug(`Ignoring out of date repsonse for project: ${projectId}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isProjectCurrent(projectId)) {
          this.setStateIfMounted({
            error: "Error while retrieving categories for project " + projectId,
          })
        }
      })
  }

  isSubCategoryEnabled(categoryName, subCategoryName) {
    return (
      this.props.settings &&
      this.props.settings.disabledCategories &&
      (!this.props.settings.disabledCategories[categoryName] ||
        this.props.settings.disabledCategories[categoryName].indexOf(subCategoryName) < 0)
    )
  }

  isAccountEnabled(categoryName, subCategoryName, accountName) {
    return (
      this.props.settings &&
      (!this.props.settings.disabledAccounts ||
        !this.props.settings.disabledAccounts[categoryName] ||
        !this.props.settings.disabledAccounts[categoryName][subCategoryName] ||
        this.props.settings.disabledAccounts[categoryName][subCategoryName].indexOf(accountName) < 0)
    )
  }

  toggleSubCategory(categoryName, subCategoryName) {
    let settings = this.props.settings
    if (settings.disabledCategories[categoryName]) {
      if (settings.disabledCategories[categoryName].indexOf(subCategoryName) < 0) {
        // Add the missing category to the list
        settings.disabledCategories[categoryName].push(subCategoryName)
      } else {
        // Remove the existing category from the list
        settings.disabledCategories[categoryName] = settings.disabledCategories[categoryName].filter(
          (disabledSubCategoryName) => {
            return disabledSubCategoryName !== subCategoryName
          },
        )
      }
    } else {
      // Create a new entry and disabled the sub category
      settings.disabledCategories[categoryName] = [subCategoryName]
    }

    this.setStateIfMounted({ settings: settings })
  }

  toggleAccount(categoryName, subCategoryName, accountName) {
    let settings = this.props.settings
    // Check for the first entry
    if (!settings.disabledAccounts) {
      settings.disabledAccounts = {}
    }

    if (!settings.disabledAccounts[categoryName]) {
      settings.disabledAccounts[categoryName] = {}
    }

    if (!settings.disabledAccounts[categoryName][subCategoryName]) {
      settings.disabledAccounts[categoryName][subCategoryName] = []
    }

    if (settings.disabledAccounts[categoryName][subCategoryName].indexOf(accountName) < 0) {
      // Add the missing category to the list
      settings.disabledAccounts[categoryName][subCategoryName].push(accountName)
    } else {
      // Remove the existing category from the list
      settings.disabledAccounts[categoryName][subCategoryName] = settings.disabledAccounts[categoryName][
        subCategoryName
      ].filter((disabledAccountName) => {
        return disabledAccountName !== accountName
      })
    }

    this.setStateIfMounted({ settings: settings })
  }

  saveSettings(e) {
    this.props.actions.saveSettings(this.state.settings)
    e.preventDefault()
  }

  renderContent() {
    if (this.state.error) {
      return <Alert color="danger">{this.state.error}</Alert>
    }

    if (this.state.categories && this.props.settings) {
      return this.renderCategories()
    }

    return <Loading />
  }

  renderCategories() {
    return (
      <Card>
        <CardBody>
          <CardTitle>
            <h4>{t("headings.irr_categories")}</h4>
          </CardTitle>
          <CardSubtitle>
            <p>{t("data.finance.card_subtitle")}</p>
          </CardSubtitle>
          <CardText tag="div">
            <Form className="ecogy-form" onSubmit={this.saveSettings}>
              {Object.keys(this.state.categories).map((categoryName) => {
                let category = this.state.categories[categoryName]
                return (
                  <div key={categoryName} className="project-finance-category">
                    <h5>{category.name}</h5>
                    {Object.keys(category.categories).map((subCategoryName) => {
                      return this.renderSubCategory(category, categoryName, subCategoryName)
                    })}
                  </div>
                )
              })}
              <Button color="primary" disabled={this.props.readonly}>
                {t("buttons.update")}
              </Button>
            </Form>
          </CardText>
        </CardBody>
      </Card>
    )
  }

  renderSubCategory(category, categoryName, subCategoryName) {
    let subCategory = category.categories[subCategoryName]
    if (
      this.props.location &&
      !shouldRenderIrrCategoryBasedOnLocation(this.props.location, this.state.locations, subCategory)
    )
      return null
    return (
      <FormGroup check key={subCategoryName}>
        <Label check>
          <Input
            type="checkbox"
            checked={this.isSubCategoryEnabled(categoryName, subCategoryName)}
            onChange={() => {
              this.toggleSubCategory(categoryName, subCategoryName)
            }}
            disabled={this.props.readonly}
          />{" "}
          {subCategoryName}
        </Label>

        {subCategory.categories
          ? Object.keys(subCategory.categories).map((accountName) => {
              return this.renderAccounts(category, categoryName, subCategoryName, accountName)
            })
          : null}
      </FormGroup>
    )
  }

  renderAccounts(subCategory, categoryName, subCategoryName, accountName) {
    const childSubCategory = subCategory.categories[subCategoryName].categories[accountName]

    if (
      this.props.location &&
      !shouldRenderIrrCategoryBasedOnLocation(this.props.location, this.state.locations, childSubCategory)
    )
      return null

    return (
      <FormGroup check key={accountName}>
        <Label check>
          <Input
            type="checkbox"
            checked={this.isAccountEnabled(categoryName, subCategoryName, accountName)}
            onChange={() => {
              this.toggleAccount(categoryName, subCategoryName, accountName)
            }}
            disabled={this.props.readonly}
          />{" "}
          {accountName}
        </Label>
      </FormGroup>
    )
  }
}

export default IRRCategories
