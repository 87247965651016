import React, { useState } from "react"
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row } from "reactstrap"
import EcosuiteForm, { EcosuiteArrayFieldTemplate } from "@common/form/EcosuiteForm"
import { createRFI, createRFIReply, updateRFI, updateRFIReply } from "@dashboard/process/views/rfi/RFIService"
import LoadingModal from "@common/display/LoadingModal"
import { DateTime } from "luxon"
import MediaAutoComplete from "@common/form/MediaAutoComplete"
import i18n from "src/i18n"

/**
 * What is currently being edited.
 */
export enum RFIEditorValue {
  RFI,
  RFIReply,
}

/**
 * The RFI editor.
 * @param code - The current code.
 * @param allMedia - All media.
 * @param value - The selected editing value.
 * @param selectedRFI - The selected RFI.
 * @param rfiSchema - The RFI schema.
 * @param rfiReplySchema - The RFI reply schema.
 * @param rfiEditorValue - The value type.
 * @param onCreateRFI - The callback for creating an RFI.
 * @param onEditRFI - The callback for editing an RFI.
 * @param onCreateRFIReply - The callback for creating an RFI reply.
 * @param onEditRFIReply - The callback for editing an RFI reply.
 * @constructor
 */
const { t } = i18n
export const RFIEditor = ({
  code,
  allMedia,
  value,
  selectedRFI,
  rfiSchema,
  rfiReplySchema,
  rfiEditorValue,
  onCreateRFI,
  onEditRFI,
  onCreateRFIReply,
  onEditRFIReply,
}: {
  code: string
  allMedia: MediaResponse[] | undefined
  value: RFIStored | RFIReplyStored | undefined
  selectedRFI: RFIStored | undefined
  rfiSchema: Schema
  rfiReplySchema: Schema
  rfiEditorValue: RFIEditorValue | undefined
  onCreateRFI: (rfi: RFIStored) => void
  onEditRFI: (id: string) => void
  onCreateRFIReply: (rfiReply: RFIReplyStored) => void
  onEditRFIReply: (id: string) => void
}): JSX.Element => {
  // Whether the editor is currently submitting something.
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  /**
   * Generically handle default data.
   */
  const handleDefaultData = (): Schema => {
    const baseValue = { code: code }

    switch (rfiEditorValue) {
      case RFIEditorValue.RFIReply: {
        if (value) {
          return {
            rfiID: value.rfiID,
            content: value.content,
            media: value.media,
          }
        }

        return { ...baseValue, rfiID: selectedRFI ? selectedRFI.id : undefined }
      }
      case RFIEditorValue.RFI:
      default: {
        if (value) {
          return {
            code: value.code,
            name: value.name,
            dueDateTime: DateTime.fromISO(value.dueDateTime).toISODate(),
            costImpacts: value.costImpacts,
            scheduleImpacts: value.scheduleImpacts,
            description: value.description,
            linked: value.linked,
            assigned: value.assigned,
            media: value.media,
          }
        }

        return baseValue
      }
    }
  }

  // Automatically fill the code with whatever the current project is.
  const [currentData, setCurrentData] = useState<Schema>(handleDefaultData())

  /**
   * Generically handle the schema.
   */
  const handleSchema = () => {
    switch (rfiEditorValue) {
      case RFIEditorValue.RFIReply:
        return rfiReplySchema
      case RFIEditorValue.RFI:
      default:
        return rfiSchema
    }
  }

  /**
   * The RFI UI schema.
   */
  const rfiUISchema = () => {
    return {
      // The code should not be editable.
      code: {
        "ui:readonly": currentData.code,
      },
      linked: {
        "ui:widget": "hidden",
      },
      media: {
        "ui:options": {
          orderable: false,
        },
        items: {
          // @ts-ignore
          "ui:widget": (props) => (
            <MediaAutoComplete
              code={currentData.code}
              allMedia={allMedia}
              disableFetch={true}
              ignoreMediaKeys={currentData.media ?? []}
              selectCallback={(value) => props.onChange(value)}
              selected={props.value}
            />
          ),
        },
      },
    }
  }

  /**
   * The RFI reply UI schema.
   */
  const rfiReplyUISchema = () => {
    return {
      rfiID: {
        "ui:widget": currentData.rfiID ? "hidden" : undefined,
      },
      content: {
        "ui:widget": "textarea",
      },
      media: {
        "ui:options": {
          orderable: false,
        },
        items: {
          // @ts-ignore
          "ui:widget": (props) => (
            <MediaAutoComplete
              code={selectedRFI.code}
              allMedia={allMedia}
              disableFetch={true}
              ignoreMediaKeys={currentData.media ?? []}
              selectCallback={(value) => props.onChange(value)}
              selected={props.value}
            />
          ),
        },
      },
    }
  }

  /**
   * Generically handle the UI schema.
   */
  const handleUISchema = () => {
    switch (rfiEditorValue) {
      case RFIEditorValue.RFIReply:
        return rfiReplyUISchema()
      case RFIEditorValue.RFI:
      default:
        return rfiUISchema()
    }
  }

  /**
   * The generic onChange for changing the current data.
   * @param schema - The changed schema.
   */
  const onChange = (schema: Schema) => setCurrentData(schema.formData)

  /**
   * Create a new RFI.
   */
  const onSubmitCreateRFI = async () => {
    setIsSubmitting(true)
    const createdRFI = await createRFI(currentData)
    setIsSubmitting(false)
    onCreateRFI.call(this, createdRFI)
  }

  /**
   * Update an RFI.
   */
  const onSubmitUpdateRFI = async () => {
    setIsSubmitting(true)
    await updateRFI(value.id, currentData)
    setIsSubmitting(false)
    onEditRFI.call(this, value.id)
  }

  /**
   * Create a new RFI reply.
   */
  const onSubmitCreateRFIReply = async () => {
    setIsSubmitting(true)
    const createdRFIReply = await createRFIReply(currentData)
    setIsSubmitting(false)
    onCreateRFIReply.call(this, createdRFIReply)
  }

  /**
   * Update an RFI reply.
   */
  const onSubmitUpdateRFIReply = async () => {
    setIsSubmitting(true)
    await updateRFIReply(value.id, currentData)
    setIsSubmitting(false)
    onEditRFIReply.call(this, selectedRFI.id)
  }

  /**
   * Generically handle the form submit.
   */
  const handleSubmit = async () => {
    switch (rfiEditorValue) {
      case RFIEditorValue.RFIReply:
        if (!value) {
          await onSubmitCreateRFIReply()
        } else {
          await onSubmitUpdateRFIReply()
        }
        break
      case RFIEditorValue.RFI:
      default:
        if (!value) {
          await onSubmitCreateRFI()
        } else {
          await onSubmitUpdateRFI()
        }
        break
    }
  }

  return (
    <>
      {isSubmitting && <LoadingModal show={isSubmitting} exit={undefined} />}
      <Card>
        <CardHeader tag={"h5"}>
          <Row>
            <Col>
              <CardTitle>
                {rfiEditorValue === RFIEditorValue.RFI ? (value ? `${t("process.labels.edit_rfi")}` : `${t("process.labels.create_rfi")}`) : `${t("process.labels.edit_rfi_reply")}`}
              </CardTitle>
              <CardSubtitle className={"mb-2 text-muted"} tag={"h6"}>
                {selectedRFI ? selectedRFI.id : value ? value.id : code}
              </CardSubtitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Col className={"ecogy-form"}>
            {/** @ts-ignore **/}
            <EcosuiteForm schema={handleSchema()} uiSchema={handleUISchema()} onChange={onChange} formData={currentData} ArrayFieldTemplate={EcosuiteArrayFieldTemplate} onSubmit={handleSubmit}></EcosuiteForm>
          </Col>
        </CardBody>
      </Card>
    </>
  )
}

export default RFIEditor
