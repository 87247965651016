import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { LAYOUT_FULL } from "@common/module/EcosuiteView"
import { Loading, Error, EcosuiteComponentError } from "@common/EcosuiteComponent"

import EnergyView from "./EnergyView"
import PhotoService from "./photo/PhotoService"
import { Card, CardBody, CardSubtitle } from "reactstrap"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const { t } = i18n
class EnergyPhotoProjectView extends EnergyView {
  constructor(props) {
    super(props)

    this.selectPhoto = this.selectPhoto.bind(this)
  }
  componentDidMount() {
    super.componentDidMount()
    this.loadPhotos()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project || !this.props.range.isSame(prevProps.range)) {
      this.loadPhotos()
    }
  }

  loadPhotos() {
    this.setStateIfMounted({
      photos: null,
    })
    PhotoService.listPhotos(this.props.project, this.props.range)
      .then((photos) => {
        photos = photos.reverse()
        this.setStateIfMounted({
          photos: photos,
        })
      })
      .catch((err) => {
        this.setStateIfMounted({ photos: new EcosuiteComponentError(err) })
      })
  }

  getLayout() {
    return LAYOUT_FULL
  }

  selectPhoto(photo) {
    this.setStateIfMounted({ photo: photo })
  }

  renderMainView() {
    if (this.state.photo) {
      return (
        <div>
          <div
            className="float-end"
            onClick={() => {
              this.selectPhoto()
            }}
          >
            <Icon icon="close" className="close-button" />
          </div>
          <div className="node-photo">
            <img
              src={this.state.photo.signedUrl}
              alt={`Node: ${this.state.photo.nodeId} ${this.state.photo.LastModified}`}
            />
          </div>
        </div>
      )
    }
    if (this.isContentError(this.state.photos)) {
      return <Error error={this.state.photos.getError()} />
    } else if (this.isContentValid(this.state.photos)) {
      if (!this.state.photos.length) {
        return `${t("alertsInfo.no_photos_available")}`
      }
      return (
        <div>
          {this.state.photos.map((photo) => {
            return (
              <Card
                key={photo.key}
                className="node-photo-thumb"
                onClick={() => {
                  this.selectPhoto(photo)
                }}
              >
                {/* <CardImg top width="100%" src="/assets/318x180.svg" alt="Card image cap" /> */}
                <LazyLoadImage
                  alt={`${t("table_headings.node")}: ${photo.nodeId} ${photo.LastModified}`}
                  src={photo.signedUrl}
                />
                <CardBody>
                  <CardSubtitle tag="h6" className="mb-2 text-muted">
                    {`${t("table_headings.node")} ${photo.nodeId}: ${photo.date.format("lll")}`}
                  </CardSubtitle>
                </CardBody>
              </Card>
            )
          })}
        </div>
      )
    } else {
      return <Loading />
    }
  }

  renderViewControls() {
    return null
  }
}

export default EnergyPhotoProjectView
